import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';

type Props = PropsWithChildren<{
  label: string;
}>;

const MenuGroup: React.FC<Props> = React.memo(function MenuGroup({ label, children }) {
  return (
    <div role="group">
      <div css={[styles.groupItem]}>{label}</div>
      {children}
    </div>
  );
});

const styles = {
  groupItem: css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    padding: var(--spacing-3) var(--spacing-5) var(--spacing-2);
    font: var(--font-body-xs-bold);
    color: var(--color-text-placeholder);
    background-color: transparent;
  `,
};

export { MenuGroup };
