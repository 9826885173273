import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

type Gap = '4px' | '8px' | '16px' | '24px';

type Overflow = {
  overflow?: 'auto' | 'hidden'; // default: 'visible'
  overflowX?: 'auto' | 'hidden'; // default: 'visible'
  overflowY?: 'auto' | 'hidden'; // default: 'visible'
};

type Props = {
  gap?: Gap;
  justify?: 'start' | 'center' | 'end' | 'space-between';
  align?: 'stretch' | 'start' | 'center' | 'end';
  /** @deprecated width, height 100% 指定を削除するための移行フラグ */
  legacy?: boolean;
} & Overflow;

type BaseProps = Props & {
  direction: 'row' | 'column';
};

const BaseStack = ({ direction, children, ...props }: PropsWithChildren<BaseProps>) => (
  <div css={style({ direction, ...props })}>{children}</div>
);

/** 水平方向に要素を並べるコンポーネント */
const HStack = ({ children, ...props }: PropsWithChildren<Props>) => (
  <BaseStack direction="row" {...props}>
    {children}
  </BaseStack>
);

/** 垂直方向に要素を並べるコンポーネント */
const VStack = ({ children, ...props }: PropsWithChildren<Props>) => (
  <BaseStack direction="column" {...props}>
    {children}
  </BaseStack>
);

const SPACINGS: Record<Gap, string> = {
  '4px': 'var(--spacing-2)',
  '8px': 'var(--spacing-3)',
  '16px': 'var(--spacing-5)',
  '24px': 'var(--spacing-6)',
};

const style = ({
  direction,
  gap,
  justify,
  align,
  overflow,
  overflowX,
  overflowY,
  legacy,
}: BaseProps & Props) => css`
  ${legacy ? 'width: 100%; height: 100%;' : ''}
  display: flex;
  flex-direction: ${direction};
  justify-content: ${justify || 'start'};
  ${align ? `align-items: ${align};` : ''}
  flex-wrap: nowrap;
  gap: ${gap ? SPACINGS[gap] : 0};
  ${overflows({ overflow, overflowX, overflowY })}

  /* 子要素に対するセレクタ */
  & > * {
    flex-shrink: 0; /* 子要素の縮小を禁止 */
  }
`;

const overflows = ({ overflow, overflowX, overflowY }: Overflow) =>
  `${overflow ? `overflow: ${overflow};` : ''}` +
  `${overflowX ? `overflow-x: ${overflowX};` : ''}` +
  `${overflowY ? `overflow-y: ${overflowY};` : ''}`;

export { HStack, VStack };
