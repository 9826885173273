import React, { ReactNode, useContext } from 'react';
import { useReloadAllUnauthorizedTabs } from '~/common/providers/auth/use-reload-all-unauthorized-tabs';
import { LoginUserContext } from '~/common/providers/auth/LoginUserProvider';
import { useLogout } from '~/features/my-account/use-logout';

type Props = {
  children?: ReactNode;
};

// 現在ログインしているユーザーが取得できている場合のみ、children を表示する
const LoginUserAuthGuard: React.FC<Props> = React.memo(function AuthGuard({ children }) {
  const { isLoading, user } = useContext(LoginUserContext);
  const { logout } = useLogout();

  useReloadAllUnauthorizedTabs(logout);

  if (isLoading) {
    return null;
  }

  if (user) {
    return children;
  }

  return null;
});

export { LoginUserAuthGuard };
