import { css } from '@emotion/react';
import React, { PropsWithChildren } from 'react';

type DrawerFooterProps = PropsWithChildren;

const DrawerFooter = React.memo(function DrawerFooter({ children }: DrawerFooterProps) {
  return <div css={styles.footer}>{children}</div>;
});

const styles = {
  footer: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-3);
    padding: var(--spacing-5);
    border-top: 1px solid var(--color-new-neutral-2);
  `,
};

export { DrawerFooter };
