import React, { memo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { css } from '@emotion/react';
import { Icon } from '~/common/components/icon';
import { Popover, PopoverPanel } from '~/common/components/popover';
import { Span } from '~/common/components/span';
import { Menu, MenuGroup, MenuItem, MenuSeparator } from '~/common/components/menu';
import { fullName } from '~/common/providers/auth/login-user';
import { LoginUserInfoForAppLayout, Tenant } from '~/common/components/layout/AppLayout';
import { InternalLink } from '~/common/components/link';
import { useAvailableTenants } from '~/common/hooks/use-available-tenants';
import { useTenantContext } from '~/common/providers/auth/TenantProvider';

type Props = {
  loginUser: LoginUserInfoForAppLayout;
  onLogout: () => void;
  tenant: Tenant;
};

const AppHeaderUserMenu = memo(function AppHeaderUserMenu({ loginUser, onLogout, tenant }: Props) {
  const [menuShown, setMenuShown] = useState(false);
  const { data: availableTenants } = useAvailableTenants();
  const { switchTenant } = useTenantContext();

  const availableTenantMenuItems =
    availableTenants
      // 現在ログインしているテナントは表示しない
      ?.filter((availableTenant) => tenant.id !== availableTenant.tenantId)
      ?.map((tenant) => (
        <MenuItem
          key={tenant.tenantId}
          onClick={() => {
            setMenuShown(!menuShown);
            switchTenant(tenant.tenantId, '/');
          }}
        >
          {tenant.tenantName}
        </MenuItem>
      )) || [];

  const menu = (
    <PopoverPanel>
      <Menu>
        <InternalLink href={'/my-account'}>
          <MenuItem onClick={() => setMenuShown(!menuShown)}>アカウント設定</MenuItem>
        </InternalLink>
        <MenuItem
          key={'ログアウト'}
          onClick={() => {
            setMenuShown(!menuShown);
            onLogout();
          }}
        >
          ログアウト
        </MenuItem>
        {availableTenantMenuItems.length > 0 && (
          <>
            <MenuSeparator />
            <MenuGroup label={'テナントの切り替え'}>{availableTenantMenuItems}</MenuGroup>
          </>
        )}
      </Menu>
    </PopoverPanel>
  );

  return (
    <Popover popover={menu} show={menuShown} setShow={setMenuShown} align={'end'}>
      <div css={styles.userInfo} onClick={() => setMenuShown((current) => !current)}>
        <div css={styles.nameTenant}>
          <Span level={3} ellipsis>
            {tenant.name}
          </Span>
          <Span level={2} ellipsis>
            {fullName(loginUser)}
          </Span>
        </div>
        <div css={styles.menuIcon}>
          <Icon size={'s'} color={'dark'}>
            <ChevronDown />
          </Icon>
        </div>
      </div>
    </Popover>
  );
});

const styles = {
  userInfo: css`
    display: flex;
    align-items: center;
    cursor: pointer;
  `,

  nameTenant: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,

  menuIcon: css`
    margin-left: var(--spacing-4);
  `,
};

export { AppHeaderUserMenu };
