import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { HttpError } from '~/common/hooks/http-error/http-error';
import { clearLoglassAllItemsInLocalStorage } from '~/common/utils/local-storage';

const useHandleUnauthorizedError = () => {
  const { logout } = useAuth0();

  const handleUnauthorizedError = useCallback(
    (httpError: HttpError | undefined) => {
      if (httpError?.response.status === 401) {
        // 401の場合はリロードしてログイン画面へ飛ばす
        clearLoglassAllItemsInLocalStorage();
        logout();
      }
    },
    [logout],
  );

  return { handleUnauthorizedError };
};

export { useHandleUnauthorizedError };
