import React, { memo } from 'react';
import { css } from '@emotion/react';
import { ChevronRight } from 'react-feather';
import { Span } from '~/common/components/span';
import { Icon } from '~/common/components/icon';
import { useToggleNavigationItem } from '~/common/components/layout/navigation/use-toggle-navigation-item';

type Props = {
  id: string;
  text: string;
  children: React.ReactNode;
};

const NavigationSecondToggleItem: React.FC<Props> = memo(function NavigationSecondToggleItem({
  id,
  text,
  children,
}) {
  const [state, setState] = useToggleNavigationItem();

  return (
    <>
      <button
        css={styles.toggle}
        onClick={() => {
          setState(id);
        }}
      >
        <Icon size={'s'} color={'dark'} css={[styles.toggleIcon, toggleStatusStyle(state[id])]}>
          <ChevronRight />
        </Icon>
        <Span level={2}>{text}</Span>
      </button>
      <div css={[styles.subItemsContainer, state[id] === false ? styles.closed : styles.opened]}>
        <div css={styles.subItems}>{children}</div>
      </div>
    </>
  );
});

const styles = {
  toggle: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-3);
    width: 100%;
    border-radius: var(--border-radius-m);
    padding: var(--spacing-3);
    background: transparent;
    cursor: pointer;
    transition: background-color 300ms;

    &:hover,
    &:focus {
      background-color: var(--color-new-neutral-3);
    }
  `,
  subItemsContainer: css`
    display: flex;
    justify-content: flex-end;
    transition: max-height 300ms;
    overflow: hidden;
  `,
  opened: css`
    max-height: 100vh;
  `,
  closed: css`
    max-height: 0;
  `,
  subItems: css`
    width: 100%;
    padding: var(--spacing-1);
  `,
  toggleIcon: css`
    transition: transform 300ms;
  `,
  toggleIconClosed: css`
    transform: rotate(0deg);
  `,
  toggleIconOpened: css`
    transform: rotate(90deg);
  `,
};

const toggleStatusStyle = (state: boolean | undefined) => {
  return state === false ? styles.toggleIconClosed : styles.toggleIconOpened;
};

export { NavigationSecondToggleItem };
