import { css } from '@emotion/react';

const MenuSeparator = () => <hr css={styles.separator} />;

const styles = {
  separator: css`
    margin: 0; /** ブラウザデフォルトスタイルを上書き */
    border: none;
    border-top: solid 1px var(--color-new-neutral-2);
  `,
};

export { MenuSeparator };
