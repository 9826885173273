import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useSWR from 'swr';
import { AuthControllerApi } from '~/common/api';
import { getItemFromLocalStorage, setItemToLocalStorage } from '~/common/utils/local-storage';
import { Spinner } from '~/common/components/spinner';
import { asSafely, isString, isUndefined } from '~/common/utils/as-safely';

const LOGLASS_ACTIVE_TENANT_ID_KEY_NAME = 'loglass-active-tenant-id';

type TenantContextType = {
  isLoading: boolean;
  tenantId: string | undefined;
  error: Error | undefined;
  switchTenant(tenantId: string, returnTo: string): void;
};

const TenantContext = createContext<TenantContextType>({
  isLoading: true,
  tenantId: undefined,
  error: undefined,
  switchTenant: () => {},
});

const TenantProvider = React.memo(function TenantProviderWrapper({ children }: PropsWithChildren) {
  const { getAccessTokenSilently } = useAuth0();
  const storedTenantId = asSafely(
    getItemFromLocalStorage(LOGLASS_ACTIVE_TENANT_ID_KEY_NAME) ?? undefined,
    [isString, isUndefined],
  );

  // useFetchを使わないのは、useFetchの内部でuseAuthReqOptionsを使っており、useAuthReqOptions内でuseTenantIdを使っているため呼び出せない
  const { data, isLoading, error } = useSWR('/api/auth/default-tenant', async () => {
    // すでにテナントIDがある場合はそれを使う (画面更新を想定)
    if (storedTenantId) return { tenantId: storedTenantId };

    const accessToken = await getAccessTokenSilently();
    const { data } = await new AuthControllerApi().getDefaultTenant({
      headers: { authorization: `Bearer ${accessToken}` },
    });

    setItemToLocalStorage(LOGLASS_ACTIVE_TENANT_ID_KEY_NAME, data.tenantId);
    return data;
  });

  const switchTenant = (tenantId: string, returnTo: string) => {
    setItemToLocalStorage(LOGLASS_ACTIVE_TENANT_ID_KEY_NAME, tenantId);
    window.location.href = returnTo;
  };

  return (
    <TenantContext.Provider value={{ isLoading, error, tenantId: data?.tenantId, switchTenant }}>
      <Spinner show={isLoading}>{!isLoading && children}</Spinner>
    </TenantContext.Provider>
  );
});

const useTenantContext = (): TenantContextType => {
  return useContext(TenantContext);
};

export { TenantProvider, useTenantContext };
