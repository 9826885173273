import React, { createContext, PropsWithChildren } from 'react';
import { LoginUser } from '~/common/providers/auth/login-user';
import { useFetch } from '~/common/hooks/use-fetch';
import { UserControllerApi } from '~/common/api';

const LoginUserContext = createContext<{
  isLoading: boolean;
  user: LoginUser | undefined;
  error: Error | undefined;
}>({
  isLoading: true,
  user: undefined,
  error: undefined,
});

const LoginUserProvider = React.memo(function LoginUserProviderWrapper({
  children,
}: PropsWithChildren) {
  const { data, isLoading, error } = useFetch('/api/users/current', async (reqOps) => {
    const { data } = await new UserControllerApi().getCurrentUser(reqOps);
    return data;
  });

  const loginUser = data && {
    role: data.role,
    userInfo: {
      tenantId: data.tenantId,
      firstName: data.firstName,
      lastName: data.lastName,
      userId: data.userId,
    },
    tenantInfo: {
      tenantId: data.tenantId,
      tenantName: data.tenantName,
    },
    name: data.name,
    email: data.email,
    sub: data.auth0UserId,
  };

  return (
    <LoginUserContext.Provider value={{ isLoading, user: loginUser, error }}>
      {children}
    </LoginUserContext.Provider>
  );
});

export { LoginUserProvider, LoginUserContext };
