import { memo } from 'react';
import { css } from '@emotion/react';
import { NavigationBody } from '~/common/components/layout/navigation/NavigationBody';
import { NavigationHeader } from '~/common/components/layout/navigation/NavigationHeader';
import { Role } from '~/common/providers/auth/role';
import { EnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';
import {
  NavigationStatus,
  useNavigationControl,
} from '~/common/components/layout/navigation/navigation-control';
import { assertNever } from '~/common/utils/assertNever';

type NavigationProps = {
  currentUrl: string;
  myRole: Role;
  enabledFeatureMap: EnabledFeatureMap;
};

const Navigation = memo(function Navigation({
  currentUrl,
  myRole,
  enabledFeatureMap,
}: NavigationProps) {
  const {
    navigationStatus,
    navigationPreviousStatus,
    openFloatNavigation,
    closeNavigationIfFloatOpen,
    NavigationToggleButton,
  } = useNavigationControl();

  return (
    <div
      css={[styles.container, navigationStatusStyle(navigationStatus, navigationPreviousStatus)]}
      onMouseLeave={() => closeNavigationIfFloatOpen()}
    >
      <nav onMouseEnter={() => openFloatNavigation()}>
        <NavigationHeader navigationStatus={navigationStatus} />
        <NavigationBody
          currentUrl={currentUrl}
          myRole={myRole}
          enabledFeatureMap={enabledFeatureMap}
          navigationStatus={navigationStatus}
        />
      </nav>
      <div css={[styles.toggleButton, navigationToggleStyle(navigationStatus)]}>
        <NavigationToggleButton />
      </div>
    </div>
  );
});

const styles = {
  container: css`
    height: 100vh;
    overflow-y: clip;
    position: relative;
    background-color: var(--color-new-neutral-2);
    transition: width 500ms;
  `,
  navigationCloseFromOpen: css`
    width: 52px;
  `,
  navigationCloseFromFloatOpen: css`
    width: 52px;
    position: absolute;
  `,
  navigationOpen: css`
    width: 256px;
  `,
  navigationFloatOpen: css`
    position: absolute;
    width: 256px;
  `,
  toggleButton: css`
    position: absolute;
    top: calc(61px + 36px); /* headerの高さ(borderを含む) + 36px */
    right: -12px;
    transition-duration: 0.5s;
  `,
  toggleButtonClose: css`
    transform: rotate(0deg);
  `,
  toggleButtonOpen: css`
    transform: rotate(180deg);
  `,
};

const navigationToggleStyle = (state: NavigationStatus) =>
  state === 'OPEN' ? styles.toggleButtonOpen : styles.toggleButtonClose;

const navigationStatusStyle = (status: NavigationStatus, prevStatus: NavigationStatus) => {
  switch (status) {
    case 'OPEN':
      return styles.navigationOpen;
    case 'FLOAT_OPEN':
      return styles.navigationFloatOpen;
    case 'CLOSE':
      return prevStatus === 'OPEN'
        ? styles.navigationCloseFromOpen
        : styles.navigationCloseFromFloatOpen;
    default:
      assertNever(status);
  }
};

export { Navigation };
export type { NavigationProps };
