import { SWRResponse } from 'swr';
import { useFetch } from '~/common/hooks/use-fetch';
import { EnabledFeatureControllerApi, EnabledFeaturesDtoEnabledFeaturesEnum } from '~/common/api';

export type EnabledFeatureMap = {
  isDashboardEnabled: boolean;
  isAnalyticsDataDownloadEnabled: boolean;
  isAllocationStandardBulkUploadEnabled: boolean;
  isTableauDashboardEnabled: boolean;
  isComparisonSheetSelectedConditionSaveEnabled: boolean;
  isActualItemDetailViewEnabled: boolean;
  isBulkPlanUpdateRequestEnabled: boolean;
  isComparisonSheetHighlightEnabled: boolean;
  isWorkforcePlanningEnabled: boolean;
  isNonFinancialActualCSVFormat: boolean;
  isLabsEnabled: boolean;
  isAllocationByDimensions: boolean;
  isReportBigQueryEnabled: boolean;
  isReportAccountColumnEnabled: boolean;
  isDetailPopupInActualEnabled: boolean;
  isDimensionTreeEnabled: boolean;
  isAiComparisonAnalysisEnabled: boolean;
  isDisplayNoneDimensionEnabled: boolean;
  isViewPermissionEnabled: boolean;
  isAsyncAllocationExecutionEnabled: boolean;
  isFixedValueAllocationEnabled: boolean;
  isAiPlanActualAnalysisReportEnabled: boolean;
  isFinancialSheetHistoryAndDepartmentEnabled: boolean;
  isApiIntegrationEnabled: boolean;
  isWorkforceImportMappingSettingEnabled: boolean;
  isForeignCurrencyEnabled: boolean;
  isFinancialSimulatorEnabled: boolean;
};

const convertToFeatureMap = (
  enabledFeatures?: EnabledFeaturesDtoEnabledFeaturesEnum[],
): EnabledFeatureMap | undefined => {
  if (!enabledFeatures) return;

  const enabledFeatureMap: Map<EnabledFeaturesDtoEnabledFeaturesEnum, boolean> =
    enabledFeatures.reduce((acc, value) => acc.set(value, true), new Map());

  return {
    isDashboardEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DevTableauDashboard,
    ),
    isAnalyticsDataDownloadEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AnalyticsDataDownload,
    ),
    isAllocationStandardBulkUploadEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AllocationStandardBulkUpload,
    ),
    isTableauDashboardEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DevTableauDashboard,
    ),
    isComparisonSheetSelectedConditionSaveEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ComparisonSheetSelectedConditionSave,
    ),
    isActualItemDetailViewEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ActualItemDetailView,
    ),
    isAllocationByDimensions: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AllocationByDimensions,
    ),
    isBulkPlanUpdateRequestEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.BulkPlanUpdateRequest,
    ),
    isComparisonSheetHighlightEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ComparisonSheetHighlight,
    ),
    isWorkforcePlanningEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.WorkforcePlanning,
    ),
    isNonFinancialActualCSVFormat: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.NonFinancialActualCsvFormat,
    ),
    isLabsEnabled: !!enabledFeatureMap.get(EnabledFeaturesDtoEnabledFeaturesEnum.Labs),
    isDetailPopupInActualEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DetailPopupInActualReport,
    ),
    isDimensionTreeEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DimensionTree,
    ),
    isAiComparisonAnalysisEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.LlmComparisonAnalysis,
    ),
    isDisplayNoneDimensionEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DisplayNoneDimensionInReport,
    ),
    isViewPermissionEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ViewPermission,
    ),
    isReportBigQueryEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ReportBigquery,
    ),
    isReportAccountColumnEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ReportAccountColumn,
    ),
    isAsyncAllocationExecutionEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AsyncAllocationExecution,
    ),
    isFixedValueAllocationEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.FixedValueAllocation,
    ),
    isAiPlanActualAnalysisReportEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AiPlanActualAnalysisReport,
    ),
    isFinancialSheetHistoryAndDepartmentEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.FinancialSheetHistoryAndDepartment,
    ),
    isApiIntegrationEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ApiIntegration,
    ),
    isWorkforceImportMappingSettingEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.WorkforceActualImportMappingSetting,
    ),
    isForeignCurrencyEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ForeignCurrency,
    ),
    isFinancialSimulatorEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.FinancialSimulator,
    ),
  };
};

const useEnabledFeatureMap = (): SWRResponse<EnabledFeatureMap | undefined, Error> => {
  return useFetch(
    '/api/features',
    (reqOps) => {
      return new EnabledFeatureControllerApi()
        .listEnabledFeatures(reqOps)
        .then((response) => convertToFeatureMap(response.data.enabledFeatures));
    },
    {
      dedupingInterval: 1000 * 60 * 60, // フィーチャーフラグはあまり変化しないので、1時間メモリ上のキャッシュに乗せる。リロードでリフレッシュする。
    },
  );
};

export { useEnabledFeatureMap };
