import React, { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { Database, Layers, Lock, Settings } from 'react-feather';
import { RoleGuard } from '~/common/providers/auth/RoleGuard';
import { FeatureGuard } from '~/common/guards/FeatureGuard';
import { Role } from '~/common/providers/auth/role';
import { NavigationItem } from '~/common/components/layout/navigation/NavigationItem';
import { NavigationSecondItem } from '~/common/components/layout/navigation/NavigationSecondItem';
import { financialSheetsAllowedRoles } from '~/features/financial-sheets/shared/financial-sheets-allowed-roles';
import { EnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';
import {
  analyticsMenuAllowedRoles,
  analyticsMenuViewAccountAllowedRoles,
} from '~/features/analytics-menu/analytics-menu-allowed-role';
import { planningAllowedRoles } from '~/features/plannings/index/plannings-allowed-roles';
import { allocationsAllowedRoles } from '~/features/allocation/shared/allocation-allowed-roles';
import { analyticsDataDownloadAllowedRoles } from '~/features/analytics-menu/analytics-data-download/analytics-data-download-allowed-role';
import { uploadAllowedRoles } from '~/features/upload/common/upload-allowed-role';
import { NavigationStatus } from '~/common/components/layout/navigation/navigation-control';
import * as roles from '~/features/settings/settings-allowed-roles';
import { NavigationSecondToggleItem } from '~/common/components/layout/navigation/NavigationSecondToggleItem';
import { NavigationThirdItem } from '~/common/components/layout/navigation/NavigationThirdItem';
import { Tooltip } from '~/common/components/tooltip';
import { Icon } from '~/common/components/icon';
import { planUpdateRequestMappingAllowedRoles } from '~/features/plan-update-request-mapping/plan-update-request-mapping-allowed-roles';
import { planUpdateRequestFormatAllowedRoles } from '~/features/plan-update-request-format/plan-update-request-format-allowed-roles';
import { planUpdateRequestFileAllowedRoles } from '~/features/plan-update-request-file/plan-update-request-file-allowed-roles';
import { workforceMasterAllowedRoles } from '~/features/workforce-planning/workforces/workforce-master-allowed-roles';
import { workforcePlanningAllowedRoles } from '~/features/workforce-planning/workforce-planning-allowed-roles';
import { workforceActualsAllowedRole } from '~/features/workforce-planning/workforce-actuals/workforce-actuals-allowed-role';
import { workforcePlanningItemConversionRuleAllowedRoles } from '~/features/workforce-planning/to-financial-item/workforce-planning-item-conversion-rule-allowed-roles';
import { personListAllowedRoles } from '~/features/workforce-planning/person-list/person-list-allowed-roles';
import { settingsViewPermissionsAllowedRoles } from '~/features/settings/settings-allowed-roles';
import { workforcePlanningSimulationAllowedRoles } from '~/features/workforce-planning/simulation/workforce-planning-simulation-allowed-roles';
import { NewRoleGuard } from '~/common/providers/auth/NewRoleGuard';
import { unitAmountAllowedRoles } from '~/features/workforce-planning/unit-amount/unit-amount-allowed-roles';
import { CURRENCIES_ALLOWED_ROLES } from '~/features/currencies/currencies-allowed-roles';

type NavigationBodyProps = {
  currentUrl: string;
  myRole: Role;
  enabledFeatureMap: EnabledFeatureMap;
  navigationStatus: NavigationStatus;
};

const NavigationBody = memo(function NavigationBody({
  myRole,
  currentUrl,
  enabledFeatureMap,
  navigationStatus,
}: NavigationBodyProps) {
  const isClose = navigationStatus === 'CLOSE';
  const isCurrentPage = (targetUrl: string) => {
    if (targetUrl === currentUrl) return true;
    return currentUrl.startsWith(`${targetUrl}/`); // NOTE: 子ページに遷移してもActiveになるように末尾に`/`を入れている
  };

  const isShowDashboardMenu = useMemo(
    () =>
      analyticsMenuAllowedRoles.includes(myRole) ||
      (analyticsMenuViewAccountAllowedRoles.includes(myRole) &&
        enabledFeatureMap.isTableauDashboardEnabled),
    [myRole, enabledFeatureMap.isTableauDashboardEnabled],
  );

  const isAnalyticsDataDownloadLocked = useMemo(
    () => !(myRole === 'LOGLASS_SYSTEM_ADMIN' || enabledFeatureMap?.isAnalyticsDataDownloadEnabled),
    [myRole, enabledFeatureMap?.isAnalyticsDataDownloadEnabled],
  );

  const isFinancialSheetHistoryAndDepartmentEnabled = useMemo(
    () => enabledFeatureMap?.isFinancialSheetHistoryAndDepartmentEnabled,
    [enabledFeatureMap?.isFinancialSheetHistoryAndDepartmentEnabled],
  );

  const Separator = () => (!isClose ? <hr css={styles.separator} /> : <></>);

  return (
    <div css={styles.container}>
      <div css={styles.itemList}>
        <RoleGuard myRole={myRole} allowedRoles={financialSheetsAllowedRoles}>
          <NavigationItem
            text="経営分析"
            icon={<img alt="spreadsheeticon" src="/assets/icons/spreadsheet.svg" />}
            isClose={isClose}
          >
            <NavigationSecondItem
              text={'レポート'}
              targetUrl={'/financial-sheets/reports'}
              isActive={isCurrentPage('/financial-sheets/reports')}
            />
            {isFinancialSheetHistoryAndDepartmentEnabled && (
              <NavigationSecondItem
                text={'推移表'}
                targetUrl={'/financial-sheets/history'}
                isActive={isCurrentPage('/financial-sheets/history')}
              />
            )}
            <NavigationSecondItem
              text={'対比表'}
              targetUrl={'/financial-sheets/comparison'}
              isActive={isCurrentPage('/financial-sheets/comparison')}
            />
            {isFinancialSheetHistoryAndDepartmentEnabled && (
              <NavigationSecondItem
                text={'部署別表'}
                targetUrl={'/financial-sheets/department'}
                isActive={isCurrentPage('/financial-sheets/department')}
              />
            )}
            {isFinancialSheetHistoryAndDepartmentEnabled && (
              <NavigationSecondItem
                text={'部署別対比表'}
                targetUrl={'/financial-sheets/department-comparison'}
                isActive={isCurrentPage('/financial-sheets/department-comparison')}
              />
            )}
            {enabledFeatureMap.isFinancialSimulatorEnabled && (
              <NavigationSecondItem
                text={'シミュレーション'}
                targetUrl={'/financial-simulations'}
                isActive={isCurrentPage('/financial-simulations')}
              />
            )}
            {isShowDashboardMenu && (
              <NavigationSecondItem
                text={'ダッシュボード'}
                targetUrl={'/dashboard'}
                isActive={isCurrentPage('/dashboard')}
                suffix={
                  !enabledFeatureMap?.isTableauDashboardEnabled ? <LockedSuffix /> : undefined
                }
              />
            )}
            <RoleGuard myRole={myRole} allowedRoles={analyticsDataDownloadAllowedRoles}>
              <NavigationSecondItem
                text={'データダウンロード'}
                targetUrl={'/analytics-data-download'}
                isActive={isCurrentPage('/analytics-data-download')}
                suffix={isAnalyticsDataDownloadLocked ? <LockedSuffix /> : undefined}
              />
            </RoleGuard>
          </NavigationItem>
        </RoleGuard>

        <RoleGuard myRole={myRole} allowedRoles={planningAllowedRoles}>
          {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING', 'MANAGER' */}
          <Separator />
          <NavigationItem text="経営データ更新" icon={<Layers />} isClose={isClose}>
            <NavigationSecondToggleItem id="budgets-forecasts" text={'予算・見込'}>
              <RoleGuard myRole={myRole} allowedRoles={roles.settingsPlansAllowedRoles}>
                {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING' */}
                <NavigationThirdItem
                  text={'計画'}
                  targetUrl={'/plans'}
                  isActive={isCurrentPage('/plans')}
                />
              </RoleGuard>
              <FeatureGuard requiredFeatures={['isWorkforcePlanningEnabled']}>
                <NewRoleGuard allowedRoles={workforcePlanningSimulationAllowedRoles}>
                  <NavigationThirdItem
                    text={'人員計画シミュレーション'}
                    targetUrl={'/workforce-planning/simulations'}
                    isActive={isCurrentPage('/workforce-planning/simulations')}
                  />
                </NewRoleGuard>
              </FeatureGuard>
              {/* ↓ 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING', 'MANAGER' ↓ */}
              <NavigationThirdItem
                text={'編成プロジェクト'}
                targetUrl={'/plannings'}
                isActive={isCurrentPage('/plannings')}
              />
              <RoleGuard myRole={myRole} allowedRoles={planUpdateRequestFileAllowedRoles}>
                {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING' */}
                <NavigationThirdItem
                  text={'申請済ファイル'}
                  targetUrl={'/plan-update-request-files'}
                  isActive={isCurrentPage('/plan-update-request-files')}
                />
              </RoleGuard>
              <RoleGuard myRole={myRole} allowedRoles={planUpdateRequestFormatAllowedRoles}>
                <NavigationThirdItem
                  text={'申請フォーマット'}
                  targetUrl={'/plan-update-request-formats'}
                  isActive={isCurrentPage('/plan-update-request-formats')}
                />
              </RoleGuard>
              <RoleGuard myRole={myRole} allowedRoles={planUpdateRequestMappingAllowedRoles}>
                {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING' */}
                <NavigationThirdItem
                  text={'取込・出力設定'}
                  targetUrl={'/plan-update-request-mappings'}
                  isActive={isCurrentPage('/plan-update-request-mappings')}
                />
              </RoleGuard>
            </NavigationSecondToggleItem>
            <RoleGuard myRole={myRole} allowedRoles={uploadAllowedRoles}>
              {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING' */}
              <NavigationSecondToggleItem id="actuals" text={'実績'}>
                <NavigationThirdItem
                  text={'財務実績'}
                  targetUrl={'/upload/financial-actuals'}
                  isActive={isCurrentPage('/upload/financial-actuals')}
                />
                <NavigationThirdItem
                  text={'非財務実績'}
                  targetUrl={'/upload/non-financial-actuals'}
                  isActive={isCurrentPage('/upload/non-financial-actuals')}
                />
                <FeatureGuard requiredFeatures={['isWorkforcePlanningEnabled']}>
                  <RoleGuard myRole={myRole} allowedRoles={workforceActualsAllowedRole}>
                    {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN' */}
                    <NavigationThirdItem
                      text={'工数実績'}
                      targetUrl={'/upload/workforce/actuals'}
                      isActive={isCurrentPage('/upload/workforce/actuals')}
                    />
                  </RoleGuard>
                </FeatureGuard>
                <NavigationThirdItem
                  text={'取込設定'}
                  targetUrl={'/actuals-import-mappings'}
                  isActive={isCurrentPage('/actuals-import-mappings')}
                />
                {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING' */}
                <NavigationThirdItem
                  text={'加工設定'}
                  targetUrl={'/process-settings'}
                  isActive={isCurrentPage('/process-settings')}
                />
              </NavigationSecondToggleItem>
            </RoleGuard>
            <RoleGuard myRole={myRole} allowedRoles={allocationsAllowedRoles}>
              {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING' */}
              <NavigationSecondToggleItem id="allocations" text={'振替・配賦'}>
                <NavigationThirdItem
                  text={'配賦基準'}
                  targetUrl={'/allocations/standards'}
                  isActive={isCurrentPage('/allocations/standards')}
                />
                <NavigationThirdItem
                  text={'振替・配賦ルール'}
                  targetUrl={'/allocations/rules'}
                  isActive={isCurrentPage('/allocations/rules')}
                />
                <NavigationThirdItem
                  text={'実行計画'}
                  targetUrl={'/allocations/strategies'}
                  isActive={isCurrentPage('/allocations/strategies')}
                />
              </NavigationSecondToggleItem>
            </RoleGuard>

            <FeatureGuard requiredFeatures={['isWorkforcePlanningEnabled']}>
              <RoleGuard myRole={myRole} allowedRoles={workforcePlanningAllowedRoles}>
                {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', */}
                <RoleGuard
                  myRole={myRole}
                  allowedRoles={workforcePlanningItemConversionRuleAllowedRoles}
                >
                  {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN' */}
                  <NavigationSecondToggleItem id="allocations" text={'人件費変換'}>
                    <NavigationThirdItem
                      text={'昇給'}
                      targetUrl={'/workforce-planning/to-financial-item/base-salary-increase'}
                      isActive={isCurrentPage(
                        '/workforce-planning/to-financial-item/base-salary-increase',
                      )}
                    />
                    <NavigationThirdItem
                      text={'科目変換ルール'}
                      targetUrl={'/workforce-planning/to-financial-item/conversion-rules'}
                      isActive={isCurrentPage(
                        '/workforce-planning/to-financial-item/conversion-rules',
                      )}
                    />
                  </NavigationSecondToggleItem>
                </RoleGuard>
              </RoleGuard>
            </FeatureGuard>
          </NavigationItem>
        </RoleGuard>

        <FeatureGuard requiredFeatures={['isForeignCurrencyEnabled']}>
          <RoleGuard myRole={myRole} allowedRoles={CURRENCIES_ALLOWED_ROLES}>
            {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING' */}
            <Separator />
            <NavigationItem text="データ加工" icon={<Database />} isClose={isClose}>
              <NavigationSecondToggleItem id="currencies" text={'為替換算'}>
                <NavigationThirdItem
                  id="nav-local-currency"
                  text={'現地通貨'}
                  targetUrl={'/currencies/local-currency'}
                  isActive={isCurrentPage('/currencies/local-currency')}
                />
                <NavigationThirdItem
                  id="nav-rates"
                  text={'為替レート'}
                  targetUrl={'/currencies/rates'}
                  isActive={isCurrentPage('/currencies/rates')}
                />
              </NavigationSecondToggleItem>
            </NavigationItem>
          </RoleGuard>
        </FeatureGuard>

        <RoleGuard myRole={myRole} allowedRoles={MASTER_MANAGEMENT_ALLOWED_ROLES}>
          {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING', 'IT_STAFF', */}
          <Separator />
          <NavigationItem
            text="マスタ管理"
            icon={<img alt="spreadsheeticon" src="/assets/icons/allocation.svg" />}
            isClose={isClose}
          >
            <RoleGuard myRole={myRole} allowedRoles={roles.settingsOrganizationAllowedRoles}>
              {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING', 'IT_STAFF' */}
              <NavigationSecondItem
                // LOG-8924 KARTE でチュートリアルを作るために実験的に追加。後ほど削除する
                id="nav-organization"
                text={'組織'}
                targetUrl={'/master/organization'}
                isActive={isCurrentPage('/master/organization')}
              />
            </RoleGuard>
            <RoleGuard
              myRole={myRole}
              allowedRoles={
                roles.settingsAccountTreeAllowedRoles
                // 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING',
              }
            >
              <NavigationSecondToggleItem id="accounts" text={'科目'}>
                <NavigationThirdItem
                  // LOG-8924 KARTE でチュートリアルを作るために実験的に追加。後ほど削除する
                  id="nav-account-tree"
                  text={'財務科目'}
                  targetUrl={'/master/account/pl'}
                  isActive={isCurrentPage('/master/account/pl')}
                />
                <NavigationThirdItem
                  text={'非財務科目'}
                  targetUrl={'/master/account/non-financial'}
                  isActive={isCurrentPage('/master/account/non-financial')}
                />
                <NavigationThirdItem
                  text={'計算科目'}
                  targetUrl={'/master/account/calculated-account'}
                  isActive={isCurrentPage('/master/account/calculated-account')}
                />
              </NavigationSecondToggleItem>
            </RoleGuard>
            <RoleGuard myRole={myRole} allowedRoles={roles.settingsDimensionsAllowedRoles}>
              {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'CORP_PLANNING' */}
              <NavigationSecondItem
                text={'分析軸'}
                targetUrl={'/master/dimensions'}
                isActive={isCurrentPage('/master/dimensions')}
              />
            </RoleGuard>
            <FeatureGuard requiredFeatures={['isWorkforcePlanningEnabled']}>
              <RoleGuard myRole={myRole} allowedRoles={workforcePlanningAllowedRoles}>
                {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN' */}

                <RoleGuard myRole={myRole} allowedRoles={personListAllowedRoles}>
                  <NavigationSecondItem
                    text={'人員'}
                    targetUrl={'/workforce-planning/person-list'}
                    isActive={isCurrentPage('/workforce-planning/person-list')}
                  />
                </RoleGuard>

                <NewRoleGuard allowedRoles={unitAmountAllowedRoles}>
                  <NavigationSecondItem
                    text={'人件費単価'}
                    targetUrl={'/workforce-planning/unit-amounts'}
                    isActive={isCurrentPage('/workforce-planning/unit-amounts')}
                  />
                </NewRoleGuard>

                <RoleGuard myRole={myRole} allowedRoles={workforceMasterAllowedRoles}>
                  <NavigationSecondItem
                    text={'人員計画枠'}
                    targetUrl={'/workforce-planning/workforce-slots'}
                    isActive={isCurrentPage('/workforce-planning/workforce-slots')}
                  />
                </RoleGuard>
                <NewRoleGuard allowedRoles={workforceMasterAllowedRoles}>
                  <NavigationSecondItem
                    text={'取込設定'}
                    targetUrl={'/workforce-planning/workforce-actual-import-mapping-settings'}
                    isActive={isCurrentPage(
                      '/workforce-planning/workforce-actual-import-mapping-settings',
                    )}
                  />
                </NewRoleGuard>
              </RoleGuard>
            </FeatureGuard>
          </NavigationItem>
        </RoleGuard>

        <RoleGuard myRole={myRole} allowedRoles={SETTINGS_ALLOWED_ROLES}>
          {/* 'LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'IT_STAFF' */}
          <Separator />
          <NavigationItem text="設定" icon={<Settings />} isClose={isClose}>
            <NavigationSecondItem
              text={'会社設定'}
              targetUrl={'/settings/tenant'}
              isActive={isCurrentPage('/settings/tenant')}
            />
            <NavigationSecondItem
              text={'ユーザー管理'}
              targetUrl={'/settings/users'}
              isActive={isCurrentPage('/settings/users')}
            />
            <FeatureGuard requiredFeatures={['isViewPermissionEnabled']}>
              <RoleGuard myRole={myRole} allowedRoles={settingsViewPermissionsAllowedRoles}>
                <NavigationSecondItem
                  text={'閲覧権限管理'}
                  targetUrl={'/settings/view-permissions'}
                  isActive={isCurrentPage('/settings/view-permissions')}
                />
              </RoleGuard>
            </FeatureGuard>
          </NavigationItem>
        </RoleGuard>
      </div>
    </div>
  );
});

const LockedSuffix = () => (
  <div>
    <Tooltip
      message={'この機能をご利用されたい場合には、サポート担当までご相談ください'}
      position={'top'}
    >
      <Icon size={'s'} color={'dark'}>
        <Lock />
      </Icon>
    </Tooltip>
  </div>
);

// マスタ管理にアクセスできるロール群
const MASTER_MANAGEMENT_ALLOWED_ROLES: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
  'IT_STAFF',
];

// 設定にアクセスできるロール群
const SETTINGS_ALLOWED_ROLES: Role[] = ['LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN', 'IT_STAFF'];

const styles = {
  container: css`
    width: 100%;
    height: calc(100vh - 61px);
    padding: var(--spacing-3);
    padding-bottom: 80px; // Onboarding ボタンとの重なりを防ぐため
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  `,
  itemList: css`
    width: 240px;
  `,
  separator: css`
    margin: 1px 0 var(--spacing-2);
    border: none;
    border-top: solid 1px var(--color-new-neutral-3);
  `,
};

export { NavigationBody };
export type { NavigationBodyProps };
