import { useFetch } from '~/common/hooks/use-fetch';
import { AuthControllerApi } from '~/common/api';

const useAvailableTenants = () => {
  return useFetch('/api/available-tenants', (reqOps) => {
    return new AuthControllerApi().listAvailableTenants(reqOps).then((res) => res.data);
  });
};

export { useAvailableTenants };
