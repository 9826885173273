import React, { ReactNode, StrictMode } from 'react';
import { ToastProvider } from '~/common/components/toast';
import { ErrorBoundary } from '~/app/ErrorBoundary';
import { ModalProvider } from '~/common/components/modal';
import { HookPopoverProvider } from '~/common/components/popover/HookPopoverProvider';
import { AuthProvider } from '~/common/providers/auth/AuthProvider';
import { AuthGuard } from '~/common/providers/auth/AuthGuard';
import { DatadogRumProvider } from '~/app/DatadogRumProvider';
import { LabsLayoutContainer } from '~/app/LabsLayoutContainer';
import { GoogleTagManagerProvider } from '~/app/GoogleTagManagerProvider';
import { LoginUserProvider } from '~/common/providers/auth/LoginUserProvider';
import { LoginUserAuthGuard } from '~/common/providers/auth/LoginUserAuthGuard';
import { TenantProvider } from '~/common/providers/auth/TenantProvider';

type Props = {
  noAuth: boolean;
  children: ReactNode;
};

const LabsAppProvider: React.FC<Props> = ({ children, noAuth }) => (
  <StrictMode>
    <ErrorBoundary>
      {noAuth && children}
      {!noAuth && (
        <AuthProvider>
          <AuthGuard>
            <ToastProvider>
              <TenantProvider>
                <LoginUserProvider>
                  <LoginUserAuthGuard>
                    <DatadogRumProvider>
                      <GoogleTagManagerProvider>
                        <ModalProvider>
                          <HookPopoverProvider>
                            <LabsLayoutContainer>{children}</LabsLayoutContainer>
                          </HookPopoverProvider>
                        </ModalProvider>
                      </GoogleTagManagerProvider>
                    </DatadogRumProvider>
                  </LoginUserAuthGuard>
                </LoginUserProvider>
              </TenantProvider>
            </ToastProvider>
          </AuthGuard>
        </AuthProvider>
      )}
    </ErrorBoundary>
  </StrictMode>
);

export { LabsAppProvider };
