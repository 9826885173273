import React, { memo, ReactElement, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Icon } from '~/common/components/icon';
import { Span } from '~/common/components/span';

type Props = {
  icon: ReactElement;
  text: string;
  isActive?: boolean;
  isClose: boolean;
  children?: ReactNode;
};

const NavigationItem: React.FC<Props> = memo(function NavigationItem({
  text,
  icon,
  children,
  isActive,
  isClose,
}) {
  return (
    <div>
      <div
        css={[
          styles.navigationItem,
          isActive ? styles.navigationItemActive : undefined,
          styles.navigationItemContainer,
        ]}
      >
        <div css={styles.labelColor}>
          <Icon size={'s'} color={'dark'}>
            {icon}
          </Icon>
        </div>
        {!isClose && (
          <div css={[styles.text, styles.labelColor]}>
            <Span level={4} bold>
              {text}
            </Span>
          </div>
        )}
      </div>
      {!isClose && (
        <div css={styles.subItemsContainer}>
          <div css={styles.subItems}>{children}</div>
        </div>
      )}
    </div>
  );
});

const styles = {
  navigationItemContainer: css`
    height: 32px;
    position: relative;
  `,

  navigationItem: css`
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-m);
    padding: 0 var(--spacing-3);
  `,

  navigationItemActive: css`
    background-color: var(--color-new-neutral-2);
  `,

  labelColor: css`
    opacity: 0.79; /* NOTE: Neutral 8を表現するためにopacityを利用している。Icon,Spanではdark(N9) or gray(N6)しか選択できないため */
  `,

  text: css`
    display: flex;
    margin-left: var(--spacing-3);
  `,

  subItemsContainer: css`
    display: flex;
    justify-content: flex-end;
  `,

  subItems: css`
    width: 100%;
  `,
};

export { NavigationItem };
