import { parseISO } from 'date-fns';

/**
 * JSON.parse の reviver (第二引数)関数
 * ISO 日付文字列の正規表現にマッチする場合、Date にパースする
 * @param _ - JSON の key（使用しない）
 * @param value - value
 */
function reviver(_: string, value: string): string | Date {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(value) ? parseISO(value) : value;
}

const getItemFromLocalStorage = <T>(key: string): T | undefined => {
  const rawString = localStorage.getItem(key);
  return rawString ? JSON.parse(rawString, reviver) : undefined;
};

const setItemToLocalStorage = <T>(key: string, value: T): void => {
  const rawString = JSON.stringify(value);
  localStorage.setItem(key, rawString);
};

const removeItemInLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

const clearLoglassAllItemsInLocalStorage = (): void => {
  const storageKeys = Object.keys(localStorage);
  storageKeys.forEach((key) => {
    // NOTE: techtouchが作成したデータは削除しない
    if (!key.startsWith('tt/')) {
      removeItemInLocalStorage(key);
    }
  });
};

export {
  getItemFromLocalStorage,
  setItemToLocalStorage,
  removeItemInLocalStorage,
  clearLoglassAllItemsInLocalStorage,
};
