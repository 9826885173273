import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { assertNever } from '~/common/utils/assertNever';

type HeadingProps = {
  level: 1 | 2 | 2.5 | 3 | 4;
  prewrap?: boolean;
  id?: string;
};

const Heading: React.FC<PropsWithChildren<HeadingProps>> = React.memo(function Heading({
  children,
  level,
  prewrap,
  id,
}) {
  return (
    <h1 css={[styles.heading, levelStyle(level), prewrap && styles.prewrap]} id={id}>
      {children}
    </h1>
  );
});

const levelStyle = (level: 1 | 2 | 2.5 | 3 | 4) => {
  switch (level) {
    case 1:
      return styles.level1;
    case 2:
      return styles.level2;
    case 2.5:
      return styles.level2_5;
    case 3:
      return styles.level3;
    case 4:
      return styles.level4;
    default:
      return assertNever(level);
  }
};

const styles = {
  heading: css`
    font-family: var(--font-family-jp);
    letter-spacing: normal;
    text-transform: none;
    color: var(--color-text-normal);
  `,

  level1: css`
    font-size: 32px; /* Design token に定義はないが、ここだけ値指定する */
    font-weight: var(--font-weight-w6);
    line-height: 48px;
  `,

  level2: css`
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-w6);
    line-height: 32px;
  `,

  // TODO: Level 2.5は暫定措置。Design SystemのTypography改修でHeading名と合わせる
  level2_5: css`
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-w6);
    line-height: 1.4;
  `,

  level3: css`
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-w6);
    line-height: 24px;
  `,

  level4: css`
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-w6);
    line-height: 20px;
  `,
  prewrap: css`
    white-space: pre-wrap;
  `,
};

export type { HeadingProps };
export { Heading };
