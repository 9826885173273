/* tslint:disable */
/* eslint-disable */
/**
 * Loglass API Documentation
 * This is a loglass-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountFlatTreeForSelectionView
 */
export interface AccountFlatTreeForSelectionView {
    /**
     * 
     * @type {Array<AccountTreeNodeForSelectionView>}
     * @memberof AccountFlatTreeForSelectionView
     */
    'items': Array<AccountTreeNodeForSelectionView>;
    /**
     * 
     * @type {AccountTreeMetaView}
     * @memberof AccountFlatTreeForSelectionView
     */
    'meta': AccountTreeMetaView;
    /**
     * 
     * @type {Array<AccountView>}
     * @memberof AccountFlatTreeForSelectionView
     */
    'notIncludes': Array<AccountView>;
}
/**
 * 
 * @export
 * @interface AccountFlatTreeView
 */
export interface AccountFlatTreeView {
    /**
     * 
     * @type {Array<AccountTreeNodeView>}
     * @memberof AccountFlatTreeView
     */
    'items': Array<AccountTreeNodeView>;
    /**
     * 
     * @type {AccountTreeMetaView}
     * @memberof AccountFlatTreeView
     */
    'meta': AccountTreeMetaView;
}
/**
 * 
 * @export
 * @interface AccountGroupView
 */
export interface AccountGroupView {
    /**
     * 
     * @type {string}
     * @memberof AccountGroupView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AccountGroupView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountGroupView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AccountTreeListView
 */
export interface AccountTreeListView {
    /**
     * 
     * @type {Array<AccountTreeMetaView>}
     * @memberof AccountTreeListView
     */
    'items': Array<AccountTreeMetaView>;
}
/**
 * 
 * @export
 * @interface AccountTreeMetaView
 */
export interface AccountTreeMetaView {
    /**
     * 
     * @type {string}
     * @memberof AccountTreeMetaView
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountTreeMetaView
     */
    'isPrimary': boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeMetaView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AccountTreeNodeForSelectionView
 */
export interface AccountTreeNodeForSelectionView {
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeForSelectionView
     */
    'accountId': string;
    /**
     * 
     * @type {AccountView}
     * @memberof AccountTreeNodeForSelectionView
     */
    'data': AccountView;
    /**
     * 
     * @type {boolean}
     * @memberof AccountTreeNodeForSelectionView
     */
    'isAggregation': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountTreeNodeForSelectionView
     */
    'isOwnValue': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountTreeNodeForSelectionView
     */
    'isViewable': boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountTreeNodeForSelectionView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeForSelectionView
     */
    'parentAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeForSelectionView
     */
    'rowType': AccountTreeNodeForSelectionViewRowTypeEnum;
}

export const AccountTreeNodeForSelectionViewRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type AccountTreeNodeForSelectionViewRowTypeEnum = typeof AccountTreeNodeForSelectionViewRowTypeEnum[keyof typeof AccountTreeNodeForSelectionViewRowTypeEnum];

/**
 * 
 * @export
 * @interface AccountTreeNodeView
 */
export interface AccountTreeNodeView {
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeView
     */
    'accountType': AccountTreeNodeViewAccountTypeEnum;
    /**
     * 
     * @type {AccountingSystemCodePairView}
     * @memberof AccountTreeNodeView
     */
    'accountingSystemCodePair'?: AccountingSystemCodePairView;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeView
     */
    'calculationType': AccountTreeNodeViewCalculationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AccountTreeNodeView
     */
    'canInsertNewAccount': boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeView
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AccountTreeNodeView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeView
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeView
     */
    'rowType': AccountTreeNodeViewRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeNodeView
     */
    'viewControlType': AccountTreeNodeViewViewControlTypeEnum;
}

export const AccountTreeNodeViewAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type AccountTreeNodeViewAccountTypeEnum = typeof AccountTreeNodeViewAccountTypeEnum[keyof typeof AccountTreeNodeViewAccountTypeEnum];
export const AccountTreeNodeViewCalculationTypeEnum = {
    Normal: 'NORMAL'
} as const;

export type AccountTreeNodeViewCalculationTypeEnum = typeof AccountTreeNodeViewCalculationTypeEnum[keyof typeof AccountTreeNodeViewCalculationTypeEnum];
export const AccountTreeNodeViewRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type AccountTreeNodeViewRowTypeEnum = typeof AccountTreeNodeViewRowTypeEnum[keyof typeof AccountTreeNodeViewRowTypeEnum];
export const AccountTreeNodeViewViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type AccountTreeNodeViewViewControlTypeEnum = typeof AccountTreeNodeViewViewControlTypeEnum[keyof typeof AccountTreeNodeViewViewControlTypeEnum];

/**
 * 
 * @export
 * @interface AccountTreeWithFormulaNodeDataView
 */
export interface AccountTreeWithFormulaNodeDataView {
    /**
     * 
     * @type {string}
     * @memberof AccountTreeWithFormulaNodeDataView
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountTreeWithFormulaNodeDataView
     */
    'accountName': string;
    /**
     * 
     * @type {FinancialModelFormulaWithTypeView}
     * @memberof AccountTreeWithFormulaNodeDataView
     */
    'formula': FinancialModelFormulaWithTypeView;
    /**
     * 
     * @type {number}
     * @memberof AccountTreeWithFormulaNodeDataView
     */
    'level': number;
}
/**
 * 
 * @export
 * @interface AccountView
 */
export interface AccountView {
    /**
     * 
     * @type {string}
     * @memberof AccountView
     */
    'accountType': AccountViewAccountTypeEnum;
    /**
     * 
     * @type {AccountingSystemCodePairView}
     * @memberof AccountView
     */
    'accountingSystemCodePair'?: AccountingSystemCodePairView;
    /**
     * 
     * @type {string}
     * @memberof AccountView
     */
    'calculationType': AccountViewCalculationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AccountView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountView
     */
    'name': string;
    /**
     * 
     * @type {SubAccountsView}
     * @memberof AccountView
     */
    'subAccounts': SubAccountsView;
    /**
     * 
     * @type {string}
     * @memberof AccountView
     */
    'viewControlType': AccountViewViewControlTypeEnum;
}

export const AccountViewAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type AccountViewAccountTypeEnum = typeof AccountViewAccountTypeEnum[keyof typeof AccountViewAccountTypeEnum];
export const AccountViewCalculationTypeEnum = {
    Normal: 'NORMAL'
} as const;

export type AccountViewCalculationTypeEnum = typeof AccountViewCalculationTypeEnum[keyof typeof AccountViewCalculationTypeEnum];
export const AccountViewViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type AccountViewViewControlTypeEnum = typeof AccountViewViewControlTypeEnum[keyof typeof AccountViewViewControlTypeEnum];

/**
 * 
 * @export
 * @interface AccountingSystemCodePairView
 */
export interface AccountingSystemCodePairView {
    /**
     * 
     * @type {AccountingSystemCodeView}
     * @memberof AccountingSystemCodePairView
     */
    'exCodeView': AccountingSystemCodeView;
    /**
     * 
     * @type {AccountingSystemSubCodeView}
     * @memberof AccountingSystemCodePairView
     */
    'exSubCodeView'?: AccountingSystemSubCodeView;
}
/**
 * 
 * @export
 * @interface AccountingSystemCodeView
 */
export interface AccountingSystemCodeView {
    /**
     * 
     * @type {string}
     * @memberof AccountingSystemCodeView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystemCodeView
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface AccountingSystemSubCodeView
 */
export interface AccountingSystemSubCodeView {
    /**
     * 
     * @type {string}
     * @memberof AccountingSystemSubCodeView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystemSubCodeView
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface AccountsForReplaceRootNodeSelectionView
 */
export interface AccountsForReplaceRootNodeSelectionView {
    /**
     * 
     * @type {AccountView}
     * @memberof AccountsForReplaceRootNodeSelectionView
     */
    'currentRootNodeAccount': AccountView;
    /**
     * 
     * @type {Array<AccountView>}
     * @memberof AccountsForReplaceRootNodeSelectionView
     */
    'selectionAccounts': Array<AccountView>;
}
/**
 * 
 * @export
 * @interface AccountsView
 */
export interface AccountsView {
    /**
     * 
     * @type {Array<AccountView>}
     * @memberof AccountsView
     */
    'value': Array<AccountView>;
}
/**
 * 
 * @export
 * @interface ActiveUserCountDto
 */
export interface ActiveUserCountDto {
    /**
     * 
     * @type {number}
     * @memberof ActiveUserCountDto
     */
    'view': number;
    /**
     * 
     * @type {number}
     * @memberof ActiveUserCountDto
     */
    'write': number;
}
/**
 * 
 * @export
 * @interface ActualImportEventDto
 */
export interface ActualImportEventDto {
    /**
     * 
     * @type {string}
     * @memberof ActualImportEventDto
     */
    'deleteStartAt'?: string;
    /**
     * 
     * @type {IDActualImportEvent}
     * @memberof ActualImportEventDto
     */
    'eventId': IDActualImportEvent;
    /**
     * 
     * @type {string}
     * @memberof ActualImportEventDto
     */
    'importedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ActualImportEventDto
     */
    'importedCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActualImportEventDto
     */
    'importedFileName': string;
    /**
     * 
     * @type {string}
     * @memberof ActualImportEventDto
     */
    'importerName': string;
    /**
     * 
     * @type {string}
     * @memberof ActualImportEventDto
     */
    'max'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualImportEventDto
     */
    'min'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualImportEventDto
     */
    'status': ActualImportEventDtoStatusEnum;
}

export const ActualImportEventDtoStatusEnum = {
    Running: 'RUNNING',
    Completed: 'COMPLETED',
    Error: 'ERROR',
    MasterNotCreatedError: 'MASTER_NOT_CREATED_ERROR',
    Deleting: 'DELETING'
} as const;

export type ActualImportEventDtoStatusEnum = typeof ActualImportEventDtoStatusEnum[keyof typeof ActualImportEventDtoStatusEnum];

/**
 * 
 * @export
 * @interface ActualImportEventView
 */
export interface ActualImportEventView {
    /**
     * 
     * @type {string}
     * @memberof ActualImportEventView
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ActualImportMapping
 */
export interface ActualImportMapping {
    /**
     * 
     * @type {Array<ActualImportMappingFilter>}
     * @memberof ActualImportMapping
     */
    'actualImportMappingColumnFilters': Array<ActualImportMappingFilter>;
    /**
     * 
     * @type {Array<ActualImportMappingDebitCreditCondition>}
     * @memberof ActualImportMapping
     */
    'actualImportMappingDebitCreditConditions': Array<ActualImportMappingDebitCreditCondition>;
    /**
     * 
     * @type {Array<ActualImportMappingItem>}
     * @memberof ActualImportMapping
     */
    'actualImportMappingItems': Array<ActualImportMappingItem>;
    /**
     * 
     * @type {Array<ActualImportMappingFilter>}
     * @memberof ActualImportMapping
     */
    'actualImportMappingRowFilters': Array<ActualImportMappingFilter>;
    /**
     * 
     * @type {Array<ActualImportMappingSubsidiaryCode>}
     * @memberof ActualImportMapping
     */
    'actualImportMappingSubsidiaryCodes': Array<ActualImportMappingSubsidiaryCode>;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMapping
     */
    'actualImportPattern': ActualImportMappingActualImportPatternEnum;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMapping
     */
    'amountDataDirection': ActualImportMappingAmountDataDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMapping
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof ActualImportMapping
     */
    'headerRowIndex': number;
    /**
     * 
     * @type {IDActualImportMapping}
     * @memberof ActualImportMapping
     */
    'id': IDActualImportMapping;
    /**
     * 
     * @type {boolean}
     * @memberof ActualImportMapping
     */
    'isFindByNameForExistingAccount': boolean;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMapping
     */
    'journalOrBalance': ActualImportMappingJournalOrBalanceEnum;
    /**
     * 
     * @type {Name}
     * @memberof ActualImportMapping
     */
    'name': Name;
    /**
     * 
     * @type {Array<IDProcessSetting>}
     * @memberof ActualImportMapping
     */
    'processSettingIds': Array<IDProcessSetting>;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMapping
     */
    'repeatColumnHeaderType'?: ActualImportMappingRepeatColumnHeaderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMapping
     */
    'updatedAt': string;
}

export const ActualImportMappingActualImportPatternEnum = {
    Divide: 'DIVIDE',
    Combine: 'COMBINE',
    AccountType: 'ACCOUNT_TYPE'
} as const;

export type ActualImportMappingActualImportPatternEnum = typeof ActualImportMappingActualImportPatternEnum[keyof typeof ActualImportMappingActualImportPatternEnum];
export const ActualImportMappingAmountDataDirectionEnum = {
    RowColumn: 'ROW_COLUMN',
    Column: 'COLUMN'
} as const;

export type ActualImportMappingAmountDataDirectionEnum = typeof ActualImportMappingAmountDataDirectionEnum[keyof typeof ActualImportMappingAmountDataDirectionEnum];
export const ActualImportMappingJournalOrBalanceEnum = {
    Journal: 'JOURNAL',
    Balance: 'BALANCE'
} as const;

export type ActualImportMappingJournalOrBalanceEnum = typeof ActualImportMappingJournalOrBalanceEnum[keyof typeof ActualImportMappingJournalOrBalanceEnum];
export const ActualImportMappingRepeatColumnHeaderTypeEnum = {
    Date: 'DATE',
    Account: 'ACCOUNT',
    Department: 'DEPARTMENT'
} as const;

export type ActualImportMappingRepeatColumnHeaderTypeEnum = typeof ActualImportMappingRepeatColumnHeaderTypeEnum[keyof typeof ActualImportMappingRepeatColumnHeaderTypeEnum];

/**
 * 
 * @export
 * @interface ActualImportMappingDebitCreditCondition
 */
export interface ActualImportMappingDebitCreditCondition {
    /**
     * 
     * @type {number}
     * @memberof ActualImportMappingDebitCreditCondition
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMappingDebitCreditCondition
     */
    'conditionType': ActualImportMappingDebitCreditConditionConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMappingDebitCreditCondition
     */
    'conditionValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMappingDebitCreditCondition
     */
    'debitCredit': ActualImportMappingDebitCreditConditionDebitCreditEnum;
    /**
     * 
     * @type {IDActualImportMappingDebitCreditCondition}
     * @memberof ActualImportMappingDebitCreditCondition
     */
    'id': IDActualImportMappingDebitCreditCondition;
}

export const ActualImportMappingDebitCreditConditionConditionTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Entered: 'ENTERED',
    NotEntered: 'NOT_ENTERED'
} as const;

export type ActualImportMappingDebitCreditConditionConditionTypeEnum = typeof ActualImportMappingDebitCreditConditionConditionTypeEnum[keyof typeof ActualImportMappingDebitCreditConditionConditionTypeEnum];
export const ActualImportMappingDebitCreditConditionDebitCreditEnum = {
    Debit: 'DEBIT',
    Credit: 'CREDIT'
} as const;

export type ActualImportMappingDebitCreditConditionDebitCreditEnum = typeof ActualImportMappingDebitCreditConditionDebitCreditEnum[keyof typeof ActualImportMappingDebitCreditConditionDebitCreditEnum];

/**
 * 
 * @export
 * @interface ActualImportMappingFilter
 */
export interface ActualImportMappingFilter {
    /**
     * 
     * @type {number}
     * @memberof ActualImportMappingFilter
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMappingFilter
     */
    'conditionType': ActualImportMappingFilterConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMappingFilter
     */
    'conditionValue'?: string;
    /**
     * 
     * @type {IDActualImportMappingFilter}
     * @memberof ActualImportMappingFilter
     */
    'id': IDActualImportMappingFilter;
    /**
     * 
     * @type {boolean}
     * @memberof ActualImportMappingFilter
     */
    'isColumnFilter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActualImportMappingFilter
     */
    'isRowFilter': boolean;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMappingFilter
     */
    'targetType': ActualImportMappingFilterTargetTypeEnum;
}

export const ActualImportMappingFilterConditionTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Entered: 'ENTERED',
    NotEntered: 'NOT_ENTERED'
} as const;

export type ActualImportMappingFilterConditionTypeEnum = typeof ActualImportMappingFilterConditionTypeEnum[keyof typeof ActualImportMappingFilterConditionTypeEnum];
export const ActualImportMappingFilterTargetTypeEnum = {
    Row: 'ROW',
    Column: 'COLUMN'
} as const;

export type ActualImportMappingFilterTargetTypeEnum = typeof ActualImportMappingFilterTargetTypeEnum[keyof typeof ActualImportMappingFilterTargetTypeEnum];

/**
 * 
 * @export
 * @interface ActualImportMappingItem
 */
export interface ActualImportMappingItem {
    /**
     * 
     * @type {number}
     * @memberof ActualImportMappingItem
     */
    'columnIndex': number;
    /**
     * 
     * @type {IDDimension}
     * @memberof ActualImportMappingItem
     */
    'dimensionId'?: IDDimension;
    /**
     * 
     * @type {IDActualImportMappingItem}
     * @memberof ActualImportMappingItem
     */
    'id': IDActualImportMappingItem;
    /**
     * 
     * @type {string}
     * @memberof ActualImportMappingItem
     */
    'type': ActualImportMappingItemTypeEnum;
}

export const ActualImportMappingItemTypeEnum = {
    Date: 'DATE',
    DebitAmount: 'DEBIT_AMOUNT',
    CreditAmount: 'CREDIT_AMOUNT',
    DebitAccountName: 'DEBIT_ACCOUNT_NAME',
    CreditAccountName: 'CREDIT_ACCOUNT_NAME',
    DocumentId: 'DOCUMENT_ID',
    Description: 'DESCRIPTION',
    DebitAccountExCode: 'DEBIT_ACCOUNT_EX_CODE',
    CreditAccountExCode: 'CREDIT_ACCOUNT_EX_CODE',
    DebitDepartmentName: 'DEBIT_DEPARTMENT_NAME',
    CreditDepartmentName: 'CREDIT_DEPARTMENT_NAME',
    DebitDepartmentExCode: 'DEBIT_DEPARTMENT_EX_CODE',
    CreditDepartmentExCode: 'CREDIT_DEPARTMENT_EX_CODE',
    DebitSubAccountName: 'DEBIT_SUB_ACCOUNT_NAME',
    CreditSubAccountName: 'CREDIT_SUB_ACCOUNT_NAME',
    DebitSubAccountExCode: 'DEBIT_SUB_ACCOUNT_EX_CODE',
    CreditSubAccountExCode: 'CREDIT_SUB_ACCOUNT_EX_CODE',
    DebitSupplierName: 'DEBIT_SUPPLIER_NAME',
    CreditSupplierName: 'CREDIT_SUPPLIER_NAME',
    DebitSupplierExCode: 'DEBIT_SUPPLIER_EX_CODE',
    CreditSupplierExCode: 'CREDIT_SUPPLIER_EX_CODE',
    DebitDimensionName: 'DEBIT_DIMENSION_NAME',
    CreditDimensionName: 'CREDIT_DIMENSION_NAME',
    Amount: 'AMOUNT',
    AccountName: 'ACCOUNT_NAME',
    AccountExCode: 'ACCOUNT_EX_CODE',
    DepartmentName: 'DEPARTMENT_NAME',
    DepartmentExCode: 'DEPARTMENT_EX_CODE',
    SubAccountName: 'SUB_ACCOUNT_NAME',
    SubAccountExCode: 'SUB_ACCOUNT_EX_CODE',
    SupplierName: 'SUPPLIER_NAME',
    SupplierExCode: 'SUPPLIER_EX_CODE',
    DimensionName: 'DIMENSION_NAME'
} as const;

export type ActualImportMappingItemTypeEnum = typeof ActualImportMappingItemTypeEnum[keyof typeof ActualImportMappingItemTypeEnum];

/**
 * 
 * @export
 * @interface ActualImportMappingSubsidiaryCode
 */
export interface ActualImportMappingSubsidiaryCode {
    /**
     * 
     * @type {string}
     * @memberof ActualImportMappingSubsidiaryCode
     */
    'code': string;
    /**
     * 
     * @type {IDActualImportMappingSubsidiaryCode}
     * @memberof ActualImportMappingSubsidiaryCode
     */
    'id': IDActualImportMappingSubsidiaryCode;
    /**
     * 
     * @type {Name}
     * @memberof ActualImportMappingSubsidiaryCode
     */
    'name': Name;
}
/**
 * 
 * @export
 * @interface ActualPlanSelect
 */
export interface ActualPlanSelect {
    /**
     * 
     * @type {Array<string>}
     * @memberof ActualPlanSelect
     */
    'planIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActualPlanSelect
     */
    'planningProjectIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ActualPlanSelect
     */
    'requireActual': boolean;
}
/**
 * 
 * @export
 * @interface ActualsImportMappingView
 */
export interface ActualsImportMappingView {
    /**
     * 
     * @type {Array<ActualImportMappingFilter>}
     * @memberof ActualsImportMappingView
     */
    'actualImportMappingColumnFilters': Array<ActualImportMappingFilter>;
    /**
     * 
     * @type {Array<ActualImportMappingItem>}
     * @memberof ActualsImportMappingView
     */
    'actualImportMappingItems': Array<ActualImportMappingItem>;
    /**
     * 
     * @type {Array<ActualImportMappingFilter>}
     * @memberof ActualsImportMappingView
     */
    'actualImportMappingRowFilters': Array<ActualImportMappingFilter>;
    /**
     * 
     * @type {Array<ActualImportMappingSubsidiaryCode>}
     * @memberof ActualsImportMappingView
     */
    'actualImportMappingSubsidiaryCodes': Array<ActualImportMappingSubsidiaryCode>;
    /**
     * 
     * @type {string}
     * @memberof ActualsImportMappingView
     */
    'amountDataDirection': ActualsImportMappingViewAmountDataDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof ActualsImportMappingView
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof ActualsImportMappingView
     */
    'headerRowIndex': number;
    /**
     * 
     * @type {IDActualImportMapping}
     * @memberof ActualsImportMappingView
     */
    'id': IDActualImportMapping;
    /**
     * 
     * @type {string}
     * @memberof ActualsImportMappingView
     */
    'name': string;
    /**
     * 
     * @type {Array<IDProcessSetting>}
     * @memberof ActualsImportMappingView
     */
    'processSettingIds': Array<IDProcessSetting>;
    /**
     * 
     * @type {string}
     * @memberof ActualsImportMappingView
     */
    'repeatColumnHeaderType'?: ActualsImportMappingViewRepeatColumnHeaderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActualsImportMappingView
     */
    'updatedAt': string;
}

export const ActualsImportMappingViewAmountDataDirectionEnum = {
    RowColumn: 'ROW_COLUMN',
    Column: 'COLUMN'
} as const;

export type ActualsImportMappingViewAmountDataDirectionEnum = typeof ActualsImportMappingViewAmountDataDirectionEnum[keyof typeof ActualsImportMappingViewAmountDataDirectionEnum];
export const ActualsImportMappingViewRepeatColumnHeaderTypeEnum = {
    Date: 'DATE',
    Account: 'ACCOUNT',
    Department: 'DEPARTMENT'
} as const;

export type ActualsImportMappingViewRepeatColumnHeaderTypeEnum = typeof ActualsImportMappingViewRepeatColumnHeaderTypeEnum[keyof typeof ActualsImportMappingViewRepeatColumnHeaderTypeEnum];

/**
 * 
 * @export
 * @interface AddBSAccountRequestBody
 */
export interface AddBSAccountRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AddBSAccountRequestBody
     */
    'parentAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddBSAccountRequestBody
     */
    'targetAccountId': string;
}
/**
 * 
 * @export
 * @interface AddDimensionTreeNodeRequestBody
 */
export interface AddDimensionTreeNodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AddDimensionTreeNodeRequestBody
     */
    'parentDimensionTagId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddDimensionTreeNodeRequestBody
     */
    'targetDimensionTagId': string;
}
/**
 * 
 * @export
 * @interface AddNodeToNonFinancialAccountTreeBody
 */
export interface AddNodeToNonFinancialAccountTreeBody {
    /**
     * 
     * @type {NonFinancialAccountTreeNodeBody}
     * @memberof AddNodeToNonFinancialAccountTreeBody
     */
    'parentNode'?: NonFinancialAccountTreeNodeBody;
    /**
     * 
     * @type {NonFinancialAccountTreeNodeBody}
     * @memberof AddNodeToNonFinancialAccountTreeBody
     */
    'targetNode': NonFinancialAccountTreeNodeBody;
}
/**
 * 
 * @export
 * @interface AiPlanActualAnalysisConditionParam
 */
export interface AiPlanActualAnalysisConditionParam {
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'factorAnalysisType1': AiPlanActualAnalysisConditionParamFactorAnalysisType1Enum;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'orgTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'reportName': string;
    /**
     * 
     * @type {boolean}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'requireAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'showNumbersIn': AiPlanActualAnalysisConditionParamShowNumbersInEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionParam
     */
    'targetYearMonth': string;
}

export const AiPlanActualAnalysisConditionParamFactorAnalysisType1Enum = {
    Department: 'DEPARTMENT',
    Account: 'ACCOUNT'
} as const;

export type AiPlanActualAnalysisConditionParamFactorAnalysisType1Enum = typeof AiPlanActualAnalysisConditionParamFactorAnalysisType1Enum[keyof typeof AiPlanActualAnalysisConditionParamFactorAnalysisType1Enum];
export const AiPlanActualAnalysisConditionParamShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type AiPlanActualAnalysisConditionParamShowNumbersInEnum = typeof AiPlanActualAnalysisConditionParamShowNumbersInEnum[keyof typeof AiPlanActualAnalysisConditionParamShowNumbersInEnum];

/**
 * 
 * @export
 * @interface AiPlanActualAnalysisConditionView
 */
export interface AiPlanActualAnalysisConditionView {
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionView
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionView
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionView
     */
    'analyticsType1'?: AiPlanActualAnalysisConditionViewAnalyticsType1Enum;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionView
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionView
     */
    'orgTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionView
     */
    'planMasterId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AiPlanActualAnalysisConditionView
     */
    'requireAllocatedItem': boolean;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionView
     */
    'showNumbersIn': AiPlanActualAnalysisConditionViewShowNumbersInEnum;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisConditionView
     */
    'targetYearMonth': string;
}

export const AiPlanActualAnalysisConditionViewAnalyticsType1Enum = {
    Department: 'DEPARTMENT',
    Account: 'ACCOUNT'
} as const;

export type AiPlanActualAnalysisConditionViewAnalyticsType1Enum = typeof AiPlanActualAnalysisConditionViewAnalyticsType1Enum[keyof typeof AiPlanActualAnalysisConditionViewAnalyticsType1Enum];
export const AiPlanActualAnalysisConditionViewShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type AiPlanActualAnalysisConditionViewShowNumbersInEnum = typeof AiPlanActualAnalysisConditionViewShowNumbersInEnum[keyof typeof AiPlanActualAnalysisConditionViewShowNumbersInEnum];

/**
 * 
 * @export
 * @interface AiPlanActualAnalysisDisplayConditionView
 */
export interface AiPlanActualAnalysisDisplayConditionView {
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisDisplayConditionView
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisDisplayConditionView
     */
    'accountTreeName': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisDisplayConditionView
     */
    'analyticsType1': AiPlanActualAnalysisDisplayConditionViewAnalyticsType1Enum;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisDisplayConditionView
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisDisplayConditionView
     */
    'orgTreeName': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisDisplayConditionView
     */
    'planMasterName': string;
    /**
     * 
     * @type {boolean}
     * @memberof AiPlanActualAnalysisDisplayConditionView
     */
    'requireAllocatedItem': boolean;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisDisplayConditionView
     */
    'showNumbersIn': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisDisplayConditionView
     */
    'targetYearMonth': string;
}

export const AiPlanActualAnalysisDisplayConditionViewAnalyticsType1Enum = {
    Department: 'DEPARTMENT',
    Account: 'ACCOUNT'
} as const;

export type AiPlanActualAnalysisDisplayConditionViewAnalyticsType1Enum = typeof AiPlanActualAnalysisDisplayConditionViewAnalyticsType1Enum[keyof typeof AiPlanActualAnalysisDisplayConditionViewAnalyticsType1Enum];

/**
 * 
 * @export
 * @interface AiPlanActualAnalysisMonthlyView
 */
export interface AiPlanActualAnalysisMonthlyView {
    /**
     * 
     * @type {PlanVsActualDataView}
     * @memberof AiPlanActualAnalysisMonthlyView
     */
    'cumulativePlanVsActual': PlanVsActualDataView;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisMonthlyView
     */
    'lastMonthActual'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisMonthlyView
     */
    'lastMonthActualDiffRatio'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisMonthlyView
     */
    'lastYearSameMonthActual'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisMonthlyView
     */
    'lastYearSameMonthActualDiffRatio'?: string;
    /**
     * 
     * @type {PlanVsActualDataView}
     * @memberof AiPlanActualAnalysisMonthlyView
     */
    'monthlyPlanVsActual': PlanVsActualDataView;
}
/**
 * 
 * @export
 * @interface AiPlanActualAnalysisReportConditionView
 */
export interface AiPlanActualAnalysisReportConditionView {
    /**
     * 
     * @type {AiPlanActualAnalysisConditionView}
     * @memberof AiPlanActualAnalysisReportConditionView
     */
    'condition': AiPlanActualAnalysisConditionView;
    /**
     * 
     * @type {Array<AiPlanActualReportConditionTreeNodeView>}
     * @memberof AiPlanActualAnalysisReportConditionView
     */
    'conditionTree': Array<AiPlanActualReportConditionTreeNodeView>;
    /**
     * 
     * @type {AiPlanActualAnalysisDisplayConditionView}
     * @memberof AiPlanActualAnalysisReportConditionView
     */
    'displayCondition': AiPlanActualAnalysisDisplayConditionView;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisReportConditionView
     */
    'reportName': string;
}
/**
 * 
 * @export
 * @interface AiPlanActualAnalysisTimeSeriesView
 */
export interface AiPlanActualAnalysisTimeSeriesView {
    /**
     * 
     * @type {Array<MonthlyAnalysisTimeSeriesUnitView>}
     * @memberof AiPlanActualAnalysisTimeSeriesView
     */
    'data': Array<MonthlyAnalysisTimeSeriesUnitView>;
}
/**
 * 
 * @export
 * @interface AiPlanActualAnalysisView
 */
export interface AiPlanActualAnalysisView {
    /**
     * 
     * @type {AiPlanActualAnalysisMonthlyView}
     * @memberof AiPlanActualAnalysisView
     */
    'aiPlanActualAnalysisMonthlyData': AiPlanActualAnalysisMonthlyView;
    /**
     * 
     * @type {AiPlanActualAnalysisTimeSeriesView}
     * @memberof AiPlanActualAnalysisView
     */
    'aiPlanActualAnalysisTimeSeriesView': AiPlanActualAnalysisTimeSeriesView;
    /**
     * 
     * @type {AiPlanActualAnalysisConditionView}
     * @memberof AiPlanActualAnalysisView
     */
    'condition': AiPlanActualAnalysisConditionView;
    /**
     * 
     * @type {AiPlanActualAnalysisDisplayConditionView}
     * @memberof AiPlanActualAnalysisView
     */
    'displayCondition': AiPlanActualAnalysisDisplayConditionView;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisView
     */
    'factorAnalysisCommentTaskId': string;
    /**
     * 
     * @type {FactorAnalysisDetailView}
     * @memberof AiPlanActualAnalysisView
     */
    'factorAnalysisDetail': FactorAnalysisDetailView;
    /**
     * 
     * @type {FactorAnalysisOptions}
     * @memberof AiPlanActualAnalysisView
     */
    'factoryAnalysisOptions': FactorAnalysisOptions;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisView
     */
    'monthlyAnalysisCommentTaskId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisView
     */
    'reportName': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualAnalysisView
     */
    'yoySummaryTaskId': string;
}
/**
 * 
 * @export
 * @interface AiPlanActualReportConditionTreeNodeView
 */
export interface AiPlanActualReportConditionTreeNodeView {
    /**
     * 
     * @type {AiPlanActualAnalysisConditionView}
     * @memberof AiPlanActualReportConditionTreeNodeView
     */
    'condition': AiPlanActualAnalysisConditionView;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualReportConditionTreeNodeView
     */
    'nodeId': string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualReportConditionTreeNodeView
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiPlanActualReportConditionTreeNodeView
     */
    'tabName': string;
}
/**
 * 
 * @export
 * @interface AllocationAccountDto
 */
export interface AllocationAccountDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationAccountDto
     */
    'accountType': AllocationAccountDtoAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AllocationAccountDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationAccountDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationAccountDto
     */
    'name': string;
}

export const AllocationAccountDtoAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type AllocationAccountDtoAccountTypeEnum = typeof AllocationAccountDtoAccountTypeEnum[keyof typeof AllocationAccountDtoAccountTypeEnum];

/**
 * 
 * @export
 * @interface AllocationDepartmentDto
 */
export interface AllocationDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationDepartmentDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationDepartmentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationDepartmentDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AllocationDimensionDto
 */
export interface AllocationDimensionDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationDimensionDto
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationDimensionDto
     */
    'dimensionName': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationDimensionDto
     */
    'dimensionNumber': number;
}
/**
 * 
 * @export
 * @interface AllocationDimensionTagDto
 */
export interface AllocationDimensionTagDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationDimensionTagDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationDimensionTagDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationDimensionTagDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AllocationRuleDetailSummary
 */
export interface AllocationRuleDetailSummary {
    /**
     * 
     * @type {string}
     * @memberof AllocationRuleDetailSummary
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationRuleDetailSummary
     */
    'name': string;
    /**
     * 
     * @type {Array<AllocationRuleReferencingDataSource>}
     * @memberof AllocationRuleDetailSummary
     */
    'referencingDataSourceList': Array<AllocationRuleReferencingDataSource>;
    /**
     * 
     * @type {Array<AllocationTransferStepListItem>}
     * @memberof AllocationRuleDetailSummary
     */
    'steps': Array<AllocationTransferStepListItem>;
}
/**
 * 
 * @export
 * @interface AllocationRuleReferencingDataSource
 */
export interface AllocationRuleReferencingDataSource {
    /**
     * 
     * @type {string}
     * @memberof AllocationRuleReferencingDataSource
     */
    'dataSourceName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllocationRuleReferencingDataSource
     */
    'yearMonthList': Array<string>;
}
/**
 * 
 * @export
 * @interface AllocationRuleSummaryDto
 */
export interface AllocationRuleSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationRuleSummaryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationRuleSummaryDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllocationRuleSummaryDto
     */
    'stepIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AllocationStandardDto
 */
export interface AllocationStandardDto {
    /**
     * 
     * @type {AllocationStandardReferenceDto}
     * @memberof AllocationStandardDto
     */
    'allocationStandardReference'?: AllocationStandardReferenceDto;
    /**
     * 
     * @type {AllocationStandardUpdatedDto}
     * @memberof AllocationStandardDto
     */
    'allocationStandardUpdated'?: AllocationStandardUpdatedDto;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardDto
     */
    'dimensionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardDto
     */
    'dimensionName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationStandardDto
     */
    'hasReferencePlanOrActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationStandardDto
     */
    'isDimensionEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardDto
     */
    'name': string;
    /**
     * 
     * @type {Array<AllocationYearMonthStandardDto>}
     * @memberof AllocationStandardDto
     */
    'yearMonthStandards': Array<AllocationYearMonthStandardDto>;
}
/**
 * 
 * @export
 * @interface AllocationStandardLastUpdatedUserDto
 */
export interface AllocationStandardLastUpdatedUserDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardLastUpdatedUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardLastUpdatedUserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardLastUpdatedUserDto
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface AllocationStandardReferenceAccountDto
 */
export interface AllocationStandardReferenceAccountDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardReferenceAccountDto
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardReferenceAccountDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardReferenceAccountDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AllocationStandardReferenceDto
 */
export interface AllocationStandardReferenceDto {
    /**
     * 
     * @type {Array<AllocationStandardReferenceAccountDto>}
     * @memberof AllocationStandardReferenceDto
     */
    'accounts': Array<AllocationStandardReferenceAccountDto>;
    /**
     * 
     * @type {AllocationStandardReferencePlanMasterDto}
     * @memberof AllocationStandardReferenceDto
     */
    'planMaster'?: AllocationStandardReferencePlanMasterDto;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardReferenceDto
     */
    'referenceValueType': string;
}
/**
 * 
 * @export
 * @interface AllocationStandardReferencePlanMasterDto
 */
export interface AllocationStandardReferencePlanMasterDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardReferencePlanMasterDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardReferencePlanMasterDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AllocationStandardSummaryDto
 */
export interface AllocationStandardSummaryDto {
    /**
     * 
     * @type {Array<AllocationDepartmentDto>}
     * @memberof AllocationStandardSummaryDto
     */
    'destinationDepartments': Array<AllocationDepartmentDto>;
    /**
     * 
     * @type {AllocationDimensionDto}
     * @memberof AllocationStandardSummaryDto
     */
    'destinationDimension'?: AllocationDimensionDto;
    /**
     * 
     * @type {Array<AllocationDimensionTagDto>}
     * @memberof AllocationStandardSummaryDto
     */
    'destinationDimensionTags'?: Array<AllocationDimensionTagDto>;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardSummaryDto
     */
    'endYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardSummaryDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationStandardSummaryDto
     */
    'isDimensionEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardSummaryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardSummaryDto
     */
    'startYearMonth'?: string;
}
/**
 * 
 * @export
 * @interface AllocationStandardUpdatedDto
 */
export interface AllocationStandardUpdatedDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardUpdatedDto
     */
    'lastUpdatedAt': string;
    /**
     * 
     * @type {AllocationStandardLastUpdatedUserDto}
     * @memberof AllocationStandardUpdatedDto
     */
    'lastUpdatedUser': AllocationStandardLastUpdatedUserDto;
}
/**
 * 
 * @export
 * @interface AllocationStandardWeightDto
 */
export interface AllocationStandardWeightDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardWeightDto
     */
    'departmentCode': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardWeightDto
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardWeightDto
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardWeightDto
     */
    'dimensionTagCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardWeightDto
     */
    'dimensionTagId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStandardWeightDto
     */
    'dimensionTagName'?: string;
    /**
     * 
     * @type {number}
     * @memberof AllocationStandardWeightDto
     */
    'percentage': number;
    /**
     * 
     * @type {number}
     * @memberof AllocationStandardWeightDto
     */
    'weight': number;
}
/**
 * 
 * @export
 * @interface AllocationStepView
 */
export interface AllocationStepView {
    /**
     * 
     * @type {string}
     * @memberof AllocationStepView
     */
    'allocationStandardId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStepView
     */
    'allocationStandardName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStepView
     */
    'description'?: string;
    /**
     * 
     * @type {AllocationAccountDto}
     * @memberof AllocationStepView
     */
    'destinationAccount'?: AllocationAccountDto;
    /**
     * 
     * @type {Array<AllocationDepartmentDto>}
     * @memberof AllocationStepView
     */
    'destinationDepartments': Array<AllocationDepartmentDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllocationStepView
     */
    'destinationDimensionTagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AllocationStepView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStepView
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationStepView
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof AllocationStepView
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStepView
     */
    'ruleName': string;
    /**
     * 
     * @type {Array<AllocationAccountDto>}
     * @memberof AllocationStepView
     */
    'sourceAccounts': Array<AllocationAccountDto>;
    /**
     * 
     * @type {Array<AllocationDepartmentDto>}
     * @memberof AllocationStepView
     */
    'sourceDepartments': Array<AllocationDepartmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AllocationStepView
     */
    'sourceDimensionId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllocationStepView
     */
    'sourceDimensionTagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AllocationStepView
     */
    'stepType': AllocationStepViewStepTypeEnum;
}

export const AllocationStepViewStepTypeEnum = {
    Allocation: 'ALLOCATION',
    Transfer: 'TRANSFER'
} as const;

export type AllocationStepViewStepTypeEnum = typeof AllocationStepViewStepTypeEnum[keyof typeof AllocationStepViewStepTypeEnum];

/**
 * 
 * @export
 * @interface AllocationStrategyDto
 */
export interface AllocationStrategyDto {
    /**
     * 
     * @type {Array<LastAllocationResultDto>}
     * @memberof AllocationStrategyDto
     */
    'eachMonthResults': Array<LastAllocationResultDto>;
    /**
     * 
     * @type {string}
     * @memberof AllocationStrategyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStrategyDto
     */
    'planId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStrategyDto
     */
    'targetType': AllocationStrategyDtoTargetTypeEnum;
}

export const AllocationStrategyDtoTargetTypeEnum = {
    Actual: 'ACTUAL',
    Plan: 'PLAN'
} as const;

export type AllocationStrategyDtoTargetTypeEnum = typeof AllocationStrategyDtoTargetTypeEnum[keyof typeof AllocationStrategyDtoTargetTypeEnum];

/**
 * 
 * @export
 * @interface AllocationStrategySummaryDto
 */
export interface AllocationStrategySummaryDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationStrategySummaryDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStrategySummaryDto
     */
    'planId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStrategySummaryDto
     */
    'targetType': AllocationStrategySummaryDtoTargetTypeEnum;
}

export const AllocationStrategySummaryDtoTargetTypeEnum = {
    Actual: 'ACTUAL',
    Plan: 'PLAN'
} as const;

export type AllocationStrategySummaryDtoTargetTypeEnum = typeof AllocationStrategySummaryDtoTargetTypeEnum[keyof typeof AllocationStrategySummaryDtoTargetTypeEnum];

/**
 * 
 * @export
 * @interface AllocationTransferStepListItem
 */
export interface AllocationTransferStepListItem {
    /**
     * 
     * @type {string}
     * @memberof AllocationTransferStepListItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationTransferStepListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationTransferStepListItem
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationTransferStepListItem
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof AllocationTransferStepListItem
     */
    'standardName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AllocationTransferStepListItem
     */
    'stepType': AllocationTransferStepListItemStepTypeEnum;
}

export const AllocationTransferStepListItemStepTypeEnum = {
    Allocation: 'ALLOCATION',
    Transfer: 'TRANSFER'
} as const;

export type AllocationTransferStepListItemStepTypeEnum = typeof AllocationTransferStepListItemStepTypeEnum[keyof typeof AllocationTransferStepListItemStepTypeEnum];

/**
 * 
 * @export
 * @interface AllocationYearMonthStandardDto
 */
export interface AllocationYearMonthStandardDto {
    /**
     * 
     * @type {Array<AllocationStandardWeightDto>}
     * @memberof AllocationYearMonthStandardDto
     */
    'allocationStandardWeights': Array<AllocationStandardWeightDto>;
    /**
     * 
     * @type {number}
     * @memberof AllocationYearMonthStandardDto
     */
    'sumOfWeight': number;
    /**
     * 
     * @type {string}
     * @memberof AllocationYearMonthStandardDto
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * 
     * @type {boolean}
     * @memberof Amount
     */
    'isDecimalValue$loglass_domain': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Amount
     */
    'isZero': boolean;
    /**
     * 
     * @type {number}
     * @memberof Amount
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface AmountErrorView
 */
export interface AmountErrorView {
    /**
     * 
     * @type {string}
     * @memberof AmountErrorView
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof AmountErrorView
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface AnalysisAssistantDataLimitCheckRequestBody
 */
export interface AnalysisAssistantDataLimitCheckRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantDataLimitCheckRequestBody
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantDataLimitCheckRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantDataLimitCheckRequestBody
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantDataLimitCheckRequestBody
     */
    'organizationTreeId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AnalysisAssistantDataLimitCheckRequestBody
     */
    'requireAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantDataLimitCheckRequestBody
     */
    'rowType': AnalysisAssistantDataLimitCheckRequestBodyRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantDataLimitCheckRequestBody
     */
    'targetYearMonth': string;
}

export const AnalysisAssistantDataLimitCheckRequestBodyRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type AnalysisAssistantDataLimitCheckRequestBodyRowTypeEnum = typeof AnalysisAssistantDataLimitCheckRequestBodyRowTypeEnum[keyof typeof AnalysisAssistantDataLimitCheckRequestBodyRowTypeEnum];

/**
 * 
 * @export
 * @interface AnalysisAssistantDataLimitValidateResultView
 */
export interface AnalysisAssistantDataLimitValidateResultView {
    /**
     * 
     * @type {boolean}
     * @memberof AnalysisAssistantDataLimitValidateResultView
     */
    'canAccess': boolean;
    /**
     * 
     * @type {number}
     * @memberof AnalysisAssistantDataLimitValidateResultView
     */
    'itemsCount': number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisAssistantDataLimitValidateResultView
     */
    'maxNumber': number;
}
/**
 * 
 * @export
 * @interface AnalysisAssistantLayoutComponent
 */
export interface AnalysisAssistantLayoutComponent {
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantLayoutComponent
     */
    'key'?: AnalysisAssistantLayoutComponentKeyEnum;
    /**
     * 
     * @type {object}
     * @memberof AnalysisAssistantLayoutComponent
     */
    'properties': object;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantLayoutComponent
     */
    'type': AnalysisAssistantLayoutComponentTypeEnum;
}

export const AnalysisAssistantLayoutComponentKeyEnum = {
    MonthlyForecastAndActualTable: 'MONTHLY_FORECAST_AND_ACTUAL_TABLE',
    CumulativeForecastAndActualTable: 'CUMULATIVE_FORECAST_AND_ACTUAL_TABLE',
    ForecastAndActualSummaryByDepartmentTable: 'FORECAST_AND_ACTUAL_SUMMARY_BY_DEPARTMENT_TABLE',
    YearlyForecastAndActualCumulativeGraph: 'YEARLY_FORECAST_AND_ACTUAL_CUMULATIVE_GRAPH',
    ForecastAndActualStatusComment: 'FORECAST_AND_ACTUAL_STATUS_COMMENT',
    YearOnYearComparisonComment: 'YEAR_ON_YEAR_COMPARISON_COMMENT',
    ForecastAndActualSummaryByDepartmentComment: 'FORECAST_AND_ACTUAL_SUMMARY_BY_DEPARTMENT_COMMENT',
    DepartmentForecastAndActualComment: 'DEPARTMENT_FORECAST_AND_ACTUAL_COMMENT'
} as const;

export type AnalysisAssistantLayoutComponentKeyEnum = typeof AnalysisAssistantLayoutComponentKeyEnum[keyof typeof AnalysisAssistantLayoutComponentKeyEnum];
export const AnalysisAssistantLayoutComponentTypeEnum = {
    Markdown: 'MARKDOWN',
    Graph: 'GRAPH',
    Table: 'TABLE',
    SuggestActions: 'SUGGEST_ACTIONS',
    Tag: 'TAG',
    Button: 'BUTTON',
    AnalysisCondition: 'ANALYSIS_CONDITION'
} as const;

export type AnalysisAssistantLayoutComponentTypeEnum = typeof AnalysisAssistantLayoutComponentTypeEnum[keyof typeof AnalysisAssistantLayoutComponentTypeEnum];

/**
 * 
 * @export
 * @interface AnalysisAssistantSideKickRequestBody
 */
export interface AnalysisAssistantSideKickRequestBody {
    /**
     * 
     * @type {AnalysisConditionProperties}
     * @memberof AnalysisAssistantSideKickRequestBody
     */
    'analysisConditionProperties': AnalysisConditionProperties;
    /**
     * 
     * @type {Array<BarAndLineGraphData>}
     * @memberof AnalysisAssistantSideKickRequestBody
     */
    'barAndLineGraphData': Array<BarAndLineGraphData>;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantSideKickRequestBody
     */
    'instruction': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantSideKickRequestBody
     */
    'mentionedToolId'?: string;
    /**
     * 
     * @type {Array<TableProperties>}
     * @memberof AnalysisAssistantSideKickRequestBody
     */
    'tableProperties': Array<TableProperties>;
}
/**
 * 
 * @export
 * @interface AnalysisAssistantToolItem
 */
export interface AnalysisAssistantToolItem {
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantToolItem
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantToolItem
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisAssistantToolItem
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface AnalysisConditionProperties
 */
export interface AnalysisConditionProperties {
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'accountTreeName': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'departmentName': string;
    /**
     * 
     * @type {boolean}
     * @memberof AnalysisConditionProperties
     */
    'enableFinancialSheetComment': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'orgTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'orgTreeName': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'planMasterName': string;
    /**
     * 
     * @type {boolean}
     * @memberof AnalysisConditionProperties
     */
    'requireAllocatedItem': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnalysisConditionProperties
     */
    'targetYearMonth': string;
}
/**
 * 
 * @export
 * @interface AppliedNumberLimitsView
 */
export interface AppliedNumberLimitsView {
    /**
     * 
     * @type {Array<NumberLimitView>}
     * @memberof AppliedNumberLimitsView
     */
    'limits': Array<NumberLimitView>;
}
/**
 * 
 * @export
 * @interface ApproveErrorMessage
 */
export interface ApproveErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ApproveErrorMessage
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof ApproveErrorMessage
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof ApproveErrorMessage
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ApproveErrorMessage
     */
    'planUpdateRequestId': string;
}
/**
 * 
 * @export
 * @interface AssignUserDepartmentParam
 */
export interface AssignUserDepartmentParam {
    /**
     * 
     * @type {string}
     * @memberof AssignUserDepartmentParam
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface AssignedDepartmentView
 */
export interface AssignedDepartmentView {
    /**
     * 
     * @type {string}
     * @memberof AssignedDepartmentView
     */
    'departmentId': string;
    /**
     * 
     * @type {Array<OrgNodeLevelView>}
     * @memberof AssignedDepartmentView
     */
    'orgNodes': Array<OrgNodeLevelView>;
}
/**
 * 
 * @export
 * @interface AssignmentEachDepartmentDetailView
 */
export interface AssignmentEachDepartmentDetailView {
    /**
     * 
     * @type {string}
     * @memberof AssignmentEachDepartmentDetailView
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface AssignmentEachDepartmentView
 */
export interface AssignmentEachDepartmentView {
    /**
     * 
     * @type {Array<AssignmentEachDepartmentDetailView>}
     * @memberof AssignmentEachDepartmentView
     */
    'assignments': Array<AssignmentEachDepartmentDetailView>;
    /**
     * 
     * @type {string}
     * @memberof AssignmentEachDepartmentView
     */
    'departmentId': string;
}
/**
 * 
 * @export
 * @interface AvailableTenantView
 */
export interface AvailableTenantView {
    /**
     * 
     * @type {string}
     * @memberof AvailableTenantView
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTenantView
     */
    'tenantName': string;
}
/**
 * 
 * @export
 * @interface BSAccountFlatTreeView
 */
export interface BSAccountFlatTreeView {
    /**
     * 
     * @type {string}
     * @memberof BSAccountFlatTreeView
     */
    'id': string;
    /**
     * 
     * @type {Array<BSAccountTreeNodeView>}
     * @memberof BSAccountFlatTreeView
     */
    'items': Array<BSAccountTreeNodeView>;
}
/**
 * 
 * @export
 * @interface BSAccountTreeNameView
 */
export interface BSAccountTreeNameView {
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNameView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNameView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface BSAccountTreeNodeView
 */
export interface BSAccountTreeNodeView {
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNodeView
     */
    'accountType': BSAccountTreeNodeViewAccountTypeEnum;
    /**
     * 
     * @type {AccountingSystemCodePairView}
     * @memberof BSAccountTreeNodeView
     */
    'accountingSystemCodePair'?: AccountingSystemCodePairView;
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNodeView
     */
    'calculationType': BSAccountTreeNodeViewCalculationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNodeView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNodeView
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof BSAccountTreeNodeView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNodeView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNodeView
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNodeView
     */
    'rowType': BSAccountTreeNodeViewRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BSAccountTreeNodeView
     */
    'viewControlType': BSAccountTreeNodeViewViewControlTypeEnum;
}

export const BSAccountTreeNodeViewAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type BSAccountTreeNodeViewAccountTypeEnum = typeof BSAccountTreeNodeViewAccountTypeEnum[keyof typeof BSAccountTreeNodeViewAccountTypeEnum];
export const BSAccountTreeNodeViewCalculationTypeEnum = {
    Normal: 'NORMAL'
} as const;

export type BSAccountTreeNodeViewCalculationTypeEnum = typeof BSAccountTreeNodeViewCalculationTypeEnum[keyof typeof BSAccountTreeNodeViewCalculationTypeEnum];
export const BSAccountTreeNodeViewRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type BSAccountTreeNodeViewRowTypeEnum = typeof BSAccountTreeNodeViewRowTypeEnum[keyof typeof BSAccountTreeNodeViewRowTypeEnum];
export const BSAccountTreeNodeViewViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type BSAccountTreeNodeViewViewControlTypeEnum = typeof BSAccountTreeNodeViewViewControlTypeEnum[keyof typeof BSAccountTreeNodeViewViewControlTypeEnum];

/**
 * 
 * @export
 * @interface BSReportConditionSchema
 */
export interface BSReportConditionSchema {
    /**
     * 
     * @type {Array<CellGroupOrder>}
     * @memberof BSReportConditionSchema
     */
    'cellGroupOrders': Array<CellGroupOrder>;
    /**
     * 
     * @type {ColumnAggregationDimension}
     * @memberof BSReportConditionSchema
     */
    'columnAggregationDimension': ColumnAggregationDimension;
    /**
     * 
     * @type {Array<ComparisonCondition>}
     * @memberof BSReportConditionSchema
     */
    'comparisonConditions': Array<ComparisonCondition>;
    /**
     * 
     * @type {Array<DataCondition>}
     * @memberof BSReportConditionSchema
     */
    'dataConditions': Array<DataCondition>;
    /**
     * 
     * @type {boolean}
     * @memberof BSReportConditionSchema
     */
    'isShowEmptyRows': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BSReportConditionSchema
     */
    'isTreeView': boolean;
    /**
     * 
     * @type {Array<RowAggregationDimension>}
     * @memberof BSReportConditionSchema
     */
    'rowAggregationDimensions': Array<RowAggregationDimension>;
    /**
     * 
     * @type {boolean}
     * @memberof BSReportConditionSchema
     */
    'showNoneDimension': boolean;
    /**
     * 
     * @type {string}
     * @memberof BSReportConditionSchema
     */
    'showNumbersIn': BSReportConditionSchemaShowNumbersInEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BSReportConditionSchema
     */
    'showSumRow': boolean;
}

export const BSReportConditionSchemaShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type BSReportConditionSchemaShowNumbersInEnum = typeof BSReportConditionSchemaShowNumbersInEnum[keyof typeof BSReportConditionSchemaShowNumbersInEnum];

/**
 * 
 * @export
 * @interface BarAndLineGraphData
 */
export interface BarAndLineGraphData {
    /**
     * 
     * @type {Amount}
     * @memberof BarAndLineGraphData
     */
    'cumulativeActualUntilThisMonth'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof BarAndLineGraphData
     */
    'cumulativePlanUntilThisMonth'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof BarAndLineGraphData
     */
    'monthlyActual'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof BarAndLineGraphData
     */
    'monthlyPlan'?: Amount;
    /**
     * 
     * @type {string}
     * @memberof BarAndLineGraphData
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface BaseConditionRequestBody
 */
export interface BaseConditionRequestBody {
    /**
     * 
     * @type {MaybeOwnValueAccountId}
     * @memberof BaseConditionRequestBody
     */
    'accountId': MaybeOwnValueAccountId;
    /**
     * 
     * @type {string}
     * @memberof BaseConditionRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {MaybeOwnValueDepartmentId}
     * @memberof BaseConditionRequestBody
     */
    'departmentId': MaybeOwnValueDepartmentId;
    /**
     * 
     * @type {string}
     * @memberof BaseConditionRequestBody
     */
    'orgTreeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseConditionRequestBody
     */
    'requireAllocatedItem': boolean;
}
/**
 * 
 * @export
 * @interface BeforeConnectedWorkforceSlotView
 */
export interface BeforeConnectedWorkforceSlotView {
    /**
     * 
     * @type {string}
     * @memberof BeforeConnectedWorkforceSlotView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof BeforeConnectedWorkforceSlotView
     */
    'departmentName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeforeConnectedWorkforceSlotView
     */
    'dimensionTagNames': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BeforeConnectedWorkforceSlotView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BeforeConnectedWorkforceSlotView
     */
    'slotType': BeforeConnectedWorkforceSlotViewSlotTypeEnum;
    /**
     * 
     * @type {Array<RemainingCountByMonthView>}
     * @memberof BeforeConnectedWorkforceSlotView
     */
    'slotUsageByMonthViews': Array<RemainingCountByMonthView>;
    /**
     * 
     * @type {string}
     * @memberof BeforeConnectedWorkforceSlotView
     */
    'workforceSlotId': string;
}

export const BeforeConnectedWorkforceSlotViewSlotTypeEnum = {
    Hiring: 'HIRING',
    Resignation: 'RESIGNATION',
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    ReturnToWork: 'RETURN_TO_WORK'
} as const;

export type BeforeConnectedWorkforceSlotViewSlotTypeEnum = typeof BeforeConnectedWorkforceSlotViewSlotTypeEnum[keyof typeof BeforeConnectedWorkforceSlotViewSlotTypeEnum];

/**
 * 
 * @export
 * @interface BsNotIncludedAccountView
 */
export interface BsNotIncludedAccountView {
    /**
     * 
     * @type {AccountView}
     * @memberof BsNotIncludedAccountView
     */
    'accountView': AccountView;
    /**
     * 
     * @type {boolean}
     * @memberof BsNotIncludedAccountView
     */
    'isVisible': boolean;
}
/**
 * 
 * @export
 * @interface BsNotIncludedAccountsView
 */
export interface BsNotIncludedAccountsView {
    /**
     * 
     * @type {Array<BsNotIncludedAccountView>}
     * @memberof BsNotIncludedAccountsView
     */
    'listAccountView': Array<BsNotIncludedAccountView>;
}
/**
 * 
 * @export
 * @interface BulkPlanUpdateRequestBody
 */
export interface BulkPlanUpdateRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkPlanUpdateRequestBody
     */
    'planUpdateRequestIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BulkUpdateAccountTypeRequestBody
 */
export interface BulkUpdateAccountTypeRequestBody {
    /**
     * 
     * @type {Array<UpdateAccountTypeWithIdRequestBody>}
     * @memberof BulkUpdateAccountTypeRequestBody
     */
    'items': Array<UpdateAccountTypeWithIdRequestBody>;
}
/**
 * 
 * @export
 * @interface BulkUpdateRemainingCountsBody
 */
export interface BulkUpdateRemainingCountsBody {
    /**
     * 
     * @type {Array<RemainingCountUpdateTarget>}
     * @memberof BulkUpdateRemainingCountsBody
     */
    'updateTargets': Array<RemainingCountUpdateTarget>;
}
/**
 * 
 * @export
 * @interface CalculateActionParams
 */
export interface CalculateActionParams {
    /**
     * 
     * @type {string}
     * @memberof CalculateActionParams
     */
    'formula': string;
}
/**
 * 
 * @export
 * @interface CalculatedAccountView
 */
export interface CalculatedAccountView {
    /**
     * 
     * @type {string}
     * @memberof CalculatedAccountView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CalculatedAccountView
     */
    'formula': string;
    /**
     * 
     * @type {string}
     * @memberof CalculatedAccountView
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CalculatedAccountView
     */
    'isSelectable': boolean;
    /**
     * 
     * @type {string}
     * @memberof CalculatedAccountView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CalculatedAccountView
     */
    'roundingPrecision': CalculatedAccountViewRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof CalculatedAccountView
     */
    'valueFormat': CalculatedAccountViewValueFormatEnum;
}

export const CalculatedAccountViewRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type CalculatedAccountViewRoundingPrecisionEnum = typeof CalculatedAccountViewRoundingPrecisionEnum[keyof typeof CalculatedAccountViewRoundingPrecisionEnum];
export const CalculatedAccountViewValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type CalculatedAccountViewValueFormatEnum = typeof CalculatedAccountViewValueFormatEnum[keyof typeof CalculatedAccountViewValueFormatEnum];

/**
 * 
 * @export
 * @interface CalculatedAccountsView
 */
export interface CalculatedAccountsView {
    /**
     * 
     * @type {Array<CalculatedAccountView>}
     * @memberof CalculatedAccountsView
     */
    'items': Array<CalculatedAccountView>;
}
/**
 * 
 * @export
 * @interface CellDimensionCondition
 */
export interface CellDimensionCondition {
    /**
     * 
     * @type {string}
     * @memberof CellDimensionCondition
     */
    'dimensionTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CellDimensionCondition
     */
    'dimensionType': CellDimensionConditionDimensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CellDimensionCondition
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CellDimensionCondition
     */
    'periodAggregation'?: CellDimensionConditionPeriodAggregationEnum;
}

export const CellDimensionConditionDimensionTypeEnum = {
    Account: 'ACCOUNT',
    Department: 'DEPARTMENT',
    Dimension1: 'DIMENSION1',
    Dimension2: 'DIMENSION2',
    Dimension3: 'DIMENSION3',
    Dimension4: 'DIMENSION4',
    Dimension5: 'DIMENSION5',
    Dimension6: 'DIMENSION6',
    Dimension7: 'DIMENSION7',
    Period: 'PERIOD'
} as const;

export type CellDimensionConditionDimensionTypeEnum = typeof CellDimensionConditionDimensionTypeEnum[keyof typeof CellDimensionConditionDimensionTypeEnum];
export const CellDimensionConditionPeriodAggregationEnum = {
    Month: 'MONTH',
    Quarter: 'QUARTER',
    FiscalYear: 'FISCAL_YEAR',
    FiscalHalfYear: 'FISCAL_HALF_YEAR'
} as const;

export type CellDimensionConditionPeriodAggregationEnum = typeof CellDimensionConditionPeriodAggregationEnum[keyof typeof CellDimensionConditionPeriodAggregationEnum];

/**
 * 
 * @export
 * @interface CellGroupOrder
 */
export interface CellGroupOrder {
    /**
     * 
     * @type {string}
     * @memberof CellGroupOrder
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof CellGroupOrder
     */
    'type': CellGroupOrderTypeEnum;
}

export const CellGroupOrderTypeEnum = {
    Data: 'DATA',
    Comparison: 'COMPARISON'
} as const;

export type CellGroupOrderTypeEnum = typeof CellGroupOrderTypeEnum[keyof typeof CellGroupOrderTypeEnum];

/**
 * 
 * @export
 * @interface CellGroupOrderKey
 */
export interface CellGroupOrderKey {
    /**
     * 
     * @type {string}
     * @memberof CellGroupOrderKey
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface CellGroupView
 */
export interface CellGroupView {
    /**
     * 
     * @type {Array<CellView>}
     * @memberof CellGroupView
     */
    'cells': Array<CellView>;
}
/**
 * @type CellView
 * @export
 */
export type CellView = { type: 'COMPARISON' } & ComparisonFormulaCellView | { type: 'DATA' } & DataCellView;

/**
 * 
 * @export
 * @interface ChatCompletionMessage
 */
export interface ChatCompletionMessage {
    /**
     * 
     * @type {string}
     * @memberof ChatCompletionMessage
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ChatCompletionMessage
     */
    'role': ChatCompletionMessageRoleEnum;
}

export const ChatCompletionMessageRoleEnum = {
    System: 'SYSTEM',
    Assistant: 'ASSISTANT',
    User: 'USER'
} as const;

export type ChatCompletionMessageRoleEnum = typeof ChatCompletionMessageRoleEnum[keyof typeof ChatCompletionMessageRoleEnum];

/**
 * 
 * @export
 * @interface ChatCompletionRequest
 */
export interface ChatCompletionRequest {
    /**
     * 
     * @type {Array<ChatCompletionMessage>}
     * @memberof ChatCompletionRequest
     */
    'messages': Array<ChatCompletionMessage>;
}
/**
 * 
 * @export
 * @interface ColumnAggregationDimension
 */
export interface ColumnAggregationDimension {
    /**
     * 
     * @type {string}
     * @memberof ColumnAggregationDimension
     */
    'dimensionTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnAggregationDimension
     */
    'dimensionType': ColumnAggregationDimensionDimensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ColumnAggregationDimension
     */
    'periodAggregation'?: ColumnAggregationDimensionPeriodAggregationEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColumnAggregationDimension
     */
    'visibleIds': Array<string>;
}

export const ColumnAggregationDimensionDimensionTypeEnum = {
    Account: 'ACCOUNT',
    Period: 'PERIOD',
    Department: 'DEPARTMENT',
    Dimension1: 'DIMENSION_1',
    Dimension2: 'DIMENSION_2',
    Dimension3: 'DIMENSION_3',
    Dimension4: 'DIMENSION_4',
    Dimension5: 'DIMENSION_5',
    Dimension6: 'DIMENSION_6',
    Dimension7: 'DIMENSION_7'
} as const;

export type ColumnAggregationDimensionDimensionTypeEnum = typeof ColumnAggregationDimensionDimensionTypeEnum[keyof typeof ColumnAggregationDimensionDimensionTypeEnum];
export const ColumnAggregationDimensionPeriodAggregationEnum = {
    Month: 'MONTH',
    Quarter: 'QUARTER',
    FiscalYear: 'FISCAL_YEAR',
    FiscalHalfYear: 'FISCAL_HALF_YEAR'
} as const;

export type ColumnAggregationDimensionPeriodAggregationEnum = typeof ColumnAggregationDimensionPeriodAggregationEnum[keyof typeof ColumnAggregationDimensionPeriodAggregationEnum];

/**
 * 
 * @export
 * @interface ComparisonAnalysisRequestBody
 */
export interface ComparisonAnalysisRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ComparisonAnalysisRequestBody
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonAnalysisRequestBody
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonAnalysisRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonAnalysisRequestBody
     */
    'departmentId': string;
    /**
     * 
     * @type {number}
     * @memberof ComparisonAnalysisRequestBody
     */
    'diffAmountThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof ComparisonAnalysisRequestBody
     */
    'diffRatioThreshold'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAnalysisRequestBody
     */
    'enableFinancialSheetComment': boolean;
    /**
     * 
     * @type {string}
     * @memberof ComparisonAnalysisRequestBody
     */
    'organizationTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonAnalysisRequestBody
     */
    'planMasterId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonAnalysisRequestBody
     */
    'requireAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof ComparisonAnalysisRequestBody
     */
    'rowType': ComparisonAnalysisRequestBodyRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ComparisonAnalysisRequestBody
     */
    'targetYearMonth': string;
}

export const ComparisonAnalysisRequestBodyRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type ComparisonAnalysisRequestBodyRowTypeEnum = typeof ComparisonAnalysisRequestBodyRowTypeEnum[keyof typeof ComparisonAnalysisRequestBodyRowTypeEnum];

/**
 * 
 * @export
 * @interface ComparisonCellDetailV3View
 */
export interface ComparisonCellDetailV3View {
    /**
     * 
     * @type {number}
     * @memberof ComparisonCellDetailV3View
     */
    'commentCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonCellDetailV3View
     */
    'hasDetails': boolean;
    /**
     * 
     * @type {string}
     * @memberof ComparisonCellDetailV3View
     */
    'periodKeyValue': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonCellDetailV3View
     */
    'periodLabel': string;
    /**
     * 
     * @type {number}
     * @memberof ComparisonCellDetailV3View
     */
    'sum'?: number;
}
/**
 * 
 * @export
 * @interface ComparisonCellV3View
 */
export interface ComparisonCellV3View {
    /**
     * 
     * @type {ComparisonDetailView}
     * @memberof ComparisonCellV3View
     */
    'comparisonDetail': ComparisonDetailView;
    /**
     * 
     * @type {string}
     * @memberof ComparisonCellV3View
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonCellV3View
     */
    'periodPairKey': string;
    /**
     * 
     * @type {ComparisonCellDetailV3View}
     * @memberof ComparisonCellV3View
     */
    'planA': ComparisonCellDetailV3View;
    /**
     * 
     * @type {ComparisonCellDetailV3View}
     * @memberof ComparisonCellV3View
     */
    'planB': ComparisonCellDetailV3View;
}
/**
 * 
 * @export
 * @interface ComparisonCondition
 */
export interface ComparisonCondition {
    /**
     * 
     * @type {string}
     * @memberof ComparisonCondition
     */
    'calculationFormula': ComparisonConditionCalculationFormulaEnum;
    /**
     * 
     * @type {string}
     * @memberof ComparisonCondition
     */
    'comparisonType': ComparisonConditionComparisonTypeEnum;
}

export const ComparisonConditionCalculationFormulaEnum = {
    DiffAb: 'DIFF_AB',
    DiffAc: 'DIFF_AC',
    DiffBa: 'DIFF_BA',
    DiffBc: 'DIFF_BC',
    DiffCa: 'DIFF_CA',
    DiffCb: 'DIFF_CB',
    DiffRatioAb: 'DIFF_RATIO_AB',
    DiffRatioAc: 'DIFF_RATIO_AC',
    DiffRatioBa: 'DIFF_RATIO_BA',
    DiffRatioBc: 'DIFF_RATIO_BC',
    DiffRatioCa: 'DIFF_RATIO_CA',
    DiffRatioCb: 'DIFF_RATIO_CB',
    AchievementRatioAb: 'ACHIEVEMENT_RATIO_AB',
    AchievementRatioAc: 'ACHIEVEMENT_RATIO_AC',
    AchievementRatioBa: 'ACHIEVEMENT_RATIO_BA',
    AchievementRatioBc: 'ACHIEVEMENT_RATIO_BC',
    AchievementRatioCa: 'ACHIEVEMENT_RATIO_CA',
    AchievementRatioCb: 'ACHIEVEMENT_RATIO_CB',
    SpecificAccountRatioA: 'SPECIFIC_ACCOUNT_RATIO_A',
    SpecificAccountRatioB: 'SPECIFIC_ACCOUNT_RATIO_B',
    SpecificAccountRatioC: 'SPECIFIC_ACCOUNT_RATIO_C'
} as const;

export type ComparisonConditionCalculationFormulaEnum = typeof ComparisonConditionCalculationFormulaEnum[keyof typeof ComparisonConditionCalculationFormulaEnum];
export const ComparisonConditionComparisonTypeEnum = {
    Diff: 'DIFF',
    DiffRatio: 'DIFF_RATIO',
    AchievementRatio: 'ACHIEVEMENT_RATIO',
    SpecificAccountRatio: 'SPECIFIC_ACCOUNT_RATIO'
} as const;

export type ComparisonConditionComparisonTypeEnum = typeof ComparisonConditionComparisonTypeEnum[keyof typeof ComparisonConditionComparisonTypeEnum];

/**
 * 
 * @export
 * @interface ComparisonDetailView
 */
export interface ComparisonDetailView {
    /**
     * 
     * @type {ComparisonDiffRatioView}
     * @memberof ComparisonDetailView
     */
    'diffRatio'?: ComparisonDiffRatioView;
    /**
     * 
     * @type {ComparisonSpecificAccountRatioView}
     * @memberof ComparisonDetailView
     */
    'specificAccountRatio'?: ComparisonSpecificAccountRatioView;
}
/**
 * 
 * @export
 * @interface ComparisonDiffRatioView
 */
export interface ComparisonDiffRatioView {
    /**
     * 
     * @type {number}
     * @memberof ComparisonDiffRatioView
     */
    'diff'?: number;
    /**
     * 
     * @type {number}
     * @memberof ComparisonDiffRatioView
     */
    'diffCommentCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonDiffRatioView
     */
    'diffHighlight': boolean;
    /**
     * 
     * @type {number}
     * @memberof ComparisonDiffRatioView
     */
    'ratio'?: number;
    /**
     * 
     * @type {number}
     * @memberof ComparisonDiffRatioView
     */
    'ratioCommentCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonDiffRatioView
     */
    'ratioHighlight': boolean;
}
/**
 * 
 * @export
 * @interface ComparisonFlatTreeItemV3View
 */
export interface ComparisonFlatTreeItemV3View {
    /**
     * 
     * @type {string}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'accountType': ComparisonFlatTreeItemV3ViewAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'code': string;
    /**
     * 
     * @type {ComparisonDetailView}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'comparisonDetail': ComparisonDetailView;
    /**
     * 
     * @type {Array<ComparisonCellV3View>}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'data': Array<ComparisonCellV3View>;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'parentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'planASum'?: number;
    /**
     * 
     * @type {number}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'planBSum'?: number;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'rowType': ComparisonFlatTreeItemV3ViewRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFlatTreeItemV3View
     */
    'viewControlType': ComparisonFlatTreeItemV3ViewViewControlTypeEnum;
}

export const ComparisonFlatTreeItemV3ViewAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type ComparisonFlatTreeItemV3ViewAccountTypeEnum = typeof ComparisonFlatTreeItemV3ViewAccountTypeEnum[keyof typeof ComparisonFlatTreeItemV3ViewAccountTypeEnum];
export const ComparisonFlatTreeItemV3ViewRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type ComparisonFlatTreeItemV3ViewRowTypeEnum = typeof ComparisonFlatTreeItemV3ViewRowTypeEnum[keyof typeof ComparisonFlatTreeItemV3ViewRowTypeEnum];
export const ComparisonFlatTreeItemV3ViewViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type ComparisonFlatTreeItemV3ViewViewControlTypeEnum = typeof ComparisonFlatTreeItemV3ViewViewControlTypeEnum[keyof typeof ComparisonFlatTreeItemV3ViewViewControlTypeEnum];

/**
 * 
 * @export
 * @interface ComparisonFlatTreeV3View
 */
export interface ComparisonFlatTreeV3View {
    /**
     * 
     * @type {Array<ComparisonFlatTreeItemV3View>}
     * @memberof ComparisonFlatTreeV3View
     */
    'items': Array<ComparisonFlatTreeItemV3View>;
}
/**
 * 
 * @export
 * @interface ComparisonFormulaCellView
 */
export interface ComparisonFormulaCellView {
    /**
     * 
     * @type {EitherAmountOrErrorView}
     * @memberof ComparisonFormulaCellView
     */
    'eitherAmountOrError'?: EitherAmountOrErrorView;
    /**
     * 
     * @type {string}
     * @memberof ComparisonFormulaCellView
     */
    'id': string;
    /**
     * 
     * @type {CellGroupOrderKey}
     * @memberof ComparisonFormulaCellView
     */
    'orderKey': CellGroupOrderKey;
    /**
     * 
     * @type {ReportCellType}
     * @memberof ComparisonFormulaCellView
     */
    'type': ReportCellType;
}


/**
 * 
 * @export
 * @interface ComparisonPeriodPair
 */
export interface ComparisonPeriodPair {
    /**
     * 
     * @type {string}
     * @memberof ComparisonPeriodPair
     */
    'periodPairKey': string;
    /**
     * 
     * @type {PeriodComparisonCellTypeV3View}
     * @memberof ComparisonPeriodPair
     */
    'planA': PeriodComparisonCellTypeV3View;
    /**
     * 
     * @type {PeriodComparisonCellTypeV3View}
     * @memberof ComparisonPeriodPair
     */
    'planB': PeriodComparisonCellTypeV3View;
}
/**
 * 
 * @export
 * @interface ComparisonSheetSelectedConditionDetailDto
 */
export interface ComparisonSheetSelectedConditionDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'aggregate': ComparisonSheetSelectedConditionDetailDtoAggregateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'highlightThreshold'?: number;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'highlightType'?: ComparisonSheetSelectedConditionDetailDtoHighlightTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'orgTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planAActualEndYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planAActualStartYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planAEndYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planAMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planAShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planAStartYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planAUpdateProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planBActualEndYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planBActualStartYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planBEndYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planBMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planBShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planBStartYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'planBUpdateProjectId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonSheetSelectedConditionDetailDto
     */
    'requireAllocatedItem': boolean;
}

export const ComparisonSheetSelectedConditionDetailDtoAggregateEnum = {
    Month: 'MONTH',
    Quarter: 'QUARTER',
    FiscalYear: 'FISCAL_YEAR',
    FiscalHalfYear: 'FISCAL_HALF_YEAR'
} as const;

export type ComparisonSheetSelectedConditionDetailDtoAggregateEnum = typeof ComparisonSheetSelectedConditionDetailDtoAggregateEnum[keyof typeof ComparisonSheetSelectedConditionDetailDtoAggregateEnum];
export const ComparisonSheetSelectedConditionDetailDtoHighlightTypeEnum = {
    Amount: 'DIFF_AMOUNT',
    Ratio: 'DIFF_RATIO'
} as const;

export type ComparisonSheetSelectedConditionDetailDtoHighlightTypeEnum = typeof ComparisonSheetSelectedConditionDetailDtoHighlightTypeEnum[keyof typeof ComparisonSheetSelectedConditionDetailDtoHighlightTypeEnum];

/**
 * 
 * @export
 * @interface ComparisonSheetSelectedConditionDto
 */
export interface ComparisonSheetSelectedConditionDto {
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDto
     */
    'comparisonSheetSelectedConditionGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDto
     */
    'comparisonSheetSelectedConditionId': string;
    /**
     * 
     * @type {ComparisonSheetSelectedConditionDetailDto}
     * @memberof ComparisonSheetSelectedConditionDto
     */
    'detail': ComparisonSheetSelectedConditionDetailDto;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ComparisonSheetSelectedConditionForSelectionView
 */
export interface ComparisonSheetSelectedConditionForSelectionView {
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionForSelectionView
     */
    'conditionGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionForSelectionView
     */
    'conditionGroupName': string;
}
/**
 * 
 * @export
 * @interface ComparisonSheetSelectedConditionGroupView
 */
export interface ComparisonSheetSelectedConditionGroupView {
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionGroupView
     */
    'conditionGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionGroupView
     */
    'conditionGroupName': string;
    /**
     * 
     * @type {Array<ComparisonSheetSelectedConditionView>}
     * @memberof ComparisonSheetSelectedConditionGroupView
     */
    'conditions': Array<ComparisonSheetSelectedConditionView>;
}
/**
 * 
 * @export
 * @interface ComparisonSheetSelectedConditionView
 */
export interface ComparisonSheetSelectedConditionView {
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionView
     */
    'comparisonSheetSelectedConditionGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionView
     */
    'comparisonSheetSelectedConditionId': string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionView
     */
    'createdUserId': string;
    /**
     * 
     * @type {SelectedConditionDetail}
     * @memberof ComparisonSheetSelectedConditionView
     */
    'detail': SelectedConditionDetail;
    /**
     * 
     * @type {string}
     * @memberof ComparisonSheetSelectedConditionView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ComparisonSpecificAccountRatioView
 */
export interface ComparisonSpecificAccountRatioView {
    /**
     * 
     * @type {number}
     * @memberof ComparisonSpecificAccountRatioView
     */
    'specificAccountRatioA'?: number;
    /**
     * 
     * @type {number}
     * @memberof ComparisonSpecificAccountRatioView
     */
    'specificAccountRatioB'?: number;
}
/**
 * 
 * @export
 * @interface ComparisonTableMetaV3View
 */
export interface ComparisonTableMetaV3View {
    /**
     * 
     * @type {Array<ComparisonPeriodPair>}
     * @memberof ComparisonTableMetaV3View
     */
    'periodPairs': Array<ComparisonPeriodPair>;
    /**
     * 
     * @type {PlanDetailView}
     * @memberof ComparisonTableMetaV3View
     */
    'planA': PlanDetailView;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'planAActualEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'planAActualStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'planAEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'planAStart'?: string;
    /**
     * 
     * @type {PlanDetailView}
     * @memberof ComparisonTableMetaV3View
     */
    'planB': PlanDetailView;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'planBActualEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'planBActualStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'planBEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'planBStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'roundingMode': ComparisonTableMetaV3ViewRoundingModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ComparisonTableMetaV3View
     */
    'showIn': ComparisonTableMetaV3ViewShowInEnum;
}

export const ComparisonTableMetaV3ViewRoundingModeEnum = {
    Down: 'DOWN',
    HalfUp: 'HALF_UP'
} as const;

export type ComparisonTableMetaV3ViewRoundingModeEnum = typeof ComparisonTableMetaV3ViewRoundingModeEnum[keyof typeof ComparisonTableMetaV3ViewRoundingModeEnum];
export const ComparisonTableMetaV3ViewShowInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type ComparisonTableMetaV3ViewShowInEnum = typeof ComparisonTableMetaV3ViewShowInEnum[keyof typeof ComparisonTableMetaV3ViewShowInEnum];

/**
 * 
 * @export
 * @interface ComparisonTableV3View
 */
export interface ComparisonTableV3View {
    /**
     * 
     * @type {ComparisonTableMetaV3View}
     * @memberof ComparisonTableV3View
     */
    'meta': ComparisonTableMetaV3View;
    /**
     * 
     * @type {ComparisonFlatTreeV3View}
     * @memberof ComparisonTableV3View
     */
    'tree': ComparisonFlatTreeV3View;
}
/**
 * 
 * @export
 * @interface ConcurrentPostRatioInfosView
 */
export interface ConcurrentPostRatioInfosView {
    /**
     * 
     * @type {string}
     * @memberof ConcurrentPostRatioInfosView
     */
    'departmentName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConcurrentPostRatioInfosView
     */
    'dimensionTagNames': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ConcurrentPostRatioInfosView
     */
    'ratio': number;
}
/**
 * 
 * @export
 * @interface ConnectSlotsToActualChangeEventsBody
 */
export interface ConnectSlotsToActualChangeEventsBody {
    /**
     * 
     * @type {Array<ConnectWorkforceSlotsToActualChangeEventsParam>}
     * @memberof ConnectSlotsToActualChangeEventsBody
     */
    'workforceSlotParams': Array<ConnectWorkforceSlotsToActualChangeEventsParam>;
}
/**
 * 
 * @export
 * @interface ConnectWorkforceSlotsToActualChangeEventsParam
 */
export interface ConnectWorkforceSlotsToActualChangeEventsParam {
    /**
     * 
     * @type {string}
     * @memberof ConnectWorkforceSlotsToActualChangeEventsParam
     */
    'actualChangeEventId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectWorkforceSlotsToActualChangeEventsParam
     */
    'isPlanned': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectWorkforceSlotsToActualChangeEventsParam
     */
    'plannedYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectWorkforceSlotsToActualChangeEventsParam
     */
    'slotType': ConnectWorkforceSlotsToActualChangeEventsParamSlotTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConnectWorkforceSlotsToActualChangeEventsParam
     */
    'workforceSlotId'?: string;
}

export const ConnectWorkforceSlotsToActualChangeEventsParamSlotTypeEnum = {
    Hiring: 'HIRING',
    Resignation: 'RESIGNATION',
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    ReturnToWork: 'RETURN_TO_WORK'
} as const;

export type ConnectWorkforceSlotsToActualChangeEventsParamSlotTypeEnum = typeof ConnectWorkforceSlotsToActualChangeEventsParamSlotTypeEnum[keyof typeof ConnectWorkforceSlotsToActualChangeEventsParamSlotTypeEnum];

/**
 * 
 * @export
 * @interface ConnectedPersonByYearMonthView
 */
export interface ConnectedPersonByYearMonthView {
    /**
     * 
     * @type {Array<ConnectedPersonView>}
     * @memberof ConnectedPersonByYearMonthView
     */
    'connectedPersons': Array<ConnectedPersonView>;
    /**
     * 
     * @type {string}
     * @memberof ConnectedPersonByYearMonthView
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface ConnectedPersonView
 */
export interface ConnectedPersonView {
    /**
     * 
     * @type {string}
     * @memberof ConnectedPersonView
     */
    'actualChangeEventId': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectedPersonView
     */
    'personCode': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectedPersonView
     */
    'personFullName': string;
}
/**
 * 
 * @export
 * @interface ConnectedPlanUpdateRequestDto
 */
export interface ConnectedPlanUpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ConnectedPlanUpdateRequestDto
     */
    'connectedPlanUpdateRequestId': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectedPlanUpdateRequestDto
     */
    'departmentName': string;
}
/**
 * 
 * @export
 * @interface ContractUserTypeAndActiveUserCountDto
 */
export interface ContractUserTypeAndActiveUserCountDto {
    /**
     * 
     * @type {ActiveUserCountDto}
     * @memberof ContractUserTypeAndActiveUserCountDto
     */
    'activeUserCount': ActiveUserCountDto;
    /**
     * 
     * @type {ContractUserTypeCountDto}
     * @memberof ContractUserTypeAndActiveUserCountDto
     */
    'contractUserTypeCount': ContractUserTypeCountDto;
}
/**
 * 
 * @export
 * @interface ContractUserTypeCountDto
 */
export interface ContractUserTypeCountDto {
    /**
     * 
     * @type {number}
     * @memberof ContractUserTypeCountDto
     */
    'view': number;
    /**
     * 
     * @type {number}
     * @memberof ContractUserTypeCountDto
     */
    'write': number;
}
/**
 * 
 * @export
 * @interface ConvertScenarioTableToPlanRequestBody
 */
export interface ConvertScenarioTableToPlanRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ConvertScenarioTableToPlanRequestBody
     */
    'newPlanName': string;
    /**
     * 
     * @type {string}
     * @memberof ConvertScenarioTableToPlanRequestBody
     */
    'newPlanType': ConvertScenarioTableToPlanRequestBodyNewPlanTypeEnum;
    /**
     * 
     * @type {Array<FinancialSimulationReportRowRequest>}
     * @memberof ConvertScenarioTableToPlanRequestBody
     */
    'rows': Array<FinancialSimulationReportRowRequest>;
}

export const ConvertScenarioTableToPlanRequestBodyNewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type ConvertScenarioTableToPlanRequestBodyNewPlanTypeEnum = typeof ConvertScenarioTableToPlanRequestBodyNewPlanTypeEnum[keyof typeof ConvertScenarioTableToPlanRequestBodyNewPlanTypeEnum];

/**
 * 
 * @export
 * @interface CopyDimensionTreeBody
 */
export interface CopyDimensionTreeBody {
    /**
     * 
     * @type {string}
     * @memberof CopyDimensionTreeBody
     */
    'dimensionTreeName': string;
}
/**
 * 
 * @export
 * @interface CopyNonFinancialAccountTreeBody
 */
export interface CopyNonFinancialAccountTreeBody {
    /**
     * 
     * @type {string}
     * @memberof CopyNonFinancialAccountTreeBody
     */
    'newName': string;
}
/**
 * 
 * @export
 * @interface CopyOrganizationTreeParam
 */
export interface CopyOrganizationTreeParam {
    /**
     * 
     * @type {string}
     * @memberof CopyOrganizationTreeParam
     */
    'treeName': string;
}
/**
 * 
 * @export
 * @interface CountDepartmentsNotIncludedInOrgDto
 */
export interface CountDepartmentsNotIncludedInOrgDto {
    /**
     * 
     * @type {number}
     * @memberof CountDepartmentsNotIncludedInOrgDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CreateAccountAndAddNonFinancialAccountTreeBody
 */
export interface CreateAccountAndAddNonFinancialAccountTreeBody {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountAndAddNonFinancialAccountTreeBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountAndAddNonFinancialAccountTreeBody
     */
    'externalSystemAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountAndAddNonFinancialAccountTreeBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountAndAddNonFinancialAccountTreeBody
     */
    'nonFinancialAccountTreeId': string;
    /**
     * 
     * @type {NonFinancialAccountTreeNodeBody}
     * @memberof CreateAccountAndAddNonFinancialAccountTreeBody
     */
    'parentNode'?: NonFinancialAccountTreeNodeBody;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountAndAddNonFinancialAccountTreeBody
     */
    'roundingPrecision'?: CreateAccountAndAddNonFinancialAccountTreeBodyRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountAndAddNonFinancialAccountTreeBody
     */
    'valueFormat'?: CreateAccountAndAddNonFinancialAccountTreeBodyValueFormatEnum;
}

export const CreateAccountAndAddNonFinancialAccountTreeBodyRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type CreateAccountAndAddNonFinancialAccountTreeBodyRoundingPrecisionEnum = typeof CreateAccountAndAddNonFinancialAccountTreeBodyRoundingPrecisionEnum[keyof typeof CreateAccountAndAddNonFinancialAccountTreeBodyRoundingPrecisionEnum];
export const CreateAccountAndAddNonFinancialAccountTreeBodyValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type CreateAccountAndAddNonFinancialAccountTreeBodyValueFormatEnum = typeof CreateAccountAndAddNonFinancialAccountTreeBodyValueFormatEnum[keyof typeof CreateAccountAndAddNonFinancialAccountTreeBodyValueFormatEnum];

/**
 * 
 * @export
 * @interface CreateAccountRequestBody
 */
export interface CreateAccountRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestBody
     */
    'accountType': CreateAccountRequestBodyAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestBody
     */
    'externalSystemAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestBody
     */
    'externalSystemSubAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestBody
     */
    'roundingPrecision'?: CreateAccountRequestBodyRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestBody
     */
    'valueFormat'?: CreateAccountRequestBodyValueFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestBody
     */
    'viewControlType': CreateAccountRequestBodyViewControlTypeEnum;
}

export const CreateAccountRequestBodyAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type CreateAccountRequestBodyAccountTypeEnum = typeof CreateAccountRequestBodyAccountTypeEnum[keyof typeof CreateAccountRequestBodyAccountTypeEnum];
export const CreateAccountRequestBodyRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type CreateAccountRequestBodyRoundingPrecisionEnum = typeof CreateAccountRequestBodyRoundingPrecisionEnum[keyof typeof CreateAccountRequestBodyRoundingPrecisionEnum];
export const CreateAccountRequestBodyValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type CreateAccountRequestBodyValueFormatEnum = typeof CreateAccountRequestBodyValueFormatEnum[keyof typeof CreateAccountRequestBodyValueFormatEnum];
export const CreateAccountRequestBodyViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type CreateAccountRequestBodyViewControlTypeEnum = typeof CreateAccountRequestBodyViewControlTypeEnum[keyof typeof CreateAccountRequestBodyViewControlTypeEnum];

/**
 * 
 * @export
 * @interface CreateActualsImportMappingDebitCreditConditionParams
 */
export interface CreateActualsImportMappingDebitCreditConditionParams {
    /**
     * 
     * @type {number}
     * @memberof CreateActualsImportMappingDebitCreditConditionParams
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingDebitCreditConditionParams
     */
    'conditionType': CreateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingDebitCreditConditionParams
     */
    'conditionValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingDebitCreditConditionParams
     */
    'debitCredit': CreateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum;
}

export const CreateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Entered: 'ENTERED',
    NotEntered: 'NOT_ENTERED'
} as const;

export type CreateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum = typeof CreateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum[keyof typeof CreateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum];
export const CreateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum = {
    Debit: 'DEBIT',
    Credit: 'CREDIT'
} as const;

export type CreateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum = typeof CreateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum[keyof typeof CreateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum];

/**
 * 
 * @export
 * @interface CreateActualsImportMappingFilterParams
 */
export interface CreateActualsImportMappingFilterParams {
    /**
     * 
     * @type {number}
     * @memberof CreateActualsImportMappingFilterParams
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingFilterParams
     */
    'conditionType': CreateActualsImportMappingFilterParamsConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingFilterParams
     */
    'conditionValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingFilterParams
     */
    'targetType': CreateActualsImportMappingFilterParamsTargetTypeEnum;
}

export const CreateActualsImportMappingFilterParamsConditionTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Entered: 'ENTERED',
    NotEntered: 'NOT_ENTERED'
} as const;

export type CreateActualsImportMappingFilterParamsConditionTypeEnum = typeof CreateActualsImportMappingFilterParamsConditionTypeEnum[keyof typeof CreateActualsImportMappingFilterParamsConditionTypeEnum];
export const CreateActualsImportMappingFilterParamsTargetTypeEnum = {
    Row: 'ROW',
    Column: 'COLUMN'
} as const;

export type CreateActualsImportMappingFilterParamsTargetTypeEnum = typeof CreateActualsImportMappingFilterParamsTargetTypeEnum[keyof typeof CreateActualsImportMappingFilterParamsTargetTypeEnum];

/**
 * 
 * @export
 * @interface CreateActualsImportMappingItemParams
 */
export interface CreateActualsImportMappingItemParams {
    /**
     * 
     * @type {number}
     * @memberof CreateActualsImportMappingItemParams
     */
    'columnIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingItemParams
     */
    'dimensionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingItemParams
     */
    'type': CreateActualsImportMappingItemParamsTypeEnum;
}

export const CreateActualsImportMappingItemParamsTypeEnum = {
    Date: 'DATE',
    DebitAmount: 'DEBIT_AMOUNT',
    CreditAmount: 'CREDIT_AMOUNT',
    DebitAccountName: 'DEBIT_ACCOUNT_NAME',
    CreditAccountName: 'CREDIT_ACCOUNT_NAME',
    DocumentId: 'DOCUMENT_ID',
    Description: 'DESCRIPTION',
    DebitAccountExCode: 'DEBIT_ACCOUNT_EX_CODE',
    CreditAccountExCode: 'CREDIT_ACCOUNT_EX_CODE',
    DebitDepartmentName: 'DEBIT_DEPARTMENT_NAME',
    CreditDepartmentName: 'CREDIT_DEPARTMENT_NAME',
    DebitDepartmentExCode: 'DEBIT_DEPARTMENT_EX_CODE',
    CreditDepartmentExCode: 'CREDIT_DEPARTMENT_EX_CODE',
    DebitSubAccountName: 'DEBIT_SUB_ACCOUNT_NAME',
    CreditSubAccountName: 'CREDIT_SUB_ACCOUNT_NAME',
    DebitSubAccountExCode: 'DEBIT_SUB_ACCOUNT_EX_CODE',
    CreditSubAccountExCode: 'CREDIT_SUB_ACCOUNT_EX_CODE',
    DebitSupplierName: 'DEBIT_SUPPLIER_NAME',
    CreditSupplierName: 'CREDIT_SUPPLIER_NAME',
    DebitSupplierExCode: 'DEBIT_SUPPLIER_EX_CODE',
    CreditSupplierExCode: 'CREDIT_SUPPLIER_EX_CODE',
    DebitDimensionName: 'DEBIT_DIMENSION_NAME',
    CreditDimensionName: 'CREDIT_DIMENSION_NAME',
    Amount: 'AMOUNT',
    AccountName: 'ACCOUNT_NAME',
    AccountExCode: 'ACCOUNT_EX_CODE',
    DepartmentName: 'DEPARTMENT_NAME',
    DepartmentExCode: 'DEPARTMENT_EX_CODE',
    SubAccountName: 'SUB_ACCOUNT_NAME',
    SubAccountExCode: 'SUB_ACCOUNT_EX_CODE',
    SupplierName: 'SUPPLIER_NAME',
    SupplierExCode: 'SUPPLIER_EX_CODE',
    DimensionName: 'DIMENSION_NAME'
} as const;

export type CreateActualsImportMappingItemParamsTypeEnum = typeof CreateActualsImportMappingItemParamsTypeEnum[keyof typeof CreateActualsImportMappingItemParamsTypeEnum];

/**
 * 
 * @export
 * @interface CreateActualsImportMappingParams
 */
export interface CreateActualsImportMappingParams {
    /**
     * 
     * @type {Array<CreateActualsImportMappingFilterParams>}
     * @memberof CreateActualsImportMappingParams
     */
    'actualImportMappingFilters': Array<CreateActualsImportMappingFilterParams>;
    /**
     * 
     * @type {Array<CreateActualsImportMappingItemParams>}
     * @memberof CreateActualsImportMappingParams
     */
    'actualImportMappingItems': Array<CreateActualsImportMappingItemParams>;
    /**
     * 
     * @type {Array<CreateActualsImportMappingSubsidiaryCodeParams>}
     * @memberof CreateActualsImportMappingParams
     */
    'actualImportMappingSubsidiaryCodes': Array<CreateActualsImportMappingSubsidiaryCodeParams>;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingParams
     */
    'actualImportPattern': CreateActualsImportMappingParamsActualImportPatternEnum;
    /**
     * 
     * @type {Array<CreateActualsImportMappingDebitCreditConditionParams>}
     * @memberof CreateActualsImportMappingParams
     */
    'actualsImportMappingDebitCreditConditions': Array<CreateActualsImportMappingDebitCreditConditionParams>;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingParams
     */
    'amountDataDirection': CreateActualsImportMappingParamsAmountDataDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateActualsImportMappingParams
     */
    'headerRowIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingParams
     */
    'journalOrBalance': CreateActualsImportMappingParamsJournalOrBalanceEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingParams
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateActualsImportMappingParams
     */
    'processSettingIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingParams
     */
    'repeatColumnHeaderType'?: CreateActualsImportMappingParamsRepeatColumnHeaderTypeEnum;
}

export const CreateActualsImportMappingParamsActualImportPatternEnum = {
    Divide: 'DIVIDE',
    Combine: 'COMBINE',
    AccountType: 'ACCOUNT_TYPE'
} as const;

export type CreateActualsImportMappingParamsActualImportPatternEnum = typeof CreateActualsImportMappingParamsActualImportPatternEnum[keyof typeof CreateActualsImportMappingParamsActualImportPatternEnum];
export const CreateActualsImportMappingParamsAmountDataDirectionEnum = {
    RowColumn: 'ROW_COLUMN',
    Column: 'COLUMN'
} as const;

export type CreateActualsImportMappingParamsAmountDataDirectionEnum = typeof CreateActualsImportMappingParamsAmountDataDirectionEnum[keyof typeof CreateActualsImportMappingParamsAmountDataDirectionEnum];
export const CreateActualsImportMappingParamsJournalOrBalanceEnum = {
    Journal: 'JOURNAL',
    Balance: 'BALANCE'
} as const;

export type CreateActualsImportMappingParamsJournalOrBalanceEnum = typeof CreateActualsImportMappingParamsJournalOrBalanceEnum[keyof typeof CreateActualsImportMappingParamsJournalOrBalanceEnum];
export const CreateActualsImportMappingParamsRepeatColumnHeaderTypeEnum = {
    Date: 'DATE',
    Account: 'ACCOUNT',
    Department: 'DEPARTMENT'
} as const;

export type CreateActualsImportMappingParamsRepeatColumnHeaderTypeEnum = typeof CreateActualsImportMappingParamsRepeatColumnHeaderTypeEnum[keyof typeof CreateActualsImportMappingParamsRepeatColumnHeaderTypeEnum];

/**
 * 
 * @export
 * @interface CreateActualsImportMappingSubsidiaryCodeParams
 */
export interface CreateActualsImportMappingSubsidiaryCodeParams {
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingSubsidiaryCodeParams
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateActualsImportMappingSubsidiaryCodeParams
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateAiPlanActualAnalysisReportResponse
 */
export interface CreateAiPlanActualAnalysisReportResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateAiPlanActualAnalysisReportResponse
     */
    'aiPlanActualAnalysisReportId': string;
}
/**
 * 
 * @export
 * @interface CreateAllocationRuleRequestBody
 */
export interface CreateAllocationRuleRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationRuleRequestBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateAllocationStandardDepartmentsRequestBody
 */
export interface CreateAllocationStandardDepartmentsRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationStandardDepartmentsRequestBody
     */
    'departmentCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationStandardDepartmentsRequestBody
     */
    'departmentId': string;
}
/**
 * 
 * @export
 * @interface CreateAllocationStandardReferenceRequestBody
 */
export interface CreateAllocationStandardReferenceRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAllocationStandardReferenceRequestBody
     */
    'accountIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationStandardReferenceRequestBody
     */
    'planId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationStandardReferenceRequestBody
     */
    'referenceValueType': string;
}
/**
 * 
 * @export
 * @interface CreateAllocationStepRequestBody
 */
export interface CreateAllocationStepRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationStepRequestBody
     */
    'allocationStandardId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationStepRequestBody
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationStepRequestBody
     */
    'destinationAccountId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAllocationStepRequestBody
     */
    'destinationDepartmentIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAllocationStepRequestBody
     */
    'destinationDimensionTagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationStepRequestBody
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAllocationStepRequestBody
     */
    'sourceAccountIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAllocationStepRequestBody
     */
    'sourceDepartmentIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAllocationStepRequestBody
     */
    'sourceDimensionTagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationStepRequestBody
     */
    'stepType': CreateAllocationStepRequestBodyStepTypeEnum;
}

export const CreateAllocationStepRequestBodyStepTypeEnum = {
    Allocation: 'ALLOCATION',
    Transfer: 'TRANSFER'
} as const;

export type CreateAllocationStepRequestBodyStepTypeEnum = typeof CreateAllocationStepRequestBodyStepTypeEnum[keyof typeof CreateAllocationStepRequestBodyStepTypeEnum];

/**
 * 
 * @export
 * @interface CreateAllocationStrategyRequestBody
 */
export interface CreateAllocationStrategyRequestBody {
    /**
     * 
     * @type {Array<YearMonthRequestBody>}
     * @memberof CreateAllocationStrategyRequestBody
     */
    'yearMonthSet': Array<YearMonthRequestBody>;
}
/**
 * 
 * @export
 * @interface CreateAndAddDimensionTagsRequestBody
 */
export interface CreateAndAddDimensionTagsRequestBody {
    /**
     * 
     * @type {CreateDimensionTagsRequestBody}
     * @memberof CreateAndAddDimensionTagsRequestBody
     */
    'createParam': CreateDimensionTagsRequestBody;
    /**
     * 
     * @type {string}
     * @memberof CreateAndAddDimensionTagsRequestBody
     */
    'parentDimensionTagId': string;
}
/**
 * 
 * @export
 * @interface CreateAndAddToTreeAccountParam
 */
export interface CreateAndAddToTreeAccountParam {
    /**
     * 
     * @type {string}
     * @memberof CreateAndAddToTreeAccountParam
     */
    'accountType': CreateAndAddToTreeAccountParamAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAndAddToTreeAccountParam
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAndAddToTreeAccountParam
     */
    'externalSystemAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAndAddToTreeAccountParam
     */
    'externalSystemSubAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAndAddToTreeAccountParam
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAndAddToTreeAccountParam
     */
    'viewControlType': CreateAndAddToTreeAccountParamViewControlTypeEnum;
}

export const CreateAndAddToTreeAccountParamAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type CreateAndAddToTreeAccountParamAccountTypeEnum = typeof CreateAndAddToTreeAccountParamAccountTypeEnum[keyof typeof CreateAndAddToTreeAccountParamAccountTypeEnum];
export const CreateAndAddToTreeAccountParamViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type CreateAndAddToTreeAccountParamViewControlTypeEnum = typeof CreateAndAddToTreeAccountParamViewControlTypeEnum[keyof typeof CreateAndAddToTreeAccountParamViewControlTypeEnum];

/**
 * 
 * @export
 * @interface CreateBulkPlanUpdateRequestGSheetParam
 */
export interface CreateBulkPlanUpdateRequestGSheetParam {
    /**
     * 
     * @type {string}
     * @memberof CreateBulkPlanUpdateRequestGSheetParam
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBulkPlanUpdateRequestGSheetParam
     */
    'spreadsheetIdOrURL'?: string;
}
/**
 * 
 * @export
 * @interface CreateCalculatedAccountRequestBody
 */
export interface CreateCalculatedAccountRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateCalculatedAccountRequestBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalculatedAccountRequestBody
     */
    'formula': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalculatedAccountRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalculatedAccountRequestBody
     */
    'roundingPrecision': CreateCalculatedAccountRequestBodyRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCalculatedAccountRequestBody
     */
    'valueFormat': CreateCalculatedAccountRequestBodyValueFormatEnum;
}

export const CreateCalculatedAccountRequestBodyRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type CreateCalculatedAccountRequestBodyRoundingPrecisionEnum = typeof CreateCalculatedAccountRequestBodyRoundingPrecisionEnum[keyof typeof CreateCalculatedAccountRequestBodyRoundingPrecisionEnum];
export const CreateCalculatedAccountRequestBodyValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type CreateCalculatedAccountRequestBodyValueFormatEnum = typeof CreateCalculatedAccountRequestBodyValueFormatEnum[keyof typeof CreateCalculatedAccountRequestBodyValueFormatEnum];

/**
 * 
 * @export
 * @interface CreateComparisonSheetSelectedConditionResponse
 */
export interface CreateComparisonSheetSelectedConditionResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateComparisonSheetSelectedConditionResponse
     */
    'comparisonSheetSelectedConditionId': string;
}
/**
 * 
 * @export
 * @interface CreateDepartmentComparisonSheetSelectedConditionRequestBody
 */
export interface CreateDepartmentComparisonSheetSelectedConditionRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'orgTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planAActualEndYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planAActualStartYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planAEndYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planAMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planAShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planAStartYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planAUpdateProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planBActualEndYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planBActualStartYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planBEndYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planBMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planBShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planBStartYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'planBUpdateProjectId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionRequestBody
     */
    'requireAllocatedItem': boolean;
}
/**
 * 
 * @export
 * @interface CreateDepartmentComparisonSheetSelectedConditionResponse
 */
export interface CreateDepartmentComparisonSheetSelectedConditionResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentComparisonSheetSelectedConditionResponse
     */
    'departmentComparisonSheetSelectedConditionId': string;
}
/**
 * 
 * @export
 * @interface CreateDepartmentParam
 */
export interface CreateDepartmentParam {
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentParam
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentParam
     */
    'externalSystemDepartmentCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentParam
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateDimensionTagsRequestBody
 */
export interface CreateDimensionTagsRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateDimensionTagsRequestBody
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDimensionTagsRequestBody
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDimensionTagsRequestBody
     */
    'dimensionTagCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDimensionTagsRequestBody
     */
    'dimensionTagName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDimensionTagsRequestBody
     */
    'exCodes': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateFinancialSheetCommentRequestBody
 */
export interface CreateFinancialSheetCommentRequestBody {
    /**
     * 
     * @type {BaseConditionRequestBody}
     * @memberof CreateFinancialSheetCommentRequestBody
     */
    'baseCondition': BaseConditionRequestBody;
    /**
     * 
     * @type {string}
     * @memberof CreateFinancialSheetCommentRequestBody
     */
    'commentText': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFinancialSheetCommentRequestBody
     */
    'commentType': CreateFinancialSheetCommentRequestBodyCommentTypeEnum;
    /**
     * 
     * @type {DetailConditionRequestBody}
     * @memberof CreateFinancialSheetCommentRequestBody
     */
    'planACondition'?: DetailConditionRequestBody;
    /**
     * 
     * @type {DetailConditionRequestBody}
     * @memberof CreateFinancialSheetCommentRequestBody
     */
    'planBCondition'?: DetailConditionRequestBody;
}

export const CreateFinancialSheetCommentRequestBodyCommentTypeEnum = {
    Amount: 'AMOUNT',
    DiffAmount: 'DIFF_AMOUNT',
    DiffRatio: 'DIFF_RATIO'
} as const;

export type CreateFinancialSheetCommentRequestBodyCommentTypeEnum = typeof CreateFinancialSheetCommentRequestBodyCommentTypeEnum[keyof typeof CreateFinancialSheetCommentRequestBodyCommentTypeEnum];

/**
 * 
 * @export
 * @interface CreateFinancialSheetCommentView
 */
export interface CreateFinancialSheetCommentView {
    /**
     * 
     * @type {string}
     * @memberof CreateFinancialSheetCommentView
     */
    'financialSheetCommentId': string;
}
/**
 * 
 * @export
 * @interface CreateFinancialSimulationRequestBody
 */
export interface CreateFinancialSimulationRequestBody {
    /**
     * 
     * @type {FinancialSimulationPeriod}
     * @memberof CreateFinancialSimulationRequestBody
     */
    'actualPeriod'?: FinancialSimulationPeriod;
    /**
     * 
     * @type {Array<FinancialSimulationCompareTargetRequestParam>}
     * @memberof CreateFinancialSimulationRequestBody
     */
    'compareTargets': Array<FinancialSimulationCompareTargetRequestParam>;
    /**
     * 
     * @type {string}
     * @memberof CreateFinancialSimulationRequestBody
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFinancialSimulationRequestBody
     */
    'name': string;
    /**
     * 
     * @type {FinancialSimulationPeriod}
     * @memberof CreateFinancialSimulationRequestBody
     */
    'period': FinancialSimulationPeriod;
    /**
     * 
     * @type {string}
     * @memberof CreateFinancialSimulationRequestBody
     */
    'planMasterId': string;
}
/**
 * 
 * @export
 * @interface CreateGSheetParam
 */
export interface CreateGSheetParam {
    /**
     * 
     * @type {string}
     * @memberof CreateGSheetParam
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGSheetParam
     */
    'departmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGSheetParam
     */
    'spreadsheetIdOrURL'?: string;
}
/**
 * 
 * @export
 * @interface CreateGroupAndAddToNonFinancialAccountTreeBody
 */
export interface CreateGroupAndAddToNonFinancialAccountTreeBody {
    /**
     * 
     * @type {string}
     * @memberof CreateGroupAndAddToNonFinancialAccountTreeBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupAndAddToNonFinancialAccountTreeBody
     */
    'name': string;
    /**
     * 
     * @type {NonFinancialAccountTreeNodeBody}
     * @memberof CreateGroupAndAddToNonFinancialAccountTreeBody
     */
    'parentNode'?: NonFinancialAccountTreeNodeBody;
}
/**
 * 
 * @export
 * @interface CreateLinkedGSheetParam
 */
export interface CreateLinkedGSheetParam {
    /**
     * 
     * @type {string}
     * @memberof CreateLinkedGSheetParam
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLinkedGSheetParam
     */
    'sheetId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLinkedGSheetParam
     */
    'sheetName': string;
}
/**
 * 
 * @export
 * @interface CreateNoDiffPlanUpdateRequestBody
 */
export interface CreateNoDiffPlanUpdateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateNoDiffPlanUpdateRequestBody
     */
    'departmentId': string;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateAllocationStandardRequestBody
 */
export interface CreateOrUpdateAllocationStandardRequestBody {
    /**
     * 
     * @type {CreateAllocationStandardReferenceRequestBody}
     * @memberof CreateOrUpdateAllocationStandardRequestBody
     */
    'allocationStandardReference'?: CreateAllocationStandardReferenceRequestBody;
    /**
     * 
     * @type {Array<CreateAllocationStandardDepartmentsRequestBody>}
     * @memberof CreateOrUpdateAllocationStandardRequestBody
     */
    'destinationDepartments': Array<CreateAllocationStandardDepartmentsRequestBody>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrUpdateAllocationStandardRequestBody
     */
    'destinationDimensionTagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAllocationStandardRequestBody
     */
    'dimensionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAllocationStandardRequestBody
     */
    'endYearMonth': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAllocationStandardRequestBody
     */
    'isDimensionEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAllocationStandardRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAllocationStandardRequestBody
     */
    'startYearMonth': string;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody
 */
export interface CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody
     */
    'conditionGroupName': string;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateComparisonSheetSelectedConditionRequestBody
 */
export interface CreateOrUpdateComparisonSheetSelectedConditionRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'aggregate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'groupId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'highlightThreshold'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'highlightType'?: CreateOrUpdateComparisonSheetSelectedConditionRequestBodyHighlightTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'orgTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planAActualEndYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planAActualStartYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planAEndYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planAMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planAShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planAStartYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planAUpdateProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planBActualEndYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planBActualStartYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planBEndYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planBMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planBShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planBStartYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'planBUpdateProjectId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateComparisonSheetSelectedConditionRequestBody
     */
    'requireAllocatedItem': boolean;
}

export const CreateOrUpdateComparisonSheetSelectedConditionRequestBodyHighlightTypeEnum = {
    Amount: 'DIFF_AMOUNT',
    Ratio: 'DIFF_RATIO'
} as const;

export type CreateOrUpdateComparisonSheetSelectedConditionRequestBodyHighlightTypeEnum = typeof CreateOrUpdateComparisonSheetSelectedConditionRequestBodyHighlightTypeEnum[keyof typeof CreateOrUpdateComparisonSheetSelectedConditionRequestBodyHighlightTypeEnum];

/**
 * 
 * @export
 * @interface CreateOrganizationTreeParam
 */
export interface CreateOrganizationTreeParam {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationTreeParam
     */
    'treeName': string;
}
/**
 * 
 * @export
 * @interface CreatePlanMaster
 */
export interface CreatePlanMaster {
    /**
     * 
     * @type {string}
     * @memberof CreatePlanMaster
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanMaster
     */
    'planType': CreatePlanMasterPlanTypeEnum;
}

export const CreatePlanMasterPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type CreatePlanMasterPlanTypeEnum = typeof CreatePlanMasterPlanTypeEnum[keyof typeof CreatePlanMasterPlanTypeEnum];

/**
 * 
 * @export
 * @interface CreatePlanUpdateRequestFormatParams
 */
export interface CreatePlanUpdateRequestFormatParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePlanUpdateRequestFormatParams
     */
    'downloadableDepartments': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanUpdateRequestFormatParams
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreatePlanUpdateRequestMappingAccountKeyParams
 */
export interface CreatePlanUpdateRequestMappingAccountKeyParams {
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof CreatePlanUpdateRequestMappingAccountKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanUpdateRequestMappingAccountKeyParams
     */
    'valueType': CreatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum;
}

export const CreatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type CreatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum = typeof CreatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum[keyof typeof CreatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum];

/**
 * 
 * @export
 * @interface CreatePlanUpdateRequestMappingAmountParams
 */
export interface CreatePlanUpdateRequestMappingAmountParams {
    /**
     * 
     * @type {number}
     * @memberof CreatePlanUpdateRequestMappingAmountParams
     */
    'endColumnIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePlanUpdateRequestMappingAmountParams
     */
    'endRowIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePlanUpdateRequestMappingAmountParams
     */
    'startColumnIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePlanUpdateRequestMappingAmountParams
     */
    'startRowIndex': number;
}
/**
 * 
 * @export
 * @interface CreatePlanUpdateRequestMappingDepartmentKeyParams
 */
export interface CreatePlanUpdateRequestMappingDepartmentKeyParams {
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof CreatePlanUpdateRequestMappingDepartmentKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanUpdateRequestMappingDepartmentKeyParams
     */
    'valueType': CreatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum;
}

export const CreatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type CreatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum = typeof CreatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum[keyof typeof CreatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum];

/**
 * 
 * @export
 * @interface CreatePlanUpdateRequestMappingDescriptionKeyParams
 */
export interface CreatePlanUpdateRequestMappingDescriptionKeyParams {
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof CreatePlanUpdateRequestMappingDescriptionKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
}
/**
 * 
 * @export
 * @interface CreatePlanUpdateRequestMappingDimensionKeyParams
 */
export interface CreatePlanUpdateRequestMappingDimensionKeyParams {
    /**
     * 
     * @type {string}
     * @memberof CreatePlanUpdateRequestMappingDimensionKeyParams
     */
    'dimensionId': string;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof CreatePlanUpdateRequestMappingDimensionKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanUpdateRequestMappingDimensionKeyParams
     */
    'valueType': CreatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum;
}

export const CreatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type CreatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum = typeof CreatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum[keyof typeof CreatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum];

/**
 * 
 * @export
 * @interface CreatePlanUpdateRequestMappingFilterParams
 */
export interface CreatePlanUpdateRequestMappingFilterParams {
    /**
     * 
     * @type {string}
     * @memberof CreatePlanUpdateRequestMappingFilterParams
     */
    'conditionType': CreatePlanUpdateRequestMappingFilterParamsConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanUpdateRequestMappingFilterParams
     */
    'conditionValue': string;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof CreatePlanUpdateRequestMappingFilterParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
}

export const CreatePlanUpdateRequestMappingFilterParamsConditionTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Empty: 'EMPTY',
    NotEmpty: 'NOT_EMPTY'
} as const;

export type CreatePlanUpdateRequestMappingFilterParamsConditionTypeEnum = typeof CreatePlanUpdateRequestMappingFilterParamsConditionTypeEnum[keyof typeof CreatePlanUpdateRequestMappingFilterParamsConditionTypeEnum];

/**
 * 
 * @export
 * @interface CreatePlanUpdateRequestMappingParams
 */
export interface CreatePlanUpdateRequestMappingParams {
    /**
     * 
     * @type {CreatePlanUpdateRequestMappingAccountKeyParams}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'accountKey': CreatePlanUpdateRequestMappingAccountKeyParams;
    /**
     * 
     * @type {CreatePlanUpdateRequestMappingAmountParams}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'amountParams': CreatePlanUpdateRequestMappingAmountParams;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'canUseForDownload': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'canUseForUpload': boolean;
    /**
     * 
     * @type {CreatePlanUpdateRequestMappingDepartmentKeyParams}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'departmentKey': CreatePlanUpdateRequestMappingDepartmentKeyParams;
    /**
     * 
     * @type {CreatePlanUpdateRequestMappingDescriptionKeyParams}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'descriptionKey'?: CreatePlanUpdateRequestMappingDescriptionKeyParams;
    /**
     * 
     * @type {Array<CreatePlanUpdateRequestMappingDimensionKeyParams>}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'dimensionKeys': Array<CreatePlanUpdateRequestMappingDimensionKeyParams>;
    /**
     * 
     * @type {Array<CreatePlanUpdateRequestMappingFilterParams>}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'filters': Array<CreatePlanUpdateRequestMappingFilterParams>;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'planUpdateRequestMappingUniqueKey'?: string;
    /**
     * 
     * @type {CreatePlanUpdateRequestMappingYearMonthKeyParams}
     * @memberof CreatePlanUpdateRequestMappingParams
     */
    'yearMonthKey': CreatePlanUpdateRequestMappingYearMonthKeyParams;
}
/**
 * 
 * @export
 * @interface CreatePlanUpdateRequestMappingYearMonthKeyParams
 */
export interface CreatePlanUpdateRequestMappingYearMonthKeyParams {
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof CreatePlanUpdateRequestMappingYearMonthKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
}
/**
 * 
 * @export
 * @interface CreatePlanningProjectDepartmentCommentBody
 */
export interface CreatePlanningProjectDepartmentCommentBody {
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectDepartmentCommentBody
     */
    'commentText': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectDepartmentCommentBody
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectDepartmentCommentBody
     */
    'planningProjectId': string;
}
/**
 * 
 * @export
 * @interface CreatePlanningProjectParam
 */
export interface CreatePlanningProjectParam {
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectParam
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectParam
     */
    'actualDownloadPeriodEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectParam
     */
    'actualDownloadPeriodStart'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePlanningProjectParam
     */
    'assignedDepartments': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlanningProjectParam
     */
    'canActualDownload': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlanningProjectParam
     */
    'canRequestActualMonth': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectParam
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectParam
     */
    'orgTreeId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlanningProjectParam
     */
    'requirePlanMasterAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectParam
     */
    'scheduledPlanningProjectEndAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectParam
     */
    'targetEnd': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanningProjectParam
     */
    'targetStart': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlanningProjectParam
     */
    'useMultiStepApprovalFlow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePlanningProjectParam
     */
    'withSyncWithActualWorkforceChangeEvents': boolean;
}
/**
 * 
 * @export
 * @interface CreateReportBody
 */
export interface CreateReportBody {
    /**
     * 
     * @type {string}
     * @memberof CreateReportBody
     */
    'folderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReportBody
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReportBody
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface CreateReportDescriptionBody
 */
export interface CreateReportDescriptionBody {
    /**
     * 
     * @type {string}
     * @memberof CreateReportDescriptionBody
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface CreateReportFolderBody
 */
export interface CreateReportFolderBody {
    /**
     * 
     * @type {string}
     * @memberof CreateReportFolderBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReportFolderBody
     */
    'parentFolderId'?: string;
}
/**
 * 
 * @export
 * @interface CreateReportFromTemplateBody
 */
export interface CreateReportFromTemplateBody {
    /**
     * 
     * @type {string}
     * @memberof CreateReportFromTemplateBody
     */
    'folderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReportFromTemplateBody
     */
    'templateType': CreateReportFromTemplateBodyTemplateTypeEnum;
}

export const CreateReportFromTemplateBodyTemplateTypeEnum = {
    PlHistory: 'PLHistory',
    PlComparison: 'PLComparison'
} as const;

export type CreateReportFromTemplateBodyTemplateTypeEnum = typeof CreateReportFromTemplateBodyTemplateTypeEnum[keyof typeof CreateReportFromTemplateBodyTemplateTypeEnum];

/**
 * 
 * @export
 * @interface CreateSimulationBody
 */
export interface CreateSimulationBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSimulationBody
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateSimulationBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSimulationBody
     */
    'planMasterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSimulationBody
     */
    'planningProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSimulationBody
     */
    'targetPeriodEnd': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSimulationBody
     */
    'targetPeriodStart': string;
}
/**
 * 
 * @export
 * @interface CreateStrategyResultDto
 */
export interface CreateStrategyResultDto {
    /**
     * 
     * @type {string}
     * @memberof CreateStrategyResultDto
     */
    'planId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStrategyResultDto
     */
    'targetType': CreateStrategyResultDtoTargetTypeEnum;
}

export const CreateStrategyResultDtoTargetTypeEnum = {
    Actual: 'ACTUAL',
    Plan: 'PLAN'
} as const;

export type CreateStrategyResultDtoTargetTypeEnum = typeof CreateStrategyResultDtoTargetTypeEnum[keyof typeof CreateStrategyResultDtoTargetTypeEnum];

/**
 * 
 * @export
 * @interface CreateTransferStepRequestBody
 */
export interface CreateTransferStepRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateTransferStepRequestBody
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransferStepRequestBody
     */
    'destinationAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransferStepRequestBody
     */
    'destinationDepartmentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTransferStepRequestBody
     */
    'destinationDimensionTagIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateTransferStepRequestBody
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTransferStepRequestBody
     */
    'sourceAccountIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTransferStepRequestBody
     */
    'sourceDepartmentIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTransferStepRequestBody
     */
    'sourceDimensionTagIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateUserParam
 */
export interface CreateUserParam {
    /**
     * 
     * @type {string}
     * @memberof CreateUserParam
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParam
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParam
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserParam
     */
    'requireMFA': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParam
     */
    'role': CreateUserParamRoleEnum;
}

export const CreateUserParamRoleEnum = {
    LoglassSystemAdmin: 'LOGLASS_SYSTEM_ADMIN',
    TenantAdmin: 'TENANT_ADMIN',
    CorpPlanning: 'CORP_PLANNING',
    Manager: 'MANAGER',
    ItStaff: 'IT_STAFF',
    General: 'GENERAL'
} as const;

export type CreateUserParamRoleEnum = typeof CreateUserParamRoleEnum[keyof typeof CreateUserParamRoleEnum];

/**
 * 
 * @export
 * @interface CreateViewPermissionParams
 */
export interface CreateViewPermissionParams {
    /**
     * 
     * @type {string}
     * @memberof CreateViewPermissionParams
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateViewPermissionParams
     */
    'selectedAccounts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateViewPermissionParams
     */
    'selectedCalculatedAccounts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateViewPermissionParams
     */
    'users': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateViewPermissionParams
     */
    'viewableAccountScope': CreateViewPermissionParamsViewableAccountScopeEnum;
}

export const CreateViewPermissionParamsViewableAccountScopeEnum = {
    All: 'ALL',
    Selected: 'SELECTED'
} as const;

export type CreateViewPermissionParamsViewableAccountScopeEnum = typeof CreateViewPermissionParamsViewableAccountScopeEnum[keyof typeof CreateViewPermissionParamsViewableAccountScopeEnum];

/**
 * 
 * @export
 * @interface CreateWorkforceActualImportMappingTargetDimensionParam
 */
export interface CreateWorkforceActualImportMappingTargetDimensionParam {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkforceActualImportMappingTargetDimensionParam
     */
    'columnAlphabet': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkforceActualImportMappingTargetDimensionParam
     */
    'dimensionId': string;
}
/**
 * 
 * @export
 * @interface CreateWorkforceActualImportMappingTargetParam
 */
export interface CreateWorkforceActualImportMappingTargetParam {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkforceActualImportMappingTargetParam
     */
    'columnAlphabet': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkforceActualImportMappingTargetParam
     */
    'type': CreateWorkforceActualImportMappingTargetParamTypeEnum;
}

export const CreateWorkforceActualImportMappingTargetParamTypeEnum = {
    WorkforceCode: 'WORKFORCE_CODE',
    LastName: 'LAST_NAME',
    FirstName: 'FIRST_NAME',
    StartDate: 'START_DATE',
    EndDate: 'END_DATE',
    EmploymentStatus: 'EMPLOYMENT_STATUS',
    ExternalSystemDepartmentCode: 'EXTERNAL_SYSTEM_DEPARTMENT_CODE',
    EffectiveDate: 'EFFECTIVE_DATE'
} as const;

export type CreateWorkforceActualImportMappingTargetParamTypeEnum = typeof CreateWorkforceActualImportMappingTargetParamTypeEnum[keyof typeof CreateWorkforceActualImportMappingTargetParamTypeEnum];

/**
 * 
 * @export
 * @interface CreateWorkforceActualImportRowFilterParam
 */
export interface CreateWorkforceActualImportRowFilterParam {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkforceActualImportRowFilterParam
     */
    'columnAlphabet': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkforceActualImportRowFilterParam
     */
    'conditionType': CreateWorkforceActualImportRowFilterParamConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkforceActualImportRowFilterParam
     */
    'conditionValue'?: string;
}

export const CreateWorkforceActualImportRowFilterParamConditionTypeEnum = {
    Equal: 'EQUAL',
    NotEqual: 'NOT_EQUAL',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Entered: 'ENTERED',
    NotEntered: 'NOT_ENTERED'
} as const;

export type CreateWorkforceActualImportRowFilterParamConditionTypeEnum = typeof CreateWorkforceActualImportRowFilterParamConditionTypeEnum[keyof typeof CreateWorkforceActualImportRowFilterParamConditionTypeEnum];

/**
 * 
 * @export
 * @interface CreatedFinancialSimulationDto
 */
export interface CreatedFinancialSimulationDto {
    /**
     * 
     * @type {FinancialModelDto}
     * @memberof CreatedFinancialSimulationDto
     */
    'model': FinancialModelDto;
    /**
     * 
     * @type {ScenarioDto}
     * @memberof CreatedFinancialSimulationDto
     */
    'scenario': ScenarioDto;
    /**
     * 
     * @type {FinancialSimulationDto}
     * @memberof CreatedFinancialSimulationDto
     */
    'simulation': FinancialSimulationDto;
}
/**
 * 
 * @export
 * @interface CreatedGSheetView
 */
export interface CreatedGSheetView {
    /**
     * 
     * @type {string}
     * @memberof CreatedGSheetView
     */
    'spreadsheetId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatedGSheetView
     */
    'spreadsheetUrl': string;
}
/**
 * 
 * @export
 * @interface CreatedSimulationView
 */
export interface CreatedSimulationView {
    /**
     * 
     * @type {string}
     * @memberof CreatedSimulationView
     */
    'simulationId': string;
}
/**
 * 
 * @export
 * @interface CurrencyMaster
 */
export interface CurrencyMaster {
    /**
     * 
     * @type {string}
     * @memberof CurrencyMaster
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyMaster
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CurrentUserView
 */
export interface CurrentUserView {
    /**
     * 
     * @type {string}
     * @memberof CurrentUserView
     */
    'auth0UserId': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserView
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserView
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserView
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserView
     */
    'role': CurrentUserViewRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserView
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserView
     */
    'tenantName': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserView
     */
    'userId': string;
}

export const CurrentUserViewRoleEnum = {
    LoglassSystemAdmin: 'LOGLASS_SYSTEM_ADMIN',
    TenantAdmin: 'TENANT_ADMIN',
    CorpPlanning: 'CORP_PLANNING',
    Manager: 'MANAGER',
    ItStaff: 'IT_STAFF',
    General: 'GENERAL'
} as const;

export type CurrentUserViewRoleEnum = typeof CurrentUserViewRoleEnum[keyof typeof CurrentUserViewRoleEnum];

/**
 * 
 * @export
 * @interface DataCellView
 */
export interface DataCellView {
    /**
     * 
     * @type {string}
     * @memberof DataCellView
     */
    'dataConditionId': DataCellViewDataConditionIdEnum;
    /**
     * 
     * @type {EitherAmountOrErrorView}
     * @memberof DataCellView
     */
    'eitherAmountOrError'?: EitherAmountOrErrorView;
    /**
     * 
     * @type {string}
     * @memberof DataCellView
     */
    'id': string;
    /**
     * 
     * @type {CellGroupOrderKey}
     * @memberof DataCellView
     */
    'orderKey': CellGroupOrderKey;
    /**
     * 
     * @type {ReportCellType}
     * @memberof DataCellView
     */
    'type': ReportCellType;
    /**
     * 
     * @type {UniqueKeyView}
     * @memberof DataCellView
     */
    'uniqueKey': UniqueKeyView;
}

export const DataCellViewDataConditionIdEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;

export type DataCellViewDataConditionIdEnum = typeof DataCellViewDataConditionIdEnum[keyof typeof DataCellViewDataConditionIdEnum];

/**
 * 
 * @export
 * @interface DataCondition
 */
export interface DataCondition {
    /**
     * 
     * @type {string}
     * @memberof DataCondition
     */
    'accountTreeId': string;
    /**
     * 
     * @type {Period}
     * @memberof DataCondition
     */
    'actualPeriod'?: Period;
    /**
     * 
     * @type {Array<DimensionFilter>}
     * @memberof DataCondition
     */
    'dimensionFilters': Array<DimensionFilter>;
    /**
     * 
     * @type {string}
     * @memberof DataCondition
     */
    'id': DataConditionIdEnum;
    /**
     * 
     * @type {string}
     * @memberof DataCondition
     */
    'nonFinancialAccountTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCondition
     */
    'orgTreeId': string;
    /**
     * 
     * @type {Period}
     * @memberof DataCondition
     */
    'period': Period;
    /**
     * 
     * @type {PlanId}
     * @memberof DataCondition
     */
    'planId'?: PlanId;
    /**
     * 
     * @type {boolean}
     * @memberof DataCondition
     */
    'requireAllocatedItem': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataCondition
     */
    'showActual': boolean;
}

export const DataConditionIdEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;

export type DataConditionIdEnum = typeof DataConditionIdEnum[keyof typeof DataConditionIdEnum];

/**
 * 
 * @export
 * @interface DateTime
 */
export interface DateTime {
    /**
     * 
     * @type {string}
     * @memberof DateTime
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface DefaultTenantView
 */
export interface DefaultTenantView {
    /**
     * 
     * @type {string}
     * @memberof DefaultTenantView
     */
    'tenantId': string;
}
/**
 * 
 * @export
 * @interface DeleteReportRequestBody
 */
export interface DeleteReportRequestBody {
    /**
     * 
     * @type {string}
     * @memberof DeleteReportRequestBody
     */
    'reportType': DeleteReportRequestBodyReportTypeEnum;
}

export const DeleteReportRequestBodyReportTypeEnum = {
    Pl: 'PL',
    Bs: 'BS',
    AiPlanActualAnalysisReport: 'AI_PLAN_ACTUAL_ANALYSIS_REPORT'
} as const;

export type DeleteReportRequestBodyReportTypeEnum = typeof DeleteReportRequestBodyReportTypeEnum[keyof typeof DeleteReportRequestBodyReportTypeEnum];

/**
 * 
 * @export
 * @interface DepartmentAssignedActiveUserView
 */
export interface DepartmentAssignedActiveUserView {
    /**
     * 
     * @type {string}
     * @memberof DepartmentAssignedActiveUserView
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentAssignedActiveUserView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentAssignedActiveUserView
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentAssignedActiveUserView
     */
    'role': DepartmentAssignedActiveUserViewRoleEnum;
}

export const DepartmentAssignedActiveUserViewRoleEnum = {
    LoglassSystemAdmin: 'LOGLASS_SYSTEM_ADMIN',
    TenantAdmin: 'TENANT_ADMIN',
    CorpPlanning: 'CORP_PLANNING',
    Manager: 'MANAGER',
    ItStaff: 'IT_STAFF',
    General: 'GENERAL'
} as const;

export type DepartmentAssignedActiveUserViewRoleEnum = typeof DepartmentAssignedActiveUserViewRoleEnum[keyof typeof DepartmentAssignedActiveUserViewRoleEnum];

/**
 * 
 * @export
 * @interface DepartmentComparisonSheetSelectedConditionDetailDto
 */
export interface DepartmentComparisonSheetSelectedConditionDetailDto {
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'accountTreeId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'orgTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planAActualEndYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planAActualStartYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planAEndYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planAMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planAShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planAStartYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planAUpdateProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planBActualEndYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planBActualStartYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planBEndYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planBMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planBShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planBStartYearMonth': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'planBUpdateProjectId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentComparisonSheetSelectedConditionDetailDto
     */
    'requireAllocatedItem': boolean;
}
/**
 * 
 * @export
 * @interface DepartmentComparisonSheetSelectedConditionDto
 */
export interface DepartmentComparisonSheetSelectedConditionDto {
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDto
     */
    'departmentComparisonSheetSelectedConditionId': string;
    /**
     * 
     * @type {DepartmentComparisonSheetSelectedConditionDetailDto}
     * @memberof DepartmentComparisonSheetSelectedConditionDto
     */
    'detail': DepartmentComparisonSheetSelectedConditionDetailDto;
    /**
     * 
     * @type {string}
     * @memberof DepartmentComparisonSheetSelectedConditionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DepartmentView
 */
export interface DepartmentView {
    /**
     * 
     * @type {string}
     * @memberof DepartmentView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentView
     */
    'externalSystemDepartmentCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DepartmentViewWithIsAssignedAndIsArchived
 */
export interface DepartmentViewWithIsAssignedAndIsArchived {
    /**
     * 
     * @type {DepartmentView}
     * @memberof DepartmentViewWithIsAssignedAndIsArchived
     */
    'department': DepartmentView;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentViewWithIsAssignedAndIsArchived
     */
    'isArchived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentViewWithIsAssignedAndIsArchived
     */
    'isAssigned': boolean;
}
/**
 * 
 * @export
 * @interface DepartmentWithAssignedActiveUsersView
 */
export interface DepartmentWithAssignedActiveUsersView {
    /**
     * 
     * @type {DepartmentView}
     * @memberof DepartmentWithAssignedActiveUsersView
     */
    'department': DepartmentView;
    /**
     * 
     * @type {Array<DepartmentAssignedActiveUserView>}
     * @memberof DepartmentWithAssignedActiveUsersView
     */
    'users': Array<DepartmentAssignedActiveUserView>;
}
/**
 * 
 * @export
 * @interface DepartmentWithAssignmentView
 */
export interface DepartmentWithAssignmentView {
    /**
     * 
     * @type {AssignmentEachDepartmentView}
     * @memberof DepartmentWithAssignmentView
     */
    'assignment': AssignmentEachDepartmentView;
    /**
     * 
     * @type {DepartmentView}
     * @memberof DepartmentWithAssignmentView
     */
    'department': DepartmentView;
}
/**
 * 
 * @export
 * @interface DepartmentsView
 */
export interface DepartmentsView {
    /**
     * 
     * @type {Array<DepartmentView>}
     * @memberof DepartmentsView
     */
    'items': Array<DepartmentView>;
}
/**
 * 
 * @export
 * @interface DetachNonFinancialAccountTreeNodeBody
 */
export interface DetachNonFinancialAccountTreeNodeBody {
    /**
     * 
     * @type {NonFinancialAccountTreeNodeBody}
     * @memberof DetachNonFinancialAccountTreeNodeBody
     */
    'targetNode': NonFinancialAccountTreeNodeBody;
}
/**
 * 
 * @export
 * @interface DetailConditionRequestBody
 */
export interface DetailConditionRequestBody {
    /**
     * 
     * @type {number}
     * @memberof DetailConditionRequestBody
     */
    'commentedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailConditionRequestBody
     */
    'periodKeyValue': string;
    /**
     * 
     * @type {string}
     * @memberof DetailConditionRequestBody
     */
    'planMasterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailConditionRequestBody
     */
    'planningProjectId'?: string;
}
/**
 * 
 * @export
 * @interface DiffRatioView
 */
export interface DiffRatioView {
    /**
     * 
     * @type {number}
     * @memberof DiffRatioView
     */
    'diff'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiffRatioView
     */
    'diffCommentCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiffRatioView
     */
    'ratio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiffRatioView
     */
    'ratioCommentCount'?: number;
}
/**
 * 
 * @export
 * @interface DimensionFilter
 */
export interface DimensionFilter {
    /**
     * 
     * @type {string}
     * @memberof DimensionFilter
     */
    'dimensionTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DimensionFilter
     */
    'dimensionType': DimensionFilterDimensionTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DimensionFilter
     */
    'ids': Array<string>;
}

export const DimensionFilterDimensionTypeEnum = {
    Account: 'ACCOUNT',
    Department: 'DEPARTMENT',
    Dimension1: 'DIMENSION_1',
    Dimension2: 'DIMENSION_2',
    Dimension3: 'DIMENSION_3',
    Dimension4: 'DIMENSION_4',
    Dimension5: 'DIMENSION_5',
    Dimension6: 'DIMENSION_6',
    Dimension7: 'DIMENSION_7'
} as const;

export type DimensionFilterDimensionTypeEnum = typeof DimensionFilterDimensionTypeEnum[keyof typeof DimensionFilterDimensionTypeEnum];

/**
 * 
 * @export
 * @interface DimensionIdToDimensionTagId
 */
export interface DimensionIdToDimensionTagId {
    /**
     * 
     * @type {string}
     * @memberof DimensionIdToDimensionTagId
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionIdToDimensionTagId
     */
    'dimensionTagId': string;
}
/**
 * 
 * @export
 * @interface DimensionName
 */
export interface DimensionName {
    /**
     * 
     * @type {string}
     * @memberof DimensionName
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface DimensionTagFlatTreeNodeView
 */
export interface DimensionTagFlatTreeNodeView {
    /**
     * 
     * @type {string}
     * @memberof DimensionTagFlatTreeNodeView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTagFlatTreeNodeView
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DimensionTagFlatTreeNodeView
     */
    'exCodes': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DimensionTagFlatTreeNodeView
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof DimensionTagFlatTreeNodeView
     */
    'level': number;
    /**
     * 
     * @type {boolean}
     * @memberof DimensionTagFlatTreeNodeView
     */
    'matched': boolean;
    /**
     * 
     * @type {MatchedDimensionTagKeywordView}
     * @memberof DimensionTagFlatTreeNodeView
     */
    'matchedKeyword': MatchedDimensionTagKeywordView;
    /**
     * 
     * @type {string}
     * @memberof DimensionTagFlatTreeNodeView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTagFlatTreeNodeView
     */
    'parentId'?: string;
}
/**
 * 
 * @export
 * @interface DimensionTagFlatTreeView
 */
export interface DimensionTagFlatTreeView {
    /**
     * 
     * @type {string}
     * @memberof DimensionTagFlatTreeView
     */
    'id': string;
    /**
     * 
     * @type {Array<DimensionTagFlatTreeNodeView>}
     * @memberof DimensionTagFlatTreeView
     */
    'items': Array<DimensionTagFlatTreeNodeView>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DimensionTagFlatTreeView
     */
    'openNodeIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DimensionTagInfoView
 */
export interface DimensionTagInfoView {
    /**
     * 
     * @type {string}
     * @memberof DimensionTagInfoView
     */
    'dimensionName': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTagInfoView
     */
    'dimensionTagName': string;
}
/**
 * 
 * @export
 * @interface DimensionTagListView
 */
export interface DimensionTagListView {
    /**
     * 
     * @type {string}
     * @memberof DimensionTagListView
     */
    'dimensionName': string;
    /**
     * 
     * @type {PagingDto}
     * @memberof DimensionTagListView
     */
    'paging': PagingDto;
    /**
     * 
     * @type {Array<DimensionTagView>}
     * @memberof DimensionTagListView
     */
    'tags': Array<DimensionTagView>;
}
/**
 * 
 * @export
 * @interface DimensionTagView
 */
export interface DimensionTagView {
    /**
     * 
     * @type {string}
     * @memberof DimensionTagView
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTagView
     */
    'dimensionTagCode': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTagView
     */
    'dimensionTagId': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTagView
     */
    'dimensionTagName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DimensionTagView
     */
    'exCodes': Array<string>;
}
/**
 * 
 * @export
 * @interface DimensionTagsView
 */
export interface DimensionTagsView {
    /**
     * 
     * @type {Array<DimensionTagView>}
     * @memberof DimensionTagsView
     */
    'items': Array<DimensionTagView>;
}
/**
 * 
 * @export
 * @interface DimensionTreeForSelectionView
 */
export interface DimensionTreeForSelectionView {
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeForSelectionView
     */
    'id': string;
    /**
     * 
     * @type {Array<DimensionTreeNodesForSelectionView>}
     * @memberof DimensionTreeForSelectionView
     */
    'items': Array<DimensionTreeNodesForSelectionView>;
    /**
     * 
     * @type {Array<DimensionTreeNodesForSelectionView>}
     * @memberof DimensionTreeForSelectionView
     */
    'notIncludedItems': Array<DimensionTreeNodesForSelectionView>;
}
/**
 * 
 * @export
 * @interface DimensionTreeListView
 */
export interface DimensionTreeListView {
    /**
     * 
     * @type {Array<DimensionTreeView>}
     * @memberof DimensionTreeListView
     */
    'items': Array<DimensionTreeView>;
}
/**
 * 
 * @export
 * @interface DimensionTreeMetaView
 */
export interface DimensionTreeMetaView {
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeMetaView
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeMetaView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeMetaView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DimensionTreeNodeForSelectionView
 */
export interface DimensionTreeNodeForSelectionView {
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeNodeForSelectionView
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeNodeForSelectionView
     */
    'dimensionTagCode': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeNodeForSelectionView
     */
    'dimensionTagId': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeNodeForSelectionView
     */
    'dimensionTagName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DimensionTreeNodeForSelectionView
     */
    'exCodes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DimensionTreeNodeForSelectionView
     */
    'isOwnValue': boolean;
    /**
     * 
     * @type {number}
     * @memberof DimensionTreeNodeForSelectionView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeNodeForSelectionView
     */
    'parentId'?: string;
}
/**
 * 
 * @export
 * @interface DimensionTreeNodesForSelectionView
 */
export interface DimensionTreeNodesForSelectionView {
    /**
     * 
     * @type {TreeNodeDimensionTreeNodeForSelectionView}
     * @memberof DimensionTreeNodesForSelectionView
     */
    'value': TreeNodeDimensionTreeNodeForSelectionView;
}
/**
 * 
 * @export
 * @interface DimensionTreeView
 */
export interface DimensionTreeView {
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeView
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeView
     */
    'dimensionTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionTreeView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DimensionView
 */
export interface DimensionView {
    /**
     * 
     * @type {string}
     * @memberof DimensionView
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof DimensionView
     */
    'dimensionName': string;
    /**
     * 
     * @type {number}
     * @memberof DimensionView
     */
    'dimensionNumber': number;
    /**
     * 
     * @type {string}
     * @memberof DimensionView
     */
    'importColumnName': string;
}
/**
 * 
 * @export
 * @interface DisconnectWorkforceSlotFromPersonRequestBody
 */
export interface DisconnectWorkforceSlotFromPersonRequestBody {
    /**
     * 
     * @type {string}
     * @memberof DisconnectWorkforceSlotFromPersonRequestBody
     */
    'actualChangeEventId': string;
}
/**
 * 
 * @export
 * @interface DownloadActualExcelRequestBody
 */
export interface DownloadActualExcelRequestBody {
    /**
     * 
     * @type {string}
     * @memberof DownloadActualExcelRequestBody
     */
    'actualEnd': string;
    /**
     * 
     * @type {string}
     * @memberof DownloadActualExcelRequestBody
     */
    'actualStart': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DownloadActualExcelRequestBody
     */
    'departmentIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadActualExcelRequestBody
     */
    'isAsc': boolean;
    /**
     * 
     * @type {string}
     * @memberof DownloadActualExcelRequestBody
     */
    'orgTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof DownloadActualExcelRequestBody
     */
    'period'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadActualExcelRequestBody
     */
    'requireAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof DownloadActualExcelRequestBody
     */
    'rowType': DownloadActualExcelRequestBodyRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DownloadActualExcelRequestBody
     */
    'sortKey': DownloadActualExcelRequestBodySortKeyEnum;
}

export const DownloadActualExcelRequestBodyRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type DownloadActualExcelRequestBodyRowTypeEnum = typeof DownloadActualExcelRequestBodyRowTypeEnum[keyof typeof DownloadActualExcelRequestBodyRowTypeEnum];
export const DownloadActualExcelRequestBodySortKeyEnum = {
    JournalDate: 'JOURNAL_DATE',
    DepartmentName: 'DEPARTMENT_NAME',
    AccountName: 'ACCOUNT_NAME',
    SupplierName: 'SUPPLIER_NAME',
    Description: 'DESCRIPTION',
    Amount: 'AMOUNT'
} as const;

export type DownloadActualExcelRequestBodySortKeyEnum = typeof DownloadActualExcelRequestBodySortKeyEnum[keyof typeof DownloadActualExcelRequestBodySortKeyEnum];

/**
 * 
 * @export
 * @interface DownloadRequestBody
 */
export interface DownloadRequestBody {
    /**
     * 
     * @type {string}
     * @memberof DownloadRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {ActualPlanSelect}
     * @memberof DownloadRequestBody
     */
    'actualPlanSelect': ActualPlanSelect;
    /**
     * 
     * @type {Array<MaybeOwnValueDepartmentId>}
     * @memberof DownloadRequestBody
     */
    'departmentIds': Array<MaybeOwnValueDepartmentId>;
    /**
     * 
     * @type {string}
     * @memberof DownloadRequestBody
     */
    'format': DownloadRequestBodyFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof DownloadRequestBody
     */
    'nonFinancialAccountTreeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadRequestBody
     */
    'requireAllocatedItems': boolean;
    /**
     * 
     * @type {TargetYearMonthRange}
     * @memberof DownloadRequestBody
     */
    'targetMonthRange': TargetYearMonthRange;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadRequestBody
     */
    'withExCode': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadRequestBody
     */
    'withLoglassCode': boolean;
}

export const DownloadRequestBodyFormatEnum = {
    Raw: 'Raw',
    Aggregate: 'Aggregate'
} as const;

export type DownloadRequestBodyFormatEnum = typeof DownloadRequestBodyFormatEnum[keyof typeof DownloadRequestBodyFormatEnum];

/**
 * 
 * @export
 * @interface EachConcurrentPost
 */
export interface EachConcurrentPost {
    /**
     * 
     * @type {string}
     * @memberof EachConcurrentPost
     */
    'departmentName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EachConcurrentPost
     */
    'dimensionTagNames': Array<string>;
    /**
     * 
     * @type {Array<EachConcurrentPostItem>}
     * @memberof EachConcurrentPost
     */
    'eachConcurrentPostItems': Array<EachConcurrentPostItem>;
}
/**
 * 
 * @export
 * @interface EachConcurrentPostDiff
 */
export interface EachConcurrentPostDiff {
    /**
     * 
     * @type {string}
     * @memberof EachConcurrentPostDiff
     */
    'departmentName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EachConcurrentPostDiff
     */
    'dimensionTagNames': Array<string>;
    /**
     * 
     * @type {Array<EachConcurrentPostDiffItem>}
     * @memberof EachConcurrentPostDiff
     */
    'eachConcurrentPostDiffItems': Array<EachConcurrentPostDiffItem>;
}
/**
 * 
 * @export
 * @interface EachConcurrentPostDiffItem
 */
export interface EachConcurrentPostDiffItem {
    /**
     * 
     * @type {number}
     * @memberof EachConcurrentPostDiffItem
     */
    'afterAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EachConcurrentPostDiffItem
     */
    'beforeAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EachConcurrentPostDiffItem
     */
    'diffAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof EachConcurrentPostDiffItem
     */
    'yearMonthId': string;
}
/**
 * 
 * @export
 * @interface EachConcurrentPostItem
 */
export interface EachConcurrentPostItem {
    /**
     * 
     * @type {number}
     * @memberof EachConcurrentPostItem
     */
    'sum': number;
    /**
     * 
     * @type {string}
     * @memberof EachConcurrentPostItem
     */
    'yearMonthId': string;
}
/**
 * 
 * @export
 * @interface EachDepartmentComparisonCellDetailV2View
 */
export interface EachDepartmentComparisonCellDetailV2View {
    /**
     * 
     * @type {boolean}
     * @memberof EachDepartmentComparisonCellDetailV2View
     */
    'hasDetails': boolean;
    /**
     * 
     * @type {number}
     * @memberof EachDepartmentComparisonCellDetailV2View
     */
    'sum'?: number;
}
/**
 * 
 * @export
 * @interface EachDepartmentComparisonCellTypeV2View
 */
export interface EachDepartmentComparisonCellTypeV2View {
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonCellTypeV2View
     */
    'columnTypeA': EachDepartmentComparisonCellTypeV2ViewColumnTypeAEnum;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonCellTypeV2View
     */
    'columnTypeB': EachDepartmentComparisonCellTypeV2ViewColumnTypeBEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EachDepartmentComparisonCellTypeV2View
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonCellTypeV2View
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonCellTypeV2View
     */
    'topDepartmentCode': string;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonCellTypeV2View
     */
    'topDepartmentName': string;
}

export const EachDepartmentComparisonCellTypeV2ViewColumnTypeAEnum = {
    ActualBudget: 'ACTUAL_BUDGET',
    ActualForecast: 'ACTUAL_FORECAST',
    ActualBudgetMixed: 'ACTUAL_BUDGET_MIXED',
    ActualForecastMixed: 'ACTUAL_FORECAST_MIXED',
    ActualNull: 'ACTUAL_NULL',
    NullNull: 'NULL_NULL',
    NullBudget: 'NULL_BUDGET',
    NullForecast: 'NULL_FORECAST'
} as const;

export type EachDepartmentComparisonCellTypeV2ViewColumnTypeAEnum = typeof EachDepartmentComparisonCellTypeV2ViewColumnTypeAEnum[keyof typeof EachDepartmentComparisonCellTypeV2ViewColumnTypeAEnum];
export const EachDepartmentComparisonCellTypeV2ViewColumnTypeBEnum = {
    ActualBudget: 'ACTUAL_BUDGET',
    ActualForecast: 'ACTUAL_FORECAST',
    ActualBudgetMixed: 'ACTUAL_BUDGET_MIXED',
    ActualForecastMixed: 'ACTUAL_FORECAST_MIXED',
    ActualNull: 'ACTUAL_NULL',
    NullNull: 'NULL_NULL',
    NullBudget: 'NULL_BUDGET',
    NullForecast: 'NULL_FORECAST'
} as const;

export type EachDepartmentComparisonCellTypeV2ViewColumnTypeBEnum = typeof EachDepartmentComparisonCellTypeV2ViewColumnTypeBEnum[keyof typeof EachDepartmentComparisonCellTypeV2ViewColumnTypeBEnum];

/**
 * 
 * @export
 * @interface EachDepartmentComparisonCellV2View
 */
export interface EachDepartmentComparisonCellV2View {
    /**
     * 
     * @type {EachDepartmentComparisonCellDetailV2View}
     * @memberof EachDepartmentComparisonCellV2View
     */
    'comparisonA': EachDepartmentComparisonCellDetailV2View;
    /**
     * 
     * @type {EachDepartmentComparisonCellDetailV2View}
     * @memberof EachDepartmentComparisonCellV2View
     */
    'comparisonB': EachDepartmentComparisonCellDetailV2View;
    /**
     * 
     * @type {DiffRatioView}
     * @memberof EachDepartmentComparisonCellV2View
     */
    'diff': DiffRatioView;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonCellV2View
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonCellV2View
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonCellV2View
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EachDepartmentComparisonFlatTreeV2View
 */
export interface EachDepartmentComparisonFlatTreeV2View {
    /**
     * 
     * @type {Array<EachDepartmentComparisonTreeV4View>}
     * @memberof EachDepartmentComparisonFlatTreeV2View
     */
    'items': Array<EachDepartmentComparisonTreeV4View>;
}
/**
 * 
 * @export
 * @interface EachDepartmentComparisonTableMetaPlanView
 */
export interface EachDepartmentComparisonTableMetaPlanView {
    /**
     * 
     * @type {boolean}
     * @memberof EachDepartmentComparisonTableMetaPlanView
     */
    'containsActual': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EachDepartmentComparisonTableMetaPlanView
     */
    'containsPlan': boolean;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTableMetaPlanView
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTableMetaPlanView
     */
    'planMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EachDepartmentComparisonTableMetaPlanView
     */
    'planSelected': boolean;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTableMetaPlanView
     */
    'planType'?: EachDepartmentComparisonTableMetaPlanViewPlanTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTableMetaPlanView
     */
    'planUpdateProjectId'?: string;
}

export const EachDepartmentComparisonTableMetaPlanViewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type EachDepartmentComparisonTableMetaPlanViewPlanTypeEnum = typeof EachDepartmentComparisonTableMetaPlanViewPlanTypeEnum[keyof typeof EachDepartmentComparisonTableMetaPlanViewPlanTypeEnum];

/**
 * 
 * @export
 * @interface EachDepartmentComparisonTableMetaV2View
 */
export interface EachDepartmentComparisonTableMetaV2View {
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTableMetaV2View
     */
    'accountTreeId': string;
    /**
     * 
     * @type {EachDepartmentComparisonTableMetaPlanView}
     * @memberof EachDepartmentComparisonTableMetaV2View
     */
    'comparisonA': EachDepartmentComparisonTableMetaPlanView;
    /**
     * 
     * @type {EachDepartmentComparisonTableMetaPlanView}
     * @memberof EachDepartmentComparisonTableMetaV2View
     */
    'comparisonB': EachDepartmentComparisonTableMetaPlanView;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTableMetaV2View
     */
    'roundingMode': EachDepartmentComparisonTableMetaV2ViewRoundingModeEnum;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTableMetaV2View
     */
    'showIn': EachDepartmentComparisonTableMetaV2ViewShowInEnum;
    /**
     * 
     * @type {Array<EachDepartmentComparisonCellTypeV2View>}
     * @memberof EachDepartmentComparisonTableMetaV2View
     */
    'topDepartments': Array<EachDepartmentComparisonCellTypeV2View>;
}

export const EachDepartmentComparisonTableMetaV2ViewRoundingModeEnum = {
    Down: 'DOWN',
    HalfUp: 'HALF_UP'
} as const;

export type EachDepartmentComparisonTableMetaV2ViewRoundingModeEnum = typeof EachDepartmentComparisonTableMetaV2ViewRoundingModeEnum[keyof typeof EachDepartmentComparisonTableMetaV2ViewRoundingModeEnum];
export const EachDepartmentComparisonTableMetaV2ViewShowInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type EachDepartmentComparisonTableMetaV2ViewShowInEnum = typeof EachDepartmentComparisonTableMetaV2ViewShowInEnum[keyof typeof EachDepartmentComparisonTableMetaV2ViewShowInEnum];

/**
 * 
 * @export
 * @interface EachDepartmentComparisonTreeV4View
 */
export interface EachDepartmentComparisonTreeV4View {
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'accountType': EachDepartmentComparisonTreeV4ViewAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'code': string;
    /**
     * 
     * @type {Array<EachDepartmentComparisonCellV2View>}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'data': Array<EachDepartmentComparisonCellV2View>;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'rowType': EachDepartmentComparisonTreeV4ViewRowTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'sumA'?: number;
    /**
     * 
     * @type {number}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'sumB'?: number;
    /**
     * 
     * @type {DiffRatioView}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'sumDiff': DiffRatioView;
    /**
     * 
     * @type {string}
     * @memberof EachDepartmentComparisonTreeV4View
     */
    'viewControlType': EachDepartmentComparisonTreeV4ViewViewControlTypeEnum;
}

export const EachDepartmentComparisonTreeV4ViewAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type EachDepartmentComparisonTreeV4ViewAccountTypeEnum = typeof EachDepartmentComparisonTreeV4ViewAccountTypeEnum[keyof typeof EachDepartmentComparisonTreeV4ViewAccountTypeEnum];
export const EachDepartmentComparisonTreeV4ViewRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type EachDepartmentComparisonTreeV4ViewRowTypeEnum = typeof EachDepartmentComparisonTreeV4ViewRowTypeEnum[keyof typeof EachDepartmentComparisonTreeV4ViewRowTypeEnum];
export const EachDepartmentComparisonTreeV4ViewViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type EachDepartmentComparisonTreeV4ViewViewControlTypeEnum = typeof EachDepartmentComparisonTreeV4ViewViewControlTypeEnum[keyof typeof EachDepartmentComparisonTreeV4ViewViewControlTypeEnum];

/**
 * 
 * @export
 * @interface EachDepartmentComparisonV2View
 */
export interface EachDepartmentComparisonV2View {
    /**
     * 
     * @type {EachDepartmentComparisonTableMetaV2View}
     * @memberof EachDepartmentComparisonV2View
     */
    'meta': EachDepartmentComparisonTableMetaV2View;
    /**
     * 
     * @type {EachDepartmentComparisonFlatTreeV2View}
     * @memberof EachDepartmentComparisonV2View
     */
    'tree': EachDepartmentComparisonFlatTreeV2View;
}
/**
 * 
 * @export
 * @interface EachMonthFixedPatternBody
 */
export interface EachMonthFixedPatternBody {
    /**
     * 
     * @type {Array<MonthToFixedAmount>}
     * @memberof EachMonthFixedPatternBody
     */
    'monthToFixedAmounts': Array<MonthToFixedAmount>;
    /**
     * 
     * @type {string}
     * @memberof EachMonthFixedPatternBody
     */
    'targetAccountId': string;
}
/**
 * 
 * @export
 * @interface EachMonthFixedPatternView
 */
export interface EachMonthFixedPatternView {
    /**
     * 
     * @type {Array<MonthToFixedAmount>}
     * @memberof EachMonthFixedPatternView
     */
    'monthToFixedAmounts': Array<MonthToFixedAmount>;
    /**
     * 
     * @type {string}
     * @memberof EachMonthFixedPatternView
     */
    'targetAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof EachMonthFixedPatternView
     */
    'targetAccountName': string;
}
/**
 * 
 * @export
 * @interface EachMonthRatioPatternBody
 */
export interface EachMonthRatioPatternBody {
    /**
     * 
     * @type {Array<MonthToRatio>}
     * @memberof EachMonthRatioPatternBody
     */
    'monthToRatios': Array<MonthToRatio>;
    /**
     * 
     * @type {string}
     * @memberof EachMonthRatioPatternBody
     */
    'targetAccountId': string;
}
/**
 * 
 * @export
 * @interface EachMonthRatioPatternView
 */
export interface EachMonthRatioPatternView {
    /**
     * 
     * @type {Array<MonthToRatio>}
     * @memberof EachMonthRatioPatternView
     */
    'monthToRatios': Array<MonthToRatio>;
    /**
     * 
     * @type {string}
     * @memberof EachMonthRatioPatternView
     */
    'targetAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof EachMonthRatioPatternView
     */
    'targetAccountName': string;
}
/**
 * 
 * @export
 * @interface EachWorkforceDiffItem
 */
export interface EachWorkforceDiffItem {
    /**
     * 
     * @type {number}
     * @memberof EachWorkforceDiffItem
     */
    'afterAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EachWorkforceDiffItem
     */
    'beforeAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EachWorkforceDiffItem
     */
    'diffAmount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EachWorkforceDiffItem
     */
    'hasEvent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EachWorkforceDiffItem
     */
    'isLeaveOfAbsence': boolean;
    /**
     * 
     * @type {string}
     * @memberof EachWorkforceDiffItem
     */
    'yearMonthId': string;
}
/**
 * 
 * @export
 * @interface EachWorkforceDiffRow
 */
export interface EachWorkforceDiffRow {
    /**
     * 
     * @type {Array<EachConcurrentPostDiff>}
     * @memberof EachWorkforceDiffRow
     */
    'eachConcurrentPostDiffs': Array<EachConcurrentPostDiff>;
    /**
     * 
     * @type {Array<EachWorkforceDiffItem>}
     * @memberof EachWorkforceDiffRow
     */
    'eachWorkforceDiffItems': Array<EachWorkforceDiffItem>;
    /**
     * 
     * @type {string}
     * @memberof EachWorkforceDiffRow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EachWorkforceDiffRow
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EachWorkforceDiffRow
     */
    'workforceOrSlotType': EachWorkforceDiffRowWorkforceOrSlotTypeEnum;
}

export const EachWorkforceDiffRowWorkforceOrSlotTypeEnum = {
    Workforce: 'WORKFORCE',
    HiringSlot: 'HIRING_SLOT',
    ResignationSlot: 'RESIGNATION_SLOT',
    LeaveOfAbsenceSlot: 'LEAVE_OF_ABSENCE_SLOT',
    ReturnToWorkSlot: 'RETURN_TO_WORK_SLOT'
} as const;

export type EachWorkforceDiffRowWorkforceOrSlotTypeEnum = typeof EachWorkforceDiffRowWorkforceOrSlotTypeEnum[keyof typeof EachWorkforceDiffRowWorkforceOrSlotTypeEnum];

/**
 * 
 * @export
 * @interface EachWorkforceItem
 */
export interface EachWorkforceItem {
    /**
     * 
     * @type {number}
     * @memberof EachWorkforceItem
     */
    'amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EachWorkforceItem
     */
    'hasEvent': boolean;
    /**
     * 
     * @type {string}
     * @memberof EachWorkforceItem
     */
    'yearMonthId': string;
}
/**
 * 
 * @export
 * @interface EachWorkforceRow
 */
export interface EachWorkforceRow {
    /**
     * 
     * @type {Array<EachConcurrentPost>}
     * @memberof EachWorkforceRow
     */
    'eachConcurrentPosts': Array<EachConcurrentPost>;
    /**
     * 
     * @type {Array<EachWorkforceItem>}
     * @memberof EachWorkforceRow
     */
    'eachWorkforceItems': Array<EachWorkforceItem>;
    /**
     * 
     * @type {string}
     * @memberof EachWorkforceRow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EachWorkforceRow
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EditReportDescriptionBody
 */
export interface EditReportDescriptionBody {
    /**
     * 
     * @type {string}
     * @memberof EditReportDescriptionBody
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface EditUserParam
 */
export interface EditUserParam {
    /**
     * 
     * @type {string}
     * @memberof EditUserParam
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserParam
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserParam
     */
    'lastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserParam
     */
    'requireMFA': boolean;
    /**
     * 
     * @type {string}
     * @memberof EditUserParam
     */
    'role': EditUserParamRoleEnum;
}

export const EditUserParamRoleEnum = {
    LoglassSystemAdmin: 'LOGLASS_SYSTEM_ADMIN',
    TenantAdmin: 'TENANT_ADMIN',
    CorpPlanning: 'CORP_PLANNING',
    Manager: 'MANAGER',
    ItStaff: 'IT_STAFF',
    General: 'GENERAL'
} as const;

export type EditUserParamRoleEnum = typeof EditUserParamRoleEnum[keyof typeof EditUserParamRoleEnum];

/**
 * 
 * @export
 * @interface EitherAmountOrErrorView
 */
export interface EitherAmountOrErrorView {
    /**
     * 
     * @type {string}
     * @memberof EitherAmountOrErrorView
     */
    'amount'?: string;
    /**
     * 
     * @type {AmountErrorView}
     * @memberof EitherAmountOrErrorView
     */
    'error'?: AmountErrorView;
}
/**
 * 
 * @export
 * @interface EnabledFeaturesDto
 */
export interface EnabledFeaturesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof EnabledFeaturesDto
     */
    'enabledFeatures': Array<EnabledFeaturesDtoEnabledFeaturesEnum>;
}

export const EnabledFeaturesDtoEnabledFeaturesEnum = {
    ActualItemDetailView: 'ACTUAL_ITEM_DETAIL_VIEW',
    FinancialSheetComment: 'FINANCIAL_SHEET_COMMENT',
    FinancialSheetExcelDownload: 'FINANCIAL_SHEET_EXCEL_DOWNLOAD',
    ComparisonSheetSelectedConditionSave: 'COMPARISON_SHEET_SELECTED_CONDITION_SAVE',
    ComparisonSheetHighlight: 'COMPARISON_SHEET_HIGHLIGHT',
    AnalyticsDataDownload: 'ANALYTICS_DATA_DOWNLOAD',
    BulkPlanUpdateRequest: 'BULK_PLAN_UPDATE_REQUEST',
    DetailPopupInActualReport: 'DETAIL_POPUP_IN_ACTUAL_REPORT',
    DevTableauDashboard: 'DEV_TABLEAU_DASHBOARD',
    ViewPermission: 'VIEW_PERMISSION',
    NonFinancialActualCsvFormat: 'NON_FINANCIAL_ACTUAL_CSV_FORMAT',
    DimensionTree: 'DIMENSION_TREE',
    FinancialSheetHistoryAndDepartment: 'FINANCIAL_SHEET_HISTORY_AND_DEPARTMENT',
    AllocationByDimensions: 'ALLOCATION_BY_DIMENSIONS',
    LlmComparisonAnalysis: 'LLM_COMPARISON_ANALYSIS',
    WorkforcePlanning: 'WORKFORCE_PLANNING',
    AllocationStandardBulkUpload: 'ALLOCATION_STANDARD_BULK_UPLOAD',
    ApiIntegration: 'API_INTEGRATION',
    AllocationForEachItem: 'ALLOCATION_FOR_EACH_ITEM',
    DisplayNoneDimensionInReport: 'DISPLAY_NONE_DIMENSION_IN_REPORT',
    Labs: 'LABS',
    ReportBigquery: 'REPORT_BIGQUERY',
    ReportAccountColumn: 'REPORT_ACCOUNT_COLUMN',
    AsyncAllocationExecution: 'ASYNC_ALLOCATION_EXECUTION',
    FixedValueAllocation: 'FIXED_VALUE_ALLOCATION',
    AiPlanActualAnalysisReport: 'AI_PLAN_ACTUAL_ANALYSIS_REPORT',
    DimensionTreeExtension: 'DIMENSION_TREE_EXTENSION',
    WorkforceActualImportMappingSetting: 'WORKFORCE_ACTUAL_IMPORT_MAPPING_SETTING',
    ForeignCurrency: 'FOREIGN_CURRENCY',
    FinancialSimulator: 'FINANCIAL_SIMULATOR',
    ReportPolars: 'REPORT_POLARS',
    ReportPolarsBackground: 'REPORT_POLARS_BACKGROUND'
} as const;

export type EnabledFeaturesDtoEnabledFeaturesEnum = typeof EnabledFeaturesDtoEnabledFeaturesEnum[keyof typeof EnabledFeaturesDtoEnabledFeaturesEnum];

/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'errorCode': ErrorResponseErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'jsonMsg'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'statusCode': ErrorResponseStatusCodeEnum;
}

export const ErrorResponseErrorCodeEnum = {
    ValidationError: 'VALIDATION_ERROR',
    InvalidYearMonth: 'INVALID_YEAR_MONTH',
    BadRequest: 'BAD_REQUEST',
    UnavailableFeature: 'UNAVAILABLE_FEATURE',
    InvalidStateOfTree: 'INVALID_STATE_OF_TREE',
    PlanningProjectIsFixed: 'PLANNING_PROJECT_IS_FIXED',
    PlanningProjectIsNotFixing: 'PLANNING_PROJECT_IS_NOT_FIXING',
    PlanningProjectIsFixing: 'PLANNING_PROJECT_IS_FIXING',
    PlanUpdateRequestIsNotLatest: 'PLAN_UPDATE_REQUEST_IS_NOT_LATEST',
    PlanUpdateRequestIsClosed: 'PLAN_UPDATE_REQUEST_IS_CLOSED',
    PlanUpdateRequestHasNoDiff: 'PLAN_UPDATE_REQUEST_HAS_NO_DIFF',
    BulkPullrequestsHaveNoDiff: 'BULK_PULLREQUESTS_HAVE_NO_DIFF',
    Unauthenticated: 'UNAUTHENTICATED',
    PermissionDenied: 'PERMISSION_DENIED',
    NotFound: 'NOT_FOUND',
    DuplicatedEmail: 'DUPLICATED_EMAIL',
    CodeConflict: 'CODE_CONFLICT',
    NameConflict: 'NAME_CONFLICT',
    ConcurrentUpdate: 'CONCURRENT_UPDATE',
    InternalServerError: 'INTERNAL_SERVER_ERROR'
} as const;

export type ErrorResponseErrorCodeEnum = typeof ErrorResponseErrorCodeEnum[keyof typeof ErrorResponseErrorCodeEnum];
export const ErrorResponseStatusCodeEnum = {
    Default: 'DEFAULT',
    Continue: 'CONTINUE',
    SwitchingProtocols: 'SWITCHING_PROTOCOLS',
    Processing: 'PROCESSING',
    Ok: 'OK',
    Created: 'CREATED',
    Accepted: 'ACCEPTED',
    NonAuthoritativeInformation: 'NON_AUTHORITATIVE_INFORMATION',
    NoContent: 'NO_CONTENT',
    ResetContent: 'RESET_CONTENT',
    PartialContent: 'PARTIAL_CONTENT',
    MultiStatus: 'MULTI_STATUS',
    ImUsed: 'IM_USED',
    MultipleChoices: 'MULTIPLE_CHOICES',
    HttpStatusCodeMovedPermanently: 'HTTP_STATUS_CODE_MOVED_PERMANENTLY',
    Found: 'FOUND',
    SeeOther: 'SEE_OTHER',
    NotModified: 'NOT_MODIFIED',
    UseProxy: 'USE_PROXY',
    Unused: 'UNUSED',
    TemporaryRedirect: 'TEMPORARY_REDIRECT',
    BadRequest: 'BAD_REQUEST',
    Unauthorized: 'UNAUTHORIZED',
    PaymentRequired: 'PAYMENT_REQUIRED',
    Forbidden: 'FORBIDDEN',
    NotFound: 'NOT_FOUND',
    MethodNotAllowed: 'METHOD_NOT_ALLOWED',
    NotAcceptable: 'NOT_ACCEPTABLE',
    ProxyAuthenticationRequired: 'PROXY_AUTHENTICATION_REQUIRED',
    RequestTimeOut: 'REQUEST_TIME_OUT',
    Conflict: 'CONFLICT',
    Gone: 'GONE',
    LengthRequired: 'LENGTH_REQUIRED',
    PreconditionFailed: 'PRECONDITION_FAILED',
    RequestEntityTooLarge: 'REQUEST_ENTITY_TOO_LARGE',
    RequestUriTooLong: 'REQUEST_URI_TOO_LONG',
    UnsupportedMediaType: 'UNSUPPORTED_MEDIA_TYPE',
    RequestedRangeNotSatisfiable: 'REQUESTED_RANGE_NOT_SATISFIABLE',
    ExpectationFailed: 'EXPECTATION_FAILED',
    UnprocessableEntity: 'UNPROCESSABLE_ENTITY',
    Locked: 'LOCKED',
    FailedDependency: 'FAILED_DEPENDENCY',
    UpgradeRequired: 'UPGRADE_REQUIRED',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    NotImplemented: 'NOT_IMPLEMENTED',
    BadGateway: 'BAD_GATEWAY',
    ServiceUnavailable: 'SERVICE_UNAVAILABLE',
    GatewayTimeOut: 'GATEWAY_TIME_OUT',
    HttpVersionNotSupported: 'HTTP_VERSION_NOT_SUPPORTED',
    VariantAlsoNegotiates: 'VARIANT_ALSO_NEGOTIATES',
    InsufficientStorage: 'INSUFFICIENT_STORAGE',
    BandWidthLimitExceeded: 'BAND_WIDTH_LIMIT_EXCEEDED',
    NotExtended: 'NOT_EXTENDED'
} as const;

export type ErrorResponseStatusCodeEnum = typeof ErrorResponseStatusCodeEnum[keyof typeof ErrorResponseStatusCodeEnum];

/**
 * 
 * @export
 * @interface ExchangeRateCurrencyData
 */
export interface ExchangeRateCurrencyData {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateCurrencyData
     */
    'currencyLocalId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateCurrencyData
     */
    'currencyLocalName': string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ExchangeRateCurrencyData
     */
    'fiscalMonthData': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface ExchangeRateCurrencyDataParam
 */
export interface ExchangeRateCurrencyDataParam {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateCurrencyDataParam
     */
    'currencyLocalId': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExchangeRateCurrencyDataParam
     */
    'fiscalMonthData': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ExchangeRateData
 */
export interface ExchangeRateData {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateData
     */
    'amountLastUpdateUserName': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateData
     */
    'amountLastUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateData
     */
    'exchangeRateId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateData
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface ExchangeRateDetailData
 */
export interface ExchangeRateDetailData {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateDetailData
     */
    'amountLastUpdatedAt': string;
    /**
     * 
     * @type {Array<ExchangeRateCurrencyData>}
     * @memberof ExchangeRateDetailData
     */
    'exchangeRateCurrencyList': Array<ExchangeRateCurrencyData>;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateDetailData
     */
    'exchangeRateId': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateDetailData
     */
    'lastUpdateUserName': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateDetailData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateDetailData
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface ExchangeRateRequestParam
 */
export interface ExchangeRateRequestParam {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateRequestParam
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeRateRequestParam
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface ExcludePatternBody
 */
export interface ExcludePatternBody {
    /**
     * 
     * @type {string}
     * @memberof ExcludePatternBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ExcludePatternView
 */
export interface ExcludePatternView {
    /**
     * 
     * @type {string}
     * @memberof ExcludePatternView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ExecuteAllocationRequestBody
 */
export interface ExecuteAllocationRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ExecuteAllocationRequestBody
     */
    'targetType': ExecuteAllocationRequestBodyTargetTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecuteAllocationRequestBody
     */
    'yearMonthSet': Array<string>;
}

export const ExecuteAllocationRequestBodyTargetTypeEnum = {
    Actual: 'ACTUAL',
    Plan: 'PLAN'
} as const;

export type ExecuteAllocationRequestBodyTargetTypeEnum = typeof ExecuteAllocationRequestBodyTargetTypeEnum[keyof typeof ExecuteAllocationRequestBodyTargetTypeEnum];

/**
 * 
 * @export
 * @interface FactorAnalysisDetailDataView
 */
export interface FactorAnalysisDetailDataView {
    /**
     * 
     * @type {PlanVsActualDataView}
     * @memberof FactorAnalysisDetailDataView
     */
    'data': PlanVsActualDataView;
    /**
     * 
     * @type {string}
     * @memberof FactorAnalysisDetailDataView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FactorAnalysisDetailView
 */
export interface FactorAnalysisDetailView {
    /**
     * 
     * @type {Array<FactorAnalysisDetailDataView>}
     * @memberof FactorAnalysisDetailView
     */
    'data': Array<FactorAnalysisDetailDataView>;
    /**
     * 
     * @type {string}
     * @memberof FactorAnalysisDetailView
     */
    'type': FactorAnalysisDetailViewTypeEnum;
}

export const FactorAnalysisDetailViewTypeEnum = {
    Department: 'DEPARTMENT',
    Account: 'ACCOUNT'
} as const;

export type FactorAnalysisDetailViewTypeEnum = typeof FactorAnalysisDetailViewTypeEnum[keyof typeof FactorAnalysisDetailViewTypeEnum];

/**
 * 
 * @export
 * @interface FactorAnalysisOption
 */
export interface FactorAnalysisOption {
    /**
     * 
     * @type {string}
     * @memberof FactorAnalysisOption
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FactorAnalysisOption
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FactorAnalysisOptions
 */
export interface FactorAnalysisOptions {
    /**
     * 
     * @type {Array<FactorAnalysisOption>}
     * @memberof FactorAnalysisOptions
     */
    'options': Array<FactorAnalysisOption>;
    /**
     * 
     * @type {string}
     * @memberof FactorAnalysisOptions
     */
    'type': FactorAnalysisOptionsTypeEnum;
}

export const FactorAnalysisOptionsTypeEnum = {
    Department: 'DEPARTMENT',
    Account: 'ACCOUNT'
} as const;

export type FactorAnalysisOptionsTypeEnum = typeof FactorAnalysisOptionsTypeEnum[keyof typeof FactorAnalysisOptionsTypeEnum];

/**
 * 
 * @export
 * @interface FetchActualRequestBody
 */
export interface FetchActualRequestBody {
    /**
     * 
     * @type {string}
     * @memberof FetchActualRequestBody
     */
    'actualEnd': string;
    /**
     * 
     * @type {string}
     * @memberof FetchActualRequestBody
     */
    'actualStart': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FetchActualRequestBody
     */
    'departmentIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof FetchActualRequestBody
     */
    'isAsc': boolean;
    /**
     * 
     * @type {number}
     * @memberof FetchActualRequestBody
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof FetchActualRequestBody
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof FetchActualRequestBody
     */
    'orgTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof FetchActualRequestBody
     */
    'period'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FetchActualRequestBody
     */
    'requireAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof FetchActualRequestBody
     */
    'rowType': FetchActualRequestBodyRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FetchActualRequestBody
     */
    'showIn'?: FetchActualRequestBodyShowInEnum;
    /**
     * 
     * @type {string}
     * @memberof FetchActualRequestBody
     */
    'sortKey': FetchActualRequestBodySortKeyEnum;
}

export const FetchActualRequestBodyRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type FetchActualRequestBodyRowTypeEnum = typeof FetchActualRequestBodyRowTypeEnum[keyof typeof FetchActualRequestBodyRowTypeEnum];
export const FetchActualRequestBodyShowInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type FetchActualRequestBodyShowInEnum = typeof FetchActualRequestBodyShowInEnum[keyof typeof FetchActualRequestBodyShowInEnum];
export const FetchActualRequestBodySortKeyEnum = {
    JournalDate: 'JOURNAL_DATE',
    DepartmentName: 'DEPARTMENT_NAME',
    AccountName: 'ACCOUNT_NAME',
    SupplierName: 'SUPPLIER_NAME',
    Description: 'DESCRIPTION',
    Amount: 'AMOUNT'
} as const;

export type FetchActualRequestBodySortKeyEnum = typeof FetchActualRequestBodySortKeyEnum[keyof typeof FetchActualRequestBodySortKeyEnum];

/**
 * 
 * @export
 * @interface FetchAggregatePlanUpdateRequestRequestBody
 */
export interface FetchAggregatePlanUpdateRequestRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof FetchAggregatePlanUpdateRequestRequestBody
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FetchAggregatePlanUpdateRequestRequestBody
     */
    'showIn'?: FetchAggregatePlanUpdateRequestRequestBodyShowInEnum;
    /**
     * 
     * @type {string}
     * @memberof FetchAggregatePlanUpdateRequestRequestBody
     */
    'target': FetchAggregatePlanUpdateRequestRequestBodyTargetEnum;
}

export const FetchAggregatePlanUpdateRequestRequestBodyShowInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type FetchAggregatePlanUpdateRequestRequestBodyShowInEnum = typeof FetchAggregatePlanUpdateRequestRequestBodyShowInEnum[keyof typeof FetchAggregatePlanUpdateRequestRequestBodyShowInEnum];
export const FetchAggregatePlanUpdateRequestRequestBodyTargetEnum = {
    Pl: 'PL',
    Bs: 'BS'
} as const;

export type FetchAggregatePlanUpdateRequestRequestBodyTargetEnum = typeof FetchAggregatePlanUpdateRequestRequestBodyTargetEnum[keyof typeof FetchAggregatePlanUpdateRequestRequestBodyTargetEnum];

/**
 * 
 * @export
 * @interface FetchComparisonActualStartEndParam
 */
export interface FetchComparisonActualStartEndParam {
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonActualStartEndParam
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonActualStartEndParam
     */
    'start': string;
}
/**
 * 
 * @export
 * @interface FetchComparisonHighlightParam
 */
export interface FetchComparisonHighlightParam {
    /**
     * 
     * @type {number}
     * @memberof FetchComparisonHighlightParam
     */
    'threshold': number;
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonHighlightParam
     */
    'type': FetchComparisonHighlightParamTypeEnum;
}

export const FetchComparisonHighlightParamTypeEnum = {
    Amount: 'DIFF_AMOUNT',
    Ratio: 'DIFF_RATIO'
} as const;

export type FetchComparisonHighlightParamTypeEnum = typeof FetchComparisonHighlightParamTypeEnum[keyof typeof FetchComparisonHighlightParamTypeEnum];

/**
 * 
 * @export
 * @interface FetchComparisonRequestABParam
 */
export interface FetchComparisonRequestABParam {
    /**
     * 
     * @type {FetchComparisonActualStartEndParam}
     * @memberof FetchComparisonRequestABParam
     */
    'actualStartEnd'?: FetchComparisonActualStartEndParam;
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonRequestABParam
     */
    'planEnd': string;
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonRequestABParam
     */
    'planMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FetchComparisonRequestABParam
     */
    'planShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonRequestABParam
     */
    'planStart': string;
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonRequestABParam
     */
    'planningProjectId'?: string;
}
/**
 * 
 * @export
 * @interface FetchComparisonRequestBody
 */
export interface FetchComparisonRequestBody {
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonRequestBody
     */
    'aggregate': FetchComparisonRequestBodyAggregateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof FetchComparisonRequestBody
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {FetchComparisonHighlightParam}
     * @memberof FetchComparisonRequestBody
     */
    'highlightParam'?: FetchComparisonHighlightParam;
    /**
     * 
     * @type {boolean}
     * @memberof FetchComparisonRequestBody
     */
    'isSpecificAccountRatio': boolean;
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonRequestBody
     */
    'orgTreeId': string;
    /**
     * 
     * @type {FetchComparisonRequestABParam}
     * @memberof FetchComparisonRequestBody
     */
    'paramA': FetchComparisonRequestABParam;
    /**
     * 
     * @type {FetchComparisonRequestABParam}
     * @memberof FetchComparisonRequestBody
     */
    'paramB': FetchComparisonRequestABParam;
    /**
     * 
     * @type {boolean}
     * @memberof FetchComparisonRequestBody
     */
    'requireAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof FetchComparisonRequestBody
     */
    'showIn': FetchComparisonRequestBodyShowInEnum;
}

export const FetchComparisonRequestBodyAggregateEnum = {
    Month: 'MONTH',
    Quarter: 'QUARTER',
    FiscalYear: 'FISCAL_YEAR',
    FiscalHalfYear: 'FISCAL_HALF_YEAR'
} as const;

export type FetchComparisonRequestBodyAggregateEnum = typeof FetchComparisonRequestBodyAggregateEnum[keyof typeof FetchComparisonRequestBodyAggregateEnum];
export const FetchComparisonRequestBodyShowInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type FetchComparisonRequestBodyShowInEnum = typeof FetchComparisonRequestBodyShowInEnum[keyof typeof FetchComparisonRequestBodyShowInEnum];

/**
 * 
 * @export
 * @interface FetchDetailRequestBody
 */
export interface FetchDetailRequestBody {
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {Array<MaybeOwnValueDepartmentId>}
     * @memberof FetchDetailRequestBody
     */
    'departmentIds'?: Array<MaybeOwnValueDepartmentId>;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'dimensionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'groupingType': FetchDetailRequestBodyGroupingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FetchDetailRequestBody
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof FetchDetailRequestBody
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'orgTreeId': string;
    /**
     * 
     * @type {FetchPlanActualDetailActualStartEndParam}
     * @memberof FetchDetailRequestBody
     */
    'planAActualStartEnd'?: FetchPlanActualDetailActualStartEndParam;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'planAEnd': string;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'planAMasterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'planAPlanUpdateProjectId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FetchDetailRequestBody
     */
    'planAShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'planAStart': string;
    /**
     * 
     * @type {FetchPlanActualDetailActualStartEndParam}
     * @memberof FetchDetailRequestBody
     */
    'planBActualStartEnd'?: FetchPlanActualDetailActualStartEndParam;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'planBEnd': string;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'planBMasterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'planBPlanUpdateProjectId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FetchDetailRequestBody
     */
    'planBShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'planBStart': string;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'rowType': FetchDetailRequestBodyRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FetchDetailRequestBody
     */
    'showIn'?: FetchDetailRequestBodyShowInEnum;
}

export const FetchDetailRequestBodyGroupingTypeEnum = {
    Dimension: 'DIMENSION'
} as const;

export type FetchDetailRequestBodyGroupingTypeEnum = typeof FetchDetailRequestBodyGroupingTypeEnum[keyof typeof FetchDetailRequestBodyGroupingTypeEnum];
export const FetchDetailRequestBodyRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type FetchDetailRequestBodyRowTypeEnum = typeof FetchDetailRequestBodyRowTypeEnum[keyof typeof FetchDetailRequestBodyRowTypeEnum];
export const FetchDetailRequestBodyShowInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type FetchDetailRequestBodyShowInEnum = typeof FetchDetailRequestBodyShowInEnum[keyof typeof FetchDetailRequestBodyShowInEnum];

/**
 * 
 * @export
 * @interface FetchEachDepartmentComparisonV2ActualStartEndParam
 */
export interface FetchEachDepartmentComparisonV2ActualStartEndParam {
    /**
     * 
     * @type {string}
     * @memberof FetchEachDepartmentComparisonV2ActualStartEndParam
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof FetchEachDepartmentComparisonV2ActualStartEndParam
     */
    'start': string;
}
/**
 * 
 * @export
 * @interface FetchEachDepartmentComparisonV2RequestABParam
 */
export interface FetchEachDepartmentComparisonV2RequestABParam {
    /**
     * 
     * @type {FetchEachDepartmentComparisonV2ActualStartEndParam}
     * @memberof FetchEachDepartmentComparisonV2RequestABParam
     */
    'actualStartEnd'?: FetchEachDepartmentComparisonV2ActualStartEndParam;
    /**
     * 
     * @type {string}
     * @memberof FetchEachDepartmentComparisonV2RequestABParam
     */
    'planEnd': string;
    /**
     * 
     * @type {string}
     * @memberof FetchEachDepartmentComparisonV2RequestABParam
     */
    'planMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FetchEachDepartmentComparisonV2RequestABParam
     */
    'planShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof FetchEachDepartmentComparisonV2RequestABParam
     */
    'planStart': string;
    /**
     * 
     * @type {string}
     * @memberof FetchEachDepartmentComparisonV2RequestABParam
     */
    'planUpdateProjectId'?: string;
}
/**
 * 
 * @export
 * @interface FetchEachDepartmentComparisonV2RequestBody
 */
export interface FetchEachDepartmentComparisonV2RequestBody {
    /**
     * 
     * @type {string}
     * @memberof FetchEachDepartmentComparisonV2RequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FetchEachDepartmentComparisonV2RequestBody
     */
    'departmentIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FetchEachDepartmentComparisonV2RequestBody
     */
    'orgTreeId': string;
    /**
     * 
     * @type {FetchEachDepartmentComparisonV2RequestABParam}
     * @memberof FetchEachDepartmentComparisonV2RequestBody
     */
    'paramA': FetchEachDepartmentComparisonV2RequestABParam;
    /**
     * 
     * @type {FetchEachDepartmentComparisonV2RequestABParam}
     * @memberof FetchEachDepartmentComparisonV2RequestBody
     */
    'paramB': FetchEachDepartmentComparisonV2RequestABParam;
    /**
     * 
     * @type {boolean}
     * @memberof FetchEachDepartmentComparisonV2RequestBody
     */
    'requireAllocatedItems'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FetchEachDepartmentComparisonV2RequestBody
     */
    'showIn'?: FetchEachDepartmentComparisonV2RequestBodyShowInEnum;
}

export const FetchEachDepartmentComparisonV2RequestBodyShowInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type FetchEachDepartmentComparisonV2RequestBodyShowInEnum = typeof FetchEachDepartmentComparisonV2RequestBodyShowInEnum[keyof typeof FetchEachDepartmentComparisonV2RequestBodyShowInEnum];

/**
 * 
 * @export
 * @interface FetchFinancialSimulationScenarioCondition
 */
export interface FetchFinancialSimulationScenarioCondition {
    /**
     * 
     * @type {Array<string>}
     * @memberof FetchFinancialSimulationScenarioCondition
     */
    'compareTargetIds': Array<FetchFinancialSimulationScenarioConditionCompareTargetIdsEnum>;
}

export const FetchFinancialSimulationScenarioConditionCompareTargetIdsEnum = {
    Baseline: 'Baseline',
    A: 'A',
    B: 'B'
} as const;

export type FetchFinancialSimulationScenarioConditionCompareTargetIdsEnum = typeof FetchFinancialSimulationScenarioConditionCompareTargetIdsEnum[keyof typeof FetchFinancialSimulationScenarioConditionCompareTargetIdsEnum];

/**
 * 
 * @export
 * @interface FetchFinancialSimulationScenarioRequestBody
 */
export interface FetchFinancialSimulationScenarioRequestBody {
    /**
     * 
     * @type {FetchFinancialSimulationScenarioCondition}
     * @memberof FetchFinancialSimulationScenarioRequestBody
     */
    'condition': FetchFinancialSimulationScenarioCondition;
    /**
     * 
     * @type {Array<FinancialSimulationReportRowRequest>}
     * @memberof FetchFinancialSimulationScenarioRequestBody
     */
    'rows': Array<FinancialSimulationReportRowRequest>;
}
/**
 * 
 * @export
 * @interface FetchPlanActualDetailActualStartEndParam
 */
export interface FetchPlanActualDetailActualStartEndParam {
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailActualStartEndParam
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailActualStartEndParam
     */
    'start': string;
}
/**
 * 
 * @export
 * @interface FetchPlanActualDetailRowView
 */
export interface FetchPlanActualDetailRowView {
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailRowView
     */
    'accountCode': string;
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailRowView
     */
    'accountName': string;
    /**
     * 
     * @type {Array<PlanActualCellView>}
     * @memberof FetchPlanActualDetailRowView
     */
    'cells': Array<PlanActualCellView>;
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailRowView
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailRowView
     */
    'planASumScaledAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailRowView
     */
    'planBSumScaledAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailRowView
     */
    'sumDiffRatio'?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailRowView
     */
    'sumDiffScaledAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPlanActualDetailRowView
     */
    'tagName': string;
}
/**
 * 
 * @export
 * @interface FetchPlanActualDetailView
 */
export interface FetchPlanActualDetailView {
    /**
     * 
     * @type {FetchPlanActualMetaView}
     * @memberof FetchPlanActualDetailView
     */
    'meta': FetchPlanActualMetaView;
    /**
     * 
     * @type {Array<FetchPlanActualDetailRowView>}
     * @memberof FetchPlanActualDetailView
     */
    'rows': Array<FetchPlanActualDetailRowView>;
}
/**
 * 
 * @export
 * @interface FetchPlanActualMetaView
 */
export interface FetchPlanActualMetaView {
    /**
     * 
     * @type {PagingDto}
     * @memberof FetchPlanActualMetaView
     */
    'paging': PagingDto;
    /**
     * 
     * @type {Array<PlanPeriodPairView>}
     * @memberof FetchPlanActualMetaView
     */
    'periodPairs': Array<PlanPeriodPairView>;
}
/**
 * 
 * @export
 * @interface FinancialModelDetailFinancialModelView
 */
export interface FinancialModelDetailFinancialModelView {
    /**
     * 
     * @type {string}
     * @memberof FinancialModelDetailFinancialModelView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialModelDetailFinancialModelView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FinancialModelDetailView
 */
export interface FinancialModelDetailView {
    /**
     * 
     * @type {FinancialModelDetailFinancialModelView}
     * @memberof FinancialModelDetailView
     */
    'financialModel': FinancialModelDetailFinancialModelView;
    /**
     * 
     * @type {Array<TreeNodeViewAccountTreeWithFormulaNodeDataView>}
     * @memberof FinancialModelDetailView
     */
    'rows': Array<TreeNodeViewAccountTreeWithFormulaNodeDataView>;
}
/**
 * 
 * @export
 * @interface FinancialModelDto
 */
export interface FinancialModelDto {
    /**
     * 
     * @type {string}
     * @memberof FinancialModelDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialModelDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FinancialModelFormulaWithTypeView
 */
export interface FinancialModelFormulaWithTypeView {
    /**
     * 
     * @type {string}
     * @memberof FinancialModelFormulaWithTypeView
     */
    'type': FinancialModelFormulaWithTypeViewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancialModelFormulaWithTypeView
     */
    'value'?: string;
}

export const FinancialModelFormulaWithTypeViewTypeEnum = {
    Const: 'CONST',
    Accumulation: 'ACCUMULATION',
    Formula: 'FORMULA'
} as const;

export type FinancialModelFormulaWithTypeViewTypeEnum = typeof FinancialModelFormulaWithTypeViewTypeEnum[keyof typeof FinancialModelFormulaWithTypeViewTypeEnum];

/**
 * 
 * @export
 * @interface FinancialSheetCommentDetailView
 */
export interface FinancialSheetCommentDetailView {
    /**
     * 
     * @type {boolean}
     * @memberof FinancialSheetCommentDetailView
     */
    'canDelete': boolean;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentDetailView
     */
    'commentText': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentDetailView
     */
    'createAt': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentDetailView
     */
    'financialSheetCommentId': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentDetailView
     */
    'userFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentDetailView
     */
    'userLastName': string;
}
/**
 * 
 * @export
 * @interface FinancialSheetCommentListItemConditionDto
 */
export interface FinancialSheetCommentListItemConditionDto {
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemConditionDto
     */
    'periodKey': string;
    /**
     * 
     * @type {PlanMasterOrPlanningProjectId}
     * @memberof FinancialSheetCommentListItemConditionDto
     */
    'planMasterOrPlanningProjectId'?: PlanMasterOrPlanningProjectId;
}
/**
 * 
 * @export
 * @interface FinancialSheetCommentListItemDto
 */
export interface FinancialSheetCommentListItemDto {
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'commentText': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'commentType': FinancialSheetCommentListItemDtoCommentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'createAt': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'financialSheetCommentId': string;
    /**
     * 
     * @type {FinancialSheetCommentListItemConditionDto}
     * @memberof FinancialSheetCommentListItemDto
     */
    'mainCondition': FinancialSheetCommentListItemConditionDto;
    /**
     * 
     * @type {FinancialSheetCommentListItemConditionDto}
     * @memberof FinancialSheetCommentListItemDto
     */
    'subCondition'?: FinancialSheetCommentListItemConditionDto;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'userFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentListItemDto
     */
    'userLastName': string;
}

export const FinancialSheetCommentListItemDtoCommentTypeEnum = {
    Amount: 'AMOUNT',
    DiffAmount: 'DIFF_AMOUNT',
    DiffRatio: 'DIFF_RATIO'
} as const;

export type FinancialSheetCommentListItemDtoCommentTypeEnum = typeof FinancialSheetCommentListItemDtoCommentTypeEnum[keyof typeof FinancialSheetCommentListItemDtoCommentTypeEnum];

/**
 * 
 * @export
 * @interface FinancialSheetCommentsByCellView
 */
export interface FinancialSheetCommentsByCellView {
    /**
     * 
     * @type {Array<FinancialSheetCommentsByDepartmentView>}
     * @memberof FinancialSheetCommentsByCellView
     */
    'value': Array<FinancialSheetCommentsByDepartmentView>;
}
/**
 * 
 * @export
 * @interface FinancialSheetCommentsByDepartmentView
 */
export interface FinancialSheetCommentsByDepartmentView {
    /**
     * 
     * @type {Array<FinancialSheetCommentDetailView>}
     * @memberof FinancialSheetCommentsByDepartmentView
     */
    'comments': Array<FinancialSheetCommentDetailView>;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentsByDepartmentView
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSheetCommentsByDepartmentView
     */
    'departmentName': string;
}
/**
 * 
 * @export
 * @interface FinancialSimulationCompareTargetRequestParam
 */
export interface FinancialSimulationCompareTargetRequestParam {
    /**
     * 
     * @type {FinancialSimulationPeriod}
     * @memberof FinancialSimulationCompareTargetRequestParam
     */
    'actualPeriod'?: FinancialSimulationPeriod;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationCompareTargetRequestParam
     */
    'compareTargetId': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationCompareTargetRequestParam
     */
    'planId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FinancialSimulationCompareTargetRequestParam
     */
    'yearOffset': number;
}
/**
 * 
 * @export
 * @interface FinancialSimulationCompareTargetView
 */
export interface FinancialSimulationCompareTargetView {
    /**
     * 
     * @type {FinancialSimulationPeriodView}
     * @memberof FinancialSimulationCompareTargetView
     */
    'actualPeriod'?: FinancialSimulationPeriodView;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationCompareTargetView
     */
    'datasetId': FinancialSimulationCompareTargetViewDatasetIdEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationCompareTargetView
     */
    'datasetLabel': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationCompareTargetView
     */
    'datasetType': FinancialSimulationCompareTargetViewDatasetTypeEnum;
    /**
     * 
     * @type {FinancialSimulationPeriodView}
     * @memberof FinancialSimulationCompareTargetView
     */
    'period': FinancialSimulationPeriodView;
    /**
     * 
     * @type {FinancialSimulationPlanMasterView}
     * @memberof FinancialSimulationCompareTargetView
     */
    'planMaster'?: FinancialSimulationPlanMasterView;
}

export const FinancialSimulationCompareTargetViewDatasetIdEnum = {
    A: 'A',
    B: 'B'
} as const;

export type FinancialSimulationCompareTargetViewDatasetIdEnum = typeof FinancialSimulationCompareTargetViewDatasetIdEnum[keyof typeof FinancialSimulationCompareTargetViewDatasetIdEnum];
export const FinancialSimulationCompareTargetViewDatasetTypeEnum = {
    Actual: 'ACTUAL',
    Forecast: 'FORECAST',
    Budget: 'BUDGET'
} as const;

export type FinancialSimulationCompareTargetViewDatasetTypeEnum = typeof FinancialSimulationCompareTargetViewDatasetTypeEnum[keyof typeof FinancialSimulationCompareTargetViewDatasetTypeEnum];

/**
 * 
 * @export
 * @interface FinancialSimulationDetailView
 */
export interface FinancialSimulationDetailView {
    /**
     * 
     * @type {Array<FinancialSimulationCompareTargetView>}
     * @memberof FinancialSimulationDetailView
     */
    'compareTargets': Array<FinancialSimulationCompareTargetView>;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationDetailView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationDetailView
     */
    'name': string;
    /**
     * 
     * @type {FinancialSimulationSimulationTargetPlanView}
     * @memberof FinancialSimulationDetailView
     */
    'targetPlan': FinancialSimulationSimulationTargetPlanView;
}
/**
 * 
 * @export
 * @interface FinancialSimulationDto
 */
export interface FinancialSimulationDto {
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FinancialSimulationListItemView
 */
export interface FinancialSimulationListItemView {
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationListItemView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationListItemView
     */
    'createdUserName': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationListItemView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationListItemView
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancialSimulationListItemView
     */
    'scenarioIds': Array<string>;
}
/**
 * 
 * @export
 * @interface FinancialSimulationPeriod
 */
export interface FinancialSimulationPeriod {
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationPeriod
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationPeriod
     */
    'start': string;
}
/**
 * 
 * @export
 * @interface FinancialSimulationPeriodView
 */
export interface FinancialSimulationPeriodView {
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationPeriodView
     */
    'periodEnd': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationPeriodView
     */
    'periodStart': string;
}
/**
 * 
 * @export
 * @interface FinancialSimulationPlanMasterView
 */
export interface FinancialSimulationPlanMasterView {
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationPlanMasterView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationPlanMasterView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FinancialSimulationReportCellRequest
 */
export interface FinancialSimulationReportCellRequest {
    /**
     * 
     * @type {number}
     * @memberof FinancialSimulationReportCellRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationReportCellRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FinancialSimulationReportRowIdView
 */
export interface FinancialSimulationReportRowIdView {
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationReportRowIdView
     */
    'first': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationReportRowIdView
     */
    'second'?: string;
}
/**
 * 
 * @export
 * @interface FinancialSimulationReportRowRequest
 */
export interface FinancialSimulationReportRowRequest {
    /**
     * 
     * @type {Array<FinancialSimulationReportCellRequest>}
     * @memberof FinancialSimulationReportRowRequest
     */
    'cells': Array<FinancialSimulationReportCellRequest>;
    /**
     * 
     * @type {FinancialSimulationReportRowIdView}
     * @memberof FinancialSimulationReportRowRequest
     */
    'id': FinancialSimulationReportRowIdView;
}
/**
 * 
 * @export
 * @interface FinancialSimulationScenarioCellView
 */
export interface FinancialSimulationScenarioCellView {
    /**
     * 
     * @type {number}
     * @memberof FinancialSimulationScenarioCellView
     */
    'amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof FinancialSimulationScenarioCellView
     */
    'editable': boolean;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationScenarioCellView
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FinancialSimulationScenarioRowGroupView
 */
export interface FinancialSimulationScenarioRowGroupView {
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationScenarioRowGroupView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationScenarioRowGroupView
     */
    'parentId'?: string;
    /**
     * 
     * @type {Array<FinancialSimulationScenarioRowView>}
     * @memberof FinancialSimulationScenarioRowGroupView
     */
    'rows': Array<FinancialSimulationScenarioRowView>;
}
/**
 * 
 * @export
 * @interface FinancialSimulationScenarioRowView
 */
export interface FinancialSimulationScenarioRowView {
    /**
     * 
     * @type {{ [key: string]: FinancialSimulationScenarioCellView; }}
     * @memberof FinancialSimulationScenarioRowView
     */
    'cells': { [key: string]: FinancialSimulationScenarioCellView; };
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationScenarioRowView
     */
    'datasetId': FinancialSimulationScenarioRowViewDatasetIdEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationScenarioRowView
     */
    'datasetLabel': string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationScenarioRowView
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof FinancialSimulationScenarioRowView
     */
    'level': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialSimulationScenarioRowView
     */
    'sumAmount': number;
}

export const FinancialSimulationScenarioRowViewDatasetIdEnum = {
    SimulationTarget: 'SIMULATION_TARGET',
    Baseline: 'BASELINE',
    A: 'A',
    B: 'B'
} as const;

export type FinancialSimulationScenarioRowViewDatasetIdEnum = typeof FinancialSimulationScenarioRowViewDatasetIdEnum[keyof typeof FinancialSimulationScenarioRowViewDatasetIdEnum];

/**
 * 
 * @export
 * @interface FinancialSimulationScenarioTableMetaView
 */
export interface FinancialSimulationScenarioTableMetaView {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FinancialSimulationScenarioTableMetaView
     */
    'amountKeyLabels': { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancialSimulationScenarioTableMetaView
     */
    'columnLabels': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FinancialSimulationScenarioTableMetaView
     */
    'financialModelId': string;
}
/**
 * 
 * @export
 * @interface FinancialSimulationScenarioTableView
 */
export interface FinancialSimulationScenarioTableView {
    /**
     * 
     * @type {FetchFinancialSimulationScenarioCondition}
     * @memberof FinancialSimulationScenarioTableView
     */
    'condition': FetchFinancialSimulationScenarioCondition;
    /**
     * 
     * @type {FinancialSimulationScenarioTableMetaView}
     * @memberof FinancialSimulationScenarioTableView
     */
    'meta': FinancialSimulationScenarioTableMetaView;
    /**
     * 
     * @type {Array<FinancialSimulationScenarioRowGroupView>}
     * @memberof FinancialSimulationScenarioTableView
     */
    'rows': Array<FinancialSimulationScenarioRowGroupView>;
}
/**
 * 
 * @export
 * @interface FinancialSimulationSimulationTargetPlanView
 */
export interface FinancialSimulationSimulationTargetPlanView {
    /**
     * 
     * @type {FinancialSimulationPeriodView}
     * @memberof FinancialSimulationSimulationTargetPlanView
     */
    'actualPeriod'?: FinancialSimulationPeriodView;
    /**
     * 
     * @type {FinancialSimulationPeriodView}
     * @memberof FinancialSimulationSimulationTargetPlanView
     */
    'period': FinancialSimulationPeriodView;
    /**
     * 
     * @type {FinancialSimulationPlanMasterView}
     * @memberof FinancialSimulationSimulationTargetPlanView
     */
    'planMaster': FinancialSimulationPlanMasterView;
}
/**
 * 
 * @export
 * @interface FindCalculateActionDto
 */
export interface FindCalculateActionDto {
    /**
     * 
     * @type {string}
     * @memberof FindCalculateActionDto
     */
    'formula': string;
}
/**
 * 
 * @export
 * @interface FindProcessConditionDataDto
 */
export interface FindProcessConditionDataDto {
    /**
     * 
     * @type {string}
     * @memberof FindProcessConditionDataDto
     */
    'conditionType': string;
    /**
     * 
     * @type {string}
     * @memberof FindProcessConditionDataDto
     */
    'conditionValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindProcessConditionDataDto
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof FindProcessConditionDataDto
     */
    'rowColumnType': FindProcessConditionDataDtoRowColumnTypeEnum;
}

export const FindProcessConditionDataDtoRowColumnTypeEnum = {
    Row: 'ROW',
    Column: 'COLUMN'
} as const;

export type FindProcessConditionDataDtoRowColumnTypeEnum = typeof FindProcessConditionDataDtoRowColumnTypeEnum[keyof typeof FindProcessConditionDataDtoRowColumnTypeEnum];

/**
 * 
 * @export
 * @interface FindProcessInsertSettingDto
 */
export interface FindProcessInsertSettingDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof FindProcessInsertSettingDto
     */
    'columns': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindProcessInsertSettingDto
     */
    'rows': Array<string>;
}
/**
 * 
 * @export
 * @interface FindProcessOperationDataDto
 */
export interface FindProcessOperationDataDto {
    /**
     * 
     * @type {FindCalculateActionDto}
     * @memberof FindProcessOperationDataDto
     */
    'calculateAction'?: FindCalculateActionDto;
    /**
     * 
     * @type {boolean}
     * @memberof FindProcessOperationDataDto
     */
    'isReused': boolean;
    /**
     * 
     * @type {FindReplaceActionDto}
     * @memberof FindProcessOperationDataDto
     */
    'replaceAction'?: FindReplaceActionDto;
    /**
     * 
     * @type {string}
     * @memberof FindProcessOperationDataDto
     */
    'rowColumnType': FindProcessOperationDataDtoRowColumnTypeEnum;
    /**
     * 
     * @type {FindSplitActionDto}
     * @memberof FindProcessOperationDataDto
     */
    'splitAction'?: FindSplitActionDto;
    /**
     * 
     * @type {string}
     * @memberof FindProcessOperationDataDto
     */
    'variableName'?: string;
}

export const FindProcessOperationDataDtoRowColumnTypeEnum = {
    Row: 'ROW',
    Column: 'COLUMN'
} as const;

export type FindProcessOperationDataDtoRowColumnTypeEnum = typeof FindProcessOperationDataDtoRowColumnTypeEnum[keyof typeof FindProcessOperationDataDtoRowColumnTypeEnum];

/**
 * 
 * @export
 * @interface FindProcessSettingDto
 */
export interface FindProcessSettingDto {
    /**
     * 
     * @type {FindProcessInsertSettingDto}
     * @memberof FindProcessSettingDto
     */
    'insertSetting': FindProcessInsertSettingDto;
    /**
     * 
     * @type {string}
     * @memberof FindProcessSettingDto
     */
    'name': string;
    /**
     * 
     * @type {Array<FindProcessTransformSettingDto>}
     * @memberof FindProcessSettingDto
     */
    'transformSettings': Array<FindProcessTransformSettingDto>;
}
/**
 * 
 * @export
 * @interface FindProcessTransformDto
 */
export interface FindProcessTransformDto {
    /**
     * 
     * @type {Array<FindProcessConditionDataDto>}
     * @memberof FindProcessTransformDto
     */
    'conditions': Array<FindProcessConditionDataDto>;
    /**
     * 
     * @type {string}
     * @memberof FindProcessTransformDto
     */
    'matchingType': FindProcessTransformDtoMatchingTypeEnum;
    /**
     * 
     * @type {Array<FindProcessOperationDataDto>}
     * @memberof FindProcessTransformDto
     */
    'operations': Array<FindProcessOperationDataDto>;
}

export const FindProcessTransformDtoMatchingTypeEnum = {
    And: 'AND',
    Or: 'OR'
} as const;

export type FindProcessTransformDtoMatchingTypeEnum = typeof FindProcessTransformDtoMatchingTypeEnum[keyof typeof FindProcessTransformDtoMatchingTypeEnum];

/**
 * 
 * @export
 * @interface FindProcessTransformSettingDto
 */
export interface FindProcessTransformSettingDto {
    /**
     * 
     * @type {Array<FindProcessTransformDto>}
     * @memberof FindProcessTransformSettingDto
     */
    'conditionalTransforms': Array<FindProcessTransformDto>;
    /**
     * 
     * @type {string}
     * @memberof FindProcessTransformSettingDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindProcessTransformSettingDto
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof FindProcessTransformSettingDto
     */
    'targetRowColumnType': FindProcessTransformSettingDtoTargetRowColumnTypeEnum;
    /**
     * 
     * @type {FindProcessUnConditionalTransformDto}
     * @memberof FindProcessTransformSettingDto
     */
    'unConditionalTransform'?: FindProcessUnConditionalTransformDto;
}

export const FindProcessTransformSettingDtoTargetRowColumnTypeEnum = {
    Row: 'ROW',
    Column: 'COLUMN'
} as const;

export type FindProcessTransformSettingDtoTargetRowColumnTypeEnum = typeof FindProcessTransformSettingDtoTargetRowColumnTypeEnum[keyof typeof FindProcessTransformSettingDtoTargetRowColumnTypeEnum];

/**
 * 
 * @export
 * @interface FindProcessUnConditionalTransformDto
 */
export interface FindProcessUnConditionalTransformDto {
    /**
     * 
     * @type {Array<FindProcessOperationDataDto>}
     * @memberof FindProcessUnConditionalTransformDto
     */
    'operations': Array<FindProcessOperationDataDto>;
}
/**
 * 
 * @export
 * @interface FindReplaceActionDto
 */
export interface FindReplaceActionDto {
    /**
     * 
     * @type {string}
     * @memberof FindReplaceActionDto
     */
    'format': string;
}
/**
 * 
 * @export
 * @interface FindSplitActionDto
 */
export interface FindSplitActionDto {
    /**
     * 
     * @type {string}
     * @memberof FindSplitActionDto
     */
    'delimiter': string;
    /**
     * 
     * @type {number}
     * @memberof FindSplitActionDto
     */
    'pickingNumber': number;
    /**
     * 
     * @type {string}
     * @memberof FindSplitActionDto
     */
    'target': string;
}
/**
 * 
 * @export
 * @interface FixedPatternBody
 */
export interface FixedPatternBody {
    /**
     * 
     * @type {number}
     * @memberof FixedPatternBody
     */
    'fixedAmount': number;
    /**
     * 
     * @type {string}
     * @memberof FixedPatternBody
     */
    'targetAccountId': string;
}
/**
 * 
 * @export
 * @interface FixedPatternView
 */
export interface FixedPatternView {
    /**
     * 
     * @type {number}
     * @memberof FixedPatternView
     */
    'fixedAmount': number;
    /**
     * 
     * @type {string}
     * @memberof FixedPatternView
     */
    'targetAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof FixedPatternView
     */
    'targetAccountName': string;
}
/**
 * 
 * @export
 * @interface ForOpenApiGenerator1Request
 */
export interface ForOpenApiGenerator1Request {
    /**
     * 
     * @type {UploadExcelPlanUpdateParams}
     * @memberof ForOpenApiGenerator1Request
     */
    'uploadExcelPlanUpdateParams': UploadExcelPlanUpdateParams;
}
/**
 * 
 * @export
 * @interface ForOpenApiGenerator2Request
 */
export interface ForOpenApiGenerator2Request {
    /**
     * 
     * @type {CreatePlanUpdateRequestFormatParams}
     * @memberof ForOpenApiGenerator2Request
     */
    'createParams': CreatePlanUpdateRequestFormatParams;
    /**
     * 
     * @type {UpdatePlanUpdateRequestFormatParams}
     * @memberof ForOpenApiGenerator2Request
     */
    'updateParams': UpdatePlanUpdateRequestFormatParams;
}
/**
 * 
 * @export
 * @interface ForOpenApiGenerator3Request
 */
export interface ForOpenApiGenerator3Request {
    /**
     * 
     * @type {UploadNonFinancialItemsParams}
     * @memberof ForOpenApiGenerator3Request
     */
    'updateParams': UploadNonFinancialItemsParams;
}
/**
 * 
 * @export
 * @interface ForOpenApiGeneratorRequest
 */
export interface ForOpenApiGeneratorRequest {
    /**
     * 
     * @type {UploadActualByMappingParams}
     * @memberof ForOpenApiGeneratorRequest
     */
    'updateParams': UploadActualByMappingParams;
}
/**
 * 
 * @export
 * @interface FormattedRetrieveComparisonAnalysisResult
 */
export interface FormattedRetrieveComparisonAnalysisResult {
    /**
     * 
     * @type {string}
     * @memberof FormattedRetrieveComparisonAnalysisResult
     */
    'result'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormattedRetrieveComparisonAnalysisResult
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface GetLoglassPLValueBulkRequest
 */
export interface GetLoglassPLValueBulkRequest {
    /**
     * 
     * @type {Array<GetLoglassPLValueParam>}
     * @memberof GetLoglassPLValueBulkRequest
     */
    'params': Array<GetLoglassPLValueParam>;
}
/**
 * 
 * @export
 * @interface GetLoglassPLValueBulkResponseView
 */
export interface GetLoglassPLValueBulkResponseView {
    /**
     * 
     * @type {Array<GetLoglassPLValueResponseView>}
     * @memberof GetLoglassPLValueBulkResponseView
     */
    'values': Array<GetLoglassPLValueResponseView>;
}
/**
 * 
 * @export
 * @interface GetLoglassPLValueParam
 */
export interface GetLoglassPLValueParam {
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueParam
     */
    'accountCode': string;
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueParam
     */
    'accountTreeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueParam
     */
    'departmentCode': string;
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueParam
     */
    'endYearMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueParam
     */
    'fetchItemType': string;
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueParam
     */
    'orgTreeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueParam
     */
    'sourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueParam
     */
    'startYearMonth': string;
}
/**
 * 
 * @export
 * @interface GetLoglassPLValueResponseView
 */
export interface GetLoglassPLValueResponseView {
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueResponseView
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLoglassPLValueResponseView
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof GetLoglassPLValueResponseView
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface GetReportTableDetailBody
 */
export interface GetReportTableDetailBody {
    /**
     * 
     * @type {ReportTableCellCondition}
     * @memberof GetReportTableDetailBody
     */
    'cellCondition': ReportTableCellCondition;
    /**
     * 
     * @type {DataCondition}
     * @memberof GetReportTableDetailBody
     */
    'dataCondition': DataCondition;
    /**
     * 
     * @type {boolean}
     * @memberof GetReportTableDetailBody
     */
    'isAsc': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetReportTableDetailBody
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof GetReportTableDetailBody
     */
    'offset': number;
    /**
     * 
     * @type {Array<RowAggregationDimension>}
     * @memberof GetReportTableDetailBody
     */
    'rowAggregationDimensions': Array<RowAggregationDimension>;
    /**
     * 
     * @type {boolean}
     * @memberof GetReportTableDetailBody
     */
    'showNoneDimension': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetReportTableDetailBody
     */
    'showNumbersIn': GetReportTableDetailBodyShowNumbersInEnum;
    /**
     * 
     * @type {string}
     * @memberof GetReportTableDetailBody
     */
    'sortKey': GetReportTableDetailBodySortKeyEnum;
}

export const GetReportTableDetailBodyShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type GetReportTableDetailBodyShowNumbersInEnum = typeof GetReportTableDetailBodyShowNumbersInEnum[keyof typeof GetReportTableDetailBodyShowNumbersInEnum];
export const GetReportTableDetailBodySortKeyEnum = {
    JournalDate: 'JOURNAL_DATE',
    DepartmentName: 'DEPARTMENT_NAME',
    AccountName: 'ACCOUNT_NAME',
    Description: 'DESCRIPTION',
    Amount: 'AMOUNT',
    Dimension1Name: 'DIMENSION_1_NAME',
    Dimension2Name: 'DIMENSION_2_NAME',
    Dimension3Name: 'DIMENSION_3_NAME',
    Dimension4Name: 'DIMENSION_4_NAME',
    Dimension5Name: 'DIMENSION_5_NAME',
    Dimension6Name: 'DIMENSION_6_NAME',
    Dimension7Name: 'DIMENSION_7_NAME'
} as const;

export type GetReportTableDetailBodySortKeyEnum = typeof GetReportTableDetailBodySortKeyEnum[keyof typeof GetReportTableDetailBodySortKeyEnum];

/**
 * 
 * @export
 * @interface GetReportTableDetailView
 */
export interface GetReportTableDetailView {
    /**
     * 
     * @type {ReportTableDetailHeaderView}
     * @memberof GetReportTableDetailView
     */
    'header': ReportTableDetailHeaderView;
    /**
     * 
     * @type {boolean}
     * @memberof GetReportTableDetailView
     */
    'isBigQueryUsed': boolean;
    /**
     * 
     * @type {PagingDto}
     * @memberof GetReportTableDetailView
     */
    'paging': PagingDto;
    /**
     * 
     * @type {Array<ReportTableDetailRowView>}
     * @memberof GetReportTableDetailView
     */
    'rows': Array<ReportTableDetailRowView>;
    /**
     * 
     * @type {string}
     * @memberof GetReportTableDetailView
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface GetReportTableView
 */
export interface GetReportTableView {
    /**
     * 
     * @type {ReportConditionSchema}
     * @memberof GetReportTableView
     */
    'appliedCondition': ReportConditionSchema;
    /**
     * 
     * @type {Array<InvalidConditionView>}
     * @memberof GetReportTableView
     */
    'invalidConditions': Array<InvalidConditionView>;
    /**
     * 
     * @type {boolean}
     * @memberof GetReportTableView
     */
    'isBigQueryUsed': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetReportTableView
     */
    'omitRowsCount': number;
    /**
     * 
     * @type {ReportTableView}
     * @memberof GetReportTableView
     */
    'reportFlatTable'?: ReportTableView;
    /**
     * 
     * @type {ReportTreeTableView}
     * @memberof GetReportTableView
     */
    'reportTreeTable'?: ReportTreeTableView;
    /**
     * 
     * @type {Array<WarningMessageView>}
     * @memberof GetReportTableView
     */
    'warningMessages': Array<WarningMessageView>;
}
/**
 * 
 * @export
 * @interface HeaderCellGroupView
 */
export interface HeaderCellGroupView {
    /**
     * 
     * @type {Array<HeaderCellView>}
     * @memberof HeaderCellGroupView
     */
    'cells': Array<HeaderCellView>;
}
/**
 * @type HeaderCellView
 * @export
 */
export type HeaderCellView = { type: 'COMPARISON' } & HeaderComparisonFormulaCellView | { type: 'DATA' } & HeaderDataCellView;

/**
 * 
 * @export
 * @interface HeaderComparisonFormulaCellView
 */
export interface HeaderComparisonFormulaCellView {
    /**
     * 
     * @type {string}
     * @memberof HeaderComparisonFormulaCellView
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof HeaderComparisonFormulaCellView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HeaderComparisonFormulaCellView
     */
    'label': string;
    /**
     * 
     * @type {CellGroupOrderKey}
     * @memberof HeaderComparisonFormulaCellView
     */
    'orderKey': CellGroupOrderKey;
    /**
     * 
     * @type {ReportCellType}
     * @memberof HeaderComparisonFormulaCellView
     */
    'type': ReportCellType;
}


/**
 * 
 * @export
 * @interface HeaderDataCellView
 */
export interface HeaderDataCellView {
    /**
     * 
     * @type {string}
     * @memberof HeaderDataCellView
     */
    'actualPlanLabel': string;
    /**
     * 
     * @type {string}
     * @memberof HeaderDataCellView
     */
    'dataConditionId': HeaderDataCellViewDataConditionIdEnum;
    /**
     * 
     * @type {string}
     * @memberof HeaderDataCellView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HeaderDataCellView
     */
    'label': string;
    /**
     * 
     * @type {CellGroupOrderKey}
     * @memberof HeaderDataCellView
     */
    'orderKey': CellGroupOrderKey;
    /**
     * 
     * @type {ReportCellType}
     * @memberof HeaderDataCellView
     */
    'type': ReportCellType;
}

export const HeaderDataCellViewDataConditionIdEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;

export type HeaderDataCellViewDataConditionIdEnum = typeof HeaderDataCellViewDataConditionIdEnum[keyof typeof HeaderDataCellViewDataConditionIdEnum];

/**
 * 
 * @export
 * @interface HeaderSumCellGroupView
 */
export interface HeaderSumCellGroupView {
    /**
     * 
     * @type {Array<HeaderCellView>}
     * @memberof HeaderSumCellGroupView
     */
    'cells': Array<HeaderCellView>;
}
/**
 * 
 * @export
 * @interface HttpStatusCode
 */
export interface HttpStatusCode {
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    'error'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    'is1xxInformational'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    'is2xxSuccessful'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    'is3xxRedirection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    'is4xxClientError'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HttpStatusCode
     */
    'is5xxServerError'?: boolean;
}
/**
 * 
 * @export
 * @interface IDAccount
 */
export interface IDAccount {
    /**
     * 
     * @type {string}
     * @memberof IDAccount
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDActualImportEvent
 */
export interface IDActualImportEvent {
    /**
     * 
     * @type {string}
     * @memberof IDActualImportEvent
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDActualImportMapping
 */
export interface IDActualImportMapping {
    /**
     * 
     * @type {string}
     * @memberof IDActualImportMapping
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDActualImportMappingDebitCreditCondition
 */
export interface IDActualImportMappingDebitCreditCondition {
    /**
     * 
     * @type {string}
     * @memberof IDActualImportMappingDebitCreditCondition
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDActualImportMappingFilter
 */
export interface IDActualImportMappingFilter {
    /**
     * 
     * @type {string}
     * @memberof IDActualImportMappingFilter
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDActualImportMappingItem
 */
export interface IDActualImportMappingItem {
    /**
     * 
     * @type {string}
     * @memberof IDActualImportMappingItem
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDActualImportMappingSubsidiaryCode
 */
export interface IDActualImportMappingSubsidiaryCode {
    /**
     * 
     * @type {string}
     * @memberof IDActualImportMappingSubsidiaryCode
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDCalculatedAccount
 */
export interface IDCalculatedAccount {
    /**
     * 
     * @type {string}
     * @memberof IDCalculatedAccount
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDDepartment
 */
export interface IDDepartment {
    /**
     * 
     * @type {string}
     * @memberof IDDepartment
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDDimension
 */
export interface IDDimension {
    /**
     * 
     * @type {string}
     * @memberof IDDimension
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDOrgTree
 */
export interface IDOrgTree {
    /**
     * 
     * @type {string}
     * @memberof IDOrgTree
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPLAccountTree
 */
export interface IDPLAccountTree {
    /**
     * 
     * @type {string}
     * @memberof IDPLAccountTree
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanMaster
 */
export interface IDPlanMaster {
    /**
     * 
     * @type {string}
     * @memberof IDPlanMaster
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanUpdateRequestFile
 */
export interface IDPlanUpdateRequestFile {
    /**
     * 
     * @type {string}
     * @memberof IDPlanUpdateRequestFile
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanUpdateRequestFormat
 */
export interface IDPlanUpdateRequestFormat {
    /**
     * 
     * @type {string}
     * @memberof IDPlanUpdateRequestFormat
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanUpdateRequestMapping
 */
export interface IDPlanUpdateRequestMapping {
    /**
     * 
     * @type {string}
     * @memberof IDPlanUpdateRequestMapping
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanUpdateRequestMappingAccountKey
 */
export interface IDPlanUpdateRequestMappingAccountKey {
    /**
     * 
     * @type {string}
     * @memberof IDPlanUpdateRequestMappingAccountKey
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanUpdateRequestMappingDepartmentKey
 */
export interface IDPlanUpdateRequestMappingDepartmentKey {
    /**
     * 
     * @type {string}
     * @memberof IDPlanUpdateRequestMappingDepartmentKey
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanUpdateRequestMappingDescriptionKey
 */
export interface IDPlanUpdateRequestMappingDescriptionKey {
    /**
     * 
     * @type {string}
     * @memberof IDPlanUpdateRequestMappingDescriptionKey
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanUpdateRequestMappingDimensionKey
 */
export interface IDPlanUpdateRequestMappingDimensionKey {
    /**
     * 
     * @type {string}
     * @memberof IDPlanUpdateRequestMappingDimensionKey
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanUpdateRequestMappingFilter
 */
export interface IDPlanUpdateRequestMappingFilter {
    /**
     * 
     * @type {string}
     * @memberof IDPlanUpdateRequestMappingFilter
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanUpdateRequestMappingYearMonthKey
 */
export interface IDPlanUpdateRequestMappingYearMonthKey {
    /**
     * 
     * @type {string}
     * @memberof IDPlanUpdateRequestMappingYearMonthKey
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDPlanningProject
 */
export interface IDPlanningProject {
    /**
     * 
     * @type {string}
     * @memberof IDPlanningProject
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDProcessSetting
 */
export interface IDProcessSetting {
    /**
     * 
     * @type {string}
     * @memberof IDProcessSetting
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDUser
 */
export interface IDUser {
    /**
     * 
     * @type {string}
     * @memberof IDUser
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IDViewPermission
 */
export interface IDViewPermission {
    /**
     * 
     * @type {string}
     * @memberof IDViewPermission
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IdWithName
 */
export interface IdWithName {
    /**
     * 
     * @type {string}
     * @memberof IdWithName
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IdWithName
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface IncreaseRateForEachMonthView
 */
export interface IncreaseRateForEachMonthView {
    /**
     * 
     * @type {number}
     * @memberof IncreaseRateForEachMonthView
     */
    'month': number;
    /**
     * 
     * @type {string}
     * @memberof IncreaseRateForEachMonthView
     */
    'ratePercent': string;
}
/**
 * 
 * @export
 * @interface InitiatedComparisonAnalysis
 */
export interface InitiatedComparisonAnalysis {
    /**
     * 
     * @type {Array<AnalysisAssistantLayoutComponent>}
     * @memberof InitiatedComparisonAnalysis
     */
    'blocks': Array<AnalysisAssistantLayoutComponent>;
}
/**
 * 
 * @export
 * @interface InvalidConditionView
 */
export interface InvalidConditionView {
    /**
     * 
     * @type {string}
     * @memberof InvalidConditionView
     */
    'dimensionLabel': string;
    /**
     * 
     * @type {Array<IdWithName>}
     * @memberof InvalidConditionView
     */
    'idWithNames': Array<IdWithName>;
    /**
     * 
     * @type {string}
     * @memberof InvalidConditionView
     */
    'treeLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidConditionView
     */
    'type': InvalidConditionViewTypeEnum;
}

export const InvalidConditionViewTypeEnum = {
    NotIncludedInTree: 'NOT_INCLUDED_IN_TREE',
    Deleted: 'DELETED'
} as const;

export type InvalidConditionViewTypeEnum = typeof InvalidConditionViewTypeEnum[keyof typeof InvalidConditionViewTypeEnum];

/**
 * 
 * @export
 * @interface JournalDescriptionView
 */
export interface JournalDescriptionView {
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionView
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionView
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionView
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionView
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionView
     */
    'descriptionWithTags': string;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionView
     */
    'journalDate': string;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionView
     */
    'subAccountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionView
     */
    'supplierName'?: string;
    /**
     * 
     * @type {Array<TagView>}
     * @memberof JournalDescriptionView
     */
    'tags': Array<TagView>;
}
/**
 * 
 * @export
 * @interface JournalDescriptionsView
 */
export interface JournalDescriptionsView {
    /**
     * 
     * @type {number}
     * @memberof JournalDescriptionsView
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof JournalDescriptionsView
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof JournalDescriptionsView
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionsView
     */
    'roundingMode': JournalDescriptionsViewRoundingModeEnum;
    /**
     * 
     * @type {string}
     * @memberof JournalDescriptionsView
     */
    'showNumbersIn': JournalDescriptionsViewShowNumbersInEnum;
    /**
     * 
     * @type {Array<JournalDescriptionView>}
     * @memberof JournalDescriptionsView
     */
    'values': Array<JournalDescriptionView>;
}

export const JournalDescriptionsViewRoundingModeEnum = {
    Down: 'DOWN',
    HalfUp: 'HALF_UP'
} as const;

export type JournalDescriptionsViewRoundingModeEnum = typeof JournalDescriptionsViewRoundingModeEnum[keyof typeof JournalDescriptionsViewRoundingModeEnum];
export const JournalDescriptionsViewShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type JournalDescriptionsViewShowNumbersInEnum = typeof JournalDescriptionsViewShowNumbersInEnum[keyof typeof JournalDescriptionsViewShowNumbersInEnum];

/**
 * 
 * @export
 * @interface LastAllocationResultDto
 */
export interface LastAllocationResultDto {
    /**
     * 
     * @type {string}
     * @memberof LastAllocationResultDto
     */
    'allocationStatus': LastAllocationResultDtoAllocationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof LastAllocationResultDto
     */
    'allocationTargetType': LastAllocationResultDtoAllocationTargetTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LastAllocationResultDto
     */
    'hasSkippedSteps': boolean;
    /**
     * 
     * @type {string}
     * @memberof LastAllocationResultDto
     */
    'lastExecutedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LastAllocationResultDto
     */
    'lastExecutionFinishedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LastAllocationResultDto
     */
    'planId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LastAllocationResultDto
     */
    'resultMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof LastAllocationResultDto
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof LastAllocationResultDto
     */
    'ruleName': string;
    /**
     * 
     * @type {string}
     * @memberof LastAllocationResultDto
     */
    'yearMonth': string;
}

export const LastAllocationResultDtoAllocationStatusEnum = {
    NoExecutionHistory: 'NO_EXECUTION_HISTORY',
    ErrorSetting: 'ERROR_SETTING',
    SuccessfulCompletion: 'SUCCESSFUL_COMPLETION',
    ErrorTermination: 'ERROR_TERMINATION',
    InProgress: 'IN_PROGRESS'
} as const;

export type LastAllocationResultDtoAllocationStatusEnum = typeof LastAllocationResultDtoAllocationStatusEnum[keyof typeof LastAllocationResultDtoAllocationStatusEnum];
export const LastAllocationResultDtoAllocationTargetTypeEnum = {
    Actual: 'ACTUAL',
    Plan: 'PLAN'
} as const;

export type LastAllocationResultDtoAllocationTargetTypeEnum = typeof LastAllocationResultDtoAllocationTargetTypeEnum[keyof typeof LastAllocationResultDtoAllocationTargetTypeEnum];

/**
 * 
 * @export
 * @interface LatestPlanningProjectNodeMetaView
 */
export interface LatestPlanningProjectNodeMetaView {
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'actualDownloadPeriodEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'actualDownloadPeriodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'actualEndWhenPlanningProject'?: string;
    /**
     * 
     * @type {Array<DepartmentView>}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'assignedDepartments': Array<DepartmentView>;
    /**
     * 
     * @type {boolean}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'canActualDownload': boolean;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'organizationTreeId': string;
    /**
     * 
     * @type {PlanningProjectOverView}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'overview': PlanningProjectOverView;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'planType': LatestPlanningProjectNodeMetaViewPlanTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'planningProjectEndAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'planningProjectId': string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'planningProjectName': string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'planningProjectStartAt': string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'planningTargetEnd': string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'planningTargetStart': string;
    /**
     * 
     * @type {boolean}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'requirePlanMasterAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'roundingMode': LatestPlanningProjectNodeMetaViewRoundingModeEnum;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'scheduledPlanningProjectEndAt': string;
    /**
     * 
     * @type {string}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'status': LatestPlanningProjectNodeMetaViewStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LatestPlanningProjectNodeMetaView
     */
    'useMultiStepApprovalFlow': boolean;
}

export const LatestPlanningProjectNodeMetaViewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type LatestPlanningProjectNodeMetaViewPlanTypeEnum = typeof LatestPlanningProjectNodeMetaViewPlanTypeEnum[keyof typeof LatestPlanningProjectNodeMetaViewPlanTypeEnum];
export const LatestPlanningProjectNodeMetaViewRoundingModeEnum = {
    Down: 'DOWN',
    HalfUp: 'HALF_UP'
} as const;

export type LatestPlanningProjectNodeMetaViewRoundingModeEnum = typeof LatestPlanningProjectNodeMetaViewRoundingModeEnum[keyof typeof LatestPlanningProjectNodeMetaViewRoundingModeEnum];
export const LatestPlanningProjectNodeMetaViewStatusEnum = {
    Creating: 'CREATING',
    CreateError: 'CREATE_ERROR',
    Wip: 'WIP',
    Fixing: 'FIXING',
    Error: 'ERROR',
    Fixed: 'FIXED'
} as const;

export type LatestPlanningProjectNodeMetaViewStatusEnum = typeof LatestPlanningProjectNodeMetaViewStatusEnum[keyof typeof LatestPlanningProjectNodeMetaViewStatusEnum];

/**
 * 
 * @export
 * @interface LinkedGSheetsView
 */
export interface LinkedGSheetsView {
    /**
     * 
     * @type {Array<LinkedGoogleSheetView>}
     * @memberof LinkedGSheetsView
     */
    'items': Array<LinkedGoogleSheetView>;
    /**
     * 
     * @type {string}
     * @memberof LinkedGSheetsView
     */
    'tenantId': string;
}
/**
 * 
 * @export
 * @interface LinkedGoogleSheetView
 */
export interface LinkedGoogleSheetView {
    /**
     * 
     * @type {string}
     * @memberof LinkedGoogleSheetView
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedGoogleSheetView
     */
    'sheetId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedGoogleSheetView
     */
    'sheetName': string;
}
/**
 * 
 * @export
 * @interface ListAmountTypeCommentRequestBody
 */
export interface ListAmountTypeCommentRequestBody {
    /**
     * 
     * @type {MaybeOwnValueAccountId}
     * @memberof ListAmountTypeCommentRequestBody
     */
    'accountId': MaybeOwnValueAccountId;
    /**
     * 
     * @type {string}
     * @memberof ListAmountTypeCommentRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAmountTypeCommentRequestBody
     */
    'departmentIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListAmountTypeCommentRequestBody
     */
    'orgTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAmountTypeCommentRequestBody
     */
    'periodKeyValue': string;
    /**
     * 
     * @type {string}
     * @memberof ListAmountTypeCommentRequestBody
     */
    'planMasterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAmountTypeCommentRequestBody
     */
    'planningProjectId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListAmountTypeCommentRequestBody
     */
    'requireAllocatedItem': boolean;
}
/**
 * 
 * @export
 * @interface ListCommentsActualStartEndParam
 */
export interface ListCommentsActualStartEndParam {
    /**
     * 
     * @type {string}
     * @memberof ListCommentsActualStartEndParam
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof ListCommentsActualStartEndParam
     */
    'start': string;
}
/**
 * 
 * @export
 * @interface ListCommentsRequestABParam
 */
export interface ListCommentsRequestABParam {
    /**
     * 
     * @type {ListCommentsActualStartEndParam}
     * @memberof ListCommentsRequestABParam
     */
    'actualStartEnd'?: ListCommentsActualStartEndParam;
    /**
     * 
     * @type {string}
     * @memberof ListCommentsRequestABParam
     */
    'planEnd': string;
    /**
     * 
     * @type {string}
     * @memberof ListCommentsRequestABParam
     */
    'planMasterId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListCommentsRequestABParam
     */
    'planShowActual': boolean;
    /**
     * 
     * @type {string}
     * @memberof ListCommentsRequestABParam
     */
    'planStart': string;
    /**
     * 
     * @type {string}
     * @memberof ListCommentsRequestABParam
     */
    'planningProjectId'?: string;
}
/**
 * 
 * @export
 * @interface ListCommentsRequestBody
 */
export interface ListCommentsRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ListCommentsRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListCommentsRequestBody
     */
    'departmentIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListCommentsRequestBody
     */
    'orgTreeId'?: string;
    /**
     * 
     * @type {ListCommentsRequestABParam}
     * @memberof ListCommentsRequestBody
     */
    'paramA': ListCommentsRequestABParam;
    /**
     * 
     * @type {ListCommentsRequestABParam}
     * @memberof ListCommentsRequestBody
     */
    'paramB': ListCommentsRequestABParam;
    /**
     * 
     * @type {boolean}
     * @memberof ListCommentsRequestBody
     */
    'requireAllocatedItems': boolean;
}
/**
 * 
 * @export
 * @interface ListDiffTypeCommentRequestBody
 */
export interface ListDiffTypeCommentRequestBody {
    /**
     * 
     * @type {MaybeOwnValueAccountId}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'accountId': MaybeOwnValueAccountId;
    /**
     * 
     * @type {string}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'departmentIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'isRatio': boolean;
    /**
     * 
     * @type {string}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'orgTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'planAPeriodKeyValue': string;
    /**
     * 
     * @type {string}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'planAPlanMasterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'planAPlanningProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'planBPeriodKeyValue': string;
    /**
     * 
     * @type {string}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'planBPlanMasterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'planBPlanningProjectId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListDiffTypeCommentRequestBody
     */
    'requireAllocatedItem': boolean;
}
/**
 * 
 * @export
 * @interface ListSimulationView
 */
export interface ListSimulationView {
    /**
     * 
     * @type {Array<SimulationListItem>}
     * @memberof ListSimulationView
     */
    'items': Array<SimulationListItem>;
}
/**
 * 
 * @export
 * @interface ListUnassignedActiveUserDto
 */
export interface ListUnassignedActiveUserDto {
    /**
     * 
     * @type {string}
     * @memberof ListUnassignedActiveUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ListUnassignedActiveUserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ListUnassignedActiveUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ListUnassignedActiveUserDto
     */
    'role': ListUnassignedActiveUserDtoRoleEnum;
}

export const ListUnassignedActiveUserDtoRoleEnum = {
    LoglassSystemAdmin: 'LOGLASS_SYSTEM_ADMIN',
    TenantAdmin: 'TENANT_ADMIN',
    CorpPlanning: 'CORP_PLANNING',
    Manager: 'MANAGER',
    ItStaff: 'IT_STAFF',
    General: 'GENERAL'
} as const;

export type ListUnassignedActiveUserDtoRoleEnum = typeof ListUnassignedActiveUserDtoRoleEnum[keyof typeof ListUnassignedActiveUserDtoRoleEnum];

/**
 * 
 * @export
 * @interface ListUnitAmountItemsResponse
 */
export interface ListUnitAmountItemsResponse {
    /**
     * 
     * @type {Array<UnitAmountItemView>}
     * @memberof ListUnitAmountItemsResponse
     */
    'items': Array<UnitAmountItemView>;
}
/**
 * 
 * @export
 * @interface LocalCurrencyData
 */
export interface LocalCurrencyData {
    /**
     * 
     * @type {number}
     * @memberof LocalCurrencyData
     */
    'conversionFactor': number;
    /**
     * 
     * @type {CurrencyMaster}
     * @memberof LocalCurrencyData
     */
    'currency': CurrencyMaster;
    /**
     * 
     * @type {string}
     * @memberof LocalCurrencyData
     */
    'currencyLocalId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocalCurrencyData
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LocalCurrencyData
     */
    'displayDecimal': number;
    /**
     * 
     * @type {string}
     * @memberof LocalCurrencyData
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface LocalCurrencyRequestParam
 */
export interface LocalCurrencyRequestParam {
    /**
     * 
     * @type {number}
     * @memberof LocalCurrencyRequestParam
     */
    'conversionFactor': number;
    /**
     * 
     * @type {string}
     * @memberof LocalCurrencyRequestParam
     */
    'currencyCode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocalCurrencyRequestParam
     */
    'departmentIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LocalCurrencyRequestParam
     */
    'displayDecimal': number;
    /**
     * 
     * @type {string}
     * @memberof LocalCurrencyRequestParam
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface LoglassYearMonth
 */
export interface LoglassYearMonth {
    /**
     * 
     * @type {LoglassYearMonthValue}
     * @memberof LoglassYearMonth
     */
    'value': LoglassYearMonthValue;
}
/**
 * 
 * @export
 * @interface LoglassYearMonthValue
 */
export interface LoglassYearMonthValue {
    /**
     * 
     * @type {boolean}
     * @memberof LoglassYearMonthValue
     */
    'leapYear'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoglassYearMonthValue
     */
    'month'?: LoglassYearMonthValueMonthEnum;
    /**
     * 
     * @type {number}
     * @memberof LoglassYearMonthValue
     */
    'monthValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoglassYearMonthValue
     */
    'year'?: number;
}

export const LoglassYearMonthValueMonthEnum = {
    January: 'JANUARY',
    February: 'FEBRUARY',
    March: 'MARCH',
    April: 'APRIL',
    May: 'MAY',
    June: 'JUNE',
    July: 'JULY',
    August: 'AUGUST',
    September: 'SEPTEMBER',
    October: 'OCTOBER',
    November: 'NOVEMBER',
    December: 'DECEMBER'
} as const;

export type LoglassYearMonthValueMonthEnum = typeof LoglassYearMonthValueMonthEnum[keyof typeof LoglassYearMonthValueMonthEnum];

/**
 * 
 * @export
 * @interface MappingSheetIndex
 */
export interface MappingSheetIndex {
    /**
     * 
     * @type {string}
     * @memberof MappingSheetIndex
     */
    'mappingId': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MappingSheetIndex
     */
    'sheetIndexes': Array<number>;
}
/**
 * 
 * @export
 * @interface MatchedAccountKeywordView
 */
export interface MatchedAccountKeywordView {
    /**
     * 
     * @type {string}
     * @memberof MatchedAccountKeywordView
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchedAccountKeywordView
     */
    'exAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchedAccountKeywordView
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MatchedDimensionTagKeywordView
 */
export interface MatchedDimensionTagKeywordView {
    /**
     * 
     * @type {string}
     * @memberof MatchedDimensionTagKeywordView
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchedDimensionTagKeywordView
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchedDimensionTagKeywordView
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MaybeOwnValueAccountCode
 */
export interface MaybeOwnValueAccountCode {
    /**
     * 
     * @type {string}
     * @memberof MaybeOwnValueAccountCode
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface MaybeOwnValueAccountId
 */
export interface MaybeOwnValueAccountId {
    /**
     * 
     * @type {string}
     * @memberof MaybeOwnValueAccountId
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface MaybeOwnValueDepartmentCode
 */
export interface MaybeOwnValueDepartmentCode {
    /**
     * 
     * @type {string}
     * @memberof MaybeOwnValueDepartmentCode
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface MaybeOwnValueDepartmentId
 */
export interface MaybeOwnValueDepartmentId {
    /**
     * 
     * @type {string}
     * @memberof MaybeOwnValueDepartmentId
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Meta
 */
export interface Meta {
    /**
     * 
     * @type {boolean}
     * @memberof Meta
     */
    'canShowDetail': boolean;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'columnType': MetaColumnTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'dataConditionId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Meta
     */
    'hasAllocatedItem': boolean;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'roundingMode': MetaRoundingModeEnum;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'showNumbersIn': MetaShowNumbersInEnum;
}

export const MetaColumnTypeEnum = {
    ActualBudget: 'ACTUAL_BUDGET',
    ActualForecast: 'ACTUAL_FORECAST',
    ActualBudgetMixed: 'ACTUAL_BUDGET_MIXED',
    ActualForecastMixed: 'ACTUAL_FORECAST_MIXED',
    ActualNull: 'ACTUAL_NULL',
    NullNull: 'NULL_NULL',
    NullBudget: 'NULL_BUDGET',
    NullForecast: 'NULL_FORECAST'
} as const;

export type MetaColumnTypeEnum = typeof MetaColumnTypeEnum[keyof typeof MetaColumnTypeEnum];
export const MetaRoundingModeEnum = {
    Down: 'DOWN',
    HalfUp: 'HALF_UP'
} as const;

export type MetaRoundingModeEnum = typeof MetaRoundingModeEnum[keyof typeof MetaRoundingModeEnum];
export const MetaShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type MetaShowNumbersInEnum = typeof MetaShowNumbersInEnum[keyof typeof MetaShowNumbersInEnum];

/**
 * 
 * @export
 * @interface MetaView
 */
export interface MetaView {
    /**
     * 
     * @type {Array<HeaderCellGroupView>}
     * @memberof MetaView
     */
    'headerCellGroups': Array<HeaderCellGroupView>;
    /**
     * 
     * @type {HeaderSumCellGroupView}
     * @memberof MetaView
     */
    'headerSumCellGroup'?: HeaderSumCellGroupView;
    /**
     * 
     * @type {Array<RowAggregationKeyMetaView>}
     * @memberof MetaView
     */
    'rowAggregationKeyMetas': Array<RowAggregationKeyMetaView>;
}
/**
 * 
 * @export
 * @interface ModelAndView
 */
export interface ModelAndView {
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    'empty'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ModelAndView
     */
    'model'?: { [key: string]: object; };
    /**
     * 
     * @type {ModelAndViewModelMap}
     * @memberof ModelAndView
     */
    'modelMap'?: ModelAndViewModelMap;
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    'reference'?: boolean;
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof ModelAndView
     */
    'status'?: HttpStatusCode;
    /**
     * 
     * @type {View}
     * @memberof ModelAndView
     */
    'view'?: View;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    'viewName'?: string;
}
/**
 * 
 * @export
 * @interface ModelAndViewModelMap
 */
export interface ModelAndViewModelMap {
    [key: string]: object | any;

    /**
     * 
     * @type {boolean}
     * @memberof ModelAndViewModelMap
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface MonthToFixedAmount
 */
export interface MonthToFixedAmount {
    /**
     * 
     * @type {number}
     * @memberof MonthToFixedAmount
     */
    'fixedAmount': number;
    /**
     * 
     * @type {number}
     * @memberof MonthToFixedAmount
     */
    'month': number;
}
/**
 * 
 * @export
 * @interface MonthToRatio
 */
export interface MonthToRatio {
    /**
     * 
     * @type {number}
     * @memberof MonthToRatio
     */
    'month': number;
    /**
     * 
     * @type {string}
     * @memberof MonthToRatio
     */
    'ratio': string;
}
/**
 * 
 * @export
 * @interface MonthlyAnalysisTimeSeriesUnitView
 */
export interface MonthlyAnalysisTimeSeriesUnitView {
    /**
     * 
     * @type {number}
     * @memberof MonthlyAnalysisTimeSeriesUnitView
     */
    'actual'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyAnalysisTimeSeriesUnitView
     */
    'cumulativeUntilMonthActual'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyAnalysisTimeSeriesUnitView
     */
    'cumulativeUntilMonthPlan'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthlyAnalysisTimeSeriesUnitView
     */
    'plan'?: number;
    /**
     * 
     * @type {string}
     * @memberof MonthlyAnalysisTimeSeriesUnitView
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface MoveBSAccountTreeNodeRequestBody
 */
export interface MoveBSAccountTreeNodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof MoveBSAccountTreeNodeRequestBody
     */
    'newParentAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoveBSAccountTreeNodeRequestBody
     */
    'targetAccountId': string;
}
/**
 * 
 * @export
 * @interface MoveComparisonSheetSelectedConditionGroupRequestBody
 */
export interface MoveComparisonSheetSelectedConditionGroupRequestBody {
    /**
     * 
     * @type {string}
     * @memberof MoveComparisonSheetSelectedConditionGroupRequestBody
     */
    'groupId': string;
}
/**
 * 
 * @export
 * @interface MoveDimensionTreeNodeRequestBody
 */
export interface MoveDimensionTreeNodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof MoveDimensionTreeNodeRequestBody
     */
    'parentDimensionTagId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoveDimensionTreeNodeRequestBody
     */
    'targetDimensionTagId': string;
}
/**
 * 
 * @export
 * @interface MoveNodesRequestBody
 */
export interface MoveNodesRequestBody {
    /**
     * 
     * @type {string}
     * @memberof MoveNodesRequestBody
     */
    'newParentAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof MoveNodesRequestBody
     */
    'targetAccountId': string;
}
/**
 * 
 * @export
 * @interface MoveNonFinancialAccountTreeNodeBody
 */
export interface MoveNonFinancialAccountTreeNodeBody {
    /**
     * 
     * @type {NonFinancialAccountTreeNodeBody}
     * @memberof MoveNonFinancialAccountTreeNodeBody
     */
    'newParentNode'?: NonFinancialAccountTreeNodeBody;
    /**
     * 
     * @type {NonFinancialAccountTreeNodeBody}
     * @memberof MoveNonFinancialAccountTreeNodeBody
     */
    'targetNode': NonFinancialAccountTreeNodeBody;
}
/**
 * 
 * @export
 * @interface MoveReportBody
 */
export interface MoveReportBody {
    /**
     * 
     * @type {string}
     * @memberof MoveReportBody
     */
    'destinationFolderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoveReportBody
     */
    'reportType': MoveReportBodyReportTypeEnum;
}

export const MoveReportBodyReportTypeEnum = {
    Pl: 'PL',
    Bs: 'BS',
    AiPlanActualAnalysisReport: 'AI_PLAN_ACTUAL_ANALYSIS_REPORT'
} as const;

export type MoveReportBodyReportTypeEnum = typeof MoveReportBodyReportTypeEnum[keyof typeof MoveReportBodyReportTypeEnum];

/**
 * 
 * @export
 * @interface MoveReportFolderBody
 */
export interface MoveReportFolderBody {
    /**
     * 
     * @type {string}
     * @memberof MoveReportFolderBody
     */
    'destinationFolderId'?: string;
}
/**
 * 
 * @export
 * @interface MovedNotIncludedAccountsView
 */
export interface MovedNotIncludedAccountsView {
    /**
     * 
     * @type {MovedNotIncludedBSSubAccountsView}
     * @memberof MovedNotIncludedAccountsView
     */
    'movedNotIncludedBSSubAccounts': MovedNotIncludedBSSubAccountsView;
    /**
     * 
     * @type {MovedNotIncludedSubAccountsView}
     * @memberof MovedNotIncludedAccountsView
     */
    'movedNotIncludedSubAccounts': MovedNotIncludedSubAccountsView;
}
/**
 * 
 * @export
 * @interface MovedNotIncludedBSSubAccountsView
 */
export interface MovedNotIncludedBSSubAccountsView {
    /**
     * 
     * @type {number}
     * @memberof MovedNotIncludedBSSubAccountsView
     */
    'numOfMoved': number;
}
/**
 * 
 * @export
 * @interface MovedNotIncludedSubAccountsView
 */
export interface MovedNotIncludedSubAccountsView {
    /**
     * 
     * @type {number}
     * @memberof MovedNotIncludedSubAccountsView
     */
    'numOfMoved': number;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface NonFinancialAccountFlatTreeForSelectionView
 */
export interface NonFinancialAccountFlatTreeForSelectionView {
    /**
     * 
     * @type {Array<NonFinancialAccountTreeNodeForSelectionView>}
     * @memberof NonFinancialAccountFlatTreeForSelectionView
     */
    'items': Array<NonFinancialAccountTreeNodeForSelectionView>;
    /**
     * 
     * @type {NonFinancialAccountTreeView}
     * @memberof NonFinancialAccountFlatTreeForSelectionView
     */
    'meta': NonFinancialAccountTreeView;
}
/**
 * 
 * @export
 * @interface NonFinancialAccountFlatTreeNodeView
 */
export interface NonFinancialAccountFlatTreeNodeView {
    /**
     * 
     * @type {AccountingSystemCodeView}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'accountingSystemCode'?: AccountingSystemCodeView;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'level': number;
    /**
     * 
     * @type {boolean}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'matched': boolean;
    /**
     * 
     * @type {MatchedAccountKeywordView}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'matchedKeyword': MatchedAccountKeywordView;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'nodeType': NonFinancialAccountFlatTreeNodeViewNodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'parentNodeType'?: NonFinancialAccountFlatTreeNodeViewParentNodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'roundingPrecision'?: NonFinancialAccountFlatTreeNodeViewRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountFlatTreeNodeView
     */
    'valueFormat'?: NonFinancialAccountFlatTreeNodeViewValueFormatEnum;
}

export const NonFinancialAccountFlatTreeNodeViewNodeTypeEnum = {
    AccountGroup: 'ACCOUNT_GROUP',
    Account: 'ACCOUNT'
} as const;

export type NonFinancialAccountFlatTreeNodeViewNodeTypeEnum = typeof NonFinancialAccountFlatTreeNodeViewNodeTypeEnum[keyof typeof NonFinancialAccountFlatTreeNodeViewNodeTypeEnum];
export const NonFinancialAccountFlatTreeNodeViewParentNodeTypeEnum = {
    AccountGroup: 'ACCOUNT_GROUP',
    Account: 'ACCOUNT'
} as const;

export type NonFinancialAccountFlatTreeNodeViewParentNodeTypeEnum = typeof NonFinancialAccountFlatTreeNodeViewParentNodeTypeEnum[keyof typeof NonFinancialAccountFlatTreeNodeViewParentNodeTypeEnum];
export const NonFinancialAccountFlatTreeNodeViewRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type NonFinancialAccountFlatTreeNodeViewRoundingPrecisionEnum = typeof NonFinancialAccountFlatTreeNodeViewRoundingPrecisionEnum[keyof typeof NonFinancialAccountFlatTreeNodeViewRoundingPrecisionEnum];
export const NonFinancialAccountFlatTreeNodeViewValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type NonFinancialAccountFlatTreeNodeViewValueFormatEnum = typeof NonFinancialAccountFlatTreeNodeViewValueFormatEnum[keyof typeof NonFinancialAccountFlatTreeNodeViewValueFormatEnum];

/**
 * 
 * @export
 * @interface NonFinancialAccountFlatTreeView
 */
export interface NonFinancialAccountFlatTreeView {
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountFlatTreeView
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NonFinancialAccountFlatTreeView
     */
    'internalNodeIds': Array<string>;
    /**
     * 
     * @type {Array<NonFinancialAccountFlatTreeNodeView>}
     * @memberof NonFinancialAccountFlatTreeView
     */
    'items': Array<NonFinancialAccountFlatTreeNodeView>;
}
/**
 * 
 * @export
 * @interface NonFinancialAccountFlatTreeWithNotIncludedForSelectionView
 */
export interface NonFinancialAccountFlatTreeWithNotIncludedForSelectionView {
    /**
     * 
     * @type {Array<NonFinancialAccountTreeNodeForSelectionView>}
     * @memberof NonFinancialAccountFlatTreeWithNotIncludedForSelectionView
     */
    'items': Array<NonFinancialAccountTreeNodeForSelectionView>;
    /**
     * 
     * @type {NonFinancialAccountTreeView}
     * @memberof NonFinancialAccountFlatTreeWithNotIncludedForSelectionView
     */
    'meta': NonFinancialAccountTreeView;
    /**
     * 
     * @type {Array<NonFinancialAccountTreeNodeContentForSelectionView>}
     * @memberof NonFinancialAccountFlatTreeWithNotIncludedForSelectionView
     */
    'notIncludes': Array<NonFinancialAccountTreeNodeContentForSelectionView>;
}
/**
 * 
 * @export
 * @interface NonFinancialAccountTreeListView
 */
export interface NonFinancialAccountTreeListView {
    /**
     * 
     * @type {Array<NonFinancialAccountTreeView>}
     * @memberof NonFinancialAccountTreeListView
     */
    'items': Array<NonFinancialAccountTreeView>;
}
/**
 * 
 * @export
 * @interface NonFinancialAccountTreeNodeBody
 */
export interface NonFinancialAccountTreeNodeBody {
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeBody
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeBody
     */
    'nodeType': NonFinancialAccountTreeNodeBodyNodeTypeEnum;
}

export const NonFinancialAccountTreeNodeBodyNodeTypeEnum = {
    AccountGroup: 'ACCOUNT_GROUP',
    Account: 'ACCOUNT'
} as const;

export type NonFinancialAccountTreeNodeBodyNodeTypeEnum = typeof NonFinancialAccountTreeNodeBodyNodeTypeEnum[keyof typeof NonFinancialAccountTreeNodeBodyNodeTypeEnum];

/**
 * 
 * @export
 * @interface NonFinancialAccountTreeNodeContentForSelectionView
 */
export interface NonFinancialAccountTreeNodeContentForSelectionView {
    /**
     * 
     * @type {AccountingSystemCodeView}
     * @memberof NonFinancialAccountTreeNodeContentForSelectionView
     */
    'accountingSystemCode'?: AccountingSystemCodeView;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeContentForSelectionView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeContentForSelectionView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeContentForSelectionView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeContentForSelectionView
     */
    'nodeType': NonFinancialAccountTreeNodeContentForSelectionViewNodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeContentForSelectionView
     */
    'roundingPrecision'?: NonFinancialAccountTreeNodeContentForSelectionViewRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeContentForSelectionView
     */
    'valueFormat'?: NonFinancialAccountTreeNodeContentForSelectionViewValueFormatEnum;
}

export const NonFinancialAccountTreeNodeContentForSelectionViewNodeTypeEnum = {
    AccountGroup: 'ACCOUNT_GROUP',
    Account: 'ACCOUNT'
} as const;

export type NonFinancialAccountTreeNodeContentForSelectionViewNodeTypeEnum = typeof NonFinancialAccountTreeNodeContentForSelectionViewNodeTypeEnum[keyof typeof NonFinancialAccountTreeNodeContentForSelectionViewNodeTypeEnum];
export const NonFinancialAccountTreeNodeContentForSelectionViewRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type NonFinancialAccountTreeNodeContentForSelectionViewRoundingPrecisionEnum = typeof NonFinancialAccountTreeNodeContentForSelectionViewRoundingPrecisionEnum[keyof typeof NonFinancialAccountTreeNodeContentForSelectionViewRoundingPrecisionEnum];
export const NonFinancialAccountTreeNodeContentForSelectionViewValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type NonFinancialAccountTreeNodeContentForSelectionViewValueFormatEnum = typeof NonFinancialAccountTreeNodeContentForSelectionViewValueFormatEnum[keyof typeof NonFinancialAccountTreeNodeContentForSelectionViewValueFormatEnum];

/**
 * 
 * @export
 * @interface NonFinancialAccountTreeNodeForSelectionView
 */
export interface NonFinancialAccountTreeNodeForSelectionView {
    /**
     * 
     * @type {NonFinancialAccountTreeNodeContentForSelectionView}
     * @memberof NonFinancialAccountTreeNodeForSelectionView
     */
    'data': NonFinancialAccountTreeNodeContentForSelectionView;
    /**
     * 
     * @type {boolean}
     * @memberof NonFinancialAccountTreeNodeForSelectionView
     */
    'isViewable': boolean;
    /**
     * 
     * @type {number}
     * @memberof NonFinancialAccountTreeNodeForSelectionView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeForSelectionView
     */
    'nodeId': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeForSelectionView
     */
    'parentNodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeNodeForSelectionView
     */
    'parentNodeType'?: NonFinancialAccountTreeNodeForSelectionViewParentNodeTypeEnum;
}

export const NonFinancialAccountTreeNodeForSelectionViewParentNodeTypeEnum = {
    AccountGroup: 'ACCOUNT_GROUP',
    Account: 'ACCOUNT'
} as const;

export type NonFinancialAccountTreeNodeForSelectionViewParentNodeTypeEnum = typeof NonFinancialAccountTreeNodeForSelectionViewParentNodeTypeEnum[keyof typeof NonFinancialAccountTreeNodeForSelectionViewParentNodeTypeEnum];

/**
 * 
 * @export
 * @interface NonFinancialAccountTreeView
 */
export interface NonFinancialAccountTreeView {
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeView
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof NonFinancialAccountTreeView
     */
    'isPrimary': boolean;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountTreeView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NonFinancialAccountView
 */
export interface NonFinancialAccountView {
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountView
     */
    'accountId': string;
    /**
     * 
     * @type {AccountingSystemCodeView}
     * @memberof NonFinancialAccountView
     */
    'accountingSystemCode'?: AccountingSystemCodeView;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountView
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof NonFinancialAccountView
     */
    'isSelectable': boolean;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountView
     */
    'roundingPrecision': NonFinancialAccountViewRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialAccountView
     */
    'valueFormat': NonFinancialAccountViewValueFormatEnum;
}

export const NonFinancialAccountViewRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type NonFinancialAccountViewRoundingPrecisionEnum = typeof NonFinancialAccountViewRoundingPrecisionEnum[keyof typeof NonFinancialAccountViewRoundingPrecisionEnum];
export const NonFinancialAccountViewValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type NonFinancialAccountViewValueFormatEnum = typeof NonFinancialAccountViewValueFormatEnum[keyof typeof NonFinancialAccountViewValueFormatEnum];

/**
 * 
 * @export
 * @interface NonFinancialItemsUploadEventsDto
 */
export interface NonFinancialItemsUploadEventsDto {
    /**
     * 
     * @type {string}
     * @memberof NonFinancialItemsUploadEventsDto
     */
    'nonFinancialItemUploadEventId': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialItemsUploadEventsDto
     */
    'targetEndMonth': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialItemsUploadEventsDto
     */
    'targetStartMonth': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialItemsUploadEventsDto
     */
    'uploadUserName': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialItemsUploadEventsDto
     */
    'uploadedAt': string;
    /**
     * 
     * @type {string}
     * @memberof NonFinancialItemsUploadEventsDto
     */
    'uploadedFileName': string;
    /**
     * 
     * @type {number}
     * @memberof NonFinancialItemsUploadEventsDto
     */
    'uploadedItemCount': number;
}
/**
 * 
 * @export
 * @interface NotIncludedAccountCountView
 */
export interface NotIncludedAccountCountView {
    /**
     * 
     * @type {number}
     * @memberof NotIncludedAccountCountView
     */
    'response': number;
}
/**
 * 
 * @export
 * @interface NotIncludedAccountView
 */
export interface NotIncludedAccountView {
    /**
     * 
     * @type {AccountView}
     * @memberof NotIncludedAccountView
     */
    'accountView': AccountView;
    /**
     * 
     * @type {boolean}
     * @memberof NotIncludedAccountView
     */
    'isArchived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotIncludedAccountView
     */
    'isVisible': boolean;
}
/**
 * 
 * @export
 * @interface NotIncludedAccountsView
 */
export interface NotIncludedAccountsView {
    /**
     * 
     * @type {Array<NotIncludedAccountView>}
     * @memberof NotIncludedAccountsView
     */
    'listAccountView': Array<NotIncludedAccountView>;
    /**
     * 
     * @type {PagingDto}
     * @memberof NotIncludedAccountsView
     */
    'paging'?: PagingDto;
}
/**
 * 
 * @export
 * @interface NotIncludedDimensionTagView
 */
export interface NotIncludedDimensionTagView {
    /**
     * 
     * @type {string}
     * @memberof NotIncludedDimensionTagView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof NotIncludedDimensionTagView
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotIncludedDimensionTagView
     */
    'exCodes': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NotIncludedDimensionTagView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NotIncludedDimensionTagView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NotIncludedDimensionTagsView
 */
export interface NotIncludedDimensionTagsView {
    /**
     * 
     * @type {Array<NotIncludedDimensionTagView>}
     * @memberof NotIncludedDimensionTagsView
     */
    'items': Array<NotIncludedDimensionTagView>;
}
/**
 * 
 * @export
 * @interface NotIncludedNonFinancialAccountCountView
 */
export interface NotIncludedNonFinancialAccountCountView {
    /**
     * 
     * @type {number}
     * @memberof NotIncludedNonFinancialAccountCountView
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface NotIncludedNonFinancialAccountView
 */
export interface NotIncludedNonFinancialAccountView {
    /**
     * 
     * @type {AccountingSystemCodeView}
     * @memberof NotIncludedNonFinancialAccountView
     */
    'accountingSystemCode'?: AccountingSystemCodeView;
    /**
     * 
     * @type {string}
     * @memberof NotIncludedNonFinancialAccountView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof NotIncludedNonFinancialAccountView
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof NotIncludedNonFinancialAccountView
     */
    'isArchived': boolean;
    /**
     * 
     * @type {string}
     * @memberof NotIncludedNonFinancialAccountView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NotIncludedNonFinancialAccountView
     */
    'nodeType': NotIncludedNonFinancialAccountViewNodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotIncludedNonFinancialAccountView
     */
    'roundingPrecision': NotIncludedNonFinancialAccountViewRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof NotIncludedNonFinancialAccountView
     */
    'valueFormat': NotIncludedNonFinancialAccountViewValueFormatEnum;
}

export const NotIncludedNonFinancialAccountViewNodeTypeEnum = {
    AccountGroup: 'ACCOUNT_GROUP',
    Account: 'ACCOUNT'
} as const;

export type NotIncludedNonFinancialAccountViewNodeTypeEnum = typeof NotIncludedNonFinancialAccountViewNodeTypeEnum[keyof typeof NotIncludedNonFinancialAccountViewNodeTypeEnum];
export const NotIncludedNonFinancialAccountViewRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type NotIncludedNonFinancialAccountViewRoundingPrecisionEnum = typeof NotIncludedNonFinancialAccountViewRoundingPrecisionEnum[keyof typeof NotIncludedNonFinancialAccountViewRoundingPrecisionEnum];
export const NotIncludedNonFinancialAccountViewValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type NotIncludedNonFinancialAccountViewValueFormatEnum = typeof NotIncludedNonFinancialAccountViewValueFormatEnum[keyof typeof NotIncludedNonFinancialAccountViewValueFormatEnum];

/**
 * 
 * @export
 * @interface NotIncludedNonFinancialAccountsView
 */
export interface NotIncludedNonFinancialAccountsView {
    /**
     * 
     * @type {Array<NotIncludedNonFinancialAccountView>}
     * @memberof NotIncludedNonFinancialAccountsView
     */
    'items': Array<NotIncludedNonFinancialAccountView>;
}
/**
 * 
 * @export
 * @interface NumberLimitView
 */
export interface NumberLimitView {
    /**
     * 
     * @type {number}
     * @memberof NumberLimitView
     */
    'maxNumber': number;
    /**
     * 
     * @type {string}
     * @memberof NumberLimitView
     */
    'numberLimitDataName': NumberLimitViewNumberLimitDataNameEnum;
}

export const NumberLimitViewNumberLimitDataNameEnum = {
    Dimension: 'DIMENSION',
    DimensionTree: 'DIMENSION_TREE',
    AccountTree: 'ACCOUNT_TREE',
    NonFinancialAccountTree: 'NON_FINANCIAL_ACCOUNT_TREE',
    OrgTree: 'ORG_TREE',
    Report: 'REPORT',
    PlanUpdateRequestFormat: 'PLAN_UPDATE_REQUEST_FORMAT',
    ActualImportMapping: 'ACTUAL_IMPORT_MAPPING'
} as const;

export type NumberLimitViewNumberLimitDataNameEnum = typeof NumberLimitViewNumberLimitDataNameEnum[keyof typeof NumberLimitViewNumberLimitDataNameEnum];

/**
 * 
 * @export
 * @interface OkJson
 */
export interface OkJson {
    /**
     * 
     * @type {string}
     * @memberof OkJson
     */
    'response': string;
}
/**
 * 
 * @export
 * @interface OpenWorkforcePlanUpdateRequestTargetDepartments
 */
export interface OpenWorkforcePlanUpdateRequestTargetDepartments {
    /**
     * 
     * @type {Array<TargetDepartment>}
     * @memberof OpenWorkforcePlanUpdateRequestTargetDepartments
     */
    'departments': Array<TargetDepartment>;
}
/**
 * 
 * @export
 * @interface OpenWorkforcePlanUpdateRequestTargetPlanningProjects
 */
export interface OpenWorkforcePlanUpdateRequestTargetPlanningProjects {
    /**
     * 
     * @type {Array<TargetPlanningProject>}
     * @memberof OpenWorkforcePlanUpdateRequestTargetPlanningProjects
     */
    'planningProjects': Array<TargetPlanningProject>;
}
/**
 * 
 * @export
 * @interface OrgNodeLevelView
 */
export interface OrgNodeLevelView {
    /**
     * 
     * @type {number}
     * @memberof OrgNodeLevelView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof OrgNodeLevelView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrgTreeForSelectionView
 */
export interface OrgTreeForSelectionView {
    /**
     * 
     * @type {OrgTreeMetaView}
     * @memberof OrgTreeForSelectionView
     */
    'meta': OrgTreeMetaView;
    /**
     * 
     * @type {Array<OrgTreeNodeForSelectionView>}
     * @memberof OrgTreeForSelectionView
     */
    'nodes': Array<OrgTreeNodeForSelectionView>;
}
/**
 * 
 * @export
 * @interface OrgTreeMetaView
 */
export interface OrgTreeMetaView {
    /**
     * 
     * @type {string}
     * @memberof OrgTreeMetaView
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrgTreeMetaView
     */
    'isPrimary': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrgTreeMetaView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrgTreeNodeForSelectionView
 */
export interface OrgTreeNodeForSelectionView {
    /**
     * 
     * @type {DepartmentView}
     * @memberof OrgTreeNodeForSelectionView
     */
    'data': DepartmentView;
    /**
     * 
     * @type {string}
     * @memberof OrgTreeNodeForSelectionView
     */
    'departmentId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrgTreeNodeForSelectionView
     */
    'isOwnValue'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrgTreeNodeForSelectionView
     */
    'isSelectable': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrgTreeNodeForSelectionView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof OrgTreeNodeForSelectionView
     */
    'parentDepartmentId'?: string;
}
/**
 * 
 * @export
 * @interface OrgTreeNodeV2View
 */
export interface OrgTreeNodeV2View {
    /**
     * 
     * @type {Array<OrgTreeNodeV2View>}
     * @memberof OrgTreeNodeV2View
     */
    'children': Array<OrgTreeNodeV2View>;
    /**
     * 
     * @type {DepartmentView}
     * @memberof OrgTreeNodeV2View
     */
    'data': DepartmentView;
    /**
     * 
     * @type {string}
     * @memberof OrgTreeNodeV2View
     */
    'departmentId': string;
    /**
     * 
     * @type {number}
     * @memberof OrgTreeNodeV2View
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof OrgTreeNodeV2View
     */
    'parentDepartmentId'?: string;
}
/**
 * 
 * @export
 * @interface OrgTreeNodeView
 */
export interface OrgTreeNodeView {
    /**
     * 
     * @type {DepartmentView}
     * @memberof OrgTreeNodeView
     */
    'data': DepartmentView;
    /**
     * 
     * @type {string}
     * @memberof OrgTreeNodeView
     */
    'departmentId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrgTreeNodeView
     */
    'isOwnValue'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrgTreeNodeView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof OrgTreeNodeView
     */
    'parentDepartmentId'?: string;
}
/**
 * 
 * @export
 * @interface OrgTreeV2View
 */
export interface OrgTreeV2View {
    /**
     * 
     * @type {OrgTreeMetaView}
     * @memberof OrgTreeV2View
     */
    'meta': OrgTreeMetaView;
    /**
     * 
     * @type {OrgTreeNodeV2View}
     * @memberof OrgTreeV2View
     */
    'nodes'?: OrgTreeNodeV2View;
}
/**
 * 
 * @export
 * @interface OrgTreeView
 */
export interface OrgTreeView {
    /**
     * 
     * @type {OrgTreeMetaView}
     * @memberof OrgTreeView
     */
    'meta': OrgTreeMetaView;
    /**
     * 
     * @type {Array<OrgTreeNodeView>}
     * @memberof OrgTreeView
     */
    'nodes': Array<OrgTreeNodeView>;
}
/**
 * 
 * @export
 * @interface OrganizationTreeListView
 */
export interface OrganizationTreeListView {
    /**
     * 
     * @type {Array<OrganizationTreeView>}
     * @memberof OrganizationTreeListView
     */
    'items': Array<OrganizationTreeView>;
}
/**
 * 
 * @export
 * @interface OrganizationTreeView
 */
export interface OrganizationTreeView {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationTreeView
     */
    'forAuthority': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationTreeView
     */
    'isPrimary': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTreeView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTreeView
     */
    'organizationTreeId': string;
}
/**
 * 
 * @export
 * @interface PLReportConditionSchema
 */
export interface PLReportConditionSchema {
    /**
     * 
     * @type {Array<CellGroupOrder>}
     * @memberof PLReportConditionSchema
     */
    'cellGroupOrders': Array<CellGroupOrder>;
    /**
     * 
     * @type {ColumnAggregationDimension}
     * @memberof PLReportConditionSchema
     */
    'columnAggregationDimension': ColumnAggregationDimension;
    /**
     * 
     * @type {Array<ComparisonCondition>}
     * @memberof PLReportConditionSchema
     */
    'comparisonConditions': Array<ComparisonCondition>;
    /**
     * 
     * @type {Array<DataCondition>}
     * @memberof PLReportConditionSchema
     */
    'dataConditions': Array<DataCondition>;
    /**
     * 
     * @type {boolean}
     * @memberof PLReportConditionSchema
     */
    'isShowEmptyRows': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PLReportConditionSchema
     */
    'isTreeView': boolean;
    /**
     * 
     * @type {Array<RowAggregationDimension>}
     * @memberof PLReportConditionSchema
     */
    'rowAggregationDimensions': Array<RowAggregationDimension>;
    /**
     * 
     * @type {boolean}
     * @memberof PLReportConditionSchema
     */
    'showNoneDimension': boolean;
    /**
     * 
     * @type {string}
     * @memberof PLReportConditionSchema
     */
    'showNumbersIn': PLReportConditionSchemaShowNumbersInEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PLReportConditionSchema
     */
    'showSumRow': boolean;
}

export const PLReportConditionSchemaShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type PLReportConditionSchemaShowNumbersInEnum = typeof PLReportConditionSchemaShowNumbersInEnum[keyof typeof PLReportConditionSchemaShowNumbersInEnum];

/**
 * 
 * @export
 * @interface PageDtoActualImportEventDto
 */
export interface PageDtoActualImportEventDto {
    /**
     * 
     * @type {Array<ActualImportEventDto>}
     * @memberof PageDtoActualImportEventDto
     */
    'items': Array<ActualImportEventDto>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoActualImportEventDto
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PageDtoAllocationRuleSummaryDto
 */
export interface PageDtoAllocationRuleSummaryDto {
    /**
     * 
     * @type {Array<AllocationRuleSummaryDto>}
     * @memberof PageDtoAllocationRuleSummaryDto
     */
    'items': Array<AllocationRuleSummaryDto>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoAllocationRuleSummaryDto
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PageDtoAllocationStandardSummaryDto
 */
export interface PageDtoAllocationStandardSummaryDto {
    /**
     * 
     * @type {Array<AllocationStandardSummaryDto>}
     * @memberof PageDtoAllocationStandardSummaryDto
     */
    'items': Array<AllocationStandardSummaryDto>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoAllocationStandardSummaryDto
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PageDtoDepartmentViewWithIsAssignedAndIsArchived
 */
export interface PageDtoDepartmentViewWithIsAssignedAndIsArchived {
    /**
     * 
     * @type {Array<DepartmentViewWithIsAssignedAndIsArchived>}
     * @memberof PageDtoDepartmentViewWithIsAssignedAndIsArchived
     */
    'items': Array<DepartmentViewWithIsAssignedAndIsArchived>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoDepartmentViewWithIsAssignedAndIsArchived
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PageDtoFinancialSimulationListItemView
 */
export interface PageDtoFinancialSimulationListItemView {
    /**
     * 
     * @type {Array<FinancialSimulationListItemView>}
     * @memberof PageDtoFinancialSimulationListItemView
     */
    'items': Array<FinancialSimulationListItemView>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoFinancialSimulationListItemView
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PageDtoNonFinancialItemsUploadEventsDto
 */
export interface PageDtoNonFinancialItemsUploadEventsDto {
    /**
     * 
     * @type {Array<NonFinancialItemsUploadEventsDto>}
     * @memberof PageDtoNonFinancialItemsUploadEventsDto
     */
    'items': Array<NonFinancialItemsUploadEventsDto>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoNonFinancialItemsUploadEventsDto
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PageDtoPersonListItem
 */
export interface PageDtoPersonListItem {
    /**
     * 
     * @type {Array<PersonListItem>}
     * @memberof PageDtoPersonListItem
     */
    'items': Array<PersonListItem>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoPersonListItem
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PageDtoUnitAmountListItem
 */
export interface PageDtoUnitAmountListItem {
    /**
     * 
     * @type {Array<UnitAmountListItem>}
     * @memberof PageDtoUnitAmountListItem
     */
    'items': Array<UnitAmountListItem>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoUnitAmountListItem
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PageDtoUserWithDepartmentNamesDto
 */
export interface PageDtoUserWithDepartmentNamesDto {
    /**
     * 
     * @type {Array<UserWithDepartmentNamesDto>}
     * @memberof PageDtoUserWithDepartmentNamesDto
     */
    'items': Array<UserWithDepartmentNamesDto>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoUserWithDepartmentNamesDto
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PageDtoWorkforceSlotListItemView
 */
export interface PageDtoWorkforceSlotListItemView {
    /**
     * 
     * @type {Array<WorkforceSlotListItemView>}
     * @memberof PageDtoWorkforceSlotListItemView
     */
    'items': Array<WorkforceSlotListItemView>;
    /**
     * 
     * @type {PagingDto}
     * @memberof PageDtoWorkforceSlotListItemView
     */
    'paging': PagingDto;
}
/**
 * 
 * @export
 * @interface PagingDto
 */
export interface PagingDto {
    /**
     * 
     * @type {number}
     * @memberof PagingDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof PagingDto
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof PagingDto
     */
    'totalCount': number;
}
/**
 * 
 * @export
 * @interface PatchBSAccountRequestBody
 */
export interface PatchBSAccountRequestBody {
    /**
     * 
     * @type {string}
     * @memberof PatchBSAccountRequestBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PatchBSAccountRequestBody
     */
    'externalSystemAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchBSAccountRequestBody
     */
    'externalSystemSubAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchBSAccountRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PatchBSAccountRequestBody
     */
    'viewControlType': PatchBSAccountRequestBodyViewControlTypeEnum;
}

export const PatchBSAccountRequestBodyViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type PatchBSAccountRequestBodyViewControlTypeEnum = typeof PatchBSAccountRequestBodyViewControlTypeEnum[keyof typeof PatchBSAccountRequestBodyViewControlTypeEnum];

/**
 * 
 * @export
 * @interface Period
 */
export interface Period {
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'start': string;
}
/**
 * 
 * @export
 * @interface PeriodComparisonCellTypeV3View
 */
export interface PeriodComparisonCellTypeV3View {
    /**
     * 
     * @type {string}
     * @memberof PeriodComparisonCellTypeV3View
     */
    'comparisonType': PeriodComparisonCellTypeV3ViewComparisonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PeriodComparisonCellTypeV3View
     */
    'periodKey': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodComparisonCellTypeV3View
     */
    'periodLabel': string;
}

export const PeriodComparisonCellTypeV3ViewComparisonTypeEnum = {
    ActualBudget: 'ACTUAL_BUDGET',
    ActualForecast: 'ACTUAL_FORECAST',
    ActualBudgetMixed: 'ACTUAL_BUDGET_MIXED',
    ActualForecastMixed: 'ACTUAL_FORECAST_MIXED',
    ActualNull: 'ACTUAL_NULL',
    NullNull: 'NULL_NULL',
    NullBudget: 'NULL_BUDGET',
    NullForecast: 'NULL_FORECAST'
} as const;

export type PeriodComparisonCellTypeV3ViewComparisonTypeEnum = typeof PeriodComparisonCellTypeV3ViewComparisonTypeEnum[keyof typeof PeriodComparisonCellTypeV3ViewComparisonTypeEnum];

/**
 * 
 * @export
 * @interface PersonListItem
 */
export interface PersonListItem {
    /**
     * 
     * @type {string}
     * @memberof PersonListItem
     */
    'departmentName'?: string;
    /**
     * 
     * @type {Array<PersonListItemDimensionTagView>}
     * @memberof PersonListItem
     */
    'dimensionTagViews': Array<PersonListItemDimensionTagView>;
    /**
     * 
     * @type {string}
     * @memberof PersonListItem
     */
    'employmentStatus': PersonListItemEmploymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonListItem
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonListItem
     */
    'personCode': string;
    /**
     * 
     * @type {string}
     * @memberof PersonListItem
     */
    'personName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonListItem
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonListItem
     */
    'workforceId': string;
}

export const PersonListItemEmploymentStatusEnum = {
    Active: 'ACTIVE',
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    Resigned: 'RESIGNED',
    Unenrolled: 'UNENROLLED'
} as const;

export type PersonListItemEmploymentStatusEnum = typeof PersonListItemEmploymentStatusEnum[keyof typeof PersonListItemEmploymentStatusEnum];

/**
 * 
 * @export
 * @interface PersonListItemDimensionTagView
 */
export interface PersonListItemDimensionTagView {
    /**
     * 
     * @type {string}
     * @memberof PersonListItemDimensionTagView
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonListItemDimensionTagView
     */
    'dimensionTagName': string;
}
/**
 * 
 * @export
 * @interface PersonView
 */
export interface PersonView {
    /**
     * 
     * @type {string}
     * @memberof PersonView
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonView
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonView
     */
    'personCode': string;
    /**
     * 
     * @type {string}
     * @memberof PersonView
     */
    'workforceId': string;
}
/**
 * 
 * @export
 * @interface PlanActualCellView
 */
export interface PlanActualCellView {
    /**
     * 
     * @type {string}
     * @memberof PlanActualCellView
     */
    'diffRatio'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanActualCellView
     */
    'diffScaledAmount'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanActualCellView
     */
    'periodNumber': number;
    /**
     * 
     * @type {number}
     * @memberof PlanActualCellView
     */
    'planAOriginalValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlanActualCellView
     */
    'planAScaledValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanActualCellView
     */
    'planBOriginalValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlanActualCellView
     */
    'planBScaledValue'?: string;
}
/**
 * 
 * @export
 * @interface PlanDetailView
 */
export interface PlanDetailView {
    /**
     * 
     * @type {string}
     * @memberof PlanDetailView
     */
    'masterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanDetailView
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanDetailView
     */
    'planSelected': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanDetailView
     */
    'planType'?: PlanDetailViewPlanTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanDetailView
     */
    'planningProjectId'?: string;
}

export const PlanDetailViewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type PlanDetailViewPlanTypeEnum = typeof PlanDetailViewPlanTypeEnum[keyof typeof PlanDetailViewPlanTypeEnum];

/**
 * 
 * @export
 * @interface PlanId
 */
export interface PlanId {
    /**
     * 
     * @type {string}
     * @memberof PlanId
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlanId
     */
    'type': PlanIdTypeEnum;
}

export const PlanIdTypeEnum = {
    PlanMaster: 'PLAN_MASTER',
    PlanningProject: 'PLANNING_PROJECT'
} as const;

export type PlanIdTypeEnum = typeof PlanIdTypeEnum[keyof typeof PlanIdTypeEnum];

/**
 * 
 * @export
 * @interface PlanMasterMetaView
 */
export interface PlanMasterMetaView {
    /**
     * 
     * @type {string}
     * @memberof PlanMasterMetaView
     */
    'createdAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanMasterMetaView
     */
    'isArchived': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanMasterMetaView
     */
    'originId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanMasterMetaView
     */
    'originName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanMasterMetaView
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanMasterMetaView
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanMasterMetaView
     */
    'planType': PlanMasterMetaViewPlanTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanMasterMetaView
     */
    'updatedAt': string;
}

export const PlanMasterMetaViewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type PlanMasterMetaViewPlanTypeEnum = typeof PlanMasterMetaViewPlanTypeEnum[keyof typeof PlanMasterMetaViewPlanTypeEnum];

/**
 * 
 * @export
 * @interface PlanMasterOrPlanningProjectId
 */
export interface PlanMasterOrPlanningProjectId {
    /**
     * 
     * @type {string}
     * @memberof PlanMasterOrPlanningProjectId
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanMasterOrPlanningProjectId
     */
    'isPlanMaster': boolean;
}
/**
 * 
 * @export
 * @interface PlanMasterPlanningProjectsView
 */
export interface PlanMasterPlanningProjectsView {
    /**
     * 
     * @type {PlanMasterMetaView}
     * @memberof PlanMasterPlanningProjectsView
     */
    'meta': PlanMasterMetaView;
    /**
     * 
     * @type {Array<PlanningProjectMetaView>}
     * @memberof PlanMasterPlanningProjectsView
     */
    'planningProjects': Array<PlanningProjectMetaView>;
}
/**
 * 
 * @export
 * @interface PlanMastersPlanningProjectsView
 */
export interface PlanMastersPlanningProjectsView {
    /**
     * 
     * @type {Array<PlanMasterPlanningProjectsView>}
     * @memberof PlanMastersPlanningProjectsView
     */
    'planMasters': Array<PlanMasterPlanningProjectsView>;
}
/**
 * 
 * @export
 * @interface PlanMastersView
 */
export interface PlanMastersView {
    /**
     * 
     * @type {number}
     * @memberof PlanMastersView
     */
    'count': number;
    /**
     * 
     * @type {Array<PlanMasterMetaView>}
     * @memberof PlanMastersView
     */
    'items': Array<PlanMasterMetaView>;
    /**
     * 
     * @type {number}
     * @memberof PlanMastersView
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanMastersView
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface PlanPeriodPairView
 */
export interface PlanPeriodPairView {
    /**
     * 
     * @type {number}
     * @memberof PlanPeriodPairView
     */
    'periodNumber': number;
    /**
     * 
     * @type {RowInfo}
     * @memberof PlanPeriodPairView
     */
    'planA': RowInfo;
    /**
     * 
     * @type {RowInfo}
     * @memberof PlanPeriodPairView
     */
    'planB': RowInfo;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestAggregateTreeNodeMetaView
 */
export interface PlanUpdateRequestAggregateTreeNodeMetaView {
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'actualEndWhenPlanningProject'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'departmentNames': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'periodEnd': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'periodStart': string;
    /**
     * 
     * @type {Array<PlanUpdateRequestPeriodView>}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'periods': Array<PlanUpdateRequestPeriodView>;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'planType': PlanUpdateRequestAggregateTreeNodeMetaViewPlanTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'planUpdateRequestIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'planningProjectId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'planningProjectName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestAggregateTreeNodeMetaView
     */
    'showNumbersIn': PlanUpdateRequestAggregateTreeNodeMetaViewShowNumbersInEnum;
}

export const PlanUpdateRequestAggregateTreeNodeMetaViewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type PlanUpdateRequestAggregateTreeNodeMetaViewPlanTypeEnum = typeof PlanUpdateRequestAggregateTreeNodeMetaViewPlanTypeEnum[keyof typeof PlanUpdateRequestAggregateTreeNodeMetaViewPlanTypeEnum];
export const PlanUpdateRequestAggregateTreeNodeMetaViewShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type PlanUpdateRequestAggregateTreeNodeMetaViewShowNumbersInEnum = typeof PlanUpdateRequestAggregateTreeNodeMetaViewShowNumbersInEnum[keyof typeof PlanUpdateRequestAggregateTreeNodeMetaViewShowNumbersInEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestAggregateView
 */
export interface PlanUpdateRequestAggregateView {
    /**
     * 
     * @type {PlanUpdateRequestAggregateTreeNodeMetaView}
     * @memberof PlanUpdateRequestAggregateView
     */
    'meta': PlanUpdateRequestAggregateTreeNodeMetaView;
    /**
     * 
     * @type {PlanUpdateRequestTreeFlatV2View}
     * @memberof PlanUpdateRequestAggregateView
     */
    'tree': PlanUpdateRequestTreeFlatV2View;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestCellScaledView
 */
export interface PlanUpdateRequestCellScaledView {
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestCellScaledView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestCellScaledView
     */
    'periodKey': string;
    /**
     * 
     * @type {SumDiffScaledView}
     * @memberof PlanUpdateRequestCellScaledView
     */
    'sum': SumDiffScaledView;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestFileListView
 */
export interface PlanUpdateRequestFileListView {
    /**
     * 
     * @type {Array<PlanUpdateRequestFileView>}
     * @memberof PlanUpdateRequestFileListView
     */
    'items': Array<PlanUpdateRequestFileView>;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestFileListView
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestFileSubmittedDepartmentView
 */
export interface PlanUpdateRequestFileSubmittedDepartmentView {
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileSubmittedDepartmentView
     */
    'departmentCode': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileSubmittedDepartmentView
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileSubmittedDepartmentView
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileSubmittedDepartmentView
     */
    'planUpdateRequestId': string;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestFileUnreflectableActualCheckResultView
 */
export interface PlanUpdateRequestFileUnreflectableActualCheckResultView {
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestFileUnreflectableActualCheckResultView
     */
    'isExistUnreflectableActual': boolean;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestFileView
 */
export interface PlanUpdateRequestFileView {
    /**
     * 
     * @type {IDPlanUpdateRequestFile}
     * @memberof PlanUpdateRequestFileView
     */
    'id': IDPlanUpdateRequestFile;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileView
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileView
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileView
     */
    'planningProjectId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileView
     */
    'planningProjectName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileView
     */
    'submittedAt': string;
    /**
     * 
     * @type {Array<PlanUpdateRequestFileSubmittedDepartmentView>}
     * @memberof PlanUpdateRequestFileView
     */
    'submittedDepartments': Array<PlanUpdateRequestFileSubmittedDepartmentView>;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFileView
     */
    'submittedUser': string;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestFormatDownloadableDepartmentView
 */
export interface PlanUpdateRequestFormatDownloadableDepartmentView {
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFormatDownloadableDepartmentView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFormatDownloadableDepartmentView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFormatDownloadableDepartmentView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestFormatListView
 */
export interface PlanUpdateRequestFormatListView {
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestFormatListView
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<PlanUpdateRequestFormatView>}
     * @memberof PlanUpdateRequestFormatListView
     */
    'list': Array<PlanUpdateRequestFormatView>;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestFormatListView
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestFormatListView
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestFormatView
 */
export interface PlanUpdateRequestFormatView {
    /**
     * 
     * @type {Array<PlanUpdateRequestFormatDownloadableDepartmentView>}
     * @memberof PlanUpdateRequestFormatView
     */
    'downloadableDepartments': Array<PlanUpdateRequestFormatDownloadableDepartmentView>;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFormatView
     */
    'fileName': string;
    /**
     * 
     * @type {IDPlanUpdateRequestFormat}
     * @memberof PlanUpdateRequestFormatView
     */
    'id': IDPlanUpdateRequestFormat;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFormatView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestFormatView
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestMapping
 */
export interface PlanUpdateRequestMapping {
    /**
     * 
     * @type {PlanUpdateRequestMappingAccountKey}
     * @memberof PlanUpdateRequestMapping
     */
    'accountKey': PlanUpdateRequestMappingAccountKey;
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestMapping
     */
    'canUseForDownload': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestMapping
     */
    'canUseForUpload': boolean;
    /**
     * 
     * @type {PlanUpdateRequestMappingDepartmentKey}
     * @memberof PlanUpdateRequestMapping
     */
    'departmentKey': PlanUpdateRequestMappingDepartmentKey;
    /**
     * 
     * @type {PlanUpdateRequestMappingDescriptionKey}
     * @memberof PlanUpdateRequestMapping
     */
    'descriptionKey'?: PlanUpdateRequestMappingDescriptionKey;
    /**
     * 
     * @type {Array<PlanUpdateRequestMappingDimensionKey>}
     * @memberof PlanUpdateRequestMapping
     */
    'dimensionKeys': Array<PlanUpdateRequestMappingDimensionKey>;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMapping
     */
    'endColumnIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMapping
     */
    'endRowIndex'?: number;
    /**
     * 
     * @type {Array<PlanUpdateRequestMappingFilter>}
     * @memberof PlanUpdateRequestMapping
     */
    'filters': Array<PlanUpdateRequestMappingFilter>;
    /**
     * 
     * @type {IDPlanUpdateRequestMapping}
     * @memberof PlanUpdateRequestMapping
     */
    'id': IDPlanUpdateRequestMapping;
    /**
     * 
     * @type {Name}
     * @memberof PlanUpdateRequestMapping
     */
    'name': Name;
    /**
     * 
     * @type {IDDimension}
     * @memberof PlanUpdateRequestMapping
     */
    'planUpdateRequestMappingUniqueKey'?: IDDimension;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMapping
     */
    'startColumnIndex': number;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMapping
     */
    'startRowIndex': number;
    /**
     * 
     * @type {PlanUpdateRequestMappingYearMonthKey}
     * @memberof PlanUpdateRequestMapping
     */
    'yearMonthKey': PlanUpdateRequestMappingYearMonthKey;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingAccountKey
 */
export interface PlanUpdateRequestMappingAccountKey {
    /**
     * 
     * @type {IDPlanUpdateRequestMappingAccountKey}
     * @memberof PlanUpdateRequestMappingAccountKey
     */
    'id': IDPlanUpdateRequestMappingAccountKey;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof PlanUpdateRequestMappingAccountKey
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMappingAccountKey
     */
    'valueType': PlanUpdateRequestMappingAccountKeyValueTypeEnum;
}

export const PlanUpdateRequestMappingAccountKeyValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type PlanUpdateRequestMappingAccountKeyValueTypeEnum = typeof PlanUpdateRequestMappingAccountKeyValueTypeEnum[keyof typeof PlanUpdateRequestMappingAccountKeyValueTypeEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingAddress
 */
export interface PlanUpdateRequestMappingAddress {
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMappingAddress
     */
    'columnIndex': number;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMappingAddress
     */
    'rowIndex': number;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMappingAddress
     */
    'type': PlanUpdateRequestMappingAddressTypeEnum;
}

export const PlanUpdateRequestMappingAddressTypeEnum = {
    Cell: 'CELL',
    Row: 'ROW',
    Column: 'COLUMN'
} as const;

export type PlanUpdateRequestMappingAddressTypeEnum = typeof PlanUpdateRequestMappingAddressTypeEnum[keyof typeof PlanUpdateRequestMappingAddressTypeEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingDepartmentKey
 */
export interface PlanUpdateRequestMappingDepartmentKey {
    /**
     * 
     * @type {IDPlanUpdateRequestMappingDepartmentKey}
     * @memberof PlanUpdateRequestMappingDepartmentKey
     */
    'id': IDPlanUpdateRequestMappingDepartmentKey;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof PlanUpdateRequestMappingDepartmentKey
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMappingDepartmentKey
     */
    'valueType': PlanUpdateRequestMappingDepartmentKeyValueTypeEnum;
}

export const PlanUpdateRequestMappingDepartmentKeyValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type PlanUpdateRequestMappingDepartmentKeyValueTypeEnum = typeof PlanUpdateRequestMappingDepartmentKeyValueTypeEnum[keyof typeof PlanUpdateRequestMappingDepartmentKeyValueTypeEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingDescriptionKey
 */
export interface PlanUpdateRequestMappingDescriptionKey {
    /**
     * 
     * @type {IDPlanUpdateRequestMappingDescriptionKey}
     * @memberof PlanUpdateRequestMappingDescriptionKey
     */
    'id': IDPlanUpdateRequestMappingDescriptionKey;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof PlanUpdateRequestMappingDescriptionKey
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingDimensionKey
 */
export interface PlanUpdateRequestMappingDimensionKey {
    /**
     * 
     * @type {IDDimension}
     * @memberof PlanUpdateRequestMappingDimensionKey
     */
    'dimensionId': IDDimension;
    /**
     * 
     * @type {IDPlanUpdateRequestMappingDimensionKey}
     * @memberof PlanUpdateRequestMappingDimensionKey
     */
    'id': IDPlanUpdateRequestMappingDimensionKey;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof PlanUpdateRequestMappingDimensionKey
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMappingDimensionKey
     */
    'valueType': PlanUpdateRequestMappingDimensionKeyValueTypeEnum;
}

export const PlanUpdateRequestMappingDimensionKeyValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type PlanUpdateRequestMappingDimensionKeyValueTypeEnum = typeof PlanUpdateRequestMappingDimensionKeyValueTypeEnum[keyof typeof PlanUpdateRequestMappingDimensionKeyValueTypeEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingDimensionKeyView
 */
export interface PlanUpdateRequestMappingDimensionKeyView {
    /**
     * 
     * @type {IDDimension}
     * @memberof PlanUpdateRequestMappingDimensionKeyView
     */
    'dimensionId': IDDimension;
    /**
     * 
     * @type {DimensionName}
     * @memberof PlanUpdateRequestMappingDimensionKeyView
     */
    'dimensionName': DimensionName;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof PlanUpdateRequestMappingDimensionKeyView
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMappingDimensionKeyView
     */
    'valueType': PlanUpdateRequestMappingDimensionKeyViewValueTypeEnum;
}

export const PlanUpdateRequestMappingDimensionKeyViewValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type PlanUpdateRequestMappingDimensionKeyViewValueTypeEnum = typeof PlanUpdateRequestMappingDimensionKeyViewValueTypeEnum[keyof typeof PlanUpdateRequestMappingDimensionKeyViewValueTypeEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingFilter
 */
export interface PlanUpdateRequestMappingFilter {
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMappingFilter
     */
    'conditionType': PlanUpdateRequestMappingFilterConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMappingFilter
     */
    'conditionValue'?: string;
    /**
     * 
     * @type {IDPlanUpdateRequestMappingFilter}
     * @memberof PlanUpdateRequestMappingFilter
     */
    'id': IDPlanUpdateRequestMappingFilter;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof PlanUpdateRequestMappingFilter
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
}

export const PlanUpdateRequestMappingFilterConditionTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Empty: 'EMPTY',
    NotEmpty: 'NOT_EMPTY'
} as const;

export type PlanUpdateRequestMappingFilterConditionTypeEnum = typeof PlanUpdateRequestMappingFilterConditionTypeEnum[keyof typeof PlanUpdateRequestMappingFilterConditionTypeEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingUniqueKeyView
 */
export interface PlanUpdateRequestMappingUniqueKeyView {
    /**
     * 
     * @type {IDDimension}
     * @memberof PlanUpdateRequestMappingUniqueKeyView
     */
    'dimensionId': IDDimension;
    /**
     * 
     * @type {DimensionName}
     * @memberof PlanUpdateRequestMappingUniqueKeyView
     */
    'dimensionName': DimensionName;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingView
 */
export interface PlanUpdateRequestMappingView {
    /**
     * 
     * @type {PlanUpdateRequestMappingAccountKey}
     * @memberof PlanUpdateRequestMappingView
     */
    'accountKey': PlanUpdateRequestMappingAccountKey;
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestMappingView
     */
    'canUseForDownload': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestMappingView
     */
    'canUseForUpload': boolean;
    /**
     * 
     * @type {PlanUpdateRequestMappingDepartmentKey}
     * @memberof PlanUpdateRequestMappingView
     */
    'departmentKey': PlanUpdateRequestMappingDepartmentKey;
    /**
     * 
     * @type {Array<PlanUpdateRequestMappingDimensionKeyView>}
     * @memberof PlanUpdateRequestMappingView
     */
    'dimensionKeys': Array<PlanUpdateRequestMappingDimensionKeyView>;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMappingView
     */
    'endColumnIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMappingView
     */
    'endRowIndex'?: number;
    /**
     * 
     * @type {Array<PlanUpdateRequestMappingFilter>}
     * @memberof PlanUpdateRequestMappingView
     */
    'filters': Array<PlanUpdateRequestMappingFilter>;
    /**
     * 
     * @type {IDPlanUpdateRequestMapping}
     * @memberof PlanUpdateRequestMappingView
     */
    'id': IDPlanUpdateRequestMapping;
    /**
     * 
     * @type {Name}
     * @memberof PlanUpdateRequestMappingView
     */
    'name': Name;
    /**
     * 
     * @type {PlanUpdateRequestMappingUniqueKeyView}
     * @memberof PlanUpdateRequestMappingView
     */
    'planUpdateRequestMappingUniqueKey'?: PlanUpdateRequestMappingUniqueKeyView;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMappingView
     */
    'startColumnIndex': number;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestMappingView
     */
    'startRowIndex': number;
    /**
     * 
     * @type {PlanUpdateRequestMappingYearMonthKey}
     * @memberof PlanUpdateRequestMappingView
     */
    'yearMonthKey': PlanUpdateRequestMappingYearMonthKey;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestMappingYearMonthKey
 */
export interface PlanUpdateRequestMappingYearMonthKey {
    /**
     * 
     * @type {IDPlanUpdateRequestMappingYearMonthKey}
     * @memberof PlanUpdateRequestMappingYearMonthKey
     */
    'id': IDPlanUpdateRequestMappingYearMonthKey;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof PlanUpdateRequestMappingYearMonthKey
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestMetaView
 */
export interface PlanUpdateRequestMetaView {
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'createdUserId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'createdUserName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'departmentName': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestMetaView
     */
    'isWorkforcePlanUpdateRequest': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'periodEnd': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'periodStart': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'planUpdateRequestId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'planningProjectId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'planningProjectName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestMetaView
     */
    'status': PlanUpdateRequestMetaViewStatusEnum;
}

export const PlanUpdateRequestMetaViewStatusEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED',
    Closing: 'CLOSING',
    ApprovalFailed: 'APPROVAL_FAILED'
} as const;

export type PlanUpdateRequestMetaViewStatusEnum = typeof PlanUpdateRequestMetaViewStatusEnum[keyof typeof PlanUpdateRequestMetaViewStatusEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestPeriodView
 */
export interface PlanUpdateRequestPeriodView {
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestPeriodView
     */
    'actual': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestPeriodView
     */
    'key': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestPeriodView
     */
    'plan': boolean;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestStatusesView
 */
export interface PlanUpdateRequestStatusesView {
    /**
     * 
     * @type {Array<ApproveErrorMessage>}
     * @memberof PlanUpdateRequestStatusesView
     */
    'errorMessages': Array<ApproveErrorMessage>;
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestStatusesView
     */
    'hasApproving': boolean;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestSumCellScaledView
 */
export interface PlanUpdateRequestSumCellScaledView {
    /**
     * 
     * @type {SumDiffScaledView}
     * @memberof PlanUpdateRequestSumCellScaledView
     */
    'sum': SumDiffScaledView;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestTreeFlatV2View
 */
export interface PlanUpdateRequestTreeFlatV2View {
    /**
     * 
     * @type {boolean}
     * @memberof PlanUpdateRequestTreeFlatV2View
     */
    'hasRestrictedItems': boolean;
    /**
     * 
     * @type {Array<PlanUpdateRequestTreeNodeFlatV2View>}
     * @memberof PlanUpdateRequestTreeFlatV2View
     */
    'items': Array<PlanUpdateRequestTreeNodeFlatV2View>;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestTreeNodeFlatV2View
 */
export interface PlanUpdateRequestTreeNodeFlatV2View {
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'accountType': PlanUpdateRequestTreeNodeFlatV2ViewAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'code': string;
    /**
     * 
     * @type {Array<PlanUpdateRequestCellScaledView>}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'data': Array<PlanUpdateRequestCellScaledView>;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'rowType': PlanUpdateRequestTreeNodeFlatV2ViewRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'subAccountId'?: string;
    /**
     * 
     * @type {PlanUpdateRequestSumCellScaledView}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'sumData': PlanUpdateRequestSumCellScaledView;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeFlatV2View
     */
    'viewControlType': PlanUpdateRequestTreeNodeFlatV2ViewViewControlTypeEnum;
}

export const PlanUpdateRequestTreeNodeFlatV2ViewAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type PlanUpdateRequestTreeNodeFlatV2ViewAccountTypeEnum = typeof PlanUpdateRequestTreeNodeFlatV2ViewAccountTypeEnum[keyof typeof PlanUpdateRequestTreeNodeFlatV2ViewAccountTypeEnum];
export const PlanUpdateRequestTreeNodeFlatV2ViewRowTypeEnum = {
    Account: 'ACCOUNT',
    AccountOwnValue: 'ACCOUNT_OWN_VALUE'
} as const;

export type PlanUpdateRequestTreeNodeFlatV2ViewRowTypeEnum = typeof PlanUpdateRequestTreeNodeFlatV2ViewRowTypeEnum[keyof typeof PlanUpdateRequestTreeNodeFlatV2ViewRowTypeEnum];
export const PlanUpdateRequestTreeNodeFlatV2ViewViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type PlanUpdateRequestTreeNodeFlatV2ViewViewControlTypeEnum = typeof PlanUpdateRequestTreeNodeFlatV2ViewViewControlTypeEnum[keyof typeof PlanUpdateRequestTreeNodeFlatV2ViewViewControlTypeEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestTreeNodeMetaV2View
 */
export interface PlanUpdateRequestTreeNodeMetaV2View {
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'actualEndWhenPlanningProject'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'approvalFailedMessages': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'createdUserId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'createdUserName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'periodEnd': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'periodStart': string;
    /**
     * 
     * @type {Array<PlanUpdateRequestPeriodView>}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'periods': Array<PlanUpdateRequestPeriodView>;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'planType': PlanUpdateRequestTreeNodeMetaV2ViewPlanTypeEnum;
    /**
     * 
     * @type {IDPlanUpdateRequestFile}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'planUpdateRequestFileId'?: IDPlanUpdateRequestFile;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'planUpdateRequestId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'planUpdateRequestOriginFilePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'planUpdateRequestOriginType'?: PlanUpdateRequestTreeNodeMetaV2ViewPlanUpdateRequestOriginTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'planningProjectId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'planningProjectName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'roundingMode': PlanUpdateRequestTreeNodeMetaV2ViewRoundingModeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'showNumbersIn': PlanUpdateRequestTreeNodeMetaV2ViewShowNumbersInEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanUpdateRequestTreeNodeMetaV2View
     */
    'status': PlanUpdateRequestTreeNodeMetaV2ViewStatusEnum;
}

export const PlanUpdateRequestTreeNodeMetaV2ViewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type PlanUpdateRequestTreeNodeMetaV2ViewPlanTypeEnum = typeof PlanUpdateRequestTreeNodeMetaV2ViewPlanTypeEnum[keyof typeof PlanUpdateRequestTreeNodeMetaV2ViewPlanTypeEnum];
export const PlanUpdateRequestTreeNodeMetaV2ViewPlanUpdateRequestOriginTypeEnum = {
    Csv: 'CSV',
    Excel: 'Excel',
    SpreadSheet: 'SpreadSheet',
    Ui: 'UI',
    Workforce: 'WORKFORCE'
} as const;

export type PlanUpdateRequestTreeNodeMetaV2ViewPlanUpdateRequestOriginTypeEnum = typeof PlanUpdateRequestTreeNodeMetaV2ViewPlanUpdateRequestOriginTypeEnum[keyof typeof PlanUpdateRequestTreeNodeMetaV2ViewPlanUpdateRequestOriginTypeEnum];
export const PlanUpdateRequestTreeNodeMetaV2ViewRoundingModeEnum = {
    Down: 'DOWN',
    HalfUp: 'HALF_UP'
} as const;

export type PlanUpdateRequestTreeNodeMetaV2ViewRoundingModeEnum = typeof PlanUpdateRequestTreeNodeMetaV2ViewRoundingModeEnum[keyof typeof PlanUpdateRequestTreeNodeMetaV2ViewRoundingModeEnum];
export const PlanUpdateRequestTreeNodeMetaV2ViewShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type PlanUpdateRequestTreeNodeMetaV2ViewShowNumbersInEnum = typeof PlanUpdateRequestTreeNodeMetaV2ViewShowNumbersInEnum[keyof typeof PlanUpdateRequestTreeNodeMetaV2ViewShowNumbersInEnum];
export const PlanUpdateRequestTreeNodeMetaV2ViewStatusEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED',
    Closing: 'CLOSING'
} as const;

export type PlanUpdateRequestTreeNodeMetaV2ViewStatusEnum = typeof PlanUpdateRequestTreeNodeMetaV2ViewStatusEnum[keyof typeof PlanUpdateRequestTreeNodeMetaV2ViewStatusEnum];

/**
 * 
 * @export
 * @interface PlanUpdateRequestV2View
 */
export interface PlanUpdateRequestV2View {
    /**
     * 
     * @type {PlanUpdateRequestTreeNodeMetaV2View}
     * @memberof PlanUpdateRequestV2View
     */
    'meta': PlanUpdateRequestTreeNodeMetaV2View;
    /**
     * 
     * @type {PlanUpdateRequestTreeFlatV2View}
     * @memberof PlanUpdateRequestV2View
     */
    'tree': PlanUpdateRequestTreeFlatV2View;
}
/**
 * 
 * @export
 * @interface PlanUpdateRequestsView
 */
export interface PlanUpdateRequestsView {
    /**
     * 
     * @type {Array<PlanUpdateRequestMetaView>}
     * @memberof PlanUpdateRequestsView
     */
    'items': Array<PlanUpdateRequestMetaView>;
}
/**
 * 
 * @export
 * @interface PlanVsActualDataView
 */
export interface PlanVsActualDataView {
    /**
     * 
     * @type {string}
     * @memberof PlanVsActualDataView
     */
    'actual'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanVsActualDataView
     */
    'diff'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanVsActualDataView
     */
    'diffRatio'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanVsActualDataView
     */
    'plan'?: string;
}
/**
 * 
 * @export
 * @interface PlanningProjectDepartmentCommentMapView
 */
export interface PlanningProjectDepartmentCommentMapView {
    /**
     * 
     * @type {{ [key: string]: Array<PlanningProjectDepartmentCommentView>; }}
     * @memberof PlanningProjectDepartmentCommentMapView
     */
    'planningProjectDepartmentCommentMap': { [key: string]: Array<PlanningProjectDepartmentCommentView>; };
}
/**
 * 
 * @export
 * @interface PlanningProjectDepartmentCommentView
 */
export interface PlanningProjectDepartmentCommentView {
    /**
     * 
     * @type {boolean}
     * @memberof PlanningProjectDepartmentCommentView
     */
    'canDelete': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentCommentView
     */
    'commentText': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentCommentView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentCommentView
     */
    'createdUserId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentCommentView
     */
    'createdUserName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentCommentView
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentCommentView
     */
    'planningProjectDepartmentCommentId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentCommentView
     */
    'planningProjectId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentCommentView
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PlanningProjectDepartmentTreeNodeView
 */
export interface PlanningProjectDepartmentTreeNodeView {
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'departmentCode': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'departmentPlanningProjectProgress': PlanningProjectDepartmentTreeNodeViewDepartmentPlanningProjectProgressEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'isOwnValue': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'isSelectable': boolean;
    /**
     * 
     * @type {number}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'parentDepartmentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'submissions': number;
    /**
     * 
     * @type {Array<PlanUpdateRequestMetaView>}
     * @memberof PlanningProjectDepartmentTreeNodeView
     */
    'submittedPlanUpdateRequests': Array<PlanUpdateRequestMetaView>;
}

export const PlanningProjectDepartmentTreeNodeViewDepartmentPlanningProjectProgressEnum = {
    InReview: 'IN_REVIEW',
    Done: 'DONE',
    NotSubmitted: 'NOT_SUBMITTED'
} as const;

export type PlanningProjectDepartmentTreeNodeViewDepartmentPlanningProjectProgressEnum = typeof PlanningProjectDepartmentTreeNodeViewDepartmentPlanningProjectProgressEnum[keyof typeof PlanningProjectDepartmentTreeNodeViewDepartmentPlanningProjectProgressEnum];

/**
 * 
 * @export
 * @interface PlanningProjectListView
 */
export interface PlanningProjectListView {
    /**
     * 
     * @type {number}
     * @memberof PlanningProjectListView
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<PlanningProjectMetaView>}
     * @memberof PlanningProjectListView
     */
    'list': Array<PlanningProjectMetaView>;
    /**
     * 
     * @type {number}
     * @memberof PlanningProjectListView
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof PlanningProjectListView
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PlanningProjectMetaView
 */
export interface PlanningProjectMetaView {
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'organizationTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'planEnd': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'planStart': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'planningProjectEndAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'planningProjectId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'planningProjectName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'planningProjectStartAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'projectStatus': PlanningProjectMetaViewProjectStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectMetaView
     */
    'scheduledPlanningProjectEndAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningProjectMetaView
     */
    'useMultiStepApprovalFlow': boolean;
}

export const PlanningProjectMetaViewProjectStatusEnum = {
    Creating: 'CREATING',
    CreateError: 'CREATE_ERROR',
    Wip: 'WIP',
    Fixing: 'FIXING',
    Error: 'ERROR',
    Fixed: 'FIXED'
} as const;

export type PlanningProjectMetaViewProjectStatusEnum = typeof PlanningProjectMetaViewProjectStatusEnum[keyof typeof PlanningProjectMetaViewProjectStatusEnum];

/**
 * 
 * @export
 * @interface PlanningProjectNodeMetaView
 */
export interface PlanningProjectNodeMetaView {
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'accountTreeName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'actualDownloadPeriodEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'actualDownloadPeriodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'actualEndWhenPlanningProject'?: string;
    /**
     * 
     * @type {Array<DepartmentView>}
     * @memberof PlanningProjectNodeMetaView
     */
    'assignedDepartments': Array<DepartmentView>;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningProjectNodeMetaView
     */
    'canActualDownload': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'creationErrorMessage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningProjectNodeMetaView
     */
    'isArchived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'nonFinancialAccountTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'nonFinancialAccountTreeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'organizationTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'organizationTreeName': string;
    /**
     * 
     * @type {PlanningProjectOverView}
     * @memberof PlanningProjectNodeMetaView
     */
    'overview': PlanningProjectOverView;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'planMasterId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'planType': PlanningProjectNodeMetaViewPlanTypeEnum;
    /**
     * 
     * @type {Array<PlanningProjectDepartmentTreeNodeView>}
     * @memberof PlanningProjectNodeMetaView
     */
    'planningProjectDepartmentTreeNodeList': Array<PlanningProjectDepartmentTreeNodeView>;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'planningProjectEndAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'planningProjectId': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'planningProjectName': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'planningProjectStartAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'planningTargetEnd': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'planningTargetStart': string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningProjectNodeMetaView
     */
    'requirePlanMasterAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'roundingMode': PlanningProjectNodeMetaViewRoundingModeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'scheduledPlanningProjectEndAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'showIn': PlanningProjectNodeMetaViewShowInEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanningProjectNodeMetaView
     */
    'status': PlanningProjectNodeMetaViewStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PlanningProjectNodeMetaView
     */
    'useMultiStepApprovalFlow': boolean;
}

export const PlanningProjectNodeMetaViewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type PlanningProjectNodeMetaViewPlanTypeEnum = typeof PlanningProjectNodeMetaViewPlanTypeEnum[keyof typeof PlanningProjectNodeMetaViewPlanTypeEnum];
export const PlanningProjectNodeMetaViewRoundingModeEnum = {
    Down: 'DOWN',
    HalfUp: 'HALF_UP'
} as const;

export type PlanningProjectNodeMetaViewRoundingModeEnum = typeof PlanningProjectNodeMetaViewRoundingModeEnum[keyof typeof PlanningProjectNodeMetaViewRoundingModeEnum];
export const PlanningProjectNodeMetaViewShowInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type PlanningProjectNodeMetaViewShowInEnum = typeof PlanningProjectNodeMetaViewShowInEnum[keyof typeof PlanningProjectNodeMetaViewShowInEnum];
export const PlanningProjectNodeMetaViewStatusEnum = {
    Creating: 'CREATING',
    CreateError: 'CREATE_ERROR',
    Wip: 'WIP',
    Fixing: 'FIXING',
    Error: 'ERROR',
    Fixed: 'FIXED'
} as const;

export type PlanningProjectNodeMetaViewStatusEnum = typeof PlanningProjectNodeMetaViewStatusEnum[keyof typeof PlanningProjectNodeMetaViewStatusEnum];

/**
 * 
 * @export
 * @interface PlanningProjectOverView
 */
export interface PlanningProjectOverView {
    /**
     * 
     * @type {number}
     * @memberof PlanningProjectOverView
     */
    'allAssignedDepartmentsCount': number;
    /**
     * 
     * @type {number}
     * @memberof PlanningProjectOverView
     */
    'finishedPlanningDepartmentsCount': number;
    /**
     * 
     * @type {number}
     * @memberof PlanningProjectOverView
     */
    'notSubmittedDepartmentsCount': number;
}
/**
 * 
 * @export
 * @interface PlanningProjectView
 */
export interface PlanningProjectView {
    /**
     * 
     * @type {PlanningProjectNodeMetaView}
     * @memberof PlanningProjectView
     */
    'meta': PlanningProjectNodeMetaView;
}
/**
 * 
 * @export
 * @interface PreviewHighlightData
 */
export interface PreviewHighlightData {
    /**
     * 
     * @type {Array<number>}
     * @memberof PreviewHighlightData
     */
    'columnIndexes': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PreviewHighlightData
     */
    'rowIndexes': Array<number>;
}
/**
 * 
 * @export
 * @interface ProcessConditionDataParams
 */
export interface ProcessConditionDataParams {
    /**
     * 
     * @type {string}
     * @memberof ProcessConditionDataParams
     */
    'conditionType': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessConditionDataParams
     */
    'conditionValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessConditionDataParams
     */
    'number': string;
}
/**
 * 
 * @export
 * @interface ProcessInsertSettingParams
 */
export interface ProcessInsertSettingParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessInsertSettingParams
     */
    'columns': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessInsertSettingParams
     */
    'rows': Array<string>;
}
/**
 * 
 * @export
 * @interface ProcessOperationDataParams
 */
export interface ProcessOperationDataParams {
    /**
     * 
     * @type {CalculateActionParams}
     * @memberof ProcessOperationDataParams
     */
    'calculateAction'?: CalculateActionParams;
    /**
     * 
     * @type {ReplaceActionParams}
     * @memberof ProcessOperationDataParams
     */
    'replaceAction'?: ReplaceActionParams;
    /**
     * 
     * @type {SplitActionParams}
     * @memberof ProcessOperationDataParams
     */
    'splitAction'?: SplitActionParams;
    /**
     * 
     * @type {string}
     * @memberof ProcessOperationDataParams
     */
    'variableName'?: string;
}
/**
 * 
 * @export
 * @interface ProcessSettingParams
 */
export interface ProcessSettingParams {
    /**
     * 
     * @type {ProcessInsertSettingParams}
     * @memberof ProcessSettingParams
     */
    'insertSetting': ProcessInsertSettingParams;
    /**
     * 
     * @type {string}
     * @memberof ProcessSettingParams
     */
    'name': string;
    /**
     * 
     * @type {Array<ProcessTransformSettingParams>}
     * @memberof ProcessSettingParams
     */
    'transformSettings': Array<ProcessTransformSettingParams>;
}
/**
 * 
 * @export
 * @interface ProcessSettingPreview
 */
export interface ProcessSettingPreview {
    /**
     * 
     * @type {ProcessSettingPreviewData}
     * @memberof ProcessSettingPreview
     */
    'data': ProcessSettingPreviewData;
    /**
     * 
     * @type {PreviewHighlightData}
     * @memberof ProcessSettingPreview
     */
    'highlightData': PreviewHighlightData;
}
/**
 * 
 * @export
 * @interface ProcessSettingPreviewData
 */
export interface ProcessSettingPreviewData {
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ProcessSettingPreviewData
     */
    'rows': Array<Array<string>>;
}
/**
 * 
 * @export
 * @interface ProcessSettingView
 */
export interface ProcessSettingView {
    /**
     * 
     * @type {IDProcessSetting}
     * @memberof ProcessSettingView
     */
    'id': IDProcessSetting;
    /**
     * 
     * @type {string}
     * @memberof ProcessSettingView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessSettingView
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ProcessTransformParams
 */
export interface ProcessTransformParams {
    /**
     * 
     * @type {Array<ProcessConditionDataParams>}
     * @memberof ProcessTransformParams
     */
    'conditions': Array<ProcessConditionDataParams>;
    /**
     * 
     * @type {string}
     * @memberof ProcessTransformParams
     */
    'matchingType': ProcessTransformParamsMatchingTypeEnum;
    /**
     * 
     * @type {Array<ProcessOperationDataParams>}
     * @memberof ProcessTransformParams
     */
    'operations': Array<ProcessOperationDataParams>;
}

export const ProcessTransformParamsMatchingTypeEnum = {
    And: 'AND',
    Or: 'OR'
} as const;

export type ProcessTransformParamsMatchingTypeEnum = typeof ProcessTransformParamsMatchingTypeEnum[keyof typeof ProcessTransformParamsMatchingTypeEnum];

/**
 * 
 * @export
 * @interface ProcessTransformSettingParams
 */
export interface ProcessTransformSettingParams {
    /**
     * 
     * @type {string}
     * @memberof ProcessTransformSettingParams
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessTransformSettingParams
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessTransformSettingParams
     */
    'targetRowColumnType': ProcessTransformSettingParamsTargetRowColumnTypeEnum;
    /**
     * 
     * @type {Array<ProcessTransformParams>}
     * @memberof ProcessTransformSettingParams
     */
    'transforms': Array<ProcessTransformParams>;
    /**
     * 
     * @type {ProcessUnConditonalTransformParams}
     * @memberof ProcessTransformSettingParams
     */
    'unConditionalTransform'?: ProcessUnConditonalTransformParams;
}

export const ProcessTransformSettingParamsTargetRowColumnTypeEnum = {
    Row: 'ROW',
    Column: 'COLUMN'
} as const;

export type ProcessTransformSettingParamsTargetRowColumnTypeEnum = typeof ProcessTransformSettingParamsTargetRowColumnTypeEnum[keyof typeof ProcessTransformSettingParamsTargetRowColumnTypeEnum];

/**
 * 
 * @export
 * @interface ProcessUnConditonalTransformParams
 */
export interface ProcessUnConditonalTransformParams {
    /**
     * 
     * @type {Array<ProcessOperationDataParams>}
     * @memberof ProcessUnConditonalTransformParams
     */
    'operations': Array<ProcessOperationDataParams>;
}
/**
 * 
 * @export
 * @interface PutNonFinancialAccountTreeNameBody
 */
export interface PutNonFinancialAccountTreeNameBody {
    /**
     * 
     * @type {string}
     * @memberof PutNonFinancialAccountTreeNameBody
     */
    'newName': string;
}
/**
 * 
 * @export
 * @interface RatioPatternBody
 */
export interface RatioPatternBody {
    /**
     * 
     * @type {string}
     * @memberof RatioPatternBody
     */
    'ratioString': string;
    /**
     * 
     * @type {string}
     * @memberof RatioPatternBody
     */
    'targetAccountId': string;
}
/**
 * 
 * @export
 * @interface RatioPatternView
 */
export interface RatioPatternView {
    /**
     * 
     * @type {string}
     * @memberof RatioPatternView
     */
    'ratioString': string;
    /**
     * 
     * @type {string}
     * @memberof RatioPatternView
     */
    'targetAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof RatioPatternView
     */
    'targetAccountName': string;
}
/**
 * 
 * @export
 * @interface RegisterFeedBackRequestBody
 */
export interface RegisterFeedBackRequestBody {
    /**
     * 
     * @type {string}
     * @memberof RegisterFeedBackRequestBody
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterFeedBackRequestBody
     */
    'feedBackType': RegisterFeedBackRequestBodyFeedBackTypeEnum;
}

export const RegisterFeedBackRequestBodyFeedBackTypeEnum = {
    Positive: 'POSITIVE',
    Negative: 'NEGATIVE'
} as const;

export type RegisterFeedBackRequestBodyFeedBackTypeEnum = typeof RegisterFeedBackRequestBodyFeedBackTypeEnum[keyof typeof RegisterFeedBackRequestBodyFeedBackTypeEnum];

/**
 * 
 * @export
 * @interface RemainingCountByMonthView
 */
export interface RemainingCountByMonthView {
    /**
     * 
     * @type {number}
     * @memberof RemainingCountByMonthView
     */
    'remainingCount': number;
    /**
     * 
     * @type {string}
     * @memberof RemainingCountByMonthView
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface RemainingCountByYearMonth
 */
export interface RemainingCountByYearMonth {
    /**
     * 
     * @type {number}
     * @memberof RemainingCountByYearMonth
     */
    'remainingCount': number;
    /**
     * 
     * @type {string}
     * @memberof RemainingCountByYearMonth
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface RemainingCountItem
 */
export interface RemainingCountItem {
    /**
     * 
     * @type {number}
     * @memberof RemainingCountItem
     */
    'remainingCount': number;
    /**
     * 
     * @type {string}
     * @memberof RemainingCountItem
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface RemainingCountUpdateTarget
 */
export interface RemainingCountUpdateTarget {
    /**
     * 
     * @type {Array<RemainingCountItem>}
     * @memberof RemainingCountUpdateTarget
     */
    'remainingCounts': Array<RemainingCountItem>;
    /**
     * 
     * @type {string}
     * @memberof RemainingCountUpdateTarget
     */
    'simulatingSlotId': string;
}
/**
 * 
 * @export
 * @interface RenameReportFolderBody
 */
export interface RenameReportFolderBody {
    /**
     * 
     * @type {string}
     * @memberof RenameReportFolderBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ReorderLocalCurrencyRequestParam
 */
export interface ReorderLocalCurrencyRequestParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReorderLocalCurrencyRequestParam
     */
    'currencyLocalIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ReplaceActionParams
 */
export interface ReplaceActionParams {
    /**
     * 
     * @type {string}
     * @memberof ReplaceActionParams
     */
    'format': string;
}
/**
 * 
 * @export
 * @interface ReplaceRootNodeRequestBody
 */
export interface ReplaceRootNodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ReplaceRootNodeRequestBody
     */
    'newRootAccountId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportCellType = {
    Data: 'DATA',
    Comparison: 'COMPARISON'
} as const;

export type ReportCellType = typeof ReportCellType[keyof typeof ReportCellType];


/**
 * 
 * @export
 * @interface ReportConditionSchema
 */
export interface ReportConditionSchema {
    /**
     * 
     * @type {Array<CellGroupOrder>}
     * @memberof ReportConditionSchema
     */
    'cellGroupOrders': Array<CellGroupOrder>;
    /**
     * 
     * @type {ColumnAggregationDimension}
     * @memberof ReportConditionSchema
     */
    'columnAggregationDimension': ColumnAggregationDimension;
    /**
     * 
     * @type {Array<ComparisonCondition>}
     * @memberof ReportConditionSchema
     */
    'comparisonConditions': Array<ComparisonCondition>;
    /**
     * 
     * @type {Array<DataCondition>}
     * @memberof ReportConditionSchema
     */
    'dataConditions': Array<DataCondition>;
    /**
     * 
     * @type {boolean}
     * @memberof ReportConditionSchema
     */
    'isShowEmptyRows': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportConditionSchema
     */
    'isTreeView': boolean;
    /**
     * 
     * @type {Array<RowAggregationDimension>}
     * @memberof ReportConditionSchema
     */
    'rowAggregationDimensions': Array<RowAggregationDimension>;
    /**
     * 
     * @type {boolean}
     * @memberof ReportConditionSchema
     */
    'showNoneDimension': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportConditionSchema
     */
    'showNumbersIn': ReportConditionSchemaShowNumbersInEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ReportConditionSchema
     */
    'showSumRow': boolean;
}

export const ReportConditionSchemaShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type ReportConditionSchemaShowNumbersInEnum = typeof ReportConditionSchemaShowNumbersInEnum[keyof typeof ReportConditionSchemaShowNumbersInEnum];

/**
 * 
 * @export
 * @interface ReportDescriptionView
 */
export interface ReportDescriptionView {
    /**
     * 
     * @type {string}
     * @memberof ReportDescriptionView
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDescriptionView
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDescriptionView
     */
    'lastUpdatedDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDescriptionView
     */
    'reportId': string;
}
/**
 * 
 * @export
 * @interface ReportFolderDetailView
 */
export interface ReportFolderDetailView {
    /**
     * 
     * @type {ReportFolderInfo}
     * @memberof ReportFolderDetailView
     */
    'currentFolder'?: ReportFolderInfo;
    /**
     * 
     * @type {Array<ReportFolderItem>}
     * @memberof ReportFolderDetailView
     */
    'items': Array<ReportFolderItem>;
    /**
     * 
     * @type {Array<ReportFolderInfo>}
     * @memberof ReportFolderDetailView
     */
    'paths': Array<ReportFolderInfo>;
}
/**
 * 
 * @export
 * @interface ReportFolderInfo
 */
export interface ReportFolderInfo {
    /**
     * 
     * @type {string}
     * @memberof ReportFolderInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderInfo
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ReportFolderItem
 */
export interface ReportFolderItem {
    /**
     * 
     * @type {boolean}
     * @memberof ReportFolderItem
     */
    'canDelete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportFolderItem
     */
    'canRename': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportFolderItem
     */
    'canSave': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderItem
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderItem
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportFolderItem
     */
    'isEditRestricted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderItem
     */
    'itemType': ReportFolderItemItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderItem
     */
    'lastUpdatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderItem
     */
    'lastUpdatedUserName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderItem
     */
    'name': string;
    /**
     * 
     * @type {ReportFolderInfo}
     * @memberof ReportFolderItem
     */
    'parentFolderInfo'?: ReportFolderInfo;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderItem
     */
    'reportType'?: ReportFolderItemReportTypeEnum;
}

export const ReportFolderItemItemTypeEnum = {
    Folder: 'FOLDER',
    NormalReport: 'NORMAL_REPORT',
    AiPlanActualAnalysisReport: 'AI_PLAN_ACTUAL_ANALYSIS_REPORT'
} as const;

export type ReportFolderItemItemTypeEnum = typeof ReportFolderItemItemTypeEnum[keyof typeof ReportFolderItemItemTypeEnum];
export const ReportFolderItemReportTypeEnum = {
    Pl: 'PL',
    Bs: 'BS',
    AiPlanActualAnalysisReport: 'AI_PLAN_ACTUAL_ANALYSIS_REPORT'
} as const;

export type ReportFolderItemReportTypeEnum = typeof ReportFolderItemReportTypeEnum[keyof typeof ReportFolderItemReportTypeEnum];

/**
 * 
 * @export
 * @interface ReportFolderTreeForSelectionView
 */
export interface ReportFolderTreeForSelectionView {
    /**
     * 
     * @type {Array<TreeNodeReportFolderTreeNode>}
     * @memberof ReportFolderTreeForSelectionView
     */
    'rootNodes': Array<TreeNodeReportFolderTreeNode>;
}
/**
 * 
 * @export
 * @interface ReportFolderTreeNode
 */
export interface ReportFolderTreeNode {
    /**
     * 
     * @type {string}
     * @memberof ReportFolderTreeNode
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportFolderTreeNode
     */
    'isSelectable': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderTreeNode
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportFolderTreeNode
     */
    'parentId'?: string;
}
/**
 * 
 * @export
 * @interface ReportTableCellCondition
 */
export interface ReportTableCellCondition {
    /**
     * 
     * @type {Array<CellDimensionCondition>}
     * @memberof ReportTableCellCondition
     */
    'dimensionConditions': Array<CellDimensionCondition>;
}
/**
 * 
 * @export
 * @interface ReportTableDetailHeaderDimensionView
 */
export interface ReportTableDetailHeaderDimensionView {
    /**
     * 
     * @type {string}
     * @memberof ReportTableDetailHeaderDimensionView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTableDetailHeaderDimensionView
     */
    'sortKey': ReportTableDetailHeaderDimensionViewSortKeyEnum;
}

export const ReportTableDetailHeaderDimensionViewSortKeyEnum = {
    JournalDate: 'JOURNAL_DATE',
    DepartmentName: 'DEPARTMENT_NAME',
    AccountName: 'ACCOUNT_NAME',
    Description: 'DESCRIPTION',
    Amount: 'AMOUNT',
    Dimension1Name: 'DIMENSION_1_NAME',
    Dimension2Name: 'DIMENSION_2_NAME',
    Dimension3Name: 'DIMENSION_3_NAME',
    Dimension4Name: 'DIMENSION_4_NAME',
    Dimension5Name: 'DIMENSION_5_NAME',
    Dimension6Name: 'DIMENSION_6_NAME',
    Dimension7Name: 'DIMENSION_7_NAME'
} as const;

export type ReportTableDetailHeaderDimensionViewSortKeyEnum = typeof ReportTableDetailHeaderDimensionViewSortKeyEnum[keyof typeof ReportTableDetailHeaderDimensionViewSortKeyEnum];

/**
 * 
 * @export
 * @interface ReportTableDetailHeaderView
 */
export interface ReportTableDetailHeaderView {
    /**
     * 
     * @type {Array<ReportTableDetailHeaderDimensionView>}
     * @memberof ReportTableDetailHeaderView
     */
    'dimensions': Array<ReportTableDetailHeaderDimensionView>;
}
/**
 * 
 * @export
 * @interface ReportTableDetailRowView
 */
export interface ReportTableDetailRowView {
    /**
     * 
     * @type {string}
     * @memberof ReportTableDetailRowView
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTableDetailRowView
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTableDetailRowView
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTableDetailRowView
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportTableDetailRowView
     */
    'dimensionTagNames': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReportTableDetailRowView
     */
    'journalDate': string;
}
/**
 * 
 * @export
 * @interface ReportTableKeyItem
 */
export interface ReportTableKeyItem {
    /**
     * 
     * @type {string}
     * @memberof ReportTableKeyItem
     */
    'dimensionTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTableKeyItem
     */
    'dimensionType': ReportTableKeyItemDimensionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportTableKeyItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTableKeyItem
     */
    'periodAggregation'?: ReportTableKeyItemPeriodAggregationEnum;
}

export const ReportTableKeyItemDimensionTypeEnum = {
    Account: 'ACCOUNT',
    Department: 'DEPARTMENT',
    Dimension1: 'DIMENSION1',
    Dimension2: 'DIMENSION2',
    Dimension3: 'DIMENSION3',
    Dimension4: 'DIMENSION4',
    Dimension5: 'DIMENSION5',
    Dimension6: 'DIMENSION6',
    Dimension7: 'DIMENSION7',
    Period: 'PERIOD'
} as const;

export type ReportTableKeyItemDimensionTypeEnum = typeof ReportTableKeyItemDimensionTypeEnum[keyof typeof ReportTableKeyItemDimensionTypeEnum];
export const ReportTableKeyItemPeriodAggregationEnum = {
    Month: 'MONTH',
    Quarter: 'QUARTER',
    FiscalYear: 'FISCAL_YEAR',
    FiscalHalfYear: 'FISCAL_HALF_YEAR'
} as const;

export type ReportTableKeyItemPeriodAggregationEnum = typeof ReportTableKeyItemPeriodAggregationEnum[keyof typeof ReportTableKeyItemPeriodAggregationEnum];

/**
 * 
 * @export
 * @interface ReportTableView
 */
export interface ReportTableView {
    /**
     * 
     * @type {MetaView}
     * @memberof ReportTableView
     */
    'meta': MetaView;
    /**
     * 
     * @type {Array<RowView>}
     * @memberof ReportTableView
     */
    'rows': Array<RowView>;
}
/**
 * 
 * @export
 * @interface ReportTreeTableView
 */
export interface ReportTreeTableView {
    /**
     * 
     * @type {MetaView}
     * @memberof ReportTreeTableView
     */
    'meta': MetaView;
    /**
     * 
     * @type {Array<TreeNodeRowViewForTree>}
     * @memberof ReportTreeTableView
     */
    'rootRowTreeNodes': Array<TreeNodeRowViewForTree>;
}
/**
 * 
 * @export
 * @interface ReportView
 */
export interface ReportView {
    /**
     * 
     * @type {boolean}
     * @memberof ReportView
     */
    'bigqueryEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportView
     */
    'canDelete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportView
     */
    'canEditAllow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportView
     */
    'canEditRestrict': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportView
     */
    'canRename': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportView
     */
    'canSave': boolean;
    /**
     * 
     * @type {ReportConditionSchema}
     * @memberof ReportView
     */
    'condition'?: ReportConditionSchema;
    /**
     * 
     * @type {string}
     * @memberof ReportView
     */
    'createUserName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReportView
     */
    'folderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportView
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportView
     */
    'isEditRestricted': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportView
     */
    'lastUpdateUserName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportView
     */
    'lastUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReportView
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ReportView
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface RoleView
 */
export interface RoleView {
    /**
     * 
     * @type {string}
     * @memberof RoleView
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RoleView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RowAggregationDimension
 */
export interface RowAggregationDimension {
    /**
     * 
     * @type {string}
     * @memberof RowAggregationDimension
     */
    'dimensionTreeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAggregationDimension
     */
    'dimensionType': RowAggregationDimensionDimensionTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RowAggregationDimension
     */
    'visibleIds': Array<string>;
}

export const RowAggregationDimensionDimensionTypeEnum = {
    Account: 'ACCOUNT',
    Department: 'DEPARTMENT',
    Dimension1: 'DIMENSION_1',
    Dimension2: 'DIMENSION_2',
    Dimension3: 'DIMENSION_3',
    Dimension4: 'DIMENSION_4',
    Dimension5: 'DIMENSION_5',
    Dimension6: 'DIMENSION_6',
    Dimension7: 'DIMENSION_7'
} as const;

export type RowAggregationDimensionDimensionTypeEnum = typeof RowAggregationDimensionDimensionTypeEnum[keyof typeof RowAggregationDimensionDimensionTypeEnum];

/**
 * 
 * @export
 * @interface RowAggregationKeyMetaView
 */
export interface RowAggregationKeyMetaView {
    /**
     * 
     * @type {string}
     * @memberof RowAggregationKeyMetaView
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface RowAggregationKeyView
 */
export interface RowAggregationKeyView {
    /**
     * 
     * @type {string}
     * @memberof RowAggregationKeyView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RowAggregationKeyView
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAggregationKeyView
     */
    'rowAggregationKeyType'?: RowAggregationKeyViewRowAggregationKeyTypeEnum;
}

export const RowAggregationKeyViewRowAggregationKeyTypeEnum = {
    AggregatedAccount: 'AGGREGATED_ACCOUNT',
    CalculatedAccount: 'CALCULATED_ACCOUNT',
    AccountGroup: 'ACCOUNT_GROUP'
} as const;

export type RowAggregationKeyViewRowAggregationKeyTypeEnum = typeof RowAggregationKeyViewRowAggregationKeyTypeEnum[keyof typeof RowAggregationKeyViewRowAggregationKeyTypeEnum];

/**
 * 
 * @export
 * @interface RowInfo
 */
export interface RowInfo {
    /**
     * 
     * @type {string}
     * @memberof RowInfo
     */
    'periodKey': string;
    /**
     * 
     * @type {string}
     * @memberof RowInfo
     */
    'type': RowInfoTypeEnum;
}

export const RowInfoTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST',
    Actual: 'ACTUAL',
    None: 'NONE'
} as const;

export type RowInfoTypeEnum = typeof RowInfoTypeEnum[keyof typeof RowInfoTypeEnum];

/**
 * 
 * @export
 * @interface RowView
 */
export interface RowView {
    /**
     * 
     * @type {Array<CellGroupView>}
     * @memberof RowView
     */
    'cellGroups': Array<CellGroupView>;
    /**
     * 
     * @type {Array<RowAggregationKeyView>}
     * @memberof RowView
     */
    'rowAggregationKeys': Array<RowAggregationKeyView>;
    /**
     * 
     * @type {SumCellGroupView}
     * @memberof RowView
     */
    'sumCellGroup'?: SumCellGroupView;
}
/**
 * 
 * @export
 * @interface RowViewForTree
 */
export interface RowViewForTree {
    /**
     * 
     * @type {Array<CellGroupView>}
     * @memberof RowViewForTree
     */
    'cellGroups': Array<CellGroupView>;
    /**
     * 
     * @type {number}
     * @memberof RowViewForTree
     */
    'level': number;
    /**
     * 
     * @type {Array<RowAggregationKeyView>}
     * @memberof RowViewForTree
     */
    'rowAggregationKeys': Array<RowAggregationKeyView>;
    /**
     * 
     * @type {SumCellGroupView}
     * @memberof RowViewForTree
     */
    'sumCellGroup'?: SumCellGroupView;
}
/**
 * 
 * @export
 * @interface SaveFinancialSimulationScenarioRequestBody
 */
export interface SaveFinancialSimulationScenarioRequestBody {
    /**
     * 
     * @type {Array<FinancialSimulationReportRowRequest>}
     * @memberof SaveFinancialSimulationScenarioRequestBody
     */
    'rows': Array<FinancialSimulationReportRowRequest>;
}
/**
 * 
 * @export
 * @interface ScenarioDto
 */
export interface ScenarioDto {
    /**
     * 
     * @type {string}
     * @memberof ScenarioDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SearchPlanUpdateRequestFilesParams
 */
export interface SearchPlanUpdateRequestFilesParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchPlanUpdateRequestFilesParams
     */
    'departmentIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchPlanUpdateRequestFilesParams
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchPlanUpdateRequestFilesParams
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof SearchPlanUpdateRequestFilesParams
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof SearchPlanUpdateRequestFilesParams
     */
    'planId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPlanUpdateRequestFilesParams
     */
    'planningProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPlanUpdateRequestFilesParams
     */
    'userNameOrEmail'?: string;
    /**
     * 
     * @type {YearMonthRangeParams}
     * @memberof SearchPlanUpdateRequestFilesParams
     */
    'yearMonthRange'?: YearMonthRangeParams;
}
/**
 * 
 * @export
 * @interface SearchPlanUpdateRequestFormatsParams
 */
export interface SearchPlanUpdateRequestFormatsParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchPlanUpdateRequestFormatsParams
     */
    'departmentIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SearchPlanUpdateRequestFormatsParams
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof SearchPlanUpdateRequestFormatsParams
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchPlanUpdateRequestFormatsParams
     */
    'offset': number;
}
/**
 * 
 * @export
 * @interface SelectedConditionDetail
 */
export interface SelectedConditionDetail {
    /**
     * 
     * @type {IDPLAccountTree}
     * @memberof SelectedConditionDetail
     */
    'accountTreeId': IDPLAccountTree;
    /**
     * 
     * @type {string}
     * @memberof SelectedConditionDetail
     */
    'aggregate': SelectedConditionDetailAggregateEnum;
    /**
     * 
     * @type {Array<IDDepartment>}
     * @memberof SelectedConditionDetail
     */
    'departmentIds': Array<IDDepartment>;
    /**
     * 
     * @type {number}
     * @memberof SelectedConditionDetail
     */
    'highlightThreshold'?: number;
    /**
     * 
     * @type {string}
     * @memberof SelectedConditionDetail
     */
    'highlightType'?: SelectedConditionDetailHighlightTypeEnum;
    /**
     * 
     * @type {IDOrgTree}
     * @memberof SelectedConditionDetail
     */
    'organizationTreeId': IDOrgTree;
    /**
     * 
     * @type {LoglassYearMonth}
     * @memberof SelectedConditionDetail
     */
    'planAActualEndYearMonth'?: LoglassYearMonth;
    /**
     * 
     * @type {LoglassYearMonth}
     * @memberof SelectedConditionDetail
     */
    'planAActualStartYearMonth'?: LoglassYearMonth;
    /**
     * 
     * @type {LoglassYearMonth}
     * @memberof SelectedConditionDetail
     */
    'planAEndYearMonth': LoglassYearMonth;
    /**
     * 
     * @type {IDPlanMaster}
     * @memberof SelectedConditionDetail
     */
    'planAMasterId'?: IDPlanMaster;
    /**
     * 
     * @type {boolean}
     * @memberof SelectedConditionDetail
     */
    'planAShowActual': boolean;
    /**
     * 
     * @type {LoglassYearMonth}
     * @memberof SelectedConditionDetail
     */
    'planAStartYearMonth': LoglassYearMonth;
    /**
     * 
     * @type {IDPlanningProject}
     * @memberof SelectedConditionDetail
     */
    'planAUpdateProjectId'?: IDPlanningProject;
    /**
     * 
     * @type {LoglassYearMonth}
     * @memberof SelectedConditionDetail
     */
    'planBActualEndYearMonth'?: LoglassYearMonth;
    /**
     * 
     * @type {LoglassYearMonth}
     * @memberof SelectedConditionDetail
     */
    'planBActualStartYearMonth'?: LoglassYearMonth;
    /**
     * 
     * @type {LoglassYearMonth}
     * @memberof SelectedConditionDetail
     */
    'planBEndYearMonth': LoglassYearMonth;
    /**
     * 
     * @type {IDPlanMaster}
     * @memberof SelectedConditionDetail
     */
    'planBMasterId'?: IDPlanMaster;
    /**
     * 
     * @type {boolean}
     * @memberof SelectedConditionDetail
     */
    'planBShowActual': boolean;
    /**
     * 
     * @type {LoglassYearMonth}
     * @memberof SelectedConditionDetail
     */
    'planBStartYearMonth': LoglassYearMonth;
    /**
     * 
     * @type {IDPlanningProject}
     * @memberof SelectedConditionDetail
     */
    'planBUpdateProjectId'?: IDPlanningProject;
    /**
     * 
     * @type {boolean}
     * @memberof SelectedConditionDetail
     */
    'requireAllocatedItem': boolean;
}

export const SelectedConditionDetailAggregateEnum = {
    Month: 'MONTH',
    Quarter: 'QUARTER',
    FiscalYear: 'FISCAL_YEAR',
    FiscalHalfYear: 'FISCAL_HALF_YEAR'
} as const;

export type SelectedConditionDetailAggregateEnum = typeof SelectedConditionDetailAggregateEnum[keyof typeof SelectedConditionDetailAggregateEnum];
export const SelectedConditionDetailHighlightTypeEnum = {
    Amount: 'DIFF_AMOUNT',
    Ratio: 'DIFF_RATIO'
} as const;

export type SelectedConditionDetailHighlightTypeEnum = typeof SelectedConditionDetailHighlightTypeEnum[keyof typeof SelectedConditionDetailHighlightTypeEnum];

/**
 * 
 * @export
 * @interface SetPermissionsParams
 */
export interface SetPermissionsParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof SetPermissionsParams
     */
    'permissionIds': Array<string>;
}
/**
 * 
 * @export
 * @interface SetRolesParams
 */
export interface SetRolesParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof SetRolesParams
     */
    'roleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface SettingsExcelSheetProtectionView
 */
export interface SettingsExcelSheetProtectionView {
    /**
     * 
     * @type {string}
     * @memberof SettingsExcelSheetProtectionView
     */
    'excelSheetProtectionPasswordForPlanUpdateRequest'?: string;
}
/**
 * 
 * @export
 * @interface SettingsView
 */
export interface SettingsView {
    /**
     * 
     * @type {string}
     * @memberof SettingsView
     */
    'accountingSystemCode': SettingsViewAccountingSystemCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsView
     */
    'defaultDepartmentIdImportActual'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsView
     */
    'defaultDepartmentNameImportActual'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsView
     */
    'enableExcelSheetProtectionForPlanUpdateRequest': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsView
     */
    'enableMasterAutoGenerate': boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingsView
     */
    'firstViewPage': SettingsViewFirstViewPageEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsView
     */
    'fiscalYearStartMonth': string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsView
     */
    'hideAccountsIfEmptyOnPl': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsView
     */
    'isActiveEmailNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsView
     */
    'limitPermissionToAddToNewSpreadsheet': boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingsView
     */
    'roundingMode': SettingsViewRoundingModeEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsView
     */
    'showNumbersIn': SettingsViewShowNumbersInEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsView
     */
    'tableauDashboardUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsView
     */
    'tableauTargetAccountTreeId'?: string;
}

export const SettingsViewAccountingSystemCodeEnum = {
    Miroku: 'MIROKU',
    KanjoBugyo: 'KANJO_BUGYO',
    MoneyForward: 'MONEY_FORWARD',
    MoneyForwardPlus: 'MONEY_FORWARD_PLUS',
    Freee: 'FREEE',
    FreeeV2: 'FREEE_V2',
    Obic7: 'OBIC7',
    Yayoi: 'YAYOI',
    Sap: 'SAP',
    SapSalesReport: 'SAP_SALES_REPORT',
    Pca: 'PCA',
    NetSuite: 'NET_SUITE',
    Fx4: 'FX4',
    Fx5: 'FX5',
    BizIntegral: 'BIZ_INTEGRAL',
    Ai21: 'AI21',
    LoglassDepartments: 'LOGLASS_DEPARTMENTS',
    LoglassDetail: 'LOGLASS_DETAIL',
    SuperStream: 'SUPER_STREAM',
    LoglassJournal: 'LOGLASS_JOURNAL',
    Loglass: 'LOGLASS',
    Mapping: 'MAPPING'
} as const;

export type SettingsViewAccountingSystemCodeEnum = typeof SettingsViewAccountingSystemCodeEnum[keyof typeof SettingsViewAccountingSystemCodeEnum];
export const SettingsViewFirstViewPageEnum = {
    FinancialSheetHistory: 'FINANCIAL_SHEET_HISTORY',
    Reports: 'REPORTS'
} as const;

export type SettingsViewFirstViewPageEnum = typeof SettingsViewFirstViewPageEnum[keyof typeof SettingsViewFirstViewPageEnum];
export const SettingsViewRoundingModeEnum = {
    Down: 'DOWN',
    HalfUp: 'HALF_UP'
} as const;

export type SettingsViewRoundingModeEnum = typeof SettingsViewRoundingModeEnum[keyof typeof SettingsViewRoundingModeEnum];
export const SettingsViewShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type SettingsViewShowNumbersInEnum = typeof SettingsViewShowNumbersInEnum[keyof typeof SettingsViewShowNumbersInEnum];

/**
 * 
 * @export
 * @interface SheetInfo
 */
export interface SheetInfo {
    /**
     * 
     * @type {boolean}
     * @memberof SheetInfo
     */
    'selectable': boolean;
    /**
     * 
     * @type {number}
     * @memberof SheetInfo
     */
    'sheetIndex': number;
    /**
     * 
     * @type {string}
     * @memberof SheetInfo
     */
    'sheetName': string;
}
/**
 * 
 * @export
 * @interface SimulationListItem
 */
export interface SimulationListItem {
    /**
     * 
     * @type {string}
     * @memberof SimulationListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SimulationListItem
     */
    'lastUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SimulationListItem
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SimulationPLCellView
 */
export interface SimulationPLCellView {
    /**
     * 
     * @type {number}
     * @memberof SimulationPLCellView
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationPLCellView
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface SimulationPLRowView
 */
export interface SimulationPLRowView {
    /**
     * 
     * @type {string}
     * @memberof SimulationPLRowView
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof SimulationPLRowView
     */
    'accountName': string;
    /**
     * 
     * @type {Array<SimulationPLCellView>}
     * @memberof SimulationPLRowView
     */
    'cells': Array<SimulationPLCellView>;
    /**
     * 
     * @type {boolean}
     * @memberof SimulationPLRowView
     */
    'isLeaf': boolean;
    /**
     * 
     * @type {number}
     * @memberof SimulationPLRowView
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof SimulationPLRowView
     */
    'parentAccountId'?: string;
}
/**
 * 
 * @export
 * @interface SimulationPLTableView
 */
export interface SimulationPLTableView {
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationPLTableView
     */
    'columns': Array<string>;
    /**
     * 
     * @type {Array<SimulationPLRowView>}
     * @memberof SimulationPLTableView
     */
    'rows': Array<SimulationPLRowView>;
}
/**
 * 
 * @export
 * @interface SimulationPlanUpdateRequestBody
 */
export interface SimulationPlanUpdateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SimulationPlanUpdateRequestBody
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof SimulationPlanUpdateRequestBody
     */
    'planningProjectId': string;
}
/**
 * 
 * @export
 * @interface SimulationSlotCellView
 */
export interface SimulationSlotCellView {
    /**
     * 
     * @type {string}
     * @memberof SimulationSlotCellView
     */
    'columnId': string;
    /**
     * 
     * @type {number}
     * @memberof SimulationSlotCellView
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface SimulationSlotColumnView
 */
export interface SimulationSlotColumnView {
    /**
     * 
     * @type {string}
     * @memberof SimulationSlotColumnView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SimulationSlotColumnView
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface SimulationSlotRowUnitAmountView
 */
export interface SimulationSlotRowUnitAmountView {
    /**
     * 
     * @type {string}
     * @memberof SimulationSlotRowUnitAmountView
     */
    'itemId': string;
    /**
     * 
     * @type {number}
     * @memberof SimulationSlotRowUnitAmountView
     */
    'unitAmount': number;
}
/**
 * 
 * @export
 * @interface SimulationSlotRowView
 */
export interface SimulationSlotRowView {
    /**
     * 
     * @type {Array<SimulationSlotCellView>}
     * @memberof SimulationSlotRowView
     */
    'cells': Array<SimulationSlotCellView>;
    /**
     * 
     * @type {string}
     * @memberof SimulationSlotRowView
     */
    'departmentName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationSlotRowView
     */
    'dimensionTagNames': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SimulationSlotRowView
     */
    'isNewSlot': boolean;
    /**
     * 
     * @type {string}
     * @memberof SimulationSlotRowView
     */
    'simulatingSlotId': string;
    /**
     * 
     * @type {string}
     * @memberof SimulationSlotRowView
     */
    'slotCode': string;
    /**
     * 
     * @type {string}
     * @memberof SimulationSlotRowView
     */
    'slotName': string;
    /**
     * 
     * @type {string}
     * @memberof SimulationSlotRowView
     */
    'slotType': SimulationSlotRowViewSlotTypeEnum;
    /**
     * 
     * @type {Array<SimulationSlotRowUnitAmountView>}
     * @memberof SimulationSlotRowView
     */
    'unitAmounts': Array<SimulationSlotRowUnitAmountView>;
}

export const SimulationSlotRowViewSlotTypeEnum = {
    Hiring: 'HIRING',
    Resignation: 'RESIGNATION',
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    ReturnToWork: 'RETURN_TO_WORK'
} as const;

export type SimulationSlotRowViewSlotTypeEnum = typeof SimulationSlotRowViewSlotTypeEnum[keyof typeof SimulationSlotRowViewSlotTypeEnum];

/**
 * 
 * @export
 * @interface SimulationSlotTableView
 */
export interface SimulationSlotTableView {
    /**
     * 
     * @type {Array<SimulationSlotColumnView>}
     * @memberof SimulationSlotTableView
     */
    'columns': Array<SimulationSlotColumnView>;
    /**
     * 
     * @type {Array<SimulationSlotRowView>}
     * @memberof SimulationSlotTableView
     */
    'rows': Array<SimulationSlotRowView>;
}
/**
 * 
 * @export
 * @interface SimulationSubTotalRow
 */
export interface SimulationSubTotalRow {
    /**
     * 
     * @type {Array<EachWorkforceRow>}
     * @memberof SimulationSubTotalRow
     */
    'eachWorkforceRows': Array<EachWorkforceRow>;
    /**
     * 
     * @type {Array<SimulationTotalItem>}
     * @memberof SimulationSubTotalRow
     */
    'subTotalItems': Array<SimulationTotalItem>;
}
/**
 * 
 * @export
 * @interface SimulationTotalItem
 */
export interface SimulationTotalItem {
    /**
     * 
     * @type {number}
     * @memberof SimulationTotalItem
     */
    'sum': number;
    /**
     * 
     * @type {string}
     * @memberof SimulationTotalItem
     */
    'yearMonthId': string;
}
/**
 * 
 * @export
 * @interface SimulationWorkforcePlanningTableView
 */
export interface SimulationWorkforcePlanningTableView {
    /**
     * 
     * @type {SimulationSubTotalRow}
     * @memberof SimulationWorkforcePlanningTableView
     */
    'hiringSlotSubTotal': SimulationSubTotalRow;
    /**
     * 
     * @type {SimulationSubTotalRow}
     * @memberof SimulationWorkforcePlanningTableView
     */
    'leaveOfAbsenceSlotSubTotal': SimulationSubTotalRow;
    /**
     * 
     * @type {SimulationSubTotalRow}
     * @memberof SimulationWorkforcePlanningTableView
     */
    'personSubTotal': SimulationSubTotalRow;
    /**
     * 
     * @type {SimulationSubTotalRow}
     * @memberof SimulationWorkforcePlanningTableView
     */
    'resignationSlotSubTotal': SimulationSubTotalRow;
    /**
     * 
     * @type {SimulationSubTotalRow}
     * @memberof SimulationWorkforcePlanningTableView
     */
    'returnToWorkSlotSubTotal': SimulationSubTotalRow;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationWorkforcePlanningTableView
     */
    'targetYearMonths': Array<string>;
    /**
     * 
     * @type {Array<SimulationTotalItem>}
     * @memberof SimulationWorkforcePlanningTableView
     */
    'totalItems': Array<SimulationTotalItem>;
}
/**
 * 
 * @export
 * @interface SkippedAllocationStandardView
 */
export interface SkippedAllocationStandardView {
    /**
     * 
     * @type {string}
     * @memberof SkippedAllocationStandardView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SkippedAllocationStandardView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SkippedAllocationStepView
 */
export interface SkippedAllocationStepView {
    /**
     * 
     * @type {string}
     * @memberof SkippedAllocationStepView
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SkippedAllocationStepView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SkippedAllocationStepView
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SkippedAllocationStepView
     */
    'order': number;
    /**
     * 
     * @type {SkippedAllocationStandardView}
     * @memberof SkippedAllocationStepView
     */
    'standard'?: SkippedAllocationStandardView;
    /**
     * 
     * @type {string}
     * @memberof SkippedAllocationStepView
     */
    'stepType': SkippedAllocationStepViewStepTypeEnum;
}

export const SkippedAllocationStepViewStepTypeEnum = {
    Allocation: 'ALLOCATION',
    Transfer: 'TRANSFER'
} as const;

export type SkippedAllocationStepViewStepTypeEnum = typeof SkippedAllocationStepViewStepTypeEnum[keyof typeof SkippedAllocationStepViewStepTypeEnum];

/**
 * 
 * @export
 * @interface SkippedAllocationStepsSummaryView
 */
export interface SkippedAllocationStepsSummaryView {
    /**
     * 
     * @type {string}
     * @memberof SkippedAllocationStepsSummaryView
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof SkippedAllocationStepsSummaryView
     */
    'ruleName': string;
    /**
     * 
     * @type {Array<SkippedAllocationStepView>}
     * @memberof SkippedAllocationStepsSummaryView
     */
    'steps': Array<SkippedAllocationStepView>;
}
/**
 * 
 * @export
 * @interface SlotInfoView
 */
export interface SlotInfoView {
    /**
     * 
     * @type {Array<RemainingCountByYearMonth>}
     * @memberof SlotInfoView
     */
    'remainingCountByYearMonth': Array<RemainingCountByYearMonth>;
    /**
     * 
     * @type {number}
     * @memberof SlotInfoView
     */
    'totalRemainingCount': number;
}
/**
 * 
 * @export
 * @interface SlotUsageByMonthView
 */
export interface SlotUsageByMonthView {
    /**
     * 
     * @type {number}
     * @memberof SlotUsageByMonthView
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof SlotUsageByMonthView
     */
    'usedCount': number;
    /**
     * 
     * @type {string}
     * @memberof SlotUsageByMonthView
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface SocialLoginsDto
 */
export interface SocialLoginsDto {
    /**
     * 
     * @type {string}
     * @memberof SocialLoginsDto
     */
    'googleState': SocialLoginsDtoGoogleStateEnum;
}

export const SocialLoginsDtoGoogleStateEnum = {
    CanLink: 'CAN_LINK',
    CannotLink: 'CANNOT_LINK',
    Linked: 'LINKED'
} as const;

export type SocialLoginsDtoGoogleStateEnum = typeof SocialLoginsDtoGoogleStateEnum[keyof typeof SocialLoginsDtoGoogleStateEnum];

/**
 * 
 * @export
 * @interface SplitActionParams
 */
export interface SplitActionParams {
    /**
     * 
     * @type {string}
     * @memberof SplitActionParams
     */
    'delimiter': string;
    /**
     * 
     * @type {number}
     * @memberof SplitActionParams
     */
    'pickingNumber': number;
    /**
     * 
     * @type {string}
     * @memberof SplitActionParams
     */
    'target': string;
}
/**
 * 
 * @export
 * @interface SubAccountView
 */
export interface SubAccountView {
    /**
     * 
     * @type {string}
     * @memberof SubAccountView
     */
    'accountId': string;
    /**
     * 
     * @type {AccountingSystemCodeView}
     * @memberof SubAccountView
     */
    'accountingSystemCode'?: AccountingSystemCodeView;
    /**
     * 
     * @type {string}
     * @memberof SubAccountView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SubAccountView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SubAccountView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SubAccountView
     */
    'viewControlType': SubAccountViewViewControlTypeEnum;
}

export const SubAccountViewViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type SubAccountViewViewControlTypeEnum = typeof SubAccountViewViewControlTypeEnum[keyof typeof SubAccountViewViewControlTypeEnum];

/**
 * 
 * @export
 * @interface SubAccountsView
 */
export interface SubAccountsView {
    /**
     * 
     * @type {Array<SubAccountView>}
     * @memberof SubAccountsView
     */
    'value': Array<SubAccountView>;
}
/**
 * 
 * @export
 * @interface SubTotalRow
 */
export interface SubTotalRow {
    /**
     * 
     * @type {Array<EachWorkforceDiffRow>}
     * @memberof SubTotalRow
     */
    'eachWorkforceDiffRows': Array<EachWorkforceDiffRow>;
    /**
     * 
     * @type {Array<TotalItem>}
     * @memberof SubTotalRow
     */
    'subTotalItems': Array<TotalItem>;
}
/**
 * 
 * @export
 * @interface SumCellGroupView
 */
export interface SumCellGroupView {
    /**
     * 
     * @type {Array<SumCellView>}
     * @memberof SumCellGroupView
     */
    'cells': Array<SumCellView>;
}
/**
 * @type SumCellView
 * @export
 */
export type SumCellView = { type: 'COMPARISON' } & SumComparisonFormulaCellView | { type: 'DATA' } & SumDataCellView;

/**
 * 
 * @export
 * @interface SumComparisonFormulaCellView
 */
export interface SumComparisonFormulaCellView {
    /**
     * 
     * @type {EitherAmountOrErrorView}
     * @memberof SumComparisonFormulaCellView
     */
    'eitherAmountOrError'?: EitherAmountOrErrorView;
    /**
     * 
     * @type {string}
     * @memberof SumComparisonFormulaCellView
     */
    'id': string;
    /**
     * 
     * @type {CellGroupOrderKey}
     * @memberof SumComparisonFormulaCellView
     */
    'orderKey': CellGroupOrderKey;
    /**
     * 
     * @type {ReportCellType}
     * @memberof SumComparisonFormulaCellView
     */
    'type': ReportCellType;
}


/**
 * 
 * @export
 * @interface SumDataCellView
 */
export interface SumDataCellView {
    /**
     * 
     * @type {string}
     * @memberof SumDataCellView
     */
    'dataConditionId': SumDataCellViewDataConditionIdEnum;
    /**
     * 
     * @type {EitherAmountOrErrorView}
     * @memberof SumDataCellView
     */
    'eitherAmountOrError'?: EitherAmountOrErrorView;
    /**
     * 
     * @type {string}
     * @memberof SumDataCellView
     */
    'id': string;
    /**
     * 
     * @type {CellGroupOrderKey}
     * @memberof SumDataCellView
     */
    'orderKey': CellGroupOrderKey;
    /**
     * 
     * @type {ReportCellType}
     * @memberof SumDataCellView
     */
    'type': ReportCellType;
}

export const SumDataCellViewDataConditionIdEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;

export type SumDataCellViewDataConditionIdEnum = typeof SumDataCellViewDataConditionIdEnum[keyof typeof SumDataCellViewDataConditionIdEnum];

/**
 * 
 * @export
 * @interface SumDiffScaledView
 */
export interface SumDiffScaledView {
    /**
     * 
     * @type {string}
     * @memberof SumDiffScaledView
     */
    'actualSum'?: string;
    /**
     * 
     * @type {string}
     * @memberof SumDiffScaledView
     */
    'currentSum'?: string;
    /**
     * 
     * @type {string}
     * @memberof SumDiffScaledView
     */
    'diff'?: string;
    /**
     * 
     * @type {string}
     * @memberof SumDiffScaledView
     */
    'diffType': SumDiffScaledViewDiffTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SumDiffScaledView
     */
    'newSum'?: string;
}

export const SumDiffScaledViewDiffTypeEnum = {
    Increase: 'INCREASE',
    Decrease: 'DECREASE',
    NoChanges: 'NO_CHANGES',
    Undefined: 'UNDEFINED'
} as const;

export type SumDiffScaledViewDiffTypeEnum = typeof SumDiffScaledViewDiffTypeEnum[keyof typeof SumDiffScaledViewDiffTypeEnum];

/**
 * 
 * @export
 * @interface TableCell
 */
export interface TableCell {
    /**
     * 
     * @type {string}
     * @memberof TableCell
     */
    'highlightType': TableCellHighlightTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TableCell
     */
    'value': string;
}

export const TableCellHighlightTypeEnum = {
    None: 'NONE',
    Low: 'LOW',
    High: 'HIGH'
} as const;

export type TableCellHighlightTypeEnum = typeof TableCellHighlightTypeEnum[keyof typeof TableCellHighlightTypeEnum];

/**
 * 
 * @export
 * @interface TableProperties
 */
export interface TableProperties {
    /**
     * 
     * @type {Array<string>}
     * @memberof TableProperties
     */
    'headers': Array<string>;
    /**
     * 
     * @type {Array<TableRow>}
     * @memberof TableProperties
     */
    'rows': Array<TableRow>;
}
/**
 * 
 * @export
 * @interface TableRow
 */
export interface TableRow {
    /**
     * 
     * @type {Array<TableCell>}
     * @memberof TableRow
     */
    'cells': Array<TableCell>;
}
/**
 * 
 * @export
 * @interface TableauDashboardDto
 */
export interface TableauDashboardDto {
    /**
     * 
     * @type {string}
     * @memberof TableauDashboardDto
     */
    'dashboardUrl': string;
    /**
     * 
     * @type {string}
     * @memberof TableauDashboardDto
     */
    'targetAccountTreeId': string;
}
/**
 * 
 * @export
 * @interface TagView
 */
export interface TagView {
    /**
     * 
     * @type {string}
     * @memberof TagView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TagView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagView
     */
    'tagType': TagViewTagTypeEnum;
}

export const TagViewTagTypeEnum = {
    Item: 'ITEM',
    Section: 'SECTION',
    Memo: 'MEMO',
    Partner: 'PARTNER',
    SegmentOne: 'SEGMENT_ONE',
    SegmentTwo: 'SEGMENT_TWO',
    SegmentTree: 'SEGMENT_TREE'
} as const;

export type TagViewTagTypeEnum = typeof TagViewTagTypeEnum[keyof typeof TagViewTagTypeEnum];

/**
 * 
 * @export
 * @interface TargetAccountView
 */
export interface TargetAccountView {
    /**
     * 
     * @type {string}
     * @memberof TargetAccountView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TargetAccountView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TargetDepartment
 */
export interface TargetDepartment {
    /**
     * 
     * @type {string}
     * @memberof TargetDepartment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TargetDepartment
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TargetPlanningProject
 */
export interface TargetPlanningProject {
    /**
     * 
     * @type {string}
     * @memberof TargetPlanningProject
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TargetPlanningProject
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof TargetPlanningProject
     */
    'planningProjectName': string;
}
/**
 * 
 * @export
 * @interface TargetYearMonthRange
 */
export interface TargetYearMonthRange {
    /**
     * 
     * @type {string}
     * @memberof TargetYearMonthRange
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof TargetYearMonthRange
     */
    'start': string;
}
/**
 * 
 * @export
 * @interface TenantView
 */
export interface TenantView {
    /**
     * 
     * @type {boolean}
     * @memberof TenantView
     */
    'hasSamlConnection': boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TenantView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TotalItem
 */
export interface TotalItem {
    /**
     * 
     * @type {number}
     * @memberof TotalItem
     */
    'afterSum': number;
    /**
     * 
     * @type {number}
     * @memberof TotalItem
     */
    'beforeSum': number;
    /**
     * 
     * @type {number}
     * @memberof TotalItem
     */
    'diffAmount': number;
    /**
     * 
     * @type {string}
     * @memberof TotalItem
     */
    'yearMonthId': string;
}
/**
 * 
 * @export
 * @interface TransferStepView
 */
export interface TransferStepView {
    /**
     * 
     * @type {string}
     * @memberof TransferStepView
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferStepView
     */
    'destinationAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferStepView
     */
    'destinationDepartmentId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TransferStepView
     */
    'destinationDimensionDimensionTagIdMap': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TransferStepView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TransferStepView
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransferStepView
     */
    'sourceAccountIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransferStepView
     */
    'sourceDepartmentIds'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof TransferStepView
     */
    'sourceDimensionDimensionTagIdsMap': { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface TreeNodeDimensionTreeNodeForSelectionView
 */
export interface TreeNodeDimensionTreeNodeForSelectionView {
    /**
     * 
     * @type {Array<TreeNodeDimensionTreeNodeForSelectionView>}
     * @memberof TreeNodeDimensionTreeNodeForSelectionView
     */
    'children': Array<TreeNodeDimensionTreeNodeForSelectionView>;
    /**
     * 
     * @type {DimensionTreeNodeForSelectionView}
     * @memberof TreeNodeDimensionTreeNodeForSelectionView
     */
    'value': DimensionTreeNodeForSelectionView;
}
/**
 * 
 * @export
 * @interface TreeNodeReportFolderTreeNode
 */
export interface TreeNodeReportFolderTreeNode {
    /**
     * 
     * @type {ReportFolderTreeNode}
     * @memberof TreeNodeReportFolderTreeNode
     */
    'value': ReportFolderTreeNode;
}
/**
 * 
 * @export
 * @interface TreeNodeRowViewForTree
 */
export interface TreeNodeRowViewForTree {
    /**
     * 
     * @type {RowViewForTree}
     * @memberof TreeNodeRowViewForTree
     */
    'value': RowViewForTree;
}
/**
 * 
 * @export
 * @interface TreeNodeViewAccountTreeWithFormulaNodeDataView
 */
export interface TreeNodeViewAccountTreeWithFormulaNodeDataView {
    /**
     * 
     * @type {Array<TreeNodeViewAccountTreeWithFormulaNodeDataView>}
     * @memberof TreeNodeViewAccountTreeWithFormulaNodeDataView
     */
    'children': Array<TreeNodeViewAccountTreeWithFormulaNodeDataView>;
    /**
     * 
     * @type {AccountTreeWithFormulaNodeDataView}
     * @memberof TreeNodeViewAccountTreeWithFormulaNodeDataView
     */
    'value': AccountTreeWithFormulaNodeDataView;
}
/**
 * 
 * @export
 * @interface UnconnectedToSlotPersonView
 */
export interface UnconnectedToSlotPersonView {
    /**
     * 
     * @type {string}
     * @memberof UnconnectedToSlotPersonView
     */
    'actualChangeEventId': string;
    /**
     * 
     * @type {string}
     * @memberof UnconnectedToSlotPersonView
     */
    'departmentName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnconnectedToSlotPersonView
     */
    'dimensionTagNames': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UnconnectedToSlotPersonView
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof UnconnectedToSlotPersonView
     */
    'eventType': UnconnectedToSlotPersonViewEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UnconnectedToSlotPersonView
     */
    'personCode': string;
    /**
     * 
     * @type {string}
     * @memberof UnconnectedToSlotPersonView
     */
    'personName': string;
    /**
     * 
     * @type {string}
     * @memberof UnconnectedToSlotPersonView
     */
    'workforceId': string;
}

export const UnconnectedToSlotPersonViewEventTypeEnum = {
    Start: 'START',
    End: 'END',
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    ReturnToWork: 'RETURN_TO_WORK'
} as const;

export type UnconnectedToSlotPersonViewEventTypeEnum = typeof UnconnectedToSlotPersonViewEventTypeEnum[keyof typeof UnconnectedToSlotPersonViewEventTypeEnum];

/**
 * 
 * @export
 * @interface UniqueKeyView
 */
export interface UniqueKeyView {
    /**
     * 
     * @type {Meta}
     * @memberof UniqueKeyView
     */
    'meta': Meta;
    /**
     * 
     * @type {Array<ReportTableKeyItem>}
     * @memberof UniqueKeyView
     */
    'reportTableKey': Array<ReportTableKeyItem>;
}
/**
 * 
 * @export
 * @interface UnitAmountItemView
 */
export interface UnitAmountItemView {
    /**
     * 
     * @type {string}
     * @memberof UnitAmountItemView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UnitAmountItemView
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UnitAmountListItem
 */
export interface UnitAmountListItem {
    /**
     * 
     * @type {string}
     * @memberof UnitAmountListItem
     */
    'personCode': string;
    /**
     * 
     * @type {string}
     * @memberof UnitAmountListItem
     */
    'personFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof UnitAmountListItem
     */
    'personLastName': string;
    /**
     * 
     * @type {Array<UnitAmountListItemUnitAmount>}
     * @memberof UnitAmountListItem
     */
    'unitAmounts': Array<UnitAmountListItemUnitAmount>;
    /**
     * 
     * @type {string}
     * @memberof UnitAmountListItem
     */
    'workforceId': string;
}
/**
 * 
 * @export
 * @interface UnitAmountListItemUnitAmount
 */
export interface UnitAmountListItemUnitAmount {
    /**
     * 
     * @type {string}
     * @memberof UnitAmountListItemUnitAmount
     */
    'itemId': string;
    /**
     * 
     * @type {number}
     * @memberof UnitAmountListItemUnitAmount
     */
    'unitAmount': number;
}
/**
 * 
 * @export
 * @interface UnitAmountView
 */
export interface UnitAmountView {
    /**
     * 
     * @type {number}
     * @memberof UnitAmountView
     */
    'amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UnitAmountView
     */
    'isMasked': boolean;
    /**
     * 
     * @type {string}
     * @memberof UnitAmountView
     */
    'unitAmountItemId': string;
    /**
     * 
     * @type {string}
     * @memberof UnitAmountView
     */
    'unitAmountItemName': string;
}
/**
 * 
 * @export
 * @interface UpdateAccountGroupRequestBody
 */
export interface UpdateAccountGroupRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountGroupRequestBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountGroupRequestBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateAccountRequestBody
 */
export interface UpdateAccountRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequestBody
     */
    'accountType': UpdateAccountRequestBodyAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequestBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequestBody
     */
    'externalSystemAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequestBody
     */
    'externalSystemSubAccountCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequestBody
     */
    'roundingPrecision'?: UpdateAccountRequestBodyRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequestBody
     */
    'valueFormat'?: UpdateAccountRequestBodyValueFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequestBody
     */
    'viewControlType': UpdateAccountRequestBodyViewControlTypeEnum;
}

export const UpdateAccountRequestBodyAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type UpdateAccountRequestBodyAccountTypeEnum = typeof UpdateAccountRequestBodyAccountTypeEnum[keyof typeof UpdateAccountRequestBodyAccountTypeEnum];
export const UpdateAccountRequestBodyRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type UpdateAccountRequestBodyRoundingPrecisionEnum = typeof UpdateAccountRequestBodyRoundingPrecisionEnum[keyof typeof UpdateAccountRequestBodyRoundingPrecisionEnum];
export const UpdateAccountRequestBodyValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type UpdateAccountRequestBodyValueFormatEnum = typeof UpdateAccountRequestBodyValueFormatEnum[keyof typeof UpdateAccountRequestBodyValueFormatEnum];
export const UpdateAccountRequestBodyViewControlTypeEnum = {
    Public: 'PUBLIC',
    Limited: 'LIMITED'
} as const;

export type UpdateAccountRequestBodyViewControlTypeEnum = typeof UpdateAccountRequestBodyViewControlTypeEnum[keyof typeof UpdateAccountRequestBodyViewControlTypeEnum];

/**
 * 
 * @export
 * @interface UpdateAccountTypeWithIdRequestBody
 */
export interface UpdateAccountTypeWithIdRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountTypeWithIdRequestBody
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountTypeWithIdRequestBody
     */
    'accountType': UpdateAccountTypeWithIdRequestBodyAccountTypeEnum;
}

export const UpdateAccountTypeWithIdRequestBodyAccountTypeEnum = {
    Unknown: 'UNKNOWN',
    Expense: 'EXPENSE',
    Income: 'INCOME',
    BsAccount: 'BS_ACCOUNT',
    Assets: 'ASSETS',
    Liabilities: 'LIABILITIES',
    NetAssets: 'NET_ASSETS',
    NonFinancial: 'NON_FINANCIAL',
    StandardPlAggrExpense: 'STANDARD_PL_AGGR_EXPENSE',
    StandardPlAggrIncome: 'STANDARD_PL_AGGR_INCOME'
} as const;

export type UpdateAccountTypeWithIdRequestBodyAccountTypeEnum = typeof UpdateAccountTypeWithIdRequestBodyAccountTypeEnum[keyof typeof UpdateAccountTypeWithIdRequestBodyAccountTypeEnum];

/**
 * 
 * @export
 * @interface UpdateActualsImportMappingDebitCreditConditionParams
 */
export interface UpdateActualsImportMappingDebitCreditConditionParams {
    /**
     * 
     * @type {number}
     * @memberof UpdateActualsImportMappingDebitCreditConditionParams
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingDebitCreditConditionParams
     */
    'conditionType': UpdateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingDebitCreditConditionParams
     */
    'conditionValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingDebitCreditConditionParams
     */
    'debitCredit': UpdateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum;
}

export const UpdateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Entered: 'ENTERED',
    NotEntered: 'NOT_ENTERED'
} as const;

export type UpdateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum = typeof UpdateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum[keyof typeof UpdateActualsImportMappingDebitCreditConditionParamsConditionTypeEnum];
export const UpdateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum = {
    Debit: 'DEBIT',
    Credit: 'CREDIT'
} as const;

export type UpdateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum = typeof UpdateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum[keyof typeof UpdateActualsImportMappingDebitCreditConditionParamsDebitCreditEnum];

/**
 * 
 * @export
 * @interface UpdateActualsImportMappingFilterParams
 */
export interface UpdateActualsImportMappingFilterParams {
    /**
     * 
     * @type {number}
     * @memberof UpdateActualsImportMappingFilterParams
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingFilterParams
     */
    'conditionType': UpdateActualsImportMappingFilterParamsConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingFilterParams
     */
    'conditionValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingFilterParams
     */
    'targetType': UpdateActualsImportMappingFilterParamsTargetTypeEnum;
}

export const UpdateActualsImportMappingFilterParamsConditionTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Entered: 'ENTERED',
    NotEntered: 'NOT_ENTERED'
} as const;

export type UpdateActualsImportMappingFilterParamsConditionTypeEnum = typeof UpdateActualsImportMappingFilterParamsConditionTypeEnum[keyof typeof UpdateActualsImportMappingFilterParamsConditionTypeEnum];
export const UpdateActualsImportMappingFilterParamsTargetTypeEnum = {
    Row: 'ROW',
    Column: 'COLUMN'
} as const;

export type UpdateActualsImportMappingFilterParamsTargetTypeEnum = typeof UpdateActualsImportMappingFilterParamsTargetTypeEnum[keyof typeof UpdateActualsImportMappingFilterParamsTargetTypeEnum];

/**
 * 
 * @export
 * @interface UpdateActualsImportMappingItemParams
 */
export interface UpdateActualsImportMappingItemParams {
    /**
     * 
     * @type {number}
     * @memberof UpdateActualsImportMappingItemParams
     */
    'columnIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingItemParams
     */
    'dimensionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingItemParams
     */
    'type': UpdateActualsImportMappingItemParamsTypeEnum;
}

export const UpdateActualsImportMappingItemParamsTypeEnum = {
    Date: 'DATE',
    DebitAmount: 'DEBIT_AMOUNT',
    CreditAmount: 'CREDIT_AMOUNT',
    DebitAccountName: 'DEBIT_ACCOUNT_NAME',
    CreditAccountName: 'CREDIT_ACCOUNT_NAME',
    DocumentId: 'DOCUMENT_ID',
    Description: 'DESCRIPTION',
    DebitAccountExCode: 'DEBIT_ACCOUNT_EX_CODE',
    CreditAccountExCode: 'CREDIT_ACCOUNT_EX_CODE',
    DebitDepartmentName: 'DEBIT_DEPARTMENT_NAME',
    CreditDepartmentName: 'CREDIT_DEPARTMENT_NAME',
    DebitDepartmentExCode: 'DEBIT_DEPARTMENT_EX_CODE',
    CreditDepartmentExCode: 'CREDIT_DEPARTMENT_EX_CODE',
    DebitSubAccountName: 'DEBIT_SUB_ACCOUNT_NAME',
    CreditSubAccountName: 'CREDIT_SUB_ACCOUNT_NAME',
    DebitSubAccountExCode: 'DEBIT_SUB_ACCOUNT_EX_CODE',
    CreditSubAccountExCode: 'CREDIT_SUB_ACCOUNT_EX_CODE',
    DebitSupplierName: 'DEBIT_SUPPLIER_NAME',
    CreditSupplierName: 'CREDIT_SUPPLIER_NAME',
    DebitSupplierExCode: 'DEBIT_SUPPLIER_EX_CODE',
    CreditSupplierExCode: 'CREDIT_SUPPLIER_EX_CODE',
    DebitDimensionName: 'DEBIT_DIMENSION_NAME',
    CreditDimensionName: 'CREDIT_DIMENSION_NAME',
    Amount: 'AMOUNT',
    AccountName: 'ACCOUNT_NAME',
    AccountExCode: 'ACCOUNT_EX_CODE',
    DepartmentName: 'DEPARTMENT_NAME',
    DepartmentExCode: 'DEPARTMENT_EX_CODE',
    SubAccountName: 'SUB_ACCOUNT_NAME',
    SubAccountExCode: 'SUB_ACCOUNT_EX_CODE',
    SupplierName: 'SUPPLIER_NAME',
    SupplierExCode: 'SUPPLIER_EX_CODE',
    DimensionName: 'DIMENSION_NAME'
} as const;

export type UpdateActualsImportMappingItemParamsTypeEnum = typeof UpdateActualsImportMappingItemParamsTypeEnum[keyof typeof UpdateActualsImportMappingItemParamsTypeEnum];

/**
 * 
 * @export
 * @interface UpdateActualsImportMappingParams
 */
export interface UpdateActualsImportMappingParams {
    /**
     * 
     * @type {Array<UpdateActualsImportMappingFilterParams>}
     * @memberof UpdateActualsImportMappingParams
     */
    'actualImportMappingFilters': Array<UpdateActualsImportMappingFilterParams>;
    /**
     * 
     * @type {Array<UpdateActualsImportMappingItemParams>}
     * @memberof UpdateActualsImportMappingParams
     */
    'actualImportMappingItems': Array<UpdateActualsImportMappingItemParams>;
    /**
     * 
     * @type {Array<UpdateActualsImportMappingSubsidiaryCodeParams>}
     * @memberof UpdateActualsImportMappingParams
     */
    'actualImportMappingSubsidiaryCodes': Array<UpdateActualsImportMappingSubsidiaryCodeParams>;
    /**
     * 
     * @type {Array<UpdateActualsImportMappingDebitCreditConditionParams>}
     * @memberof UpdateActualsImportMappingParams
     */
    'actualsImportMappingDebitCreditConditions': Array<UpdateActualsImportMappingDebitCreditConditionParams>;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingParams
     */
    'amountDataDirection': UpdateActualsImportMappingParamsAmountDataDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateActualsImportMappingParams
     */
    'headerRowIndex': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingParams
     */
    'journalOrBalance': UpdateActualsImportMappingParamsJournalOrBalanceEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingParams
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateActualsImportMappingParams
     */
    'processSettingIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingParams
     */
    'repeatColumnHeaderType'?: UpdateActualsImportMappingParamsRepeatColumnHeaderTypeEnum;
}

export const UpdateActualsImportMappingParamsAmountDataDirectionEnum = {
    RowColumn: 'ROW_COLUMN',
    Column: 'COLUMN'
} as const;

export type UpdateActualsImportMappingParamsAmountDataDirectionEnum = typeof UpdateActualsImportMappingParamsAmountDataDirectionEnum[keyof typeof UpdateActualsImportMappingParamsAmountDataDirectionEnum];
export const UpdateActualsImportMappingParamsJournalOrBalanceEnum = {
    Journal: 'JOURNAL',
    Balance: 'BALANCE'
} as const;

export type UpdateActualsImportMappingParamsJournalOrBalanceEnum = typeof UpdateActualsImportMappingParamsJournalOrBalanceEnum[keyof typeof UpdateActualsImportMappingParamsJournalOrBalanceEnum];
export const UpdateActualsImportMappingParamsRepeatColumnHeaderTypeEnum = {
    Date: 'DATE',
    Account: 'ACCOUNT',
    Department: 'DEPARTMENT'
} as const;

export type UpdateActualsImportMappingParamsRepeatColumnHeaderTypeEnum = typeof UpdateActualsImportMappingParamsRepeatColumnHeaderTypeEnum[keyof typeof UpdateActualsImportMappingParamsRepeatColumnHeaderTypeEnum];

/**
 * 
 * @export
 * @interface UpdateActualsImportMappingSubsidiaryCodeParams
 */
export interface UpdateActualsImportMappingSubsidiaryCodeParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingSubsidiaryCodeParams
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateActualsImportMappingSubsidiaryCodeParams
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
 */
export interface UpdateAiPlanActualAnalysisReportConditionTreeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'accountTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'factorAnalysisType1': UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyFactorAnalysisType1Enum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'orgTreeId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'parentId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'planMasterId': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'requireAllocatedItems': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'showNumbersIn': UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyShowNumbersInEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeRequestBody
     */
    'targetYearMonth': string;
}

export const UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyFactorAnalysisType1Enum = {
    Department: 'DEPARTMENT',
    Account: 'ACCOUNT'
} as const;

export type UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyFactorAnalysisType1Enum = typeof UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyFactorAnalysisType1Enum[keyof typeof UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyFactorAnalysisType1Enum];
export const UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyShowNumbersInEnum = typeof UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyShowNumbersInEnum[keyof typeof UpdateAiPlanActualAnalysisReportConditionTreeRequestBodyShowNumbersInEnum];

/**
 * 
 * @export
 * @interface UpdateAiPlanActualAnalysisReportConditionTreeResponse
 */
export interface UpdateAiPlanActualAnalysisReportConditionTreeResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateAiPlanActualAnalysisReportConditionTreeResponse
     */
    'newNodeId': string;
}
/**
 * 
 * @export
 * @interface UpdateAllocationRuleRequestBody
 */
export interface UpdateAllocationRuleRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationRuleRequestBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateAllocationStepOrderRequestBody
 */
export interface UpdateAllocationStepOrderRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAllocationStepOrderRequestBody
     */
    'items': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateAllocationStepRequestBody
 */
export interface UpdateAllocationStepRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationStepRequestBody
     */
    'allocationStandardId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationStepRequestBody
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationStepRequestBody
     */
    'destinationAccountId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAllocationStepRequestBody
     */
    'destinationDepartmentIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAllocationStepRequestBody
     */
    'destinationDimensionTagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationStepRequestBody
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAllocationStepRequestBody
     */
    'sourceAccountIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAllocationStepRequestBody
     */
    'sourceDepartmentIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAllocationStepRequestBody
     */
    'sourceDimensionTagIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationStepRequestBody
     */
    'stepType': UpdateAllocationStepRequestBodyStepTypeEnum;
}

export const UpdateAllocationStepRequestBodyStepTypeEnum = {
    Allocation: 'ALLOCATION',
    Transfer: 'TRANSFER'
} as const;

export type UpdateAllocationStepRequestBodyStepTypeEnum = typeof UpdateAllocationStepRequestBodyStepTypeEnum[keyof typeof UpdateAllocationStepRequestBodyStepTypeEnum];

/**
 * 
 * @export
 * @interface UpdateAllocationStrategyRequestBody
 */
export interface UpdateAllocationStrategyRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationStrategyRequestBody
     */
    'planId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationStrategyRequestBody
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationStrategyRequestBody
     */
    'targetType': UpdateAllocationStrategyRequestBodyTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationStrategyRequestBody
     */
    'yearMonth': string;
}

export const UpdateAllocationStrategyRequestBodyTargetTypeEnum = {
    Actual: 'ACTUAL',
    Plan: 'PLAN'
} as const;

export type UpdateAllocationStrategyRequestBodyTargetTypeEnum = typeof UpdateAllocationStrategyRequestBodyTargetTypeEnum[keyof typeof UpdateAllocationStrategyRequestBodyTargetTypeEnum];

/**
 * 
 * @export
 * @interface UpdateAssignmentsParam
 */
export interface UpdateAssignmentsParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAssignmentsParam
     */
    'departmentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateBSReportConditionBody
 */
export interface UpdateBSReportConditionBody {
    /**
     * 
     * @type {BSReportConditionSchema}
     * @memberof UpdateBSReportConditionBody
     */
    'condition': BSReportConditionSchema;
}
/**
 * 
 * @export
 * @interface UpdateCalculatedAccountRequestBody
 */
export interface UpdateCalculatedAccountRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculatedAccountRequestBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculatedAccountRequestBody
     */
    'formula': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculatedAccountRequestBody
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculatedAccountRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculatedAccountRequestBody
     */
    'roundingPrecision': UpdateCalculatedAccountRequestBodyRoundingPrecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalculatedAccountRequestBody
     */
    'valueFormat': UpdateCalculatedAccountRequestBodyValueFormatEnum;
}

export const UpdateCalculatedAccountRequestBodyRoundingPrecisionEnum = {
    None: 'NONE',
    ToOneDecimal: 'TO_ONE_DECIMAL',
    ToTwoDecimals: 'TO_TWO_DECIMALS',
    ToThreeDecimals: 'TO_THREE_DECIMALS',
    ToFourDecimals: 'TO_FOUR_DECIMALS'
} as const;

export type UpdateCalculatedAccountRequestBodyRoundingPrecisionEnum = typeof UpdateCalculatedAccountRequestBodyRoundingPrecisionEnum[keyof typeof UpdateCalculatedAccountRequestBodyRoundingPrecisionEnum];
export const UpdateCalculatedAccountRequestBodyValueFormatEnum = {
    Amount: 'AMOUNT',
    Percentage: 'PERCENTAGE',
    Other: 'OTHER'
} as const;

export type UpdateCalculatedAccountRequestBodyValueFormatEnum = typeof UpdateCalculatedAccountRequestBodyValueFormatEnum[keyof typeof UpdateCalculatedAccountRequestBodyValueFormatEnum];

/**
 * 
 * @export
 * @interface UpdateDepartmentParam
 */
export interface UpdateDepartmentParam {
    /**
     * 
     * @type {Array<AssignUserDepartmentParam>}
     * @memberof UpdateDepartmentParam
     */
    'assignments'?: Array<AssignUserDepartmentParam>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDepartmentParam
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDepartmentParam
     */
    'externalSystemDepartmentCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDepartmentParam
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDimensionTagsRequestBody
 */
export interface UpdateDimensionTagsRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateDimensionTagsRequestBody
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDimensionTagsRequestBody
     */
    'dimensionTagCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDimensionTagsRequestBody
     */
    'dimensionTagName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDimensionTagsRequestBody
     */
    'exCodes': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateDimensionTreeNameBody
 */
export interface UpdateDimensionTreeNameBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateDimensionTreeNameBody
     */
    'dimensionTreeName': string;
}
/**
 * 
 * @export
 * @interface UpdateExchangeRateValueRequestParam
 */
export interface UpdateExchangeRateValueRequestParam {
    /**
     * 
     * @type {Array<ExchangeRateCurrencyDataParam>}
     * @memberof UpdateExchangeRateValueRequestParam
     */
    'data': Array<ExchangeRateCurrencyDataParam>;
    /**
     * 
     * @type {string}
     * @memberof UpdateExchangeRateValueRequestParam
     */
    'financialReportType': UpdateExchangeRateValueRequestParamFinancialReportTypeEnum;
}

export const UpdateExchangeRateValueRequestParamFinancialReportTypeEnum = {
    Pl: 'PL',
    Bs: 'BS'
} as const;

export type UpdateExchangeRateValueRequestParamFinancialReportTypeEnum = typeof UpdateExchangeRateValueRequestParamFinancialReportTypeEnum[keyof typeof UpdateExchangeRateValueRequestParamFinancialReportTypeEnum];

/**
 * 
 * @export
 * @interface UpdateFinancialModelDetailRequest
 */
export interface UpdateFinancialModelDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFinancialModelDetailRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Array<UpdateFinancialModelDetailRequestElement>}
     * @memberof UpdateFinancialModelDetailRequest
     */
    'formulas': Array<UpdateFinancialModelDetailRequestElement>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFinancialModelDetailRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateFinancialModelDetailRequestElement
 */
export interface UpdateFinancialModelDetailRequestElement {
    /**
     * 
     * @type {string}
     * @memberof UpdateFinancialModelDetailRequestElement
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFinancialModelDetailRequestElement
     */
    'formula': string;
}
/**
 * 
 * @export
 * @interface UpdateOrgTreeNameBody
 */
export interface UpdateOrgTreeNameBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrgTreeNameBody
     */
    'orgTreeName': string;
}
/**
 * 
 * @export
 * @interface UpdatePersonNameAndCodeRequestBody
 */
export interface UpdatePersonNameAndCodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonNameAndCodeRequestBody
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonNameAndCodeRequestBody
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonNameAndCodeRequestBody
     */
    'personCode': string;
}
/**
 * 
 * @export
 * @interface UpdatePlanMaster
 */
export interface UpdatePlanMaster {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanMaster
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanMaster
     */
    'planType'?: UpdatePlanMasterPlanTypeEnum;
}

export const UpdatePlanMasterPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type UpdatePlanMasterPlanTypeEnum = typeof UpdatePlanMasterPlanTypeEnum[keyof typeof UpdatePlanMasterPlanTypeEnum];

/**
 * 
 * @export
 * @interface UpdatePlanUpdateRequestFormatParams
 */
export interface UpdatePlanUpdateRequestFormatParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePlanUpdateRequestFormatParams
     */
    'downloadableDepartments': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanUpdateRequestFormatParams
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdatePlanUpdateRequestMappingAccountKeyParams
 */
export interface UpdatePlanUpdateRequestMappingAccountKeyParams {
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof UpdatePlanUpdateRequestMappingAccountKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanUpdateRequestMappingAccountKeyParams
     */
    'valueType': UpdatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum;
}

export const UpdatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type UpdatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum = typeof UpdatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum[keyof typeof UpdatePlanUpdateRequestMappingAccountKeyParamsValueTypeEnum];

/**
 * 
 * @export
 * @interface UpdatePlanUpdateRequestMappingAmountParams
 */
export interface UpdatePlanUpdateRequestMappingAmountParams {
    /**
     * 
     * @type {number}
     * @memberof UpdatePlanUpdateRequestMappingAmountParams
     */
    'endColumnIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlanUpdateRequestMappingAmountParams
     */
    'endRowIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlanUpdateRequestMappingAmountParams
     */
    'startColumnIndex': number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlanUpdateRequestMappingAmountParams
     */
    'startRowIndex': number;
}
/**
 * 
 * @export
 * @interface UpdatePlanUpdateRequestMappingDepartmentKeyParams
 */
export interface UpdatePlanUpdateRequestMappingDepartmentKeyParams {
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof UpdatePlanUpdateRequestMappingDepartmentKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanUpdateRequestMappingDepartmentKeyParams
     */
    'valueType': UpdatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum;
}

export const UpdatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type UpdatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum = typeof UpdatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum[keyof typeof UpdatePlanUpdateRequestMappingDepartmentKeyParamsValueTypeEnum];

/**
 * 
 * @export
 * @interface UpdatePlanUpdateRequestMappingDescriptionKeyParams
 */
export interface UpdatePlanUpdateRequestMappingDescriptionKeyParams {
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof UpdatePlanUpdateRequestMappingDescriptionKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
}
/**
 * 
 * @export
 * @interface UpdatePlanUpdateRequestMappingDimensionKeyParams
 */
export interface UpdatePlanUpdateRequestMappingDimensionKeyParams {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanUpdateRequestMappingDimensionKeyParams
     */
    'dimensionId': string;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof UpdatePlanUpdateRequestMappingDimensionKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanUpdateRequestMappingDimensionKeyParams
     */
    'valueType': UpdatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum;
}

export const UpdatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum = {
    Id: 'ID',
    Name: 'NAME',
    Code: 'CODE',
    ExCode: 'EX_CODE'
} as const;

export type UpdatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum = typeof UpdatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum[keyof typeof UpdatePlanUpdateRequestMappingDimensionKeyParamsValueTypeEnum];

/**
 * 
 * @export
 * @interface UpdatePlanUpdateRequestMappingFilterParams
 */
export interface UpdatePlanUpdateRequestMappingFilterParams {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanUpdateRequestMappingFilterParams
     */
    'conditionType': UpdatePlanUpdateRequestMappingFilterParamsConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanUpdateRequestMappingFilterParams
     */
    'conditionValue': string;
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof UpdatePlanUpdateRequestMappingFilterParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
}

export const UpdatePlanUpdateRequestMappingFilterParamsConditionTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Empty: 'EMPTY',
    NotEmpty: 'NOT_EMPTY'
} as const;

export type UpdatePlanUpdateRequestMappingFilterParamsConditionTypeEnum = typeof UpdatePlanUpdateRequestMappingFilterParamsConditionTypeEnum[keyof typeof UpdatePlanUpdateRequestMappingFilterParamsConditionTypeEnum];

/**
 * 
 * @export
 * @interface UpdatePlanUpdateRequestMappingParams
 */
export interface UpdatePlanUpdateRequestMappingParams {
    /**
     * 
     * @type {UpdatePlanUpdateRequestMappingAccountKeyParams}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'accountKey': UpdatePlanUpdateRequestMappingAccountKeyParams;
    /**
     * 
     * @type {UpdatePlanUpdateRequestMappingAmountParams}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'amountParams': UpdatePlanUpdateRequestMappingAmountParams;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'canUseForDownload': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'canUseForUpload': boolean;
    /**
     * 
     * @type {UpdatePlanUpdateRequestMappingDepartmentKeyParams}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'departmentKey': UpdatePlanUpdateRequestMappingDepartmentKeyParams;
    /**
     * 
     * @type {UpdatePlanUpdateRequestMappingDescriptionKeyParams}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'descriptionKey'?: UpdatePlanUpdateRequestMappingDescriptionKeyParams;
    /**
     * 
     * @type {Array<UpdatePlanUpdateRequestMappingDimensionKeyParams>}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'dimensionKeys': Array<UpdatePlanUpdateRequestMappingDimensionKeyParams>;
    /**
     * 
     * @type {Array<UpdatePlanUpdateRequestMappingFilterParams>}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'filters': Array<UpdatePlanUpdateRequestMappingFilterParams>;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'planUpdateRequestMappingUniqueKey'?: string;
    /**
     * 
     * @type {UpdatePlanUpdateRequestMappingYearMonthKeyParams}
     * @memberof UpdatePlanUpdateRequestMappingParams
     */
    'yearMonthKey': UpdatePlanUpdateRequestMappingYearMonthKeyParams;
}
/**
 * 
 * @export
 * @interface UpdatePlanUpdateRequestMappingYearMonthKeyParams
 */
export interface UpdatePlanUpdateRequestMappingYearMonthKeyParams {
    /**
     * 
     * @type {PlanUpdateRequestMappingAddress}
     * @memberof UpdatePlanUpdateRequestMappingYearMonthKeyParams
     */
    'mappedTo': PlanUpdateRequestMappingAddress;
}
/**
 * 
 * @export
 * @interface UpdatePlanningProjectParam
 */
export interface UpdatePlanningProjectParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePlanningProjectParam
     */
    'assignedDepartments'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanningProjectParam
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePlanningProjectParam
     */
    'requirePlanMasterAllocatedItems'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanningProjectParam
     */
    'scheduledPlanningProjectEndAt'?: string;
}
/**
 * 
 * @export
 * @interface UpdateReportConditionBody
 */
export interface UpdateReportConditionBody {
    /**
     * 
     * @type {PLReportConditionSchema}
     * @memberof UpdateReportConditionBody
     */
    'condition': PLReportConditionSchema;
}
/**
 * 
 * @export
 * @interface UpdateReportEditRestrictedBody
 */
export interface UpdateReportEditRestrictedBody {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateReportEditRestrictedBody
     */
    'isEditRestricted': boolean;
}
/**
 * 
 * @export
 * @interface UpdateReportNameBody
 */
export interface UpdateReportNameBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateReportNameBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateSettingsParam
 */
export interface UpdateSettingsParam {
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingsParam
     */
    'defaultDepartmentIdWhenImportActual'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSettingsParam
     */
    'enableExcelSheetProtectionForPlanUpdateRequest': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSettingsParam
     */
    'enableMasterAutoGenerate': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingsParam
     */
    'externalSystemCode': UpdateSettingsParamExternalSystemCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingsParam
     */
    'firstViewPage': UpdateSettingsParamFirstViewPageEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateSettingsParam
     */
    'fiscalYearStartMonth'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSettingsParam
     */
    'hideAccountsIfEmptyOnPl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSettingsParam
     */
    'isActiveEmailNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSettingsParam
     */
    'limitPermissionToAddToNewSpreadsheet'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingsParam
     */
    'roundingMode'?: UpdateSettingsParamRoundingModeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingsParam
     */
    'showNumbersIn'?: UpdateSettingsParamShowNumbersInEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingsParam
     */
    'tableauDashboardUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingsParam
     */
    'tableauTargetAccountTreeId'?: string;
}

export const UpdateSettingsParamExternalSystemCodeEnum = {
    Miroku: 'MIROKU',
    KanjoBugyo: 'KANJO_BUGYO',
    MoneyForward: 'MONEY_FORWARD',
    MoneyForwardPlus: 'MONEY_FORWARD_PLUS',
    Freee: 'FREEE',
    FreeeV2: 'FREEE_V2',
    Obic7: 'OBIC7',
    Yayoi: 'YAYOI',
    Sap: 'SAP',
    SapSalesReport: 'SAP_SALES_REPORT',
    Pca: 'PCA',
    NetSuite: 'NET_SUITE',
    Fx4: 'FX4',
    Fx5: 'FX5',
    BizIntegral: 'BIZ_INTEGRAL',
    Ai21: 'AI21',
    LoglassDepartments: 'LOGLASS_DEPARTMENTS',
    LoglassDetail: 'LOGLASS_DETAIL',
    SuperStream: 'SUPER_STREAM',
    LoglassJournal: 'LOGLASS_JOURNAL',
    Loglass: 'LOGLASS',
    Mapping: 'MAPPING'
} as const;

export type UpdateSettingsParamExternalSystemCodeEnum = typeof UpdateSettingsParamExternalSystemCodeEnum[keyof typeof UpdateSettingsParamExternalSystemCodeEnum];
export const UpdateSettingsParamFirstViewPageEnum = {
    FinancialSheetHistory: 'FINANCIAL_SHEET_HISTORY',
    Reports: 'REPORTS'
} as const;

export type UpdateSettingsParamFirstViewPageEnum = typeof UpdateSettingsParamFirstViewPageEnum[keyof typeof UpdateSettingsParamFirstViewPageEnum];
export const UpdateSettingsParamRoundingModeEnum = {
    Down: 'DOWN',
    HalfUp: 'HALF_UP'
} as const;

export type UpdateSettingsParamRoundingModeEnum = typeof UpdateSettingsParamRoundingModeEnum[keyof typeof UpdateSettingsParamRoundingModeEnum];
export const UpdateSettingsParamShowNumbersInEnum = {
    One: 'ONE',
    Thousand: 'THOUSAND',
    Million: 'MILLION',
    Billion: 'BILLION'
} as const;

export type UpdateSettingsParamShowNumbersInEnum = typeof UpdateSettingsParamShowNumbersInEnum[keyof typeof UpdateSettingsParamShowNumbersInEnum];

/**
 * 
 * @export
 * @interface UpdateSimulationBody
 */
export interface UpdateSimulationBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateSimulationBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateTransferStepRequestBody
 */
export interface UpdateTransferStepRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateTransferStepRequestBody
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransferStepRequestBody
     */
    'destinationAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransferStepRequestBody
     */
    'destinationDepartmentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTransferStepRequestBody
     */
    'destinationDimensionTagIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransferStepRequestBody
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTransferStepRequestBody
     */
    'sourceAccountIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTransferStepRequestBody
     */
    'sourceDepartmentIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTransferStepRequestBody
     */
    'sourceDimensionTagIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateViewPermissionParams
 */
export interface UpdateViewPermissionParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateViewPermissionParams
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateViewPermissionParams
     */
    'selectedAccounts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateViewPermissionParams
     */
    'selectedCalculatedAccounts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateViewPermissionParams
     */
    'users': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewPermissionParams
     */
    'viewableAccountScope': UpdateViewPermissionParamsViewableAccountScopeEnum;
}

export const UpdateViewPermissionParamsViewableAccountScopeEnum = {
    All: 'ALL',
    Selected: 'SELECTED'
} as const;

export type UpdateViewPermissionParamsViewableAccountScopeEnum = typeof UpdateViewPermissionParamsViewableAccountScopeEnum[keyof typeof UpdateViewPermissionParamsViewableAccountScopeEnum];

/**
 * 
 * @export
 * @interface UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody
 */
export interface UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody {
    /**
     * 
     * @type {Array<IncreaseRateForEachMonthView>}
     * @memberof UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody
     */
    'increaseRates': Array<IncreaseRateForEachMonthView>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody
     */
    'targetAccounts': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateWorkforceSimulationSlotBody
 */
export interface UpdateWorkforceSimulationSlotBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkforceSimulationSlotBody
     */
    'departmentId': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateWorkforceSimulationSlotBody
     */
    'dimensionIdToTagIdMap': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkforceSimulationSlotBody
     */
    'simulatingSlotId': string;
    /**
     * 
     * @type {Array<WorkforceSimulationSlotBodyUnitAmountItem>}
     * @memberof UpdateWorkforceSimulationSlotBody
     */
    'unitAmountItems': Array<WorkforceSimulationSlotBodyUnitAmountItem>;
}
/**
 * 
 * @export
 * @interface UpdateWorkforceSlotNameAndCodeRequestBody
 */
export interface UpdateWorkforceSlotNameAndCodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkforceSlotNameAndCodeRequestBody
     */
    'slotType': UpdateWorkforceSlotNameAndCodeRequestBodySlotTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkforceSlotNameAndCodeRequestBody
     */
    'workforceSlotCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkforceSlotNameAndCodeRequestBody
     */
    'workforceSlotName': string;
}

export const UpdateWorkforceSlotNameAndCodeRequestBodySlotTypeEnum = {
    Hiring: 'HIRING',
    Resignation: 'RESIGNATION',
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    ReturnToWork: 'RETURN_TO_WORK'
} as const;

export type UpdateWorkforceSlotNameAndCodeRequestBodySlotTypeEnum = typeof UpdateWorkforceSlotNameAndCodeRequestBodySlotTypeEnum[keyof typeof UpdateWorkforceSlotNameAndCodeRequestBodySlotTypeEnum];

/**
 * 
 * @export
 * @interface UploadActualByMappingParams
 */
export interface UploadActualByMappingParams {
    /**
     * 
     * @type {string}
     * @memberof UploadActualByMappingParams
     */
    'actualImportMappingId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadActualByMappingParams
     */
    'processSettingId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadActualByMappingParams
     */
    'subsidiaryCode': string;
}
/**
 * 
 * @export
 * @interface UploadExcelPlanUpdateParams
 */
export interface UploadExcelPlanUpdateParams {
    /**
     * 
     * @type {Array<MappingSheetIndex>}
     * @memberof UploadExcelPlanUpdateParams
     */
    'mappingSheetIndexList': Array<MappingSheetIndex>;
    /**
     * 
     * @type {string}
     * @memberof UploadExcelPlanUpdateParams
     */
    'planningProjectId': string;
}
/**
 * 
 * @export
 * @interface UploadNonFinancialItemsParams
 */
export interface UploadNonFinancialItemsParams {
    /**
     * 
     * @type {string}
     * @memberof UploadNonFinancialItemsParams
     */
    'actualImportMappingId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadNonFinancialItemsParams
     */
    'processSettingId': string;
}
/**
 * 
 * @export
 * @interface UploadPlanUpdateRequestsRequest
 */
export interface UploadPlanUpdateRequestsRequest {
    /**
     * 
     * @type {File}
     * @memberof UploadPlanUpdateRequestsRequest
     */
    'file'?: File;
}
/**
 * 
 * @export
 * @interface UpsertDimensionRequestBody
 */
export interface UpsertDimensionRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpsertDimensionRequestBody
     */
    'dimensionName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertDimensionRequestBody
     */
    'importColumnName': string;
}
/**
 * 
 * @export
 * @interface UpsertUnitAmountItemParam
 */
export interface UpsertUnitAmountItemParam {
    /**
     * 
     * @type {string}
     * @memberof UpsertUnitAmountItemParam
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpsertWorkforceActualImportMappingSettingParam
 */
export interface UpsertWorkforceActualImportMappingSettingParam {
    /**
     * 
     * @type {number}
     * @memberof UpsertWorkforceActualImportMappingSettingParam
     */
    'headerRowIndex': number;
    /**
     * 
     * @type {string}
     * @memberof UpsertWorkforceActualImportMappingSettingParam
     */
    'name': string;
    /**
     * 
     * @type {Array<CreateWorkforceActualImportMappingTargetDimensionParam>}
     * @memberof UpsertWorkforceActualImportMappingSettingParam
     */
    'workforceActualImportMappingTargetDimensions': Array<CreateWorkforceActualImportMappingTargetDimensionParam>;
    /**
     * 
     * @type {Array<CreateWorkforceActualImportMappingTargetParam>}
     * @memberof UpsertWorkforceActualImportMappingSettingParam
     */
    'workforceActualImportMappingTargets': Array<CreateWorkforceActualImportMappingTargetParam>;
    /**
     * 
     * @type {Array<CreateWorkforceActualImportRowFilterParam>}
     * @memberof UpsertWorkforceActualImportMappingSettingParam
     */
    'workforceActualImportRowFilters': Array<CreateWorkforceActualImportRowFilterParam>;
}
/**
 * 
 * @export
 * @interface UserView
 */
export interface UserView {
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserView
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserView
     */
    'requireMFA': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    'role': UserViewRoleEnum;
}

export const UserViewRoleEnum = {
    LoglassSystemAdmin: 'LOGLASS_SYSTEM_ADMIN',
    TenantAdmin: 'TENANT_ADMIN',
    CorpPlanning: 'CORP_PLANNING',
    Manager: 'MANAGER',
    ItStaff: 'IT_STAFF',
    General: 'GENERAL'
} as const;

export type UserViewRoleEnum = typeof UserViewRoleEnum[keyof typeof UserViewRoleEnum];

/**
 * 
 * @export
 * @interface UserWithDepartmentNamesDto
 */
export interface UserWithDepartmentNamesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserWithDepartmentNamesDto
     */
    'departmentNames': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserWithDepartmentNamesDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UserWithDepartmentNamesDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserWithDepartmentNamesDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithDepartmentNamesDto
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithDepartmentNamesDto
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithDepartmentNamesDto
     */
    'requireMFA': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithDepartmentNamesDto
     */
    'role': UserWithDepartmentNamesDtoRoleEnum;
}

export const UserWithDepartmentNamesDtoRoleEnum = {
    LoglassSystemAdmin: 'LOGLASS_SYSTEM_ADMIN',
    TenantAdmin: 'TENANT_ADMIN',
    CorpPlanning: 'CORP_PLANNING',
    Manager: 'MANAGER',
    ItStaff: 'IT_STAFF',
    General: 'GENERAL'
} as const;

export type UserWithDepartmentNamesDtoRoleEnum = typeof UserWithDepartmentNamesDtoRoleEnum[keyof typeof UserWithDepartmentNamesDtoRoleEnum];

/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {string}
     * @memberof View
     */
    'contentType'?: string;
}
/**
 * 
 * @export
 * @interface ViewPermission
 */
export interface ViewPermission {
    /**
     * 
     * @type {string}
     * @memberof ViewPermission
     */
    'createdAt': string;
    /**
     * 
     * @type {IDUser}
     * @memberof ViewPermission
     */
    'createdUser': IDUser;
    /**
     * 
     * @type {IDViewPermission}
     * @memberof ViewPermission
     */
    'id': IDViewPermission;
    /**
     * 
     * @type {boolean}
     * @memberof ViewPermission
     */
    'isViewableWorkforceDimensionId': boolean;
    /**
     * 
     * @type {Name}
     * @memberof ViewPermission
     */
    'name': Name;
    /**
     * 
     * @type {Array<IDAccount>}
     * @memberof ViewPermission
     */
    'selectedAccounts': Array<IDAccount>;
    /**
     * 
     * @type {Array<IDCalculatedAccount>}
     * @memberof ViewPermission
     */
    'selectedCalculatedAccounts': Array<IDCalculatedAccount>;
    /**
     * 
     * @type {string}
     * @memberof ViewPermission
     */
    'updatedAt': string;
    /**
     * 
     * @type {IDUser}
     * @memberof ViewPermission
     */
    'updatedUser': IDUser;
    /**
     * 
     * @type {Array<IDUser>}
     * @memberof ViewPermission
     */
    'users': Array<IDUser>;
    /**
     * 
     * @type {string}
     * @memberof ViewPermission
     */
    'viewableAccountScope': ViewPermissionViewableAccountScopeEnum;
}

export const ViewPermissionViewableAccountScopeEnum = {
    All: 'ALL',
    Selected: 'SELECTED'
} as const;

export type ViewPermissionViewableAccountScopeEnum = typeof ViewPermissionViewableAccountScopeEnum[keyof typeof ViewPermissionViewableAccountScopeEnum];

/**
 * 
 * @export
 * @interface ViewPermissionUsersForSelectionView
 */
export interface ViewPermissionUsersForSelectionView {
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewPermissionUsersForSelectionView
     */
    'departmentNames': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ViewPermissionUsersForSelectionView
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ViewPermissionUsersForSelectionView
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ViewPermissionUsersForSelectionView
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ViewPermissionUsersForSelectionView
     */
    'viewPermissionId'?: string;
}
/**
 * 
 * @export
 * @interface ViewPermissionView
 */
export interface ViewPermissionView {
    /**
     * 
     * @type {IDViewPermission}
     * @memberof ViewPermissionView
     */
    'id': IDViewPermission;
    /**
     * 
     * @type {string}
     * @memberof ViewPermissionView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ViewPermissionView
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ViewPermissionView
     */
    'viewableAccountScope': ViewPermissionViewViewableAccountScopeEnum;
}

export const ViewPermissionViewViewableAccountScopeEnum = {
    All: 'ALL',
    Selected: 'SELECTED'
} as const;

export type ViewPermissionViewViewableAccountScopeEnum = typeof ViewPermissionViewViewableAccountScopeEnum[keyof typeof ViewPermissionViewViewableAccountScopeEnum];

/**
 * 
 * @export
 * @interface WarningMessageView
 */
export interface WarningMessageView {
    /**
     * 
     * @type {string}
     * @memberof WarningMessageView
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface WorkforceActualAppliedEventView
 */
export interface WorkforceActualAppliedEventView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualAppliedEventView
     */
    'actualImportEventFileName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualAppliedEventView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualAppliedEventView
     */
    'createdByUserName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualAppliedEventView
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface WorkforceActualClosingHistoryView
 */
export interface WorkforceActualClosingHistoryView {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforceActualClosingHistoryView
     */
    'closableFiscalMonths': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualClosingHistoryView
     */
    'latestClosingFiscalMonth': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforceActualClosingHistoryView
     */
    'openableFiscalMonths': Array<string>;
}
/**
 * 
 * @export
 * @interface WorkforceActualImportMappingSettingListItem
 */
export interface WorkforceActualImportMappingSettingListItem {
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportMappingSettingListItem
     */
    'mappingSettingId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportMappingSettingListItem
     */
    'mappingSettingName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportMappingSettingListItem
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface WorkforceActualImportMappingSettingView
 */
export interface WorkforceActualImportMappingSettingView {
    /**
     * 
     * @type {number}
     * @memberof WorkforceActualImportMappingSettingView
     */
    'headerRowNumber': number;
    /**
     * 
     * @type {Array<WorkforceActualImportMappingTargetDimensionView>}
     * @memberof WorkforceActualImportMappingSettingView
     */
    'importMappingTargetDimensions': Array<WorkforceActualImportMappingTargetDimensionView>;
    /**
     * 
     * @type {Array<WorkforceActualImportRowFilterView>}
     * @memberof WorkforceActualImportMappingSettingView
     */
    'importRowFilters': Array<WorkforceActualImportRowFilterView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportMappingSettingView
     */
    'name': string;
    /**
     * 
     * @type {Array<WorkforceActualImportMappingTargetView>}
     * @memberof WorkforceActualImportMappingSettingView
     */
    'optionalImportMappingTargets': Array<WorkforceActualImportMappingTargetView>;
    /**
     * 
     * @type {Array<WorkforceActualImportMappingTargetView>}
     * @memberof WorkforceActualImportMappingSettingView
     */
    'requiredImportMappingTargets': Array<WorkforceActualImportMappingTargetView>;
}
/**
 * 
 * @export
 * @interface WorkforceActualImportMappingTargetDimensionView
 */
export interface WorkforceActualImportMappingTargetDimensionView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportMappingTargetDimensionView
     */
    'columnAlphabet'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportMappingTargetDimensionView
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportMappingTargetDimensionView
     */
    'dimensionName': string;
}
/**
 * 
 * @export
 * @interface WorkforceActualImportMappingTargetView
 */
export interface WorkforceActualImportMappingTargetView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportMappingTargetView
     */
    'columnAlphabet'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportMappingTargetView
     */
    'type': WorkforceActualImportMappingTargetViewTypeEnum;
}

export const WorkforceActualImportMappingTargetViewTypeEnum = {
    WorkforceCode: 'WORKFORCE_CODE',
    LastName: 'LAST_NAME',
    FirstName: 'FIRST_NAME',
    StartDate: 'START_DATE',
    EndDate: 'END_DATE',
    EmploymentStatus: 'EMPLOYMENT_STATUS',
    ExternalSystemDepartmentCode: 'EXTERNAL_SYSTEM_DEPARTMENT_CODE',
    EffectiveDate: 'EFFECTIVE_DATE'
} as const;

export type WorkforceActualImportMappingTargetViewTypeEnum = typeof WorkforceActualImportMappingTargetViewTypeEnum[keyof typeof WorkforceActualImportMappingTargetViewTypeEnum];

/**
 * 
 * @export
 * @interface WorkforceActualImportRowFilterView
 */
export interface WorkforceActualImportRowFilterView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportRowFilterView
     */
    'columnAlphabet': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportRowFilterView
     */
    'conditionType': WorkforceActualImportRowFilterViewConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualImportRowFilterView
     */
    'conditionValue'?: string;
}

export const WorkforceActualImportRowFilterViewConditionTypeEnum = {
    Equal: 'EQUAL',
    NotEqual: 'NOT_EQUAL',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Entered: 'ENTERED',
    NotEntered: 'NOT_ENTERED'
} as const;

export type WorkforceActualImportRowFilterViewConditionTypeEnum = typeof WorkforceActualImportRowFilterViewConditionTypeEnum[keyof typeof WorkforceActualImportRowFilterViewConditionTypeEnum];

/**
 * 
 * @export
 * @interface WorkforceActualUploadEventView
 */
export interface WorkforceActualUploadEventView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualUploadEventView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualUploadEventView
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualUploadEventView
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualUploadEventView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualUploadEventView
     */
    'periodEndMonth': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceActualUploadEventView
     */
    'periodStartMonth': string;
}
/**
 * 
 * @export
 * @interface WorkforceConversionRuleBody
 */
export interface WorkforceConversionRuleBody {
    /**
     * 
     * @type {string}
     * @memberof WorkforceConversionRuleBody
     */
    'conversionTiming': WorkforceConversionRuleBodyConversionTimingEnum;
    /**
     * 
     * @type {Array<WorkforceConversionRulePatternBody>}
     * @memberof WorkforceConversionRuleBody
     */
    'patterns': Array<WorkforceConversionRulePatternBody>;
    /**
     * 
     * @type {string}
     * @memberof WorkforceConversionRuleBody
     */
    'ruleName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceConversionRuleBody
     */
    'unitAmountItemId'?: string;
}

export const WorkforceConversionRuleBodyConversionTimingEnum = {
    Monthly: 'MONTHLY',
    UponJoining: 'UPON_JOINING'
} as const;

export type WorkforceConversionRuleBodyConversionTimingEnum = typeof WorkforceConversionRuleBodyConversionTimingEnum[keyof typeof WorkforceConversionRuleBodyConversionTimingEnum];

/**
 * 
 * @export
 * @interface WorkforceConversionRuleConditionDetailView
 */
export interface WorkforceConversionRuleConditionDetailView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceConversionRuleConditionDetailView
     */
    'condition': WorkforceConversionRuleConditionDetailViewConditionEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkforceConversionRuleConditionDetailView
     */
    'dimensionId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforceConversionRuleConditionDetailView
     */
    'ids': Array<string>;
}

export const WorkforceConversionRuleConditionDetailViewConditionEnum = {
    Department: 'DEPARTMENT',
    Dimension: 'DIMENSION'
} as const;

export type WorkforceConversionRuleConditionDetailViewConditionEnum = typeof WorkforceConversionRuleConditionDetailViewConditionEnum[keyof typeof WorkforceConversionRuleConditionDetailViewConditionEnum];

/**
 * 
 * @export
 * @interface WorkforceConversionRulePatternBody
 */
export interface WorkforceConversionRulePatternBody {
    /**
     * 
     * @type {Array<WorkforceConversionRuleConditionDetailView>}
     * @memberof WorkforceConversionRulePatternBody
     */
    'conditions': Array<WorkforceConversionRuleConditionDetailView>;
    /**
     * 
     * @type {EachMonthFixedPatternBody}
     * @memberof WorkforceConversionRulePatternBody
     */
    'eachMonthFixedPattern'?: EachMonthFixedPatternBody;
    /**
     * 
     * @type {EachMonthRatioPatternBody}
     * @memberof WorkforceConversionRulePatternBody
     */
    'eachMonthRatioPattern'?: EachMonthRatioPatternBody;
    /**
     * 
     * @type {ExcludePatternBody}
     * @memberof WorkforceConversionRulePatternBody
     */
    'excludePattern'?: ExcludePatternBody;
    /**
     * 
     * @type {FixedPatternBody}
     * @memberof WorkforceConversionRulePatternBody
     */
    'fixedPattern'?: FixedPatternBody;
    /**
     * 
     * @type {string}
     * @memberof WorkforceConversionRulePatternBody
     */
    'patternType': WorkforceConversionRulePatternBodyPatternTypeEnum;
    /**
     * 
     * @type {RatioPatternBody}
     * @memberof WorkforceConversionRulePatternBody
     */
    'ratioPattern'?: RatioPatternBody;
}

export const WorkforceConversionRulePatternBodyPatternTypeEnum = {
    Ratio: 'RATIO',
    Fixed: 'FIXED',
    EachMonthRatio: 'EACH_MONTH_RATIO',
    EachMonthFixed: 'EACH_MONTH_FIXED',
    Exclude: 'EXCLUDE'
} as const;

export type WorkforceConversionRulePatternBodyPatternTypeEnum = typeof WorkforceConversionRulePatternBodyPatternTypeEnum[keyof typeof WorkforceConversionRulePatternBodyPatternTypeEnum];

/**
 * 
 * @export
 * @interface WorkforceConversionRulePatternView
 */
export interface WorkforceConversionRulePatternView {
    /**
     * 
     * @type {Array<WorkforceConversionRuleConditionDetailView>}
     * @memberof WorkforceConversionRulePatternView
     */
    'conditions': Array<WorkforceConversionRuleConditionDetailView>;
    /**
     * 
     * @type {EachMonthFixedPatternView}
     * @memberof WorkforceConversionRulePatternView
     */
    'eachMonthFixedPattern'?: EachMonthFixedPatternView;
    /**
     * 
     * @type {EachMonthRatioPatternView}
     * @memberof WorkforceConversionRulePatternView
     */
    'eachMonthRatioPattern'?: EachMonthRatioPatternView;
    /**
     * 
     * @type {ExcludePatternView}
     * @memberof WorkforceConversionRulePatternView
     */
    'excludePattern'?: ExcludePatternView;
    /**
     * 
     * @type {FixedPatternView}
     * @memberof WorkforceConversionRulePatternView
     */
    'fixedPattern'?: FixedPatternView;
    /**
     * 
     * @type {string}
     * @memberof WorkforceConversionRulePatternView
     */
    'patternType': WorkforceConversionRulePatternViewPatternTypeEnum;
    /**
     * 
     * @type {RatioPatternView}
     * @memberof WorkforceConversionRulePatternView
     */
    'ratioPattern'?: RatioPatternView;
}

export const WorkforceConversionRulePatternViewPatternTypeEnum = {
    Ratio: 'RATIO',
    Fixed: 'FIXED',
    EachMonthRatio: 'EACH_MONTH_RATIO',
    EachMonthFixed: 'EACH_MONTH_FIXED',
    Exclude: 'EXCLUDE'
} as const;

export type WorkforceConversionRulePatternViewPatternTypeEnum = typeof WorkforceConversionRulePatternViewPatternTypeEnum[keyof typeof WorkforceConversionRulePatternViewPatternTypeEnum];

/**
 * 
 * @export
 * @interface WorkforceEmploymentDetails
 */
export interface WorkforceEmploymentDetails {
    /**
     * 
     * @type {Array<ConcurrentPostRatioInfosView>}
     * @memberof WorkforceEmploymentDetails
     */
    'concurrentPostRatioInfos': Array<ConcurrentPostRatioInfosView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforceEmploymentDetails
     */
    'departmentName'?: string;
    /**
     * 
     * @type {Array<DimensionTagInfoView>}
     * @memberof WorkforceEmploymentDetails
     */
    'dimensionTagInfos': Array<DimensionTagInfoView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforceEmploymentDetails
     */
    'employmentStatus': WorkforceEmploymentDetailsEmploymentStatusEnum;
    /**
     * 
     * @type {SlotInfoView}
     * @memberof WorkforceEmploymentDetails
     */
    'slotInfo'?: SlotInfoView;
    /**
     * 
     * @type {Array<UnitAmountView>}
     * @memberof WorkforceEmploymentDetails
     */
    'unitAmounts': Array<UnitAmountView>;
}

export const WorkforceEmploymentDetailsEmploymentStatusEnum = {
    Active: 'ACTIVE',
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    Resignation: 'RESIGNATION'
} as const;

export type WorkforceEmploymentDetailsEmploymentStatusEnum = typeof WorkforceEmploymentDetailsEmploymentStatusEnum[keyof typeof WorkforceEmploymentDetailsEmploymentStatusEnum];

/**
 * 
 * @export
 * @interface WorkforcePlanChangeEventAssignedInfoView
 */
export interface WorkforcePlanChangeEventAssignedInfoView {
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanChangeEventAssignedInfoView
     */
    'departmentName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforcePlanChangeEventAssignedInfoView
     */
    'dimensionTagNames': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanChangeEventAssignedInfoView
     */
    'leaveOfAbsenceEvent'?: WorkforcePlanChangeEventAssignedInfoViewLeaveOfAbsenceEventEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanChangeEventAssignedInfoView
     */
    'ratio'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkforcePlanChangeEventAssignedInfoView
     */
    'remainingSlotCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanChangeEventAssignedInfoView
     */
    'remainingSlotYearMonth'?: string;
    /**
     * 
     * @type {Array<UnitAmountView>}
     * @memberof WorkforcePlanChangeEventAssignedInfoView
     */
    'unitAmounts': Array<UnitAmountView>;
    /**
     * 
     * @type {number}
     * @memberof WorkforcePlanChangeEventAssignedInfoView
     */
    'usedSlotCount'?: number;
}

export const WorkforcePlanChangeEventAssignedInfoViewLeaveOfAbsenceEventEnum = {
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    ReturnFromLeaveOfAbsence: 'RETURN_FROM_LEAVE_OF_ABSENCE'
} as const;

export type WorkforcePlanChangeEventAssignedInfoViewLeaveOfAbsenceEventEnum = typeof WorkforcePlanChangeEventAssignedInfoViewLeaveOfAbsenceEventEnum[keyof typeof WorkforcePlanChangeEventAssignedInfoViewLeaveOfAbsenceEventEnum];

/**
 * 
 * @export
 * @interface WorkforcePlanChangeEventByYearMonthView
 */
export interface WorkforcePlanChangeEventByYearMonthView {
    /**
     * 
     * @type {Array<WorkforcePlanChangeEventView>}
     * @memberof WorkforcePlanChangeEventByYearMonthView
     */
    'events': Array<WorkforcePlanChangeEventView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanChangeEventByYearMonthView
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface WorkforcePlanChangeEventView
 */
export interface WorkforcePlanChangeEventView {
    /**
     * 
     * @type {WorkforcePlanChangeEventAssignedInfoView}
     * @memberof WorkforcePlanChangeEventView
     */
    'assignedInfo': WorkforcePlanChangeEventAssignedInfoView;
    /**
     * 
     * @type {boolean}
     * @memberof WorkforcePlanChangeEventView
     */
    'cancelled': boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanChangeEventView
     */
    'changeType': WorkforcePlanChangeEventViewChangeTypeEnum;
}

export const WorkforcePlanChangeEventViewChangeTypeEnum = {
    Start: 'START',
    Update: 'UPDATE',
    End: 'END',
    ConcurrentPostStart: 'CONCURRENT_POST_START',
    ConcurrentPostRelease: 'CONCURRENT_POST_RELEASE',
    ResignationSlot: 'RESIGNATION_SLOT',
    HiringSlot: 'HIRING_SLOT',
    LeaveOfAbsenceSlot: 'LEAVE_OF_ABSENCE_SLOT',
    ReturnToWorkSlot: 'RETURN_TO_WORK_SLOT',
    UnitAmountChanged: 'UNIT_AMOUNT_CHANGED'
} as const;

export type WorkforcePlanChangeEventViewChangeTypeEnum = typeof WorkforcePlanChangeEventViewChangeTypeEnum[keyof typeof WorkforcePlanChangeEventViewChangeTypeEnum];

/**
 * 
 * @export
 * @interface WorkforcePlanUpdateRequestCalculationStatusView
 */
export interface WorkforcePlanUpdateRequestCalculationStatusView {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforcePlanUpdateRequestCalculationStatusView
     */
    'errorMessages': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof WorkforcePlanUpdateRequestCalculationStatusView
     */
    'isCalculationWorkforcePlanUpdateRequest': boolean;
}
/**
 * 
 * @export
 * @interface WorkforcePlanUpdateRequestEventByWorkforceView
 */
export interface WorkforcePlanUpdateRequestEventByWorkforceView {
    /**
     * 
     * @type {Array<WorkforcePlanChangeEventByYearMonthView>}
     * @memberof WorkforcePlanUpdateRequestEventByWorkforceView
     */
    'eventByYearMonth': Array<WorkforcePlanChangeEventByYearMonthView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestEventByWorkforceView
     */
    'workforceName': string;
}
/**
 * 
 * @export
 * @interface WorkforcePlanUpdateRequestEventsView
 */
export interface WorkforcePlanUpdateRequestEventsView {
    /**
     * 
     * @type {Array<WorkforcePlanUpdateRequestEventByWorkforceView>}
     * @memberof WorkforcePlanUpdateRequestEventsView
     */
    'items': Array<WorkforcePlanUpdateRequestEventByWorkforceView>;
}
/**
 * 
 * @export
 * @interface WorkforcePlanUpdateRequestPLView
 */
export interface WorkforcePlanUpdateRequestPLView {
    /**
     * 
     * @type {boolean}
     * @memberof WorkforcePlanUpdateRequestPLView
     */
    'hasRestrictedItems': boolean;
    /**
     * 
     * @type {Array<PlanUpdateRequestTreeNodeFlatV2View>}
     * @memberof WorkforcePlanUpdateRequestPLView
     */
    'items': Array<PlanUpdateRequestTreeNodeFlatV2View>;
    /**
     * 
     * @type {Array<PlanUpdateRequestPeriodView>}
     * @memberof WorkforcePlanUpdateRequestPLView
     */
    'periods': Array<PlanUpdateRequestPeriodView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestPLView
     */
    'planType': WorkforcePlanUpdateRequestPLViewPlanTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforcePlanUpdateRequestPLView
     */
    'targetDepartmentNames': Array<string>;
}

export const WorkforcePlanUpdateRequestPLViewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type WorkforcePlanUpdateRequestPLViewPlanTypeEnum = typeof WorkforcePlanUpdateRequestPLViewPlanTypeEnum[keyof typeof WorkforcePlanUpdateRequestPLViewPlanTypeEnum];

/**
 * 
 * @export
 * @interface WorkforcePlanUpdateRequestSourceView
 */
export interface WorkforcePlanUpdateRequestSourceView {
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestSourceView
     */
    'simulationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestSourceView
     */
    'type': WorkforcePlanUpdateRequestSourceViewTypeEnum;
}

export const WorkforcePlanUpdateRequestSourceViewTypeEnum = {
    Simulation: 'SIMULATION',
    File: 'FILE',
    SyncWithActual: 'SYNC_WITH_ACTUAL'
} as const;

export type WorkforcePlanUpdateRequestSourceViewTypeEnum = typeof WorkforcePlanUpdateRequestSourceViewTypeEnum[keyof typeof WorkforcePlanUpdateRequestSourceViewTypeEnum];

/**
 * 
 * @export
 * @interface WorkforcePlanUpdateRequestStatusesView
 */
export interface WorkforcePlanUpdateRequestStatusesView {
    /**
     * 
     * @type {Array<ApproveErrorMessage>}
     * @memberof WorkforcePlanUpdateRequestStatusesView
     */
    'errorMessages': Array<ApproveErrorMessage>;
    /**
     * 
     * @type {boolean}
     * @memberof WorkforcePlanUpdateRequestStatusesView
     */
    'hasApproving': boolean;
}
/**
 * 
 * @export
 * @interface WorkforcePlanUpdateRequestView
 */
export interface WorkforcePlanUpdateRequestView {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'approvalFailedMessages': Array<string>;
    /**
     * 
     * @type {Array<ConnectedPlanUpdateRequestDto>}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'connectedPlanUpdateRequestDto': Array<ConnectedPlanUpdateRequestDto>;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'createdUserName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'planningProjectName': string;
    /**
     * 
     * @type {WorkforcePlanUpdateRequestSourceView}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'source'?: WorkforcePlanUpdateRequestSourceView;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'status': WorkforcePlanUpdateRequestViewStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestView
     */
    'workforcePlanUpdateRequestId': string;
}

export const WorkforcePlanUpdateRequestViewStatusEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED',
    Closing: 'CLOSING',
    ApprovalFailed: 'APPROVAL_FAILED'
} as const;

export type WorkforcePlanUpdateRequestViewStatusEnum = typeof WorkforcePlanUpdateRequestViewStatusEnum[keyof typeof WorkforcePlanUpdateRequestViewStatusEnum];

/**
 * 
 * @export
 * @interface WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView
 */
export interface WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView {
    /**
     * 
     * @type {WorkforceEmploymentDetails}
     * @memberof WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView
     */
    'employmentDetails'?: WorkforceEmploymentDetails;
    /**
     * 
     * @type {Array<WorkforcePlanChangeEventView>}
     * @memberof WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView
     */
    'events': Array<WorkforcePlanChangeEventView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView
     */
    'workforceName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface WorkforcePlanUpdateRequestWorkforceDiffView
 */
export interface WorkforcePlanUpdateRequestWorkforceDiffView {
    /**
     * 
     * @type {SubTotalRow}
     * @memberof WorkforcePlanUpdateRequestWorkforceDiffView
     */
    'hiringSlotSubTotal': SubTotalRow;
    /**
     * 
     * @type {SubTotalRow}
     * @memberof WorkforcePlanUpdateRequestWorkforceDiffView
     */
    'leaveOfAbsenceSlotSubTotal': SubTotalRow;
    /**
     * 
     * @type {SubTotalRow}
     * @memberof WorkforcePlanUpdateRequestWorkforceDiffView
     */
    'personSubTotal': SubTotalRow;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanUpdateRequestWorkforceDiffView
     */
    'planType': WorkforcePlanUpdateRequestWorkforceDiffViewPlanTypeEnum;
    /**
     * 
     * @type {SubTotalRow}
     * @memberof WorkforcePlanUpdateRequestWorkforceDiffView
     */
    'resignationSlotSubTotal': SubTotalRow;
    /**
     * 
     * @type {SubTotalRow}
     * @memberof WorkforcePlanUpdateRequestWorkforceDiffView
     */
    'returnToWorkSlotSubTotal': SubTotalRow;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforcePlanUpdateRequestWorkforceDiffView
     */
    'targetYearMonths': Array<string>;
    /**
     * 
     * @type {Array<TotalItem>}
     * @memberof WorkforcePlanUpdateRequestWorkforceDiffView
     */
    'totalItems': Array<TotalItem>;
}

export const WorkforcePlanUpdateRequestWorkforceDiffViewPlanTypeEnum = {
    Budget: 'BUDGET',
    Forecast: 'FORECAST'
} as const;

export type WorkforcePlanUpdateRequestWorkforceDiffViewPlanTypeEnum = typeof WorkforcePlanUpdateRequestWorkforceDiffViewPlanTypeEnum[keyof typeof WorkforcePlanUpdateRequestWorkforceDiffViewPlanTypeEnum];

/**
 * 
 * @export
 * @interface WorkforcePlanningBaseSalaryIncreaseRuleView
 */
export interface WorkforcePlanningBaseSalaryIncreaseRuleView {
    /**
     * 
     * @type {Array<IncreaseRateForEachMonthView>}
     * @memberof WorkforcePlanningBaseSalaryIncreaseRuleView
     */
    'increaseRates': Array<IncreaseRateForEachMonthView>;
    /**
     * 
     * @type {Array<TargetAccountView>}
     * @memberof WorkforcePlanningBaseSalaryIncreaseRuleView
     */
    'targetAccounts': Array<TargetAccountView>;
}
/**
 * 
 * @export
 * @interface WorkforcePlanningDimensionTagView
 */
export interface WorkforcePlanningDimensionTagView {
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningDimensionTagView
     */
    'dimensionTagId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningDimensionTagView
     */
    'dimensionTagName': string;
}
/**
 * 
 * @export
 * @interface WorkforcePlanningDimensionWithDimensionTagsView
 */
export interface WorkforcePlanningDimensionWithDimensionTagsView {
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningDimensionWithDimensionTagsView
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningDimensionWithDimensionTagsView
     */
    'dimensionName': string;
    /**
     * 
     * @type {Array<WorkforcePlanningDimensionTagView>}
     * @memberof WorkforcePlanningDimensionWithDimensionTagsView
     */
    'dimensionTags': Array<WorkforcePlanningDimensionTagView>;
    /**
     * 
     * @type {boolean}
     * @memberof WorkforcePlanningDimensionWithDimensionTagsView
     */
    'required': boolean;
}
/**
 * 
 * @export
 * @interface WorkforcePlanningItemConvertRuleDetailView
 */
export interface WorkforcePlanningItemConvertRuleDetailView {
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningItemConvertRuleDetailView
     */
    'conversionTiming': WorkforcePlanningItemConvertRuleDetailViewConversionTimingEnum;
    /**
     * 
     * @type {Array<WorkforceConversionRulePatternView>}
     * @memberof WorkforcePlanningItemConvertRuleDetailView
     */
    'patterns': Array<WorkforceConversionRulePatternView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningItemConvertRuleDetailView
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningItemConvertRuleDetailView
     */
    'ruleName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningItemConvertRuleDetailView
     */
    'unitAmountItemId'?: string;
}

export const WorkforcePlanningItemConvertRuleDetailViewConversionTimingEnum = {
    Monthly: 'MONTHLY',
    UponJoining: 'UPON_JOINING'
} as const;

export type WorkforcePlanningItemConvertRuleDetailViewConversionTimingEnum = typeof WorkforcePlanningItemConvertRuleDetailViewConversionTimingEnum[keyof typeof WorkforcePlanningItemConvertRuleDetailViewConversionTimingEnum];

/**
 * 
 * @export
 * @interface WorkforcePlanningItemConvertRuleView
 */
export interface WorkforcePlanningItemConvertRuleView {
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningItemConvertRuleView
     */
    'conversionTiming': WorkforcePlanningItemConvertRuleViewConversionTimingEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningItemConvertRuleView
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningItemConvertRuleView
     */
    'ruleName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforcePlanningItemConvertRuleView
     */
    'targetAccountNames': Array<string>;
}

export const WorkforcePlanningItemConvertRuleViewConversionTimingEnum = {
    Monthly: 'MONTHLY',
    UponJoining: 'UPON_JOINING'
} as const;

export type WorkforcePlanningItemConvertRuleViewConversionTimingEnum = typeof WorkforcePlanningItemConvertRuleViewConversionTimingEnum[keyof typeof WorkforcePlanningItemConvertRuleViewConversionTimingEnum];

/**
 * 
 * @export
 * @interface WorkforcePlanningSimulationMetaView
 */
export interface WorkforcePlanningSimulationMetaView {
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningSimulationMetaView
     */
    'importedAt': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningSimulationMetaView
     */
    'latestImportEventStatus': WorkforcePlanningSimulationMetaViewLatestImportEventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningSimulationMetaView
     */
    'planMasterName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningSimulationMetaView
     */
    'planningProjectName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforcePlanningSimulationMetaView
     */
    'planningSimulationName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkforcePlanningSimulationMetaView
     */
    'targetDepartmentNames': Array<string>;
}

export const WorkforcePlanningSimulationMetaViewLatestImportEventStatusEnum = {
    Importing: 'IMPORTING',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
} as const;

export type WorkforcePlanningSimulationMetaViewLatestImportEventStatusEnum = typeof WorkforcePlanningSimulationMetaViewLatestImportEventStatusEnum[keyof typeof WorkforcePlanningSimulationMetaViewLatestImportEventStatusEnum];

/**
 * 
 * @export
 * @interface WorkforceSimulatingSlotUnitAmountView
 */
export interface WorkforceSimulatingSlotUnitAmountView {
    /**
     * 
     * @type {number}
     * @memberof WorkforceSimulatingSlotUnitAmountView
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulatingSlotUnitAmountView
     */
    'itemId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulatingSlotUnitAmountView
     */
    'itemName': string;
}
/**
 * 
 * @export
 * @interface WorkforceSimulatingSlotView
 */
export interface WorkforceSimulatingSlotView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulatingSlotView
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulatingSlotView
     */
    'departmentId': string;
    /**
     * 
     * @type {Array<DimensionIdToDimensionTagId>}
     * @memberof WorkforceSimulatingSlotView
     */
    'dimensionIdToDimensionTagIds': Array<DimensionIdToDimensionTagId>;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulatingSlotView
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulatingSlotView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulatingSlotView
     */
    'slotType': WorkforceSimulatingSlotViewSlotTypeEnum;
    /**
     * 
     * @type {Array<WorkforceSimulatingSlotUnitAmountView>}
     * @memberof WorkforceSimulatingSlotView
     */
    'unitAmounts': Array<WorkforceSimulatingSlotUnitAmountView>;
}

export const WorkforceSimulatingSlotViewSlotTypeEnum = {
    Hiring: 'HIRING',
    Resignation: 'RESIGNATION',
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    ReturnToWork: 'RETURN_TO_WORK'
} as const;

export type WorkforceSimulatingSlotViewSlotTypeEnum = typeof WorkforceSimulatingSlotViewSlotTypeEnum[keyof typeof WorkforceSimulatingSlotViewSlotTypeEnum];

/**
 * 
 * @export
 * @interface WorkforceSimulationSlotBody
 */
export interface WorkforceSimulationSlotBody {
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulationSlotBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulationSlotBody
     */
    'departmentId': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WorkforceSimulationSlotBody
     */
    'dimensionIdToTagIdMap': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulationSlotBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulationSlotBody
     */
    'slotType': string;
    /**
     * 
     * @type {Array<WorkforceSimulationSlotBodyUnitAmountItem>}
     * @memberof WorkforceSimulationSlotBody
     */
    'unitAmountItems': Array<WorkforceSimulationSlotBodyUnitAmountItem>;
}
/**
 * 
 * @export
 * @interface WorkforceSimulationSlotBodyUnitAmountItem
 */
export interface WorkforceSimulationSlotBodyUnitAmountItem {
    /**
     * 
     * @type {string}
     * @memberof WorkforceSimulationSlotBodyUnitAmountItem
     */
    'itemId': string;
    /**
     * 
     * @type {number}
     * @memberof WorkforceSimulationSlotBodyUnitAmountItem
     */
    'unitAmount': number;
}
/**
 * 
 * @export
 * @interface WorkforceSlotListItemDimensionTagView
 */
export interface WorkforceSlotListItemDimensionTagView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotListItemDimensionTagView
     */
    'dimensionId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotListItemDimensionTagView
     */
    'dimensionName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotListItemDimensionTagView
     */
    'dimensionTagName': string;
}
/**
 * 
 * @export
 * @interface WorkforceSlotListItemUnitAmountView
 */
export interface WorkforceSlotListItemUnitAmountView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotListItemUnitAmountView
     */
    'itemId': string;
    /**
     * 
     * @type {number}
     * @memberof WorkforceSlotListItemUnitAmountView
     */
    'unitAmount': number;
}
/**
 * 
 * @export
 * @interface WorkforceSlotListItemView
 */
export interface WorkforceSlotListItemView {
    /**
     * 
     * @type {Array<ConnectedPersonByYearMonthView>}
     * @memberof WorkforceSlotListItemView
     */
    'connectedPersonByMonthViews': Array<ConnectedPersonByYearMonthView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotListItemView
     */
    'departmentName': string;
    /**
     * 
     * @type {Array<WorkforceSlotListItemDimensionTagView>}
     * @memberof WorkforceSlotListItemView
     */
    'dimensionTagViews': Array<WorkforceSlotListItemDimensionTagView>;
    /**
     * 
     * @type {Array<SlotUsageByMonthView>}
     * @memberof WorkforceSlotListItemView
     */
    'slotUsageByMonthViews': Array<SlotUsageByMonthView>;
    /**
     * 
     * @type {number}
     * @memberof WorkforceSlotListItemView
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof WorkforceSlotListItemView
     */
    'totalUsedCount': number;
    /**
     * 
     * @type {Array<WorkforceSlotListItemUnitAmountView>}
     * @memberof WorkforceSlotListItemView
     */
    'unitAmounts': Array<WorkforceSlotListItemUnitAmountView>;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotListItemView
     */
    'workforceSlotCode': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotListItemView
     */
    'workforceSlotId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotListItemView
     */
    'workforceSlotName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotListItemView
     */
    'workforceSlotType': WorkforceSlotListItemViewWorkforceSlotTypeEnum;
}

export const WorkforceSlotListItemViewWorkforceSlotTypeEnum = {
    Hiring: 'HIRING',
    Resignation: 'RESIGNATION',
    LeaveOfAbsence: 'LEAVE_OF_ABSENCE',
    ReturnToWork: 'RETURN_TO_WORK'
} as const;

export type WorkforceSlotListItemViewWorkforceSlotTypeEnum = typeof WorkforceSlotListItemViewWorkforceSlotTypeEnum[keyof typeof WorkforceSlotListItemViewWorkforceSlotTypeEnum];

/**
 * 
 * @export
 * @interface WorkforceSlotView
 */
export interface WorkforceSlotView {
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotView
     */
    'workforceSlotCode': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotView
     */
    'workforceSlotId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceSlotView
     */
    'workforceSlotName': string;
}
/**
 * 
 * @export
 * @interface WorkforceWithoutUnitAmountItem
 */
export interface WorkforceWithoutUnitAmountItem {
    /**
     * 
     * @type {string}
     * @memberof WorkforceWithoutUnitAmountItem
     */
    'personCode': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceWithoutUnitAmountItem
     */
    'personFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceWithoutUnitAmountItem
     */
    'personLastName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkforceWithoutUnitAmountItem
     */
    'workforceId': string;
}
/**
 * 
 * @export
 * @interface WorkforceWithoutUnitAmountItems
 */
export interface WorkforceWithoutUnitAmountItems {
    /**
     * 
     * @type {Array<WorkforceWithoutUnitAmountItem>}
     * @memberof WorkforceWithoutUnitAmountItems
     */
    'items': Array<WorkforceWithoutUnitAmountItem>;
}
/**
 * 
 * @export
 * @interface YearMonthRangeParams
 */
export interface YearMonthRangeParams {
    /**
     * 
     * @type {string}
     * @memberof YearMonthRangeParams
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof YearMonthRangeParams
     */
    'start': string;
}
/**
 * 
 * @export
 * @interface YearMonthRequestBody
 */
export interface YearMonthRequestBody {
    /**
     * 
     * @type {string}
     * @memberof YearMonthRequestBody
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof YearMonthRequestBody
     */
    'yearMonth': string;
}
/**
 * 
 * @export
 * @interface ZendeskJwtDto
 */
export interface ZendeskJwtDto {
    /**
     * 
     * @type {string}
     * @memberof ZendeskJwtDto
     */
    'jwt': string;
}

/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 科目の科目タイプを一括更新
         * @param {BulkUpdateAccountTypeRequestBody} bulkUpdateAccountTypeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateAccountType: async (bulkUpdateAccountTypeRequestBody: BulkUpdateAccountTypeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUpdateAccountTypeRequestBody' is not null or undefined
            assertParamExists('bulkUpdateAccountType', 'bulkUpdateAccountTypeRequestBody', bulkUpdateAccountTypeRequestBody)
            const localVarPath = `/api/accounts/accountType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateAccountTypeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目を作成する
         * @param {CreateAccountRequestBody} createAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (createAccountRequestBody: CreateAccountRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountRequestBody' is not null or undefined
            assertParamExists('createAccount', 'createAccountRequestBody', createAccountRequestBody)
            const localVarPath = `/api/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目を作成し、科目階層に追加する
         * @param {string} accountTreeId 
         * @param {string} parentAccountId 
         * @param {CreateAndAddToTreeAccountParam} createAndAddToTreeAccountParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndAddToTree: async (accountTreeId: string, parentAccountId: string, createAndAddToTreeAccountParam: CreateAndAddToTreeAccountParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountTreeId' is not null or undefined
            assertParamExists('createAndAddToTree', 'accountTreeId', accountTreeId)
            // verify required parameter 'parentAccountId' is not null or undefined
            assertParamExists('createAndAddToTree', 'parentAccountId', parentAccountId)
            // verify required parameter 'createAndAddToTreeAccountParam' is not null or undefined
            assertParamExists('createAndAddToTree', 'createAndAddToTreeAccountParam', createAndAddToTreeAccountParam)
            const localVarPath = `/api/accounts/create-and-add-tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountTreeId !== undefined) {
                localVarQueryParameter['accountTreeId'] = accountTreeId;
            }

            if (parentAccountId !== undefined) {
                localVarQueryParameter['parentAccountId'] = parentAccountId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAndAddToTreeAccountParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目を削除する。階層構造で子科目を持たず、実績や計画で使われていない場合のみ削除できる
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('deleteAccount', 'accountId', accountId)
            const localVarPath = `/api/accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目一覧Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAccountsExcel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excelアップロードし、科目を一括作成・更新する。
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUpload2: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('fileUpload2', 'file', file)
            const localVarPath = `/api/accounts/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目を取得する。補助科目の情報も返す
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccount', 'accountId', accountId)
            const localVarPath = `/api/accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 全ての科目を取得する。accountTypeを複数指定して絞り込める
         * @param {Array<'UNKNOWN' | 'EXPENSE' | 'INCOME' | 'BS_ACCOUNT' | 'ASSETS' | 'LIABILITIES' | 'NET_ASSETS' | 'NON_FINANCIAL' | 'STANDARD_PL_AGGR_EXPENSE' | 'STANDARD_PL_AGGR_INCOME'>} [accountTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts: async (accountTypes?: Array<'UNKNOWN' | 'EXPENSE' | 'INCOME' | 'BS_ACCOUNT' | 'ASSETS' | 'LIABILITIES' | 'NET_ASSETS' | 'NON_FINANCIAL' | 'STANDARD_PL_AGGR_EXPENSE' | 'STANDARD_PL_AGGR_INCOME'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountTypes) {
                localVarQueryParameter['accountTypes'] = accountTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BS科目階層に含まれない科目一覧を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBsAccountsNotIncludedTree: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/bs/not-included`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BS科目階層の未設定科目一覧(階層に含まれないBS科目、未識別)を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBsAccountsNotIncludedTreeAndUnknowns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/accounts/bs/not-included-and-unknowns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目を更新する
         * @param {string} accountId 
         * @param {UpdateAccountRequestBody} updateAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (accountId: string, updateAccountRequestBody: UpdateAccountRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('updateAccount', 'accountId', accountId)
            // verify required parameter 'updateAccountRequestBody' is not null or undefined
            assertParamExists('updateAccount', 'updateAccountRequestBody', updateAccountRequestBody)
            const localVarPath = `/api/accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目階層にある科目を更新
         * @param {string} accountId 
         * @param {string} accountTreeId 
         * @param {UpdateAccountRequestBody} updateAccountRequestBody 
         * @param {string} [insertDestinationParentAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountInTree: async (accountId: string, accountTreeId: string, updateAccountRequestBody: UpdateAccountRequestBody, insertDestinationParentAccountId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('updateAccountInTree', 'accountId', accountId)
            // verify required parameter 'accountTreeId' is not null or undefined
            assertParamExists('updateAccountInTree', 'accountTreeId', accountTreeId)
            // verify required parameter 'updateAccountRequestBody' is not null or undefined
            assertParamExists('updateAccountInTree', 'updateAccountRequestBody', updateAccountRequestBody)
            const localVarPath = `/api/accounts/{accountId}/in-tree`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountTreeId !== undefined) {
                localVarQueryParameter['accountTreeId'] = accountTreeId;
            }

            if (insertDestinationParentAccountId !== undefined) {
                localVarQueryParameter['insertDestinationParentAccountId'] = insertDestinationParentAccountId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BS科目階層に入っている科目を更新する
         * @param {string} accountId 
         * @param {PatchBSAccountRequestBody} patchBSAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBSAccount: async (accountId: string, patchBSAccountRequestBody: PatchBSAccountRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('updateBSAccount', 'accountId', accountId)
            // verify required parameter 'patchBSAccountRequestBody' is not null or undefined
            assertParamExists('updateBSAccount', 'patchBSAccountRequestBody', patchBSAccountRequestBody)
            const localVarPath = `/api/accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchBSAccountRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 科目の科目タイプを一括更新
         * @param {BulkUpdateAccountTypeRequestBody} bulkUpdateAccountTypeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateAccountType(bulkUpdateAccountTypeRequestBody: BulkUpdateAccountTypeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateAccountType(bulkUpdateAccountTypeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目を作成する
         * @param {CreateAccountRequestBody} createAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(createAccountRequestBody: CreateAccountRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(createAccountRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目を作成し、科目階層に追加する
         * @param {string} accountTreeId 
         * @param {string} parentAccountId 
         * @param {CreateAndAddToTreeAccountParam} createAndAddToTreeAccountParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndAddToTree(accountTreeId: string, parentAccountId: string, createAndAddToTreeAccountParam: CreateAndAddToTreeAccountParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndAddToTree(accountTreeId, parentAccountId, createAndAddToTreeAccountParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目を削除する。階層構造で子科目を持たず、実績や計画で使われていない場合のみ削除できる
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目一覧Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAccountsExcel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAccountsExcel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excelアップロードし、科目を一括作成・更新する。
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUpload2(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUpload2(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目を取得する。補助科目の情報も返す
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 全ての科目を取得する。accountTypeを複数指定して絞り込める
         * @param {Array<'UNKNOWN' | 'EXPENSE' | 'INCOME' | 'BS_ACCOUNT' | 'ASSETS' | 'LIABILITIES' | 'NET_ASSETS' | 'NON_FINANCIAL' | 'STANDARD_PL_AGGR_EXPENSE' | 'STANDARD_PL_AGGR_INCOME'>} [accountTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccounts(accountTypes?: Array<'UNKNOWN' | 'EXPENSE' | 'INCOME' | 'BS_ACCOUNT' | 'ASSETS' | 'LIABILITIES' | 'NET_ASSETS' | 'NON_FINANCIAL' | 'STANDARD_PL_AGGR_EXPENSE' | 'STANDARD_PL_AGGR_INCOME'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccounts(accountTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BS科目階層に含まれない科目一覧を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBsAccountsNotIncludedTree(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBsAccountsNotIncludedTree(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BS科目階層の未設定科目一覧(階層に含まれないBS科目、未識別)を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBsAccountsNotIncludedTreeAndUnknowns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBsAccountsNotIncludedTreeAndUnknowns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目を更新する
         * @param {string} accountId 
         * @param {UpdateAccountRequestBody} updateAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(accountId: string, updateAccountRequestBody: UpdateAccountRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(accountId, updateAccountRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目階層にある科目を更新
         * @param {string} accountId 
         * @param {string} accountTreeId 
         * @param {UpdateAccountRequestBody} updateAccountRequestBody 
         * @param {string} [insertDestinationParentAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountInTree(accountId: string, accountTreeId: string, updateAccountRequestBody: UpdateAccountRequestBody, insertDestinationParentAccountId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountInTree(accountId, accountTreeId, updateAccountRequestBody, insertDestinationParentAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BS科目階層に入っている科目を更新する
         * @param {string} accountId 
         * @param {PatchBSAccountRequestBody} patchBSAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBSAccount(accountId: string, patchBSAccountRequestBody: PatchBSAccountRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBSAccount(accountId, patchBSAccountRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 科目の科目タイプを一括更新
         * @param {BulkUpdateAccountTypeRequestBody} bulkUpdateAccountTypeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateAccountType(bulkUpdateAccountTypeRequestBody: BulkUpdateAccountTypeRequestBody, options?: any): AxiosPromise<AccountsView> {
            return localVarFp.bulkUpdateAccountType(bulkUpdateAccountTypeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目を作成する
         * @param {CreateAccountRequestBody} createAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(createAccountRequestBody: CreateAccountRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createAccount(createAccountRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目を作成し、科目階層に追加する
         * @param {string} accountTreeId 
         * @param {string} parentAccountId 
         * @param {CreateAndAddToTreeAccountParam} createAndAddToTreeAccountParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndAddToTree(accountTreeId: string, parentAccountId: string, createAndAddToTreeAccountParam: CreateAndAddToTreeAccountParam, options?: any): AxiosPromise<AccountView> {
            return localVarFp.createAndAddToTree(accountTreeId, parentAccountId, createAndAddToTreeAccountParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目を削除する。階層構造で子科目を持たず、実績や計画で使われていない場合のみ削除できる
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(accountId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目一覧Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAccountsExcel(options?: any): AxiosPromise<File> {
            return localVarFp.downloadAccountsExcel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excelアップロードし、科目を一括作成・更新する。
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUpload2(file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.fileUpload2(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目を取得する。補助科目の情報も返す
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(accountId: string, options?: any): AxiosPromise<AccountView> {
            return localVarFp.getAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 全ての科目を取得する。accountTypeを複数指定して絞り込める
         * @param {Array<'UNKNOWN' | 'EXPENSE' | 'INCOME' | 'BS_ACCOUNT' | 'ASSETS' | 'LIABILITIES' | 'NET_ASSETS' | 'NON_FINANCIAL' | 'STANDARD_PL_AGGR_EXPENSE' | 'STANDARD_PL_AGGR_INCOME'>} [accountTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts(accountTypes?: Array<'UNKNOWN' | 'EXPENSE' | 'INCOME' | 'BS_ACCOUNT' | 'ASSETS' | 'LIABILITIES' | 'NET_ASSETS' | 'NON_FINANCIAL' | 'STANDARD_PL_AGGR_EXPENSE' | 'STANDARD_PL_AGGR_INCOME'>, options?: any): AxiosPromise<AccountsView> {
            return localVarFp.listAccounts(accountTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BS科目階層に含まれない科目一覧を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBsAccountsNotIncludedTree(options?: any): AxiosPromise<AccountsView> {
            return localVarFp.listBsAccountsNotIncludedTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BS科目階層の未設定科目一覧(階層に含まれないBS科目、未識別)を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBsAccountsNotIncludedTreeAndUnknowns(options?: any): AxiosPromise<AccountsView> {
            return localVarFp.listBsAccountsNotIncludedTreeAndUnknowns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目を更新する
         * @param {string} accountId 
         * @param {UpdateAccountRequestBody} updateAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(accountId: string, updateAccountRequestBody: UpdateAccountRequestBody, options?: any): AxiosPromise<AccountView> {
            return localVarFp.updateAccount(accountId, updateAccountRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目階層にある科目を更新
         * @param {string} accountId 
         * @param {string} accountTreeId 
         * @param {UpdateAccountRequestBody} updateAccountRequestBody 
         * @param {string} [insertDestinationParentAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountInTree(accountId: string, accountTreeId: string, updateAccountRequestBody: UpdateAccountRequestBody, insertDestinationParentAccountId?: string, options?: any): AxiosPromise<AccountView> {
            return localVarFp.updateAccountInTree(accountId, accountTreeId, updateAccountRequestBody, insertDestinationParentAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BS科目階層に入っている科目を更新する
         * @param {string} accountId 
         * @param {PatchBSAccountRequestBody} patchBSAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBSAccount(accountId: string, patchBSAccountRequestBody: PatchBSAccountRequestBody, options?: any): AxiosPromise<AccountView> {
            return localVarFp.updateBSAccount(accountId, patchBSAccountRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @summary 科目の科目タイプを一括更新
     * @param {BulkUpdateAccountTypeRequestBody} bulkUpdateAccountTypeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public bulkUpdateAccountType(bulkUpdateAccountTypeRequestBody: BulkUpdateAccountTypeRequestBody, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).bulkUpdateAccountType(bulkUpdateAccountTypeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目を作成する
     * @param {CreateAccountRequestBody} createAccountRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public createAccount(createAccountRequestBody: CreateAccountRequestBody, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).createAccount(createAccountRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目を作成し、科目階層に追加する
     * @param {string} accountTreeId 
     * @param {string} parentAccountId 
     * @param {CreateAndAddToTreeAccountParam} createAndAddToTreeAccountParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public createAndAddToTree(accountTreeId: string, parentAccountId: string, createAndAddToTreeAccountParam: CreateAndAddToTreeAccountParam, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).createAndAddToTree(accountTreeId, parentAccountId, createAndAddToTreeAccountParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目を削除する。階層構造で子科目を持たず、実績や計画で使われていない場合のみ削除できる
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public deleteAccount(accountId: string, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).deleteAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目一覧Excelのダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public downloadAccountsExcel(options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).downloadAccountsExcel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excelアップロードし、科目を一括作成・更新する。
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public fileUpload2(file: File, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).fileUpload2(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目を取得する。補助科目の情報も返す
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public getAccount(accountId: string, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).getAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 全ての科目を取得する。accountTypeを複数指定して絞り込める
     * @param {Array<'UNKNOWN' | 'EXPENSE' | 'INCOME' | 'BS_ACCOUNT' | 'ASSETS' | 'LIABILITIES' | 'NET_ASSETS' | 'NON_FINANCIAL' | 'STANDARD_PL_AGGR_EXPENSE' | 'STANDARD_PL_AGGR_INCOME'>} [accountTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public listAccounts(accountTypes?: Array<'UNKNOWN' | 'EXPENSE' | 'INCOME' | 'BS_ACCOUNT' | 'ASSETS' | 'LIABILITIES' | 'NET_ASSETS' | 'NON_FINANCIAL' | 'STANDARD_PL_AGGR_EXPENSE' | 'STANDARD_PL_AGGR_INCOME'>, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).listAccounts(accountTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BS科目階層に含まれない科目一覧を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public listBsAccountsNotIncludedTree(options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).listBsAccountsNotIncludedTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BS科目階層の未設定科目一覧(階層に含まれないBS科目、未識別)を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public listBsAccountsNotIncludedTreeAndUnknowns(options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).listBsAccountsNotIncludedTreeAndUnknowns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目を更新する
     * @param {string} accountId 
     * @param {UpdateAccountRequestBody} updateAccountRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public updateAccount(accountId: string, updateAccountRequestBody: UpdateAccountRequestBody, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).updateAccount(accountId, updateAccountRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目階層にある科目を更新
     * @param {string} accountId 
     * @param {string} accountTreeId 
     * @param {UpdateAccountRequestBody} updateAccountRequestBody 
     * @param {string} [insertDestinationParentAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public updateAccountInTree(accountId: string, accountTreeId: string, updateAccountRequestBody: UpdateAccountRequestBody, insertDestinationParentAccountId?: string, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).updateAccountInTree(accountId, accountTreeId, updateAccountRequestBody, insertDestinationParentAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BS科目階層に入っている科目を更新する
     * @param {string} accountId 
     * @param {PatchBSAccountRequestBody} patchBSAccountRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public updateBSAccount(accountId: string, patchBSAccountRequestBody: PatchBSAccountRequestBody, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).updateBSAccount(accountId, patchBSAccountRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountGroupsControllerApi - axios parameter creator
 * @export
 */
export const AccountGroupsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary グループを削除する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} accountGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountGroup: async (nonFinancialAccountTreeId: string, accountGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('deleteAccountGroup', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'accountGroupId' is not null or undefined
            assertParamExists('deleteAccountGroup', 'accountGroupId', accountGroupId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/account_groups/{accountGroupId}`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)))
                .replace(`{${"accountGroupId"}}`, encodeURIComponent(String(accountGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary グループ一括作成・更新 Excel のダウンロード API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAccountGroupsExcel: async (nonFinancialAccountTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('downloadAccountGroupsExcel', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/account_groups/excel`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary グループを更新する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} accountGroupId 
         * @param {UpdateAccountGroupRequestBody} updateAccountGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountGroup: async (nonFinancialAccountTreeId: string, accountGroupId: string, updateAccountGroupRequestBody: UpdateAccountGroupRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('updateAccountGroup', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'accountGroupId' is not null or undefined
            assertParamExists('updateAccountGroup', 'accountGroupId', accountGroupId)
            // verify required parameter 'updateAccountGroupRequestBody' is not null or undefined
            assertParamExists('updateAccountGroup', 'updateAccountGroupRequestBody', updateAccountGroupRequestBody)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/account_groups/{accountGroupId}`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)))
                .replace(`{${"accountGroupId"}}`, encodeURIComponent(String(accountGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountGroupRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary グループ一括作成・更新 Excel アップロード API
         * @param {string} nonFinancialAccountTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAccountGroupsExcel: async (nonFinancialAccountTreeId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('uploadAccountGroupsExcel', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadAccountGroupsExcel', 'file', file)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/account_groups/excel`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountGroupsControllerApi - functional programming interface
 * @export
 */
export const AccountGroupsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountGroupsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary グループを削除する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} accountGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccountGroup(nonFinancialAccountTreeId: string, accountGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccountGroup(nonFinancialAccountTreeId, accountGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary グループ一括作成・更新 Excel のダウンロード API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAccountGroupsExcel(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAccountGroupsExcel(nonFinancialAccountTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary グループを更新する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} accountGroupId 
         * @param {UpdateAccountGroupRequestBody} updateAccountGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountGroup(nonFinancialAccountTreeId: string, accountGroupId: string, updateAccountGroupRequestBody: UpdateAccountGroupRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountGroupView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountGroup(nonFinancialAccountTreeId, accountGroupId, updateAccountGroupRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary グループ一括作成・更新 Excel アップロード API
         * @param {string} nonFinancialAccountTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAccountGroupsExcel(nonFinancialAccountTreeId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAccountGroupsExcel(nonFinancialAccountTreeId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountGroupsControllerApi - factory interface
 * @export
 */
export const AccountGroupsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountGroupsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary グループを削除する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} accountGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountGroup(nonFinancialAccountTreeId: string, accountGroupId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteAccountGroup(nonFinancialAccountTreeId, accountGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary グループ一括作成・更新 Excel のダウンロード API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAccountGroupsExcel(nonFinancialAccountTreeId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadAccountGroupsExcel(nonFinancialAccountTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary グループを更新する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} accountGroupId 
         * @param {UpdateAccountGroupRequestBody} updateAccountGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountGroup(nonFinancialAccountTreeId: string, accountGroupId: string, updateAccountGroupRequestBody: UpdateAccountGroupRequestBody, options?: any): AxiosPromise<AccountGroupView> {
            return localVarFp.updateAccountGroup(nonFinancialAccountTreeId, accountGroupId, updateAccountGroupRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary グループ一括作成・更新 Excel アップロード API
         * @param {string} nonFinancialAccountTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAccountGroupsExcel(nonFinancialAccountTreeId: string, file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadAccountGroupsExcel(nonFinancialAccountTreeId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountGroupsControllerApi - object-oriented interface
 * @export
 * @class AccountGroupsControllerApi
 * @extends {BaseAPI}
 */
export class AccountGroupsControllerApi extends BaseAPI {
    /**
     * 
     * @summary グループを削除する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {string} accountGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountGroupsControllerApi
     */
    public deleteAccountGroup(nonFinancialAccountTreeId: string, accountGroupId: string, options?: AxiosRequestConfig) {
        return AccountGroupsControllerApiFp(this.configuration).deleteAccountGroup(nonFinancialAccountTreeId, accountGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary グループ一括作成・更新 Excel のダウンロード API
     * @param {string} nonFinancialAccountTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountGroupsControllerApi
     */
    public downloadAccountGroupsExcel(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig) {
        return AccountGroupsControllerApiFp(this.configuration).downloadAccountGroupsExcel(nonFinancialAccountTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary グループを更新する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {string} accountGroupId 
     * @param {UpdateAccountGroupRequestBody} updateAccountGroupRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountGroupsControllerApi
     */
    public updateAccountGroup(nonFinancialAccountTreeId: string, accountGroupId: string, updateAccountGroupRequestBody: UpdateAccountGroupRequestBody, options?: AxiosRequestConfig) {
        return AccountGroupsControllerApiFp(this.configuration).updateAccountGroup(nonFinancialAccountTreeId, accountGroupId, updateAccountGroupRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary グループ一括作成・更新 Excel アップロード API
     * @param {string} nonFinancialAccountTreeId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountGroupsControllerApi
     */
    public uploadAccountGroupsExcel(nonFinancialAccountTreeId: string, file: File, options?: AxiosRequestConfig) {
        return AccountGroupsControllerApiFp(this.configuration).uploadAccountGroupsExcel(nonFinancialAccountTreeId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountTreeControllerApi - axios parameter creator
 * @export
 */
export const AccountTreeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 科目ツリーから科目をアーカイブする
         * @param {string} treeId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAccount: async (treeId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('archiveAccount', 'treeId', treeId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('archiveAccount', 'requestBody', requestBody)
            const localVarPath = `/api/account-trees/{treeId}/archive-account`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目階層を削除する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountTree: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('deleteAccountTree', 'treeId', treeId)
            const localVarPath = `/api/account-trees/{treeId}`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定された科目を科目階層から外す
         * @param {string} treeId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachSubTree: async (treeId: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('detachSubTree', 'treeId', treeId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('detachSubTree', 'accountId', accountId)
            const localVarPath = `/api/account-trees/{treeId}/detach/{accountId}`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目階層のExcelダウンロード
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownload1: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('fileDownload1', 'treeId', treeId)
            const localVarPath = `/api/account-trees/{treeId}/excel`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excelファイルをもとに、科目階層の一括更新を行う
         * @param {string} treeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUpload3: async (treeId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('fileUpload3', 'treeId', treeId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('fileUpload3', 'file', file)
            const localVarPath = `/api/account-trees/{treeId}/bulk-update`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 選択させるために本体値を表示するPL科目階層を取得するAPI
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountTreeForSelection: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getAccountTreeForSelection', 'treeId', treeId)
            const localVarPath = `/api/account-trees/{treeId}/for-selection`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PL科目階層を取得するAPI
         * @param {string} treeId 
         * @param {boolean} isOnePLTree 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountTrees: async (treeId: string, isOnePLTree: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getAccountTrees', 'treeId', treeId)
            // verify required parameter 'isOnePLTree' is not null or undefined
            assertParamExists('getAccountTrees', 'isOnePLTree', isOnePLTree)
            const localVarPath = `/api/account-trees/{treeId}`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isOnePLTree !== undefined) {
                localVarQueryParameter['isOnePLTree'] = isOnePLTree;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 頂点科目変更のための科目リストを取得
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsForReplaceRootNodeSelection: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getAccountsForReplaceRootNodeSelection', 'treeId', treeId)
            const localVarPath = `/api/account-trees/{treeId}/for-replace-root-node-selection`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BS科目階層の未設定科目一覧(階層に含まれないBS科目、未識別)の科目を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBsNotIncluded: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-trees/bs-not-included`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目の階層構造に含まれない、AccountType=BS_ACCOUNTと集計科目以外の科目を取得
         * @param {string} treeId 
         * @param {boolean} requireExSubAccount 
         * @param {boolean} requireArchivedAccount 
         * @param {number} limit 
         * @param {number} [offset] 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncluded1: async (treeId: string, requireExSubAccount: boolean, requireArchivedAccount: boolean, limit: number, offset?: number, searchWord?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getNotIncluded1', 'treeId', treeId)
            // verify required parameter 'requireExSubAccount' is not null or undefined
            assertParamExists('getNotIncluded1', 'requireExSubAccount', requireExSubAccount)
            // verify required parameter 'requireArchivedAccount' is not null or undefined
            assertParamExists('getNotIncluded1', 'requireArchivedAccount', requireArchivedAccount)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getNotIncluded1', 'limit', limit)
            const localVarPath = `/api/account-trees/{treeId}/not-included`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requireExSubAccount !== undefined) {
                localVarQueryParameter['requireExSubAccount'] = requireExSubAccount;
            }

            if (requireArchivedAccount !== undefined) {
                localVarQueryParameter['requireArchivedAccount'] = requireArchivedAccount;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (searchWord !== undefined) {
                localVarQueryParameter['searchWord'] = searchWord;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目の階層構造に含まれない、AccountType=BS_ACCOUNTと集計科目以外の科目の総数を取得（アーカイブ済み科目は含まない）
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncludedCount1: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getNotIncludedCount1', 'treeId', treeId)
            const localVarPath = `/api/account-trees/{treeId}/not-included-count`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目階層の一覧を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountTrees: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account-trees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 既存の科目階層のコピーを作成する
         * @param {string} treeId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeCopyOfAccountTree: async (treeId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('makeCopyOfAccountTree', 'treeId', treeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('makeCopyOfAccountTree', 'name', name)
            const localVarPath = `/api/account-trees/{treeId}/make-copy`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 未設定科目の内、親科目がすでに科目階層にある外部システム補助科目を科目階層に、親科目がBS科目のものをBS科目に、自動で移動する
         * @param {string} accountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNodeAutomatically: async (accountTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountTreeId' is not null or undefined
            assertParamExists('moveNodeAutomatically', 'accountTreeId', accountTreeId)
            const localVarPath = `/api/account-trees/not-included-accounts/auto-move`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountTreeId !== undefined) {
                localVarQueryParameter['accountTreeId'] = accountTreeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ノードを移動する
         * @param {string} treeId 
         * @param {MoveNodesRequestBody} moveNodesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNodes1: async (treeId: string, moveNodesRequestBody: MoveNodesRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('moveNodes1', 'treeId', treeId)
            // verify required parameter 'moveNodesRequestBody' is not null or undefined
            assertParamExists('moveNodes1', 'moveNodesRequestBody', moveNodesRequestBody)
            const localVarPath = `/api/account-trees/{treeId}/move-node`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveNodesRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目階層の名前を変更する
         * @param {string} treeId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rename: async (treeId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('rename', 'treeId', treeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('rename', 'name', name)
            const localVarPath = `/api/account-trees/{treeId}/name`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 頂点科目の変更
         * @param {string} treeId 
         * @param {ReplaceRootNodeRequestBody} replaceRootNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceRootNode: async (treeId: string, replaceRootNodeRequestBody: ReplaceRootNodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('replaceRootNode', 'treeId', treeId)
            // verify required parameter 'replaceRootNodeRequestBody' is not null or undefined
            assertParamExists('replaceRootNode', 'replaceRootNodeRequestBody', replaceRootNodeRequestBody)
            const localVarPath = `/api/account-trees/{treeId}/replace-root-node`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceRootNodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目階層をプライマリに設定する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimary1: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('setPrimary1', 'treeId', treeId)
            const localVarPath = `/api/account-trees/{treeId}/set-primary`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 科目階層のアーカイブ済みの科目を戻す
         * @param {string} treeId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveAccount: async (treeId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('unarchiveAccount', 'treeId', treeId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('unarchiveAccount', 'requestBody', requestBody)
            const localVarPath = `/api/account-trees/{treeId}/unarchive-account`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountTreeControllerApi - functional programming interface
 * @export
 */
export const AccountTreeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountTreeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 科目ツリーから科目をアーカイブする
         * @param {string} treeId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveAccount(treeId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveAccount(treeId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目階層を削除する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccountTree(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccountTree(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定された科目を科目階層から外す
         * @param {string} treeId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachSubTree(treeId: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachSubTree(treeId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目階層のExcelダウンロード
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileDownload1(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileDownload1(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excelファイルをもとに、科目階層の一括更新を行う
         * @param {string} treeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUpload3(treeId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUpload3(treeId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 選択させるために本体値を表示するPL科目階層を取得するAPI
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountTreeForSelection(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFlatTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountTreeForSelection(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PL科目階層を取得するAPI
         * @param {string} treeId 
         * @param {boolean} isOnePLTree 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountTrees(treeId: string, isOnePLTree: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFlatTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountTrees(treeId, isOnePLTree, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 頂点科目変更のための科目リストを取得
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsForReplaceRootNodeSelection(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsForReplaceRootNodeSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsForReplaceRootNodeSelection(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BS科目階層の未設定科目一覧(階層に含まれないBS科目、未識別)の科目を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBsNotIncluded(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BsNotIncludedAccountsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBsNotIncluded(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目の階層構造に含まれない、AccountType=BS_ACCOUNTと集計科目以外の科目を取得
         * @param {string} treeId 
         * @param {boolean} requireExSubAccount 
         * @param {boolean} requireArchivedAccount 
         * @param {number} limit 
         * @param {number} [offset] 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotIncluded1(treeId: string, requireExSubAccount: boolean, requireArchivedAccount: boolean, limit: number, offset?: number, searchWord?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotIncludedAccountsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotIncluded1(treeId, requireExSubAccount, requireArchivedAccount, limit, offset, searchWord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目の階層構造に含まれない、AccountType=BS_ACCOUNTと集計科目以外の科目の総数を取得（アーカイブ済み科目は含まない）
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotIncludedCount1(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotIncludedAccountCountView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotIncludedCount1(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目階層の一覧を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccountTrees(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountTreeListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccountTrees(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 既存の科目階層のコピーを作成する
         * @param {string} treeId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeCopyOfAccountTree(treeId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFlatTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeCopyOfAccountTree(treeId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 未設定科目の内、親科目がすでに科目階層にある外部システム補助科目を科目階層に、親科目がBS科目のものをBS科目に、自動で移動する
         * @param {string} accountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveNodeAutomatically(accountTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MovedNotIncludedAccountsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveNodeAutomatically(accountTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ノードを移動する
         * @param {string} treeId 
         * @param {MoveNodesRequestBody} moveNodesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveNodes1(treeId: string, moveNodesRequestBody: MoveNodesRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFlatTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveNodes1(treeId, moveNodesRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目階層の名前を変更する
         * @param {string} treeId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rename(treeId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFlatTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rename(treeId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 頂点科目の変更
         * @param {string} treeId 
         * @param {ReplaceRootNodeRequestBody} replaceRootNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceRootNode(treeId: string, replaceRootNodeRequestBody: ReplaceRootNodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceRootNode(treeId, replaceRootNodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目階層をプライマリに設定する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPrimary1(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPrimary1(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 科目階層のアーカイブ済みの科目を戻す
         * @param {string} treeId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unarchiveAccount(treeId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveAccount(treeId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountTreeControllerApi - factory interface
 * @export
 */
export const AccountTreeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountTreeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 科目ツリーから科目をアーカイブする
         * @param {string} treeId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAccount(treeId: string, requestBody: Array<string>, options?: any): AxiosPromise<OkJson> {
            return localVarFp.archiveAccount(treeId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目階層を削除する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountTree(treeId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteAccountTree(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定された科目を科目階層から外す
         * @param {string} treeId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachSubTree(treeId: string, accountId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.detachSubTree(treeId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目階層のExcelダウンロード
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownload1(treeId: string, options?: any): AxiosPromise<string> {
            return localVarFp.fileDownload1(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excelファイルをもとに、科目階層の一括更新を行う
         * @param {string} treeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUpload3(treeId: string, file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.fileUpload3(treeId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 選択させるために本体値を表示するPL科目階層を取得するAPI
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountTreeForSelection(treeId: string, options?: any): AxiosPromise<AccountFlatTreeForSelectionView> {
            return localVarFp.getAccountTreeForSelection(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PL科目階層を取得するAPI
         * @param {string} treeId 
         * @param {boolean} isOnePLTree 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountTrees(treeId: string, isOnePLTree: boolean, options?: any): AxiosPromise<AccountFlatTreeView> {
            return localVarFp.getAccountTrees(treeId, isOnePLTree, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 頂点科目変更のための科目リストを取得
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsForReplaceRootNodeSelection(treeId: string, options?: any): AxiosPromise<AccountsForReplaceRootNodeSelectionView> {
            return localVarFp.getAccountsForReplaceRootNodeSelection(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BS科目階層の未設定科目一覧(階層に含まれないBS科目、未識別)の科目を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBsNotIncluded(options?: any): AxiosPromise<BsNotIncludedAccountsView> {
            return localVarFp.getBsNotIncluded(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目の階層構造に含まれない、AccountType=BS_ACCOUNTと集計科目以外の科目を取得
         * @param {string} treeId 
         * @param {boolean} requireExSubAccount 
         * @param {boolean} requireArchivedAccount 
         * @param {number} limit 
         * @param {number} [offset] 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncluded1(treeId: string, requireExSubAccount: boolean, requireArchivedAccount: boolean, limit: number, offset?: number, searchWord?: string, options?: any): AxiosPromise<NotIncludedAccountsView> {
            return localVarFp.getNotIncluded1(treeId, requireExSubAccount, requireArchivedAccount, limit, offset, searchWord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目の階層構造に含まれない、AccountType=BS_ACCOUNTと集計科目以外の科目の総数を取得（アーカイブ済み科目は含まない）
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncludedCount1(treeId: string, options?: any): AxiosPromise<NotIncludedAccountCountView> {
            return localVarFp.getNotIncludedCount1(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目階層の一覧を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountTrees(options?: any): AxiosPromise<AccountTreeListView> {
            return localVarFp.listAccountTrees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 既存の科目階層のコピーを作成する
         * @param {string} treeId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeCopyOfAccountTree(treeId: string, name: string, options?: any): AxiosPromise<AccountFlatTreeView> {
            return localVarFp.makeCopyOfAccountTree(treeId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 未設定科目の内、親科目がすでに科目階層にある外部システム補助科目を科目階層に、親科目がBS科目のものをBS科目に、自動で移動する
         * @param {string} accountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNodeAutomatically(accountTreeId: string, options?: any): AxiosPromise<MovedNotIncludedAccountsView> {
            return localVarFp.moveNodeAutomatically(accountTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ノードを移動する
         * @param {string} treeId 
         * @param {MoveNodesRequestBody} moveNodesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNodes1(treeId: string, moveNodesRequestBody: MoveNodesRequestBody, options?: any): AxiosPromise<AccountFlatTreeView> {
            return localVarFp.moveNodes1(treeId, moveNodesRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目階層の名前を変更する
         * @param {string} treeId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rename(treeId: string, name: string, options?: any): AxiosPromise<AccountFlatTreeView> {
            return localVarFp.rename(treeId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 頂点科目の変更
         * @param {string} treeId 
         * @param {ReplaceRootNodeRequestBody} replaceRootNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceRootNode(treeId: string, replaceRootNodeRequestBody: ReplaceRootNodeRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.replaceRootNode(treeId, replaceRootNodeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目階層をプライマリに設定する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimary1(treeId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.setPrimary1(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 科目階層のアーカイブ済みの科目を戻す
         * @param {string} treeId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveAccount(treeId: string, requestBody: Array<string>, options?: any): AxiosPromise<OkJson> {
            return localVarFp.unarchiveAccount(treeId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountTreeControllerApi - object-oriented interface
 * @export
 * @class AccountTreeControllerApi
 * @extends {BaseAPI}
 */
export class AccountTreeControllerApi extends BaseAPI {
    /**
     * 
     * @summary 科目ツリーから科目をアーカイブする
     * @param {string} treeId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public archiveAccount(treeId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).archiveAccount(treeId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目階層を削除する
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public deleteAccountTree(treeId: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).deleteAccountTree(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定された科目を科目階層から外す
     * @param {string} treeId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public detachSubTree(treeId: string, accountId: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).detachSubTree(treeId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目階層のExcelダウンロード
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public fileDownload1(treeId: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).fileDownload1(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excelファイルをもとに、科目階層の一括更新を行う
     * @param {string} treeId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public fileUpload3(treeId: string, file: File, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).fileUpload3(treeId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 選択させるために本体値を表示するPL科目階層を取得するAPI
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public getAccountTreeForSelection(treeId: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).getAccountTreeForSelection(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PL科目階層を取得するAPI
     * @param {string} treeId 
     * @param {boolean} isOnePLTree 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public getAccountTrees(treeId: string, isOnePLTree: boolean, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).getAccountTrees(treeId, isOnePLTree, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 頂点科目変更のための科目リストを取得
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public getAccountsForReplaceRootNodeSelection(treeId: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).getAccountsForReplaceRootNodeSelection(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BS科目階層の未設定科目一覧(階層に含まれないBS科目、未識別)の科目を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public getBsNotIncluded(options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).getBsNotIncluded(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目の階層構造に含まれない、AccountType=BS_ACCOUNTと集計科目以外の科目を取得
     * @param {string} treeId 
     * @param {boolean} requireExSubAccount 
     * @param {boolean} requireArchivedAccount 
     * @param {number} limit 
     * @param {number} [offset] 
     * @param {string} [searchWord] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public getNotIncluded1(treeId: string, requireExSubAccount: boolean, requireArchivedAccount: boolean, limit: number, offset?: number, searchWord?: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).getNotIncluded1(treeId, requireExSubAccount, requireArchivedAccount, limit, offset, searchWord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目の階層構造に含まれない、AccountType=BS_ACCOUNTと集計科目以外の科目の総数を取得（アーカイブ済み科目は含まない）
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public getNotIncludedCount1(treeId: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).getNotIncludedCount1(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目階層の一覧を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public listAccountTrees(options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).listAccountTrees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 既存の科目階層のコピーを作成する
     * @param {string} treeId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public makeCopyOfAccountTree(treeId: string, name: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).makeCopyOfAccountTree(treeId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 未設定科目の内、親科目がすでに科目階層にある外部システム補助科目を科目階層に、親科目がBS科目のものをBS科目に、自動で移動する
     * @param {string} accountTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public moveNodeAutomatically(accountTreeId: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).moveNodeAutomatically(accountTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ノードを移動する
     * @param {string} treeId 
     * @param {MoveNodesRequestBody} moveNodesRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public moveNodes1(treeId: string, moveNodesRequestBody: MoveNodesRequestBody, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).moveNodes1(treeId, moveNodesRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目階層の名前を変更する
     * @param {string} treeId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public rename(treeId: string, name: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).rename(treeId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 頂点科目の変更
     * @param {string} treeId 
     * @param {ReplaceRootNodeRequestBody} replaceRootNodeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public replaceRootNode(treeId: string, replaceRootNodeRequestBody: ReplaceRootNodeRequestBody, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).replaceRootNode(treeId, replaceRootNodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目階層をプライマリに設定する
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public setPrimary1(treeId: string, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).setPrimary1(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 科目階層のアーカイブ済みの科目を戻す
     * @param {string} treeId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTreeControllerApi
     */
    public unarchiveAccount(treeId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AccountTreeControllerApiFp(this.configuration).unarchiveAccount(treeId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActualControllerApi - axios parameter creator
 * @export
 */
export const ActualControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 会計実績仕訳の出力。科目とその配下の科目。
         * @param {string} accountId 
         * @param {string} accountTreeId 
         * @param {FetchActualRequestBody} fetchActualRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountNode: async (accountId: string, accountTreeId: string, fetchActualRequestBody: FetchActualRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountNode', 'accountId', accountId)
            // verify required parameter 'accountTreeId' is not null or undefined
            assertParamExists('accountNode', 'accountTreeId', accountTreeId)
            // verify required parameter 'fetchActualRequestBody' is not null or undefined
            assertParamExists('accountNode', 'fetchActualRequestBody', fetchActualRequestBody)
            const localVarPath = `/api/actual/accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountTreeId !== undefined) {
                localVarQueryParameter['accountTreeId'] = accountTreeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchActualRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 会計実績仕訳の出力をExcelでダウンロードする
         * @param {string} accountId 
         * @param {string} accountTreeId 
         * @param {DownloadActualExcelRequestBody} downloadActualExcelRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAccountNodeExcel: async (accountId: string, accountTreeId: string, downloadActualExcelRequestBody: DownloadActualExcelRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('downloadAccountNodeExcel', 'accountId', accountId)
            // verify required parameter 'accountTreeId' is not null or undefined
            assertParamExists('downloadAccountNodeExcel', 'accountTreeId', accountTreeId)
            // verify required parameter 'downloadActualExcelRequestBody' is not null or undefined
            assertParamExists('downloadAccountNodeExcel', 'downloadActualExcelRequestBody', downloadActualExcelRequestBody)
            const localVarPath = `/api/actual/accounts/{accountId}/excel`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountTreeId !== undefined) {
                localVarQueryParameter['accountTreeId'] = accountTreeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadActualExcelRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActualControllerApi - functional programming interface
 * @export
 */
export const ActualControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActualControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 会計実績仕訳の出力。科目とその配下の科目。
         * @param {string} accountId 
         * @param {string} accountTreeId 
         * @param {FetchActualRequestBody} fetchActualRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountNode(accountId: string, accountTreeId: string, fetchActualRequestBody: FetchActualRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalDescriptionsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountNode(accountId, accountTreeId, fetchActualRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 会計実績仕訳の出力をExcelでダウンロードする
         * @param {string} accountId 
         * @param {string} accountTreeId 
         * @param {DownloadActualExcelRequestBody} downloadActualExcelRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAccountNodeExcel(accountId: string, accountTreeId: string, downloadActualExcelRequestBody: DownloadActualExcelRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAccountNodeExcel(accountId, accountTreeId, downloadActualExcelRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActualControllerApi - factory interface
 * @export
 */
export const ActualControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActualControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 会計実績仕訳の出力。科目とその配下の科目。
         * @param {string} accountId 
         * @param {string} accountTreeId 
         * @param {FetchActualRequestBody} fetchActualRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountNode(accountId: string, accountTreeId: string, fetchActualRequestBody: FetchActualRequestBody, options?: any): AxiosPromise<JournalDescriptionsView> {
            return localVarFp.accountNode(accountId, accountTreeId, fetchActualRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 会計実績仕訳の出力をExcelでダウンロードする
         * @param {string} accountId 
         * @param {string} accountTreeId 
         * @param {DownloadActualExcelRequestBody} downloadActualExcelRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAccountNodeExcel(accountId: string, accountTreeId: string, downloadActualExcelRequestBody: DownloadActualExcelRequestBody, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.downloadAccountNodeExcel(accountId, accountTreeId, downloadActualExcelRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActualControllerApi - object-oriented interface
 * @export
 * @class ActualControllerApi
 * @extends {BaseAPI}
 */
export class ActualControllerApi extends BaseAPI {
    /**
     * 
     * @summary 会計実績仕訳の出力。科目とその配下の科目。
     * @param {string} accountId 
     * @param {string} accountTreeId 
     * @param {FetchActualRequestBody} fetchActualRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualControllerApi
     */
    public accountNode(accountId: string, accountTreeId: string, fetchActualRequestBody: FetchActualRequestBody, options?: AxiosRequestConfig) {
        return ActualControllerApiFp(this.configuration).accountNode(accountId, accountTreeId, fetchActualRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 会計実績仕訳の出力をExcelでダウンロードする
     * @param {string} accountId 
     * @param {string} accountTreeId 
     * @param {DownloadActualExcelRequestBody} downloadActualExcelRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualControllerApi
     */
    public downloadAccountNodeExcel(accountId: string, accountTreeId: string, downloadActualExcelRequestBody: DownloadActualExcelRequestBody, options?: AxiosRequestConfig) {
        return ActualControllerApiFp(this.configuration).downloadAccountNodeExcel(accountId, accountTreeId, downloadActualExcelRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActualsImportControllerApi - axios parameter creator
 * @export
 */
export const ActualsImportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImportEvent: async (importEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importEventId' is not null or undefined
            assertParamExists('deleteImportEvent', 'importEventId', importEventId)
            const localVarPath = `/api/upload-actual/history/{importEventId}`
                .replace(`{${"importEventId"}}`, encodeURIComponent(String(importEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActualImportFile: async (importEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importEventId' is not null or undefined
            assertParamExists('downloadActualImportFile', 'importEventId', importEventId)
            const localVarPath = `/api/upload-actual/{importEventId}/file`
                .replace(`{${"importEventId"}}`, encodeURIComponent(String(importEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadErrorExcel: async (importEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importEventId' is not null or undefined
            assertParamExists('downloadErrorExcel', 'importEventId', importEventId)
            const localVarPath = `/api/upload-actual/{importEventId}/error_excel`
                .replace(`{${"importEventId"}}`, encodeURIComponent(String(importEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActualsImports: async (importEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importEventId' is not null or undefined
            assertParamExists('findActualsImports', 'importEventId', importEventId)
            const localVarPath = `/api/upload-actual/history/{importEventId}`
                .replace(`{${"importEventId"}}`, encodeURIComponent(String(importEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取込設定を用いた財務実績アップロードで利用する型生成用
         * @param {ForOpenApiGeneratorRequest} [forOpenApiGeneratorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOpenApiGenerator: async (forOpenApiGeneratorRequest?: ForOpenApiGeneratorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/upload-actual/forOnlyOpenApiGenerator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forOpenApiGeneratorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT'} sortKey 
         * @param {boolean} isAsc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActualsImports: async (limit: number, offset: number, sortKey: 'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT', isAsc: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('listActualsImports', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('listActualsImports', 'offset', offset)
            // verify required parameter 'sortKey' is not null or undefined
            assertParamExists('listActualsImports', 'sortKey', sortKey)
            // verify required parameter 'isAsc' is not null or undefined
            assertParamExists('listActualsImports', 'isAsc', isAsc)
            const localVarPath = `/api/upload-actual/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sortKey !== undefined) {
                localVarQueryParameter['sortKey'] = sortKey;
            }

            if (isAsc !== undefined) {
                localVarQueryParameter['isAsc'] = isAsc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {UploadActualByMappingParams} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadActualByMapping: async (file: File, params: UploadActualByMappingParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadActualByMapping', 'file', file)
            // verify required parameter 'params' is not null or undefined
            assertParamExists('uploadActualByMapping', 'params', params)
            const localVarPath = `/api/upload-actual/mapping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (params !== undefined) { 
                localVarFormParams.append('params', new Blob([JSON.stringify(params)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'MIROKU' | 'KANJO_BUGYO' | 'MONEY_FORWARD' | 'MONEY_FORWARD_PLUS' | 'FREEE' | 'FREEE_V2' | 'OBIC7' | 'YAYOI' | 'SAP' | 'SAP_SALES_REPORT' | 'PCA' | 'NET_SUITE' | 'FX4' | 'FX5' | 'BIZ_INTEGRAL' | 'AI21' | 'LOGLASS_DEPARTMENTS' | 'LOGLASS_DETAIL' | 'SUPER_STREAM' | 'LOGLASS_JOURNAL' | 'LOGLASS' | 'MAPPING'} accountingSystemType 
         * @param {string} [subsidiaryCode] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadActualCSV: async (accountingSystemType: 'MIROKU' | 'KANJO_BUGYO' | 'MONEY_FORWARD' | 'MONEY_FORWARD_PLUS' | 'FREEE' | 'FREEE_V2' | 'OBIC7' | 'YAYOI' | 'SAP' | 'SAP_SALES_REPORT' | 'PCA' | 'NET_SUITE' | 'FX4' | 'FX5' | 'BIZ_INTEGRAL' | 'AI21' | 'LOGLASS_DEPARTMENTS' | 'LOGLASS_DETAIL' | 'SUPER_STREAM' | 'LOGLASS_JOURNAL' | 'LOGLASS' | 'MAPPING', subsidiaryCode?: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountingSystemType' is not null or undefined
            assertParamExists('uploadActualCSV', 'accountingSystemType', accountingSystemType)
            const localVarPath = `/api/upload-actual/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountingSystemType !== undefined) {
                localVarQueryParameter['accountingSystemType'] = accountingSystemType;
            }

            if (subsidiaryCode !== undefined) {
                localVarQueryParameter['subsidiaryCode'] = subsidiaryCode;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActualsImportControllerApi - functional programming interface
 * @export
 */
export const ActualsImportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActualsImportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImportEvent(importEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImportEvent(importEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadActualImportFile(importEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadActualImportFile(importEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadErrorExcel(importEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadErrorExcel(importEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findActualsImports(importEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActualImportEventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findActualsImports(importEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取込設定を用いた財務実績アップロードで利用する型生成用
         * @param {ForOpenApiGeneratorRequest} [forOpenApiGeneratorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forOpenApiGenerator(forOpenApiGeneratorRequest?: ForOpenApiGeneratorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forOpenApiGenerator(forOpenApiGeneratorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT'} sortKey 
         * @param {boolean} isAsc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActualsImports(limit: number, offset: number, sortKey: 'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT', isAsc: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoActualImportEventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActualsImports(limit, offset, sortKey, isAsc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {UploadActualByMappingParams} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadActualByMapping(file: File, params: UploadActualByMappingParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActualImportEventView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadActualByMapping(file, params, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'MIROKU' | 'KANJO_BUGYO' | 'MONEY_FORWARD' | 'MONEY_FORWARD_PLUS' | 'FREEE' | 'FREEE_V2' | 'OBIC7' | 'YAYOI' | 'SAP' | 'SAP_SALES_REPORT' | 'PCA' | 'NET_SUITE' | 'FX4' | 'FX5' | 'BIZ_INTEGRAL' | 'AI21' | 'LOGLASS_DEPARTMENTS' | 'LOGLASS_DETAIL' | 'SUPER_STREAM' | 'LOGLASS_JOURNAL' | 'LOGLASS' | 'MAPPING'} accountingSystemType 
         * @param {string} [subsidiaryCode] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadActualCSV(accountingSystemType: 'MIROKU' | 'KANJO_BUGYO' | 'MONEY_FORWARD' | 'MONEY_FORWARD_PLUS' | 'FREEE' | 'FREEE_V2' | 'OBIC7' | 'YAYOI' | 'SAP' | 'SAP_SALES_REPORT' | 'PCA' | 'NET_SUITE' | 'FX4' | 'FX5' | 'BIZ_INTEGRAL' | 'AI21' | 'LOGLASS_DEPARTMENTS' | 'LOGLASS_DETAIL' | 'SUPER_STREAM' | 'LOGLASS_JOURNAL' | 'LOGLASS' | 'MAPPING', subsidiaryCode?: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActualImportEventView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadActualCSV(accountingSystemType, subsidiaryCode, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActualsImportControllerApi - factory interface
 * @export
 */
export const ActualsImportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActualsImportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImportEvent(importEventId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteImportEvent(importEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActualImportFile(importEventId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadActualImportFile(importEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadErrorExcel(importEventId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadErrorExcel(importEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActualsImports(importEventId: string, options?: any): AxiosPromise<ActualImportEventDto> {
            return localVarFp.findActualsImports(importEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取込設定を用いた財務実績アップロードで利用する型生成用
         * @param {ForOpenApiGeneratorRequest} [forOpenApiGeneratorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOpenApiGenerator(forOpenApiGeneratorRequest?: ForOpenApiGeneratorRequest, options?: any): AxiosPromise<void> {
            return localVarFp.forOpenApiGenerator(forOpenApiGeneratorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT'} sortKey 
         * @param {boolean} isAsc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActualsImports(limit: number, offset: number, sortKey: 'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT', isAsc: boolean, options?: any): AxiosPromise<PageDtoActualImportEventDto> {
            return localVarFp.listActualsImports(limit, offset, sortKey, isAsc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {UploadActualByMappingParams} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadActualByMapping(file: File, params: UploadActualByMappingParams, options?: any): AxiosPromise<ActualImportEventView> {
            return localVarFp.uploadActualByMapping(file, params, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'MIROKU' | 'KANJO_BUGYO' | 'MONEY_FORWARD' | 'MONEY_FORWARD_PLUS' | 'FREEE' | 'FREEE_V2' | 'OBIC7' | 'YAYOI' | 'SAP' | 'SAP_SALES_REPORT' | 'PCA' | 'NET_SUITE' | 'FX4' | 'FX5' | 'BIZ_INTEGRAL' | 'AI21' | 'LOGLASS_DEPARTMENTS' | 'LOGLASS_DETAIL' | 'SUPER_STREAM' | 'LOGLASS_JOURNAL' | 'LOGLASS' | 'MAPPING'} accountingSystemType 
         * @param {string} [subsidiaryCode] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadActualCSV(accountingSystemType: 'MIROKU' | 'KANJO_BUGYO' | 'MONEY_FORWARD' | 'MONEY_FORWARD_PLUS' | 'FREEE' | 'FREEE_V2' | 'OBIC7' | 'YAYOI' | 'SAP' | 'SAP_SALES_REPORT' | 'PCA' | 'NET_SUITE' | 'FX4' | 'FX5' | 'BIZ_INTEGRAL' | 'AI21' | 'LOGLASS_DEPARTMENTS' | 'LOGLASS_DETAIL' | 'SUPER_STREAM' | 'LOGLASS_JOURNAL' | 'LOGLASS' | 'MAPPING', subsidiaryCode?: string, file?: File, options?: any): AxiosPromise<ActualImportEventView> {
            return localVarFp.uploadActualCSV(accountingSystemType, subsidiaryCode, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActualsImportControllerApi - object-oriented interface
 * @export
 * @class ActualsImportControllerApi
 * @extends {BaseAPI}
 */
export class ActualsImportControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} importEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportControllerApi
     */
    public deleteImportEvent(importEventId: string, options?: AxiosRequestConfig) {
        return ActualsImportControllerApiFp(this.configuration).deleteImportEvent(importEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} importEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportControllerApi
     */
    public downloadActualImportFile(importEventId: string, options?: AxiosRequestConfig) {
        return ActualsImportControllerApiFp(this.configuration).downloadActualImportFile(importEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} importEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportControllerApi
     */
    public downloadErrorExcel(importEventId: string, options?: AxiosRequestConfig) {
        return ActualsImportControllerApiFp(this.configuration).downloadErrorExcel(importEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} importEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportControllerApi
     */
    public findActualsImports(importEventId: string, options?: AxiosRequestConfig) {
        return ActualsImportControllerApiFp(this.configuration).findActualsImports(importEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取込設定を用いた財務実績アップロードで利用する型生成用
     * @param {ForOpenApiGeneratorRequest} [forOpenApiGeneratorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportControllerApi
     */
    public forOpenApiGenerator(forOpenApiGeneratorRequest?: ForOpenApiGeneratorRequest, options?: AxiosRequestConfig) {
        return ActualsImportControllerApiFp(this.configuration).forOpenApiGenerator(forOpenApiGeneratorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT'} sortKey 
     * @param {boolean} isAsc 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportControllerApi
     */
    public listActualsImports(limit: number, offset: number, sortKey: 'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT', isAsc: boolean, options?: AxiosRequestConfig) {
        return ActualsImportControllerApiFp(this.configuration).listActualsImports(limit, offset, sortKey, isAsc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {UploadActualByMappingParams} params 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportControllerApi
     */
    public uploadActualByMapping(file: File, params: UploadActualByMappingParams, options?: AxiosRequestConfig) {
        return ActualsImportControllerApiFp(this.configuration).uploadActualByMapping(file, params, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'MIROKU' | 'KANJO_BUGYO' | 'MONEY_FORWARD' | 'MONEY_FORWARD_PLUS' | 'FREEE' | 'FREEE_V2' | 'OBIC7' | 'YAYOI' | 'SAP' | 'SAP_SALES_REPORT' | 'PCA' | 'NET_SUITE' | 'FX4' | 'FX5' | 'BIZ_INTEGRAL' | 'AI21' | 'LOGLASS_DEPARTMENTS' | 'LOGLASS_DETAIL' | 'SUPER_STREAM' | 'LOGLASS_JOURNAL' | 'LOGLASS' | 'MAPPING'} accountingSystemType 
     * @param {string} [subsidiaryCode] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportControllerApi
     */
    public uploadActualCSV(accountingSystemType: 'MIROKU' | 'KANJO_BUGYO' | 'MONEY_FORWARD' | 'MONEY_FORWARD_PLUS' | 'FREEE' | 'FREEE_V2' | 'OBIC7' | 'YAYOI' | 'SAP' | 'SAP_SALES_REPORT' | 'PCA' | 'NET_SUITE' | 'FX4' | 'FX5' | 'BIZ_INTEGRAL' | 'AI21' | 'LOGLASS_DEPARTMENTS' | 'LOGLASS_DETAIL' | 'SUPER_STREAM' | 'LOGLASS_JOURNAL' | 'LOGLASS' | 'MAPPING', subsidiaryCode?: string, file?: File, options?: AxiosRequestConfig) {
        return ActualsImportControllerApiFp(this.configuration).uploadActualCSV(accountingSystemType, subsidiaryCode, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActualsImportMappingControllerApi - axios parameter creator
 * @export
 */
export const ActualsImportMappingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 実績取込設定新規作成
         * @param {CreateActualsImportMappingParams} createActualsImportMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActualsImportMapping: async (createActualsImportMappingParams: CreateActualsImportMappingParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createActualsImportMappingParams' is not null or undefined
            assertParamExists('createActualsImportMapping', 'createActualsImportMappingParams', createActualsImportMappingParams)
            const localVarPath = `/api/actualsImportMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createActualsImportMappingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 実績取込設定削除
         * @param {string} actualsImportMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActualsImportMapping: async (actualsImportMappingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actualsImportMappingId' is not null or undefined
            assertParamExists('deleteActualsImportMapping', 'actualsImportMappingId', actualsImportMappingId)
            const localVarPath = `/api/actualsImportMappings/{actualsImportMappingId}`
                .replace(`{${"actualsImportMappingId"}}`, encodeURIComponent(String(actualsImportMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 実績取込設定
         * @param {string} actualsImportMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActualsImportMapping: async (actualsImportMappingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actualsImportMappingId' is not null or undefined
            assertParamExists('findActualsImportMapping', 'actualsImportMappingId', actualsImportMappingId)
            const localVarPath = `/api/actualsImportMappings/{actualsImportMappingId}`
                .replace(`{${"actualsImportMappingId"}}`, encodeURIComponent(String(actualsImportMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 実績取込設定一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActualsImportMappings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/actualsImportMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 実績取込設定更新
         * @param {string} actualsImportMappingId 
         * @param {UpdateActualsImportMappingParams} updateActualsImportMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActualsImportMapping: async (actualsImportMappingId: string, updateActualsImportMappingParams: UpdateActualsImportMappingParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actualsImportMappingId' is not null or undefined
            assertParamExists('updateActualsImportMapping', 'actualsImportMappingId', actualsImportMappingId)
            // verify required parameter 'updateActualsImportMappingParams' is not null or undefined
            assertParamExists('updateActualsImportMapping', 'updateActualsImportMappingParams', updateActualsImportMappingParams)
            const localVarPath = `/api/actualsImportMappings/{actualsImportMappingId}`
                .replace(`{${"actualsImportMappingId"}}`, encodeURIComponent(String(actualsImportMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActualsImportMappingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActualsImportMappingControllerApi - functional programming interface
 * @export
 */
export const ActualsImportMappingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActualsImportMappingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 実績取込設定新規作成
         * @param {CreateActualsImportMappingParams} createActualsImportMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActualsImportMapping(createActualsImportMappingParams: CreateActualsImportMappingParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActualsImportMapping(createActualsImportMappingParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 実績取込設定削除
         * @param {string} actualsImportMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActualsImportMapping(actualsImportMappingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActualsImportMapping(actualsImportMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 実績取込設定
         * @param {string} actualsImportMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findActualsImportMapping(actualsImportMappingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActualImportMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findActualsImportMapping(actualsImportMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 実績取込設定一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActualsImportMappings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActualsImportMappingView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActualsImportMappings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 実績取込設定更新
         * @param {string} actualsImportMappingId 
         * @param {UpdateActualsImportMappingParams} updateActualsImportMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActualsImportMapping(actualsImportMappingId: string, updateActualsImportMappingParams: UpdateActualsImportMappingParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActualsImportMapping(actualsImportMappingId, updateActualsImportMappingParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActualsImportMappingControllerApi - factory interface
 * @export
 */
export const ActualsImportMappingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActualsImportMappingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 実績取込設定新規作成
         * @param {CreateActualsImportMappingParams} createActualsImportMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActualsImportMapping(createActualsImportMappingParams: CreateActualsImportMappingParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createActualsImportMapping(createActualsImportMappingParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 実績取込設定削除
         * @param {string} actualsImportMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActualsImportMapping(actualsImportMappingId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteActualsImportMapping(actualsImportMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 実績取込設定
         * @param {string} actualsImportMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActualsImportMapping(actualsImportMappingId: string, options?: any): AxiosPromise<ActualImportMapping> {
            return localVarFp.findActualsImportMapping(actualsImportMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 実績取込設定一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActualsImportMappings(options?: any): AxiosPromise<Array<ActualsImportMappingView>> {
            return localVarFp.listActualsImportMappings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 実績取込設定更新
         * @param {string} actualsImportMappingId 
         * @param {UpdateActualsImportMappingParams} updateActualsImportMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActualsImportMapping(actualsImportMappingId: string, updateActualsImportMappingParams: UpdateActualsImportMappingParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateActualsImportMapping(actualsImportMappingId, updateActualsImportMappingParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActualsImportMappingControllerApi - object-oriented interface
 * @export
 * @class ActualsImportMappingControllerApi
 * @extends {BaseAPI}
 */
export class ActualsImportMappingControllerApi extends BaseAPI {
    /**
     * 
     * @summary 実績取込設定新規作成
     * @param {CreateActualsImportMappingParams} createActualsImportMappingParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportMappingControllerApi
     */
    public createActualsImportMapping(createActualsImportMappingParams: CreateActualsImportMappingParams, options?: AxiosRequestConfig) {
        return ActualsImportMappingControllerApiFp(this.configuration).createActualsImportMapping(createActualsImportMappingParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 実績取込設定削除
     * @param {string} actualsImportMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportMappingControllerApi
     */
    public deleteActualsImportMapping(actualsImportMappingId: string, options?: AxiosRequestConfig) {
        return ActualsImportMappingControllerApiFp(this.configuration).deleteActualsImportMapping(actualsImportMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 実績取込設定
     * @param {string} actualsImportMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportMappingControllerApi
     */
    public findActualsImportMapping(actualsImportMappingId: string, options?: AxiosRequestConfig) {
        return ActualsImportMappingControllerApiFp(this.configuration).findActualsImportMapping(actualsImportMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 実績取込設定一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportMappingControllerApi
     */
    public listActualsImportMappings(options?: AxiosRequestConfig) {
        return ActualsImportMappingControllerApiFp(this.configuration).listActualsImportMappings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 実績取込設定更新
     * @param {string} actualsImportMappingId 
     * @param {UpdateActualsImportMappingParams} updateActualsImportMappingParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActualsImportMappingControllerApi
     */
    public updateActualsImportMapping(actualsImportMappingId: string, updateActualsImportMappingParams: UpdateActualsImportMappingParams, options?: AxiosRequestConfig) {
        return ActualsImportMappingControllerApiFp(this.configuration).updateActualsImportMapping(actualsImportMappingId, updateActualsImportMappingParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AiPlanActualAnalysisReportControllerApi - axios parameter creator
 * @export
 */
export const AiPlanActualAnalysisReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AiPlanActualAnalysisConditionParam} aiPlanActualAnalysisConditionParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAiPlanActualAnalysisReport: async (aiPlanActualAnalysisConditionParam: AiPlanActualAnalysisConditionParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiPlanActualAnalysisConditionParam' is not null or undefined
            assertParamExists('createAiPlanActualAnalysisReport', 'aiPlanActualAnalysisConditionParam', aiPlanActualAnalysisConditionParam)
            const localVarPath = `/api/ai-plan-actual-analysis-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiPlanActualAnalysisConditionParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AI予実分析レポート条件ツリーノードの削除
         * @param {string} aiPlanActualAnalysisReportId 
         * @param {string} conditionNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAiPlanActualAnalysisReportConditionTreeNode: async (aiPlanActualAnalysisReportId: string, conditionNodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiPlanActualAnalysisReportId' is not null or undefined
            assertParamExists('deleteAiPlanActualAnalysisReportConditionTreeNode', 'aiPlanActualAnalysisReportId', aiPlanActualAnalysisReportId)
            // verify required parameter 'conditionNodeId' is not null or undefined
            assertParamExists('deleteAiPlanActualAnalysisReportConditionTreeNode', 'conditionNodeId', conditionNodeId)
            const localVarPath = `/api/ai-plan-actual-analysis-report/{aiPlanActualAnalysisReportId}/condition-trees/nodes/{conditionNodeId}`
                .replace(`{${"aiPlanActualAnalysisReportId"}}`, encodeURIComponent(String(aiPlanActualAnalysisReportId)))
                .replace(`{${"conditionNodeId"}}`, encodeURIComponent(String(conditionNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAiPlanActualAnalysisReportCondition: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisReportCondition', 'reportId', reportId)
            const localVarPath = `/api/ai-plan-actual-analysis-report/{reportId}/condition`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AI予実分析レポート条件ツリーの更新
         * @param {string} reportId 
         * @param {UpdateAiPlanActualAnalysisReportConditionTreeRequestBody} updateAiPlanActualAnalysisReportConditionTreeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAiPlanActualAnalysisReportConditionTree: async (reportId: string, updateAiPlanActualAnalysisReportConditionTreeRequestBody: UpdateAiPlanActualAnalysisReportConditionTreeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateAiPlanActualAnalysisReportConditionTree', 'reportId', reportId)
            // verify required parameter 'updateAiPlanActualAnalysisReportConditionTreeRequestBody' is not null or undefined
            assertParamExists('updateAiPlanActualAnalysisReportConditionTree', 'updateAiPlanActualAnalysisReportConditionTreeRequestBody', updateAiPlanActualAnalysisReportConditionTreeRequestBody)
            const localVarPath = `/api/ai-plan-actual-analysis-report/{reportId}/condition-trees`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAiPlanActualAnalysisReportConditionTreeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiPlanActualAnalysisReportControllerApi - functional programming interface
 * @export
 */
export const AiPlanActualAnalysisReportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiPlanActualAnalysisReportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AiPlanActualAnalysisConditionParam} aiPlanActualAnalysisConditionParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAiPlanActualAnalysisReport(aiPlanActualAnalysisConditionParam: AiPlanActualAnalysisConditionParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAiPlanActualAnalysisReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAiPlanActualAnalysisReport(aiPlanActualAnalysisConditionParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary AI予実分析レポート条件ツリーノードの削除
         * @param {string} aiPlanActualAnalysisReportId 
         * @param {string} conditionNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAiPlanActualAnalysisReportConditionTreeNode(aiPlanActualAnalysisReportId: string, conditionNodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAiPlanActualAnalysisReportConditionTreeNode(aiPlanActualAnalysisReportId, conditionNodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAiPlanActualAnalysisReportCondition(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiPlanActualAnalysisReportConditionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAiPlanActualAnalysisReportCondition(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary AI予実分析レポート条件ツリーの更新
         * @param {string} reportId 
         * @param {UpdateAiPlanActualAnalysisReportConditionTreeRequestBody} updateAiPlanActualAnalysisReportConditionTreeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAiPlanActualAnalysisReportConditionTree(reportId: string, updateAiPlanActualAnalysisReportConditionTreeRequestBody: UpdateAiPlanActualAnalysisReportConditionTreeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAiPlanActualAnalysisReportConditionTreeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAiPlanActualAnalysisReportConditionTree(reportId, updateAiPlanActualAnalysisReportConditionTreeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiPlanActualAnalysisReportControllerApi - factory interface
 * @export
 */
export const AiPlanActualAnalysisReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiPlanActualAnalysisReportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AiPlanActualAnalysisConditionParam} aiPlanActualAnalysisConditionParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAiPlanActualAnalysisReport(aiPlanActualAnalysisConditionParam: AiPlanActualAnalysisConditionParam, options?: any): AxiosPromise<CreateAiPlanActualAnalysisReportResponse> {
            return localVarFp.createAiPlanActualAnalysisReport(aiPlanActualAnalysisConditionParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AI予実分析レポート条件ツリーノードの削除
         * @param {string} aiPlanActualAnalysisReportId 
         * @param {string} conditionNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAiPlanActualAnalysisReportConditionTreeNode(aiPlanActualAnalysisReportId: string, conditionNodeId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteAiPlanActualAnalysisReportConditionTreeNode(aiPlanActualAnalysisReportId, conditionNodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAiPlanActualAnalysisReportCondition(reportId: string, options?: any): AxiosPromise<AiPlanActualAnalysisReportConditionView> {
            return localVarFp.fetchAiPlanActualAnalysisReportCondition(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AI予実分析レポート条件ツリーの更新
         * @param {string} reportId 
         * @param {UpdateAiPlanActualAnalysisReportConditionTreeRequestBody} updateAiPlanActualAnalysisReportConditionTreeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAiPlanActualAnalysisReportConditionTree(reportId: string, updateAiPlanActualAnalysisReportConditionTreeRequestBody: UpdateAiPlanActualAnalysisReportConditionTreeRequestBody, options?: any): AxiosPromise<UpdateAiPlanActualAnalysisReportConditionTreeResponse> {
            return localVarFp.updateAiPlanActualAnalysisReportConditionTree(reportId, updateAiPlanActualAnalysisReportConditionTreeRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiPlanActualAnalysisReportControllerApi - object-oriented interface
 * @export
 * @class AiPlanActualAnalysisReportControllerApi
 * @extends {BaseAPI}
 */
export class AiPlanActualAnalysisReportControllerApi extends BaseAPI {
    /**
     * 
     * @param {AiPlanActualAnalysisConditionParam} aiPlanActualAnalysisConditionParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiPlanActualAnalysisReportControllerApi
     */
    public createAiPlanActualAnalysisReport(aiPlanActualAnalysisConditionParam: AiPlanActualAnalysisConditionParam, options?: AxiosRequestConfig) {
        return AiPlanActualAnalysisReportControllerApiFp(this.configuration).createAiPlanActualAnalysisReport(aiPlanActualAnalysisConditionParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AI予実分析レポート条件ツリーノードの削除
     * @param {string} aiPlanActualAnalysisReportId 
     * @param {string} conditionNodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiPlanActualAnalysisReportControllerApi
     */
    public deleteAiPlanActualAnalysisReportConditionTreeNode(aiPlanActualAnalysisReportId: string, conditionNodeId: string, options?: AxiosRequestConfig) {
        return AiPlanActualAnalysisReportControllerApiFp(this.configuration).deleteAiPlanActualAnalysisReportConditionTreeNode(aiPlanActualAnalysisReportId, conditionNodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiPlanActualAnalysisReportControllerApi
     */
    public fetchAiPlanActualAnalysisReportCondition(reportId: string, options?: AxiosRequestConfig) {
        return AiPlanActualAnalysisReportControllerApiFp(this.configuration).fetchAiPlanActualAnalysisReportCondition(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AI予実分析レポート条件ツリーの更新
     * @param {string} reportId 
     * @param {UpdateAiPlanActualAnalysisReportConditionTreeRequestBody} updateAiPlanActualAnalysisReportConditionTreeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiPlanActualAnalysisReportControllerApi
     */
    public updateAiPlanActualAnalysisReportConditionTree(reportId: string, updateAiPlanActualAnalysisReportConditionTreeRequestBody: UpdateAiPlanActualAnalysisReportConditionTreeRequestBody, options?: AxiosRequestConfig) {
        return AiPlanActualAnalysisReportControllerApiFp(this.configuration).updateAiPlanActualAnalysisReportConditionTree(reportId, updateAiPlanActualAnalysisReportConditionTreeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationExecuteControllerApi - axios parameter creator
 * @export
 */
export const AllocationExecuteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 振替・配賦を実行する
         * @param {ExecuteAllocationRequestBody} executeAllocationRequestBody 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAllocation: async (executeAllocationRequestBody: ExecuteAllocationRequestBody, planId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executeAllocationRequestBody' is not null or undefined
            assertParamExists('executeAllocation', 'executeAllocationRequestBody', executeAllocationRequestBody)
            const localVarPath = `/api/allocation-execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(executeAllocationRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦を非同期に実行する
         * @param {ExecuteAllocationRequestBody} executeAllocationRequestBody 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAllocationAsync: async (executeAllocationRequestBody: ExecuteAllocationRequestBody, planId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executeAllocationRequestBody' is not null or undefined
            assertParamExists('executeAllocationAsync', 'executeAllocationRequestBody', executeAllocationRequestBody)
            const localVarPath = `/api/allocation-execute/async`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(executeAllocationRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationExecuteControllerApi - functional programming interface
 * @export
 */
export const AllocationExecuteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationExecuteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 振替・配賦を実行する
         * @param {ExecuteAllocationRequestBody} executeAllocationRequestBody 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeAllocation(executeAllocationRequestBody: ExecuteAllocationRequestBody, planId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeAllocation(executeAllocationRequestBody, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦を非同期に実行する
         * @param {ExecuteAllocationRequestBody} executeAllocationRequestBody 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeAllocationAsync(executeAllocationRequestBody: ExecuteAllocationRequestBody, planId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeAllocationAsync(executeAllocationRequestBody, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationExecuteControllerApi - factory interface
 * @export
 */
export const AllocationExecuteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationExecuteControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 振替・配賦を実行する
         * @param {ExecuteAllocationRequestBody} executeAllocationRequestBody 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAllocation(executeAllocationRequestBody: ExecuteAllocationRequestBody, planId?: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.executeAllocation(executeAllocationRequestBody, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦を非同期に実行する
         * @param {ExecuteAllocationRequestBody} executeAllocationRequestBody 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAllocationAsync(executeAllocationRequestBody: ExecuteAllocationRequestBody, planId?: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.executeAllocationAsync(executeAllocationRequestBody, planId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocationExecuteControllerApi - object-oriented interface
 * @export
 * @class AllocationExecuteControllerApi
 * @extends {BaseAPI}
 */
export class AllocationExecuteControllerApi extends BaseAPI {
    /**
     * 
     * @summary 振替・配賦を実行する
     * @param {ExecuteAllocationRequestBody} executeAllocationRequestBody 
     * @param {string} [planId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationExecuteControllerApi
     */
    public executeAllocation(executeAllocationRequestBody: ExecuteAllocationRequestBody, planId?: string, options?: AxiosRequestConfig) {
        return AllocationExecuteControllerApiFp(this.configuration).executeAllocation(executeAllocationRequestBody, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦を非同期に実行する
     * @param {ExecuteAllocationRequestBody} executeAllocationRequestBody 
     * @param {string} [planId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationExecuteControllerApi
     */
    public executeAllocationAsync(executeAllocationRequestBody: ExecuteAllocationRequestBody, planId?: string, options?: AxiosRequestConfig) {
        return AllocationExecuteControllerApiFp(this.configuration).executeAllocationAsync(executeAllocationRequestBody, planId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationResultsControllerApi - axios parameter creator
 * @export
 */
export const AllocationResultsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 配賦実行結果をCSVでダウンロードする
         * @param {'ACTUAL' | 'PLAN'} targetType 
         * @param {Array<string>} yearMonthSet 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocationResultsCsv: async (targetType: 'ACTUAL' | 'PLAN', yearMonthSet: Array<string>, planId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetType' is not null or undefined
            assertParamExists('getAllocationResultsCsv', 'targetType', targetType)
            // verify required parameter 'yearMonthSet' is not null or undefined
            assertParamExists('getAllocationResultsCsv', 'yearMonthSet', yearMonthSet)
            const localVarPath = `/api/allocation-results/strategies/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }

            if (yearMonthSet) {
                localVarQueryParameter['yearMonthSet'] = yearMonthSet;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationResultsControllerApi - functional programming interface
 * @export
 */
export const AllocationResultsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationResultsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 配賦実行結果をCSVでダウンロードする
         * @param {'ACTUAL' | 'PLAN'} targetType 
         * @param {Array<string>} yearMonthSet 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllocationResultsCsv(targetType: 'ACTUAL' | 'PLAN', yearMonthSet: Array<string>, planId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllocationResultsCsv(targetType, yearMonthSet, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationResultsControllerApi - factory interface
 * @export
 */
export const AllocationResultsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationResultsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 配賦実行結果をCSVでダウンロードする
         * @param {'ACTUAL' | 'PLAN'} targetType 
         * @param {Array<string>} yearMonthSet 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocationResultsCsv(targetType: 'ACTUAL' | 'PLAN', yearMonthSet: Array<string>, planId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getAllocationResultsCsv(targetType, yearMonthSet, planId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocationResultsControllerApi - object-oriented interface
 * @export
 * @class AllocationResultsControllerApi
 * @extends {BaseAPI}
 */
export class AllocationResultsControllerApi extends BaseAPI {
    /**
     * 
     * @summary 配賦実行結果をCSVでダウンロードする
     * @param {'ACTUAL' | 'PLAN'} targetType 
     * @param {Array<string>} yearMonthSet 
     * @param {string} [planId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationResultsControllerApi
     */
    public getAllocationResultsCsv(targetType: 'ACTUAL' | 'PLAN', yearMonthSet: Array<string>, planId?: string, options?: AxiosRequestConfig) {
        return AllocationResultsControllerApiFp(this.configuration).getAllocationResultsCsv(targetType, yearMonthSet, planId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationRuleControllerApi - axios parameter creator
 * @export
 */
export const AllocationRuleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 振替・配賦ルールの作成
         * @param {CreateAllocationRuleRequestBody} createAllocationRuleRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRules: async (createAllocationRuleRequestBody: CreateAllocationRuleRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAllocationRuleRequestBody' is not null or undefined
            assertParamExists('createRules', 'createAllocationRuleRequestBody', createAllocationRuleRequestBody)
            const localVarPath = `/api/allocations/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllocationRuleRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦ルールの削除
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRules: async (ruleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('deleteRules', 'ruleId', ruleId)
            const localVarPath = `/api/allocations/rules/{ruleId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替or配賦ステップの削除
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStep: async (ruleId: string, stepId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('deleteStep', 'ruleId', ruleId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('deleteStep', 'stepId', stepId)
            const localVarPath = `/api/allocations/rules/{ruleId}/steps/{stepId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 既存の振替・配賦ルールを複製
         * @param {string} ruleId 
         * @param {CreateAllocationRuleRequestBody} createAllocationRuleRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate: async (ruleId: string, createAllocationRuleRequestBody: CreateAllocationRuleRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('duplicate', 'ruleId', ruleId)
            // verify required parameter 'createAllocationRuleRequestBody' is not null or undefined
            assertParamExists('duplicate', 'createAllocationRuleRequestBody', createAllocationRuleRequestBody)
            const localVarPath = `/api/allocations/rules/{ruleId}/duplicate`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllocationRuleRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦ルールの取得
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRule: async (ruleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('findRule', 'ruleId', ruleId)
            const localVarPath = `/api/allocations/rules/{ruleId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦ルール一覧の取得
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRules: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocations/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦ルールの更新
         * @param {string} ruleId 
         * @param {UpdateAllocationRuleRequestBody} updateAllocationRuleRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRules: async (ruleId: string, updateAllocationRuleRequestBody: UpdateAllocationRuleRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('updateRules', 'ruleId', ruleId)
            // verify required parameter 'updateAllocationRuleRequestBody' is not null or undefined
            assertParamExists('updateRules', 'updateAllocationRuleRequestBody', updateAllocationRuleRequestBody)
            const localVarPath = `/api/allocations/rules/{ruleId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllocationRuleRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationRuleControllerApi - functional programming interface
 * @export
 */
export const AllocationRuleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationRuleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 振替・配賦ルールの作成
         * @param {CreateAllocationRuleRequestBody} createAllocationRuleRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRules(createAllocationRuleRequestBody: CreateAllocationRuleRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationRuleSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRules(createAllocationRuleRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦ルールの削除
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRules(ruleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRules(ruleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替or配賦ステップの削除
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStep(ruleId: string, stepId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStep(ruleId, stepId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 既存の振替・配賦ルールを複製
         * @param {string} ruleId 
         * @param {CreateAllocationRuleRequestBody} createAllocationRuleRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicate(ruleId: string, createAllocationRuleRequestBody: CreateAllocationRuleRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicate(ruleId, createAllocationRuleRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦ルールの取得
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRule(ruleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationRuleDetailSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRule(ruleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦ルール一覧の取得
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRules(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoAllocationRuleSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRules(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦ルールの更新
         * @param {string} ruleId 
         * @param {UpdateAllocationRuleRequestBody} updateAllocationRuleRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRules(ruleId: string, updateAllocationRuleRequestBody: UpdateAllocationRuleRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationRuleSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRules(ruleId, updateAllocationRuleRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationRuleControllerApi - factory interface
 * @export
 */
export const AllocationRuleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationRuleControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 振替・配賦ルールの作成
         * @param {CreateAllocationRuleRequestBody} createAllocationRuleRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRules(createAllocationRuleRequestBody: CreateAllocationRuleRequestBody, options?: any): AxiosPromise<AllocationRuleSummaryDto> {
            return localVarFp.createRules(createAllocationRuleRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦ルールの削除
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRules(ruleId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteRules(ruleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替or配賦ステップの削除
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStep(ruleId: string, stepId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteStep(ruleId, stepId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 既存の振替・配賦ルールを複製
         * @param {string} ruleId 
         * @param {CreateAllocationRuleRequestBody} createAllocationRuleRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate(ruleId: string, createAllocationRuleRequestBody: CreateAllocationRuleRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.duplicate(ruleId, createAllocationRuleRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦ルールの取得
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRule(ruleId: string, options?: any): AxiosPromise<AllocationRuleDetailSummary> {
            return localVarFp.findRule(ruleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦ルール一覧の取得
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRules(limit?: number, offset?: number, options?: any): AxiosPromise<PageDtoAllocationRuleSummaryDto> {
            return localVarFp.listRules(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦ルールの更新
         * @param {string} ruleId 
         * @param {UpdateAllocationRuleRequestBody} updateAllocationRuleRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRules(ruleId: string, updateAllocationRuleRequestBody: UpdateAllocationRuleRequestBody, options?: any): AxiosPromise<AllocationRuleSummaryDto> {
            return localVarFp.updateRules(ruleId, updateAllocationRuleRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocationRuleControllerApi - object-oriented interface
 * @export
 * @class AllocationRuleControllerApi
 * @extends {BaseAPI}
 */
export class AllocationRuleControllerApi extends BaseAPI {
    /**
     * 
     * @summary 振替・配賦ルールの作成
     * @param {CreateAllocationRuleRequestBody} createAllocationRuleRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRuleControllerApi
     */
    public createRules(createAllocationRuleRequestBody: CreateAllocationRuleRequestBody, options?: AxiosRequestConfig) {
        return AllocationRuleControllerApiFp(this.configuration).createRules(createAllocationRuleRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦ルールの削除
     * @param {string} ruleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRuleControllerApi
     */
    public deleteRules(ruleId: string, options?: AxiosRequestConfig) {
        return AllocationRuleControllerApiFp(this.configuration).deleteRules(ruleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替or配賦ステップの削除
     * @param {string} ruleId 
     * @param {string} stepId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRuleControllerApi
     */
    public deleteStep(ruleId: string, stepId: string, options?: AxiosRequestConfig) {
        return AllocationRuleControllerApiFp(this.configuration).deleteStep(ruleId, stepId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 既存の振替・配賦ルールを複製
     * @param {string} ruleId 
     * @param {CreateAllocationRuleRequestBody} createAllocationRuleRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRuleControllerApi
     */
    public duplicate(ruleId: string, createAllocationRuleRequestBody: CreateAllocationRuleRequestBody, options?: AxiosRequestConfig) {
        return AllocationRuleControllerApiFp(this.configuration).duplicate(ruleId, createAllocationRuleRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦ルールの取得
     * @param {string} ruleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRuleControllerApi
     */
    public findRule(ruleId: string, options?: AxiosRequestConfig) {
        return AllocationRuleControllerApiFp(this.configuration).findRule(ruleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦ルール一覧の取得
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRuleControllerApi
     */
    public listRules(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return AllocationRuleControllerApiFp(this.configuration).listRules(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦ルールの更新
     * @param {string} ruleId 
     * @param {UpdateAllocationRuleRequestBody} updateAllocationRuleRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRuleControllerApi
     */
    public updateRules(ruleId: string, updateAllocationRuleRequestBody: UpdateAllocationRuleRequestBody, options?: AxiosRequestConfig) {
        return AllocationRuleControllerApiFp(this.configuration).updateRules(ruleId, updateAllocationRuleRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationStandardControllerApi - axios parameter creator
 * @export
 */
export const AllocationStandardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 最新のLoglass内データを配賦基準に反映する
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyLatestDataToAllocationStandard: async (standardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardId' is not null or undefined
            assertParamExists('applyLatestDataToAllocationStandard', 'standardId', standardId)
            const localVarPath = `/api/allocations/standards/{standardId}/apply`
                .replace(`{${"standardId"}}`, encodeURIComponent(String(standardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦基準のExcelによる一括更新
         * @param {File} [excelFile] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        bulkUpdateAllocationStandard: async (excelFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocations/standards/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (excelFile !== undefined) { 
                localVarFormParams.append('excelFile', excelFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦基準の作成
         * @param {CreateOrUpdateAllocationStandardRequestBody} createOrUpdateAllocationStandardRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStandard: async (createOrUpdateAllocationStandardRequestBody: CreateOrUpdateAllocationStandardRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrUpdateAllocationStandardRequestBody' is not null or undefined
            assertParamExists('createStandard', 'createOrUpdateAllocationStandardRequestBody', createOrUpdateAllocationStandardRequestBody)
            const localVarPath = `/api/allocations/standards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateAllocationStandardRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦基準の削除
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStandard: async (standardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardId' is not null or undefined
            assertParamExists('deleteStandard', 'standardId', standardId)
            const localVarPath = `/api/allocations/standards/{standardId}`
                .replace(`{${"standardId"}}`, encodeURIComponent(String(standardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦基準Excelのダウンロード
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStandardExcel: async (standardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardId' is not null or undefined
            assertParamExists('downloadStandardExcel', 'standardId', standardId)
            const localVarPath = `/api/allocations/standards/{standardId}/excel`
                .replace(`{${"standardId"}}`, encodeURIComponent(String(standardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦基準の取得
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStandard: async (standardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardId' is not null or undefined
            assertParamExists('findStandard', 'standardId', standardId)
            const localVarPath = `/api/allocations/standards/{standardId}`
                .replace(`{${"standardId"}}`, encodeURIComponent(String(standardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦基準一覧の取得
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStandards: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocations/standards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦基準の更新
         * @param {string} standardId 
         * @param {CreateOrUpdateAllocationStandardRequestBody} createOrUpdateAllocationStandardRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStandard: async (standardId: string, createOrUpdateAllocationStandardRequestBody: CreateOrUpdateAllocationStandardRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardId' is not null or undefined
            assertParamExists('updateStandard', 'standardId', standardId)
            // verify required parameter 'createOrUpdateAllocationStandardRequestBody' is not null or undefined
            assertParamExists('updateStandard', 'createOrUpdateAllocationStandardRequestBody', createOrUpdateAllocationStandardRequestBody)
            const localVarPath = `/api/allocations/standards/{standardId}`
                .replace(`{${"standardId"}}`, encodeURIComponent(String(standardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateAllocationStandardRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦基準のExcelによる更新
         * @param {string} standardId 
         * @param {boolean} [isDimensionEnabled] 
         * @param {string} [dimensionId] 
         * @param {File} [excelFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYearMonthAllocationStandard: async (standardId: string, isDimensionEnabled?: boolean, dimensionId?: string, excelFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardId' is not null or undefined
            assertParamExists('updateYearMonthAllocationStandard', 'standardId', standardId)
            const localVarPath = `/api/allocations/standards/{standardId}/excel`
                .replace(`{${"standardId"}}`, encodeURIComponent(String(standardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDimensionEnabled !== undefined) {
                localVarQueryParameter['isDimensionEnabled'] = isDimensionEnabled;
            }

            if (dimensionId !== undefined) {
                localVarQueryParameter['dimensionId'] = dimensionId;
            }


            if (excelFile !== undefined) { 
                localVarFormParams.append('excelFile', excelFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationStandardControllerApi - functional programming interface
 * @export
 */
export const AllocationStandardControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationStandardControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 最新のLoglass内データを配賦基準に反映する
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyLatestDataToAllocationStandard(standardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyLatestDataToAllocationStandard(standardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦基準のExcelによる一括更新
         * @param {File} [excelFile] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async bulkUpdateAllocationStandard(excelFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateAllocationStandard(excelFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦基準の作成
         * @param {CreateOrUpdateAllocationStandardRequestBody} createOrUpdateAllocationStandardRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStandard(createOrUpdateAllocationStandardRequestBody: CreateOrUpdateAllocationStandardRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationStandardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStandard(createOrUpdateAllocationStandardRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦基準の削除
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStandard(standardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStandard(standardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦基準Excelのダウンロード
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadStandardExcel(standardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadStandardExcel(standardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦基準の取得
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStandard(standardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationStandardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStandard(standardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦基準一覧の取得
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStandards(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoAllocationStandardSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStandards(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦基準の更新
         * @param {string} standardId 
         * @param {CreateOrUpdateAllocationStandardRequestBody} createOrUpdateAllocationStandardRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStandard(standardId: string, createOrUpdateAllocationStandardRequestBody: CreateOrUpdateAllocationStandardRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStandard(standardId, createOrUpdateAllocationStandardRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦基準のExcelによる更新
         * @param {string} standardId 
         * @param {boolean} [isDimensionEnabled] 
         * @param {string} [dimensionId] 
         * @param {File} [excelFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateYearMonthAllocationStandard(standardId: string, isDimensionEnabled?: boolean, dimensionId?: string, excelFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateYearMonthAllocationStandard(standardId, isDimensionEnabled, dimensionId, excelFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationStandardControllerApi - factory interface
 * @export
 */
export const AllocationStandardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationStandardControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 最新のLoglass内データを配賦基準に反映する
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyLatestDataToAllocationStandard(standardId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.applyLatestDataToAllocationStandard(standardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦基準のExcelによる一括更新
         * @param {File} [excelFile] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        bulkUpdateAllocationStandard(excelFile?: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.bulkUpdateAllocationStandard(excelFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦基準の作成
         * @param {CreateOrUpdateAllocationStandardRequestBody} createOrUpdateAllocationStandardRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStandard(createOrUpdateAllocationStandardRequestBody: CreateOrUpdateAllocationStandardRequestBody, options?: any): AxiosPromise<AllocationStandardDto> {
            return localVarFp.createStandard(createOrUpdateAllocationStandardRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦基準の削除
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStandard(standardId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteStandard(standardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦基準Excelのダウンロード
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStandardExcel(standardId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadStandardExcel(standardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦基準の取得
         * @param {string} standardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStandard(standardId: string, options?: any): AxiosPromise<AllocationStandardDto> {
            return localVarFp.findStandard(standardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦基準一覧の取得
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStandards(limit?: number, offset?: number, options?: any): AxiosPromise<PageDtoAllocationStandardSummaryDto> {
            return localVarFp.listStandards(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦基準の更新
         * @param {string} standardId 
         * @param {CreateOrUpdateAllocationStandardRequestBody} createOrUpdateAllocationStandardRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStandard(standardId: string, createOrUpdateAllocationStandardRequestBody: CreateOrUpdateAllocationStandardRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateStandard(standardId, createOrUpdateAllocationStandardRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦基準のExcelによる更新
         * @param {string} standardId 
         * @param {boolean} [isDimensionEnabled] 
         * @param {string} [dimensionId] 
         * @param {File} [excelFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYearMonthAllocationStandard(standardId: string, isDimensionEnabled?: boolean, dimensionId?: string, excelFile?: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateYearMonthAllocationStandard(standardId, isDimensionEnabled, dimensionId, excelFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocationStandardControllerApi - object-oriented interface
 * @export
 * @class AllocationStandardControllerApi
 * @extends {BaseAPI}
 */
export class AllocationStandardControllerApi extends BaseAPI {
    /**
     * 
     * @summary 最新のLoglass内データを配賦基準に反映する
     * @param {string} standardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStandardControllerApi
     */
    public applyLatestDataToAllocationStandard(standardId: string, options?: AxiosRequestConfig) {
        return AllocationStandardControllerApiFp(this.configuration).applyLatestDataToAllocationStandard(standardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦基準のExcelによる一括更新
     * @param {File} [excelFile] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AllocationStandardControllerApi
     */
    public bulkUpdateAllocationStandard(excelFile?: File, options?: AxiosRequestConfig) {
        return AllocationStandardControllerApiFp(this.configuration).bulkUpdateAllocationStandard(excelFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦基準の作成
     * @param {CreateOrUpdateAllocationStandardRequestBody} createOrUpdateAllocationStandardRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStandardControllerApi
     */
    public createStandard(createOrUpdateAllocationStandardRequestBody: CreateOrUpdateAllocationStandardRequestBody, options?: AxiosRequestConfig) {
        return AllocationStandardControllerApiFp(this.configuration).createStandard(createOrUpdateAllocationStandardRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦基準の削除
     * @param {string} standardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStandardControllerApi
     */
    public deleteStandard(standardId: string, options?: AxiosRequestConfig) {
        return AllocationStandardControllerApiFp(this.configuration).deleteStandard(standardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦基準Excelのダウンロード
     * @param {string} standardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStandardControllerApi
     */
    public downloadStandardExcel(standardId: string, options?: AxiosRequestConfig) {
        return AllocationStandardControllerApiFp(this.configuration).downloadStandardExcel(standardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦基準の取得
     * @param {string} standardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStandardControllerApi
     */
    public findStandard(standardId: string, options?: AxiosRequestConfig) {
        return AllocationStandardControllerApiFp(this.configuration).findStandard(standardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦基準一覧の取得
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStandardControllerApi
     */
    public listStandards(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return AllocationStandardControllerApiFp(this.configuration).listStandards(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦基準の更新
     * @param {string} standardId 
     * @param {CreateOrUpdateAllocationStandardRequestBody} createOrUpdateAllocationStandardRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStandardControllerApi
     */
    public updateStandard(standardId: string, createOrUpdateAllocationStandardRequestBody: CreateOrUpdateAllocationStandardRequestBody, options?: AxiosRequestConfig) {
        return AllocationStandardControllerApiFp(this.configuration).updateStandard(standardId, createOrUpdateAllocationStandardRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦基準のExcelによる更新
     * @param {string} standardId 
     * @param {boolean} [isDimensionEnabled] 
     * @param {string} [dimensionId] 
     * @param {File} [excelFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStandardControllerApi
     */
    public updateYearMonthAllocationStandard(standardId: string, isDimensionEnabled?: boolean, dimensionId?: string, excelFile?: File, options?: AxiosRequestConfig) {
        return AllocationStandardControllerApiFp(this.configuration).updateYearMonthAllocationStandard(standardId, isDimensionEnabled, dimensionId, excelFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationStepControllerApi - axios parameter creator
 * @export
 */
export const AllocationStepControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 配賦ステップの作成
         * @param {string} ruleId 
         * @param {CreateAllocationStepRequestBody} createAllocationStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStep: async (ruleId: string, createAllocationStepRequestBody: CreateAllocationStepRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('createStep', 'ruleId', ruleId)
            // verify required parameter 'createAllocationStepRequestBody' is not null or undefined
            assertParamExists('createStep', 'createAllocationStepRequestBody', createAllocationStepRequestBody)
            const localVarPath = `/api/allocations/rules/{ruleId}/steps`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllocationStepRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替or配賦ステップの順序の編集
         * @param {string} ruleId 
         * @param {UpdateAllocationStepOrderRequestBody} updateAllocationStepOrderRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOrderOfSteps: async (ruleId: string, updateAllocationStepOrderRequestBody: UpdateAllocationStepOrderRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('editOrderOfSteps', 'ruleId', ruleId)
            // verify required parameter 'updateAllocationStepOrderRequestBody' is not null or undefined
            assertParamExists('editOrderOfSteps', 'updateAllocationStepOrderRequestBody', updateAllocationStepOrderRequestBody)
            const localVarPath = `/api/allocations/rules/rules/{ruleId}/steps-order`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllocationStepOrderRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦ステップの取得
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStep: async (ruleId: string, stepId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('findStep', 'ruleId', ruleId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('findStep', 'stepId', stepId)
            const localVarPath = `/api/allocations/rules/{ruleId}/steps/{stepId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配賦ステップの編集
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {UpdateAllocationStepRequestBody} updateAllocationStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStep: async (ruleId: string, stepId: string, updateAllocationStepRequestBody: UpdateAllocationStepRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('updateStep', 'ruleId', ruleId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('updateStep', 'stepId', stepId)
            // verify required parameter 'updateAllocationStepRequestBody' is not null or undefined
            assertParamExists('updateStep', 'updateAllocationStepRequestBody', updateAllocationStepRequestBody)
            const localVarPath = `/api/allocations/rules/{ruleId}/steps/{stepId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllocationStepRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationStepControllerApi - functional programming interface
 * @export
 */
export const AllocationStepControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationStepControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 配賦ステップの作成
         * @param {string} ruleId 
         * @param {CreateAllocationStepRequestBody} createAllocationStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStep(ruleId: string, createAllocationStepRequestBody: CreateAllocationStepRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStep(ruleId, createAllocationStepRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替or配賦ステップの順序の編集
         * @param {string} ruleId 
         * @param {UpdateAllocationStepOrderRequestBody} updateAllocationStepOrderRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editOrderOfSteps(ruleId: string, updateAllocationStepOrderRequestBody: UpdateAllocationStepOrderRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editOrderOfSteps(ruleId, updateAllocationStepOrderRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦ステップの取得
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStep(ruleId: string, stepId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationStepView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStep(ruleId, stepId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配賦ステップの編集
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {UpdateAllocationStepRequestBody} updateAllocationStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStep(ruleId: string, stepId: string, updateAllocationStepRequestBody: UpdateAllocationStepRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStep(ruleId, stepId, updateAllocationStepRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationStepControllerApi - factory interface
 * @export
 */
export const AllocationStepControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationStepControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 配賦ステップの作成
         * @param {string} ruleId 
         * @param {CreateAllocationStepRequestBody} createAllocationStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStep(ruleId: string, createAllocationStepRequestBody: CreateAllocationStepRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createStep(ruleId, createAllocationStepRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替or配賦ステップの順序の編集
         * @param {string} ruleId 
         * @param {UpdateAllocationStepOrderRequestBody} updateAllocationStepOrderRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOrderOfSteps(ruleId: string, updateAllocationStepOrderRequestBody: UpdateAllocationStepOrderRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.editOrderOfSteps(ruleId, updateAllocationStepOrderRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦ステップの取得
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStep(ruleId: string, stepId: string, options?: any): AxiosPromise<AllocationStepView> {
            return localVarFp.findStep(ruleId, stepId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配賦ステップの編集
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {UpdateAllocationStepRequestBody} updateAllocationStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStep(ruleId: string, stepId: string, updateAllocationStepRequestBody: UpdateAllocationStepRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateStep(ruleId, stepId, updateAllocationStepRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocationStepControllerApi - object-oriented interface
 * @export
 * @class AllocationStepControllerApi
 * @extends {BaseAPI}
 */
export class AllocationStepControllerApi extends BaseAPI {
    /**
     * 
     * @summary 配賦ステップの作成
     * @param {string} ruleId 
     * @param {CreateAllocationStepRequestBody} createAllocationStepRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStepControllerApi
     */
    public createStep(ruleId: string, createAllocationStepRequestBody: CreateAllocationStepRequestBody, options?: AxiosRequestConfig) {
        return AllocationStepControllerApiFp(this.configuration).createStep(ruleId, createAllocationStepRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替or配賦ステップの順序の編集
     * @param {string} ruleId 
     * @param {UpdateAllocationStepOrderRequestBody} updateAllocationStepOrderRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStepControllerApi
     */
    public editOrderOfSteps(ruleId: string, updateAllocationStepOrderRequestBody: UpdateAllocationStepOrderRequestBody, options?: AxiosRequestConfig) {
        return AllocationStepControllerApiFp(this.configuration).editOrderOfSteps(ruleId, updateAllocationStepOrderRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦ステップの取得
     * @param {string} ruleId 
     * @param {string} stepId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStepControllerApi
     */
    public findStep(ruleId: string, stepId: string, options?: AxiosRequestConfig) {
        return AllocationStepControllerApiFp(this.configuration).findStep(ruleId, stepId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配賦ステップの編集
     * @param {string} ruleId 
     * @param {string} stepId 
     * @param {UpdateAllocationStepRequestBody} updateAllocationStepRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStepControllerApi
     */
    public updateStep(ruleId: string, stepId: string, updateAllocationStepRequestBody: UpdateAllocationStepRequestBody, options?: AxiosRequestConfig) {
        return AllocationStepControllerApiFp(this.configuration).updateStep(ruleId, stepId, updateAllocationStepRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationStrategyControllerApi - axios parameter creator
 * @export
 */
export const AllocationStrategyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 振替・配賦実行計画の作成
         * @param {CreateAllocationStrategyRequestBody} createAllocationStrategyRequestBody 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStrategy: async (createAllocationStrategyRequestBody: CreateAllocationStrategyRequestBody, planId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAllocationStrategyRequestBody' is not null or undefined
            assertParamExists('createStrategy', 'createAllocationStrategyRequestBody', createAllocationStrategyRequestBody)
            const localVarPath = `/api/allocations/strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllocationStrategyRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦実行計画の削除
         * @param {Array<string>} yearMonthSet 
         * @param {'ACTUAL' | 'PLAN'} targetType 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStrategy: async (yearMonthSet: Array<string>, targetType: 'ACTUAL' | 'PLAN', planId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonthSet' is not null or undefined
            assertParamExists('deleteStrategy', 'yearMonthSet', yearMonthSet)
            // verify required parameter 'targetType' is not null or undefined
            assertParamExists('deleteStrategy', 'targetType', targetType)
            const localVarPath = `/api/allocations/strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonthSet) {
                localVarQueryParameter['yearMonthSet'] = Array.from(yearMonthSet);
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦実行計画詳細取得
         * @param {'ACTUAL' | 'PLAN'} targetType 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStrategy: async (targetType: 'ACTUAL' | 'PLAN', planId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetType' is not null or undefined
            assertParamExists('findStrategy', 'targetType', targetType)
            const localVarPath = `/api/allocations/strategies/{targetType}`
                .replace(`{${"targetType"}}`, encodeURIComponent(String(targetType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦実行計画(実績)のスキップされたステップを一覧表示する
         * @param {string} fiscalMonthId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSkippedStepsOfActual: async (fiscalMonthId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiscalMonthId' is not null or undefined
            assertParamExists('listSkippedStepsOfActual', 'fiscalMonthId', fiscalMonthId)
            const localVarPath = `/api/allocations/strategies/actual/{fiscalMonthId}/skipped-steps`
                .replace(`{${"fiscalMonthId"}}`, encodeURIComponent(String(fiscalMonthId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦実行計画(予算)のスキップされたステップを一覧表示する
         * @param {string} planId 
         * @param {string} fiscalMonthId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSkippedStepsOfPlan: async (planId: string, fiscalMonthId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('listSkippedStepsOfPlan', 'planId', planId)
            // verify required parameter 'fiscalMonthId' is not null or undefined
            assertParamExists('listSkippedStepsOfPlan', 'fiscalMonthId', fiscalMonthId)
            const localVarPath = `/api/allocations/strategies/plan/{planId}/{fiscalMonthId}/skipped-steps`
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"fiscalMonthId"}}`, encodeURIComponent(String(fiscalMonthId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦実行計画一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStrategies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/allocations/strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替・配賦実行計画の編集
         * @param {UpdateAllocationStrategyRequestBody} updateAllocationStrategyRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStrategy: async (updateAllocationStrategyRequestBody: UpdateAllocationStrategyRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAllocationStrategyRequestBody' is not null or undefined
            assertParamExists('updateStrategy', 'updateAllocationStrategyRequestBody', updateAllocationStrategyRequestBody)
            const localVarPath = `/api/allocations/strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllocationStrategyRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationStrategyControllerApi - functional programming interface
 * @export
 */
export const AllocationStrategyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationStrategyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 振替・配賦実行計画の作成
         * @param {CreateAllocationStrategyRequestBody} createAllocationStrategyRequestBody 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStrategy(createAllocationStrategyRequestBody: CreateAllocationStrategyRequestBody, planId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateStrategyResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStrategy(createAllocationStrategyRequestBody, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦実行計画の削除
         * @param {Array<string>} yearMonthSet 
         * @param {'ACTUAL' | 'PLAN'} targetType 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStrategy(yearMonthSet: Array<string>, targetType: 'ACTUAL' | 'PLAN', planId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStrategy(yearMonthSet, targetType, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦実行計画詳細取得
         * @param {'ACTUAL' | 'PLAN'} targetType 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStrategy(targetType: 'ACTUAL' | 'PLAN', planId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationStrategyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStrategy(targetType, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦実行計画(実績)のスキップされたステップを一覧表示する
         * @param {string} fiscalMonthId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSkippedStepsOfActual(fiscalMonthId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkippedAllocationStepsSummaryView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSkippedStepsOfActual(fiscalMonthId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦実行計画(予算)のスキップされたステップを一覧表示する
         * @param {string} planId 
         * @param {string} fiscalMonthId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSkippedStepsOfPlan(planId: string, fiscalMonthId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkippedAllocationStepsSummaryView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSkippedStepsOfPlan(planId, fiscalMonthId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦実行計画一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStrategies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllocationStrategySummaryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStrategies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替・配賦実行計画の編集
         * @param {UpdateAllocationStrategyRequestBody} updateAllocationStrategyRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStrategy(updateAllocationStrategyRequestBody: UpdateAllocationStrategyRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStrategy(updateAllocationStrategyRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationStrategyControllerApi - factory interface
 * @export
 */
export const AllocationStrategyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationStrategyControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 振替・配賦実行計画の作成
         * @param {CreateAllocationStrategyRequestBody} createAllocationStrategyRequestBody 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStrategy(createAllocationStrategyRequestBody: CreateAllocationStrategyRequestBody, planId?: string, options?: any): AxiosPromise<CreateStrategyResultDto> {
            return localVarFp.createStrategy(createAllocationStrategyRequestBody, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦実行計画の削除
         * @param {Array<string>} yearMonthSet 
         * @param {'ACTUAL' | 'PLAN'} targetType 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStrategy(yearMonthSet: Array<string>, targetType: 'ACTUAL' | 'PLAN', planId?: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteStrategy(yearMonthSet, targetType, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦実行計画詳細取得
         * @param {'ACTUAL' | 'PLAN'} targetType 
         * @param {string} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStrategy(targetType: 'ACTUAL' | 'PLAN', planId?: string, options?: any): AxiosPromise<AllocationStrategyDto> {
            return localVarFp.findStrategy(targetType, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦実行計画(実績)のスキップされたステップを一覧表示する
         * @param {string} fiscalMonthId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSkippedStepsOfActual(fiscalMonthId: string, options?: any): AxiosPromise<SkippedAllocationStepsSummaryView> {
            return localVarFp.listSkippedStepsOfActual(fiscalMonthId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦実行計画(予算)のスキップされたステップを一覧表示する
         * @param {string} planId 
         * @param {string} fiscalMonthId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSkippedStepsOfPlan(planId: string, fiscalMonthId: string, options?: any): AxiosPromise<SkippedAllocationStepsSummaryView> {
            return localVarFp.listSkippedStepsOfPlan(planId, fiscalMonthId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦実行計画一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStrategies(options?: any): AxiosPromise<Array<AllocationStrategySummaryDto>> {
            return localVarFp.listStrategies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替・配賦実行計画の編集
         * @param {UpdateAllocationStrategyRequestBody} updateAllocationStrategyRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStrategy(updateAllocationStrategyRequestBody: UpdateAllocationStrategyRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateStrategy(updateAllocationStrategyRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllocationStrategyControllerApi - object-oriented interface
 * @export
 * @class AllocationStrategyControllerApi
 * @extends {BaseAPI}
 */
export class AllocationStrategyControllerApi extends BaseAPI {
    /**
     * 
     * @summary 振替・配賦実行計画の作成
     * @param {CreateAllocationStrategyRequestBody} createAllocationStrategyRequestBody 
     * @param {string} [planId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStrategyControllerApi
     */
    public createStrategy(createAllocationStrategyRequestBody: CreateAllocationStrategyRequestBody, planId?: string, options?: AxiosRequestConfig) {
        return AllocationStrategyControllerApiFp(this.configuration).createStrategy(createAllocationStrategyRequestBody, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦実行計画の削除
     * @param {Array<string>} yearMonthSet 
     * @param {'ACTUAL' | 'PLAN'} targetType 
     * @param {string} [planId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStrategyControllerApi
     */
    public deleteStrategy(yearMonthSet: Array<string>, targetType: 'ACTUAL' | 'PLAN', planId?: string, options?: AxiosRequestConfig) {
        return AllocationStrategyControllerApiFp(this.configuration).deleteStrategy(yearMonthSet, targetType, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦実行計画詳細取得
     * @param {'ACTUAL' | 'PLAN'} targetType 
     * @param {string} [planId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStrategyControllerApi
     */
    public findStrategy(targetType: 'ACTUAL' | 'PLAN', planId?: string, options?: AxiosRequestConfig) {
        return AllocationStrategyControllerApiFp(this.configuration).findStrategy(targetType, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦実行計画(実績)のスキップされたステップを一覧表示する
     * @param {string} fiscalMonthId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStrategyControllerApi
     */
    public listSkippedStepsOfActual(fiscalMonthId: string, options?: AxiosRequestConfig) {
        return AllocationStrategyControllerApiFp(this.configuration).listSkippedStepsOfActual(fiscalMonthId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦実行計画(予算)のスキップされたステップを一覧表示する
     * @param {string} planId 
     * @param {string} fiscalMonthId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStrategyControllerApi
     */
    public listSkippedStepsOfPlan(planId: string, fiscalMonthId: string, options?: AxiosRequestConfig) {
        return AllocationStrategyControllerApiFp(this.configuration).listSkippedStepsOfPlan(planId, fiscalMonthId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦実行計画一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStrategyControllerApi
     */
    public listStrategies(options?: AxiosRequestConfig) {
        return AllocationStrategyControllerApiFp(this.configuration).listStrategies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替・配賦実行計画の編集
     * @param {UpdateAllocationStrategyRequestBody} updateAllocationStrategyRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationStrategyControllerApi
     */
    public updateStrategy(updateAllocationStrategyRequestBody: UpdateAllocationStrategyRequestBody, options?: AxiosRequestConfig) {
        return AllocationStrategyControllerApiFp(this.configuration).updateStrategy(updateAllocationStrategyRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalysisAssistantControllerApi - axios parameter creator
 * @export
 */
export const AnalysisAssistantControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary データ量制限に該当するか否かを返却する
         * @param {AnalysisAssistantDataLimitCheckRequestBody} analysisAssistantDataLimitCheckRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataLimitCheck: async (analysisAssistantDataLimitCheckRequestBody: AnalysisAssistantDataLimitCheckRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisAssistantDataLimitCheckRequestBody' is not null or undefined
            assertParamExists('dataLimitCheck', 'analysisAssistantDataLimitCheckRequestBody', analysisAssistantDataLimitCheckRequestBody)
            const localVarPath = `/api/analysis-assistant/data-limit-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisAssistantDataLimitCheckRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnalysisAssistantSideKickRequestBody} analysisAssistantSideKickRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateAnalysisAssistantSideKick: async (analysisAssistantSideKickRequestBody: AnalysisAssistantSideKickRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisAssistantSideKickRequestBody' is not null or undefined
            assertParamExists('initiateAnalysisAssistantSideKick', 'analysisAssistantSideKickRequestBody', analysisAssistantSideKickRequestBody)
            const localVarPath = `/api/analysis-assistant/sidekick/initiate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisAssistantSideKickRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ComparisonAnalysisRequestBody} comparisonAnalysisRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateComparisonSummary: async (comparisonAnalysisRequestBody: ComparisonAnalysisRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comparisonAnalysisRequestBody' is not null or undefined
            assertParamExists('initiateComparisonSummary', 'comparisonAnalysisRequestBody', comparisonAnalysisRequestBody)
            const localVarPath = `/api/analysis-assistant/initiate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comparisonAnalysisRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterFeedBackRequestBody} registerFeedBackRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerComparisonSummaryFeedBack: async (registerFeedBackRequestBody: RegisterFeedBackRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerFeedBackRequestBody' is not null or undefined
            assertParamExists('registerComparisonSummaryFeedBack', 'registerFeedBackRequestBody', registerFeedBackRequestBody)
            const localVarPath = `/api/analysis-assistant/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerFeedBackRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveComparisonSummary: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('retrieveComparisonSummary', 'body', body)
            const localVarPath = `/api/analysis-assistant/retrieve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisAssistantControllerApi - functional programming interface
 * @export
 */
export const AnalysisAssistantControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisAssistantControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary データ量制限に該当するか否かを返却する
         * @param {AnalysisAssistantDataLimitCheckRequestBody} analysisAssistantDataLimitCheckRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataLimitCheck(analysisAssistantDataLimitCheckRequestBody: AnalysisAssistantDataLimitCheckRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisAssistantDataLimitValidateResultView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataLimitCheck(analysisAssistantDataLimitCheckRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnalysisAssistantSideKickRequestBody} analysisAssistantSideKickRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateAnalysisAssistantSideKick(analysisAssistantSideKickRequestBody: AnalysisAssistantSideKickRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalysisAssistantLayoutComponent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateAnalysisAssistantSideKick(analysisAssistantSideKickRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ComparisonAnalysisRequestBody} comparisonAnalysisRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateComparisonSummary(comparisonAnalysisRequestBody: ComparisonAnalysisRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitiatedComparisonAnalysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateComparisonSummary(comparisonAnalysisRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterFeedBackRequestBody} registerFeedBackRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerComparisonSummaryFeedBack(registerFeedBackRequestBody: RegisterFeedBackRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerComparisonSummaryFeedBack(registerFeedBackRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveComparisonSummary(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormattedRetrieveComparisonAnalysisResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveComparisonSummary(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalysisAssistantControllerApi - factory interface
 * @export
 */
export const AnalysisAssistantControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisAssistantControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary データ量制限に該当するか否かを返却する
         * @param {AnalysisAssistantDataLimitCheckRequestBody} analysisAssistantDataLimitCheckRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataLimitCheck(analysisAssistantDataLimitCheckRequestBody: AnalysisAssistantDataLimitCheckRequestBody, options?: any): AxiosPromise<AnalysisAssistantDataLimitValidateResultView> {
            return localVarFp.dataLimitCheck(analysisAssistantDataLimitCheckRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnalysisAssistantSideKickRequestBody} analysisAssistantSideKickRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateAnalysisAssistantSideKick(analysisAssistantSideKickRequestBody: AnalysisAssistantSideKickRequestBody, options?: any): AxiosPromise<Array<AnalysisAssistantLayoutComponent>> {
            return localVarFp.initiateAnalysisAssistantSideKick(analysisAssistantSideKickRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ComparisonAnalysisRequestBody} comparisonAnalysisRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateComparisonSummary(comparisonAnalysisRequestBody: ComparisonAnalysisRequestBody, options?: any): AxiosPromise<InitiatedComparisonAnalysis> {
            return localVarFp.initiateComparisonSummary(comparisonAnalysisRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterFeedBackRequestBody} registerFeedBackRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerComparisonSummaryFeedBack(registerFeedBackRequestBody: RegisterFeedBackRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.registerComparisonSummaryFeedBack(registerFeedBackRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveComparisonSummary(body: string, options?: any): AxiosPromise<FormattedRetrieveComparisonAnalysisResult> {
            return localVarFp.retrieveComparisonSummary(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisAssistantControllerApi - object-oriented interface
 * @export
 * @class AnalysisAssistantControllerApi
 * @extends {BaseAPI}
 */
export class AnalysisAssistantControllerApi extends BaseAPI {
    /**
     * 
     * @summary データ量制限に該当するか否かを返却する
     * @param {AnalysisAssistantDataLimitCheckRequestBody} analysisAssistantDataLimitCheckRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisAssistantControllerApi
     */
    public dataLimitCheck(analysisAssistantDataLimitCheckRequestBody: AnalysisAssistantDataLimitCheckRequestBody, options?: AxiosRequestConfig) {
        return AnalysisAssistantControllerApiFp(this.configuration).dataLimitCheck(analysisAssistantDataLimitCheckRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalysisAssistantSideKickRequestBody} analysisAssistantSideKickRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisAssistantControllerApi
     */
    public initiateAnalysisAssistantSideKick(analysisAssistantSideKickRequestBody: AnalysisAssistantSideKickRequestBody, options?: AxiosRequestConfig) {
        return AnalysisAssistantControllerApiFp(this.configuration).initiateAnalysisAssistantSideKick(analysisAssistantSideKickRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ComparisonAnalysisRequestBody} comparisonAnalysisRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisAssistantControllerApi
     */
    public initiateComparisonSummary(comparisonAnalysisRequestBody: ComparisonAnalysisRequestBody, options?: AxiosRequestConfig) {
        return AnalysisAssistantControllerApiFp(this.configuration).initiateComparisonSummary(comparisonAnalysisRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterFeedBackRequestBody} registerFeedBackRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisAssistantControllerApi
     */
    public registerComparisonSummaryFeedBack(registerFeedBackRequestBody: RegisterFeedBackRequestBody, options?: AxiosRequestConfig) {
        return AnalysisAssistantControllerApiFp(this.configuration).registerComparisonSummaryFeedBack(registerFeedBackRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisAssistantControllerApi
     */
    public retrieveComparisonSummary(body: string, options?: AxiosRequestConfig) {
        return AnalysisAssistantControllerApiFp(this.configuration).retrieveComparisonSummary(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalysisAssistantToolControllerApi - axios parameter creator
 * @export
 */
export const AnalysisAssistantToolControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTools: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analysis-assistant/tools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisAssistantToolControllerApi - functional programming interface
 * @export
 */
export const AnalysisAssistantToolControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisAssistantToolControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTools(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalysisAssistantToolItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTools(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalysisAssistantToolControllerApi - factory interface
 * @export
 */
export const AnalysisAssistantToolControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisAssistantToolControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTools(options?: any): AxiosPromise<Array<AnalysisAssistantToolItem>> {
            return localVarFp.listTools(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisAssistantToolControllerApi - object-oriented interface
 * @export
 * @class AnalysisAssistantToolControllerApi
 * @extends {BaseAPI}
 */
export class AnalysisAssistantToolControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisAssistantToolControllerApi
     */
    public listTools(options?: AxiosRequestConfig) {
        return AnalysisAssistantToolControllerApiFp(this.configuration).listTools(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppliedNumberLimitsControllerApi - axios parameter creator
 * @export
 */
export const AppliedNumberLimitsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 適用された件数制限の値を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNumberLimits: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/applied-number-limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppliedNumberLimitsControllerApi - functional programming interface
 * @export
 */
export const AppliedNumberLimitsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppliedNumberLimitsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 適用された件数制限の値を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNumberLimits(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppliedNumberLimitsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNumberLimits(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppliedNumberLimitsControllerApi - factory interface
 * @export
 */
export const AppliedNumberLimitsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppliedNumberLimitsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 適用された件数制限の値を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNumberLimits(options?: any): AxiosPromise<AppliedNumberLimitsView> {
            return localVarFp.listNumberLimits(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppliedNumberLimitsControllerApi - object-oriented interface
 * @export
 * @class AppliedNumberLimitsControllerApi
 * @extends {BaseAPI}
 */
export class AppliedNumberLimitsControllerApi extends BaseAPI {
    /**
     * 
     * @summary 適用された件数制限の値を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppliedNumberLimitsControllerApi
     */
    public listNumberLimits(options?: AxiosRequestConfig) {
        return AppliedNumberLimitsControllerApiFp(this.configuration).listNumberLimits(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultTenant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/default-tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 利用可能なテナントリストを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableTenants: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/available-tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultTenant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultTenantView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultTenant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 利用可能なテナントリストを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAvailableTenants(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableTenantView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAvailableTenants(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultTenant(options?: any): AxiosPromise<DefaultTenantView> {
            return localVarFp.getDefaultTenant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 利用可能なテナントリストを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableTenants(options?: any): AxiosPromise<Array<AvailableTenantView>> {
            return localVarFp.listAvailableTenants(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public getDefaultTenant(options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).getDefaultTenant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 利用可能なテナントリストを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public listAvailableTenants(options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).listAvailableTenants(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BsAccountTreeControllerApi - axios parameter creator
 * @export
 */
export const BsAccountTreeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary BS科目階層に科目を追加する
         * @param {AddBSAccountRequestBody} addBSAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNode: async (addBSAccountRequestBody: AddBSAccountRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addBSAccountRequestBody' is not null or undefined
            assertParamExists('addNode', 'addBSAccountRequestBody', addBSAccountRequestBody)
            const localVarPath = `/api/bs-account-tree/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addBSAccountRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定された科目をBS科目階層から外す
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachNode: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('detachNode', 'accountId', accountId)
            const localVarPath = `/api/bs-account-tree/detach/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 一括作成更新用のBS科目階層Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownload: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bs-account-tree/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excelファイルをもとに、BS科目階層の一括更新を行う
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUpload1: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('fileUpload1', 'file', file)
            const localVarPath = `/api/bs-account-tree/bulk-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BS科目を選択させるために本体値を表示するBS科目階層を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBSAccountTreeForSelection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bs-account-tree/for-selection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BS科目階層を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBSAccountTreeWithAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bs-account-tree/with-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BS科目階層名, IDを取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBsAccountTree: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bs-account-tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BS科目階層内の科目の移動を行う
         * @param {MoveBSAccountTreeNodeRequestBody} moveBSAccountTreeNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNode: async (moveBSAccountTreeNodeRequestBody: MoveBSAccountTreeNodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moveBSAccountTreeNodeRequestBody' is not null or undefined
            assertParamExists('moveNode', 'moveBSAccountTreeNodeRequestBody', moveBSAccountTreeNodeRequestBody)
            const localVarPath = `/api/bs-account-tree/move-node`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveBSAccountTreeNodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BsAccountTreeControllerApi - functional programming interface
 * @export
 */
export const BsAccountTreeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BsAccountTreeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary BS科目階層に科目を追加する
         * @param {AddBSAccountRequestBody} addBSAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNode(addBSAccountRequestBody: AddBSAccountRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNode(addBSAccountRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定された科目をBS科目階層から外す
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachNode(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachNode(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 一括作成更新用のBS科目階層Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileDownload(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileDownload(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excelファイルをもとに、BS科目階層の一括更新を行う
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUpload1(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUpload1(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BS科目を選択させるために本体値を表示するBS科目階層を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBSAccountTreeForSelection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFlatTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBSAccountTreeForSelection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BS科目階層を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBSAccountTreeWithAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BSAccountFlatTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBSAccountTreeWithAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BS科目階層名, IDを取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBsAccountTree(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BSAccountTreeNameView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBsAccountTree(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BS科目階層内の科目の移動を行う
         * @param {MoveBSAccountTreeNodeRequestBody} moveBSAccountTreeNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveNode(moveBSAccountTreeNodeRequestBody: MoveBSAccountTreeNodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveNode(moveBSAccountTreeNodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BsAccountTreeControllerApi - factory interface
 * @export
 */
export const BsAccountTreeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BsAccountTreeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary BS科目階層に科目を追加する
         * @param {AddBSAccountRequestBody} addBSAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNode(addBSAccountRequestBody: AddBSAccountRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.addNode(addBSAccountRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定された科目をBS科目階層から外す
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachNode(accountId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.detachNode(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 一括作成更新用のBS科目階層Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownload(options?: any): AxiosPromise<string> {
            return localVarFp.fileDownload(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excelファイルをもとに、BS科目階層の一括更新を行う
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUpload1(file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.fileUpload1(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BS科目を選択させるために本体値を表示するBS科目階層を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBSAccountTreeForSelection(options?: any): AxiosPromise<AccountFlatTreeForSelectionView> {
            return localVarFp.getBSAccountTreeForSelection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BS科目階層を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBSAccountTreeWithAccounts(options?: any): AxiosPromise<BSAccountFlatTreeView> {
            return localVarFp.getBSAccountTreeWithAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BS科目階層名, IDを取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBsAccountTree(options?: any): AxiosPromise<BSAccountTreeNameView> {
            return localVarFp.getBsAccountTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BS科目階層内の科目の移動を行う
         * @param {MoveBSAccountTreeNodeRequestBody} moveBSAccountTreeNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNode(moveBSAccountTreeNodeRequestBody: MoveBSAccountTreeNodeRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.moveNode(moveBSAccountTreeNodeRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BsAccountTreeControllerApi - object-oriented interface
 * @export
 * @class BsAccountTreeControllerApi
 * @extends {BaseAPI}
 */
export class BsAccountTreeControllerApi extends BaseAPI {
    /**
     * 
     * @summary BS科目階層に科目を追加する
     * @param {AddBSAccountRequestBody} addBSAccountRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsAccountTreeControllerApi
     */
    public addNode(addBSAccountRequestBody: AddBSAccountRequestBody, options?: AxiosRequestConfig) {
        return BsAccountTreeControllerApiFp(this.configuration).addNode(addBSAccountRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定された科目をBS科目階層から外す
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsAccountTreeControllerApi
     */
    public detachNode(accountId: string, options?: AxiosRequestConfig) {
        return BsAccountTreeControllerApiFp(this.configuration).detachNode(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 一括作成更新用のBS科目階層Excelのダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsAccountTreeControllerApi
     */
    public fileDownload(options?: AxiosRequestConfig) {
        return BsAccountTreeControllerApiFp(this.configuration).fileDownload(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excelファイルをもとに、BS科目階層の一括更新を行う
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsAccountTreeControllerApi
     */
    public fileUpload1(file: File, options?: AxiosRequestConfig) {
        return BsAccountTreeControllerApiFp(this.configuration).fileUpload1(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BS科目を選択させるために本体値を表示するBS科目階層を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsAccountTreeControllerApi
     */
    public getBSAccountTreeForSelection(options?: AxiosRequestConfig) {
        return BsAccountTreeControllerApiFp(this.configuration).getBSAccountTreeForSelection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BS科目階層を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsAccountTreeControllerApi
     */
    public getBSAccountTreeWithAccounts(options?: AxiosRequestConfig) {
        return BsAccountTreeControllerApiFp(this.configuration).getBSAccountTreeWithAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BS科目階層名, IDを取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsAccountTreeControllerApi
     */
    public getBsAccountTree(options?: AxiosRequestConfig) {
        return BsAccountTreeControllerApiFp(this.configuration).getBsAccountTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BS科目階層内の科目の移動を行う
     * @param {MoveBSAccountTreeNodeRequestBody} moveBSAccountTreeNodeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsAccountTreeControllerApi
     */
    public moveNode(moveBSAccountTreeNodeRequestBody: MoveBSAccountTreeNodeRequestBody, options?: AxiosRequestConfig) {
        return BsAccountTreeControllerApiFp(this.configuration).moveNode(moveBSAccountTreeNodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalculatedAccountControllerApi - axios parameter creator
 * @export
 */
export const CalculatedAccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 計算科目の作成をする
         * @param {CreateCalculatedAccountRequestBody} createCalculatedAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalculatedAccount: async (createCalculatedAccountRequestBody: CreateCalculatedAccountRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCalculatedAccountRequestBody' is not null or undefined
            assertParamExists('createCalculatedAccount', 'createCalculatedAccountRequestBody', createCalculatedAccountRequestBody)
            const localVarPath = `/api/calculated-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalculatedAccountRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 計算科目を削除する
         * @param {string} calculatedAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalculatedAccount: async (calculatedAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calculatedAccountId' is not null or undefined
            assertParamExists('deleteCalculatedAccount', 'calculatedAccountId', calculatedAccountId)
            const localVarPath = `/api/calculated-accounts/{calculatedAccountId}`
                .replace(`{${"calculatedAccountId"}}`, encodeURIComponent(String(calculatedAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 計算科目を全て取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculatedAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/calculated-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 計算科目を更新する
         * @param {UpdateCalculatedAccountRequestBody} updateCalculatedAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalculatedAccount: async (updateCalculatedAccountRequestBody: UpdateCalculatedAccountRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCalculatedAccountRequestBody' is not null or undefined
            assertParamExists('updateCalculatedAccount', 'updateCalculatedAccountRequestBody', updateCalculatedAccountRequestBody)
            const localVarPath = `/api/calculated-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCalculatedAccountRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalculatedAccountControllerApi - functional programming interface
 * @export
 */
export const CalculatedAccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalculatedAccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 計算科目の作成をする
         * @param {CreateCalculatedAccountRequestBody} createCalculatedAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCalculatedAccount(createCalculatedAccountRequestBody: CreateCalculatedAccountRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCalculatedAccount(createCalculatedAccountRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 計算科目を削除する
         * @param {string} calculatedAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalculatedAccount(calculatedAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalculatedAccount(calculatedAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 計算科目を全て取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalculatedAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculatedAccountsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalculatedAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 計算科目を更新する
         * @param {UpdateCalculatedAccountRequestBody} updateCalculatedAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalculatedAccount(updateCalculatedAccountRequestBody: UpdateCalculatedAccountRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalculatedAccount(updateCalculatedAccountRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalculatedAccountControllerApi - factory interface
 * @export
 */
export const CalculatedAccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalculatedAccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 計算科目の作成をする
         * @param {CreateCalculatedAccountRequestBody} createCalculatedAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalculatedAccount(createCalculatedAccountRequestBody: CreateCalculatedAccountRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createCalculatedAccount(createCalculatedAccountRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 計算科目を削除する
         * @param {string} calculatedAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalculatedAccount(calculatedAccountId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteCalculatedAccount(calculatedAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 計算科目を全て取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculatedAccount(options?: any): AxiosPromise<CalculatedAccountsView> {
            return localVarFp.listCalculatedAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 計算科目を更新する
         * @param {UpdateCalculatedAccountRequestBody} updateCalculatedAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalculatedAccount(updateCalculatedAccountRequestBody: UpdateCalculatedAccountRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateCalculatedAccount(updateCalculatedAccountRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalculatedAccountControllerApi - object-oriented interface
 * @export
 * @class CalculatedAccountControllerApi
 * @extends {BaseAPI}
 */
export class CalculatedAccountControllerApi extends BaseAPI {
    /**
     * 
     * @summary 計算科目の作成をする
     * @param {CreateCalculatedAccountRequestBody} createCalculatedAccountRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculatedAccountControllerApi
     */
    public createCalculatedAccount(createCalculatedAccountRequestBody: CreateCalculatedAccountRequestBody, options?: AxiosRequestConfig) {
        return CalculatedAccountControllerApiFp(this.configuration).createCalculatedAccount(createCalculatedAccountRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 計算科目を削除する
     * @param {string} calculatedAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculatedAccountControllerApi
     */
    public deleteCalculatedAccount(calculatedAccountId: string, options?: AxiosRequestConfig) {
        return CalculatedAccountControllerApiFp(this.configuration).deleteCalculatedAccount(calculatedAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 計算科目を全て取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculatedAccountControllerApi
     */
    public listCalculatedAccount(options?: AxiosRequestConfig) {
        return CalculatedAccountControllerApiFp(this.configuration).listCalculatedAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 計算科目を更新する
     * @param {UpdateCalculatedAccountRequestBody} updateCalculatedAccountRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculatedAccountControllerApi
     */
    public updateCalculatedAccount(updateCalculatedAccountRequestBody: UpdateCalculatedAccountRequestBody, options?: AxiosRequestConfig) {
        return CalculatedAccountControllerApiFp(this.configuration).updateCalculatedAccount(updateCalculatedAccountRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatCompletionControllerApi - axios parameter creator
 * @export
 */
export const ChatCompletionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChatCompletionRequest} chatCompletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchChatCompletion: async (chatCompletionRequest: ChatCompletionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatCompletionRequest' is not null or undefined
            assertParamExists('fetchChatCompletion', 'chatCompletionRequest', chatCompletionRequest)
            const localVarPath = `/api/chat-completion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatCompletionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatCompletionControllerApi - functional programming interface
 * @export
 */
export const ChatCompletionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatCompletionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChatCompletionRequest} chatCompletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchChatCompletion(chatCompletionRequest: ChatCompletionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatCompletionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchChatCompletion(chatCompletionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatCompletionControllerApi - factory interface
 * @export
 */
export const ChatCompletionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatCompletionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatCompletionRequest} chatCompletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchChatCompletion(chatCompletionRequest: ChatCompletionRequest, options?: any): AxiosPromise<ChatCompletionMessage> {
            return localVarFp.fetchChatCompletion(chatCompletionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatCompletionControllerApi - object-oriented interface
 * @export
 * @class ChatCompletionControllerApi
 * @extends {BaseAPI}
 */
export class ChatCompletionControllerApi extends BaseAPI {
    /**
     * 
     * @param {ChatCompletionRequest} chatCompletionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatCompletionControllerApi
     */
    public fetchChatCompletion(chatCompletionRequest: ChatCompletionRequest, options?: AxiosRequestConfig) {
        return ChatCompletionControllerApiFp(this.configuration).fetchChatCompletion(chatCompletionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComparisonSheetSelectedConditionControllerApi - axios parameter creator
 * @export
 */
export const ComparisonSheetSelectedConditionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 対比表選択条件保存の削除
         * @param {string} comparisonSheetSelectedConditionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        comparisonConditionDelete: async (comparisonSheetSelectedConditionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comparisonSheetSelectedConditionId' is not null or undefined
            assertParamExists('comparisonConditionDelete', 'comparisonSheetSelectedConditionId', comparisonSheetSelectedConditionId)
            const localVarPath = `/api/comparison-sheet-selected-conditions/{comparisonSheetSelectedConditionId}`
                .replace(`{${"comparisonSheetSelectedConditionId"}}`, encodeURIComponent(String(comparisonSheetSelectedConditionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対比表選択条件の作成
         * @param {CreateOrUpdateComparisonSheetSelectedConditionRequestBody} createOrUpdateComparisonSheetSelectedConditionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComparisonSheetSelectedCondition: async (createOrUpdateComparisonSheetSelectedConditionRequestBody: CreateOrUpdateComparisonSheetSelectedConditionRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrUpdateComparisonSheetSelectedConditionRequestBody' is not null or undefined
            assertParamExists('createComparisonSheetSelectedCondition', 'createOrUpdateComparisonSheetSelectedConditionRequestBody', createOrUpdateComparisonSheetSelectedConditionRequestBody)
            const localVarPath = `/api/comparison-sheet-selected-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateComparisonSheetSelectedConditionRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対比表選択条件一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisonSheetSelectedCondition: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/comparison-sheet-selected-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対比表選択条件を別の選択条件グループへ移動
         * @param {string} comparisonSheetSelectedConditionId 
         * @param {MoveComparisonSheetSelectedConditionGroupRequestBody} moveComparisonSheetSelectedConditionGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveComparisonCondition: async (comparisonSheetSelectedConditionId: string, moveComparisonSheetSelectedConditionGroupRequestBody: MoveComparisonSheetSelectedConditionGroupRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comparisonSheetSelectedConditionId' is not null or undefined
            assertParamExists('moveComparisonCondition', 'comparisonSheetSelectedConditionId', comparisonSheetSelectedConditionId)
            // verify required parameter 'moveComparisonSheetSelectedConditionGroupRequestBody' is not null or undefined
            assertParamExists('moveComparisonCondition', 'moveComparisonSheetSelectedConditionGroupRequestBody', moveComparisonSheetSelectedConditionGroupRequestBody)
            const localVarPath = `/api/comparison-sheet-selected-conditions/{comparisonSheetSelectedConditionId}/move-group`
                .replace(`{${"comparisonSheetSelectedConditionId"}}`, encodeURIComponent(String(comparisonSheetSelectedConditionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveComparisonSheetSelectedConditionGroupRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対比表選択条件の更新
         * @param {string} comparisonSheetSelectedConditionId 
         * @param {CreateOrUpdateComparisonSheetSelectedConditionRequestBody} createOrUpdateComparisonSheetSelectedConditionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComparisonCondition: async (comparisonSheetSelectedConditionId: string, createOrUpdateComparisonSheetSelectedConditionRequestBody: CreateOrUpdateComparisonSheetSelectedConditionRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comparisonSheetSelectedConditionId' is not null or undefined
            assertParamExists('updateComparisonCondition', 'comparisonSheetSelectedConditionId', comparisonSheetSelectedConditionId)
            // verify required parameter 'createOrUpdateComparisonSheetSelectedConditionRequestBody' is not null or undefined
            assertParamExists('updateComparisonCondition', 'createOrUpdateComparisonSheetSelectedConditionRequestBody', createOrUpdateComparisonSheetSelectedConditionRequestBody)
            const localVarPath = `/api/comparison-sheet-selected-conditions/{comparisonSheetSelectedConditionId}`
                .replace(`{${"comparisonSheetSelectedConditionId"}}`, encodeURIComponent(String(comparisonSheetSelectedConditionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateComparisonSheetSelectedConditionRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComparisonSheetSelectedConditionControllerApi - functional programming interface
 * @export
 */
export const ComparisonSheetSelectedConditionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComparisonSheetSelectedConditionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 対比表選択条件保存の削除
         * @param {string} comparisonSheetSelectedConditionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async comparisonConditionDelete(comparisonSheetSelectedConditionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.comparisonConditionDelete(comparisonSheetSelectedConditionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対比表選択条件の作成
         * @param {CreateOrUpdateComparisonSheetSelectedConditionRequestBody} createOrUpdateComparisonSheetSelectedConditionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComparisonSheetSelectedCondition(createOrUpdateComparisonSheetSelectedConditionRequestBody: CreateOrUpdateComparisonSheetSelectedConditionRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateComparisonSheetSelectedConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComparisonSheetSelectedCondition(createOrUpdateComparisonSheetSelectedConditionRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対比表選択条件一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComparisonSheetSelectedCondition(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparisonSheetSelectedConditionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComparisonSheetSelectedCondition(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対比表選択条件を別の選択条件グループへ移動
         * @param {string} comparisonSheetSelectedConditionId 
         * @param {MoveComparisonSheetSelectedConditionGroupRequestBody} moveComparisonSheetSelectedConditionGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveComparisonCondition(comparisonSheetSelectedConditionId: string, moveComparisonSheetSelectedConditionGroupRequestBody: MoveComparisonSheetSelectedConditionGroupRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveComparisonCondition(comparisonSheetSelectedConditionId, moveComparisonSheetSelectedConditionGroupRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対比表選択条件の更新
         * @param {string} comparisonSheetSelectedConditionId 
         * @param {CreateOrUpdateComparisonSheetSelectedConditionRequestBody} createOrUpdateComparisonSheetSelectedConditionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComparisonCondition(comparisonSheetSelectedConditionId: string, createOrUpdateComparisonSheetSelectedConditionRequestBody: CreateOrUpdateComparisonSheetSelectedConditionRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComparisonCondition(comparisonSheetSelectedConditionId, createOrUpdateComparisonSheetSelectedConditionRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComparisonSheetSelectedConditionControllerApi - factory interface
 * @export
 */
export const ComparisonSheetSelectedConditionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComparisonSheetSelectedConditionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 対比表選択条件保存の削除
         * @param {string} comparisonSheetSelectedConditionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        comparisonConditionDelete(comparisonSheetSelectedConditionId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.comparisonConditionDelete(comparisonSheetSelectedConditionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対比表選択条件の作成
         * @param {CreateOrUpdateComparisonSheetSelectedConditionRequestBody} createOrUpdateComparisonSheetSelectedConditionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComparisonSheetSelectedCondition(createOrUpdateComparisonSheetSelectedConditionRequestBody: CreateOrUpdateComparisonSheetSelectedConditionRequestBody, options?: any): AxiosPromise<CreateComparisonSheetSelectedConditionResponse> {
            return localVarFp.createComparisonSheetSelectedCondition(createOrUpdateComparisonSheetSelectedConditionRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対比表選択条件一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComparisonSheetSelectedCondition(options?: any): AxiosPromise<Array<ComparisonSheetSelectedConditionDto>> {
            return localVarFp.listComparisonSheetSelectedCondition(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対比表選択条件を別の選択条件グループへ移動
         * @param {string} comparisonSheetSelectedConditionId 
         * @param {MoveComparisonSheetSelectedConditionGroupRequestBody} moveComparisonSheetSelectedConditionGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveComparisonCondition(comparisonSheetSelectedConditionId: string, moveComparisonSheetSelectedConditionGroupRequestBody: MoveComparisonSheetSelectedConditionGroupRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.moveComparisonCondition(comparisonSheetSelectedConditionId, moveComparisonSheetSelectedConditionGroupRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対比表選択条件の更新
         * @param {string} comparisonSheetSelectedConditionId 
         * @param {CreateOrUpdateComparisonSheetSelectedConditionRequestBody} createOrUpdateComparisonSheetSelectedConditionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComparisonCondition(comparisonSheetSelectedConditionId: string, createOrUpdateComparisonSheetSelectedConditionRequestBody: CreateOrUpdateComparisonSheetSelectedConditionRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateComparisonCondition(comparisonSheetSelectedConditionId, createOrUpdateComparisonSheetSelectedConditionRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComparisonSheetSelectedConditionControllerApi - object-oriented interface
 * @export
 * @class ComparisonSheetSelectedConditionControllerApi
 * @extends {BaseAPI}
 */
export class ComparisonSheetSelectedConditionControllerApi extends BaseAPI {
    /**
     * 
     * @summary 対比表選択条件保存の削除
     * @param {string} comparisonSheetSelectedConditionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionControllerApi
     */
    public comparisonConditionDelete(comparisonSheetSelectedConditionId: string, options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionControllerApiFp(this.configuration).comparisonConditionDelete(comparisonSheetSelectedConditionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対比表選択条件の作成
     * @param {CreateOrUpdateComparisonSheetSelectedConditionRequestBody} createOrUpdateComparisonSheetSelectedConditionRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionControllerApi
     */
    public createComparisonSheetSelectedCondition(createOrUpdateComparisonSheetSelectedConditionRequestBody: CreateOrUpdateComparisonSheetSelectedConditionRequestBody, options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionControllerApiFp(this.configuration).createComparisonSheetSelectedCondition(createOrUpdateComparisonSheetSelectedConditionRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対比表選択条件一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionControllerApi
     */
    public listComparisonSheetSelectedCondition(options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionControllerApiFp(this.configuration).listComparisonSheetSelectedCondition(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対比表選択条件を別の選択条件グループへ移動
     * @param {string} comparisonSheetSelectedConditionId 
     * @param {MoveComparisonSheetSelectedConditionGroupRequestBody} moveComparisonSheetSelectedConditionGroupRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionControllerApi
     */
    public moveComparisonCondition(comparisonSheetSelectedConditionId: string, moveComparisonSheetSelectedConditionGroupRequestBody: MoveComparisonSheetSelectedConditionGroupRequestBody, options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionControllerApiFp(this.configuration).moveComparisonCondition(comparisonSheetSelectedConditionId, moveComparisonSheetSelectedConditionGroupRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対比表選択条件の更新
     * @param {string} comparisonSheetSelectedConditionId 
     * @param {CreateOrUpdateComparisonSheetSelectedConditionRequestBody} createOrUpdateComparisonSheetSelectedConditionRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionControllerApi
     */
    public updateComparisonCondition(comparisonSheetSelectedConditionId: string, createOrUpdateComparisonSheetSelectedConditionRequestBody: CreateOrUpdateComparisonSheetSelectedConditionRequestBody, options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionControllerApiFp(this.configuration).updateComparisonCondition(comparisonSheetSelectedConditionId, createOrUpdateComparisonSheetSelectedConditionRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComparisonSheetSelectedConditionGroupControllerApi - axios parameter creator
 * @export
 */
export const ComparisonSheetSelectedConditionGroupControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 対比表選択条件グループの作成API
         * @param {CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody} createOrUpdateComparisonSheetSelectedConditionGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComparisonSheetSelectedConditionGroup: async (createOrUpdateComparisonSheetSelectedConditionGroupRequestBody: CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrUpdateComparisonSheetSelectedConditionGroupRequestBody' is not null or undefined
            assertParamExists('createComparisonSheetSelectedConditionGroup', 'createOrUpdateComparisonSheetSelectedConditionGroupRequestBody', createOrUpdateComparisonSheetSelectedConditionGroupRequestBody)
            const localVarPath = `/api/comparison-sheet-selected-condition-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateComparisonSheetSelectedConditionGroupRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対比表選択条件グループの削除
         * @param {string} comparisonSheetSelectedConditionGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComparisonSheetSelectedConditionGroup: async (comparisonSheetSelectedConditionGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comparisonSheetSelectedConditionGroupId' is not null or undefined
            assertParamExists('deleteComparisonSheetSelectedConditionGroup', 'comparisonSheetSelectedConditionGroupId', comparisonSheetSelectedConditionGroupId)
            const localVarPath = `/api/comparison-sheet-selected-condition-groups/{comparisonSheetSelectedConditionGroupId}`
                .replace(`{${"comparisonSheetSelectedConditionGroupId"}}`, encodeURIComponent(String(comparisonSheetSelectedConditionGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対比表選択条件グループの一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForSelection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/comparison-sheet-selected-condition-groups/for-selection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対比表選択条件グループとその選択条件一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithSelectedConditions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/comparison-sheet-selected-condition-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 対比表選択条件グループ名の更新
         * @param {string} comparisonSheetSelectedConditionGroupId 
         * @param {CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody} createOrUpdateComparisonSheetSelectedConditionGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComparisonSheetSelectedConditionGroup: async (comparisonSheetSelectedConditionGroupId: string, createOrUpdateComparisonSheetSelectedConditionGroupRequestBody: CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comparisonSheetSelectedConditionGroupId' is not null or undefined
            assertParamExists('updateComparisonSheetSelectedConditionGroup', 'comparisonSheetSelectedConditionGroupId', comparisonSheetSelectedConditionGroupId)
            // verify required parameter 'createOrUpdateComparisonSheetSelectedConditionGroupRequestBody' is not null or undefined
            assertParamExists('updateComparisonSheetSelectedConditionGroup', 'createOrUpdateComparisonSheetSelectedConditionGroupRequestBody', createOrUpdateComparisonSheetSelectedConditionGroupRequestBody)
            const localVarPath = `/api/comparison-sheet-selected-condition-groups/{comparisonSheetSelectedConditionGroupId}`
                .replace(`{${"comparisonSheetSelectedConditionGroupId"}}`, encodeURIComponent(String(comparisonSheetSelectedConditionGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateComparisonSheetSelectedConditionGroupRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComparisonSheetSelectedConditionGroupControllerApi - functional programming interface
 * @export
 */
export const ComparisonSheetSelectedConditionGroupControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComparisonSheetSelectedConditionGroupControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 対比表選択条件グループの作成API
         * @param {CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody} createOrUpdateComparisonSheetSelectedConditionGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComparisonSheetSelectedConditionGroup(createOrUpdateComparisonSheetSelectedConditionGroupRequestBody: CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComparisonSheetSelectedConditionGroup(createOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対比表選択条件グループの削除
         * @param {string} comparisonSheetSelectedConditionGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対比表選択条件グループの一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listForSelection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparisonSheetSelectedConditionForSelectionView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listForSelection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対比表選択条件グループとその選択条件一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWithSelectedConditions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparisonSheetSelectedConditionGroupView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWithSelectedConditions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 対比表選択条件グループ名の更新
         * @param {string} comparisonSheetSelectedConditionGroupId 
         * @param {CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody} createOrUpdateComparisonSheetSelectedConditionGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId: string, createOrUpdateComparisonSheetSelectedConditionGroupRequestBody: CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId, createOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComparisonSheetSelectedConditionGroupControllerApi - factory interface
 * @export
 */
export const ComparisonSheetSelectedConditionGroupControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComparisonSheetSelectedConditionGroupControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 対比表選択条件グループの作成API
         * @param {CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody} createOrUpdateComparisonSheetSelectedConditionGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComparisonSheetSelectedConditionGroup(createOrUpdateComparisonSheetSelectedConditionGroupRequestBody: CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createComparisonSheetSelectedConditionGroup(createOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対比表選択条件グループの削除
         * @param {string} comparisonSheetSelectedConditionGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対比表選択条件グループの一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForSelection(options?: any): AxiosPromise<Array<ComparisonSheetSelectedConditionForSelectionView>> {
            return localVarFp.listForSelection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対比表選択条件グループとその選択条件一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithSelectedConditions(options?: any): AxiosPromise<Array<ComparisonSheetSelectedConditionGroupView>> {
            return localVarFp.listWithSelectedConditions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 対比表選択条件グループ名の更新
         * @param {string} comparisonSheetSelectedConditionGroupId 
         * @param {CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody} createOrUpdateComparisonSheetSelectedConditionGroupRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId: string, createOrUpdateComparisonSheetSelectedConditionGroupRequestBody: CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId, createOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComparisonSheetSelectedConditionGroupControllerApi - object-oriented interface
 * @export
 * @class ComparisonSheetSelectedConditionGroupControllerApi
 * @extends {BaseAPI}
 */
export class ComparisonSheetSelectedConditionGroupControllerApi extends BaseAPI {
    /**
     * 
     * @summary 対比表選択条件グループの作成API
     * @param {CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody} createOrUpdateComparisonSheetSelectedConditionGroupRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionGroupControllerApi
     */
    public createComparisonSheetSelectedConditionGroup(createOrUpdateComparisonSheetSelectedConditionGroupRequestBody: CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionGroupControllerApiFp(this.configuration).createComparisonSheetSelectedConditionGroup(createOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対比表選択条件グループの削除
     * @param {string} comparisonSheetSelectedConditionGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionGroupControllerApi
     */
    public deleteComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId: string, options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionGroupControllerApiFp(this.configuration).deleteComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対比表選択条件グループの一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionGroupControllerApi
     */
    public listForSelection(options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionGroupControllerApiFp(this.configuration).listForSelection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対比表選択条件グループとその選択条件一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionGroupControllerApi
     */
    public listWithSelectedConditions(options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionGroupControllerApiFp(this.configuration).listWithSelectedConditions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 対比表選択条件グループ名の更新
     * @param {string} comparisonSheetSelectedConditionGroupId 
     * @param {CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody} createOrUpdateComparisonSheetSelectedConditionGroupRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonSheetSelectedConditionGroupControllerApi
     */
    public updateComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId: string, createOrUpdateComparisonSheetSelectedConditionGroupRequestBody: CreateOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options?: AxiosRequestConfig) {
        return ComparisonSheetSelectedConditionGroupControllerApiFp(this.configuration).updateComparisonSheetSelectedConditionGroup(comparisonSheetSelectedConditionGroupId, createOrUpdateComparisonSheetSelectedConditionGroupRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComparisonV3ControllerApi - axios parameter creator
 * @export
 */
export const ComparisonV3ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 対比表をExcelでダウンロードする
         * @param {FetchComparisonRequestBody} fetchComparisonRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonV3Excel: async (fetchComparisonRequestBody: FetchComparisonRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchComparisonRequestBody' is not null or undefined
            assertParamExists('downloadComparisonV3Excel', 'fetchComparisonRequestBody', fetchComparisonRequestBody)
            const localVarPath = `/api/v3/comparison/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchComparisonRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actual or PlanA vs Actual or PlanB
         * @summary 対比表API V3
         * @param {FetchComparisonRequestBody} fetchComparisonRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchComparisonV3: async (fetchComparisonRequestBody: FetchComparisonRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchComparisonRequestBody' is not null or undefined
            assertParamExists('fetchComparisonV3', 'fetchComparisonRequestBody', fetchComparisonRequestBody)
            const localVarPath = `/api/v3/comparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchComparisonRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComparisonV3ControllerApi - functional programming interface
 * @export
 */
export const ComparisonV3ControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComparisonV3ControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 対比表をExcelでダウンロードする
         * @param {FetchComparisonRequestBody} fetchComparisonRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadComparisonV3Excel(fetchComparisonRequestBody: FetchComparisonRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadComparisonV3Excel(fetchComparisonRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actual or PlanA vs Actual or PlanB
         * @summary 対比表API V3
         * @param {FetchComparisonRequestBody} fetchComparisonRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchComparisonV3(fetchComparisonRequestBody: FetchComparisonRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonTableV3View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchComparisonV3(fetchComparisonRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComparisonV3ControllerApi - factory interface
 * @export
 */
export const ComparisonV3ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComparisonV3ControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 対比表をExcelでダウンロードする
         * @param {FetchComparisonRequestBody} fetchComparisonRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadComparisonV3Excel(fetchComparisonRequestBody: FetchComparisonRequestBody, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.downloadComparisonV3Excel(fetchComparisonRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Actual or PlanA vs Actual or PlanB
         * @summary 対比表API V3
         * @param {FetchComparisonRequestBody} fetchComparisonRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchComparisonV3(fetchComparisonRequestBody: FetchComparisonRequestBody, options?: any): AxiosPromise<ComparisonTableV3View> {
            return localVarFp.fetchComparisonV3(fetchComparisonRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComparisonV3ControllerApi - object-oriented interface
 * @export
 * @class ComparisonV3ControllerApi
 * @extends {BaseAPI}
 */
export class ComparisonV3ControllerApi extends BaseAPI {
    /**
     * 
     * @summary 対比表をExcelでダウンロードする
     * @param {FetchComparisonRequestBody} fetchComparisonRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonV3ControllerApi
     */
    public downloadComparisonV3Excel(fetchComparisonRequestBody: FetchComparisonRequestBody, options?: AxiosRequestConfig) {
        return ComparisonV3ControllerApiFp(this.configuration).downloadComparisonV3Excel(fetchComparisonRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actual or PlanA vs Actual or PlanB
     * @summary 対比表API V3
     * @param {FetchComparisonRequestBody} fetchComparisonRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparisonV3ControllerApi
     */
    public fetchComparisonV3(fetchComparisonRequestBody: FetchComparisonRequestBody, options?: AxiosRequestConfig) {
        return ComparisonV3ControllerApiFp(this.configuration).fetchComparisonV3(fetchComparisonRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardControllerApi - axios parameter creator
 * @export
 */
export const DashboardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary tableauダッシュボード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableauDashboard: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/tableau`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardControllerApi - functional programming interface
 * @export
 */
export const DashboardControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary tableauダッシュボード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTableauDashboard(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableauDashboardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTableauDashboard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardControllerApi - factory interface
 * @export
 */
export const DashboardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary tableauダッシュボード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableauDashboard(options?: any): AxiosPromise<TableauDashboardDto> {
            return localVarFp.getTableauDashboard(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardControllerApi - object-oriented interface
 * @export
 * @class DashboardControllerApi
 * @extends {BaseAPI}
 */
export class DashboardControllerApi extends BaseAPI {
    /**
     * 
     * @summary tableauダッシュボード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getTableauDashboard(options?: AxiosRequestConfig) {
        return DashboardControllerApiFp(this.configuration).getTableauDashboard(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentAssignmentControllerApi - axios parameter creator
 * @export
 */
export const DepartmentAssignmentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ユーザー一に紐づく部署の組織図を再帰構造で取得
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDepartmentAssignment: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listDepartmentAssignment', 'userId', userId)
            const localVarPath = `/api/user/{userId}/departments/assignments`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーの配属を一括更新する
         * @param {string} userId 
         * @param {UpdateAssignmentsParam} updateAssignmentsParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignment: async (userId: string, updateAssignmentsParam: UpdateAssignmentsParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateAssignment', 'userId', userId)
            // verify required parameter 'updateAssignmentsParam' is not null or undefined
            assertParamExists('updateAssignment', 'updateAssignmentsParam', updateAssignmentsParam)
            const localVarPath = `/api/user/{userId}/departments/assignments`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAssignmentsParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentAssignmentControllerApi - functional programming interface
 * @export
 */
export const DepartmentAssignmentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentAssignmentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ユーザー一に紐づく部署の組織図を再帰構造で取得
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDepartmentAssignment(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssignedDepartmentView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDepartmentAssignment(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーの配属を一括更新する
         * @param {string} userId 
         * @param {UpdateAssignmentsParam} updateAssignmentsParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignment(userId: string, updateAssignmentsParam: UpdateAssignmentsParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignment(userId, updateAssignmentsParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentAssignmentControllerApi - factory interface
 * @export
 */
export const DepartmentAssignmentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentAssignmentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary ユーザー一に紐づく部署の組織図を再帰構造で取得
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDepartmentAssignment(userId: string, options?: any): AxiosPromise<Array<AssignedDepartmentView>> {
            return localVarFp.listDepartmentAssignment(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーの配属を一括更新する
         * @param {string} userId 
         * @param {UpdateAssignmentsParam} updateAssignmentsParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignment(userId: string, updateAssignmentsParam: UpdateAssignmentsParam, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateAssignment(userId, updateAssignmentsParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentAssignmentControllerApi - object-oriented interface
 * @export
 * @class DepartmentAssignmentControllerApi
 * @extends {BaseAPI}
 */
export class DepartmentAssignmentControllerApi extends BaseAPI {
    /**
     * 
     * @summary ユーザー一に紐づく部署の組織図を再帰構造で取得
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentAssignmentControllerApi
     */
    public listDepartmentAssignment(userId: string, options?: AxiosRequestConfig) {
        return DepartmentAssignmentControllerApiFp(this.configuration).listDepartmentAssignment(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーの配属を一括更新する
     * @param {string} userId 
     * @param {UpdateAssignmentsParam} updateAssignmentsParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentAssignmentControllerApi
     */
    public updateAssignment(userId: string, updateAssignmentsParam: UpdateAssignmentsParam, options?: AxiosRequestConfig) {
        return DepartmentAssignmentControllerApiFp(this.configuration).updateAssignment(userId, updateAssignmentsParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentComparisonSheetSelectedConditionControllerApi - axios parameter creator
 * @export
 */
export const DepartmentComparisonSheetSelectedConditionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 部署別対比表選択条件の作成
         * @param {CreateDepartmentComparisonSheetSelectedConditionRequestBody} createDepartmentComparisonSheetSelectedConditionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentComparisonSheetSelectedCondition: async (createDepartmentComparisonSheetSelectedConditionRequestBody: CreateDepartmentComparisonSheetSelectedConditionRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDepartmentComparisonSheetSelectedConditionRequestBody' is not null or undefined
            assertParamExists('createDepartmentComparisonSheetSelectedCondition', 'createDepartmentComparisonSheetSelectedConditionRequestBody', createDepartmentComparisonSheetSelectedConditionRequestBody)
            const localVarPath = `/api/department-comparison-sheet-selected-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepartmentComparisonSheetSelectedConditionRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 部署別対比表選択条件保存の削除
         * @param {string} departmentComparisonSheetSelectedConditionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentComparisonSheetSelectedCondition: async (departmentComparisonSheetSelectedConditionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentComparisonSheetSelectedConditionId' is not null or undefined
            assertParamExists('deleteDepartmentComparisonSheetSelectedCondition', 'departmentComparisonSheetSelectedConditionId', departmentComparisonSheetSelectedConditionId)
            const localVarPath = `/api/department-comparison-sheet-selected-conditions/{departmentComparisonSheetSelectedConditionId}`
                .replace(`{${"departmentComparisonSheetSelectedConditionId"}}`, encodeURIComponent(String(departmentComparisonSheetSelectedConditionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 部署別対比表選択条件一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDepartmentComparisonSheetSelectedCondition: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/department-comparison-sheet-selected-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentComparisonSheetSelectedConditionControllerApi - functional programming interface
 * @export
 */
export const DepartmentComparisonSheetSelectedConditionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentComparisonSheetSelectedConditionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 部署別対比表選択条件の作成
         * @param {CreateDepartmentComparisonSheetSelectedConditionRequestBody} createDepartmentComparisonSheetSelectedConditionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartmentComparisonSheetSelectedCondition(createDepartmentComparisonSheetSelectedConditionRequestBody: CreateDepartmentComparisonSheetSelectedConditionRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDepartmentComparisonSheetSelectedConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartmentComparisonSheetSelectedCondition(createDepartmentComparisonSheetSelectedConditionRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 部署別対比表選択条件保存の削除
         * @param {string} departmentComparisonSheetSelectedConditionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartmentComparisonSheetSelectedCondition(departmentComparisonSheetSelectedConditionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentComparisonSheetSelectedCondition(departmentComparisonSheetSelectedConditionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 部署別対比表選択条件一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDepartmentComparisonSheetSelectedCondition(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentComparisonSheetSelectedConditionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDepartmentComparisonSheetSelectedCondition(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentComparisonSheetSelectedConditionControllerApi - factory interface
 * @export
 */
export const DepartmentComparisonSheetSelectedConditionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentComparisonSheetSelectedConditionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 部署別対比表選択条件の作成
         * @param {CreateDepartmentComparisonSheetSelectedConditionRequestBody} createDepartmentComparisonSheetSelectedConditionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentComparisonSheetSelectedCondition(createDepartmentComparisonSheetSelectedConditionRequestBody: CreateDepartmentComparisonSheetSelectedConditionRequestBody, options?: any): AxiosPromise<CreateDepartmentComparisonSheetSelectedConditionResponse> {
            return localVarFp.createDepartmentComparisonSheetSelectedCondition(createDepartmentComparisonSheetSelectedConditionRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 部署別対比表選択条件保存の削除
         * @param {string} departmentComparisonSheetSelectedConditionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentComparisonSheetSelectedCondition(departmentComparisonSheetSelectedConditionId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteDepartmentComparisonSheetSelectedCondition(departmentComparisonSheetSelectedConditionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 部署別対比表選択条件一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDepartmentComparisonSheetSelectedCondition(options?: any): AxiosPromise<Array<DepartmentComparisonSheetSelectedConditionDto>> {
            return localVarFp.listDepartmentComparisonSheetSelectedCondition(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentComparisonSheetSelectedConditionControllerApi - object-oriented interface
 * @export
 * @class DepartmentComparisonSheetSelectedConditionControllerApi
 * @extends {BaseAPI}
 */
export class DepartmentComparisonSheetSelectedConditionControllerApi extends BaseAPI {
    /**
     * 
     * @summary 部署別対比表選択条件の作成
     * @param {CreateDepartmentComparisonSheetSelectedConditionRequestBody} createDepartmentComparisonSheetSelectedConditionRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentComparisonSheetSelectedConditionControllerApi
     */
    public createDepartmentComparisonSheetSelectedCondition(createDepartmentComparisonSheetSelectedConditionRequestBody: CreateDepartmentComparisonSheetSelectedConditionRequestBody, options?: AxiosRequestConfig) {
        return DepartmentComparisonSheetSelectedConditionControllerApiFp(this.configuration).createDepartmentComparisonSheetSelectedCondition(createDepartmentComparisonSheetSelectedConditionRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 部署別対比表選択条件保存の削除
     * @param {string} departmentComparisonSheetSelectedConditionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentComparisonSheetSelectedConditionControllerApi
     */
    public deleteDepartmentComparisonSheetSelectedCondition(departmentComparisonSheetSelectedConditionId: string, options?: AxiosRequestConfig) {
        return DepartmentComparisonSheetSelectedConditionControllerApiFp(this.configuration).deleteDepartmentComparisonSheetSelectedCondition(departmentComparisonSheetSelectedConditionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 部署別対比表選択条件一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentComparisonSheetSelectedConditionControllerApi
     */
    public listDepartmentComparisonSheetSelectedCondition(options?: AxiosRequestConfig) {
        return DepartmentComparisonSheetSelectedConditionControllerApiFp(this.configuration).listDepartmentComparisonSheetSelectedCondition(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentControllerApi - axios parameter creator
 * @export
 */
export const DepartmentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 部署情報の作成
         * @param {CreateDepartmentParam} createDepartmentParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartment: async (createDepartmentParam: CreateDepartmentParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDepartmentParam' is not null or undefined
            assertParamExists('createDepartment', 'createDepartmentParam', createDepartmentParam)
            const localVarPath = `/api/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepartmentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 部署削除
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartment', 'departmentId', departmentId)
            const localVarPath = `/api/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 部署情報とその部署に配属されたユーザー情報を合わせて取得する
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDepartmentWithAssignedActiveUsers: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('findDepartmentWithAssignedActiveUsers', 'departmentId', departmentId)
            const localVarPath = `/api/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 部署一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDepartments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定部署に配属されていないユーザー一覧を取得する
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnassignedActiveUsers: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('listUnassignedActiveUsers', 'departmentId', departmentId)
            const localVarPath = `/api/departments/{departmentId}/unassigned`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 部署情報と配属の更新
         * @param {string} departmentId 
         * @param {UpdateDepartmentParam} updateDepartmentParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment: async (departmentId: string, updateDepartmentParam: UpdateDepartmentParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('updateDepartment', 'departmentId', departmentId)
            // verify required parameter 'updateDepartmentParam' is not null or undefined
            assertParamExists('updateDepartment', 'updateDepartmentParam', updateDepartmentParam)
            const localVarPath = `/api/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDepartmentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentControllerApi - functional programming interface
 * @export
 */
export const DepartmentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 部署情報の作成
         * @param {CreateDepartmentParam} createDepartmentParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartment(createDepartmentParam: CreateDepartmentParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartment(createDepartmentParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 部署削除
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartment(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartment(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 部署情報とその部署に配属されたユーザー情報を合わせて取得する
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDepartmentWithAssignedActiveUsers(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentWithAssignedActiveUsersView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDepartmentWithAssignedActiveUsers(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 部署一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDepartments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDepartments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定部署に配属されていないユーザー一覧を取得する
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnassignedActiveUsers(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListUnassignedActiveUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnassignedActiveUsers(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 部署情報と配属の更新
         * @param {string} departmentId 
         * @param {UpdateDepartmentParam} updateDepartmentParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDepartment(departmentId: string, updateDepartmentParam: UpdateDepartmentParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentWithAssignmentView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartment(departmentId, updateDepartmentParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentControllerApi - factory interface
 * @export
 */
export const DepartmentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 部署情報の作成
         * @param {CreateDepartmentParam} createDepartmentParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartment(createDepartmentParam: CreateDepartmentParam, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createDepartment(createDepartmentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 部署削除
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment(departmentId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteDepartment(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 部署情報とその部署に配属されたユーザー情報を合わせて取得する
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDepartmentWithAssignedActiveUsers(departmentId: string, options?: any): AxiosPromise<DepartmentWithAssignedActiveUsersView> {
            return localVarFp.findDepartmentWithAssignedActiveUsers(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 部署一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDepartments(options?: any): AxiosPromise<DepartmentsView> {
            return localVarFp.listDepartments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定部署に配属されていないユーザー一覧を取得する
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnassignedActiveUsers(departmentId: string, options?: any): AxiosPromise<Array<ListUnassignedActiveUserDto>> {
            return localVarFp.listUnassignedActiveUsers(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 部署情報と配属の更新
         * @param {string} departmentId 
         * @param {UpdateDepartmentParam} updateDepartmentParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment(departmentId: string, updateDepartmentParam: UpdateDepartmentParam, options?: any): AxiosPromise<DepartmentWithAssignmentView> {
            return localVarFp.updateDepartment(departmentId, updateDepartmentParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentControllerApi - object-oriented interface
 * @export
 * @class DepartmentControllerApi
 * @extends {BaseAPI}
 */
export class DepartmentControllerApi extends BaseAPI {
    /**
     * 
     * @summary 部署情報の作成
     * @param {CreateDepartmentParam} createDepartmentParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentControllerApi
     */
    public createDepartment(createDepartmentParam: CreateDepartmentParam, options?: AxiosRequestConfig) {
        return DepartmentControllerApiFp(this.configuration).createDepartment(createDepartmentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 部署削除
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentControllerApi
     */
    public deleteDepartment(departmentId: string, options?: AxiosRequestConfig) {
        return DepartmentControllerApiFp(this.configuration).deleteDepartment(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 部署情報とその部署に配属されたユーザー情報を合わせて取得する
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentControllerApi
     */
    public findDepartmentWithAssignedActiveUsers(departmentId: string, options?: AxiosRequestConfig) {
        return DepartmentControllerApiFp(this.configuration).findDepartmentWithAssignedActiveUsers(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 部署一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentControllerApi
     */
    public listDepartments(options?: AxiosRequestConfig) {
        return DepartmentControllerApiFp(this.configuration).listDepartments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定部署に配属されていないユーザー一覧を取得する
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentControllerApi
     */
    public listUnassignedActiveUsers(departmentId: string, options?: AxiosRequestConfig) {
        return DepartmentControllerApiFp(this.configuration).listUnassignedActiveUsers(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 部署情報と配属の更新
     * @param {string} departmentId 
     * @param {UpdateDepartmentParam} updateDepartmentParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentControllerApi
     */
    public updateDepartment(departmentId: string, updateDepartmentParam: UpdateDepartmentParam, options?: AxiosRequestConfig) {
        return DepartmentControllerApiFp(this.configuration).updateDepartment(departmentId, updateDepartmentParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DimensionTagControllerApi - axios parameter creator
 * @export
 */
export const DimensionTagControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 分析軸タグの作成
         * @param {CreateDimensionTagsRequestBody} createDimensionTagsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags: async (createDimensionTagsRequestBody: CreateDimensionTagsRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDimensionTagsRequestBody' is not null or undefined
            assertParamExists('createTags', 'createDimensionTagsRequestBody', createDimensionTagsRequestBody)
            const localVarPath = `/api/dimension-tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDimensionTagsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸タグの削除
         * @param {string} dimensionTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDimensionTag: async (dimensionTagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionTagId' is not null or undefined
            assertParamExists('deleteDimensionTag', 'dimensionTagId', dimensionTagId)
            const localVarPath = `/api/dimension-tags/{dimensionTagId}`
                .replace(`{${"dimensionTagId"}}`, encodeURIComponent(String(dimensionTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸タグの更新
         * @param {string} dimensionTagId 
         * @param {UpdateDimensionTagsRequestBody} updateDimensionTagsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDimensionTag: async (dimensionTagId: string, updateDimensionTagsRequestBody: UpdateDimensionTagsRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionTagId' is not null or undefined
            assertParamExists('updateDimensionTag', 'dimensionTagId', dimensionTagId)
            // verify required parameter 'updateDimensionTagsRequestBody' is not null or undefined
            assertParamExists('updateDimensionTag', 'updateDimensionTagsRequestBody', updateDimensionTagsRequestBody)
            const localVarPath = `/api/dimension-tags/{dimensionTagId}`
                .replace(`{${"dimensionTagId"}}`, encodeURIComponent(String(dimensionTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDimensionTagsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DimensionTagControllerApi - functional programming interface
 * @export
 */
export const DimensionTagControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DimensionTagControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 分析軸タグの作成
         * @param {CreateDimensionTagsRequestBody} createDimensionTagsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTags(createDimensionTagsRequestBody: CreateDimensionTagsRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTags(createDimensionTagsRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸タグの削除
         * @param {string} dimensionTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDimensionTag(dimensionTagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDimensionTag(dimensionTagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸タグの更新
         * @param {string} dimensionTagId 
         * @param {UpdateDimensionTagsRequestBody} updateDimensionTagsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDimensionTag(dimensionTagId: string, updateDimensionTagsRequestBody: UpdateDimensionTagsRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDimensionTag(dimensionTagId, updateDimensionTagsRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DimensionTagControllerApi - factory interface
 * @export
 */
export const DimensionTagControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DimensionTagControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 分析軸タグの作成
         * @param {CreateDimensionTagsRequestBody} createDimensionTagsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags(createDimensionTagsRequestBody: CreateDimensionTagsRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createTags(createDimensionTagsRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸タグの削除
         * @param {string} dimensionTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDimensionTag(dimensionTagId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteDimensionTag(dimensionTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸タグの更新
         * @param {string} dimensionTagId 
         * @param {UpdateDimensionTagsRequestBody} updateDimensionTagsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDimensionTag(dimensionTagId: string, updateDimensionTagsRequestBody: UpdateDimensionTagsRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateDimensionTag(dimensionTagId, updateDimensionTagsRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DimensionTagControllerApi - object-oriented interface
 * @export
 * @class DimensionTagControllerApi
 * @extends {BaseAPI}
 */
export class DimensionTagControllerApi extends BaseAPI {
    /**
     * 
     * @summary 分析軸タグの作成
     * @param {CreateDimensionTagsRequestBody} createDimensionTagsRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTagControllerApi
     */
    public createTags(createDimensionTagsRequestBody: CreateDimensionTagsRequestBody, options?: AxiosRequestConfig) {
        return DimensionTagControllerApiFp(this.configuration).createTags(createDimensionTagsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸タグの削除
     * @param {string} dimensionTagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTagControllerApi
     */
    public deleteDimensionTag(dimensionTagId: string, options?: AxiosRequestConfig) {
        return DimensionTagControllerApiFp(this.configuration).deleteDimensionTag(dimensionTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸タグの更新
     * @param {string} dimensionTagId 
     * @param {UpdateDimensionTagsRequestBody} updateDimensionTagsRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTagControllerApi
     */
    public updateDimensionTag(dimensionTagId: string, updateDimensionTagsRequestBody: UpdateDimensionTagsRequestBody, options?: AxiosRequestConfig) {
        return DimensionTagControllerApiFp(this.configuration).updateDimensionTag(dimensionTagId, updateDimensionTagsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DimensionTreeControllerApi - axios parameter creator
 * @export
 */
export const DimensionTreeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 分析軸階層に分析軸タグを追加するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {AddDimensionTreeNodeRequestBody} addDimensionTreeNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDimensionTreeNode: async (dimensionId: string, dimensionTreeId: string, addDimensionTreeNodeRequestBody: AddDimensionTreeNodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('addDimensionTreeNode', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('addDimensionTreeNode', 'dimensionTreeId', dimensionTreeId)
            // verify required parameter 'addDimensionTreeNodeRequestBody' is not null or undefined
            assertParamExists('addDimensionTreeNode', 'addDimensionTreeNodeRequestBody', addDimensionTreeNodeRequestBody)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/add`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDimensionTreeNodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸階層をコピーするAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {CopyDimensionTreeBody} copyDimensionTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDimensionTree: async (dimensionId: string, dimensionTreeId: string, copyDimensionTreeBody: CopyDimensionTreeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('copyDimensionTree', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('copyDimensionTree', 'dimensionTreeId', dimensionTreeId)
            // verify required parameter 'copyDimensionTreeBody' is not null or undefined
            assertParamExists('copyDimensionTree', 'copyDimensionTreeBody', copyDimensionTreeBody)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/make-copy`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyDimensionTreeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸タグを作成し、分析軸階層に分析軸タグを追加するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {CreateAndAddDimensionTagsRequestBody} createAndAddDimensionTagsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndAddDimensionTreeNode: async (dimensionId: string, dimensionTreeId: string, createAndAddDimensionTagsRequestBody: CreateAndAddDimensionTagsRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('createAndAddDimensionTreeNode', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('createAndAddDimensionTreeNode', 'dimensionTreeId', dimensionTreeId)
            // verify required parameter 'createAndAddDimensionTagsRequestBody' is not null or undefined
            assertParamExists('createAndAddDimensionTreeNode', 'createAndAddDimensionTagsRequestBody', createAndAddDimensionTagsRequestBody)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/create-and-add-tree`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAndAddDimensionTagsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸階層を削除するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDimensionTree: async (dimensionId: string, dimensionTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('deleteDimensionTree', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('deleteDimensionTree', 'dimensionTreeId', dimensionTreeId)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸タグを階層から外すAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {string} dimensionTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachDimensionTreeNode: async (dimensionId: string, dimensionTreeId: string, dimensionTagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('detachDimensionTreeNode', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('detachDimensionTreeNode', 'dimensionTreeId', dimensionTreeId)
            // verify required parameter 'dimensionTagId' is not null or undefined
            assertParamExists('detachDimensionTreeNode', 'dimensionTagId', dimensionTagId)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/detach/{dimensionTagId}`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)))
                .replace(`{${"dimensionTagId"}}`, encodeURIComponent(String(dimensionTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excelファイルをもとに分析軸階層を更新するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dimensionTreeFileUpload: async (dimensionId: string, dimensionTreeId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('dimensionTreeFileUpload', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('dimensionTreeFileUpload', 'dimensionTreeId', dimensionTreeId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('dimensionTreeFileUpload', 'file', file)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/bulk-update`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸階層Excelファイルを取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDimensionTreeFile: async (dimensionId: string, dimensionTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('downloadDimensionTreeFile', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('downloadDimensionTreeFile', 'dimensionTreeId', dimensionTreeId)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/excel`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸階層を取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDimensionTree: async (dimensionId: string, dimensionTreeId: string, searchWord?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('getDimensionTree', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('getDimensionTree', 'dimensionTreeId', dimensionTreeId)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchWord !== undefined) {
                localVarQueryParameter['searchWord'] = searchWord;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 階層名昇順の最初の階層を取得する
         * @summary 分析軸階層(本体値付き)を取得するAPI
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDimensionTreeForSelection: async (dimensionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('getDimensionTreeForSelection', 'dimensionId', dimensionId)
            const localVarPath = `/api/dimension-tree/{dimensionId}/for-selection`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 階層パターンを指定して、分析軸階層(本体値付き)を取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDimensionTreeForSelectionByTreeId: async (dimensionId: string, dimensionTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('getDimensionTreeForSelectionByTreeId', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('getDimensionTreeForSelectionByTreeId', 'dimensionTreeId', dimensionTreeId)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/for-selection`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸階層に含まれない分析軸タグ一覧を取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncludedDimensionTags: async (dimensionId: string, dimensionTreeId: string, searchWord?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('getNotIncludedDimensionTags', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('getNotIncludedDimensionTags', 'dimensionTreeId', dimensionTreeId)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/not-included`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchWord !== undefined) {
                localVarQueryParameter['searchWord'] = searchWord;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸階層のリストを取得するAPI
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDimensionTree: async (dimensionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('listDimensionTree', 'dimensionId', dimensionId)
            const localVarPath = `/api/dimension-tree/{dimensionId}`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 階層内の分析軸タグを移動するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {MoveDimensionTreeNodeRequestBody} moveDimensionTreeNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveDimensionTreeNode: async (dimensionId: string, dimensionTreeId: string, moveDimensionTreeNodeRequestBody: MoveDimensionTreeNodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('moveDimensionTreeNode', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('moveDimensionTreeNode', 'dimensionTreeId', dimensionTreeId)
            // verify required parameter 'moveDimensionTreeNodeRequestBody' is not null or undefined
            assertParamExists('moveDimensionTreeNode', 'moveDimensionTreeNodeRequestBody', moveDimensionTreeNodeRequestBody)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/move`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveDimensionTreeNodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸階層名を更新するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {UpdateDimensionTreeNameBody} updateDimensionTreeNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDimensionTreeName: async (dimensionId: string, dimensionTreeId: string, updateDimensionTreeNameBody: UpdateDimensionTreeNameBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('updateDimensionTreeName', 'dimensionId', dimensionId)
            // verify required parameter 'dimensionTreeId' is not null or undefined
            assertParamExists('updateDimensionTreeName', 'dimensionTreeId', dimensionTreeId)
            // verify required parameter 'updateDimensionTreeNameBody' is not null or undefined
            assertParamExists('updateDimensionTreeName', 'updateDimensionTreeNameBody', updateDimensionTreeNameBody)
            const localVarPath = `/api/dimension-tree/{dimensionId}/{dimensionTreeId}/name`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)))
                .replace(`{${"dimensionTreeId"}}`, encodeURIComponent(String(dimensionTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDimensionTreeNameBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DimensionTreeControllerApi - functional programming interface
 * @export
 */
export const DimensionTreeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DimensionTreeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 分析軸階層に分析軸タグを追加するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {AddDimensionTreeNodeRequestBody} addDimensionTreeNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDimensionTreeNode(dimensionId: string, dimensionTreeId: string, addDimensionTreeNodeRequestBody: AddDimensionTreeNodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDimensionTreeNode(dimensionId, dimensionTreeId, addDimensionTreeNodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸階層をコピーするAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {CopyDimensionTreeBody} copyDimensionTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyDimensionTree(dimensionId: string, dimensionTreeId: string, copyDimensionTreeBody: CopyDimensionTreeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DimensionTreeMetaView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyDimensionTree(dimensionId, dimensionTreeId, copyDimensionTreeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸タグを作成し、分析軸階層に分析軸タグを追加するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {CreateAndAddDimensionTagsRequestBody} createAndAddDimensionTagsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndAddDimensionTreeNode(dimensionId: string, dimensionTreeId: string, createAndAddDimensionTagsRequestBody: CreateAndAddDimensionTagsRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndAddDimensionTreeNode(dimensionId, dimensionTreeId, createAndAddDimensionTagsRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸階層を削除するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDimensionTree(dimensionId: string, dimensionTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDimensionTree(dimensionId, dimensionTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸タグを階層から外すAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {string} dimensionTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachDimensionTreeNode(dimensionId: string, dimensionTreeId: string, dimensionTagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachDimensionTreeNode(dimensionId, dimensionTreeId, dimensionTagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excelファイルをもとに分析軸階層を更新するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dimensionTreeFileUpload(dimensionId: string, dimensionTreeId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dimensionTreeFileUpload(dimensionId, dimensionTreeId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸階層Excelファイルを取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDimensionTreeFile(dimensionId: string, dimensionTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDimensionTreeFile(dimensionId, dimensionTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸階層を取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDimensionTree(dimensionId: string, dimensionTreeId: string, searchWord?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DimensionTagFlatTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDimensionTree(dimensionId, dimensionTreeId, searchWord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 階層名昇順の最初の階層を取得する
         * @summary 分析軸階層(本体値付き)を取得するAPI
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDimensionTreeForSelection(dimensionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DimensionTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDimensionTreeForSelection(dimensionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 階層パターンを指定して、分析軸階層(本体値付き)を取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDimensionTreeForSelectionByTreeId(dimensionId: string, dimensionTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DimensionTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDimensionTreeForSelectionByTreeId(dimensionId, dimensionTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸階層に含まれない分析軸タグ一覧を取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotIncludedDimensionTags(dimensionId: string, dimensionTreeId: string, searchWord?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotIncludedDimensionTagsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotIncludedDimensionTags(dimensionId, dimensionTreeId, searchWord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸階層のリストを取得するAPI
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDimensionTree(dimensionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DimensionTreeListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDimensionTree(dimensionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 階層内の分析軸タグを移動するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {MoveDimensionTreeNodeRequestBody} moveDimensionTreeNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveDimensionTreeNode(dimensionId: string, dimensionTreeId: string, moveDimensionTreeNodeRequestBody: MoveDimensionTreeNodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveDimensionTreeNode(dimensionId, dimensionTreeId, moveDimensionTreeNodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸階層名を更新するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {UpdateDimensionTreeNameBody} updateDimensionTreeNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDimensionTreeName(dimensionId: string, dimensionTreeId: string, updateDimensionTreeNameBody: UpdateDimensionTreeNameBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDimensionTreeName(dimensionId, dimensionTreeId, updateDimensionTreeNameBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DimensionTreeControllerApi - factory interface
 * @export
 */
export const DimensionTreeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DimensionTreeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 分析軸階層に分析軸タグを追加するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {AddDimensionTreeNodeRequestBody} addDimensionTreeNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDimensionTreeNode(dimensionId: string, dimensionTreeId: string, addDimensionTreeNodeRequestBody: AddDimensionTreeNodeRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.addDimensionTreeNode(dimensionId, dimensionTreeId, addDimensionTreeNodeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸階層をコピーするAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {CopyDimensionTreeBody} copyDimensionTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDimensionTree(dimensionId: string, dimensionTreeId: string, copyDimensionTreeBody: CopyDimensionTreeBody, options?: any): AxiosPromise<DimensionTreeMetaView> {
            return localVarFp.copyDimensionTree(dimensionId, dimensionTreeId, copyDimensionTreeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸タグを作成し、分析軸階層に分析軸タグを追加するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {CreateAndAddDimensionTagsRequestBody} createAndAddDimensionTagsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndAddDimensionTreeNode(dimensionId: string, dimensionTreeId: string, createAndAddDimensionTagsRequestBody: CreateAndAddDimensionTagsRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createAndAddDimensionTreeNode(dimensionId, dimensionTreeId, createAndAddDimensionTagsRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸階層を削除するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDimensionTree(dimensionId: string, dimensionTreeId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteDimensionTree(dimensionId, dimensionTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸タグを階層から外すAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {string} dimensionTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachDimensionTreeNode(dimensionId: string, dimensionTreeId: string, dimensionTagId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.detachDimensionTreeNode(dimensionId, dimensionTreeId, dimensionTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excelファイルをもとに分析軸階層を更新するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dimensionTreeFileUpload(dimensionId: string, dimensionTreeId: string, file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.dimensionTreeFileUpload(dimensionId, dimensionTreeId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸階層Excelファイルを取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDimensionTreeFile(dimensionId: string, dimensionTreeId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadDimensionTreeFile(dimensionId, dimensionTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸階層を取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDimensionTree(dimensionId: string, dimensionTreeId: string, searchWord?: string, options?: any): AxiosPromise<DimensionTagFlatTreeView> {
            return localVarFp.getDimensionTree(dimensionId, dimensionTreeId, searchWord, options).then((request) => request(axios, basePath));
        },
        /**
         * 階層名昇順の最初の階層を取得する
         * @summary 分析軸階層(本体値付き)を取得するAPI
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDimensionTreeForSelection(dimensionId: string, options?: any): AxiosPromise<DimensionTreeForSelectionView> {
            return localVarFp.getDimensionTreeForSelection(dimensionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 階層パターンを指定して、分析軸階層(本体値付き)を取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDimensionTreeForSelectionByTreeId(dimensionId: string, dimensionTreeId: string, options?: any): AxiosPromise<DimensionTreeForSelectionView> {
            return localVarFp.getDimensionTreeForSelectionByTreeId(dimensionId, dimensionTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸階層に含まれない分析軸タグ一覧を取得するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncludedDimensionTags(dimensionId: string, dimensionTreeId: string, searchWord?: string, options?: any): AxiosPromise<NotIncludedDimensionTagsView> {
            return localVarFp.getNotIncludedDimensionTags(dimensionId, dimensionTreeId, searchWord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸階層のリストを取得するAPI
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDimensionTree(dimensionId: string, options?: any): AxiosPromise<DimensionTreeListView> {
            return localVarFp.listDimensionTree(dimensionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 階層内の分析軸タグを移動するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {MoveDimensionTreeNodeRequestBody} moveDimensionTreeNodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveDimensionTreeNode(dimensionId: string, dimensionTreeId: string, moveDimensionTreeNodeRequestBody: MoveDimensionTreeNodeRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.moveDimensionTreeNode(dimensionId, dimensionTreeId, moveDimensionTreeNodeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸階層名を更新するAPI
         * @param {string} dimensionId 
         * @param {string} dimensionTreeId 
         * @param {UpdateDimensionTreeNameBody} updateDimensionTreeNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDimensionTreeName(dimensionId: string, dimensionTreeId: string, updateDimensionTreeNameBody: UpdateDimensionTreeNameBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateDimensionTreeName(dimensionId, dimensionTreeId, updateDimensionTreeNameBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DimensionTreeControllerApi - object-oriented interface
 * @export
 * @class DimensionTreeControllerApi
 * @extends {BaseAPI}
 */
export class DimensionTreeControllerApi extends BaseAPI {
    /**
     * 
     * @summary 分析軸階層に分析軸タグを追加するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {AddDimensionTreeNodeRequestBody} addDimensionTreeNodeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public addDimensionTreeNode(dimensionId: string, dimensionTreeId: string, addDimensionTreeNodeRequestBody: AddDimensionTreeNodeRequestBody, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).addDimensionTreeNode(dimensionId, dimensionTreeId, addDimensionTreeNodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸階層をコピーするAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {CopyDimensionTreeBody} copyDimensionTreeBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public copyDimensionTree(dimensionId: string, dimensionTreeId: string, copyDimensionTreeBody: CopyDimensionTreeBody, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).copyDimensionTree(dimensionId, dimensionTreeId, copyDimensionTreeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸タグを作成し、分析軸階層に分析軸タグを追加するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {CreateAndAddDimensionTagsRequestBody} createAndAddDimensionTagsRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public createAndAddDimensionTreeNode(dimensionId: string, dimensionTreeId: string, createAndAddDimensionTagsRequestBody: CreateAndAddDimensionTagsRequestBody, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).createAndAddDimensionTreeNode(dimensionId, dimensionTreeId, createAndAddDimensionTagsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸階層を削除するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public deleteDimensionTree(dimensionId: string, dimensionTreeId: string, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).deleteDimensionTree(dimensionId, dimensionTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸タグを階層から外すAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {string} dimensionTagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public detachDimensionTreeNode(dimensionId: string, dimensionTreeId: string, dimensionTagId: string, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).detachDimensionTreeNode(dimensionId, dimensionTreeId, dimensionTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excelファイルをもとに分析軸階層を更新するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public dimensionTreeFileUpload(dimensionId: string, dimensionTreeId: string, file: File, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).dimensionTreeFileUpload(dimensionId, dimensionTreeId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸階層Excelファイルを取得するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public downloadDimensionTreeFile(dimensionId: string, dimensionTreeId: string, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).downloadDimensionTreeFile(dimensionId, dimensionTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸階層を取得するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {string} [searchWord] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public getDimensionTree(dimensionId: string, dimensionTreeId: string, searchWord?: string, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).getDimensionTree(dimensionId, dimensionTreeId, searchWord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 階層名昇順の最初の階層を取得する
     * @summary 分析軸階層(本体値付き)を取得するAPI
     * @param {string} dimensionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public getDimensionTreeForSelection(dimensionId: string, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).getDimensionTreeForSelection(dimensionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 階層パターンを指定して、分析軸階層(本体値付き)を取得するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public getDimensionTreeForSelectionByTreeId(dimensionId: string, dimensionTreeId: string, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).getDimensionTreeForSelectionByTreeId(dimensionId, dimensionTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸階層に含まれない分析軸タグ一覧を取得するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {string} [searchWord] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public getNotIncludedDimensionTags(dimensionId: string, dimensionTreeId: string, searchWord?: string, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).getNotIncludedDimensionTags(dimensionId, dimensionTreeId, searchWord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸階層のリストを取得するAPI
     * @param {string} dimensionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public listDimensionTree(dimensionId: string, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).listDimensionTree(dimensionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 階層内の分析軸タグを移動するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {MoveDimensionTreeNodeRequestBody} moveDimensionTreeNodeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public moveDimensionTreeNode(dimensionId: string, dimensionTreeId: string, moveDimensionTreeNodeRequestBody: MoveDimensionTreeNodeRequestBody, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).moveDimensionTreeNode(dimensionId, dimensionTreeId, moveDimensionTreeNodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸階層名を更新するAPI
     * @param {string} dimensionId 
     * @param {string} dimensionTreeId 
     * @param {UpdateDimensionTreeNameBody} updateDimensionTreeNameBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionTreeControllerApi
     */
    public updateDimensionTreeName(dimensionId: string, dimensionTreeId: string, updateDimensionTreeNameBody: UpdateDimensionTreeNameBody, options?: AxiosRequestConfig) {
        return DimensionTreeControllerApiFp(this.configuration).updateDimensionTreeName(dimensionId, dimensionTreeId, updateDimensionTreeNameBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DimensionsControllerApi - axios parameter creator
 * @export
 */
export const DimensionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 分析軸の追加
         * @param {UpsertDimensionRequestBody} upsertDimensionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1: async (upsertDimensionRequestBody: UpsertDimensionRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertDimensionRequestBody' is not null or undefined
            assertParamExists('create1', 'upsertDimensionRequestBody', upsertDimensionRequestBody)
            const localVarPath = `/api/dimensions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertDimensionRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸の削除
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDimension: async (dimensionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('deleteDimension', 'dimensionId', dimensionId)
            const localVarPath = `/api/dimensions/{dimensionId}`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸タグの取得
         * @param {string} dimensionId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find: async (dimensionId: string, limit?: number, offset?: number, searchWord?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('find', 'dimensionId', dimensionId)
            const localVarPath = `/api/dimensions/{dimensionId}`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (searchWord !== undefined) {
                localVarQueryParameter['searchWord'] = searchWord;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸タグ一覧のExcelダウンロード
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find1: async (dimensionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('find1', 'dimensionId', dimensionId)
            const localVarPath = `/api/dimensions/{dimensionId}/excel`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸タグの取得
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDimensionTagsByDimensionId: async (dimensionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('findDimensionTagsByDimensionId', 'dimensionId', dimensionId)
            const localVarPath = `/api/dimensions/{dimensionId}/dimension-tags`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDimensions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dimensions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸の修正
         * @param {string} dimensionId 
         * @param {UpsertDimensionRequestBody} upsertDimensionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (dimensionId: string, upsertDimensionRequestBody: UpsertDimensionRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('update1', 'dimensionId', dimensionId)
            // verify required parameter 'upsertDimensionRequestBody' is not null or undefined
            assertParamExists('update1', 'upsertDimensionRequestBody', upsertDimensionRequestBody)
            const localVarPath = `/api/dimensions/{dimensionId}`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertDimensionRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸タグのExcel一括アップロード
         * @param {string} dimensionId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (dimensionId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimensionId' is not null or undefined
            assertParamExists('upload', 'dimensionId', dimensionId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('upload', 'file', file)
            const localVarPath = `/api/dimensions/{dimensionId}/excel/upload`
                .replace(`{${"dimensionId"}}`, encodeURIComponent(String(dimensionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DimensionsControllerApi - functional programming interface
 * @export
 */
export const DimensionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DimensionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 分析軸の追加
         * @param {UpsertDimensionRequestBody} upsertDimensionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create1(upsertDimensionRequestBody: UpsertDimensionRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create1(upsertDimensionRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸の削除
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDimension(dimensionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDimension(dimensionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸タグの取得
         * @param {string} dimensionId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find(dimensionId: string, limit?: number, offset?: number, searchWord?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DimensionTagListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find(dimensionId, limit, offset, searchWord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸タグ一覧のExcelダウンロード
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find1(dimensionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find1(dimensionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸タグの取得
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDimensionTagsByDimensionId(dimensionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DimensionTagsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDimensionTagsByDimensionId(dimensionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDimensions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DimensionView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDimensions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸の修正
         * @param {string} dimensionId 
         * @param {UpsertDimensionRequestBody} upsertDimensionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(dimensionId: string, upsertDimensionRequestBody: UpsertDimensionRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update1(dimensionId, upsertDimensionRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸タグのExcel一括アップロード
         * @param {string} dimensionId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(dimensionId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upload(dimensionId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DimensionsControllerApi - factory interface
 * @export
 */
export const DimensionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DimensionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 分析軸の追加
         * @param {UpsertDimensionRequestBody} upsertDimensionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(upsertDimensionRequestBody: UpsertDimensionRequestBody, options?: any): AxiosPromise<string> {
            return localVarFp.create1(upsertDimensionRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸の削除
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDimension(dimensionId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteDimension(dimensionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸タグの取得
         * @param {string} dimensionId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find(dimensionId: string, limit?: number, offset?: number, searchWord?: string, options?: any): AxiosPromise<DimensionTagListView> {
            return localVarFp.find(dimensionId, limit, offset, searchWord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸タグ一覧のExcelダウンロード
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find1(dimensionId: string, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.find1(dimensionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸タグの取得
         * @param {string} dimensionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDimensionTagsByDimensionId(dimensionId: string, options?: any): AxiosPromise<DimensionTagsView> {
            return localVarFp.findDimensionTagsByDimensionId(dimensionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDimensions(options?: any): AxiosPromise<Array<DimensionView>> {
            return localVarFp.listDimensions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸の修正
         * @param {string} dimensionId 
         * @param {UpsertDimensionRequestBody} upsertDimensionRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(dimensionId: string, upsertDimensionRequestBody: UpsertDimensionRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.update1(dimensionId, upsertDimensionRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸タグのExcel一括アップロード
         * @param {string} dimensionId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(dimensionId: string, file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.upload(dimensionId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DimensionsControllerApi - object-oriented interface
 * @export
 * @class DimensionsControllerApi
 * @extends {BaseAPI}
 */
export class DimensionsControllerApi extends BaseAPI {
    /**
     * 
     * @summary 分析軸の追加
     * @param {UpsertDimensionRequestBody} upsertDimensionRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionsControllerApi
     */
    public create1(upsertDimensionRequestBody: UpsertDimensionRequestBody, options?: AxiosRequestConfig) {
        return DimensionsControllerApiFp(this.configuration).create1(upsertDimensionRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸の削除
     * @param {string} dimensionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionsControllerApi
     */
    public deleteDimension(dimensionId: string, options?: AxiosRequestConfig) {
        return DimensionsControllerApiFp(this.configuration).deleteDimension(dimensionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸タグの取得
     * @param {string} dimensionId 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [searchWord] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionsControllerApi
     */
    public find(dimensionId: string, limit?: number, offset?: number, searchWord?: string, options?: AxiosRequestConfig) {
        return DimensionsControllerApiFp(this.configuration).find(dimensionId, limit, offset, searchWord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸タグ一覧のExcelダウンロード
     * @param {string} dimensionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionsControllerApi
     */
    public find1(dimensionId: string, options?: AxiosRequestConfig) {
        return DimensionsControllerApiFp(this.configuration).find1(dimensionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸タグの取得
     * @param {string} dimensionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionsControllerApi
     */
    public findDimensionTagsByDimensionId(dimensionId: string, options?: AxiosRequestConfig) {
        return DimensionsControllerApiFp(this.configuration).findDimensionTagsByDimensionId(dimensionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionsControllerApi
     */
    public listDimensions(options?: AxiosRequestConfig) {
        return DimensionsControllerApiFp(this.configuration).listDimensions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸の修正
     * @param {string} dimensionId 
     * @param {UpsertDimensionRequestBody} upsertDimensionRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionsControllerApi
     */
    public update1(dimensionId: string, upsertDimensionRequestBody: UpsertDimensionRequestBody, options?: AxiosRequestConfig) {
        return DimensionsControllerApiFp(this.configuration).update1(dimensionId, upsertDimensionRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸タグのExcel一括アップロード
     * @param {string} dimensionId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DimensionsControllerApi
     */
    public upload(dimensionId: string, file: File, options?: AxiosRequestConfig) {
        return DimensionsControllerApiFp(this.configuration).upload(dimensionId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DownloadExcelControllerApi - axios parameter creator
 * @export
 */
export const DownloadExcelControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 分析データダウンロード(Excel)
         * @param {DownloadRequestBody} downloadRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (downloadRequestBody: DownloadRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadRequestBody' is not null or undefined
            assertParamExists('download', 'downloadRequestBody', downloadRequestBody)
            const localVarPath = `/api/analytics/download/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadExcelControllerApi - functional programming interface
 * @export
 */
export const DownloadExcelControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DownloadExcelControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 分析データダウンロード(Excel)
         * @param {DownloadRequestBody} downloadRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(downloadRequestBody: DownloadRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(downloadRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DownloadExcelControllerApi - factory interface
 * @export
 */
export const DownloadExcelControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DownloadExcelControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 分析データダウンロード(Excel)
         * @param {DownloadRequestBody} downloadRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(downloadRequestBody: DownloadRequestBody, options?: any): AxiosPromise<File> {
            return localVarFp.download(downloadRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DownloadExcelControllerApi - object-oriented interface
 * @export
 * @class DownloadExcelControllerApi
 * @extends {BaseAPI}
 */
export class DownloadExcelControllerApi extends BaseAPI {
    /**
     * 
     * @summary 分析データダウンロード(Excel)
     * @param {DownloadRequestBody} downloadRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadExcelControllerApi
     */
    public download(downloadRequestBody: DownloadRequestBody, options?: AxiosRequestConfig) {
        return DownloadExcelControllerApiFp(this.configuration).download(downloadRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EachDepartmentComparisonV2ControllerApi - axios parameter creator
 * @export
 */
export const EachDepartmentComparisonV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 部署別表もしくは部署別対比表をExcelでダウンロードする
         * @param {FetchEachDepartmentComparisonV2RequestBody} fetchEachDepartmentComparisonV2RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEachDepartmentComparisonExcelV2: async (fetchEachDepartmentComparisonV2RequestBody: FetchEachDepartmentComparisonV2RequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchEachDepartmentComparisonV2RequestBody' is not null or undefined
            assertParamExists('downloadEachDepartmentComparisonExcelV2', 'fetchEachDepartmentComparisonV2RequestBody', fetchEachDepartmentComparisonV2RequestBody)
            const localVarPath = `/api/v2/each-department-comparison/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchEachDepartmentComparisonV2RequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 部署別対比表・部署別表API
         * @param {FetchEachDepartmentComparisonV2RequestBody} fetchEachDepartmentComparisonV2RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchComparison: async (fetchEachDepartmentComparisonV2RequestBody: FetchEachDepartmentComparisonV2RequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchEachDepartmentComparisonV2RequestBody' is not null or undefined
            assertParamExists('fetchComparison', 'fetchEachDepartmentComparisonV2RequestBody', fetchEachDepartmentComparisonV2RequestBody)
            const localVarPath = `/api/v2/each-department-comparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchEachDepartmentComparisonV2RequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EachDepartmentComparisonV2ControllerApi - functional programming interface
 * @export
 */
export const EachDepartmentComparisonV2ControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EachDepartmentComparisonV2ControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 部署別表もしくは部署別対比表をExcelでダウンロードする
         * @param {FetchEachDepartmentComparisonV2RequestBody} fetchEachDepartmentComparisonV2RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadEachDepartmentComparisonExcelV2(fetchEachDepartmentComparisonV2RequestBody: FetchEachDepartmentComparisonV2RequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadEachDepartmentComparisonExcelV2(fetchEachDepartmentComparisonV2RequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 部署別対比表・部署別表API
         * @param {FetchEachDepartmentComparisonV2RequestBody} fetchEachDepartmentComparisonV2RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchComparison(fetchEachDepartmentComparisonV2RequestBody: FetchEachDepartmentComparisonV2RequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EachDepartmentComparisonV2View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchComparison(fetchEachDepartmentComparisonV2RequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EachDepartmentComparisonV2ControllerApi - factory interface
 * @export
 */
export const EachDepartmentComparisonV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EachDepartmentComparisonV2ControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 部署別表もしくは部署別対比表をExcelでダウンロードする
         * @param {FetchEachDepartmentComparisonV2RequestBody} fetchEachDepartmentComparisonV2RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEachDepartmentComparisonExcelV2(fetchEachDepartmentComparisonV2RequestBody: FetchEachDepartmentComparisonV2RequestBody, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.downloadEachDepartmentComparisonExcelV2(fetchEachDepartmentComparisonV2RequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 部署別対比表・部署別表API
         * @param {FetchEachDepartmentComparisonV2RequestBody} fetchEachDepartmentComparisonV2RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchComparison(fetchEachDepartmentComparisonV2RequestBody: FetchEachDepartmentComparisonV2RequestBody, options?: any): AxiosPromise<EachDepartmentComparisonV2View> {
            return localVarFp.fetchComparison(fetchEachDepartmentComparisonV2RequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EachDepartmentComparisonV2ControllerApi - object-oriented interface
 * @export
 * @class EachDepartmentComparisonV2ControllerApi
 * @extends {BaseAPI}
 */
export class EachDepartmentComparisonV2ControllerApi extends BaseAPI {
    /**
     * 
     * @summary 部署別表もしくは部署別対比表をExcelでダウンロードする
     * @param {FetchEachDepartmentComparisonV2RequestBody} fetchEachDepartmentComparisonV2RequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EachDepartmentComparisonV2ControllerApi
     */
    public downloadEachDepartmentComparisonExcelV2(fetchEachDepartmentComparisonV2RequestBody: FetchEachDepartmentComparisonV2RequestBody, options?: AxiosRequestConfig) {
        return EachDepartmentComparisonV2ControllerApiFp(this.configuration).downloadEachDepartmentComparisonExcelV2(fetchEachDepartmentComparisonV2RequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 部署別対比表・部署別表API
     * @param {FetchEachDepartmentComparisonV2RequestBody} fetchEachDepartmentComparisonV2RequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EachDepartmentComparisonV2ControllerApi
     */
    public fetchComparison(fetchEachDepartmentComparisonV2RequestBody: FetchEachDepartmentComparisonV2RequestBody, options?: AxiosRequestConfig) {
        return EachDepartmentComparisonV2ControllerApiFp(this.configuration).fetchComparison(fetchEachDepartmentComparisonV2RequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnabledFeatureControllerApi - axios parameter creator
 * @export
 */
export const EnabledFeatureControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary フィーチャートグル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnabledFeatures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnabledFeatureControllerApi - functional programming interface
 * @export
 */
export const EnabledFeatureControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnabledFeatureControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary フィーチャートグル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEnabledFeatures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnabledFeaturesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEnabledFeatures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnabledFeatureControllerApi - factory interface
 * @export
 */
export const EnabledFeatureControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnabledFeatureControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary フィーチャートグル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnabledFeatures(options?: any): AxiosPromise<EnabledFeaturesDto> {
            return localVarFp.listEnabledFeatures(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnabledFeatureControllerApi - object-oriented interface
 * @export
 * @class EnabledFeatureControllerApi
 * @extends {BaseAPI}
 */
export class EnabledFeatureControllerApi extends BaseAPI {
    /**
     * 
     * @summary フィーチャートグル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnabledFeatureControllerApi
     */
    public listEnabledFeatures(options?: AxiosRequestConfig) {
        return EnabledFeatureControllerApiFp(this.configuration).listEnabledFeatures(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExchangeRateControllerApi - axios parameter creator
 * @export
 */
export const ExchangeRateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create exchange rate
         * @param {ExchangeRateRequestParam} exchangeRateRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchangeRate: async (exchangeRateRequestParam: ExchangeRateRequestParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRateRequestParam' is not null or undefined
            assertParamExists('createExchangeRate', 'exchangeRateRequestParam', exchangeRateRequestParam)
            const localVarPath = `/api/exchange_rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeRateRequestParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete exchange rate
         * @param {string} exchangeRateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExchangeRate: async (exchangeRateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRateId' is not null or undefined
            assertParamExists('deleteExchangeRate', 'exchangeRateId', exchangeRateId)
            const localVarPath = `/api/exchange_rate/{exchangeRateId}`
                .replace(`{${"exchangeRateId"}}`, encodeURIComponent(String(exchangeRateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GET exchange rate detail.
         * @param {string} exchangeRateId 
         * @param {string} financialReportType 
         * @param {string} fromFiscalMonthId 
         * @param {string} toFiscalMonthId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeRateDetail: async (exchangeRateId: string, financialReportType: string, fromFiscalMonthId: string, toFiscalMonthId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRateId' is not null or undefined
            assertParamExists('getExchangeRateDetail', 'exchangeRateId', exchangeRateId)
            // verify required parameter 'financialReportType' is not null or undefined
            assertParamExists('getExchangeRateDetail', 'financialReportType', financialReportType)
            // verify required parameter 'fromFiscalMonthId' is not null or undefined
            assertParamExists('getExchangeRateDetail', 'fromFiscalMonthId', fromFiscalMonthId)
            // verify required parameter 'toFiscalMonthId' is not null or undefined
            assertParamExists('getExchangeRateDetail', 'toFiscalMonthId', toFiscalMonthId)
            const localVarPath = `/api/exchange_rate/{exchangeRateId}`
                .replace(`{${"exchangeRateId"}}`, encodeURIComponent(String(exchangeRateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (financialReportType !== undefined) {
                localVarQueryParameter['financialReportType'] = financialReportType;
            }

            if (fromFiscalMonthId !== undefined) {
                localVarQueryParameter['fromFiscalMonthId'] = fromFiscalMonthId;
            }

            if (toFiscalMonthId !== undefined) {
                localVarQueryParameter['toFiscalMonthId'] = toFiscalMonthId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List exchange rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeRateList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/exchange_rate/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update exchange rate
         * @param {string} exchangeRateId 
         * @param {ExchangeRateRequestParam} exchangeRateRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchangeRate: async (exchangeRateId: string, exchangeRateRequestParam: ExchangeRateRequestParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRateId' is not null or undefined
            assertParamExists('updateExchangeRate', 'exchangeRateId', exchangeRateId)
            // verify required parameter 'exchangeRateRequestParam' is not null or undefined
            assertParamExists('updateExchangeRate', 'exchangeRateRequestParam', exchangeRateRequestParam)
            const localVarPath = `/api/exchange_rate/{exchangeRateId}`
                .replace(`{${"exchangeRateId"}}`, encodeURIComponent(String(exchangeRateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeRateRequestParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update exchange rate value.
         * @param {string} exchangeRateId 
         * @param {UpdateExchangeRateValueRequestParam} updateExchangeRateValueRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchangeRateValue: async (exchangeRateId: string, updateExchangeRateValueRequestParam: UpdateExchangeRateValueRequestParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRateId' is not null or undefined
            assertParamExists('updateExchangeRateValue', 'exchangeRateId', exchangeRateId)
            // verify required parameter 'updateExchangeRateValueRequestParam' is not null or undefined
            assertParamExists('updateExchangeRateValue', 'updateExchangeRateValueRequestParam', updateExchangeRateValueRequestParam)
            const localVarPath = `/api/exchange_rate/{exchangeRateId}/value`
                .replace(`{${"exchangeRateId"}}`, encodeURIComponent(String(exchangeRateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExchangeRateValueRequestParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeRateControllerApi - functional programming interface
 * @export
 */
export const ExchangeRateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeRateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create exchange rate
         * @param {ExchangeRateRequestParam} exchangeRateRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExchangeRate(exchangeRateRequestParam: ExchangeRateRequestParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeRateData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExchangeRate(exchangeRateRequestParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete exchange rate
         * @param {string} exchangeRateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExchangeRate(exchangeRateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExchangeRate(exchangeRateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GET exchange rate detail.
         * @param {string} exchangeRateId 
         * @param {string} financialReportType 
         * @param {string} fromFiscalMonthId 
         * @param {string} toFiscalMonthId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchangeRateDetail(exchangeRateId: string, financialReportType: string, fromFiscalMonthId: string, toFiscalMonthId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeRateDetailData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeRateDetail(exchangeRateId, financialReportType, fromFiscalMonthId, toFiscalMonthId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List exchange rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchangeRateList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExchangeRateData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeRateList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update exchange rate
         * @param {string} exchangeRateId 
         * @param {ExchangeRateRequestParam} exchangeRateRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExchangeRate(exchangeRateId: string, exchangeRateRequestParam: ExchangeRateRequestParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeRateData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExchangeRate(exchangeRateId, exchangeRateRequestParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update exchange rate value.
         * @param {string} exchangeRateId 
         * @param {UpdateExchangeRateValueRequestParam} updateExchangeRateValueRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExchangeRateValue(exchangeRateId: string, updateExchangeRateValueRequestParam: UpdateExchangeRateValueRequestParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExchangeRateValue(exchangeRateId, updateExchangeRateValueRequestParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExchangeRateControllerApi - factory interface
 * @export
 */
export const ExchangeRateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeRateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create exchange rate
         * @param {ExchangeRateRequestParam} exchangeRateRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchangeRate(exchangeRateRequestParam: ExchangeRateRequestParam, options?: any): AxiosPromise<ExchangeRateData> {
            return localVarFp.createExchangeRate(exchangeRateRequestParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete exchange rate
         * @param {string} exchangeRateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExchangeRate(exchangeRateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteExchangeRate(exchangeRateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GET exchange rate detail.
         * @param {string} exchangeRateId 
         * @param {string} financialReportType 
         * @param {string} fromFiscalMonthId 
         * @param {string} toFiscalMonthId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeRateDetail(exchangeRateId: string, financialReportType: string, fromFiscalMonthId: string, toFiscalMonthId: string, options?: any): AxiosPromise<ExchangeRateDetailData> {
            return localVarFp.getExchangeRateDetail(exchangeRateId, financialReportType, fromFiscalMonthId, toFiscalMonthId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List exchange rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeRateList(options?: any): AxiosPromise<Array<ExchangeRateData>> {
            return localVarFp.getExchangeRateList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update exchange rate
         * @param {string} exchangeRateId 
         * @param {ExchangeRateRequestParam} exchangeRateRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchangeRate(exchangeRateId: string, exchangeRateRequestParam: ExchangeRateRequestParam, options?: any): AxiosPromise<ExchangeRateData> {
            return localVarFp.updateExchangeRate(exchangeRateId, exchangeRateRequestParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update exchange rate value.
         * @param {string} exchangeRateId 
         * @param {UpdateExchangeRateValueRequestParam} updateExchangeRateValueRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchangeRateValue(exchangeRateId: string, updateExchangeRateValueRequestParam: UpdateExchangeRateValueRequestParam, options?: any): AxiosPromise<void> {
            return localVarFp.updateExchangeRateValue(exchangeRateId, updateExchangeRateValueRequestParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangeRateControllerApi - object-oriented interface
 * @export
 * @class ExchangeRateControllerApi
 * @extends {BaseAPI}
 */
export class ExchangeRateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create exchange rate
     * @param {ExchangeRateRequestParam} exchangeRateRequestParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateControllerApi
     */
    public createExchangeRate(exchangeRateRequestParam: ExchangeRateRequestParam, options?: AxiosRequestConfig) {
        return ExchangeRateControllerApiFp(this.configuration).createExchangeRate(exchangeRateRequestParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete exchange rate
     * @param {string} exchangeRateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateControllerApi
     */
    public deleteExchangeRate(exchangeRateId: string, options?: AxiosRequestConfig) {
        return ExchangeRateControllerApiFp(this.configuration).deleteExchangeRate(exchangeRateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GET exchange rate detail.
     * @param {string} exchangeRateId 
     * @param {string} financialReportType 
     * @param {string} fromFiscalMonthId 
     * @param {string} toFiscalMonthId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateControllerApi
     */
    public getExchangeRateDetail(exchangeRateId: string, financialReportType: string, fromFiscalMonthId: string, toFiscalMonthId: string, options?: AxiosRequestConfig) {
        return ExchangeRateControllerApiFp(this.configuration).getExchangeRateDetail(exchangeRateId, financialReportType, fromFiscalMonthId, toFiscalMonthId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List exchange rate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateControllerApi
     */
    public getExchangeRateList(options?: AxiosRequestConfig) {
        return ExchangeRateControllerApiFp(this.configuration).getExchangeRateList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update exchange rate
     * @param {string} exchangeRateId 
     * @param {ExchangeRateRequestParam} exchangeRateRequestParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateControllerApi
     */
    public updateExchangeRate(exchangeRateId: string, exchangeRateRequestParam: ExchangeRateRequestParam, options?: AxiosRequestConfig) {
        return ExchangeRateControllerApiFp(this.configuration).updateExchangeRate(exchangeRateId, exchangeRateRequestParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update exchange rate value.
     * @param {string} exchangeRateId 
     * @param {UpdateExchangeRateValueRequestParam} updateExchangeRateValueRequestParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateControllerApi
     */
    public updateExchangeRateValue(exchangeRateId: string, updateExchangeRateValueRequestParam: UpdateExchangeRateValueRequestParam, options?: AxiosRequestConfig) {
        return ExchangeRateControllerApiFp(this.configuration).updateExchangeRateValue(exchangeRateId, updateExchangeRateValueRequestParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FetchAiPlanActualAnalysisDataControllerApi - axios parameter creator
 * @export
 */
export const FetchAiPlanActualAnalysisDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} accountTreeId 
         * @param {string} organizationTreeId 
         * @param {string} planMasterId 
         * @param {boolean} requireAllocatedItems 
         * @param {string} targetYearMonth 
         * @param {string} accountId 
         * @param {string} departmentId 
         * @param {'DEPARTMENT' | 'ACCOUNT'} factorAnalysisType1 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} showNumbersIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAiPlanActualAnalysisData: async (accountTreeId: string, organizationTreeId: string, planMasterId: string, requireAllocatedItems: boolean, targetYearMonth: string, accountId: string, departmentId: string, factorAnalysisType1: 'DEPARTMENT' | 'ACCOUNT', showNumbersIn: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountTreeId' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisData', 'accountTreeId', accountTreeId)
            // verify required parameter 'organizationTreeId' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisData', 'organizationTreeId', organizationTreeId)
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisData', 'planMasterId', planMasterId)
            // verify required parameter 'requireAllocatedItems' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisData', 'requireAllocatedItems', requireAllocatedItems)
            // verify required parameter 'targetYearMonth' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisData', 'targetYearMonth', targetYearMonth)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisData', 'accountId', accountId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisData', 'departmentId', departmentId)
            // verify required parameter 'factorAnalysisType1' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisData', 'factorAnalysisType1', factorAnalysisType1)
            // verify required parameter 'showNumbersIn' is not null or undefined
            assertParamExists('fetchAiPlanActualAnalysisData', 'showNumbersIn', showNumbersIn)
            const localVarPath = `/api/ai-plan-actual-analysis-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountTreeId !== undefined) {
                localVarQueryParameter['accountTreeId'] = accountTreeId;
            }

            if (organizationTreeId !== undefined) {
                localVarQueryParameter['organizationTreeId'] = organizationTreeId;
            }

            if (planMasterId !== undefined) {
                localVarQueryParameter['planMasterId'] = planMasterId;
            }

            if (requireAllocatedItems !== undefined) {
                localVarQueryParameter['requireAllocatedItems'] = requireAllocatedItems;
            }

            if (targetYearMonth !== undefined) {
                localVarQueryParameter['targetYearMonth'] = targetYearMonth;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (factorAnalysisType1 !== undefined) {
                localVarQueryParameter['factorAnalysisType1'] = factorAnalysisType1;
            }

            if (showNumbersIn !== undefined) {
                localVarQueryParameter['showNumbersIn'] = showNumbersIn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FetchAiPlanActualAnalysisDataControllerApi - functional programming interface
 * @export
 */
export const FetchAiPlanActualAnalysisDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FetchAiPlanActualAnalysisDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} accountTreeId 
         * @param {string} organizationTreeId 
         * @param {string} planMasterId 
         * @param {boolean} requireAllocatedItems 
         * @param {string} targetYearMonth 
         * @param {string} accountId 
         * @param {string} departmentId 
         * @param {'DEPARTMENT' | 'ACCOUNT'} factorAnalysisType1 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} showNumbersIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAiPlanActualAnalysisData(accountTreeId: string, organizationTreeId: string, planMasterId: string, requireAllocatedItems: boolean, targetYearMonth: string, accountId: string, departmentId: string, factorAnalysisType1: 'DEPARTMENT' | 'ACCOUNT', showNumbersIn: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiPlanActualAnalysisView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAiPlanActualAnalysisData(accountTreeId, organizationTreeId, planMasterId, requireAllocatedItems, targetYearMonth, accountId, departmentId, factorAnalysisType1, showNumbersIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FetchAiPlanActualAnalysisDataControllerApi - factory interface
 * @export
 */
export const FetchAiPlanActualAnalysisDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FetchAiPlanActualAnalysisDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} accountTreeId 
         * @param {string} organizationTreeId 
         * @param {string} planMasterId 
         * @param {boolean} requireAllocatedItems 
         * @param {string} targetYearMonth 
         * @param {string} accountId 
         * @param {string} departmentId 
         * @param {'DEPARTMENT' | 'ACCOUNT'} factorAnalysisType1 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} showNumbersIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAiPlanActualAnalysisData(accountTreeId: string, organizationTreeId: string, planMasterId: string, requireAllocatedItems: boolean, targetYearMonth: string, accountId: string, departmentId: string, factorAnalysisType1: 'DEPARTMENT' | 'ACCOUNT', showNumbersIn: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options?: any): AxiosPromise<AiPlanActualAnalysisView> {
            return localVarFp.fetchAiPlanActualAnalysisData(accountTreeId, organizationTreeId, planMasterId, requireAllocatedItems, targetYearMonth, accountId, departmentId, factorAnalysisType1, showNumbersIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FetchAiPlanActualAnalysisDataControllerApi - object-oriented interface
 * @export
 * @class FetchAiPlanActualAnalysisDataControllerApi
 * @extends {BaseAPI}
 */
export class FetchAiPlanActualAnalysisDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} accountTreeId 
     * @param {string} organizationTreeId 
     * @param {string} planMasterId 
     * @param {boolean} requireAllocatedItems 
     * @param {string} targetYearMonth 
     * @param {string} accountId 
     * @param {string} departmentId 
     * @param {'DEPARTMENT' | 'ACCOUNT'} factorAnalysisType1 
     * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} showNumbersIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FetchAiPlanActualAnalysisDataControllerApi
     */
    public fetchAiPlanActualAnalysisData(accountTreeId: string, organizationTreeId: string, planMasterId: string, requireAllocatedItems: boolean, targetYearMonth: string, accountId: string, departmentId: string, factorAnalysisType1: 'DEPARTMENT' | 'ACCOUNT', showNumbersIn: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options?: AxiosRequestConfig) {
        return FetchAiPlanActualAnalysisDataControllerApiFp(this.configuration).fetchAiPlanActualAnalysisData(accountTreeId, organizationTreeId, planMasterId, requireAllocatedItems, targetYearMonth, accountId, departmentId, factorAnalysisType1, showNumbersIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FinancialModelControllerApi - axios parameter creator
 * @export
 */
export const FinancialModelControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 財務モデル詳細取得
         * @param {string} financialModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFinancialModelDetail: async (financialModelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialModelId' is not null or undefined
            assertParamExists('fetchFinancialModelDetail', 'financialModelId', financialModelId)
            const localVarPath = `/api/financial-model/{financialModelId}`
                .replace(`{${"financialModelId"}}`, encodeURIComponent(String(financialModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 財務モデル更新
         * @param {string} financialModelId 
         * @param {string} simulationId 
         * @param {UpdateFinancialModelDetailRequest} updateFinancialModelDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinancialModel: async (financialModelId: string, simulationId: string, updateFinancialModelDetailRequest: UpdateFinancialModelDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialModelId' is not null or undefined
            assertParamExists('updateFinancialModel', 'financialModelId', financialModelId)
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('updateFinancialModel', 'simulationId', simulationId)
            // verify required parameter 'updateFinancialModelDetailRequest' is not null or undefined
            assertParamExists('updateFinancialModel', 'updateFinancialModelDetailRequest', updateFinancialModelDetailRequest)
            const localVarPath = `/api/financial-model/{financialModelId}`
                .replace(`{${"financialModelId"}}`, encodeURIComponent(String(financialModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (simulationId !== undefined) {
                localVarQueryParameter['simulationId'] = simulationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFinancialModelDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialModelControllerApi - functional programming interface
 * @export
 */
export const FinancialModelControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialModelControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 財務モデル詳細取得
         * @param {string} financialModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchFinancialModelDetail(financialModelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialModelDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchFinancialModelDetail(financialModelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 財務モデル更新
         * @param {string} financialModelId 
         * @param {string} simulationId 
         * @param {UpdateFinancialModelDetailRequest} updateFinancialModelDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFinancialModel(financialModelId: string, simulationId: string, updateFinancialModelDetailRequest: UpdateFinancialModelDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFinancialModel(financialModelId, simulationId, updateFinancialModelDetailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinancialModelControllerApi - factory interface
 * @export
 */
export const FinancialModelControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialModelControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 財務モデル詳細取得
         * @param {string} financialModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFinancialModelDetail(financialModelId: string, options?: any): AxiosPromise<FinancialModelDetailView> {
            return localVarFp.fetchFinancialModelDetail(financialModelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 財務モデル更新
         * @param {string} financialModelId 
         * @param {string} simulationId 
         * @param {UpdateFinancialModelDetailRequest} updateFinancialModelDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinancialModel(financialModelId: string, simulationId: string, updateFinancialModelDetailRequest: UpdateFinancialModelDetailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateFinancialModel(financialModelId, simulationId, updateFinancialModelDetailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinancialModelControllerApi - object-oriented interface
 * @export
 * @class FinancialModelControllerApi
 * @extends {BaseAPI}
 */
export class FinancialModelControllerApi extends BaseAPI {
    /**
     * 
     * @summary 財務モデル詳細取得
     * @param {string} financialModelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialModelControllerApi
     */
    public fetchFinancialModelDetail(financialModelId: string, options?: AxiosRequestConfig) {
        return FinancialModelControllerApiFp(this.configuration).fetchFinancialModelDetail(financialModelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 財務モデル更新
     * @param {string} financialModelId 
     * @param {string} simulationId 
     * @param {UpdateFinancialModelDetailRequest} updateFinancialModelDetailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialModelControllerApi
     */
    public updateFinancialModel(financialModelId: string, simulationId: string, updateFinancialModelDetailRequest: UpdateFinancialModelDetailRequest, options?: AxiosRequestConfig) {
        return FinancialModelControllerApiFp(this.configuration).updateFinancialModel(financialModelId, simulationId, updateFinancialModelDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FinancialSheetCommentControllerApi - axios parameter creator
 * @export
 */
export const FinancialSheetCommentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 財務シートコメントの作成
         * @param {CreateFinancialSheetCommentRequestBody} createFinancialSheetCommentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFinancialSheetComment: async (createFinancialSheetCommentRequestBody: CreateFinancialSheetCommentRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFinancialSheetCommentRequestBody' is not null or undefined
            assertParamExists('createFinancialSheetComment', 'createFinancialSheetCommentRequestBody', createFinancialSheetCommentRequestBody)
            const localVarPath = `/api/financial-sheet-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFinancialSheetCommentRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 財務シートコメント削除
         * @param {string} financialSheetCommentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFinancialSheetComment: async (financialSheetCommentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialSheetCommentId' is not null or undefined
            assertParamExists('deleteFinancialSheetComment', 'financialSheetCommentId', financialSheetCommentId)
            const localVarPath = `/api/financial-sheet-comments/{financialSheetCommentId}`
                .replace(`{${"financialSheetCommentId"}}`, encodeURIComponent(String(financialSheetCommentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary セルごとの財務シートのコメント一覧(AMOUNT)
         * @param {ListAmountTypeCommentRequestBody} listAmountTypeCommentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAmountTypeCommentsByCell: async (listAmountTypeCommentRequestBody: ListAmountTypeCommentRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listAmountTypeCommentRequestBody' is not null or undefined
            assertParamExists('listAmountTypeCommentsByCell', 'listAmountTypeCommentRequestBody', listAmountTypeCommentRequestBody)
            const localVarPath = `/api/financial-sheet-comments/amount-type-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listAmountTypeCommentRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ページ単位の財務シートのコメント一覧
         * @param {ListCommentsRequestBody} listCommentsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComments: async (listCommentsRequestBody: ListCommentsRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listCommentsRequestBody' is not null or undefined
            assertParamExists('listComments', 'listCommentsRequestBody', listCommentsRequestBody)
            const localVarPath = `/api/financial-sheet-comments/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listCommentsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary セルごとの財務シートのコメント一覧(DIFF_RATIO, DIFF_AMOUNT)
         * @param {ListDiffTypeCommentRequestBody} listDiffTypeCommentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDiffTypeCommentsByCell: async (listDiffTypeCommentRequestBody: ListDiffTypeCommentRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listDiffTypeCommentRequestBody' is not null or undefined
            assertParamExists('listDiffTypeCommentsByCell', 'listDiffTypeCommentRequestBody', listDiffTypeCommentRequestBody)
            const localVarPath = `/api/financial-sheet-comments/diff-type-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listDiffTypeCommentRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialSheetCommentControllerApi - functional programming interface
 * @export
 */
export const FinancialSheetCommentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialSheetCommentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 財務シートコメントの作成
         * @param {CreateFinancialSheetCommentRequestBody} createFinancialSheetCommentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFinancialSheetComment(createFinancialSheetCommentRequestBody: CreateFinancialSheetCommentRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFinancialSheetCommentView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFinancialSheetComment(createFinancialSheetCommentRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 財務シートコメント削除
         * @param {string} financialSheetCommentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFinancialSheetComment(financialSheetCommentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFinancialSheetComment(financialSheetCommentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary セルごとの財務シートのコメント一覧(AMOUNT)
         * @param {ListAmountTypeCommentRequestBody} listAmountTypeCommentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAmountTypeCommentsByCell(listAmountTypeCommentRequestBody: ListAmountTypeCommentRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialSheetCommentsByCellView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAmountTypeCommentsByCell(listAmountTypeCommentRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ページ単位の財務シートのコメント一覧
         * @param {ListCommentsRequestBody} listCommentsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComments(listCommentsRequestBody: ListCommentsRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FinancialSheetCommentListItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComments(listCommentsRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary セルごとの財務シートのコメント一覧(DIFF_RATIO, DIFF_AMOUNT)
         * @param {ListDiffTypeCommentRequestBody} listDiffTypeCommentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDiffTypeCommentsByCell(listDiffTypeCommentRequestBody: ListDiffTypeCommentRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialSheetCommentsByCellView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDiffTypeCommentsByCell(listDiffTypeCommentRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinancialSheetCommentControllerApi - factory interface
 * @export
 */
export const FinancialSheetCommentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialSheetCommentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 財務シートコメントの作成
         * @param {CreateFinancialSheetCommentRequestBody} createFinancialSheetCommentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFinancialSheetComment(createFinancialSheetCommentRequestBody: CreateFinancialSheetCommentRequestBody, options?: any): AxiosPromise<CreateFinancialSheetCommentView> {
            return localVarFp.createFinancialSheetComment(createFinancialSheetCommentRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 財務シートコメント削除
         * @param {string} financialSheetCommentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFinancialSheetComment(financialSheetCommentId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteFinancialSheetComment(financialSheetCommentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary セルごとの財務シートのコメント一覧(AMOUNT)
         * @param {ListAmountTypeCommentRequestBody} listAmountTypeCommentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAmountTypeCommentsByCell(listAmountTypeCommentRequestBody: ListAmountTypeCommentRequestBody, options?: any): AxiosPromise<FinancialSheetCommentsByCellView> {
            return localVarFp.listAmountTypeCommentsByCell(listAmountTypeCommentRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ページ単位の財務シートのコメント一覧
         * @param {ListCommentsRequestBody} listCommentsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComments(listCommentsRequestBody: ListCommentsRequestBody, options?: any): AxiosPromise<Array<FinancialSheetCommentListItemDto>> {
            return localVarFp.listComments(listCommentsRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary セルごとの財務シートのコメント一覧(DIFF_RATIO, DIFF_AMOUNT)
         * @param {ListDiffTypeCommentRequestBody} listDiffTypeCommentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDiffTypeCommentsByCell(listDiffTypeCommentRequestBody: ListDiffTypeCommentRequestBody, options?: any): AxiosPromise<FinancialSheetCommentsByCellView> {
            return localVarFp.listDiffTypeCommentsByCell(listDiffTypeCommentRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinancialSheetCommentControllerApi - object-oriented interface
 * @export
 * @class FinancialSheetCommentControllerApi
 * @extends {BaseAPI}
 */
export class FinancialSheetCommentControllerApi extends BaseAPI {
    /**
     * 
     * @summary 財務シートコメントの作成
     * @param {CreateFinancialSheetCommentRequestBody} createFinancialSheetCommentRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSheetCommentControllerApi
     */
    public createFinancialSheetComment(createFinancialSheetCommentRequestBody: CreateFinancialSheetCommentRequestBody, options?: AxiosRequestConfig) {
        return FinancialSheetCommentControllerApiFp(this.configuration).createFinancialSheetComment(createFinancialSheetCommentRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 財務シートコメント削除
     * @param {string} financialSheetCommentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSheetCommentControllerApi
     */
    public deleteFinancialSheetComment(financialSheetCommentId: string, options?: AxiosRequestConfig) {
        return FinancialSheetCommentControllerApiFp(this.configuration).deleteFinancialSheetComment(financialSheetCommentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary セルごとの財務シートのコメント一覧(AMOUNT)
     * @param {ListAmountTypeCommentRequestBody} listAmountTypeCommentRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSheetCommentControllerApi
     */
    public listAmountTypeCommentsByCell(listAmountTypeCommentRequestBody: ListAmountTypeCommentRequestBody, options?: AxiosRequestConfig) {
        return FinancialSheetCommentControllerApiFp(this.configuration).listAmountTypeCommentsByCell(listAmountTypeCommentRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ページ単位の財務シートのコメント一覧
     * @param {ListCommentsRequestBody} listCommentsRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSheetCommentControllerApi
     */
    public listComments(listCommentsRequestBody: ListCommentsRequestBody, options?: AxiosRequestConfig) {
        return FinancialSheetCommentControllerApiFp(this.configuration).listComments(listCommentsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary セルごとの財務シートのコメント一覧(DIFF_RATIO, DIFF_AMOUNT)
     * @param {ListDiffTypeCommentRequestBody} listDiffTypeCommentRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSheetCommentControllerApi
     */
    public listDiffTypeCommentsByCell(listDiffTypeCommentRequestBody: ListDiffTypeCommentRequestBody, options?: AxiosRequestConfig) {
        return FinancialSheetCommentControllerApiFp(this.configuration).listDiffTypeCommentsByCell(listDiffTypeCommentRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FinancialSimulationControllerApi - axios parameter creator
 * @export
 */
export const FinancialSimulationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 財務シミュレーション作成
         * @param {CreateFinancialSimulationRequestBody} createFinancialSimulationRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFinancialSimulation: async (createFinancialSimulationRequestBody: CreateFinancialSimulationRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFinancialSimulationRequestBody' is not null or undefined
            assertParamExists('createFinancialSimulation', 'createFinancialSimulationRequestBody', createFinancialSimulationRequestBody)
            const localVarPath = `/api/financial-simulation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFinancialSimulationRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFinancialSimulation: async (simulationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('deleteFinancialSimulation', 'simulationId', simulationId)
            const localVarPath = `/api/financial-simulation/{simulationId}`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 財務シミュレーション詳細取得
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFinancialSimulation: async (simulationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('fetchFinancialSimulation', 'simulationId', simulationId)
            const localVarPath = `/api/financial-simulation/{simulationId}`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 財務シミュレーション一覧取得
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFinancialSimulations: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/financial-simulation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialSimulationControllerApi - functional programming interface
 * @export
 */
export const FinancialSimulationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialSimulationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 財務シミュレーション作成
         * @param {CreateFinancialSimulationRequestBody} createFinancialSimulationRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFinancialSimulation(createFinancialSimulationRequestBody: CreateFinancialSimulationRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedFinancialSimulationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFinancialSimulation(createFinancialSimulationRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFinancialSimulation(simulationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFinancialSimulation(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 財務シミュレーション詳細取得
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchFinancialSimulation(simulationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialSimulationDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchFinancialSimulation(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 財務シミュレーション一覧取得
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFinancialSimulations(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoFinancialSimulationListItemView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFinancialSimulations(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinancialSimulationControllerApi - factory interface
 * @export
 */
export const FinancialSimulationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialSimulationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 財務シミュレーション作成
         * @param {CreateFinancialSimulationRequestBody} createFinancialSimulationRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFinancialSimulation(createFinancialSimulationRequestBody: CreateFinancialSimulationRequestBody, options?: any): AxiosPromise<CreatedFinancialSimulationDto> {
            return localVarFp.createFinancialSimulation(createFinancialSimulationRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFinancialSimulation(simulationId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteFinancialSimulation(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 財務シミュレーション詳細取得
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFinancialSimulation(simulationId: string, options?: any): AxiosPromise<FinancialSimulationDetailView> {
            return localVarFp.fetchFinancialSimulation(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 財務シミュレーション一覧取得
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFinancialSimulations(limit?: number, offset?: number, options?: any): AxiosPromise<PageDtoFinancialSimulationListItemView> {
            return localVarFp.listFinancialSimulations(limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinancialSimulationControllerApi - object-oriented interface
 * @export
 * @class FinancialSimulationControllerApi
 * @extends {BaseAPI}
 */
export class FinancialSimulationControllerApi extends BaseAPI {
    /**
     * 
     * @summary 財務シミュレーション作成
     * @param {CreateFinancialSimulationRequestBody} createFinancialSimulationRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSimulationControllerApi
     */
    public createFinancialSimulation(createFinancialSimulationRequestBody: CreateFinancialSimulationRequestBody, options?: AxiosRequestConfig) {
        return FinancialSimulationControllerApiFp(this.configuration).createFinancialSimulation(createFinancialSimulationRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSimulationControllerApi
     */
    public deleteFinancialSimulation(simulationId: string, options?: AxiosRequestConfig) {
        return FinancialSimulationControllerApiFp(this.configuration).deleteFinancialSimulation(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 財務シミュレーション詳細取得
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSimulationControllerApi
     */
    public fetchFinancialSimulation(simulationId: string, options?: AxiosRequestConfig) {
        return FinancialSimulationControllerApiFp(this.configuration).fetchFinancialSimulation(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 財務シミュレーション一覧取得
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSimulationControllerApi
     */
    public listFinancialSimulations(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return FinancialSimulationControllerApiFp(this.configuration).listFinancialSimulations(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FinancialSimulationScenarioTableControllerApi - axios parameter creator
 * @export
 */
export const FinancialSimulationScenarioTableControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary シナリオを計画として保存
         * @param {string} scenarioId 
         * @param {ConvertScenarioTableToPlanRequestBody} convertScenarioTableToPlanRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertScenarioTableToPlan: async (scenarioId: string, convertScenarioTableToPlanRequestBody: ConvertScenarioTableToPlanRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scenarioId' is not null or undefined
            assertParamExists('convertScenarioTableToPlan', 'scenarioId', scenarioId)
            // verify required parameter 'convertScenarioTableToPlanRequestBody' is not null or undefined
            assertParamExists('convertScenarioTableToPlan', 'convertScenarioTableToPlanRequestBody', convertScenarioTableToPlanRequestBody)
            const localVarPath = `/api/financial-simulation-scenarios/{scenarioId}/convert`
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convertScenarioTableToPlanRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary シナリオについてシミュレーションを行い、シナリオを取得
         * @param {string} scenarioId 
         * @param {FetchFinancialSimulationScenarioRequestBody} fetchFinancialSimulationScenarioRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchScenarioTable: async (scenarioId: string, fetchFinancialSimulationScenarioRequestBody: FetchFinancialSimulationScenarioRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scenarioId' is not null or undefined
            assertParamExists('fetchScenarioTable', 'scenarioId', scenarioId)
            // verify required parameter 'fetchFinancialSimulationScenarioRequestBody' is not null or undefined
            assertParamExists('fetchScenarioTable', 'fetchFinancialSimulationScenarioRequestBody', fetchFinancialSimulationScenarioRequestBody)
            const localVarPath = `/api/financial-simulation-scenarios/{scenarioId}`
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchFinancialSimulationScenarioRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary シナリオ保存
         * @param {string} scenarioId 
         * @param {SaveFinancialSimulationScenarioRequestBody} saveFinancialSimulationScenarioRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveScenarioTable: async (scenarioId: string, saveFinancialSimulationScenarioRequestBody: SaveFinancialSimulationScenarioRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scenarioId' is not null or undefined
            assertParamExists('saveScenarioTable', 'scenarioId', scenarioId)
            // verify required parameter 'saveFinancialSimulationScenarioRequestBody' is not null or undefined
            assertParamExists('saveScenarioTable', 'saveFinancialSimulationScenarioRequestBody', saveFinancialSimulationScenarioRequestBody)
            const localVarPath = `/api/financial-simulation-scenarios/{scenarioId}/save`
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveFinancialSimulationScenarioRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialSimulationScenarioTableControllerApi - functional programming interface
 * @export
 */
export const FinancialSimulationScenarioTableControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialSimulationScenarioTableControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary シナリオを計画として保存
         * @param {string} scenarioId 
         * @param {ConvertScenarioTableToPlanRequestBody} convertScenarioTableToPlanRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertScenarioTableToPlan(scenarioId: string, convertScenarioTableToPlanRequestBody: ConvertScenarioTableToPlanRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertScenarioTableToPlan(scenarioId, convertScenarioTableToPlanRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary シナリオについてシミュレーションを行い、シナリオを取得
         * @param {string} scenarioId 
         * @param {FetchFinancialSimulationScenarioRequestBody} fetchFinancialSimulationScenarioRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchScenarioTable(scenarioId: string, fetchFinancialSimulationScenarioRequestBody: FetchFinancialSimulationScenarioRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialSimulationScenarioTableView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchScenarioTable(scenarioId, fetchFinancialSimulationScenarioRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary シナリオ保存
         * @param {string} scenarioId 
         * @param {SaveFinancialSimulationScenarioRequestBody} saveFinancialSimulationScenarioRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveScenarioTable(scenarioId: string, saveFinancialSimulationScenarioRequestBody: SaveFinancialSimulationScenarioRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveScenarioTable(scenarioId, saveFinancialSimulationScenarioRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinancialSimulationScenarioTableControllerApi - factory interface
 * @export
 */
export const FinancialSimulationScenarioTableControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialSimulationScenarioTableControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary シナリオを計画として保存
         * @param {string} scenarioId 
         * @param {ConvertScenarioTableToPlanRequestBody} convertScenarioTableToPlanRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertScenarioTableToPlan(scenarioId: string, convertScenarioTableToPlanRequestBody: ConvertScenarioTableToPlanRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.convertScenarioTableToPlan(scenarioId, convertScenarioTableToPlanRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary シナリオについてシミュレーションを行い、シナリオを取得
         * @param {string} scenarioId 
         * @param {FetchFinancialSimulationScenarioRequestBody} fetchFinancialSimulationScenarioRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchScenarioTable(scenarioId: string, fetchFinancialSimulationScenarioRequestBody: FetchFinancialSimulationScenarioRequestBody, options?: any): AxiosPromise<FinancialSimulationScenarioTableView> {
            return localVarFp.fetchScenarioTable(scenarioId, fetchFinancialSimulationScenarioRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary シナリオ保存
         * @param {string} scenarioId 
         * @param {SaveFinancialSimulationScenarioRequestBody} saveFinancialSimulationScenarioRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveScenarioTable(scenarioId: string, saveFinancialSimulationScenarioRequestBody: SaveFinancialSimulationScenarioRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.saveScenarioTable(scenarioId, saveFinancialSimulationScenarioRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinancialSimulationScenarioTableControllerApi - object-oriented interface
 * @export
 * @class FinancialSimulationScenarioTableControllerApi
 * @extends {BaseAPI}
 */
export class FinancialSimulationScenarioTableControllerApi extends BaseAPI {
    /**
     * 
     * @summary シナリオを計画として保存
     * @param {string} scenarioId 
     * @param {ConvertScenarioTableToPlanRequestBody} convertScenarioTableToPlanRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSimulationScenarioTableControllerApi
     */
    public convertScenarioTableToPlan(scenarioId: string, convertScenarioTableToPlanRequestBody: ConvertScenarioTableToPlanRequestBody, options?: AxiosRequestConfig) {
        return FinancialSimulationScenarioTableControllerApiFp(this.configuration).convertScenarioTableToPlan(scenarioId, convertScenarioTableToPlanRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary シナリオについてシミュレーションを行い、シナリオを取得
     * @param {string} scenarioId 
     * @param {FetchFinancialSimulationScenarioRequestBody} fetchFinancialSimulationScenarioRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSimulationScenarioTableControllerApi
     */
    public fetchScenarioTable(scenarioId: string, fetchFinancialSimulationScenarioRequestBody: FetchFinancialSimulationScenarioRequestBody, options?: AxiosRequestConfig) {
        return FinancialSimulationScenarioTableControllerApiFp(this.configuration).fetchScenarioTable(scenarioId, fetchFinancialSimulationScenarioRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary シナリオ保存
     * @param {string} scenarioId 
     * @param {SaveFinancialSimulationScenarioRequestBody} saveFinancialSimulationScenarioRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialSimulationScenarioTableControllerApi
     */
    public saveScenarioTable(scenarioId: string, saveFinancialSimulationScenarioRequestBody: SaveFinancialSimulationScenarioRequestBody, options?: AxiosRequestConfig) {
        return FinancialSimulationScenarioTableControllerApiFp(this.configuration).saveScenarioTable(scenarioId, saveFinancialSimulationScenarioRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GSheetCustomFunctionControllerApi - axios parameter creator
 * @export
 */
export const GSheetCustomFunctionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary スプレッドシート独自関数用に明細の計画または実績の合算値を取得する
         * @param {string} fetchItemType 
         * @param {string} startYearMonth 
         * @param {MaybeOwnValueAccountCode} accountCode 
         * @param {MaybeOwnValueDepartmentCode} departmentCode 
         * @param {string} [sourceName] 
         * @param {string} [endYearMonth] 
         * @param {string} [accountTreeName] 
         * @param {string} [orgTreeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoglassPLValue: async (fetchItemType: string, startYearMonth: string, accountCode: MaybeOwnValueAccountCode, departmentCode: MaybeOwnValueDepartmentCode, sourceName?: string, endYearMonth?: string, accountTreeName?: string, orgTreeName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchItemType' is not null or undefined
            assertParamExists('getLoglassPLValue', 'fetchItemType', fetchItemType)
            // verify required parameter 'startYearMonth' is not null or undefined
            assertParamExists('getLoglassPLValue', 'startYearMonth', startYearMonth)
            // verify required parameter 'accountCode' is not null or undefined
            assertParamExists('getLoglassPLValue', 'accountCode', accountCode)
            // verify required parameter 'departmentCode' is not null or undefined
            assertParamExists('getLoglassPLValue', 'departmentCode', departmentCode)
            const localVarPath = `/api/v1/gsheets-custom-function/loglass-pl-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fetchItemType !== undefined) {
                localVarQueryParameter['fetchItemType'] = fetchItemType;
            }

            if (sourceName !== undefined) {
                localVarQueryParameter['sourceName'] = sourceName;
            }

            if (startYearMonth !== undefined) {
                localVarQueryParameter['startYearMonth'] = startYearMonth;
            }

            if (endYearMonth !== undefined) {
                localVarQueryParameter['endYearMonth'] = endYearMonth;
            }

            if (accountTreeName !== undefined) {
                localVarQueryParameter['accountTreeName'] = accountTreeName;
            }

            if (accountCode !== undefined) {
                localVarQueryParameter['accountCode'] = accountCode;
            }

            if (departmentCode !== undefined) {
                localVarQueryParameter['departmentCode'] = departmentCode;
            }

            if (orgTreeName !== undefined) {
                localVarQueryParameter['orgTreeName'] = orgTreeName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary スプレッドシート独自関数用に明細の計画または実績の合算値を一括で取得する
         * @param {GetLoglassPLValueBulkRequest} getLoglassPLValueBulkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoglassPLValueBulk: async (getLoglassPLValueBulkRequest: GetLoglassPLValueBulkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getLoglassPLValueBulkRequest' is not null or undefined
            assertParamExists('getLoglassPLValueBulk', 'getLoglassPLValueBulkRequest', getLoglassPLValueBulkRequest)
            const localVarPath = `/api/v1/gsheets-custom-function/loglass-pl-value-bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getLoglassPLValueBulkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GSheetCustomFunctionControllerApi - functional programming interface
 * @export
 */
export const GSheetCustomFunctionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GSheetCustomFunctionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary スプレッドシート独自関数用に明細の計画または実績の合算値を取得する
         * @param {string} fetchItemType 
         * @param {string} startYearMonth 
         * @param {MaybeOwnValueAccountCode} accountCode 
         * @param {MaybeOwnValueDepartmentCode} departmentCode 
         * @param {string} [sourceName] 
         * @param {string} [endYearMonth] 
         * @param {string} [accountTreeName] 
         * @param {string} [orgTreeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoglassPLValue(fetchItemType: string, startYearMonth: string, accountCode: MaybeOwnValueAccountCode, departmentCode: MaybeOwnValueDepartmentCode, sourceName?: string, endYearMonth?: string, accountTreeName?: string, orgTreeName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoglassPLValueResponseView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoglassPLValue(fetchItemType, startYearMonth, accountCode, departmentCode, sourceName, endYearMonth, accountTreeName, orgTreeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary スプレッドシート独自関数用に明細の計画または実績の合算値を一括で取得する
         * @param {GetLoglassPLValueBulkRequest} getLoglassPLValueBulkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoglassPLValueBulk(getLoglassPLValueBulkRequest: GetLoglassPLValueBulkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoglassPLValueBulkResponseView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoglassPLValueBulk(getLoglassPLValueBulkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GSheetCustomFunctionControllerApi - factory interface
 * @export
 */
export const GSheetCustomFunctionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GSheetCustomFunctionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary スプレッドシート独自関数用に明細の計画または実績の合算値を取得する
         * @param {string} fetchItemType 
         * @param {string} startYearMonth 
         * @param {MaybeOwnValueAccountCode} accountCode 
         * @param {MaybeOwnValueDepartmentCode} departmentCode 
         * @param {string} [sourceName] 
         * @param {string} [endYearMonth] 
         * @param {string} [accountTreeName] 
         * @param {string} [orgTreeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoglassPLValue(fetchItemType: string, startYearMonth: string, accountCode: MaybeOwnValueAccountCode, departmentCode: MaybeOwnValueDepartmentCode, sourceName?: string, endYearMonth?: string, accountTreeName?: string, orgTreeName?: string, options?: any): AxiosPromise<GetLoglassPLValueResponseView> {
            return localVarFp.getLoglassPLValue(fetchItemType, startYearMonth, accountCode, departmentCode, sourceName, endYearMonth, accountTreeName, orgTreeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary スプレッドシート独自関数用に明細の計画または実績の合算値を一括で取得する
         * @param {GetLoglassPLValueBulkRequest} getLoglassPLValueBulkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoglassPLValueBulk(getLoglassPLValueBulkRequest: GetLoglassPLValueBulkRequest, options?: any): AxiosPromise<GetLoglassPLValueBulkResponseView> {
            return localVarFp.getLoglassPLValueBulk(getLoglassPLValueBulkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GSheetCustomFunctionControllerApi - object-oriented interface
 * @export
 * @class GSheetCustomFunctionControllerApi
 * @extends {BaseAPI}
 */
export class GSheetCustomFunctionControllerApi extends BaseAPI {
    /**
     * 
     * @summary スプレッドシート独自関数用に明細の計画または実績の合算値を取得する
     * @param {string} fetchItemType 
     * @param {string} startYearMonth 
     * @param {MaybeOwnValueAccountCode} accountCode 
     * @param {MaybeOwnValueDepartmentCode} departmentCode 
     * @param {string} [sourceName] 
     * @param {string} [endYearMonth] 
     * @param {string} [accountTreeName] 
     * @param {string} [orgTreeName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GSheetCustomFunctionControllerApi
     */
    public getLoglassPLValue(fetchItemType: string, startYearMonth: string, accountCode: MaybeOwnValueAccountCode, departmentCode: MaybeOwnValueDepartmentCode, sourceName?: string, endYearMonth?: string, accountTreeName?: string, orgTreeName?: string, options?: AxiosRequestConfig) {
        return GSheetCustomFunctionControllerApiFp(this.configuration).getLoglassPLValue(fetchItemType, startYearMonth, accountCode, departmentCode, sourceName, endYearMonth, accountTreeName, orgTreeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary スプレッドシート独自関数用に明細の計画または実績の合算値を一括で取得する
     * @param {GetLoglassPLValueBulkRequest} getLoglassPLValueBulkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GSheetCustomFunctionControllerApi
     */
    public getLoglassPLValueBulk(getLoglassPLValueBulkRequest: GetLoglassPLValueBulkRequest, options?: AxiosRequestConfig) {
        return GSheetCustomFunctionControllerApiFp(this.configuration).getLoglassPLValueBulk(getLoglassPLValueBulkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GSheetsControllerApi - axios parameter creator
 * @export
 */
export const GSheetsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 取込設定の一覧を取得する
         * @param {string} xEmail 
         * @param {string} [xLoglassTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMappings: async (xEmail: string, xLoglassTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xEmail' is not null or undefined
            assertParamExists('listMappings', 'xEmail', xEmail)
            const localVarPath = `/api/gsheets/mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xEmail != null) {
                localVarHeaderParameter['X-Email'] = String(xEmail);
            }

            if (xLoglassTenantId != null) {
                localVarHeaderParameter['X-Loglass-Tenant-Id'] = String(xLoglassTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 編成プロジェクトの一覧を取得する
         * @param {string} xEmail 
         * @param {string} [xLoglassTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanningProjects: async (xEmail: string, xLoglassTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xEmail' is not null or undefined
            assertParamExists('listPlanningProjects', 'xEmail', xEmail)
            const localVarPath = `/api/gsheets/planning-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xEmail != null) {
                localVarHeaderParameter['X-Email'] = String(xEmail);
            }

            if (xLoglassTenantId != null) {
                localVarHeaderParameter['X-Loglass-Tenant-Id'] = String(xLoglassTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請をアップロードする
         * @param {string} xEmail 
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {string} sheetUrl 
         * @param {UploadPlanUpdateRequestsRequest} uploadPlanUpdateRequestsRequest 
         * @param {string} [xLoglassTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPlanUpdateRequests: async (xEmail: string, planningProjectId: string, mappingId: string, sheetUrl: string, uploadPlanUpdateRequestsRequest: UploadPlanUpdateRequestsRequest, xLoglassTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xEmail' is not null or undefined
            assertParamExists('uploadPlanUpdateRequests', 'xEmail', xEmail)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('uploadPlanUpdateRequests', 'planningProjectId', planningProjectId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('uploadPlanUpdateRequests', 'mappingId', mappingId)
            // verify required parameter 'sheetUrl' is not null or undefined
            assertParamExists('uploadPlanUpdateRequests', 'sheetUrl', sheetUrl)
            // verify required parameter 'uploadPlanUpdateRequestsRequest' is not null or undefined
            assertParamExists('uploadPlanUpdateRequests', 'uploadPlanUpdateRequestsRequest', uploadPlanUpdateRequestsRequest)
            const localVarPath = `/api/gsheets/upload-plan-update-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planningProjectId !== undefined) {
                localVarQueryParameter['planningProjectId'] = planningProjectId;
            }

            if (mappingId !== undefined) {
                localVarQueryParameter['mappingId'] = mappingId;
            }

            if (sheetUrl !== undefined) {
                localVarQueryParameter['sheetUrl'] = sheetUrl;
            }

            if (xEmail != null) {
                localVarHeaderParameter['X-Email'] = String(xEmail);
            }

            if (xLoglassTenantId != null) {
                localVarHeaderParameter['X-Loglass-Tenant-Id'] = String(xLoglassTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadPlanUpdateRequestsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GSheetsControllerApi - functional programming interface
 * @export
 */
export const GSheetsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GSheetsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 取込設定の一覧を取得する
         * @param {string} xEmail 
         * @param {string} [xLoglassTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMappings(xEmail: string, xLoglassTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanUpdateRequestMappingView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMappings(xEmail, xLoglassTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 編成プロジェクトの一覧を取得する
         * @param {string} xEmail 
         * @param {string} [xLoglassTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlanningProjects(xEmail: string, xLoglassTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanningProjectListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlanningProjects(xEmail, xLoglassTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請をアップロードする
         * @param {string} xEmail 
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {string} sheetUrl 
         * @param {UploadPlanUpdateRequestsRequest} uploadPlanUpdateRequestsRequest 
         * @param {string} [xLoglassTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPlanUpdateRequests(xEmail: string, planningProjectId: string, mappingId: string, sheetUrl: string, uploadPlanUpdateRequestsRequest: UploadPlanUpdateRequestsRequest, xLoglassTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPlanUpdateRequests(xEmail, planningProjectId, mappingId, sheetUrl, uploadPlanUpdateRequestsRequest, xLoglassTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GSheetsControllerApi - factory interface
 * @export
 */
export const GSheetsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GSheetsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 取込設定の一覧を取得する
         * @param {string} xEmail 
         * @param {string} [xLoglassTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMappings(xEmail: string, xLoglassTenantId?: string, options?: any): AxiosPromise<Array<PlanUpdateRequestMappingView>> {
            return localVarFp.listMappings(xEmail, xLoglassTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 編成プロジェクトの一覧を取得する
         * @param {string} xEmail 
         * @param {string} [xLoglassTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanningProjects(xEmail: string, xLoglassTenantId?: string, options?: any): AxiosPromise<PlanningProjectListView> {
            return localVarFp.listPlanningProjects(xEmail, xLoglassTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請をアップロードする
         * @param {string} xEmail 
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {string} sheetUrl 
         * @param {UploadPlanUpdateRequestsRequest} uploadPlanUpdateRequestsRequest 
         * @param {string} [xLoglassTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPlanUpdateRequests(xEmail: string, planningProjectId: string, mappingId: string, sheetUrl: string, uploadPlanUpdateRequestsRequest: UploadPlanUpdateRequestsRequest, xLoglassTenantId?: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadPlanUpdateRequests(xEmail, planningProjectId, mappingId, sheetUrl, uploadPlanUpdateRequestsRequest, xLoglassTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GSheetsControllerApi - object-oriented interface
 * @export
 * @class GSheetsControllerApi
 * @extends {BaseAPI}
 */
export class GSheetsControllerApi extends BaseAPI {
    /**
     * 
     * @summary 取込設定の一覧を取得する
     * @param {string} xEmail 
     * @param {string} [xLoglassTenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GSheetsControllerApi
     */
    public listMappings(xEmail: string, xLoglassTenantId?: string, options?: AxiosRequestConfig) {
        return GSheetsControllerApiFp(this.configuration).listMappings(xEmail, xLoglassTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 編成プロジェクトの一覧を取得する
     * @param {string} xEmail 
     * @param {string} [xLoglassTenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GSheetsControllerApi
     */
    public listPlanningProjects(xEmail: string, xLoglassTenantId?: string, options?: AxiosRequestConfig) {
        return GSheetsControllerApiFp(this.configuration).listPlanningProjects(xEmail, xLoglassTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請をアップロードする
     * @param {string} xEmail 
     * @param {string} planningProjectId 
     * @param {string} mappingId 
     * @param {string} sheetUrl 
     * @param {UploadPlanUpdateRequestsRequest} uploadPlanUpdateRequestsRequest 
     * @param {string} [xLoglassTenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GSheetsControllerApi
     */
    public uploadPlanUpdateRequests(xEmail: string, planningProjectId: string, mappingId: string, sheetUrl: string, uploadPlanUpdateRequestsRequest: UploadPlanUpdateRequestsRequest, xLoglassTenantId?: string, options?: AxiosRequestConfig) {
        return GSheetsControllerApiFp(this.configuration).uploadPlanUpdateRequests(xEmail, planningProjectId, mappingId, sheetUrl, uploadPlanUpdateRequestsRequest, xLoglassTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthCheckControllerApi - axios parameter creator
 * @export
 */
export const HealthCheckControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckControllerApi - functional programming interface
 * @export
 */
export const HealthCheckControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCheckControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthCheckControllerApi - factory interface
 * @export
 */
export const HealthCheckControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCheckControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheck(options?: any): AxiosPromise<string> {
            return localVarFp.healthcheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCheckControllerApi - object-oriented interface
 * @export
 * @class HealthCheckControllerApi
 * @extends {BaseAPI}
 */
export class HealthCheckControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckControllerApi
     */
    public healthcheck(options?: AxiosRequestConfig) {
        return HealthCheckControllerApiFp(this.configuration).healthcheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LatestPlanningProjectControllerApi - axios parameter creator
 * @export
 */
export const LatestPlanningProjectControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 計画マスタに紐づく最新の編成プロジェクトの概要
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPlanningProject: async (planMasterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('getLatestPlanningProject', 'planMasterId', planMasterId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/latest-planningProjects`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LatestPlanningProjectControllerApi - functional programming interface
 * @export
 */
export const LatestPlanningProjectControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LatestPlanningProjectControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 計画マスタに紐づく最新の編成プロジェクトの概要
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestPlanningProject(planMasterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestPlanningProjectNodeMetaView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestPlanningProject(planMasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LatestPlanningProjectControllerApi - factory interface
 * @export
 */
export const LatestPlanningProjectControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LatestPlanningProjectControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 計画マスタに紐づく最新の編成プロジェクトの概要
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPlanningProject(planMasterId: string, options?: any): AxiosPromise<LatestPlanningProjectNodeMetaView> {
            return localVarFp.getLatestPlanningProject(planMasterId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LatestPlanningProjectControllerApi - object-oriented interface
 * @export
 * @class LatestPlanningProjectControllerApi
 * @extends {BaseAPI}
 */
export class LatestPlanningProjectControllerApi extends BaseAPI {
    /**
     * 
     * @summary 計画マスタに紐づく最新の編成プロジェクトの概要
     * @param {string} planMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatestPlanningProjectControllerApi
     */
    public getLatestPlanningProject(planMasterId: string, options?: AxiosRequestConfig) {
        return LatestPlanningProjectControllerApiFp(this.configuration).getLatestPlanningProject(planMasterId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LinkedGoogleSheetsControllerApi - axios parameter creator
 * @export
 */
export const LinkedGoogleSheetsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLinkedGSheetParam} createLinkedGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkedGSheet: async (createLinkedGSheetParam: CreateLinkedGSheetParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLinkedGSheetParam' is not null or undefined
            assertParamExists('createLinkedGSheet', 'createLinkedGSheetParam', createLinkedGSheetParam)
            const localVarPath = `/api/linked-gsheets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLinkedGSheetParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkedGSheet: async (sheetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sheetId' is not null or undefined
            assertParamExists('deleteLinkedGSheet', 'sheetId', sheetId)
            const localVarPath = `/api/linked-gsheets/{sheetId}`
                .replace(`{${"sheetId"}}`, encodeURIComponent(String(sheetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLinkedGSheets: async (departmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/linked-gsheets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkedGoogleSheetsControllerApi - functional programming interface
 * @export
 */
export const LinkedGoogleSheetsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkedGoogleSheetsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLinkedGSheetParam} createLinkedGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkedGSheet(createLinkedGSheetParam: CreateLinkedGSheetParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedGoogleSheetView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLinkedGSheet(createLinkedGSheetParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLinkedGSheet(sheetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLinkedGSheet(sheetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLinkedGSheets(departmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedGSheetsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLinkedGSheets(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LinkedGoogleSheetsControllerApi - factory interface
 * @export
 */
export const LinkedGoogleSheetsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkedGoogleSheetsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLinkedGSheetParam} createLinkedGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkedGSheet(createLinkedGSheetParam: CreateLinkedGSheetParam, options?: any): AxiosPromise<LinkedGoogleSheetView> {
            return localVarFp.createLinkedGSheet(createLinkedGSheetParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkedGSheet(sheetId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteLinkedGSheet(sheetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLinkedGSheets(departmentId?: string, options?: any): AxiosPromise<LinkedGSheetsView> {
            return localVarFp.listLinkedGSheets(departmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinkedGoogleSheetsControllerApi - object-oriented interface
 * @export
 * @class LinkedGoogleSheetsControllerApi
 * @extends {BaseAPI}
 */
export class LinkedGoogleSheetsControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateLinkedGSheetParam} createLinkedGSheetParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedGoogleSheetsControllerApi
     */
    public createLinkedGSheet(createLinkedGSheetParam: CreateLinkedGSheetParam, options?: AxiosRequestConfig) {
        return LinkedGoogleSheetsControllerApiFp(this.configuration).createLinkedGSheet(createLinkedGSheetParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sheetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedGoogleSheetsControllerApi
     */
    public deleteLinkedGSheet(sheetId: string, options?: AxiosRequestConfig) {
        return LinkedGoogleSheetsControllerApiFp(this.configuration).deleteLinkedGSheet(sheetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedGoogleSheetsControllerApi
     */
    public listLinkedGSheets(departmentId?: string, options?: AxiosRequestConfig) {
        return LinkedGoogleSheetsControllerApiFp(this.configuration).listLinkedGSheets(departmentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocalCurrencyControllerApi - axios parameter creator
 * @export
 */
export const LocalCurrencyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 新しい現地通貨を作成します。
         * @param {LocalCurrencyRequestParam} localCurrencyRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocalCurrency: async (localCurrencyRequestParam: LocalCurrencyRequestParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localCurrencyRequestParam' is not null or undefined
            assertParamExists('createLocalCurrency', 'localCurrencyRequestParam', localCurrencyRequestParam)
            const localVarPath = `/api/currency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localCurrencyRequestParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete local currency
         * @param {string} currencyLocalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocalCurrency: async (currencyLocalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currencyLocalId' is not null or undefined
            assertParamExists('deleteLocalCurrency', 'currencyLocalId', currencyLocalId)
            const localVarPath = `/api/currency/{currencyLocalId}`
                .replace(`{${"currencyLocalId"}}`, encodeURIComponent(String(currencyLocalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 現地通貨リストを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/currency/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 通貨マスターデータを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyMaster: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/currency/master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 現地通貨を削除します。
         * @param {ReorderLocalCurrencyRequestParam} reorderLocalCurrencyRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderLocalCurrencies: async (reorderLocalCurrencyRequestParam: ReorderLocalCurrencyRequestParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reorderLocalCurrencyRequestParam' is not null or undefined
            assertParamExists('reorderLocalCurrencies', 'reorderLocalCurrencyRequestParam', reorderLocalCurrencyRequestParam)
            const localVarPath = `/api/currency/reorder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderLocalCurrencyRequestParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 現地通貨を更新します。
         * @param {string} currencyLocalId 
         * @param {LocalCurrencyRequestParam} localCurrencyRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocalCurrency: async (currencyLocalId: string, localCurrencyRequestParam: LocalCurrencyRequestParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currencyLocalId' is not null or undefined
            assertParamExists('updateLocalCurrency', 'currencyLocalId', currencyLocalId)
            // verify required parameter 'localCurrencyRequestParam' is not null or undefined
            assertParamExists('updateLocalCurrency', 'localCurrencyRequestParam', localCurrencyRequestParam)
            const localVarPath = `/api/currency/{currencyLocalId}`
                .replace(`{${"currencyLocalId"}}`, encodeURIComponent(String(currencyLocalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localCurrencyRequestParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalCurrencyControllerApi - functional programming interface
 * @export
 */
export const LocalCurrencyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalCurrencyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 新しい現地通貨を作成します。
         * @param {LocalCurrencyRequestParam} localCurrencyRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocalCurrency(localCurrencyRequestParam: LocalCurrencyRequestParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalCurrencyData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocalCurrency(localCurrencyRequestParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete local currency
         * @param {string} currencyLocalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocalCurrency(currencyLocalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocalCurrency(currencyLocalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 現地通貨リストを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrencyList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocalCurrencyData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrencyList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 通貨マスターデータを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrencyMaster(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyMaster>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrencyMaster(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 現地通貨を削除します。
         * @param {ReorderLocalCurrencyRequestParam} reorderLocalCurrencyRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorderLocalCurrencies(reorderLocalCurrencyRequestParam: ReorderLocalCurrencyRequestParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorderLocalCurrencies(reorderLocalCurrencyRequestParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 現地通貨を更新します。
         * @param {string} currencyLocalId 
         * @param {LocalCurrencyRequestParam} localCurrencyRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocalCurrency(currencyLocalId: string, localCurrencyRequestParam: LocalCurrencyRequestParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalCurrencyData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocalCurrency(currencyLocalId, localCurrencyRequestParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocalCurrencyControllerApi - factory interface
 * @export
 */
export const LocalCurrencyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalCurrencyControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 新しい現地通貨を作成します。
         * @param {LocalCurrencyRequestParam} localCurrencyRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocalCurrency(localCurrencyRequestParam: LocalCurrencyRequestParam, options?: any): AxiosPromise<LocalCurrencyData> {
            return localVarFp.createLocalCurrency(localCurrencyRequestParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete local currency
         * @param {string} currencyLocalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocalCurrency(currencyLocalId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLocalCurrency(currencyLocalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 現地通貨リストを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyList(options?: any): AxiosPromise<Array<LocalCurrencyData>> {
            return localVarFp.getCurrencyList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 通貨マスターデータを取得します。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyMaster(options?: any): AxiosPromise<Array<CurrencyMaster>> {
            return localVarFp.getCurrencyMaster(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 現地通貨を削除します。
         * @param {ReorderLocalCurrencyRequestParam} reorderLocalCurrencyRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderLocalCurrencies(reorderLocalCurrencyRequestParam: ReorderLocalCurrencyRequestParam, options?: any): AxiosPromise<void> {
            return localVarFp.reorderLocalCurrencies(reorderLocalCurrencyRequestParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 現地通貨を更新します。
         * @param {string} currencyLocalId 
         * @param {LocalCurrencyRequestParam} localCurrencyRequestParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocalCurrency(currencyLocalId: string, localCurrencyRequestParam: LocalCurrencyRequestParam, options?: any): AxiosPromise<LocalCurrencyData> {
            return localVarFp.updateLocalCurrency(currencyLocalId, localCurrencyRequestParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalCurrencyControllerApi - object-oriented interface
 * @export
 * @class LocalCurrencyControllerApi
 * @extends {BaseAPI}
 */
export class LocalCurrencyControllerApi extends BaseAPI {
    /**
     * 
     * @summary 新しい現地通貨を作成します。
     * @param {LocalCurrencyRequestParam} localCurrencyRequestParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyControllerApi
     */
    public createLocalCurrency(localCurrencyRequestParam: LocalCurrencyRequestParam, options?: AxiosRequestConfig) {
        return LocalCurrencyControllerApiFp(this.configuration).createLocalCurrency(localCurrencyRequestParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete local currency
     * @param {string} currencyLocalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyControllerApi
     */
    public deleteLocalCurrency(currencyLocalId: string, options?: AxiosRequestConfig) {
        return LocalCurrencyControllerApiFp(this.configuration).deleteLocalCurrency(currencyLocalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 現地通貨リストを取得します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyControllerApi
     */
    public getCurrencyList(options?: AxiosRequestConfig) {
        return LocalCurrencyControllerApiFp(this.configuration).getCurrencyList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 通貨マスターデータを取得します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyControllerApi
     */
    public getCurrencyMaster(options?: AxiosRequestConfig) {
        return LocalCurrencyControllerApiFp(this.configuration).getCurrencyMaster(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 現地通貨を削除します。
     * @param {ReorderLocalCurrencyRequestParam} reorderLocalCurrencyRequestParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyControllerApi
     */
    public reorderLocalCurrencies(reorderLocalCurrencyRequestParam: ReorderLocalCurrencyRequestParam, options?: AxiosRequestConfig) {
        return LocalCurrencyControllerApiFp(this.configuration).reorderLocalCurrencies(reorderLocalCurrencyRequestParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 現地通貨を更新します。
     * @param {string} currencyLocalId 
     * @param {LocalCurrencyRequestParam} localCurrencyRequestParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyControllerApi
     */
    public updateLocalCurrency(currencyLocalId: string, localCurrencyRequestParam: LocalCurrencyRequestParam, options?: AxiosRequestConfig) {
        return LocalCurrencyControllerApiFp(this.configuration).updateLocalCurrency(currencyLocalId, localCurrencyRequestParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NonFinancialAccountTreesControllerApi - axios parameter creator
 * @export
 */
export const NonFinancialAccountTreesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 未設定科目・グループを非財務科目階層に追加する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {AddNodeToNonFinancialAccountTreeBody} addNodeToNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNodeToNonFinancialAccountTree: async (nonFinancialAccountTreeId: string, addNodeToNonFinancialAccountTreeBody: AddNodeToNonFinancialAccountTreeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('addNodeToNonFinancialAccountTree', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'addNodeToNonFinancialAccountTreeBody' is not null or undefined
            assertParamExists('addNodeToNonFinancialAccountTree', 'addNodeToNonFinancialAccountTreeBody', addNodeToNonFinancialAccountTreeBody)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/add-node`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addNodeToNonFinancialAccountTreeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目をアーカイブする API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} targetAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNonFinancialAccount: async (nonFinancialAccountTreeId: string, targetAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('archiveNonFinancialAccount', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'targetAccountId' is not null or undefined
            assertParamExists('archiveNonFinancialAccount', 'targetAccountId', targetAccountId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/archive-account`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetAccountId !== undefined) {
                localVarQueryParameter['targetAccountId'] = targetAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目階層内にグループを作成する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {CreateGroupAndAddToNonFinancialAccountTreeBody} createGroupAndAddToNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNonFinancialAccountGroup: async (nonFinancialAccountTreeId: string, createGroupAndAddToNonFinancialAccountTreeBody: CreateGroupAndAddToNonFinancialAccountTreeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('createNonFinancialAccountGroup', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'createGroupAndAddToNonFinancialAccountTreeBody' is not null or undefined
            assertParamExists('createNonFinancialAccountGroup', 'createGroupAndAddToNonFinancialAccountTreeBody', createGroupAndAddToNonFinancialAccountTreeBody)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/create-group-and-add-tree`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupAndAddToNonFinancialAccountTreeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目階層を削除する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNonFinancialAccountTree: async (nonFinancialAccountTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('deleteNonFinancialAccountTree', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務階層内の科目を指定して子階層ごと外す API
         * @param {string} nonFinancialAccountTreeId 
         * @param {DetachNonFinancialAccountTreeNodeBody} detachNonFinancialAccountTreeNodeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachNonFinancialAccountTreeNode: async (nonFinancialAccountTreeId: string, detachNonFinancialAccountTreeNodeBody: DetachNonFinancialAccountTreeNodeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('detachNonFinancialAccountTreeNode', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'detachNonFinancialAccountTreeNodeBody' is not null or undefined
            assertParamExists('detachNonFinancialAccountTreeNode', 'detachNonFinancialAccountTreeNodeBody', detachNonFinancialAccountTreeNodeBody)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/detach`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detachNonFinancialAccountTreeNodeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目階層の Excel ファイルを取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNonFinancialAccountTreesExcel: async (nonFinancialAccountTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('downloadNonFinancialAccountTreesExcel', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/excel`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目階層を取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonFinancialAccountTree: async (nonFinancialAccountTreeId: string, searchWord?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('getNonFinancialAccountTree', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchWord !== undefined) {
                localVarQueryParameter['searchWord'] = searchWord;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 選択用の本体値を含めた非財務科目階層を取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonFinancialAccountTreeForSelection: async (nonFinancialAccountTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('getNonFinancialAccountTreeForSelection', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/for-selection`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目階層に含まれない未設定科目の総数を取得する API（アーカイブ済み科目は含まない）
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncludedNonFinancialAccountCount: async (nonFinancialAccountTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('getNotIncludedNonFinancialAccountCount', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/not-included-count`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 選択用の本体値を含めたプライマリ非財務科目階層と未設定一覧を取得する API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrimaryNonFinancialAccountTreeForSelection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/non-financial-account-trees/for-primary-selection-with-not-included`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目階層の一覧を取得する API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNonFinancialAccountTrees: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/non-financial-account-trees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 未設定の非財務科目一覧を取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotIncludedNonFinancialAccounts: async (nonFinancialAccountTreeId: string, searchWord?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('listNotIncludedNonFinancialAccounts', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/not-included`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchWord !== undefined) {
                localVarQueryParameter['searchWord'] = searchWord;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 既存の非財務科目階層のコピーを作成する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {CopyNonFinancialAccountTreeBody} copyNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeCopyNonFinancialAccountTree: async (nonFinancialAccountTreeId: string, copyNonFinancialAccountTreeBody: CopyNonFinancialAccountTreeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('makeCopyNonFinancialAccountTree', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'copyNonFinancialAccountTreeBody' is not null or undefined
            assertParamExists('makeCopyNonFinancialAccountTree', 'copyNonFinancialAccountTreeBody', copyNonFinancialAccountTreeBody)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/make-copy`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyNonFinancialAccountTreeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目階層内の非財務科目・グループを移動させる API
         * @param {string} nonFinancialAccountTreeId 
         * @param {MoveNonFinancialAccountTreeNodeBody} moveNonFinancialAccountTreeNodeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNonFinancialAccountTreeNode: async (nonFinancialAccountTreeId: string, moveNonFinancialAccountTreeNodeBody: MoveNonFinancialAccountTreeNodeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('moveNonFinancialAccountTreeNode', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'moveNonFinancialAccountTreeNodeBody' is not null or undefined
            assertParamExists('moveNonFinancialAccountTreeNode', 'moveNonFinancialAccountTreeNodeBody', moveNonFinancialAccountTreeNodeBody)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/move-node`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveNonFinancialAccountTreeNodeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目階層の名前を変更する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {PutNonFinancialAccountTreeNameBody} putNonFinancialAccountTreeNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putName: async (nonFinancialAccountTreeId: string, putNonFinancialAccountTreeNameBody: PutNonFinancialAccountTreeNameBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('putName', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'putNonFinancialAccountTreeNameBody' is not null or undefined
            assertParamExists('putName', 'putNonFinancialAccountTreeNameBody', putNonFinancialAccountTreeNameBody)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/name`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putNonFinancialAccountTreeNameBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定した非財務科目階層をプライマリに設定する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryNonFinancialAccountTree: async (nonFinancialAccountTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('setPrimaryNonFinancialAccountTree', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/set-primary`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目をアーカイブ解除する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} targetAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unArchiveNonFinancialAccount: async (nonFinancialAccountTreeId: string, targetAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('unArchiveNonFinancialAccount', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'targetAccountId' is not null or undefined
            assertParamExists('unArchiveNonFinancialAccount', 'targetAccountId', targetAccountId)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/unarchive-account`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetAccountId !== undefined) {
                localVarQueryParameter['targetAccountId'] = targetAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excel ファイルをもとに非財務科目階層を更新する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNonFinancialAccountTreesExcel: async (nonFinancialAccountTreeId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialAccountTreeId' is not null or undefined
            assertParamExists('uploadNonFinancialAccountTreesExcel', 'nonFinancialAccountTreeId', nonFinancialAccountTreeId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadNonFinancialAccountTreesExcel', 'file', file)
            const localVarPath = `/api/non-financial-account-trees/{nonFinancialAccountTreeId}/bulk-update`
                .replace(`{${"nonFinancialAccountTreeId"}}`, encodeURIComponent(String(nonFinancialAccountTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NonFinancialAccountTreesControllerApi - functional programming interface
 * @export
 */
export const NonFinancialAccountTreesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NonFinancialAccountTreesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 未設定科目・グループを非財務科目階層に追加する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {AddNodeToNonFinancialAccountTreeBody} addNodeToNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNodeToNonFinancialAccountTree(nonFinancialAccountTreeId: string, addNodeToNonFinancialAccountTreeBody: AddNodeToNonFinancialAccountTreeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNodeToNonFinancialAccountTree(nonFinancialAccountTreeId, addNodeToNonFinancialAccountTreeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目をアーカイブする API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} targetAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveNonFinancialAccount(nonFinancialAccountTreeId: string, targetAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveNonFinancialAccount(nonFinancialAccountTreeId, targetAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目階層内にグループを作成する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {CreateGroupAndAddToNonFinancialAccountTreeBody} createGroupAndAddToNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNonFinancialAccountGroup(nonFinancialAccountTreeId: string, createGroupAndAddToNonFinancialAccountTreeBody: CreateGroupAndAddToNonFinancialAccountTreeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNonFinancialAccountGroup(nonFinancialAccountTreeId, createGroupAndAddToNonFinancialAccountTreeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目階層を削除する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNonFinancialAccountTree(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNonFinancialAccountTree(nonFinancialAccountTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務階層内の科目を指定して子階層ごと外す API
         * @param {string} nonFinancialAccountTreeId 
         * @param {DetachNonFinancialAccountTreeNodeBody} detachNonFinancialAccountTreeNodeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachNonFinancialAccountTreeNode(nonFinancialAccountTreeId: string, detachNonFinancialAccountTreeNodeBody: DetachNonFinancialAccountTreeNodeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachNonFinancialAccountTreeNode(nonFinancialAccountTreeId, detachNonFinancialAccountTreeNodeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目階層の Excel ファイルを取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目階層を取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNonFinancialAccountTree(nonFinancialAccountTreeId: string, searchWord?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonFinancialAccountFlatTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNonFinancialAccountTree(nonFinancialAccountTreeId, searchWord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 選択用の本体値を含めた非財務科目階層を取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNonFinancialAccountTreeForSelection(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonFinancialAccountFlatTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNonFinancialAccountTreeForSelection(nonFinancialAccountTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目階層に含まれない未設定科目の総数を取得する API（アーカイブ済み科目は含まない）
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotIncludedNonFinancialAccountCount(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotIncludedNonFinancialAccountCountView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotIncludedNonFinancialAccountCount(nonFinancialAccountTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 選択用の本体値を含めたプライマリ非財務科目階層と未設定一覧を取得する API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrimaryNonFinancialAccountTreeForSelection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonFinancialAccountFlatTreeWithNotIncludedForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrimaryNonFinancialAccountTreeForSelection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目階層の一覧を取得する API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNonFinancialAccountTrees(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonFinancialAccountTreeListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNonFinancialAccountTrees(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 未設定の非財務科目一覧を取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotIncludedNonFinancialAccounts(nonFinancialAccountTreeId: string, searchWord?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotIncludedNonFinancialAccountsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotIncludedNonFinancialAccounts(nonFinancialAccountTreeId, searchWord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 既存の非財務科目階層のコピーを作成する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {CopyNonFinancialAccountTreeBody} copyNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeCopyNonFinancialAccountTree(nonFinancialAccountTreeId: string, copyNonFinancialAccountTreeBody: CopyNonFinancialAccountTreeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonFinancialAccountTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeCopyNonFinancialAccountTree(nonFinancialAccountTreeId, copyNonFinancialAccountTreeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目階層内の非財務科目・グループを移動させる API
         * @param {string} nonFinancialAccountTreeId 
         * @param {MoveNonFinancialAccountTreeNodeBody} moveNonFinancialAccountTreeNodeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveNonFinancialAccountTreeNode(nonFinancialAccountTreeId: string, moveNonFinancialAccountTreeNodeBody: MoveNonFinancialAccountTreeNodeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveNonFinancialAccountTreeNode(nonFinancialAccountTreeId, moveNonFinancialAccountTreeNodeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目階層の名前を変更する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {PutNonFinancialAccountTreeNameBody} putNonFinancialAccountTreeNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putName(nonFinancialAccountTreeId: string, putNonFinancialAccountTreeNameBody: PutNonFinancialAccountTreeNameBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonFinancialAccountTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putName(nonFinancialAccountTreeId, putNonFinancialAccountTreeNameBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定した非財務科目階層をプライマリに設定する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPrimaryNonFinancialAccountTree(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPrimaryNonFinancialAccountTree(nonFinancialAccountTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目をアーカイブ解除する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} targetAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unArchiveNonFinancialAccount(nonFinancialAccountTreeId: string, targetAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unArchiveNonFinancialAccount(nonFinancialAccountTreeId, targetAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excel ファイルをもとに非財務科目階層を更新する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NonFinancialAccountTreesControllerApi - factory interface
 * @export
 */
export const NonFinancialAccountTreesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NonFinancialAccountTreesControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 未設定科目・グループを非財務科目階層に追加する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {AddNodeToNonFinancialAccountTreeBody} addNodeToNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNodeToNonFinancialAccountTree(nonFinancialAccountTreeId: string, addNodeToNonFinancialAccountTreeBody: AddNodeToNonFinancialAccountTreeBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.addNodeToNonFinancialAccountTree(nonFinancialAccountTreeId, addNodeToNonFinancialAccountTreeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目をアーカイブする API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} targetAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNonFinancialAccount(nonFinancialAccountTreeId: string, targetAccountId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.archiveNonFinancialAccount(nonFinancialAccountTreeId, targetAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目階層内にグループを作成する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {CreateGroupAndAddToNonFinancialAccountTreeBody} createGroupAndAddToNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNonFinancialAccountGroup(nonFinancialAccountTreeId: string, createGroupAndAddToNonFinancialAccountTreeBody: CreateGroupAndAddToNonFinancialAccountTreeBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createNonFinancialAccountGroup(nonFinancialAccountTreeId, createGroupAndAddToNonFinancialAccountTreeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目階層を削除する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNonFinancialAccountTree(nonFinancialAccountTreeId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteNonFinancialAccountTree(nonFinancialAccountTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務階層内の科目を指定して子階層ごと外す API
         * @param {string} nonFinancialAccountTreeId 
         * @param {DetachNonFinancialAccountTreeNodeBody} detachNonFinancialAccountTreeNodeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachNonFinancialAccountTreeNode(nonFinancialAccountTreeId: string, detachNonFinancialAccountTreeNodeBody: DetachNonFinancialAccountTreeNodeBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.detachNonFinancialAccountTreeNode(nonFinancialAccountTreeId, detachNonFinancialAccountTreeNodeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目階層の Excel ファイルを取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目階層を取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonFinancialAccountTree(nonFinancialAccountTreeId: string, searchWord?: string, options?: any): AxiosPromise<NonFinancialAccountFlatTreeView> {
            return localVarFp.getNonFinancialAccountTree(nonFinancialAccountTreeId, searchWord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 選択用の本体値を含めた非財務科目階層を取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonFinancialAccountTreeForSelection(nonFinancialAccountTreeId: string, options?: any): AxiosPromise<NonFinancialAccountFlatTreeForSelectionView> {
            return localVarFp.getNonFinancialAccountTreeForSelection(nonFinancialAccountTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目階層に含まれない未設定科目の総数を取得する API（アーカイブ済み科目は含まない）
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncludedNonFinancialAccountCount(nonFinancialAccountTreeId: string, options?: any): AxiosPromise<NotIncludedNonFinancialAccountCountView> {
            return localVarFp.getNotIncludedNonFinancialAccountCount(nonFinancialAccountTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 選択用の本体値を含めたプライマリ非財務科目階層と未設定一覧を取得する API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrimaryNonFinancialAccountTreeForSelection(options?: any): AxiosPromise<NonFinancialAccountFlatTreeWithNotIncludedForSelectionView> {
            return localVarFp.getPrimaryNonFinancialAccountTreeForSelection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目階層の一覧を取得する API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNonFinancialAccountTrees(options?: any): AxiosPromise<NonFinancialAccountTreeListView> {
            return localVarFp.listNonFinancialAccountTrees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 未設定の非財務科目一覧を取得する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotIncludedNonFinancialAccounts(nonFinancialAccountTreeId: string, searchWord?: string, options?: any): AxiosPromise<NotIncludedNonFinancialAccountsView> {
            return localVarFp.listNotIncludedNonFinancialAccounts(nonFinancialAccountTreeId, searchWord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 既存の非財務科目階層のコピーを作成する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {CopyNonFinancialAccountTreeBody} copyNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeCopyNonFinancialAccountTree(nonFinancialAccountTreeId: string, copyNonFinancialAccountTreeBody: CopyNonFinancialAccountTreeBody, options?: any): AxiosPromise<NonFinancialAccountTreeView> {
            return localVarFp.makeCopyNonFinancialAccountTree(nonFinancialAccountTreeId, copyNonFinancialAccountTreeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目階層内の非財務科目・グループを移動させる API
         * @param {string} nonFinancialAccountTreeId 
         * @param {MoveNonFinancialAccountTreeNodeBody} moveNonFinancialAccountTreeNodeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNonFinancialAccountTreeNode(nonFinancialAccountTreeId: string, moveNonFinancialAccountTreeNodeBody: MoveNonFinancialAccountTreeNodeBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.moveNonFinancialAccountTreeNode(nonFinancialAccountTreeId, moveNonFinancialAccountTreeNodeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目階層の名前を変更する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {PutNonFinancialAccountTreeNameBody} putNonFinancialAccountTreeNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putName(nonFinancialAccountTreeId: string, putNonFinancialAccountTreeNameBody: PutNonFinancialAccountTreeNameBody, options?: any): AxiosPromise<NonFinancialAccountTreeView> {
            return localVarFp.putName(nonFinancialAccountTreeId, putNonFinancialAccountTreeNameBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定した非財務科目階層をプライマリに設定する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryNonFinancialAccountTree(nonFinancialAccountTreeId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.setPrimaryNonFinancialAccountTree(nonFinancialAccountTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目をアーカイブ解除する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {string} targetAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unArchiveNonFinancialAccount(nonFinancialAccountTreeId: string, targetAccountId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.unArchiveNonFinancialAccount(nonFinancialAccountTreeId, targetAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excel ファイルをもとに非財務科目階層を更新する API
         * @param {string} nonFinancialAccountTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId: string, file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NonFinancialAccountTreesControllerApi - object-oriented interface
 * @export
 * @class NonFinancialAccountTreesControllerApi
 * @extends {BaseAPI}
 */
export class NonFinancialAccountTreesControllerApi extends BaseAPI {
    /**
     * 
     * @summary 未設定科目・グループを非財務科目階層に追加する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {AddNodeToNonFinancialAccountTreeBody} addNodeToNonFinancialAccountTreeBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public addNodeToNonFinancialAccountTree(nonFinancialAccountTreeId: string, addNodeToNonFinancialAccountTreeBody: AddNodeToNonFinancialAccountTreeBody, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).addNodeToNonFinancialAccountTree(nonFinancialAccountTreeId, addNodeToNonFinancialAccountTreeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目をアーカイブする API
     * @param {string} nonFinancialAccountTreeId 
     * @param {string} targetAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public archiveNonFinancialAccount(nonFinancialAccountTreeId: string, targetAccountId: string, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).archiveNonFinancialAccount(nonFinancialAccountTreeId, targetAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目階層内にグループを作成する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {CreateGroupAndAddToNonFinancialAccountTreeBody} createGroupAndAddToNonFinancialAccountTreeBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public createNonFinancialAccountGroup(nonFinancialAccountTreeId: string, createGroupAndAddToNonFinancialAccountTreeBody: CreateGroupAndAddToNonFinancialAccountTreeBody, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).createNonFinancialAccountGroup(nonFinancialAccountTreeId, createGroupAndAddToNonFinancialAccountTreeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目階層を削除する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public deleteNonFinancialAccountTree(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).deleteNonFinancialAccountTree(nonFinancialAccountTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務階層内の科目を指定して子階層ごと外す API
     * @param {string} nonFinancialAccountTreeId 
     * @param {DetachNonFinancialAccountTreeNodeBody} detachNonFinancialAccountTreeNodeBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public detachNonFinancialAccountTreeNode(nonFinancialAccountTreeId: string, detachNonFinancialAccountTreeNodeBody: DetachNonFinancialAccountTreeNodeBody, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).detachNonFinancialAccountTreeNode(nonFinancialAccountTreeId, detachNonFinancialAccountTreeNodeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目階層の Excel ファイルを取得する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public downloadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).downloadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目階層を取得する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {string} [searchWord] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public getNonFinancialAccountTree(nonFinancialAccountTreeId: string, searchWord?: string, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).getNonFinancialAccountTree(nonFinancialAccountTreeId, searchWord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 選択用の本体値を含めた非財務科目階層を取得する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public getNonFinancialAccountTreeForSelection(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).getNonFinancialAccountTreeForSelection(nonFinancialAccountTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目階層に含まれない未設定科目の総数を取得する API（アーカイブ済み科目は含まない）
     * @param {string} nonFinancialAccountTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public getNotIncludedNonFinancialAccountCount(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).getNotIncludedNonFinancialAccountCount(nonFinancialAccountTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 選択用の本体値を含めたプライマリ非財務科目階層と未設定一覧を取得する API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public getPrimaryNonFinancialAccountTreeForSelection(options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).getPrimaryNonFinancialAccountTreeForSelection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目階層の一覧を取得する API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public listNonFinancialAccountTrees(options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).listNonFinancialAccountTrees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 未設定の非財務科目一覧を取得する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {string} [searchWord] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public listNotIncludedNonFinancialAccounts(nonFinancialAccountTreeId: string, searchWord?: string, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).listNotIncludedNonFinancialAccounts(nonFinancialAccountTreeId, searchWord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 既存の非財務科目階層のコピーを作成する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {CopyNonFinancialAccountTreeBody} copyNonFinancialAccountTreeBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public makeCopyNonFinancialAccountTree(nonFinancialAccountTreeId: string, copyNonFinancialAccountTreeBody: CopyNonFinancialAccountTreeBody, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).makeCopyNonFinancialAccountTree(nonFinancialAccountTreeId, copyNonFinancialAccountTreeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目階層内の非財務科目・グループを移動させる API
     * @param {string} nonFinancialAccountTreeId 
     * @param {MoveNonFinancialAccountTreeNodeBody} moveNonFinancialAccountTreeNodeBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public moveNonFinancialAccountTreeNode(nonFinancialAccountTreeId: string, moveNonFinancialAccountTreeNodeBody: MoveNonFinancialAccountTreeNodeBody, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).moveNonFinancialAccountTreeNode(nonFinancialAccountTreeId, moveNonFinancialAccountTreeNodeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目階層の名前を変更する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {PutNonFinancialAccountTreeNameBody} putNonFinancialAccountTreeNameBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public putName(nonFinancialAccountTreeId: string, putNonFinancialAccountTreeNameBody: PutNonFinancialAccountTreeNameBody, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).putName(nonFinancialAccountTreeId, putNonFinancialAccountTreeNameBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定した非財務科目階層をプライマリに設定する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public setPrimaryNonFinancialAccountTree(nonFinancialAccountTreeId: string, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).setPrimaryNonFinancialAccountTree(nonFinancialAccountTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目をアーカイブ解除する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {string} targetAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public unArchiveNonFinancialAccount(nonFinancialAccountTreeId: string, targetAccountId: string, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).unArchiveNonFinancialAccount(nonFinancialAccountTreeId, targetAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excel ファイルをもとに非財務科目階層を更新する API
     * @param {string} nonFinancialAccountTreeId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountTreesControllerApi
     */
    public uploadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId: string, file: File, options?: AxiosRequestConfig) {
        return NonFinancialAccountTreesControllerApiFp(this.configuration).uploadNonFinancialAccountTreesExcel(nonFinancialAccountTreeId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NonFinancialAccountsControllerApi - axios parameter creator
 * @export
 */
export const NonFinancialAccountsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 非財務科目を作成し、非財務科目階層に追加する API
         * @param {CreateAccountAndAddNonFinancialAccountTreeBody} createAccountAndAddNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountAndAddNonFinancialAccountTree: async (createAccountAndAddNonFinancialAccountTreeBody: CreateAccountAndAddNonFinancialAccountTreeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountAndAddNonFinancialAccountTreeBody' is not null or undefined
            assertParamExists('createAccountAndAddNonFinancialAccountTree', 'createAccountAndAddNonFinancialAccountTreeBody', createAccountAndAddNonFinancialAccountTreeBody)
            const localVarPath = `/api/non-financial-accounts/create-and-add-tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountAndAddNonFinancialAccountTreeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目一覧Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNonFinancialAccountsExcel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/non-financial-accounts/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 全ての非財務科目を取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNonFinancialAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/non-financial-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excelアップロードし、非財務科目を一括作成・更新する
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNonFinancialAccountsExcel: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadNonFinancialAccountsExcel', 'file', file)
            const localVarPath = `/api/non-financial-accounts/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NonFinancialAccountsControllerApi - functional programming interface
 * @export
 */
export const NonFinancialAccountsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NonFinancialAccountsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 非財務科目を作成し、非財務科目階層に追加する API
         * @param {CreateAccountAndAddNonFinancialAccountTreeBody} createAccountAndAddNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccountAndAddNonFinancialAccountTree(createAccountAndAddNonFinancialAccountTreeBody: CreateAccountAndAddNonFinancialAccountTreeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccountAndAddNonFinancialAccountTree(createAccountAndAddNonFinancialAccountTreeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目一覧Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadNonFinancialAccountsExcel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadNonFinancialAccountsExcel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 全ての非財務科目を取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNonFinancialAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NonFinancialAccountView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNonFinancialAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excelアップロードし、非財務科目を一括作成・更新する
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNonFinancialAccountsExcel(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNonFinancialAccountsExcel(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NonFinancialAccountsControllerApi - factory interface
 * @export
 */
export const NonFinancialAccountsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NonFinancialAccountsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 非財務科目を作成し、非財務科目階層に追加する API
         * @param {CreateAccountAndAddNonFinancialAccountTreeBody} createAccountAndAddNonFinancialAccountTreeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountAndAddNonFinancialAccountTree(createAccountAndAddNonFinancialAccountTreeBody: CreateAccountAndAddNonFinancialAccountTreeBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createAccountAndAddNonFinancialAccountTree(createAccountAndAddNonFinancialAccountTreeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目一覧Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNonFinancialAccountsExcel(options?: any): AxiosPromise<File> {
            return localVarFp.downloadNonFinancialAccountsExcel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 全ての非財務科目を取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNonFinancialAccounts(options?: any): AxiosPromise<Array<NonFinancialAccountView>> {
            return localVarFp.listNonFinancialAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excelアップロードし、非財務科目を一括作成・更新する
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNonFinancialAccountsExcel(file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadNonFinancialAccountsExcel(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NonFinancialAccountsControllerApi - object-oriented interface
 * @export
 * @class NonFinancialAccountsControllerApi
 * @extends {BaseAPI}
 */
export class NonFinancialAccountsControllerApi extends BaseAPI {
    /**
     * 
     * @summary 非財務科目を作成し、非財務科目階層に追加する API
     * @param {CreateAccountAndAddNonFinancialAccountTreeBody} createAccountAndAddNonFinancialAccountTreeBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountsControllerApi
     */
    public createAccountAndAddNonFinancialAccountTree(createAccountAndAddNonFinancialAccountTreeBody: CreateAccountAndAddNonFinancialAccountTreeBody, options?: AxiosRequestConfig) {
        return NonFinancialAccountsControllerApiFp(this.configuration).createAccountAndAddNonFinancialAccountTree(createAccountAndAddNonFinancialAccountTreeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目一覧Excelのダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountsControllerApi
     */
    public downloadNonFinancialAccountsExcel(options?: AxiosRequestConfig) {
        return NonFinancialAccountsControllerApiFp(this.configuration).downloadNonFinancialAccountsExcel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 全ての非財務科目を取得する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountsControllerApi
     */
    public listNonFinancialAccounts(options?: AxiosRequestConfig) {
        return NonFinancialAccountsControllerApiFp(this.configuration).listNonFinancialAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excelアップロードし、非財務科目を一括作成・更新する
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialAccountsControllerApi
     */
    public uploadNonFinancialAccountsExcel(file: File, options?: AxiosRequestConfig) {
        return NonFinancialAccountsControllerApiFp(this.configuration).uploadNonFinancialAccountsExcel(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NonFinancialItemsControllerApi - axios parameter creator
 * @export
 */
export const NonFinancialItemsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 非財務科目明細フォーマットのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFormat: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/non-financial-items/format`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務科目明細フォーマット(Excel)のダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFormatExcel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/non-financial-items/format/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取込設定・加工設定を使った非財務実績アップロードで利用する型生成用
         * @param {ForOpenApiGenerator3Request} [forOpenApiGenerator3Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOpenApiGenerator3: async (forOpenApiGenerator3Request?: ForOpenApiGenerator3Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/non-financial-items/forOnlyOpenApiGenerator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forOpenApiGenerator3Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excelによる非財務科目明細の作成更新
         * @param {File} [excelFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadExcel: async (excelFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/non-financial-items/upload/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (excelFile !== undefined) { 
                localVarFormParams.append('excelFile', excelFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取込設定・加工設定を使った非財務実績アップロード
         * @param {File} file 
         * @param {UploadNonFinancialItemsParams} uploadParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNonFinancialItemsByMapping: async (file: File, uploadParams: UploadNonFinancialItemsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadNonFinancialItemsByMapping', 'file', file)
            // verify required parameter 'uploadParams' is not null or undefined
            assertParamExists('uploadNonFinancialItemsByMapping', 'uploadParams', uploadParams)
            const localVarPath = `/api/non-financial-items/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (uploadParams !== undefined) { 
                localVarFormParams.append('uploadParams', new Blob([JSON.stringify(uploadParams)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CSVによる非財務科目明細の作成更新
         * @param {File} [csvFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert: async (csvFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/non-financial-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NonFinancialItemsControllerApi - functional programming interface
 * @export
 */
export const NonFinancialItemsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NonFinancialItemsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 非財務科目明細フォーマットのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFormat(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFormat(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務科目明細フォーマット(Excel)のダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFormatExcel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFormatExcel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取込設定・加工設定を使った非財務実績アップロードで利用する型生成用
         * @param {ForOpenApiGenerator3Request} [forOpenApiGenerator3Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forOpenApiGenerator3(forOpenApiGenerator3Request?: ForOpenApiGenerator3Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forOpenApiGenerator3(forOpenApiGenerator3Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excelによる非財務科目明細の作成更新
         * @param {File} [excelFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadExcel(excelFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadExcel(excelFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取込設定・加工設定を使った非財務実績アップロード
         * @param {File} file 
         * @param {UploadNonFinancialItemsParams} uploadParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNonFinancialItemsByMapping(file: File, uploadParams: UploadNonFinancialItemsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNonFinancialItemsByMapping(file, uploadParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CSVによる非財務科目明細の作成更新
         * @param {File} [csvFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsert(csvFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsert(csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NonFinancialItemsControllerApi - factory interface
 * @export
 */
export const NonFinancialItemsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NonFinancialItemsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 非財務科目明細フォーマットのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFormat(options?: any): AxiosPromise<string> {
            return localVarFp.downloadFormat(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務科目明細フォーマット(Excel)のダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFormatExcel(options?: any): AxiosPromise<File> {
            return localVarFp.downloadFormatExcel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取込設定・加工設定を使った非財務実績アップロードで利用する型生成用
         * @param {ForOpenApiGenerator3Request} [forOpenApiGenerator3Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOpenApiGenerator3(forOpenApiGenerator3Request?: ForOpenApiGenerator3Request, options?: any): AxiosPromise<void> {
            return localVarFp.forOpenApiGenerator3(forOpenApiGenerator3Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excelによる非財務科目明細の作成更新
         * @param {File} [excelFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadExcel(excelFile?: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadExcel(excelFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取込設定・加工設定を使った非財務実績アップロード
         * @param {File} file 
         * @param {UploadNonFinancialItemsParams} uploadParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNonFinancialItemsByMapping(file: File, uploadParams: UploadNonFinancialItemsParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadNonFinancialItemsByMapping(file, uploadParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CSVによる非財務科目明細の作成更新
         * @param {File} [csvFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert(csvFile?: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.upsert(csvFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NonFinancialItemsControllerApi - object-oriented interface
 * @export
 * @class NonFinancialItemsControllerApi
 * @extends {BaseAPI}
 */
export class NonFinancialItemsControllerApi extends BaseAPI {
    /**
     * 
     * @summary 非財務科目明細フォーマットのダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialItemsControllerApi
     */
    public downloadFormat(options?: AxiosRequestConfig) {
        return NonFinancialItemsControllerApiFp(this.configuration).downloadFormat(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務科目明細フォーマット(Excel)のダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialItemsControllerApi
     */
    public downloadFormatExcel(options?: AxiosRequestConfig) {
        return NonFinancialItemsControllerApiFp(this.configuration).downloadFormatExcel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取込設定・加工設定を使った非財務実績アップロードで利用する型生成用
     * @param {ForOpenApiGenerator3Request} [forOpenApiGenerator3Request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialItemsControllerApi
     */
    public forOpenApiGenerator3(forOpenApiGenerator3Request?: ForOpenApiGenerator3Request, options?: AxiosRequestConfig) {
        return NonFinancialItemsControllerApiFp(this.configuration).forOpenApiGenerator3(forOpenApiGenerator3Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excelによる非財務科目明細の作成更新
     * @param {File} [excelFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialItemsControllerApi
     */
    public uploadExcel(excelFile?: File, options?: AxiosRequestConfig) {
        return NonFinancialItemsControllerApiFp(this.configuration).uploadExcel(excelFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取込設定・加工設定を使った非財務実績アップロード
     * @param {File} file 
     * @param {UploadNonFinancialItemsParams} uploadParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialItemsControllerApi
     */
    public uploadNonFinancialItemsByMapping(file: File, uploadParams: UploadNonFinancialItemsParams, options?: AxiosRequestConfig) {
        return NonFinancialItemsControllerApiFp(this.configuration).uploadNonFinancialItemsByMapping(file, uploadParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CSVによる非財務科目明細の作成更新
     * @param {File} [csvFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialItemsControllerApi
     */
    public upsert(csvFile?: File, options?: AxiosRequestConfig) {
        return NonFinancialItemsControllerApiFp(this.configuration).upsert(csvFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NonFinancialItemsUploadEventsControllerApi - axios parameter creator
 * @export
 */
export const NonFinancialItemsUploadEventsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 非財務明細アップロードイベントの削除
         * @param {string} nonFinancialItemsUploadEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (nonFinancialItemsUploadEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonFinancialItemsUploadEventId' is not null or undefined
            assertParamExists('_delete', 'nonFinancialItemsUploadEventId', nonFinancialItemsUploadEventId)
            const localVarPath = `/api/non-financial-items-upload-events/{nonFinancialItemsUploadEventId}`
                .replace(`{${"nonFinancialItemsUploadEventId"}}`, encodeURIComponent(String(nonFinancialItemsUploadEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uploadEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNonFinancialUploadedRaw: async (uploadEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadEventId' is not null or undefined
            assertParamExists('downloadNonFinancialUploadedRaw', 'uploadEventId', uploadEventId)
            const localVarPath = `/api/non-financial-items-upload-events/{uploadEventId}/raw`
                .replace(`{${"uploadEventId"}}`, encodeURIComponent(String(uploadEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非財務明細アップロードイベント一覧
         * @param {number} limit 
         * @param {number} offset 
         * @param {'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT'} sortKey 
         * @param {boolean} isAsc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNonFinancialItemsUploadEvents: async (limit: number, offset: number, sortKey: 'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT', isAsc: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('listNonFinancialItemsUploadEvents', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('listNonFinancialItemsUploadEvents', 'offset', offset)
            // verify required parameter 'sortKey' is not null or undefined
            assertParamExists('listNonFinancialItemsUploadEvents', 'sortKey', sortKey)
            // verify required parameter 'isAsc' is not null or undefined
            assertParamExists('listNonFinancialItemsUploadEvents', 'isAsc', isAsc)
            const localVarPath = `/api/non-financial-items-upload-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sortKey !== undefined) {
                localVarQueryParameter['sortKey'] = sortKey;
            }

            if (isAsc !== undefined) {
                localVarQueryParameter['isAsc'] = isAsc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NonFinancialItemsUploadEventsControllerApi - functional programming interface
 * @export
 */
export const NonFinancialItemsUploadEventsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NonFinancialItemsUploadEventsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 非財務明細アップロードイベントの削除
         * @param {string} nonFinancialItemsUploadEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(nonFinancialItemsUploadEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(nonFinancialItemsUploadEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uploadEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadNonFinancialUploadedRaw(uploadEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadNonFinancialUploadedRaw(uploadEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非財務明細アップロードイベント一覧
         * @param {number} limit 
         * @param {number} offset 
         * @param {'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT'} sortKey 
         * @param {boolean} isAsc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNonFinancialItemsUploadEvents(limit: number, offset: number, sortKey: 'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT', isAsc: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoNonFinancialItemsUploadEventsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNonFinancialItemsUploadEvents(limit, offset, sortKey, isAsc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NonFinancialItemsUploadEventsControllerApi - factory interface
 * @export
 */
export const NonFinancialItemsUploadEventsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NonFinancialItemsUploadEventsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 非財務明細アップロードイベントの削除
         * @param {string} nonFinancialItemsUploadEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(nonFinancialItemsUploadEventId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp._delete(nonFinancialItemsUploadEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uploadEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNonFinancialUploadedRaw(uploadEventId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadNonFinancialUploadedRaw(uploadEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非財務明細アップロードイベント一覧
         * @param {number} limit 
         * @param {number} offset 
         * @param {'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT'} sortKey 
         * @param {boolean} isAsc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNonFinancialItemsUploadEvents(limit: number, offset: number, sortKey: 'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT', isAsc: boolean, options?: any): AxiosPromise<PageDtoNonFinancialItemsUploadEventsDto> {
            return localVarFp.listNonFinancialItemsUploadEvents(limit, offset, sortKey, isAsc, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NonFinancialItemsUploadEventsControllerApi - object-oriented interface
 * @export
 * @class NonFinancialItemsUploadEventsControllerApi
 * @extends {BaseAPI}
 */
export class NonFinancialItemsUploadEventsControllerApi extends BaseAPI {
    /**
     * 
     * @summary 非財務明細アップロードイベントの削除
     * @param {string} nonFinancialItemsUploadEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialItemsUploadEventsControllerApi
     */
    public _delete(nonFinancialItemsUploadEventId: string, options?: AxiosRequestConfig) {
        return NonFinancialItemsUploadEventsControllerApiFp(this.configuration)._delete(nonFinancialItemsUploadEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uploadEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialItemsUploadEventsControllerApi
     */
    public downloadNonFinancialUploadedRaw(uploadEventId: string, options?: AxiosRequestConfig) {
        return NonFinancialItemsUploadEventsControllerApiFp(this.configuration).downloadNonFinancialUploadedRaw(uploadEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非財務明細アップロードイベント一覧
     * @param {number} limit 
     * @param {number} offset 
     * @param {'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT'} sortKey 
     * @param {boolean} isAsc 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NonFinancialItemsUploadEventsControllerApi
     */
    public listNonFinancialItemsUploadEvents(limit: number, offset: number, sortKey: 'FILE_NAME' | 'MIN_FISCAL_MONTH' | 'IMPORTED_AT', isAsc: boolean, options?: AxiosRequestConfig) {
        return NonFinancialItemsUploadEventsControllerApiFp(this.configuration).listNonFinancialItemsUploadEvents(limit, offset, sortKey, isAsc, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OAuthControllerApi - axios parameter creator
 * @export
 */
export const OAuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary oauth-callback
         * @param {string} state 
         * @param {string} code 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callback: async (state: string, code: string, errorDescription?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('callback', 'state', state)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('callback', 'code', code)
            const localVarPath = `/oauth/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (errorDescription !== undefined) {
                localVarQueryParameter['error_description'] = errorDescription;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get-grant-code-of-oauth
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCode: async (state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('getCode', 'state', state)
            const localVarPath = `/oauth/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthControllerApi - functional programming interface
 * @export
 */
export const OAuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary oauth-callback
         * @param {string} state 
         * @param {string} code 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callback(state: string, code: string, errorDescription?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callback(state, code, errorDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get-grant-code-of-oauth
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCode(state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCode(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OAuthControllerApi - factory interface
 * @export
 */
export const OAuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary oauth-callback
         * @param {string} state 
         * @param {string} code 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callback(state: string, code: string, errorDescription?: string, options?: any): AxiosPromise<string> {
            return localVarFp.callback(state, code, errorDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get-grant-code-of-oauth
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCode(state: string, options?: any): AxiosPromise<string> {
            return localVarFp.getCode(state, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OAuthControllerApi - object-oriented interface
 * @export
 * @class OAuthControllerApi
 * @extends {BaseAPI}
 */
export class OAuthControllerApi extends BaseAPI {
    /**
     * 
     * @summary oauth-callback
     * @param {string} state 
     * @param {string} code 
     * @param {string} [errorDescription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthControllerApi
     */
    public callback(state: string, code: string, errorDescription?: string, options?: AxiosRequestConfig) {
        return OAuthControllerApiFp(this.configuration).callback(state, code, errorDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get-grant-code-of-oauth
     * @param {string} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthControllerApi
     */
    public getCode(state: string, options?: AxiosRequestConfig) {
        return OAuthControllerApiFp(this.configuration).getCode(state, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationTreeV2ControllerApi - axios parameter creator
 * @export
 */
export const OrganizationTreeV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織図に未設定部署を追加する
         * @param {string} treeId 
         * @param {Array<string>} targetDepartmentIds 
         * @param {string} [newParentDepartmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDepartments: async (treeId: string, targetDepartmentIds: Array<string>, newParentDepartmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('addDepartments', 'treeId', treeId)
            // verify required parameter 'targetDepartmentIds' is not null or undefined
            assertParamExists('addDepartments', 'targetDepartmentIds', targetDepartmentIds)
            const localVarPath = `/api/v2/organization-trees/{treeId}/adddepartments`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetDepartmentIds) {
                localVarQueryParameter['targetDepartmentIds'] = Array.from(targetDepartmentIds);
            }

            if (newParentDepartmentId !== undefined) {
                localVarQueryParameter['newParentDepartmentId'] = newParentDepartmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図の部署をアーカイブする
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveDepartment: async (treeId: string, targetDepartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('archiveDepartment', 'treeId', treeId)
            // verify required parameter 'targetDepartmentId' is not null or undefined
            assertParamExists('archiveDepartment', 'targetDepartmentId', targetDepartmentId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/archive-department`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetDepartmentId !== undefined) {
                localVarQueryParameter['targetDepartmentId'] = targetDepartmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 新規組織図を作成する
         * @param {CreateOrganizationTreeParam} createOrganizationTreeParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationTree: async (createOrganizationTreeParam: CreateOrganizationTreeParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationTreeParam' is not null or undefined
            assertParamExists('createOrganizationTree', 'createOrganizationTreeParam', createOrganizationTreeParam)
            const localVarPath = `/api/v2/organization-trees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationTreeParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary サブツリーを削除する
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubTree: async (treeId: string, targetDepartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('deleteSubTree', 'treeId', treeId)
            // verify required parameter 'targetDepartmentId' is not null or undefined
            assertParamExists('deleteSubTree', 'targetDepartmentId', targetDepartmentId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/subtree`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetDepartmentId !== undefined) {
                localVarQueryParameter['targetDepartmentId'] = targetDepartmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図を削除する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTree: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('deleteTree', 'treeId', treeId)
            const localVarPath = `/api/v2/organization-trees/{treeId}`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 部署一覧Excelのダウンロード
         * @param {string} orgTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrgDepartmentsExcel: async (orgTreeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgTreeId' is not null or undefined
            assertParamExists('downloadOrgDepartmentsExcel', 'orgTreeId', orgTreeId)
            const localVarPath = `/api/v2/organization-trees/{orgTreeId}/departments/excel`
                .replace(`{${"orgTreeId"}}`, encodeURIComponent(String(orgTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excelアップロードし、組織階層を一括作成・更新・削除する。
         * @param {string} orgTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUpload: async (orgTreeId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgTreeId' is not null or undefined
            assertParamExists('fileUpload', 'orgTreeId', orgTreeId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('fileUpload', 'file', file)
            const localVarPath = `/api/v2/organization-trees/{orgTreeId}/departments/excel`
                .replace(`{${"orgTreeId"}}`, encodeURIComponent(String(orgTreeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 選択させるために本体値を表示するアクセス権限用組織図を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorityTreeForSelect: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/organization-trees/authority/for-selection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 特定のユーザーに選択させるために本体値を表示するアクセス権限用組織図を取得するAPI
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorityTreeForSelectionForUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAuthorityTreeForSelectionForUser', 'userId', userId)
            const localVarPath = `/api/v2/organization-trees/authority/for-selection/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図に含まれる部署一覧と未設定部署とアーカイブ部署の一覧の取得
         * @param {string} treeId 
         * @param {boolean} onlyNotIncluded 
         * @param {string} [departmentNameOrCode] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentsInOrganizationTreeAndNotIncludedAndArchivedDepartmentsList: async (treeId: string, onlyNotIncluded: boolean, departmentNameOrCode?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getDepartmentsInOrganizationTreeAndNotIncludedAndArchivedDepartmentsList', 'treeId', treeId)
            // verify required parameter 'onlyNotIncluded' is not null or undefined
            assertParamExists('getDepartmentsInOrganizationTreeAndNotIncludedAndArchivedDepartmentsList', 'onlyNotIncluded', onlyNotIncluded)
            const localVarPath = `/api/v2/organization-trees/{treeId}/departments`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (departmentNameOrCode !== undefined) {
                localVarQueryParameter['departmentNameOrCode'] = departmentNameOrCode;
            }

            if (onlyNotIncluded !== undefined) {
                localVarQueryParameter['onlyNotIncluded'] = onlyNotIncluded;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図に含まれない部署一覧を取得, 0件の場合は空のListを返す
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncluded: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getNotIncluded', 'treeId', treeId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/not-included`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図に含まれない部署数を取得
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncludedCount: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getNotIncludedCount', 'treeId', treeId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/not-included/count`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図構造で選択させるために本体値のある科目階層を取得する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTreeForSelect: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getOrganizationTreeForSelect', 'treeId', treeId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/for-selection`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図を取得するAPI
         * @param {string} treeId 
         * @param {string} [departmentName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTrees: async (treeId: string, departmentName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('getOrganizationTrees', 'treeId', treeId)
            const localVarPath = `/api/v2/organization-trees/{treeId}`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (departmentName !== undefined) {
                localVarQueryParameter['departmentName'] = departmentName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 選択させるために本体値を表示するプライマリ組織図を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrimaryTreeForSelect: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/organization-trees/primary/for-selection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図の一覧を取得するAPI
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationTrees: async (limit: number, offset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('listOrganizationTrees', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('listOrganizationTrees', 'offset', offset)
            const localVarPath = `/api/v2/organization-trees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ノードの移動先、部署の移動先の部署IDの候補（組織図に存在する部署ID一覧）
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listParentDepartmentCandidates: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('listParentDepartmentCandidates', 'treeId', treeId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/parentdepartmentcandidates`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 既存の組織図のコピーを作成する
         * @param {string} treeId 
         * @param {CopyOrganizationTreeParam} copyOrganizationTreeParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeCopy: async (treeId: string, copyOrganizationTreeParam: CopyOrganizationTreeParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('makeCopy', 'treeId', treeId)
            // verify required parameter 'copyOrganizationTreeParam' is not null or undefined
            assertParamExists('makeCopy', 'copyOrganizationTreeParam', copyOrganizationTreeParam)
            const localVarPath = `/api/v2/organization-trees/{treeId}/makecopy`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyOrganizationTreeParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ノードを移動する
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {string} [newParentDepartmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNodes: async (treeId: string, targetDepartmentId: string, newParentDepartmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('moveNodes', 'treeId', treeId)
            // verify required parameter 'targetDepartmentId' is not null or undefined
            assertParamExists('moveNodes', 'targetDepartmentId', targetDepartmentId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/movenode`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetDepartmentId !== undefined) {
                localVarQueryParameter['targetDepartmentId'] = targetDepartmentId;
            }

            if (newParentDepartmentId !== undefined) {
                localVarQueryParameter['newParentDepartmentId'] = newParentDepartmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図をアクセス権限用に設定する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAuthority: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('setAuthority', 'treeId', treeId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/set-authority`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図をプライマリに設定する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimary: async (treeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('setPrimary', 'treeId', treeId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/set-primary`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図のアーカイブ済の部署を戻す
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveDepartment: async (treeId: string, targetDepartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('unarchiveDepartment', 'treeId', treeId)
            // verify required parameter 'targetDepartmentId' is not null or undefined
            assertParamExists('unarchiveDepartment', 'targetDepartmentId', targetDepartmentId)
            const localVarPath = `/api/v2/organization-trees/{treeId}/unarchive-department`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetDepartmentId !== undefined) {
                localVarQueryParameter['targetDepartmentId'] = targetDepartmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織図名の変更
         * @param {string} treeId 
         * @param {UpdateOrgTreeNameBody} updateOrgTreeNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationTreeName: async (treeId: string, updateOrgTreeNameBody: UpdateOrgTreeNameBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treeId' is not null or undefined
            assertParamExists('updateOrganizationTreeName', 'treeId', treeId)
            // verify required parameter 'updateOrgTreeNameBody' is not null or undefined
            assertParamExists('updateOrganizationTreeName', 'updateOrgTreeNameBody', updateOrgTreeNameBody)
            const localVarPath = `/api/v2/organization-trees/{treeId}/treename`
                .replace(`{${"treeId"}}`, encodeURIComponent(String(treeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrgTreeNameBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationTreeV2ControllerApi - functional programming interface
 * @export
 */
export const OrganizationTreeV2ControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationTreeV2ControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織図に未設定部署を追加する
         * @param {string} treeId 
         * @param {Array<string>} targetDepartmentIds 
         * @param {string} [newParentDepartmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDepartments(treeId: string, targetDepartmentIds: Array<string>, newParentDepartmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDepartments(treeId, targetDepartmentIds, newParentDepartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図の部署をアーカイブする
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveDepartment(treeId: string, targetDepartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeV2View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveDepartment(treeId, targetDepartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 新規組織図を作成する
         * @param {CreateOrganizationTreeParam} createOrganizationTreeParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganizationTree(createOrganizationTreeParam: CreateOrganizationTreeParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationTree(createOrganizationTreeParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary サブツリーを削除する
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubTree(treeId: string, targetDepartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubTree(treeId, targetDepartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図を削除する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTree(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTree(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 部署一覧Excelのダウンロード
         * @param {string} orgTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOrgDepartmentsExcel(orgTreeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOrgDepartmentsExcel(orgTreeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excelアップロードし、組織階層を一括作成・更新・削除する。
         * @param {string} orgTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUpload(orgTreeId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUpload(orgTreeId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 選択させるために本体値を表示するアクセス権限用組織図を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorityTreeForSelect(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorityTreeForSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 特定のユーザーに選択させるために本体値を表示するアクセス権限用組織図を取得するAPI
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorityTreeForSelectionForUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorityTreeForSelectionForUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図に含まれる部署一覧と未設定部署とアーカイブ部署の一覧の取得
         * @param {string} treeId 
         * @param {boolean} onlyNotIncluded 
         * @param {string} [departmentNameOrCode] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentsInOrganizationTreeAndNotIncludedAndArchivedDepartmentsList(treeId: string, onlyNotIncluded: boolean, departmentNameOrCode?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoDepartmentViewWithIsAssignedAndIsArchived>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentsInOrganizationTreeAndNotIncludedAndArchivedDepartmentsList(treeId, onlyNotIncluded, departmentNameOrCode, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図に含まれない部署一覧を取得, 0件の場合は空のListを返す
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotIncluded(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotIncluded(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図に含まれない部署数を取得
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotIncludedCount(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountDepartmentsNotIncludedInOrgDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotIncludedCount(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図構造で選択させるために本体値のある科目階層を取得する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationTreeForSelect(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationTreeForSelect(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図を取得するAPI
         * @param {string} treeId 
         * @param {string} [departmentName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationTrees(treeId: string, departmentName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationTrees(treeId, departmentName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 選択させるために本体値を表示するプライマリ組織図を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrimaryTreeForSelect(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrimaryTreeForSelect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図の一覧を取得するAPI
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizationTrees(limit: number, offset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationTreeListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizationTrees(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ノードの移動先、部署の移動先の部署IDの候補（組織図に存在する部署ID一覧）
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listParentDepartmentCandidates(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listParentDepartmentCandidates(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 既存の組織図のコピーを作成する
         * @param {string} treeId 
         * @param {CopyOrganizationTreeParam} copyOrganizationTreeParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeCopy(treeId: string, copyOrganizationTreeParam: CopyOrganizationTreeParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeCopy(treeId, copyOrganizationTreeParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ノードを移動する
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {string} [newParentDepartmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveNodes(treeId: string, targetDepartmentId: string, newParentDepartmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveNodes(treeId, targetDepartmentId, newParentDepartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図をアクセス権限用に設定する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAuthority(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAuthority(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図をプライマリに設定する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPrimary(treeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPrimary(treeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図のアーカイブ済の部署を戻す
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unarchiveDepartment(treeId: string, targetDepartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgTreeV2View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveDepartment(treeId, targetDepartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織図名の変更
         * @param {string} treeId 
         * @param {UpdateOrgTreeNameBody} updateOrgTreeNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationTreeName(treeId: string, updateOrgTreeNameBody: UpdateOrgTreeNameBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationTreeName(treeId, updateOrgTreeNameBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationTreeV2ControllerApi - factory interface
 * @export
 */
export const OrganizationTreeV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationTreeV2ControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織図に未設定部署を追加する
         * @param {string} treeId 
         * @param {Array<string>} targetDepartmentIds 
         * @param {string} [newParentDepartmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDepartments(treeId: string, targetDepartmentIds: Array<string>, newParentDepartmentId?: string, options?: any): AxiosPromise<OrgTreeView> {
            return localVarFp.addDepartments(treeId, targetDepartmentIds, newParentDepartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図の部署をアーカイブする
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveDepartment(treeId: string, targetDepartmentId: string, options?: any): AxiosPromise<OrgTreeV2View> {
            return localVarFp.archiveDepartment(treeId, targetDepartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 新規組織図を作成する
         * @param {CreateOrganizationTreeParam} createOrganizationTreeParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationTree(createOrganizationTreeParam: CreateOrganizationTreeParam, options?: any): AxiosPromise<OrgTreeView> {
            return localVarFp.createOrganizationTree(createOrganizationTreeParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary サブツリーを削除する
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubTree(treeId: string, targetDepartmentId: string, options?: any): AxiosPromise<OrgTreeView> {
            return localVarFp.deleteSubTree(treeId, targetDepartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図を削除する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTree(treeId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteTree(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 部署一覧Excelのダウンロード
         * @param {string} orgTreeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrgDepartmentsExcel(orgTreeId: string, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.downloadOrgDepartmentsExcel(orgTreeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excelアップロードし、組織階層を一括作成・更新・削除する。
         * @param {string} orgTreeId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUpload(orgTreeId: string, file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.fileUpload(orgTreeId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 選択させるために本体値を表示するアクセス権限用組織図を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorityTreeForSelect(options?: any): AxiosPromise<OrgTreeForSelectionView> {
            return localVarFp.getAuthorityTreeForSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 特定のユーザーに選択させるために本体値を表示するアクセス権限用組織図を取得するAPI
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorityTreeForSelectionForUser(userId: string, options?: any): AxiosPromise<OrgTreeForSelectionView> {
            return localVarFp.getAuthorityTreeForSelectionForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図に含まれる部署一覧と未設定部署とアーカイブ部署の一覧の取得
         * @param {string} treeId 
         * @param {boolean} onlyNotIncluded 
         * @param {string} [departmentNameOrCode] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentsInOrganizationTreeAndNotIncludedAndArchivedDepartmentsList(treeId: string, onlyNotIncluded: boolean, departmentNameOrCode?: string, limit?: number, offset?: number, options?: any): AxiosPromise<PageDtoDepartmentViewWithIsAssignedAndIsArchived> {
            return localVarFp.getDepartmentsInOrganizationTreeAndNotIncludedAndArchivedDepartmentsList(treeId, onlyNotIncluded, departmentNameOrCode, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図に含まれない部署一覧を取得, 0件の場合は空のListを返す
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncluded(treeId: string, options?: any): AxiosPromise<Array<DepartmentView>> {
            return localVarFp.getNotIncluded(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図に含まれない部署数を取得
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotIncludedCount(treeId: string, options?: any): AxiosPromise<CountDepartmentsNotIncludedInOrgDto> {
            return localVarFp.getNotIncludedCount(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図構造で選択させるために本体値のある科目階層を取得する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTreeForSelect(treeId: string, options?: any): AxiosPromise<OrgTreeForSelectionView> {
            return localVarFp.getOrganizationTreeForSelect(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図を取得するAPI
         * @param {string} treeId 
         * @param {string} [departmentName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationTrees(treeId: string, departmentName?: string, options?: any): AxiosPromise<OrgTreeView> {
            return localVarFp.getOrganizationTrees(treeId, departmentName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 選択させるために本体値を表示するプライマリ組織図を取得するAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrimaryTreeForSelect(options?: any): AxiosPromise<OrgTreeForSelectionView> {
            return localVarFp.getPrimaryTreeForSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図の一覧を取得するAPI
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationTrees(limit: number, offset: number, options?: any): AxiosPromise<OrganizationTreeListView> {
            return localVarFp.listOrganizationTrees(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ノードの移動先、部署の移動先の部署IDの候補（組織図に存在する部署ID一覧）
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listParentDepartmentCandidates(treeId: string, options?: any): AxiosPromise<Array<DepartmentView>> {
            return localVarFp.listParentDepartmentCandidates(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 既存の組織図のコピーを作成する
         * @param {string} treeId 
         * @param {CopyOrganizationTreeParam} copyOrganizationTreeParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeCopy(treeId: string, copyOrganizationTreeParam: CopyOrganizationTreeParam, options?: any): AxiosPromise<OrgTreeView> {
            return localVarFp.makeCopy(treeId, copyOrganizationTreeParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ノードを移動する
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {string} [newParentDepartmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveNodes(treeId: string, targetDepartmentId: string, newParentDepartmentId?: string, options?: any): AxiosPromise<OrgTreeView> {
            return localVarFp.moveNodes(treeId, targetDepartmentId, newParentDepartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図をアクセス権限用に設定する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAuthority(treeId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.setAuthority(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図をプライマリに設定する
         * @param {string} treeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimary(treeId: string, options?: any): AxiosPromise<OrgTreeView> {
            return localVarFp.setPrimary(treeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図のアーカイブ済の部署を戻す
         * @param {string} treeId 
         * @param {string} targetDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveDepartment(treeId: string, targetDepartmentId: string, options?: any): AxiosPromise<OrgTreeV2View> {
            return localVarFp.unarchiveDepartment(treeId, targetDepartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織図名の変更
         * @param {string} treeId 
         * @param {UpdateOrgTreeNameBody} updateOrgTreeNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationTreeName(treeId: string, updateOrgTreeNameBody: UpdateOrgTreeNameBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateOrganizationTreeName(treeId, updateOrgTreeNameBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationTreeV2ControllerApi - object-oriented interface
 * @export
 * @class OrganizationTreeV2ControllerApi
 * @extends {BaseAPI}
 */
export class OrganizationTreeV2ControllerApi extends BaseAPI {
    /**
     * 
     * @summary 組織図に未設定部署を追加する
     * @param {string} treeId 
     * @param {Array<string>} targetDepartmentIds 
     * @param {string} [newParentDepartmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public addDepartments(treeId: string, targetDepartmentIds: Array<string>, newParentDepartmentId?: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).addDepartments(treeId, targetDepartmentIds, newParentDepartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図の部署をアーカイブする
     * @param {string} treeId 
     * @param {string} targetDepartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public archiveDepartment(treeId: string, targetDepartmentId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).archiveDepartment(treeId, targetDepartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 新規組織図を作成する
     * @param {CreateOrganizationTreeParam} createOrganizationTreeParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public createOrganizationTree(createOrganizationTreeParam: CreateOrganizationTreeParam, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).createOrganizationTree(createOrganizationTreeParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary サブツリーを削除する
     * @param {string} treeId 
     * @param {string} targetDepartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public deleteSubTree(treeId: string, targetDepartmentId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).deleteSubTree(treeId, targetDepartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図を削除する
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public deleteTree(treeId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).deleteTree(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 部署一覧Excelのダウンロード
     * @param {string} orgTreeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public downloadOrgDepartmentsExcel(orgTreeId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).downloadOrgDepartmentsExcel(orgTreeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excelアップロードし、組織階層を一括作成・更新・削除する。
     * @param {string} orgTreeId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public fileUpload(orgTreeId: string, file: File, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).fileUpload(orgTreeId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 選択させるために本体値を表示するアクセス権限用組織図を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public getAuthorityTreeForSelect(options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).getAuthorityTreeForSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 特定のユーザーに選択させるために本体値を表示するアクセス権限用組織図を取得するAPI
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public getAuthorityTreeForSelectionForUser(userId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).getAuthorityTreeForSelectionForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図に含まれる部署一覧と未設定部署とアーカイブ部署の一覧の取得
     * @param {string} treeId 
     * @param {boolean} onlyNotIncluded 
     * @param {string} [departmentNameOrCode] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public getDepartmentsInOrganizationTreeAndNotIncludedAndArchivedDepartmentsList(treeId: string, onlyNotIncluded: boolean, departmentNameOrCode?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).getDepartmentsInOrganizationTreeAndNotIncludedAndArchivedDepartmentsList(treeId, onlyNotIncluded, departmentNameOrCode, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図に含まれない部署一覧を取得, 0件の場合は空のListを返す
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public getNotIncluded(treeId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).getNotIncluded(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図に含まれない部署数を取得
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public getNotIncludedCount(treeId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).getNotIncludedCount(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図構造で選択させるために本体値のある科目階層を取得する
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public getOrganizationTreeForSelect(treeId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).getOrganizationTreeForSelect(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図を取得するAPI
     * @param {string} treeId 
     * @param {string} [departmentName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public getOrganizationTrees(treeId: string, departmentName?: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).getOrganizationTrees(treeId, departmentName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 選択させるために本体値を表示するプライマリ組織図を取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public getPrimaryTreeForSelect(options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).getPrimaryTreeForSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図の一覧を取得するAPI
     * @param {number} limit 
     * @param {number} offset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public listOrganizationTrees(limit: number, offset: number, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).listOrganizationTrees(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ノードの移動先、部署の移動先の部署IDの候補（組織図に存在する部署ID一覧）
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public listParentDepartmentCandidates(treeId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).listParentDepartmentCandidates(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 既存の組織図のコピーを作成する
     * @param {string} treeId 
     * @param {CopyOrganizationTreeParam} copyOrganizationTreeParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public makeCopy(treeId: string, copyOrganizationTreeParam: CopyOrganizationTreeParam, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).makeCopy(treeId, copyOrganizationTreeParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ノードを移動する
     * @param {string} treeId 
     * @param {string} targetDepartmentId 
     * @param {string} [newParentDepartmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public moveNodes(treeId: string, targetDepartmentId: string, newParentDepartmentId?: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).moveNodes(treeId, targetDepartmentId, newParentDepartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図をアクセス権限用に設定する
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public setAuthority(treeId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).setAuthority(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図をプライマリに設定する
     * @param {string} treeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public setPrimary(treeId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).setPrimary(treeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図のアーカイブ済の部署を戻す
     * @param {string} treeId 
     * @param {string} targetDepartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public unarchiveDepartment(treeId: string, targetDepartmentId: string, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).unarchiveDepartment(treeId, targetDepartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織図名の変更
     * @param {string} treeId 
     * @param {UpdateOrgTreeNameBody} updateOrgTreeNameBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTreeV2ControllerApi
     */
    public updateOrganizationTreeName(treeId: string, updateOrgTreeNameBody: UpdateOrgTreeNameBody, options?: AxiosRequestConfig) {
        return OrganizationTreeV2ControllerApiFp(this.configuration).updateOrganizationTreeName(treeId, updateOrgTreeNameBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonListControllerApi - axios parameter creator
 * @export
 */
export const PersonListControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 人員情報を削除する
         * @param {string} workforceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson: async (workforceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceId' is not null or undefined
            assertParamExists('deletePerson', 'workforceId', workforceId)
            const localVarPath = `/api/workforce-planning/person-list/{workforceId}`
                .replace(`{${"workforceId"}}`, encodeURIComponent(String(workforceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員の一括登録・更新をするフォーマットをダウンロードする
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBulkUpsertPeopleExcel: async (yearMonth: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('downloadBulkUpsertPeopleExcel', 'yearMonth', yearMonth)
            const localVarPath = `/api/workforce-planning/person-list/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員の一括登録/更新の Excel ファイルをダウンロードする
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWorkforceActualImportEventFile: async (eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('downloadWorkforceActualImportEventFile', 'eventId', eventId)
            const localVarPath = `/api/workforce-planning/person-list/upload-event/{eventId}/excel`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員情報を取得する
         * @param {string} workforceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPerson: async (workforceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceId' is not null or undefined
            assertParamExists('findPerson', 'workforceId', workforceId)
            const localVarPath = `/api/workforce-planning/person-list/{workforceId}`
                .replace(`{${"workforceId"}}`, encodeURIComponent(String(workforceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員一覧を取得する
         * @param {string} yearMonth 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPeople: async (yearMonth: string, limit: number, offset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('listPeople', 'yearMonth', yearMonth)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('listPeople', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('listPeople', 'offset', offset)
            const localVarPath = `/api/workforce-planning/person-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員実績で利用している分析軸のリストを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingDimensions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/person-list/using-dimensions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員の姓名とコードを変更する
         * @param {string} workforceId 
         * @param {UpdatePersonNameAndCodeRequestBody} updatePersonNameAndCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonNameAndCode: async (workforceId: string, updatePersonNameAndCodeRequestBody: UpdatePersonNameAndCodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceId' is not null or undefined
            assertParamExists('updatePersonNameAndCode', 'workforceId', workforceId)
            // verify required parameter 'updatePersonNameAndCodeRequestBody' is not null or undefined
            assertParamExists('updatePersonNameAndCode', 'updatePersonNameAndCodeRequestBody', updatePersonNameAndCodeRequestBody)
            const localVarPath = `/api/workforce-planning/person-list/{workforceId}`
                .replace(`{${"workforceId"}}`, encodeURIComponent(String(workforceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePersonNameAndCodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員の一括登録/更新の Excel ファイルを取込設定を指定してアップロードする
         * @param {string} mappingSettingId 
         * @param {File} file 
         * @param {string} [effectiveDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWorkforceActual: async (mappingSettingId: string, file: File, effectiveDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingSettingId' is not null or undefined
            assertParamExists('uploadWorkforceActual', 'mappingSettingId', mappingSettingId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadWorkforceActual', 'file', file)
            const localVarPath = `/api/workforce-planning/person-list/bulk-upsert-workforce-actual/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mappingSettingId !== undefined) {
                localVarQueryParameter['mappingSettingId'] = mappingSettingId;
            }

            if (effectiveDate !== undefined) {
                localVarQueryParameter['effectiveDate'] = effectiveDate;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員の一括登録/更新の Excel ファイルをアップロードする
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWorkforceActualChangeEventSnapshot: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadWorkforceActualChangeEventSnapshot', 'file', file)
            const localVarPath = `/api/workforce-planning/person-list/bulk-upsert-workforce-people/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonListControllerApi - functional programming interface
 * @export
 */
export const PersonListControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonListControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 人員情報を削除する
         * @param {string} workforceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerson(workforceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerson(workforceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員の一括登録・更新をするフォーマットをダウンロードする
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadBulkUpsertPeopleExcel(yearMonth: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadBulkUpsertPeopleExcel(yearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員の一括登録/更新の Excel ファイルをダウンロードする
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadWorkforceActualImportEventFile(eventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadWorkforceActualImportEventFile(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員情報を取得する
         * @param {string} workforceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPerson(workforceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPerson(workforceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員一覧を取得する
         * @param {string} yearMonth 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPeople(yearMonth: string, limit: number, offset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoPersonListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPeople(yearMonth, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員実績で利用している分析軸のリストを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsingDimensions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DimensionView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsingDimensions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員の姓名とコードを変更する
         * @param {string} workforceId 
         * @param {UpdatePersonNameAndCodeRequestBody} updatePersonNameAndCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonNameAndCode(workforceId: string, updatePersonNameAndCodeRequestBody: UpdatePersonNameAndCodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonNameAndCode(workforceId, updatePersonNameAndCodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員の一括登録/更新の Excel ファイルを取込設定を指定してアップロードする
         * @param {string} mappingSettingId 
         * @param {File} file 
         * @param {string} [effectiveDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadWorkforceActual(mappingSettingId: string, file: File, effectiveDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadWorkforceActual(mappingSettingId, file, effectiveDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員の一括登録/更新の Excel ファイルをアップロードする
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadWorkforceActualChangeEventSnapshot(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadWorkforceActualChangeEventSnapshot(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonListControllerApi - factory interface
 * @export
 */
export const PersonListControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonListControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 人員情報を削除する
         * @param {string} workforceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson(workforceId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deletePerson(workforceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員の一括登録・更新をするフォーマットをダウンロードする
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBulkUpsertPeopleExcel(yearMonth: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadBulkUpsertPeopleExcel(yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員の一括登録/更新の Excel ファイルをダウンロードする
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWorkforceActualImportEventFile(eventId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadWorkforceActualImportEventFile(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員情報を取得する
         * @param {string} workforceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPerson(workforceId: string, options?: any): AxiosPromise<PersonView> {
            return localVarFp.findPerson(workforceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員一覧を取得する
         * @param {string} yearMonth 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPeople(yearMonth: string, limit: number, offset: number, options?: any): AxiosPromise<PageDtoPersonListItem> {
            return localVarFp.listPeople(yearMonth, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員実績で利用している分析軸のリストを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingDimensions(options?: any): AxiosPromise<Array<DimensionView>> {
            return localVarFp.listUsingDimensions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員の姓名とコードを変更する
         * @param {string} workforceId 
         * @param {UpdatePersonNameAndCodeRequestBody} updatePersonNameAndCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonNameAndCode(workforceId: string, updatePersonNameAndCodeRequestBody: UpdatePersonNameAndCodeRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updatePersonNameAndCode(workforceId, updatePersonNameAndCodeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員の一括登録/更新の Excel ファイルを取込設定を指定してアップロードする
         * @param {string} mappingSettingId 
         * @param {File} file 
         * @param {string} [effectiveDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWorkforceActual(mappingSettingId: string, file: File, effectiveDate?: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadWorkforceActual(mappingSettingId, file, effectiveDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員の一括登録/更新の Excel ファイルをアップロードする
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWorkforceActualChangeEventSnapshot(file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadWorkforceActualChangeEventSnapshot(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonListControllerApi - object-oriented interface
 * @export
 * @class PersonListControllerApi
 * @extends {BaseAPI}
 */
export class PersonListControllerApi extends BaseAPI {
    /**
     * 
     * @summary 人員情報を削除する
     * @param {string} workforceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonListControllerApi
     */
    public deletePerson(workforceId: string, options?: AxiosRequestConfig) {
        return PersonListControllerApiFp(this.configuration).deletePerson(workforceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員の一括登録・更新をするフォーマットをダウンロードする
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonListControllerApi
     */
    public downloadBulkUpsertPeopleExcel(yearMonth: string, options?: AxiosRequestConfig) {
        return PersonListControllerApiFp(this.configuration).downloadBulkUpsertPeopleExcel(yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員の一括登録/更新の Excel ファイルをダウンロードする
     * @param {string} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonListControllerApi
     */
    public downloadWorkforceActualImportEventFile(eventId: string, options?: AxiosRequestConfig) {
        return PersonListControllerApiFp(this.configuration).downloadWorkforceActualImportEventFile(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員情報を取得する
     * @param {string} workforceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonListControllerApi
     */
    public findPerson(workforceId: string, options?: AxiosRequestConfig) {
        return PersonListControllerApiFp(this.configuration).findPerson(workforceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員一覧を取得する
     * @param {string} yearMonth 
     * @param {number} limit 
     * @param {number} offset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonListControllerApi
     */
    public listPeople(yearMonth: string, limit: number, offset: number, options?: AxiosRequestConfig) {
        return PersonListControllerApiFp(this.configuration).listPeople(yearMonth, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員実績で利用している分析軸のリストを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonListControllerApi
     */
    public listUsingDimensions(options?: AxiosRequestConfig) {
        return PersonListControllerApiFp(this.configuration).listUsingDimensions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員の姓名とコードを変更する
     * @param {string} workforceId 
     * @param {UpdatePersonNameAndCodeRequestBody} updatePersonNameAndCodeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonListControllerApi
     */
    public updatePersonNameAndCode(workforceId: string, updatePersonNameAndCodeRequestBody: UpdatePersonNameAndCodeRequestBody, options?: AxiosRequestConfig) {
        return PersonListControllerApiFp(this.configuration).updatePersonNameAndCode(workforceId, updatePersonNameAndCodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員の一括登録/更新の Excel ファイルを取込設定を指定してアップロードする
     * @param {string} mappingSettingId 
     * @param {File} file 
     * @param {string} [effectiveDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonListControllerApi
     */
    public uploadWorkforceActual(mappingSettingId: string, file: File, effectiveDate?: string, options?: AxiosRequestConfig) {
        return PersonListControllerApiFp(this.configuration).uploadWorkforceActual(mappingSettingId, file, effectiveDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員の一括登録/更新の Excel ファイルをアップロードする
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonListControllerApi
     */
    public uploadWorkforceActualChangeEventSnapshot(file: File, options?: AxiosRequestConfig) {
        return PersonListControllerApiFp(this.configuration).uploadWorkforceActualChangeEventSnapshot(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanActualDetailControllerApi - axios parameter creator
 * @export
 */
export const PlanActualDetailControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FetchDetailRequestBody} fetchDetailRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchResult: async (fetchDetailRequestBody: FetchDetailRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchDetailRequestBody' is not null or undefined
            assertParamExists('fetchResult', 'fetchDetailRequestBody', fetchDetailRequestBody)
            const localVarPath = `/api/plan-actual-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchDetailRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanActualDetailControllerApi - functional programming interface
 * @export
 */
export const PlanActualDetailControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanActualDetailControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FetchDetailRequestBody} fetchDetailRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchResult(fetchDetailRequestBody: FetchDetailRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchPlanActualDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchResult(fetchDetailRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanActualDetailControllerApi - factory interface
 * @export
 */
export const PlanActualDetailControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanActualDetailControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {FetchDetailRequestBody} fetchDetailRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchResult(fetchDetailRequestBody: FetchDetailRequestBody, options?: any): AxiosPromise<FetchPlanActualDetailView> {
            return localVarFp.fetchResult(fetchDetailRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanActualDetailControllerApi - object-oriented interface
 * @export
 * @class PlanActualDetailControllerApi
 * @extends {BaseAPI}
 */
export class PlanActualDetailControllerApi extends BaseAPI {
    /**
     * 
     * @param {FetchDetailRequestBody} fetchDetailRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanActualDetailControllerApi
     */
    public fetchResult(fetchDetailRequestBody: FetchDetailRequestBody, options?: AxiosRequestConfig) {
        return PlanActualDetailControllerApiFp(this.configuration).fetchResult(fetchDetailRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanBulkExcelPublishControllerApi - axios parameter creator
 * @export
 */
export const PlanBulkExcelPublishControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 一括更新申請のためのExcelを作成
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanBulkExcel: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createPlanBulkExcel', 'projectId', projectId)
            const localVarPath = `/api/plans/planningProjects/{projectId}/bulk-format/excel`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanBulkExcelPublishControllerApi - functional programming interface
 * @export
 */
export const PlanBulkExcelPublishControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanBulkExcelPublishControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 一括更新申請のためのExcelを作成
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlanBulkExcel(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlanBulkExcel(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanBulkExcelPublishControllerApi - factory interface
 * @export
 */
export const PlanBulkExcelPublishControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanBulkExcelPublishControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 一括更新申請のためのExcelを作成
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanBulkExcel(projectId: string, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.createPlanBulkExcel(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanBulkExcelPublishControllerApi - object-oriented interface
 * @export
 * @class PlanBulkExcelPublishControllerApi
 * @extends {BaseAPI}
 */
export class PlanBulkExcelPublishControllerApi extends BaseAPI {
    /**
     * 
     * @summary 一括更新申請のためのExcelを作成
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanBulkExcelPublishControllerApi
     */
    public createPlanBulkExcel(projectId: string, options?: AxiosRequestConfig) {
        return PlanBulkExcelPublishControllerApiFp(this.configuration).createPlanBulkExcel(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanExcelPublishControllerApi - axios parameter creator
 * @export
 */
export const PlanExcelPublishControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 明細単位の更新申請のためのExcelを作成
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanDetailFormat: async (planningProjectId: string, departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('createPlanDetailFormat', 'planningProjectId', planningProjectId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('createPlanDetailFormat', 'departmentId', departmentId)
            const localVarPath = `/api/plans/planningProjects/{planningProjectId}/departments/{departmentId}/excel/plan-detail`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請のためのExcelを作成
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanExcel: async (planningProjectId: string, departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('createPlanExcel', 'planningProjectId', planningProjectId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('createPlanExcel', 'departmentId', departmentId)
            const localVarPath = `/api/plans/planningProjects/{planningProjectId}/departments/{departmentId}/excel`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanExcelPublishControllerApi - functional programming interface
 * @export
 */
export const PlanExcelPublishControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanExcelPublishControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 明細単位の更新申請のためのExcelを作成
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlanDetailFormat(planningProjectId: string, departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlanDetailFormat(planningProjectId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請のためのExcelを作成
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlanExcel(planningProjectId: string, departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlanExcel(planningProjectId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanExcelPublishControllerApi - factory interface
 * @export
 */
export const PlanExcelPublishControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanExcelPublishControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 明細単位の更新申請のためのExcelを作成
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanDetailFormat(planningProjectId: string, departmentId: string, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.createPlanDetailFormat(planningProjectId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請のためのExcelを作成
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanExcel(planningProjectId: string, departmentId: string, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.createPlanExcel(planningProjectId, departmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanExcelPublishControllerApi - object-oriented interface
 * @export
 * @class PlanExcelPublishControllerApi
 * @extends {BaseAPI}
 */
export class PlanExcelPublishControllerApi extends BaseAPI {
    /**
     * 
     * @summary 明細単位の更新申請のためのExcelを作成
     * @param {string} planningProjectId 
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanExcelPublishControllerApi
     */
    public createPlanDetailFormat(planningProjectId: string, departmentId: string, options?: AxiosRequestConfig) {
        return PlanExcelPublishControllerApiFp(this.configuration).createPlanDetailFormat(planningProjectId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請のためのExcelを作成
     * @param {string} planningProjectId 
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanExcelPublishControllerApi
     */
    public createPlanExcel(planningProjectId: string, departmentId: string, options?: AxiosRequestConfig) {
        return PlanExcelPublishControllerApiFp(this.configuration).createPlanExcel(planningProjectId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanGSheetPublishControllerApi - axios parameter creator
 * @export
 */
export const PlanGSheetPublishControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 編成プロジェクトのGoogle Sheetsを作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateGSheetParam} createGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (planMasterId: string, planningProjectId: string, createGSheetParam: CreateGSheetParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('create', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('create', 'planningProjectId', planningProjectId)
            // verify required parameter 'createGSheetParam' is not null or undefined
            assertParamExists('create', 'createGSheetParam', createGSheetParam)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/gsheets/publish`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGSheetParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 一括更新申請のGoogle Sheetsを作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateBulkPlanUpdateRequestGSheetParam} createBulkPlanUpdateRequestGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkPlanUpdateRequest: async (planMasterId: string, planningProjectId: string, createBulkPlanUpdateRequestGSheetParam: CreateBulkPlanUpdateRequestGSheetParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('createBulkPlanUpdateRequest', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('createBulkPlanUpdateRequest', 'planningProjectId', planningProjectId)
            // verify required parameter 'createBulkPlanUpdateRequestGSheetParam' is not null or undefined
            assertParamExists('createBulkPlanUpdateRequest', 'createBulkPlanUpdateRequestGSheetParam', createBulkPlanUpdateRequestGSheetParam)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/gsheets/publish/plan-update-requests`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBulkPlanUpdateRequestGSheetParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分析軸単位の更新申請のGoogle Sheetsを作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateGSheetParam} createGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetail: async (planMasterId: string, planningProjectId: string, createGSheetParam: CreateGSheetParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('createDetail', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('createDetail', 'planningProjectId', planningProjectId)
            // verify required parameter 'createGSheetParam' is not null or undefined
            assertParamExists('createDetail', 'createGSheetParam', createGSheetParam)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/gsheets/publish/plan-detail`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGSheetParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanGSheetPublishControllerApi - functional programming interface
 * @export
 */
export const PlanGSheetPublishControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanGSheetPublishControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 編成プロジェクトのGoogle Sheetsを作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateGSheetParam} createGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(planMasterId: string, planningProjectId: string, createGSheetParam: CreateGSheetParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedGSheetView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(planMasterId, planningProjectId, createGSheetParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 一括更新申請のGoogle Sheetsを作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateBulkPlanUpdateRequestGSheetParam} createBulkPlanUpdateRequestGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBulkPlanUpdateRequest(planMasterId: string, planningProjectId: string, createBulkPlanUpdateRequestGSheetParam: CreateBulkPlanUpdateRequestGSheetParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedGSheetView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBulkPlanUpdateRequest(planMasterId, planningProjectId, createBulkPlanUpdateRequestGSheetParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分析軸単位の更新申請のGoogle Sheetsを作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateGSheetParam} createGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDetail(planMasterId: string, planningProjectId: string, createGSheetParam: CreateGSheetParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedGSheetView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDetail(planMasterId, planningProjectId, createGSheetParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanGSheetPublishControllerApi - factory interface
 * @export
 */
export const PlanGSheetPublishControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanGSheetPublishControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 編成プロジェクトのGoogle Sheetsを作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateGSheetParam} createGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(planMasterId: string, planningProjectId: string, createGSheetParam: CreateGSheetParam, options?: any): AxiosPromise<CreatedGSheetView> {
            return localVarFp.create(planMasterId, planningProjectId, createGSheetParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 一括更新申請のGoogle Sheetsを作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateBulkPlanUpdateRequestGSheetParam} createBulkPlanUpdateRequestGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkPlanUpdateRequest(planMasterId: string, planningProjectId: string, createBulkPlanUpdateRequestGSheetParam: CreateBulkPlanUpdateRequestGSheetParam, options?: any): AxiosPromise<CreatedGSheetView> {
            return localVarFp.createBulkPlanUpdateRequest(planMasterId, planningProjectId, createBulkPlanUpdateRequestGSheetParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分析軸単位の更新申請のGoogle Sheetsを作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateGSheetParam} createGSheetParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetail(planMasterId: string, planningProjectId: string, createGSheetParam: CreateGSheetParam, options?: any): AxiosPromise<CreatedGSheetView> {
            return localVarFp.createDetail(planMasterId, planningProjectId, createGSheetParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanGSheetPublishControllerApi - object-oriented interface
 * @export
 * @class PlanGSheetPublishControllerApi
 * @extends {BaseAPI}
 */
export class PlanGSheetPublishControllerApi extends BaseAPI {
    /**
     * 
     * @summary 編成プロジェクトのGoogle Sheetsを作成
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {CreateGSheetParam} createGSheetParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanGSheetPublishControllerApi
     */
    public create(planMasterId: string, planningProjectId: string, createGSheetParam: CreateGSheetParam, options?: AxiosRequestConfig) {
        return PlanGSheetPublishControllerApiFp(this.configuration).create(planMasterId, planningProjectId, createGSheetParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 一括更新申請のGoogle Sheetsを作成
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {CreateBulkPlanUpdateRequestGSheetParam} createBulkPlanUpdateRequestGSheetParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanGSheetPublishControllerApi
     */
    public createBulkPlanUpdateRequest(planMasterId: string, planningProjectId: string, createBulkPlanUpdateRequestGSheetParam: CreateBulkPlanUpdateRequestGSheetParam, options?: AxiosRequestConfig) {
        return PlanGSheetPublishControllerApiFp(this.configuration).createBulkPlanUpdateRequest(planMasterId, planningProjectId, createBulkPlanUpdateRequestGSheetParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分析軸単位の更新申請のGoogle Sheetsを作成
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {CreateGSheetParam} createGSheetParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanGSheetPublishControllerApi
     */
    public createDetail(planMasterId: string, planningProjectId: string, createGSheetParam: CreateGSheetParam, options?: AxiosRequestConfig) {
        return PlanGSheetPublishControllerApiFp(this.configuration).createDetail(planMasterId, planningProjectId, createGSheetParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanMasterControllerApi - axios parameter creator
 * @export
 */
export const PlanMasterControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 計画マスタアーカイブ
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveMaster: async (planMasterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('archiveMaster', 'planMasterId', planMasterId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/archive`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 計画マスタアーカイブ取消
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelArchiveMaster: async (planMasterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('cancelArchiveMaster', 'planMasterId', planMasterId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/cancelArchive`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 計画マスタ新規作成
         * @param {CreatePlanMaster} createPlanMaster 
         * @param {string} [originId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaster: async (createPlanMaster: CreatePlanMaster, originId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlanMaster' is not null or undefined
            assertParamExists('createMaster', 'createPlanMaster', createPlanMaster)
            const localVarPath = `/api/plans/planmasters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (originId !== undefined) {
                localVarQueryParameter['originId'] = originId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlanMaster, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 計画マスタ削除
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaster: async (planMasterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('deleteMaster', 'planMasterId', planMasterId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 計画マスタの一覧
         * @param {boolean} isArchived 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanMasters: async (isArchived: boolean, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isArchived' is not null or undefined
            assertParamExists('listPlanMasters', 'isArchived', isArchived)
            const localVarPath = `/api/plans/planmasters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['isArchived'] = isArchived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 計画マスタ編集
         * @param {string} planMasterId 
         * @param {UpdatePlanMaster} updatePlanMaster 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaster: async (planMasterId: string, updatePlanMaster: UpdatePlanMaster, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('updateMaster', 'planMasterId', planMasterId)
            // verify required parameter 'updatePlanMaster' is not null or undefined
            assertParamExists('updateMaster', 'updatePlanMaster', updatePlanMaster)
            const localVarPath = `/api/plans/planmasters/{planMasterId}`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlanMaster, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanMasterControllerApi - functional programming interface
 * @export
 */
export const PlanMasterControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanMasterControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 計画マスタアーカイブ
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveMaster(planMasterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveMaster(planMasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 計画マスタアーカイブ取消
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelArchiveMaster(planMasterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelArchiveMaster(planMasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 計画マスタ新規作成
         * @param {CreatePlanMaster} createPlanMaster 
         * @param {string} [originId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMaster(createPlanMaster: CreatePlanMaster, originId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanMasterMetaView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMaster(createPlanMaster, originId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 計画マスタ削除
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMaster(planMasterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMaster(planMasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 計画マスタの一覧
         * @param {boolean} isArchived 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlanMasters(isArchived: boolean, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanMastersView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlanMasters(isArchived, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 計画マスタ編集
         * @param {string} planMasterId 
         * @param {UpdatePlanMaster} updatePlanMaster 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMaster(planMasterId: string, updatePlanMaster: UpdatePlanMaster, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanMasterMetaView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMaster(planMasterId, updatePlanMaster, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanMasterControllerApi - factory interface
 * @export
 */
export const PlanMasterControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanMasterControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 計画マスタアーカイブ
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveMaster(planMasterId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.archiveMaster(planMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 計画マスタアーカイブ取消
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelArchiveMaster(planMasterId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.cancelArchiveMaster(planMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 計画マスタ新規作成
         * @param {CreatePlanMaster} createPlanMaster 
         * @param {string} [originId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaster(createPlanMaster: CreatePlanMaster, originId?: string, options?: any): AxiosPromise<PlanMasterMetaView> {
            return localVarFp.createMaster(createPlanMaster, originId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 計画マスタ削除
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaster(planMasterId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteMaster(planMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 計画マスタの一覧
         * @param {boolean} isArchived 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanMasters(isArchived: boolean, limit?: number, offset?: number, options?: any): AxiosPromise<PlanMastersView> {
            return localVarFp.listPlanMasters(isArchived, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 計画マスタ編集
         * @param {string} planMasterId 
         * @param {UpdatePlanMaster} updatePlanMaster 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaster(planMasterId: string, updatePlanMaster: UpdatePlanMaster, options?: any): AxiosPromise<PlanMasterMetaView> {
            return localVarFp.updateMaster(planMasterId, updatePlanMaster, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanMasterControllerApi - object-oriented interface
 * @export
 * @class PlanMasterControllerApi
 * @extends {BaseAPI}
 */
export class PlanMasterControllerApi extends BaseAPI {
    /**
     * 
     * @summary 計画マスタアーカイブ
     * @param {string} planMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanMasterControllerApi
     */
    public archiveMaster(planMasterId: string, options?: AxiosRequestConfig) {
        return PlanMasterControllerApiFp(this.configuration).archiveMaster(planMasterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 計画マスタアーカイブ取消
     * @param {string} planMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanMasterControllerApi
     */
    public cancelArchiveMaster(planMasterId: string, options?: AxiosRequestConfig) {
        return PlanMasterControllerApiFp(this.configuration).cancelArchiveMaster(planMasterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 計画マスタ新規作成
     * @param {CreatePlanMaster} createPlanMaster 
     * @param {string} [originId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanMasterControllerApi
     */
    public createMaster(createPlanMaster: CreatePlanMaster, originId?: string, options?: AxiosRequestConfig) {
        return PlanMasterControllerApiFp(this.configuration).createMaster(createPlanMaster, originId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 計画マスタ削除
     * @param {string} planMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanMasterControllerApi
     */
    public deleteMaster(planMasterId: string, options?: AxiosRequestConfig) {
        return PlanMasterControllerApiFp(this.configuration).deleteMaster(planMasterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 計画マスタの一覧
     * @param {boolean} isArchived 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanMasterControllerApi
     */
    public listPlanMasters(isArchived: boolean, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return PlanMasterControllerApiFp(this.configuration).listPlanMasters(isArchived, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 計画マスタ編集
     * @param {string} planMasterId 
     * @param {UpdatePlanMaster} updatePlanMaster 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanMasterControllerApi
     */
    public updateMaster(planMasterId: string, updatePlanMaster: UpdatePlanMaster, options?: AxiosRequestConfig) {
        return PlanMasterControllerApiFp(this.configuration).updateMaster(planMasterId, updatePlanMaster, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanUpdateRequestAggControllerApi - axios parameter creator
 * @export
 */
export const PlanUpdateRequestAggControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOpenPlanUpdateRequestDepartmentIds: async (planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('fetchOpenPlanUpdateRequestDepartmentIds', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/planUpdateRequests/openPlanUpdateRequestDepartmentIds`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} planningProjectId 
         * @param {FetchAggregatePlanUpdateRequestRequestBody} fetchAggregatePlanUpdateRequestRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPlanUpdateRequestAggregate: async (planningProjectId: string, fetchAggregatePlanUpdateRequestRequestBody: FetchAggregatePlanUpdateRequestRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('fetchPlanUpdateRequestAggregate', 'planningProjectId', planningProjectId)
            // verify required parameter 'fetchAggregatePlanUpdateRequestRequestBody' is not null or undefined
            assertParamExists('fetchPlanUpdateRequestAggregate', 'fetchAggregatePlanUpdateRequestRequestBody', fetchAggregatePlanUpdateRequestRequestBody)
            const localVarPath = `/api/planningProjects/{planningProjectId}/planUpdateRequests`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchAggregatePlanUpdateRequestRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanUpdateRequestAggControllerApi - functional programming interface
 * @export
 */
export const PlanUpdateRequestAggControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanUpdateRequestAggControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOpenPlanUpdateRequestDepartmentIds(planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOpenPlanUpdateRequestDepartmentIds(planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} planningProjectId 
         * @param {FetchAggregatePlanUpdateRequestRequestBody} fetchAggregatePlanUpdateRequestRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPlanUpdateRequestAggregate(planningProjectId: string, fetchAggregatePlanUpdateRequestRequestBody: FetchAggregatePlanUpdateRequestRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanUpdateRequestAggregateView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPlanUpdateRequestAggregate(planningProjectId, fetchAggregatePlanUpdateRequestRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanUpdateRequestAggControllerApi - factory interface
 * @export
 */
export const PlanUpdateRequestAggControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanUpdateRequestAggControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOpenPlanUpdateRequestDepartmentIds(planningProjectId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.fetchOpenPlanUpdateRequestDepartmentIds(planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} planningProjectId 
         * @param {FetchAggregatePlanUpdateRequestRequestBody} fetchAggregatePlanUpdateRequestRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPlanUpdateRequestAggregate(planningProjectId: string, fetchAggregatePlanUpdateRequestRequestBody: FetchAggregatePlanUpdateRequestRequestBody, options?: any): AxiosPromise<PlanUpdateRequestAggregateView> {
            return localVarFp.fetchPlanUpdateRequestAggregate(planningProjectId, fetchAggregatePlanUpdateRequestRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanUpdateRequestAggControllerApi - object-oriented interface
 * @export
 * @class PlanUpdateRequestAggControllerApi
 * @extends {BaseAPI}
 */
export class PlanUpdateRequestAggControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestAggControllerApi
     */
    public fetchOpenPlanUpdateRequestDepartmentIds(planningProjectId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestAggControllerApiFp(this.configuration).fetchOpenPlanUpdateRequestDepartmentIds(planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} planningProjectId 
     * @param {FetchAggregatePlanUpdateRequestRequestBody} fetchAggregatePlanUpdateRequestRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestAggControllerApi
     */
    public fetchPlanUpdateRequestAggregate(planningProjectId: string, fetchAggregatePlanUpdateRequestRequestBody: FetchAggregatePlanUpdateRequestRequestBody, options?: AxiosRequestConfig) {
        return PlanUpdateRequestAggControllerApiFp(this.configuration).fetchPlanUpdateRequestAggregate(planningProjectId, fetchAggregatePlanUpdateRequestRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanUpdateRequestControllerApi - axios parameter creator
 * @export
 */
export const PlanUpdateRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 現在は承認即、編成プロジェクトにマージされる
         * @summary 更新申請の承認
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePlanUpdateRequest: async (planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('approvePlanUpdateRequest', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('approvePlanUpdateRequest', 'planningProjectId', planningProjectId)
            // verify required parameter 'planUpdateRequestId' is not null or undefined
            assertParamExists('approvePlanUpdateRequest', 'planUpdateRequestId', planUpdateRequestId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/planUpdateRequests/{planUpdateRequestId}/approve`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"planUpdateRequestId"}}`, encodeURIComponent(String(planUpdateRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象の進行中の編成プロジェクトの更新申請のうち、未承認の更新申請の一括削除
         * @summary 更新申請の一括削除
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {BulkPlanUpdateRequestBody} bulkPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDeletePlanUpdateRequest: async (planMasterId: string, planningProjectId: string, bulkPlanUpdateRequestBody: BulkPlanUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('bulkDeletePlanUpdateRequest', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('bulkDeletePlanUpdateRequest', 'planningProjectId', planningProjectId)
            // verify required parameter 'bulkPlanUpdateRequestBody' is not null or undefined
            assertParamExists('bulkDeletePlanUpdateRequest', 'bulkPlanUpdateRequestBody', bulkPlanUpdateRequestBody)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/planUpdateRequests/bulk-delete`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkPlanUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 差分なし更新申請の作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateNoDiffPlanUpdateRequestBody} createNoDiffPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoDiffPlanUpdateRequest: async (planMasterId: string, planningProjectId: string, createNoDiffPlanUpdateRequestBody: CreateNoDiffPlanUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('createNoDiffPlanUpdateRequest', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('createNoDiffPlanUpdateRequest', 'planningProjectId', planningProjectId)
            // verify required parameter 'createNoDiffPlanUpdateRequestBody' is not null or undefined
            assertParamExists('createNoDiffPlanUpdateRequest', 'createNoDiffPlanUpdateRequestBody', createNoDiffPlanUpdateRequestBody)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/planUpdateRequests/no-diff`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNoDiffPlanUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 進行中の編成プロジェクトの更新申請のうち、最新の更新申請（承認、未承認を問わない）の削除
         * @summary 更新申請の削除
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanUpdateRequest: async (planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('deletePlanUpdateRequest', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('deletePlanUpdateRequest', 'planningProjectId', planningProjectId)
            // verify required parameter 'planUpdateRequestId' is not null or undefined
            assertParamExists('deletePlanUpdateRequest', 'planUpdateRequestId', planUpdateRequestId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/planUpdateRequests/{planUpdateRequestId}`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"planUpdateRequestId"}}`, encodeURIComponent(String(planUpdateRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 編成プロジェクトへの更新申請一覧
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanUpdateRequests: async (planMasterId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('listPlanUpdateRequests', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('listPlanUpdateRequests', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/planUpdateRequests`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請の一括承認の開始
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {BulkPlanUpdateRequestBody} bulkPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startBulkApprovingPlanUpdateRequests: async (planMasterId: string, planningProjectId: string, bulkPlanUpdateRequestBody: BulkPlanUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('startBulkApprovingPlanUpdateRequests', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('startBulkApprovingPlanUpdateRequests', 'planningProjectId', planningProjectId)
            // verify required parameter 'bulkPlanUpdateRequestBody' is not null or undefined
            assertParamExists('startBulkApprovingPlanUpdateRequests', 'bulkPlanUpdateRequestBody', bulkPlanUpdateRequestBody)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/planUpdateRequests/start-bulk-approving`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkPlanUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanUpdateRequestControllerApi - functional programming interface
 * @export
 */
export const PlanUpdateRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanUpdateRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 現在は承認即、編成プロジェクトにマージされる
         * @summary 更新申請の承認
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvePlanUpdateRequest(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvePlanUpdateRequest(planMasterId, planningProjectId, planUpdateRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 対象の進行中の編成プロジェクトの更新申請のうち、未承認の更新申請の一括削除
         * @summary 更新申請の一括削除
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {BulkPlanUpdateRequestBody} bulkPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkDeletePlanUpdateRequest(planMasterId: string, planningProjectId: string, bulkPlanUpdateRequestBody: BulkPlanUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkDeletePlanUpdateRequest(planMasterId, planningProjectId, bulkPlanUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 差分なし更新申請の作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateNoDiffPlanUpdateRequestBody} createNoDiffPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNoDiffPlanUpdateRequest(planMasterId: string, planningProjectId: string, createNoDiffPlanUpdateRequestBody: CreateNoDiffPlanUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNoDiffPlanUpdateRequest(planMasterId, planningProjectId, createNoDiffPlanUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 進行中の編成プロジェクトの更新申請のうち、最新の更新申請（承認、未承認を問わない）の削除
         * @summary 更新申請の削除
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlanUpdateRequest(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlanUpdateRequest(planMasterId, planningProjectId, planUpdateRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 編成プロジェクトへの更新申請一覧
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlanUpdateRequests(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanUpdateRequestsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlanUpdateRequests(planMasterId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請の一括承認の開始
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {BulkPlanUpdateRequestBody} bulkPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startBulkApprovingPlanUpdateRequests(planMasterId: string, planningProjectId: string, bulkPlanUpdateRequestBody: BulkPlanUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startBulkApprovingPlanUpdateRequests(planMasterId, planningProjectId, bulkPlanUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanUpdateRequestControllerApi - factory interface
 * @export
 */
export const PlanUpdateRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanUpdateRequestControllerApiFp(configuration)
    return {
        /**
         * 現在は承認即、編成プロジェクトにマージされる
         * @summary 更新申請の承認
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePlanUpdateRequest(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.approvePlanUpdateRequest(planMasterId, planningProjectId, planUpdateRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象の進行中の編成プロジェクトの更新申請のうち、未承認の更新申請の一括削除
         * @summary 更新申請の一括削除
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {BulkPlanUpdateRequestBody} bulkPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDeletePlanUpdateRequest(planMasterId: string, planningProjectId: string, bulkPlanUpdateRequestBody: BulkPlanUpdateRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.bulkDeletePlanUpdateRequest(planMasterId, planningProjectId, bulkPlanUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 差分なし更新申請の作成
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {CreateNoDiffPlanUpdateRequestBody} createNoDiffPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoDiffPlanUpdateRequest(planMasterId: string, planningProjectId: string, createNoDiffPlanUpdateRequestBody: CreateNoDiffPlanUpdateRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createNoDiffPlanUpdateRequest(planMasterId, planningProjectId, createNoDiffPlanUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 進行中の編成プロジェクトの更新申請のうち、最新の更新申請（承認、未承認を問わない）の削除
         * @summary 更新申請の削除
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanUpdateRequest(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deletePlanUpdateRequest(planMasterId, planningProjectId, planUpdateRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 編成プロジェクトへの更新申請一覧
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanUpdateRequests(planMasterId: string, planningProjectId: string, options?: any): AxiosPromise<PlanUpdateRequestsView> {
            return localVarFp.listPlanUpdateRequests(planMasterId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請の一括承認の開始
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {BulkPlanUpdateRequestBody} bulkPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startBulkApprovingPlanUpdateRequests(planMasterId: string, planningProjectId: string, bulkPlanUpdateRequestBody: BulkPlanUpdateRequestBody, options?: any): AxiosPromise<object> {
            return localVarFp.startBulkApprovingPlanUpdateRequests(planMasterId, planningProjectId, bulkPlanUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanUpdateRequestControllerApi - object-oriented interface
 * @export
 * @class PlanUpdateRequestControllerApi
 * @extends {BaseAPI}
 */
export class PlanUpdateRequestControllerApi extends BaseAPI {
    /**
     * 現在は承認即、編成プロジェクトにマージされる
     * @summary 更新申請の承認
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {string} planUpdateRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestControllerApi
     */
    public approvePlanUpdateRequest(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestControllerApiFp(this.configuration).approvePlanUpdateRequest(planMasterId, planningProjectId, planUpdateRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象の進行中の編成プロジェクトの更新申請のうち、未承認の更新申請の一括削除
     * @summary 更新申請の一括削除
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {BulkPlanUpdateRequestBody} bulkPlanUpdateRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestControllerApi
     */
    public bulkDeletePlanUpdateRequest(planMasterId: string, planningProjectId: string, bulkPlanUpdateRequestBody: BulkPlanUpdateRequestBody, options?: AxiosRequestConfig) {
        return PlanUpdateRequestControllerApiFp(this.configuration).bulkDeletePlanUpdateRequest(planMasterId, planningProjectId, bulkPlanUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 差分なし更新申請の作成
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {CreateNoDiffPlanUpdateRequestBody} createNoDiffPlanUpdateRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestControllerApi
     */
    public createNoDiffPlanUpdateRequest(planMasterId: string, planningProjectId: string, createNoDiffPlanUpdateRequestBody: CreateNoDiffPlanUpdateRequestBody, options?: AxiosRequestConfig) {
        return PlanUpdateRequestControllerApiFp(this.configuration).createNoDiffPlanUpdateRequest(planMasterId, planningProjectId, createNoDiffPlanUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 進行中の編成プロジェクトの更新申請のうち、最新の更新申請（承認、未承認を問わない）の削除
     * @summary 更新申請の削除
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {string} planUpdateRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestControllerApi
     */
    public deletePlanUpdateRequest(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestControllerApiFp(this.configuration).deletePlanUpdateRequest(planMasterId, planningProjectId, planUpdateRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 編成プロジェクトへの更新申請一覧
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestControllerApi
     */
    public listPlanUpdateRequests(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestControllerApiFp(this.configuration).listPlanUpdateRequests(planMasterId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請の一括承認の開始
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {BulkPlanUpdateRequestBody} bulkPlanUpdateRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestControllerApi
     */
    public startBulkApprovingPlanUpdateRequests(planMasterId: string, planningProjectId: string, bulkPlanUpdateRequestBody: BulkPlanUpdateRequestBody, options?: AxiosRequestConfig) {
        return PlanUpdateRequestControllerApiFp(this.configuration).startBulkApprovingPlanUpdateRequests(planMasterId, planningProjectId, bulkPlanUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanUpdateRequestFileControllerApi - axios parameter creator
 * @export
 */
export const PlanUpdateRequestFileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 反映できない実績の存在を確認する
         * @param {string} planUpdateRequestFileId 
         * @param {string} mappingId 
         * @param {string} actualDownloadPeriodStart 
         * @param {string} actualDownloadPeriodEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistsUnreflectableActualPlanUpdateRequestFile: async (planUpdateRequestFileId: string, mappingId: string, actualDownloadPeriodStart: string, actualDownloadPeriodEnd: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestFileId' is not null or undefined
            assertParamExists('checkExistsUnreflectableActualPlanUpdateRequestFile', 'planUpdateRequestFileId', planUpdateRequestFileId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('checkExistsUnreflectableActualPlanUpdateRequestFile', 'mappingId', mappingId)
            // verify required parameter 'actualDownloadPeriodStart' is not null or undefined
            assertParamExists('checkExistsUnreflectableActualPlanUpdateRequestFile', 'actualDownloadPeriodStart', actualDownloadPeriodStart)
            // verify required parameter 'actualDownloadPeriodEnd' is not null or undefined
            assertParamExists('checkExistsUnreflectableActualPlanUpdateRequestFile', 'actualDownloadPeriodEnd', actualDownloadPeriodEnd)
            const localVarPath = `/api/planUpdateRequestFiles/{planUpdateRequestFileId}/checkExistsUnreflectableActual`
                .replace(`{${"planUpdateRequestFileId"}}`, encodeURIComponent(String(planUpdateRequestFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mappingId !== undefined) {
                localVarQueryParameter['mappingId'] = mappingId;
            }

            if (actualDownloadPeriodStart !== undefined) {
                localVarQueryParameter['actualDownloadPeriodStart'] = actualDownloadPeriodStart;
            }

            if (actualDownloadPeriodEnd !== undefined) {
                localVarQueryParameter['actualDownloadPeriodEnd'] = actualDownloadPeriodEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 実績反映された更新申請ファイルのダウンロード
         * @param {string} planUpdateRequestFileId 
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActualPlanUpdateRequestFile: async (planUpdateRequestFileId: string, planningProjectId: string, mappingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestFileId' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFile', 'planUpdateRequestFileId', planUpdateRequestFileId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFile', 'planningProjectId', planningProjectId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFile', 'mappingId', mappingId)
            const localVarPath = `/api/planUpdateRequestFiles/{planUpdateRequestFileId}/downloadActual`
                .replace(`{${"planUpdateRequestFileId"}}`, encodeURIComponent(String(planUpdateRequestFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planningProjectId !== undefined) {
                localVarQueryParameter['planningProjectId'] = planningProjectId;
            }

            if (mappingId !== undefined) {
                localVarQueryParameter['mappingId'] = mappingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 実績反映された申請済ファイルを期間指定でダウンロード
         * @param {string} planUpdateRequestFileId 
         * @param {string} mappingId 
         * @param {string} actualDownloadPeriodStart 
         * @param {string} actualDownloadPeriodEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActualPlanUpdateRequestFileByDownloadPeriod: async (planUpdateRequestFileId: string, mappingId: string, actualDownloadPeriodStart: string, actualDownloadPeriodEnd: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestFileId' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFileByDownloadPeriod', 'planUpdateRequestFileId', planUpdateRequestFileId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFileByDownloadPeriod', 'mappingId', mappingId)
            // verify required parameter 'actualDownloadPeriodStart' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFileByDownloadPeriod', 'actualDownloadPeriodStart', actualDownloadPeriodStart)
            // verify required parameter 'actualDownloadPeriodEnd' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFileByDownloadPeriod', 'actualDownloadPeriodEnd', actualDownloadPeriodEnd)
            const localVarPath = `/api/planUpdateRequestFiles/{planUpdateRequestFileId}/downloadActualByDownloadPeriod`
                .replace(`{${"planUpdateRequestFileId"}}`, encodeURIComponent(String(planUpdateRequestFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mappingId !== undefined) {
                localVarQueryParameter['mappingId'] = mappingId;
            }

            if (actualDownloadPeriodStart !== undefined) {
                localVarQueryParameter['actualDownloadPeriodStart'] = actualDownloadPeriodStart;
            }

            if (actualDownloadPeriodEnd !== undefined) {
                localVarQueryParameter['actualDownloadPeriodEnd'] = actualDownloadPeriodEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請ファイルのダウンロード
         * @param {string} planUpdateRequestFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPlanUpdateRequestFile: async (planUpdateRequestFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestFileId' is not null or undefined
            assertParamExists('downloadPlanUpdateRequestFile', 'planUpdateRequestFileId', planUpdateRequestFileId)
            const localVarPath = `/api/planUpdateRequestFiles/{planUpdateRequestFileId}/download`
                .replace(`{${"planUpdateRequestFileId"}}`, encodeURIComponent(String(planUpdateRequestFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請ファイルの検索
         * @param {SearchPlanUpdateRequestFilesParams} searchPlanUpdateRequestFilesParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlanUpdateRequestFiles: async (searchPlanUpdateRequestFilesParams: SearchPlanUpdateRequestFilesParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPlanUpdateRequestFilesParams' is not null or undefined
            assertParamExists('searchPlanUpdateRequestFiles', 'searchPlanUpdateRequestFilesParams', searchPlanUpdateRequestFilesParams)
            const localVarPath = `/api/planUpdateRequestFiles/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPlanUpdateRequestFilesParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanUpdateRequestFileControllerApi - functional programming interface
 * @export
 */
export const PlanUpdateRequestFileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanUpdateRequestFileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 反映できない実績の存在を確認する
         * @param {string} planUpdateRequestFileId 
         * @param {string} mappingId 
         * @param {string} actualDownloadPeriodStart 
         * @param {string} actualDownloadPeriodEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkExistsUnreflectableActualPlanUpdateRequestFile(planUpdateRequestFileId: string, mappingId: string, actualDownloadPeriodStart: string, actualDownloadPeriodEnd: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanUpdateRequestFileUnreflectableActualCheckResultView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkExistsUnreflectableActualPlanUpdateRequestFile(planUpdateRequestFileId, mappingId, actualDownloadPeriodStart, actualDownloadPeriodEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 実績反映された更新申請ファイルのダウンロード
         * @param {string} planUpdateRequestFileId 
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadActualPlanUpdateRequestFile(planUpdateRequestFileId: string, planningProjectId: string, mappingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadActualPlanUpdateRequestFile(planUpdateRequestFileId, planningProjectId, mappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 実績反映された申請済ファイルを期間指定でダウンロード
         * @param {string} planUpdateRequestFileId 
         * @param {string} mappingId 
         * @param {string} actualDownloadPeriodStart 
         * @param {string} actualDownloadPeriodEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadActualPlanUpdateRequestFileByDownloadPeriod(planUpdateRequestFileId: string, mappingId: string, actualDownloadPeriodStart: string, actualDownloadPeriodEnd: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadActualPlanUpdateRequestFileByDownloadPeriod(planUpdateRequestFileId, mappingId, actualDownloadPeriodStart, actualDownloadPeriodEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請ファイルのダウンロード
         * @param {string} planUpdateRequestFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPlanUpdateRequestFile(planUpdateRequestFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPlanUpdateRequestFile(planUpdateRequestFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請ファイルの検索
         * @param {SearchPlanUpdateRequestFilesParams} searchPlanUpdateRequestFilesParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPlanUpdateRequestFiles(searchPlanUpdateRequestFilesParams: SearchPlanUpdateRequestFilesParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanUpdateRequestFileListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPlanUpdateRequestFiles(searchPlanUpdateRequestFilesParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanUpdateRequestFileControllerApi - factory interface
 * @export
 */
export const PlanUpdateRequestFileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanUpdateRequestFileControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 反映できない実績の存在を確認する
         * @param {string} planUpdateRequestFileId 
         * @param {string} mappingId 
         * @param {string} actualDownloadPeriodStart 
         * @param {string} actualDownloadPeriodEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistsUnreflectableActualPlanUpdateRequestFile(planUpdateRequestFileId: string, mappingId: string, actualDownloadPeriodStart: string, actualDownloadPeriodEnd: string, options?: any): AxiosPromise<PlanUpdateRequestFileUnreflectableActualCheckResultView> {
            return localVarFp.checkExistsUnreflectableActualPlanUpdateRequestFile(planUpdateRequestFileId, mappingId, actualDownloadPeriodStart, actualDownloadPeriodEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 実績反映された更新申請ファイルのダウンロード
         * @param {string} planUpdateRequestFileId 
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActualPlanUpdateRequestFile(planUpdateRequestFileId: string, planningProjectId: string, mappingId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadActualPlanUpdateRequestFile(planUpdateRequestFileId, planningProjectId, mappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 実績反映された申請済ファイルを期間指定でダウンロード
         * @param {string} planUpdateRequestFileId 
         * @param {string} mappingId 
         * @param {string} actualDownloadPeriodStart 
         * @param {string} actualDownloadPeriodEnd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActualPlanUpdateRequestFileByDownloadPeriod(planUpdateRequestFileId: string, mappingId: string, actualDownloadPeriodStart: string, actualDownloadPeriodEnd: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadActualPlanUpdateRequestFileByDownloadPeriod(planUpdateRequestFileId, mappingId, actualDownloadPeriodStart, actualDownloadPeriodEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請ファイルのダウンロード
         * @param {string} planUpdateRequestFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPlanUpdateRequestFile(planUpdateRequestFileId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadPlanUpdateRequestFile(planUpdateRequestFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請ファイルの検索
         * @param {SearchPlanUpdateRequestFilesParams} searchPlanUpdateRequestFilesParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlanUpdateRequestFiles(searchPlanUpdateRequestFilesParams: SearchPlanUpdateRequestFilesParams, options?: any): AxiosPromise<PlanUpdateRequestFileListView> {
            return localVarFp.searchPlanUpdateRequestFiles(searchPlanUpdateRequestFilesParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanUpdateRequestFileControllerApi - object-oriented interface
 * @export
 * @class PlanUpdateRequestFileControllerApi
 * @extends {BaseAPI}
 */
export class PlanUpdateRequestFileControllerApi extends BaseAPI {
    /**
     * 
     * @summary 反映できない実績の存在を確認する
     * @param {string} planUpdateRequestFileId 
     * @param {string} mappingId 
     * @param {string} actualDownloadPeriodStart 
     * @param {string} actualDownloadPeriodEnd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFileControllerApi
     */
    public checkExistsUnreflectableActualPlanUpdateRequestFile(planUpdateRequestFileId: string, mappingId: string, actualDownloadPeriodStart: string, actualDownloadPeriodEnd: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFileControllerApiFp(this.configuration).checkExistsUnreflectableActualPlanUpdateRequestFile(planUpdateRequestFileId, mappingId, actualDownloadPeriodStart, actualDownloadPeriodEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 実績反映された更新申請ファイルのダウンロード
     * @param {string} planUpdateRequestFileId 
     * @param {string} planningProjectId 
     * @param {string} mappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFileControllerApi
     */
    public downloadActualPlanUpdateRequestFile(planUpdateRequestFileId: string, planningProjectId: string, mappingId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFileControllerApiFp(this.configuration).downloadActualPlanUpdateRequestFile(planUpdateRequestFileId, planningProjectId, mappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 実績反映された申請済ファイルを期間指定でダウンロード
     * @param {string} planUpdateRequestFileId 
     * @param {string} mappingId 
     * @param {string} actualDownloadPeriodStart 
     * @param {string} actualDownloadPeriodEnd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFileControllerApi
     */
    public downloadActualPlanUpdateRequestFileByDownloadPeriod(planUpdateRequestFileId: string, mappingId: string, actualDownloadPeriodStart: string, actualDownloadPeriodEnd: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFileControllerApiFp(this.configuration).downloadActualPlanUpdateRequestFileByDownloadPeriod(planUpdateRequestFileId, mappingId, actualDownloadPeriodStart, actualDownloadPeriodEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請ファイルのダウンロード
     * @param {string} planUpdateRequestFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFileControllerApi
     */
    public downloadPlanUpdateRequestFile(planUpdateRequestFileId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFileControllerApiFp(this.configuration).downloadPlanUpdateRequestFile(planUpdateRequestFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請ファイルの検索
     * @param {SearchPlanUpdateRequestFilesParams} searchPlanUpdateRequestFilesParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFileControllerApi
     */
    public searchPlanUpdateRequestFiles(searchPlanUpdateRequestFilesParams: SearchPlanUpdateRequestFilesParams, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFileControllerApiFp(this.configuration).searchPlanUpdateRequestFiles(searchPlanUpdateRequestFilesParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanUpdateRequestFormatControllerApi - axios parameter creator
 * @export
 */
export const PlanUpdateRequestFormatControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 更新申請フォーマット新規作成
         * @param {File} file 
         * @param {CreatePlanUpdateRequestFormatParams} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanUpdateRequestFormat: async (file: File, params: CreatePlanUpdateRequestFormatParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createPlanUpdateRequestFormat', 'file', file)
            // verify required parameter 'params' is not null or undefined
            assertParamExists('createPlanUpdateRequestFormat', 'params', params)
            const localVarPath = `/api/planUpdateRequestFormats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (params !== undefined) { 
                localVarFormParams.append('params', new Blob([JSON.stringify(params)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請フォーマット削除
         * @param {string} planUpdateRequestFormatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanUpdateRequestFormat: async (planUpdateRequestFormatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestFormatId' is not null or undefined
            assertParamExists('deletePlanUpdateRequestFormat', 'planUpdateRequestFormatId', planUpdateRequestFormatId)
            const localVarPath = `/api/planUpdateRequestFormats/{planUpdateRequestFormatId}`
                .replace(`{${"planUpdateRequestFormatId"}}`, encodeURIComponent(String(planUpdateRequestFormatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 実績反映された更新申請フォーマットダウンロード
         * @param {string} planUpdateRequestFormatId 
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActualPlanUpdateRequestFormat: async (planUpdateRequestFormatId: string, planningProjectId: string, mappingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestFormatId' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFormat', 'planUpdateRequestFormatId', planUpdateRequestFormatId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFormat', 'planningProjectId', planningProjectId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('downloadActualPlanUpdateRequestFormat', 'mappingId', mappingId)
            const localVarPath = `/api/planUpdateRequestFormats/{planUpdateRequestFormatId}/downloadActual`
                .replace(`{${"planUpdateRequestFormatId"}}`, encodeURIComponent(String(planUpdateRequestFormatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planningProjectId !== undefined) {
                localVarQueryParameter['planningProjectId'] = planningProjectId;
            }

            if (mappingId !== undefined) {
                localVarQueryParameter['mappingId'] = mappingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請フォーマットダウンロード
         * @param {string} planUpdateRequestFormatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPlanUpdateRequestFormat: async (planUpdateRequestFormatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestFormatId' is not null or undefined
            assertParamExists('downloadPlanUpdateRequestFormat', 'planUpdateRequestFormatId', planUpdateRequestFormatId)
            const localVarPath = `/api/planUpdateRequestFormats/{planUpdateRequestFormatId}/download`
                .replace(`{${"planUpdateRequestFormatId"}}`, encodeURIComponent(String(planUpdateRequestFormatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請フォーマットで利用する型生成用
         * @param {ForOpenApiGenerator2Request} [forOpenApiGenerator2Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOpenApiGenerator2: async (forOpenApiGenerator2Request?: ForOpenApiGenerator2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/planUpdateRequestFormats/forOnlyOpenApiGenerator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forOpenApiGenerator2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請フォーマット一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanUpdateRequestFormats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/planUpdateRequestFormats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請フォーマットの検索
         * @param {SearchPlanUpdateRequestFormatsParams} searchPlanUpdateRequestFormatsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlanUpdateRequestFormats: async (searchPlanUpdateRequestFormatsParams: SearchPlanUpdateRequestFormatsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPlanUpdateRequestFormatsParams' is not null or undefined
            assertParamExists('searchPlanUpdateRequestFormats', 'searchPlanUpdateRequestFormatsParams', searchPlanUpdateRequestFormatsParams)
            const localVarPath = `/api/planUpdateRequestFormats/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPlanUpdateRequestFormatsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請フォーマット更新
         * @param {string} planUpdateRequestFormatId 
         * @param {UpdatePlanUpdateRequestFormatParams} params 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanUpdateRequestFormat: async (planUpdateRequestFormatId: string, params: UpdatePlanUpdateRequestFormatParams, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestFormatId' is not null or undefined
            assertParamExists('updatePlanUpdateRequestFormat', 'planUpdateRequestFormatId', planUpdateRequestFormatId)
            // verify required parameter 'params' is not null or undefined
            assertParamExists('updatePlanUpdateRequestFormat', 'params', params)
            const localVarPath = `/api/planUpdateRequestFormats/{planUpdateRequestFormatId}`
                .replace(`{${"planUpdateRequestFormatId"}}`, encodeURIComponent(String(planUpdateRequestFormatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (params !== undefined) { 
                localVarFormParams.append('params', new Blob([JSON.stringify(params)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanUpdateRequestFormatControllerApi - functional programming interface
 * @export
 */
export const PlanUpdateRequestFormatControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanUpdateRequestFormatControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 更新申請フォーマット新規作成
         * @param {File} file 
         * @param {CreatePlanUpdateRequestFormatParams} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlanUpdateRequestFormat(file: File, params: CreatePlanUpdateRequestFormatParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlanUpdateRequestFormat(file, params, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請フォーマット削除
         * @param {string} planUpdateRequestFormatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlanUpdateRequestFormat(planUpdateRequestFormatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlanUpdateRequestFormat(planUpdateRequestFormatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 実績反映された更新申請フォーマットダウンロード
         * @param {string} planUpdateRequestFormatId 
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadActualPlanUpdateRequestFormat(planUpdateRequestFormatId: string, planningProjectId: string, mappingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadActualPlanUpdateRequestFormat(planUpdateRequestFormatId, planningProjectId, mappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請フォーマットダウンロード
         * @param {string} planUpdateRequestFormatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPlanUpdateRequestFormat(planUpdateRequestFormatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPlanUpdateRequestFormat(planUpdateRequestFormatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請フォーマットで利用する型生成用
         * @param {ForOpenApiGenerator2Request} [forOpenApiGenerator2Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forOpenApiGenerator2(forOpenApiGenerator2Request?: ForOpenApiGenerator2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forOpenApiGenerator2(forOpenApiGenerator2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請フォーマット一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlanUpdateRequestFormats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanUpdateRequestFormatView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlanUpdateRequestFormats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請フォーマットの検索
         * @param {SearchPlanUpdateRequestFormatsParams} searchPlanUpdateRequestFormatsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPlanUpdateRequestFormats(searchPlanUpdateRequestFormatsParams: SearchPlanUpdateRequestFormatsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanUpdateRequestFormatListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPlanUpdateRequestFormats(searchPlanUpdateRequestFormatsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請フォーマット更新
         * @param {string} planUpdateRequestFormatId 
         * @param {UpdatePlanUpdateRequestFormatParams} params 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlanUpdateRequestFormat(planUpdateRequestFormatId: string, params: UpdatePlanUpdateRequestFormatParams, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlanUpdateRequestFormat(planUpdateRequestFormatId, params, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanUpdateRequestFormatControllerApi - factory interface
 * @export
 */
export const PlanUpdateRequestFormatControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanUpdateRequestFormatControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 更新申請フォーマット新規作成
         * @param {File} file 
         * @param {CreatePlanUpdateRequestFormatParams} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanUpdateRequestFormat(file: File, params: CreatePlanUpdateRequestFormatParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createPlanUpdateRequestFormat(file, params, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請フォーマット削除
         * @param {string} planUpdateRequestFormatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanUpdateRequestFormat(planUpdateRequestFormatId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deletePlanUpdateRequestFormat(planUpdateRequestFormatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 実績反映された更新申請フォーマットダウンロード
         * @param {string} planUpdateRequestFormatId 
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActualPlanUpdateRequestFormat(planUpdateRequestFormatId: string, planningProjectId: string, mappingId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadActualPlanUpdateRequestFormat(planUpdateRequestFormatId, planningProjectId, mappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請フォーマットダウンロード
         * @param {string} planUpdateRequestFormatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPlanUpdateRequestFormat(planUpdateRequestFormatId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadPlanUpdateRequestFormat(planUpdateRequestFormatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請フォーマットで利用する型生成用
         * @param {ForOpenApiGenerator2Request} [forOpenApiGenerator2Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOpenApiGenerator2(forOpenApiGenerator2Request?: ForOpenApiGenerator2Request, options?: any): AxiosPromise<void> {
            return localVarFp.forOpenApiGenerator2(forOpenApiGenerator2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請フォーマット一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanUpdateRequestFormats(options?: any): AxiosPromise<Array<PlanUpdateRequestFormatView>> {
            return localVarFp.listPlanUpdateRequestFormats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請フォーマットの検索
         * @param {SearchPlanUpdateRequestFormatsParams} searchPlanUpdateRequestFormatsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlanUpdateRequestFormats(searchPlanUpdateRequestFormatsParams: SearchPlanUpdateRequestFormatsParams, options?: any): AxiosPromise<PlanUpdateRequestFormatListView> {
            return localVarFp.searchPlanUpdateRequestFormats(searchPlanUpdateRequestFormatsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請フォーマット更新
         * @param {string} planUpdateRequestFormatId 
         * @param {UpdatePlanUpdateRequestFormatParams} params 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanUpdateRequestFormat(planUpdateRequestFormatId: string, params: UpdatePlanUpdateRequestFormatParams, file?: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updatePlanUpdateRequestFormat(planUpdateRequestFormatId, params, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanUpdateRequestFormatControllerApi - object-oriented interface
 * @export
 * @class PlanUpdateRequestFormatControllerApi
 * @extends {BaseAPI}
 */
export class PlanUpdateRequestFormatControllerApi extends BaseAPI {
    /**
     * 
     * @summary 更新申請フォーマット新規作成
     * @param {File} file 
     * @param {CreatePlanUpdateRequestFormatParams} params 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFormatControllerApi
     */
    public createPlanUpdateRequestFormat(file: File, params: CreatePlanUpdateRequestFormatParams, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFormatControllerApiFp(this.configuration).createPlanUpdateRequestFormat(file, params, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請フォーマット削除
     * @param {string} planUpdateRequestFormatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFormatControllerApi
     */
    public deletePlanUpdateRequestFormat(planUpdateRequestFormatId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFormatControllerApiFp(this.configuration).deletePlanUpdateRequestFormat(planUpdateRequestFormatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 実績反映された更新申請フォーマットダウンロード
     * @param {string} planUpdateRequestFormatId 
     * @param {string} planningProjectId 
     * @param {string} mappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFormatControllerApi
     */
    public downloadActualPlanUpdateRequestFormat(planUpdateRequestFormatId: string, planningProjectId: string, mappingId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFormatControllerApiFp(this.configuration).downloadActualPlanUpdateRequestFormat(planUpdateRequestFormatId, planningProjectId, mappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請フォーマットダウンロード
     * @param {string} planUpdateRequestFormatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFormatControllerApi
     */
    public downloadPlanUpdateRequestFormat(planUpdateRequestFormatId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFormatControllerApiFp(this.configuration).downloadPlanUpdateRequestFormat(planUpdateRequestFormatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請フォーマットで利用する型生成用
     * @param {ForOpenApiGenerator2Request} [forOpenApiGenerator2Request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFormatControllerApi
     */
    public forOpenApiGenerator2(forOpenApiGenerator2Request?: ForOpenApiGenerator2Request, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFormatControllerApiFp(this.configuration).forOpenApiGenerator2(forOpenApiGenerator2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請フォーマット一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFormatControllerApi
     */
    public listPlanUpdateRequestFormats(options?: AxiosRequestConfig) {
        return PlanUpdateRequestFormatControllerApiFp(this.configuration).listPlanUpdateRequestFormats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請フォーマットの検索
     * @param {SearchPlanUpdateRequestFormatsParams} searchPlanUpdateRequestFormatsParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFormatControllerApi
     */
    public searchPlanUpdateRequestFormats(searchPlanUpdateRequestFormatsParams: SearchPlanUpdateRequestFormatsParams, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFormatControllerApiFp(this.configuration).searchPlanUpdateRequestFormats(searchPlanUpdateRequestFormatsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請フォーマット更新
     * @param {string} planUpdateRequestFormatId 
     * @param {UpdatePlanUpdateRequestFormatParams} params 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestFormatControllerApi
     */
    public updatePlanUpdateRequestFormat(planUpdateRequestFormatId: string, params: UpdatePlanUpdateRequestFormatParams, file?: File, options?: AxiosRequestConfig) {
        return PlanUpdateRequestFormatControllerApiFp(this.configuration).updatePlanUpdateRequestFormat(planUpdateRequestFormatId, params, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanUpdateRequestMappingControllerApi - axios parameter creator
 * @export
 */
export const PlanUpdateRequestMappingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 更新申請マッピング新規作成
         * @param {CreatePlanUpdateRequestMappingParams} createPlanUpdateRequestMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanUpdateRequestMapping: async (createPlanUpdateRequestMappingParams: CreatePlanUpdateRequestMappingParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlanUpdateRequestMappingParams' is not null or undefined
            assertParamExists('createPlanUpdateRequestMapping', 'createPlanUpdateRequestMappingParams', createPlanUpdateRequestMappingParams)
            const localVarPath = `/api/planUpdateRequestMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlanUpdateRequestMappingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請マッピング削除
         * @param {string} planUpdateRequestMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanUpdateRequestMapping: async (planUpdateRequestMappingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestMappingId' is not null or undefined
            assertParamExists('deletePlanUpdateRequestMapping', 'planUpdateRequestMappingId', planUpdateRequestMappingId)
            const localVarPath = `/api/planUpdateRequestMappings/{planUpdateRequestMappingId}`
                .replace(`{${"planUpdateRequestMappingId"}}`, encodeURIComponent(String(planUpdateRequestMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請マッピング
         * @param {string} planUpdateRequestMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPlanUpdateRequestMapping: async (planUpdateRequestMappingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestMappingId' is not null or undefined
            assertParamExists('findPlanUpdateRequestMapping', 'planUpdateRequestMappingId', planUpdateRequestMappingId)
            const localVarPath = `/api/planUpdateRequestMappings/{planUpdateRequestMappingId}`
                .replace(`{${"planUpdateRequestMappingId"}}`, encodeURIComponent(String(planUpdateRequestMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請マッピング一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanUpdateRequestMappings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/planUpdateRequestMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請マッピング更新
         * @param {string} planUpdateRequestMappingId 
         * @param {UpdatePlanUpdateRequestMappingParams} updatePlanUpdateRequestMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanUpdateRequestMapping: async (planUpdateRequestMappingId: string, updatePlanUpdateRequestMappingParams: UpdatePlanUpdateRequestMappingParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planUpdateRequestMappingId' is not null or undefined
            assertParamExists('updatePlanUpdateRequestMapping', 'planUpdateRequestMappingId', planUpdateRequestMappingId)
            // verify required parameter 'updatePlanUpdateRequestMappingParams' is not null or undefined
            assertParamExists('updatePlanUpdateRequestMapping', 'updatePlanUpdateRequestMappingParams', updatePlanUpdateRequestMappingParams)
            const localVarPath = `/api/planUpdateRequestMappings/{planUpdateRequestMappingId}`
                .replace(`{${"planUpdateRequestMappingId"}}`, encodeURIComponent(String(planUpdateRequestMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlanUpdateRequestMappingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanUpdateRequestMappingControllerApi - functional programming interface
 * @export
 */
export const PlanUpdateRequestMappingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanUpdateRequestMappingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 更新申請マッピング新規作成
         * @param {CreatePlanUpdateRequestMappingParams} createPlanUpdateRequestMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlanUpdateRequestMapping(createPlanUpdateRequestMappingParams: CreatePlanUpdateRequestMappingParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlanUpdateRequestMapping(createPlanUpdateRequestMappingParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請マッピング削除
         * @param {string} planUpdateRequestMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlanUpdateRequestMapping(planUpdateRequestMappingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlanUpdateRequestMapping(planUpdateRequestMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請マッピング
         * @param {string} planUpdateRequestMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPlanUpdateRequestMapping(planUpdateRequestMappingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanUpdateRequestMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPlanUpdateRequestMapping(planUpdateRequestMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請マッピング一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlanUpdateRequestMappings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanUpdateRequestMappingView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlanUpdateRequestMappings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請マッピング更新
         * @param {string} planUpdateRequestMappingId 
         * @param {UpdatePlanUpdateRequestMappingParams} updatePlanUpdateRequestMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlanUpdateRequestMapping(planUpdateRequestMappingId: string, updatePlanUpdateRequestMappingParams: UpdatePlanUpdateRequestMappingParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlanUpdateRequestMapping(planUpdateRequestMappingId, updatePlanUpdateRequestMappingParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanUpdateRequestMappingControllerApi - factory interface
 * @export
 */
export const PlanUpdateRequestMappingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanUpdateRequestMappingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 更新申請マッピング新規作成
         * @param {CreatePlanUpdateRequestMappingParams} createPlanUpdateRequestMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanUpdateRequestMapping(createPlanUpdateRequestMappingParams: CreatePlanUpdateRequestMappingParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createPlanUpdateRequestMapping(createPlanUpdateRequestMappingParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請マッピング削除
         * @param {string} planUpdateRequestMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanUpdateRequestMapping(planUpdateRequestMappingId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deletePlanUpdateRequestMapping(planUpdateRequestMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請マッピング
         * @param {string} planUpdateRequestMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPlanUpdateRequestMapping(planUpdateRequestMappingId: string, options?: any): AxiosPromise<PlanUpdateRequestMapping> {
            return localVarFp.findPlanUpdateRequestMapping(planUpdateRequestMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請マッピング一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanUpdateRequestMappings(options?: any): AxiosPromise<Array<PlanUpdateRequestMappingView>> {
            return localVarFp.listPlanUpdateRequestMappings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請マッピング更新
         * @param {string} planUpdateRequestMappingId 
         * @param {UpdatePlanUpdateRequestMappingParams} updatePlanUpdateRequestMappingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanUpdateRequestMapping(planUpdateRequestMappingId: string, updatePlanUpdateRequestMappingParams: UpdatePlanUpdateRequestMappingParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updatePlanUpdateRequestMapping(planUpdateRequestMappingId, updatePlanUpdateRequestMappingParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanUpdateRequestMappingControllerApi - object-oriented interface
 * @export
 * @class PlanUpdateRequestMappingControllerApi
 * @extends {BaseAPI}
 */
export class PlanUpdateRequestMappingControllerApi extends BaseAPI {
    /**
     * 
     * @summary 更新申請マッピング新規作成
     * @param {CreatePlanUpdateRequestMappingParams} createPlanUpdateRequestMappingParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestMappingControllerApi
     */
    public createPlanUpdateRequestMapping(createPlanUpdateRequestMappingParams: CreatePlanUpdateRequestMappingParams, options?: AxiosRequestConfig) {
        return PlanUpdateRequestMappingControllerApiFp(this.configuration).createPlanUpdateRequestMapping(createPlanUpdateRequestMappingParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請マッピング削除
     * @param {string} planUpdateRequestMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestMappingControllerApi
     */
    public deletePlanUpdateRequestMapping(planUpdateRequestMappingId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestMappingControllerApiFp(this.configuration).deletePlanUpdateRequestMapping(planUpdateRequestMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請マッピング
     * @param {string} planUpdateRequestMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestMappingControllerApi
     */
    public findPlanUpdateRequestMapping(planUpdateRequestMappingId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestMappingControllerApiFp(this.configuration).findPlanUpdateRequestMapping(planUpdateRequestMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請マッピング一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestMappingControllerApi
     */
    public listPlanUpdateRequestMappings(options?: AxiosRequestConfig) {
        return PlanUpdateRequestMappingControllerApiFp(this.configuration).listPlanUpdateRequestMappings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請マッピング更新
     * @param {string} planUpdateRequestMappingId 
     * @param {UpdatePlanUpdateRequestMappingParams} updatePlanUpdateRequestMappingParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestMappingControllerApi
     */
    public updatePlanUpdateRequestMapping(planUpdateRequestMappingId: string, updatePlanUpdateRequestMappingParams: UpdatePlanUpdateRequestMappingParams, options?: AxiosRequestConfig) {
        return PlanUpdateRequestMappingControllerApiFp(this.configuration).updatePlanUpdateRequestMapping(planUpdateRequestMappingId, updatePlanUpdateRequestMappingParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanUpdateRequestV2ControllerApi - axios parameter creator
 * @export
 */
export const PlanUpdateRequestV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 更新申請は一部署のみのデータしか持たない為、部署Idの指定はない
         * @summary 編成プロジェクトへの更新申請取得API V2
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {'PL' | 'BS'} target 
         * @param {'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR'} aggregate 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} [showIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanUpdateRequest: async (planMasterId: string, planningProjectId: string, planUpdateRequestId: string, target: 'PL' | 'BS', aggregate: 'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR', showIn?: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('getPlanUpdateRequest', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getPlanUpdateRequest', 'planningProjectId', planningProjectId)
            // verify required parameter 'planUpdateRequestId' is not null or undefined
            assertParamExists('getPlanUpdateRequest', 'planUpdateRequestId', planUpdateRequestId)
            // verify required parameter 'target' is not null or undefined
            assertParamExists('getPlanUpdateRequest', 'target', target)
            // verify required parameter 'aggregate' is not null or undefined
            assertParamExists('getPlanUpdateRequest', 'aggregate', aggregate)
            const localVarPath = `/api/v2/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/planUpdateRequests/{planUpdateRequestId}`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"planUpdateRequestId"}}`, encodeURIComponent(String(planUpdateRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (target !== undefined) {
                localVarQueryParameter['target'] = target;
            }

            if (aggregate !== undefined) {
                localVarQueryParameter['aggregate'] = aggregate;
            }

            if (showIn !== undefined) {
                localVarQueryParameter['showIn'] = showIn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新申請のOriginFileを取得します
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanUpdateRequestOriginFile: async (planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('getPlanUpdateRequestOriginFile', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getPlanUpdateRequestOriginFile', 'planningProjectId', planningProjectId)
            // verify required parameter 'planUpdateRequestId' is not null or undefined
            assertParamExists('getPlanUpdateRequestOriginFile', 'planUpdateRequestId', planUpdateRequestId)
            const localVarPath = `/api/v2/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/planUpdateRequests/{planUpdateRequestId}/file`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"planUpdateRequestId"}}`, encodeURIComponent(String(planUpdateRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanUpdateRequestStatuses: async (planMasterId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('getPlanUpdateRequestStatuses', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getPlanUpdateRequestStatuses', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/v2/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/planUpdateRequests/statuses`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanUpdateRequestV2ControllerApi - functional programming interface
 * @export
 */
export const PlanUpdateRequestV2ControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanUpdateRequestV2ControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 更新申請は一部署のみのデータしか持たない為、部署Idの指定はない
         * @summary 編成プロジェクトへの更新申請取得API V2
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {'PL' | 'BS'} target 
         * @param {'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR'} aggregate 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} [showIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanUpdateRequest(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, target: 'PL' | 'BS', aggregate: 'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR', showIn?: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanUpdateRequestV2View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanUpdateRequest(planMasterId, planningProjectId, planUpdateRequestId, target, aggregate, showIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新申請のOriginFileを取得します
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanUpdateRequestOriginFile(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanUpdateRequestOriginFile(planMasterId, planningProjectId, planUpdateRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanUpdateRequestStatuses(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanUpdateRequestStatusesView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanUpdateRequestStatuses(planMasterId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanUpdateRequestV2ControllerApi - factory interface
 * @export
 */
export const PlanUpdateRequestV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanUpdateRequestV2ControllerApiFp(configuration)
    return {
        /**
         * 更新申請は一部署のみのデータしか持たない為、部署Idの指定はない
         * @summary 編成プロジェクトへの更新申請取得API V2
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {'PL' | 'BS'} target 
         * @param {'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR'} aggregate 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} [showIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanUpdateRequest(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, target: 'PL' | 'BS', aggregate: 'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR', showIn?: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options?: any): AxiosPromise<PlanUpdateRequestV2View> {
            return localVarFp.getPlanUpdateRequest(planMasterId, planningProjectId, planUpdateRequestId, target, aggregate, showIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新申請のOriginFileを取得します
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {string} planUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanUpdateRequestOriginFile(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPlanUpdateRequestOriginFile(planMasterId, planningProjectId, planUpdateRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanUpdateRequestStatuses(planMasterId: string, planningProjectId: string, options?: any): AxiosPromise<PlanUpdateRequestStatusesView> {
            return localVarFp.getPlanUpdateRequestStatuses(planMasterId, planningProjectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanUpdateRequestV2ControllerApi - object-oriented interface
 * @export
 * @class PlanUpdateRequestV2ControllerApi
 * @extends {BaseAPI}
 */
export class PlanUpdateRequestV2ControllerApi extends BaseAPI {
    /**
     * 更新申請は一部署のみのデータしか持たない為、部署Idの指定はない
     * @summary 編成プロジェクトへの更新申請取得API V2
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {string} planUpdateRequestId 
     * @param {'PL' | 'BS'} target 
     * @param {'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR'} aggregate 
     * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} [showIn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestV2ControllerApi
     */
    public getPlanUpdateRequest(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, target: 'PL' | 'BS', aggregate: 'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR', showIn?: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options?: AxiosRequestConfig) {
        return PlanUpdateRequestV2ControllerApiFp(this.configuration).getPlanUpdateRequest(planMasterId, planningProjectId, planUpdateRequestId, target, aggregate, showIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新申請のOriginFileを取得します
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {string} planUpdateRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestV2ControllerApi
     */
    public getPlanUpdateRequestOriginFile(planMasterId: string, planningProjectId: string, planUpdateRequestId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestV2ControllerApiFp(this.configuration).getPlanUpdateRequestOriginFile(planMasterId, planningProjectId, planUpdateRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestV2ControllerApi
     */
    public getPlanUpdateRequestStatuses(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return PlanUpdateRequestV2ControllerApiFp(this.configuration).getPlanUpdateRequestStatuses(planMasterId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanUpdateRequestV3ControllerApi - axios parameter creator
 * @export
 */
export const PlanUpdateRequestV3ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 取込設定を用いた財務実績アップロードで利用する型生成用
         * @param {ForOpenApiGenerator1Request} [forOpenApiGenerator1Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOpenApiGenerator1: async (forOpenApiGenerator1Request?: ForOpenApiGenerator1Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/planUpdateRequests/forOnlyOpenApiGenerator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forOpenApiGenerator1Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Loglassからエクセルの更新申請ファイルをアップロードし、シート名とインデックスの組み合わせを取得する
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelSheetNames: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('getExcelSheetNames', 'file', file)
            const localVarPath = `/api/planUpdateRequests/sheet-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Loglassからエクセルの更新申請ファイルをアップロードし、更新申請を作成する
         * @param {UploadExcelPlanUpdateParams} params 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadExcelPlanUpdateRequest: async (params: UploadExcelPlanUpdateParams, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'params' is not null or undefined
            assertParamExists('uploadExcelPlanUpdateRequest', 'params', params)
            const localVarPath = `/api/planUpdateRequests/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (params !== undefined) { 
                localVarFormParams.append('params', new Blob([JSON.stringify(params)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary スプシからエクセルの更新申請ファイルをアップロードし、更新申請を作成する
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {string} sheetUrl 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadExcelPlanUpdateRequestByGSheet: async (planningProjectId: string, mappingId: string, sheetUrl: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('uploadExcelPlanUpdateRequestByGSheet', 'planningProjectId', planningProjectId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('uploadExcelPlanUpdateRequestByGSheet', 'mappingId', mappingId)
            // verify required parameter 'sheetUrl' is not null or undefined
            assertParamExists('uploadExcelPlanUpdateRequestByGSheet', 'sheetUrl', sheetUrl)
            const localVarPath = `/api/planUpdateRequests/upload-gsheets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planningProjectId !== undefined) {
                localVarQueryParameter['planningProjectId'] = planningProjectId;
            }

            if (mappingId !== undefined) {
                localVarQueryParameter['mappingId'] = mappingId;
            }

            if (sheetUrl !== undefined) {
                localVarQueryParameter['sheetUrl'] = sheetUrl;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanUpdateRequestV3ControllerApi - functional programming interface
 * @export
 */
export const PlanUpdateRequestV3ControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanUpdateRequestV3ControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 取込設定を用いた財務実績アップロードで利用する型生成用
         * @param {ForOpenApiGenerator1Request} [forOpenApiGenerator1Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forOpenApiGenerator1(forOpenApiGenerator1Request?: ForOpenApiGenerator1Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forOpenApiGenerator1(forOpenApiGenerator1Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Loglassからエクセルの更新申請ファイルをアップロードし、シート名とインデックスの組み合わせを取得する
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExcelSheetNames(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SheetInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExcelSheetNames(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Loglassからエクセルの更新申請ファイルをアップロードし、更新申請を作成する
         * @param {UploadExcelPlanUpdateParams} params 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadExcelPlanUpdateRequest(params: UploadExcelPlanUpdateParams, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadExcelPlanUpdateRequest(params, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary スプシからエクセルの更新申請ファイルをアップロードし、更新申請を作成する
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {string} sheetUrl 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadExcelPlanUpdateRequestByGSheet(planningProjectId: string, mappingId: string, sheetUrl: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadExcelPlanUpdateRequestByGSheet(planningProjectId, mappingId, sheetUrl, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanUpdateRequestV3ControllerApi - factory interface
 * @export
 */
export const PlanUpdateRequestV3ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanUpdateRequestV3ControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 取込設定を用いた財務実績アップロードで利用する型生成用
         * @param {ForOpenApiGenerator1Request} [forOpenApiGenerator1Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOpenApiGenerator1(forOpenApiGenerator1Request?: ForOpenApiGenerator1Request, options?: any): AxiosPromise<void> {
            return localVarFp.forOpenApiGenerator1(forOpenApiGenerator1Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Loglassからエクセルの更新申請ファイルをアップロードし、シート名とインデックスの組み合わせを取得する
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExcelSheetNames(file: File, options?: any): AxiosPromise<Array<SheetInfo>> {
            return localVarFp.getExcelSheetNames(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Loglassからエクセルの更新申請ファイルをアップロードし、更新申請を作成する
         * @param {UploadExcelPlanUpdateParams} params 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadExcelPlanUpdateRequest(params: UploadExcelPlanUpdateParams, file?: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadExcelPlanUpdateRequest(params, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary スプシからエクセルの更新申請ファイルをアップロードし、更新申請を作成する
         * @param {string} planningProjectId 
         * @param {string} mappingId 
         * @param {string} sheetUrl 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadExcelPlanUpdateRequestByGSheet(planningProjectId: string, mappingId: string, sheetUrl: string, file?: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadExcelPlanUpdateRequestByGSheet(planningProjectId, mappingId, sheetUrl, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanUpdateRequestV3ControllerApi - object-oriented interface
 * @export
 * @class PlanUpdateRequestV3ControllerApi
 * @extends {BaseAPI}
 */
export class PlanUpdateRequestV3ControllerApi extends BaseAPI {
    /**
     * 
     * @summary 取込設定を用いた財務実績アップロードで利用する型生成用
     * @param {ForOpenApiGenerator1Request} [forOpenApiGenerator1Request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestV3ControllerApi
     */
    public forOpenApiGenerator1(forOpenApiGenerator1Request?: ForOpenApiGenerator1Request, options?: AxiosRequestConfig) {
        return PlanUpdateRequestV3ControllerApiFp(this.configuration).forOpenApiGenerator1(forOpenApiGenerator1Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Loglassからエクセルの更新申請ファイルをアップロードし、シート名とインデックスの組み合わせを取得する
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestV3ControllerApi
     */
    public getExcelSheetNames(file: File, options?: AxiosRequestConfig) {
        return PlanUpdateRequestV3ControllerApiFp(this.configuration).getExcelSheetNames(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Loglassからエクセルの更新申請ファイルをアップロードし、更新申請を作成する
     * @param {UploadExcelPlanUpdateParams} params 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestV3ControllerApi
     */
    public uploadExcelPlanUpdateRequest(params: UploadExcelPlanUpdateParams, file?: File, options?: AxiosRequestConfig) {
        return PlanUpdateRequestV3ControllerApiFp(this.configuration).uploadExcelPlanUpdateRequest(params, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary スプシからエクセルの更新申請ファイルをアップロードし、更新申請を作成する
     * @param {string} planningProjectId 
     * @param {string} mappingId 
     * @param {string} sheetUrl 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanUpdateRequestV3ControllerApi
     */
    public uploadExcelPlanUpdateRequestByGSheet(planningProjectId: string, mappingId: string, sheetUrl: string, file?: File, options?: AxiosRequestConfig) {
        return PlanUpdateRequestV3ControllerApiFp(this.configuration).uploadExcelPlanUpdateRequestByGSheet(planningProjectId, mappingId, sheetUrl, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanningProjectControllerApi - axios parameter creator
 * @export
 */
export const PlanningProjectControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 編成プロジェクトをアーカイブする
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivedPlanningProject: async (planMasterId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('archivedPlanningProject', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('archivedPlanningProject', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/archive`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 編成プロジェクトのアーカイブを取消する
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelArchivedPlanningProject: async (planMasterId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('cancelArchivedPlanningProject', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('cancelArchivedPlanningProject', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/cancelArchive`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 編成プロジェクト削除
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanningProject: async (planMasterId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('deletePlanningProject', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('deletePlanningProject', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 編成プロジェクト編成完了
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixPlanningProject: async (planMasterId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('fixPlanningProject', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('fixPlanningProject', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}/fix`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 編成プロジェクト最新値
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR'} aggregate 
         * @param {Array<string>} [departmentIds] 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} [showIn] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanningProject: async (planMasterId: string, planningProjectId: string, aggregate: 'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR', departmentIds?: Array<string>, showIn?: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', start?: string, end?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('getPlanningProject', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getPlanningProject', 'planningProjectId', planningProjectId)
            // verify required parameter 'aggregate' is not null or undefined
            assertParamExists('getPlanningProject', 'aggregate', aggregate)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (departmentIds) {
                localVarQueryParameter['departmentIds'] = departmentIds;
            }

            if (aggregate !== undefined) {
                localVarQueryParameter['aggregate'] = aggregate;
            }

            if (showIn !== undefined) {
                localVarQueryParameter['showIn'] = showIn;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 編成プロジェクト作成開始
         * @param {string} planMasterId 
         * @param {CreatePlanningProjectParam} createPlanningProjectParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCreatingPlanningProject: async (planMasterId: string, createPlanningProjectParam: CreatePlanningProjectParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('startCreatingPlanningProject', 'planMasterId', planMasterId)
            // verify required parameter 'createPlanningProjectParam' is not null or undefined
            assertParamExists('startCreatingPlanningProject', 'createPlanningProjectParam', createPlanningProjectParam)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/start-creating`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlanningProjectParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 編成プロジェクト編集
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {UpdatePlanningProjectParam} updatePlanningProjectParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanningProject: async (planMasterId: string, planningProjectId: string, updatePlanningProjectParam: UpdatePlanningProjectParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('updatePlanningProject', 'planMasterId', planMasterId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('updatePlanningProject', 'planningProjectId', planningProjectId)
            // verify required parameter 'updatePlanningProjectParam' is not null or undefined
            assertParamExists('updatePlanningProject', 'updatePlanningProjectParam', updatePlanningProjectParam)
            const localVarPath = `/api/plans/planmasters/{planMasterId}/planningProjects/{planningProjectId}`
                .replace(`{${"planMasterId"}}`, encodeURIComponent(String(planMasterId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlanningProjectParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanningProjectControllerApi - functional programming interface
 * @export
 */
export const PlanningProjectControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanningProjectControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 編成プロジェクトをアーカイブする
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archivedPlanningProject(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archivedPlanningProject(planMasterId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 編成プロジェクトのアーカイブを取消する
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelArchivedPlanningProject(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelArchivedPlanningProject(planMasterId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 編成プロジェクト削除
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlanningProject(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlanningProject(planMasterId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 編成プロジェクト編成完了
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixPlanningProject(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixPlanningProject(planMasterId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 編成プロジェクト最新値
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR'} aggregate 
         * @param {Array<string>} [departmentIds] 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} [showIn] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanningProject(planMasterId: string, planningProjectId: string, aggregate: 'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR', departmentIds?: Array<string>, showIn?: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', start?: string, end?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanningProjectView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanningProject(planMasterId, planningProjectId, aggregate, departmentIds, showIn, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 編成プロジェクト作成開始
         * @param {string} planMasterId 
         * @param {CreatePlanningProjectParam} createPlanningProjectParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startCreatingPlanningProject(planMasterId: string, createPlanningProjectParam: CreatePlanningProjectParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startCreatingPlanningProject(planMasterId, createPlanningProjectParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 編成プロジェクト編集
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {UpdatePlanningProjectParam} updatePlanningProjectParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlanningProject(planMasterId: string, planningProjectId: string, updatePlanningProjectParam: UpdatePlanningProjectParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlanningProject(planMasterId, planningProjectId, updatePlanningProjectParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanningProjectControllerApi - factory interface
 * @export
 */
export const PlanningProjectControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanningProjectControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 編成プロジェクトをアーカイブする
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivedPlanningProject(planMasterId: string, planningProjectId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.archivedPlanningProject(planMasterId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 編成プロジェクトのアーカイブを取消する
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelArchivedPlanningProject(planMasterId: string, planningProjectId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.cancelArchivedPlanningProject(planMasterId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 編成プロジェクト削除
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanningProject(planMasterId: string, planningProjectId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deletePlanningProject(planMasterId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 編成プロジェクト編成完了
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixPlanningProject(planMasterId: string, planningProjectId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.fixPlanningProject(planMasterId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 編成プロジェクト最新値
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR'} aggregate 
         * @param {Array<string>} [departmentIds] 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} [showIn] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanningProject(planMasterId: string, planningProjectId: string, aggregate: 'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR', departmentIds?: Array<string>, showIn?: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', start?: string, end?: string, options?: any): AxiosPromise<PlanningProjectView> {
            return localVarFp.getPlanningProject(planMasterId, planningProjectId, aggregate, departmentIds, showIn, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 編成プロジェクト作成開始
         * @param {string} planMasterId 
         * @param {CreatePlanningProjectParam} createPlanningProjectParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCreatingPlanningProject(planMasterId: string, createPlanningProjectParam: CreatePlanningProjectParam, options?: any): AxiosPromise<object> {
            return localVarFp.startCreatingPlanningProject(planMasterId, createPlanningProjectParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 編成プロジェクト編集
         * @param {string} planMasterId 
         * @param {string} planningProjectId 
         * @param {UpdatePlanningProjectParam} updatePlanningProjectParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanningProject(planMasterId: string, planningProjectId: string, updatePlanningProjectParam: UpdatePlanningProjectParam, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updatePlanningProject(planMasterId, planningProjectId, updatePlanningProjectParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanningProjectControllerApi - object-oriented interface
 * @export
 * @class PlanningProjectControllerApi
 * @extends {BaseAPI}
 */
export class PlanningProjectControllerApi extends BaseAPI {
    /**
     * 
     * @summary 編成プロジェクトをアーカイブする
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectControllerApi
     */
    public archivedPlanningProject(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return PlanningProjectControllerApiFp(this.configuration).archivedPlanningProject(planMasterId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 編成プロジェクトのアーカイブを取消する
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectControllerApi
     */
    public cancelArchivedPlanningProject(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return PlanningProjectControllerApiFp(this.configuration).cancelArchivedPlanningProject(planMasterId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 編成プロジェクト削除
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectControllerApi
     */
    public deletePlanningProject(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return PlanningProjectControllerApiFp(this.configuration).deletePlanningProject(planMasterId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 編成プロジェクト編成完了
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectControllerApi
     */
    public fixPlanningProject(planMasterId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return PlanningProjectControllerApiFp(this.configuration).fixPlanningProject(planMasterId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 編成プロジェクト最新値
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR'} aggregate 
     * @param {Array<string>} [departmentIds] 
     * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} [showIn] 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectControllerApi
     */
    public getPlanningProject(planMasterId: string, planningProjectId: string, aggregate: 'MONTH' | 'QUARTER' | 'FISCAL_YEAR' | 'FISCAL_HALF_YEAR', departmentIds?: Array<string>, showIn?: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', start?: string, end?: string, options?: AxiosRequestConfig) {
        return PlanningProjectControllerApiFp(this.configuration).getPlanningProject(planMasterId, planningProjectId, aggregate, departmentIds, showIn, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 編成プロジェクト作成開始
     * @param {string} planMasterId 
     * @param {CreatePlanningProjectParam} createPlanningProjectParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectControllerApi
     */
    public startCreatingPlanningProject(planMasterId: string, createPlanningProjectParam: CreatePlanningProjectParam, options?: AxiosRequestConfig) {
        return PlanningProjectControllerApiFp(this.configuration).startCreatingPlanningProject(planMasterId, createPlanningProjectParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 編成プロジェクト編集
     * @param {string} planMasterId 
     * @param {string} planningProjectId 
     * @param {UpdatePlanningProjectParam} updatePlanningProjectParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectControllerApi
     */
    public updatePlanningProject(planMasterId: string, planningProjectId: string, updatePlanningProjectParam: UpdatePlanningProjectParam, options?: AxiosRequestConfig) {
        return PlanningProjectControllerApiFp(this.configuration).updatePlanningProject(planMasterId, planningProjectId, updatePlanningProjectParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanningProjectDepartmentCommentsControllerApi - axios parameter creator
 * @export
 */
export const PlanningProjectDepartmentCommentsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 編成プロジェクト内の部署に紐づくコメントを作成する
         * @summary 編成プロジェクト部署コメント作成
         * @param {CreatePlanningProjectDepartmentCommentBody} createPlanningProjectDepartmentCommentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (createPlanningProjectDepartmentCommentBody: CreatePlanningProjectDepartmentCommentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlanningProjectDepartmentCommentBody' is not null or undefined
            assertParamExists('createComment', 'createPlanningProjectDepartmentCommentBody', createPlanningProjectDepartmentCommentBody)
            const localVarPath = `/api/planningProjectDepartmentComments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlanningProjectDepartmentCommentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 編成プロジェクト内の部署に紐づくコメントを削除する
         * @summary 編成プロジェクト部署コメント削除
         * @param {string} planningProjectDepartmentCommentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (planningProjectDepartmentCommentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectDepartmentCommentId' is not null or undefined
            assertParamExists('deleteComment', 'planningProjectDepartmentCommentId', planningProjectDepartmentCommentId)
            const localVarPath = `/api/planningProjectDepartmentComments/{planningProjectDepartmentCommentId}`
                .replace(`{${"planningProjectDepartmentCommentId"}}`, encodeURIComponent(String(planningProjectDepartmentCommentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 編成プロジェクト内の部署に紐づくコメントを一覧で取得する
         * @summary 編成プロジェクト部署コメント一覧
         * @param {string} planningProjectId 
         * @param {string} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComment: async (planningProjectId: string, departmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('listComment', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjectDepartmentComments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planningProjectId !== undefined) {
                localVarQueryParameter['planningProjectId'] = planningProjectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanningProjectDepartmentCommentsControllerApi - functional programming interface
 * @export
 */
export const PlanningProjectDepartmentCommentsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanningProjectDepartmentCommentsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 編成プロジェクト内の部署に紐づくコメントを作成する
         * @summary 編成プロジェクト部署コメント作成
         * @param {CreatePlanningProjectDepartmentCommentBody} createPlanningProjectDepartmentCommentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(createPlanningProjectDepartmentCommentBody: CreatePlanningProjectDepartmentCommentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(createPlanningProjectDepartmentCommentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 編成プロジェクト内の部署に紐づくコメントを削除する
         * @summary 編成プロジェクト部署コメント削除
         * @param {string} planningProjectDepartmentCommentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(planningProjectDepartmentCommentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(planningProjectDepartmentCommentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 編成プロジェクト内の部署に紐づくコメントを一覧で取得する
         * @summary 編成プロジェクト部署コメント一覧
         * @param {string} planningProjectId 
         * @param {string} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComment(planningProjectId: string, departmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanningProjectDepartmentCommentMapView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComment(planningProjectId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanningProjectDepartmentCommentsControllerApi - factory interface
 * @export
 */
export const PlanningProjectDepartmentCommentsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanningProjectDepartmentCommentsControllerApiFp(configuration)
    return {
        /**
         * 編成プロジェクト内の部署に紐づくコメントを作成する
         * @summary 編成プロジェクト部署コメント作成
         * @param {CreatePlanningProjectDepartmentCommentBody} createPlanningProjectDepartmentCommentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(createPlanningProjectDepartmentCommentBody: CreatePlanningProjectDepartmentCommentBody, options?: any): AxiosPromise<void> {
            return localVarFp.createComment(createPlanningProjectDepartmentCommentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 編成プロジェクト内の部署に紐づくコメントを削除する
         * @summary 編成プロジェクト部署コメント削除
         * @param {string} planningProjectDepartmentCommentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(planningProjectDepartmentCommentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteComment(planningProjectDepartmentCommentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 編成プロジェクト内の部署に紐づくコメントを一覧で取得する
         * @summary 編成プロジェクト部署コメント一覧
         * @param {string} planningProjectId 
         * @param {string} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComment(planningProjectId: string, departmentId?: string, options?: any): AxiosPromise<PlanningProjectDepartmentCommentMapView> {
            return localVarFp.listComment(planningProjectId, departmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanningProjectDepartmentCommentsControllerApi - object-oriented interface
 * @export
 * @class PlanningProjectDepartmentCommentsControllerApi
 * @extends {BaseAPI}
 */
export class PlanningProjectDepartmentCommentsControllerApi extends BaseAPI {
    /**
     * 編成プロジェクト内の部署に紐づくコメントを作成する
     * @summary 編成プロジェクト部署コメント作成
     * @param {CreatePlanningProjectDepartmentCommentBody} createPlanningProjectDepartmentCommentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectDepartmentCommentsControllerApi
     */
    public createComment(createPlanningProjectDepartmentCommentBody: CreatePlanningProjectDepartmentCommentBody, options?: AxiosRequestConfig) {
        return PlanningProjectDepartmentCommentsControllerApiFp(this.configuration).createComment(createPlanningProjectDepartmentCommentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 編成プロジェクト内の部署に紐づくコメントを削除する
     * @summary 編成プロジェクト部署コメント削除
     * @param {string} planningProjectDepartmentCommentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectDepartmentCommentsControllerApi
     */
    public deleteComment(planningProjectDepartmentCommentId: string, options?: AxiosRequestConfig) {
        return PlanningProjectDepartmentCommentsControllerApiFp(this.configuration).deleteComment(planningProjectDepartmentCommentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 編成プロジェクト内の部署に紐づくコメントを一覧で取得する
     * @summary 編成プロジェクト部署コメント一覧
     * @param {string} planningProjectId 
     * @param {string} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectDepartmentCommentsControllerApi
     */
    public listComment(planningProjectId: string, departmentId?: string, options?: AxiosRequestConfig) {
        return PlanningProjectDepartmentCommentsControllerApiFp(this.configuration).listComment(planningProjectId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanningProjectsControllerApi - axios parameter creator
 * @export
 */
export const PlanningProjectsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 編成プロジェクト一覧
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [isArchived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllPlanningProjects: async (limit?: number, offset?: number, isArchived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/plans/all-plannings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['isArchived'] = isArchived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanningProjectsControllerApi - functional programming interface
 * @export
 */
export const PlanningProjectsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanningProjectsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 編成プロジェクト一覧
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [isArchived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllPlanningProjects(limit?: number, offset?: number, isArchived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanningProjectListView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllPlanningProjects(limit, offset, isArchived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanningProjectsControllerApi - factory interface
 * @export
 */
export const PlanningProjectsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanningProjectsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 編成プロジェクト一覧
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [isArchived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllPlanningProjects(limit?: number, offset?: number, isArchived?: boolean, options?: any): AxiosPromise<PlanningProjectListView> {
            return localVarFp.listAllPlanningProjects(limit, offset, isArchived, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanningProjectsControllerApi - object-oriented interface
 * @export
 * @class PlanningProjectsControllerApi
 * @extends {BaseAPI}
 */
export class PlanningProjectsControllerApi extends BaseAPI {
    /**
     * 
     * @summary 編成プロジェクト一覧
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [isArchived] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningProjectsControllerApi
     */
    public listAllPlanningProjects(limit?: number, offset?: number, isArchived?: boolean, options?: AxiosRequestConfig) {
        return PlanningProjectsControllerApiFp(this.configuration).listAllPlanningProjects(limit, offset, isArchived, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlansControllerApi - axios parameter creator
 * @export
 */
export const PlansControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 計画マスタと編成プロジェクトの一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlansControllerApi - functional programming interface
 * @export
 */
export const PlansControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlansControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 計画マスタと編成プロジェクトの一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlans(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanMastersPlanningProjectsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlansControllerApi - factory interface
 * @export
 */
export const PlansControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlansControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 計画マスタと編成プロジェクトの一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlans(options?: any): AxiosPromise<PlanMastersPlanningProjectsView> {
            return localVarFp.listPlans(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlansControllerApi - object-oriented interface
 * @export
 * @class PlansControllerApi
 * @extends {BaseAPI}
 */
export class PlansControllerApi extends BaseAPI {
    /**
     * 
     * @summary 計画マスタと編成プロジェクトの一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansControllerApi
     */
    public listPlans(options?: AxiosRequestConfig) {
        return PlansControllerApiFp(this.configuration).listPlans(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProcessSettingControllerApi - axios parameter creator
 * @export
 */
export const ProcessSettingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 加工設定作成
         * @param {ProcessSettingParams} processSettingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcessSetting: async (processSettingParams: ProcessSettingParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processSettingParams' is not null or undefined
            assertParamExists('createProcessSetting', 'processSettingParams', processSettingParams)
            const localVarPath = `/api/processSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processSettingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 加工設定削除
         * @param {string} processSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcessSetting: async (processSettingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processSettingId' is not null or undefined
            assertParamExists('deleteProcessSetting', 'processSettingId', processSettingId)
            const localVarPath = `/api/processSettings/{processSettingId}`
                .replace(`{${"processSettingId"}}`, encodeURIComponent(String(processSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 加工設定
         * @param {string} processSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProcessSetting: async (processSettingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processSettingId' is not null or undefined
            assertParamExists('findProcessSetting', 'processSettingId', processSettingId)
            const localVarPath = `/api/processSettings/{processSettingId}`
                .replace(`{${"processSettingId"}}`, encodeURIComponent(String(processSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 加工設定一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProcessSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/processSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 加工前/後プレビュー取得
         * @param {File} file 
         * @param {ProcessSettingParams} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preview: async (file: File, params: ProcessSettingParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('preview', 'file', file)
            // verify required parameter 'params' is not null or undefined
            assertParamExists('preview', 'params', params)
            const localVarPath = `/api/processSettings/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (params !== undefined) { 
                localVarFormParams.append('params', new Blob([JSON.stringify(params)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 加工設定更新
         * @param {string} processSettingId 
         * @param {ProcessSettingParams} processSettingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcessSetting: async (processSettingId: string, processSettingParams: ProcessSettingParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processSettingId' is not null or undefined
            assertParamExists('updateProcessSetting', 'processSettingId', processSettingId)
            // verify required parameter 'processSettingParams' is not null or undefined
            assertParamExists('updateProcessSetting', 'processSettingParams', processSettingParams)
            const localVarPath = `/api/processSettings/{processSettingId}`
                .replace(`{${"processSettingId"}}`, encodeURIComponent(String(processSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processSettingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessSettingControllerApi - functional programming interface
 * @export
 */
export const ProcessSettingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcessSettingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 加工設定作成
         * @param {ProcessSettingParams} processSettingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProcessSetting(processSettingParams: ProcessSettingParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProcessSetting(processSettingParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 加工設定削除
         * @param {string} processSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProcessSetting(processSettingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProcessSetting(processSettingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 加工設定
         * @param {string} processSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProcessSetting(processSettingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindProcessSettingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProcessSetting(processSettingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 加工設定一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProcessSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProcessSettingView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProcessSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 加工前/後プレビュー取得
         * @param {File} file 
         * @param {ProcessSettingParams} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preview(file: File, params: ProcessSettingParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessSettingPreview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preview(file, params, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 加工設定更新
         * @param {string} processSettingId 
         * @param {ProcessSettingParams} processSettingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProcessSetting(processSettingId: string, processSettingParams: ProcessSettingParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProcessSetting(processSettingId, processSettingParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProcessSettingControllerApi - factory interface
 * @export
 */
export const ProcessSettingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcessSettingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 加工設定作成
         * @param {ProcessSettingParams} processSettingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcessSetting(processSettingParams: ProcessSettingParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createProcessSetting(processSettingParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 加工設定削除
         * @param {string} processSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcessSetting(processSettingId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteProcessSetting(processSettingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 加工設定
         * @param {string} processSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProcessSetting(processSettingId: string, options?: any): AxiosPromise<FindProcessSettingDto> {
            return localVarFp.findProcessSetting(processSettingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 加工設定一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProcessSettings(options?: any): AxiosPromise<Array<ProcessSettingView>> {
            return localVarFp.listProcessSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 加工前/後プレビュー取得
         * @param {File} file 
         * @param {ProcessSettingParams} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preview(file: File, params: ProcessSettingParams, options?: any): AxiosPromise<ProcessSettingPreview> {
            return localVarFp.preview(file, params, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 加工設定更新
         * @param {string} processSettingId 
         * @param {ProcessSettingParams} processSettingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcessSetting(processSettingId: string, processSettingParams: ProcessSettingParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateProcessSetting(processSettingId, processSettingParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessSettingControllerApi - object-oriented interface
 * @export
 * @class ProcessSettingControllerApi
 * @extends {BaseAPI}
 */
export class ProcessSettingControllerApi extends BaseAPI {
    /**
     * 
     * @summary 加工設定作成
     * @param {ProcessSettingParams} processSettingParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessSettingControllerApi
     */
    public createProcessSetting(processSettingParams: ProcessSettingParams, options?: AxiosRequestConfig) {
        return ProcessSettingControllerApiFp(this.configuration).createProcessSetting(processSettingParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 加工設定削除
     * @param {string} processSettingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessSettingControllerApi
     */
    public deleteProcessSetting(processSettingId: string, options?: AxiosRequestConfig) {
        return ProcessSettingControllerApiFp(this.configuration).deleteProcessSetting(processSettingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 加工設定
     * @param {string} processSettingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessSettingControllerApi
     */
    public findProcessSetting(processSettingId: string, options?: AxiosRequestConfig) {
        return ProcessSettingControllerApiFp(this.configuration).findProcessSetting(processSettingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 加工設定一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessSettingControllerApi
     */
    public listProcessSettings(options?: AxiosRequestConfig) {
        return ProcessSettingControllerApiFp(this.configuration).listProcessSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 加工前/後プレビュー取得
     * @param {File} file 
     * @param {ProcessSettingParams} params 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessSettingControllerApi
     */
    public preview(file: File, params: ProcessSettingParams, options?: AxiosRequestConfig) {
        return ProcessSettingControllerApiFp(this.configuration).preview(file, params, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 加工設定更新
     * @param {string} processSettingId 
     * @param {ProcessSettingParams} processSettingParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessSettingControllerApi
     */
    public updateProcessSetting(processSettingId: string, processSettingParams: ProcessSettingParams, options?: AxiosRequestConfig) {
        return ProcessSettingControllerApiFp(this.configuration).updateProcessSetting(processSettingId, processSettingParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportControllerApi - axios parameter creator
 * @export
 */
export const ReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary レポートをコピーする
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('copyReport', 'reportId', reportId)
            const localVarPath = `/api/reports/{reportId}/copy`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートを新規作成する
         * @param {CreateReportBody} createReportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (createReportBody: CreateReportBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReportBody' is not null or undefined
            assertParamExists('createReport', 'createReportBody', createReportBody)
            const localVarPath = `/api/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートをテンプレートを元に新規作成する
         * @param {CreateReportFromTemplateBody} createReportFromTemplateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportFromTemplate: async (createReportFromTemplateBody: CreateReportFromTemplateBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReportFromTemplateBody' is not null or undefined
            assertParamExists('createReportFromTemplate', 'createReportFromTemplateBody', createReportFromTemplateBody)
            const localVarPath = `/api/reports/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportFromTemplateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートを削除する
         * @param {string} reportId 
         * @param {DeleteReportRequestBody} deleteReportRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport: async (reportId: string, deleteReportRequestBody: DeleteReportRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReport', 'reportId', reportId)
            // verify required parameter 'deleteReportRequestBody' is not null or undefined
            assertParamExists('deleteReport', 'deleteReportRequestBody', deleteReportRequestBody)
            const localVarPath = `/api/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteReportRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートを取得する
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReport', 'reportId', reportId)
            const localVarPath = `/api/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートを指定したフォルダへ移動する
         * @param {string} reportId 
         * @param {MoveReportBody} moveReportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveReport: async (reportId: string, moveReportBody: MoveReportBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('moveReport', 'reportId', reportId)
            // verify required parameter 'moveReportBody' is not null or undefined
            assertParamExists('moveReport', 'moveReportBody', moveReportBody)
            const localVarPath = `/api/reports/{reportId}/move`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveReportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BSレポートの条件を更新する
         * @param {string} reportId 
         * @param {UpdateBSReportConditionBody} updateBSReportConditionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBSReportCondition: async (reportId: string, updateBSReportConditionBody: UpdateBSReportConditionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateBSReportCondition', 'reportId', reportId)
            // verify required parameter 'updateBSReportConditionBody' is not null or undefined
            assertParamExists('updateBSReportCondition', 'updateBSReportConditionBody', updateBSReportConditionBody)
            const localVarPath = `/api/reports/bs/{reportId}/condition`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBSReportConditionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PLレポートの条件を更新する
         * @param {string} reportId 
         * @param {UpdateReportConditionBody} updateReportConditionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportCondition: async (reportId: string, updateReportConditionBody: UpdateReportConditionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateReportCondition', 'reportId', reportId)
            // verify required parameter 'updateReportConditionBody' is not null or undefined
            assertParamExists('updateReportCondition', 'updateReportConditionBody', updateReportConditionBody)
            const localVarPath = `/api/reports/{reportId}/condition`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReportConditionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートの編集制限を更新する
         * @param {string} reportId 
         * @param {UpdateReportEditRestrictedBody} updateReportEditRestrictedBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportEditRestricted: async (reportId: string, updateReportEditRestrictedBody: UpdateReportEditRestrictedBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateReportEditRestricted', 'reportId', reportId)
            // verify required parameter 'updateReportEditRestrictedBody' is not null or undefined
            assertParamExists('updateReportEditRestricted', 'updateReportEditRestrictedBody', updateReportEditRestrictedBody)
            const localVarPath = `/api/reports/{reportId}/edit-restricted`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReportEditRestrictedBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートの名称を更新する
         * @param {string} reportId 
         * @param {UpdateReportNameBody} updateReportNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportName: async (reportId: string, updateReportNameBody: UpdateReportNameBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateReportName', 'reportId', reportId)
            // verify required parameter 'updateReportNameBody' is not null or undefined
            assertParamExists('updateReportName', 'updateReportNameBody', updateReportNameBody)
            const localVarPath = `/api/reports/{reportId}/name`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReportNameBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportControllerApi - functional programming interface
 * @export
 */
export const ReportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary レポートをコピーする
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートを新規作成する
         * @param {CreateReportBody} createReportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(createReportBody: CreateReportBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(createReportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートをテンプレートを元に新規作成する
         * @param {CreateReportFromTemplateBody} createReportFromTemplateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportFromTemplate(createReportFromTemplateBody: CreateReportFromTemplateBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportFromTemplate(createReportFromTemplateBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートを削除する
         * @param {string} reportId 
         * @param {DeleteReportRequestBody} deleteReportRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReport(reportId: string, deleteReportRequestBody: DeleteReportRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReport(reportId, deleteReportRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートを取得する
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートを指定したフォルダへ移動する
         * @param {string} reportId 
         * @param {MoveReportBody} moveReportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveReport(reportId: string, moveReportBody: MoveReportBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveReport(reportId, moveReportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BSレポートの条件を更新する
         * @param {string} reportId 
         * @param {UpdateBSReportConditionBody} updateBSReportConditionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBSReportCondition(reportId: string, updateBSReportConditionBody: UpdateBSReportConditionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBSReportCondition(reportId, updateBSReportConditionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PLレポートの条件を更新する
         * @param {string} reportId 
         * @param {UpdateReportConditionBody} updateReportConditionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportCondition(reportId: string, updateReportConditionBody: UpdateReportConditionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportCondition(reportId, updateReportConditionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートの編集制限を更新する
         * @param {string} reportId 
         * @param {UpdateReportEditRestrictedBody} updateReportEditRestrictedBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportEditRestricted(reportId: string, updateReportEditRestrictedBody: UpdateReportEditRestrictedBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportEditRestricted(reportId, updateReportEditRestrictedBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートの名称を更新する
         * @param {string} reportId 
         * @param {UpdateReportNameBody} updateReportNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportName(reportId: string, updateReportNameBody: UpdateReportNameBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportName(reportId, updateReportNameBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportControllerApi - factory interface
 * @export
 */
export const ReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary レポートをコピーする
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyReport(reportId: string, options?: any): AxiosPromise<ReportView> {
            return localVarFp.copyReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートを新規作成する
         * @param {CreateReportBody} createReportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(createReportBody: CreateReportBody, options?: any): AxiosPromise<ReportView> {
            return localVarFp.createReport(createReportBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートをテンプレートを元に新規作成する
         * @param {CreateReportFromTemplateBody} createReportFromTemplateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportFromTemplate(createReportFromTemplateBody: CreateReportFromTemplateBody, options?: any): AxiosPromise<ReportView> {
            return localVarFp.createReportFromTemplate(createReportFromTemplateBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートを削除する
         * @param {string} reportId 
         * @param {DeleteReportRequestBody} deleteReportRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(reportId: string, deleteReportRequestBody: DeleteReportRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteReport(reportId, deleteReportRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートを取得する
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(reportId: string, options?: any): AxiosPromise<ReportView> {
            return localVarFp.getReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートを指定したフォルダへ移動する
         * @param {string} reportId 
         * @param {MoveReportBody} moveReportBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveReport(reportId: string, moveReportBody: MoveReportBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.moveReport(reportId, moveReportBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BSレポートの条件を更新する
         * @param {string} reportId 
         * @param {UpdateBSReportConditionBody} updateBSReportConditionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBSReportCondition(reportId: string, updateBSReportConditionBody: UpdateBSReportConditionBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateBSReportCondition(reportId, updateBSReportConditionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PLレポートの条件を更新する
         * @param {string} reportId 
         * @param {UpdateReportConditionBody} updateReportConditionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportCondition(reportId: string, updateReportConditionBody: UpdateReportConditionBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateReportCondition(reportId, updateReportConditionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートの編集制限を更新する
         * @param {string} reportId 
         * @param {UpdateReportEditRestrictedBody} updateReportEditRestrictedBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportEditRestricted(reportId: string, updateReportEditRestrictedBody: UpdateReportEditRestrictedBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateReportEditRestricted(reportId, updateReportEditRestrictedBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートの名称を更新する
         * @param {string} reportId 
         * @param {UpdateReportNameBody} updateReportNameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportName(reportId: string, updateReportNameBody: UpdateReportNameBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateReportName(reportId, updateReportNameBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportControllerApi - object-oriented interface
 * @export
 * @class ReportControllerApi
 * @extends {BaseAPI}
 */
export class ReportControllerApi extends BaseAPI {
    /**
     * 
     * @summary レポートをコピーする
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public copyReport(reportId: string, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).copyReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートを新規作成する
     * @param {CreateReportBody} createReportBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public createReport(createReportBody: CreateReportBody, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).createReport(createReportBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートをテンプレートを元に新規作成する
     * @param {CreateReportFromTemplateBody} createReportFromTemplateBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public createReportFromTemplate(createReportFromTemplateBody: CreateReportFromTemplateBody, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).createReportFromTemplate(createReportFromTemplateBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートを削除する
     * @param {string} reportId 
     * @param {DeleteReportRequestBody} deleteReportRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public deleteReport(reportId: string, deleteReportRequestBody: DeleteReportRequestBody, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).deleteReport(reportId, deleteReportRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートを取得する
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public getReport(reportId: string, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).getReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートを指定したフォルダへ移動する
     * @param {string} reportId 
     * @param {MoveReportBody} moveReportBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public moveReport(reportId: string, moveReportBody: MoveReportBody, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).moveReport(reportId, moveReportBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BSレポートの条件を更新する
     * @param {string} reportId 
     * @param {UpdateBSReportConditionBody} updateBSReportConditionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public updateBSReportCondition(reportId: string, updateBSReportConditionBody: UpdateBSReportConditionBody, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).updateBSReportCondition(reportId, updateBSReportConditionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PLレポートの条件を更新する
     * @param {string} reportId 
     * @param {UpdateReportConditionBody} updateReportConditionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public updateReportCondition(reportId: string, updateReportConditionBody: UpdateReportConditionBody, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).updateReportCondition(reportId, updateReportConditionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートの編集制限を更新する
     * @param {string} reportId 
     * @param {UpdateReportEditRestrictedBody} updateReportEditRestrictedBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public updateReportEditRestricted(reportId: string, updateReportEditRestrictedBody: UpdateReportEditRestrictedBody, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).updateReportEditRestricted(reportId, updateReportEditRestrictedBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートの名称を更新する
     * @param {string} reportId 
     * @param {UpdateReportNameBody} updateReportNameBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public updateReportName(reportId: string, updateReportNameBody: UpdateReportNameBody, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).updateReportName(reportId, updateReportNameBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportDescriptionControllerApi - axios parameter creator
 * @export
 */
export const ReportDescriptionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary レポート備考を新規作成する
         * @param {string} reportId 
         * @param {CreateReportDescriptionBody} createReportDescriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportDescription: async (reportId: string, createReportDescriptionBody: CreateReportDescriptionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('createReportDescription', 'reportId', reportId)
            // verify required parameter 'createReportDescriptionBody' is not null or undefined
            assertParamExists('createReportDescription', 'createReportDescriptionBody', createReportDescriptionBody)
            const localVarPath = `/api/reports/{reportId}/description`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportDescriptionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポート備考を編集する
         * @param {string} reportId 
         * @param {EditReportDescriptionBody} editReportDescriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editReportDescription: async (reportId: string, editReportDescriptionBody: EditReportDescriptionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('editReportDescription', 'reportId', reportId)
            // verify required parameter 'editReportDescriptionBody' is not null or undefined
            assertParamExists('editReportDescription', 'editReportDescriptionBody', editReportDescriptionBody)
            const localVarPath = `/api/reports/{reportId}/description`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editReportDescriptionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポート備考を取得する
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReportDescription: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('findReportDescription', 'reportId', reportId)
            const localVarPath = `/api/reports/{reportId}/description`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportDescriptionControllerApi - functional programming interface
 * @export
 */
export const ReportDescriptionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportDescriptionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary レポート備考を新規作成する
         * @param {string} reportId 
         * @param {CreateReportDescriptionBody} createReportDescriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportDescription(reportId: string, createReportDescriptionBody: CreateReportDescriptionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportDescription(reportId, createReportDescriptionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポート備考を編集する
         * @param {string} reportId 
         * @param {EditReportDescriptionBody} editReportDescriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editReportDescription(reportId: string, editReportDescriptionBody: EditReportDescriptionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editReportDescription(reportId, editReportDescriptionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポート備考を取得する
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReportDescription(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDescriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReportDescription(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportDescriptionControllerApi - factory interface
 * @export
 */
export const ReportDescriptionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportDescriptionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary レポート備考を新規作成する
         * @param {string} reportId 
         * @param {CreateReportDescriptionBody} createReportDescriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportDescription(reportId: string, createReportDescriptionBody: CreateReportDescriptionBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createReportDescription(reportId, createReportDescriptionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポート備考を編集する
         * @param {string} reportId 
         * @param {EditReportDescriptionBody} editReportDescriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editReportDescription(reportId: string, editReportDescriptionBody: EditReportDescriptionBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.editReportDescription(reportId, editReportDescriptionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポート備考を取得する
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReportDescription(reportId: string, options?: any): AxiosPromise<ReportDescriptionView> {
            return localVarFp.findReportDescription(reportId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportDescriptionControllerApi - object-oriented interface
 * @export
 * @class ReportDescriptionControllerApi
 * @extends {BaseAPI}
 */
export class ReportDescriptionControllerApi extends BaseAPI {
    /**
     * 
     * @summary レポート備考を新規作成する
     * @param {string} reportId 
     * @param {CreateReportDescriptionBody} createReportDescriptionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportDescriptionControllerApi
     */
    public createReportDescription(reportId: string, createReportDescriptionBody: CreateReportDescriptionBody, options?: AxiosRequestConfig) {
        return ReportDescriptionControllerApiFp(this.configuration).createReportDescription(reportId, createReportDescriptionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポート備考を編集する
     * @param {string} reportId 
     * @param {EditReportDescriptionBody} editReportDescriptionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportDescriptionControllerApi
     */
    public editReportDescription(reportId: string, editReportDescriptionBody: EditReportDescriptionBody, options?: AxiosRequestConfig) {
        return ReportDescriptionControllerApiFp(this.configuration).editReportDescription(reportId, editReportDescriptionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポート備考を取得する
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportDescriptionControllerApi
     */
    public findReportDescription(reportId: string, options?: AxiosRequestConfig) {
        return ReportDescriptionControllerApiFp(this.configuration).findReportDescription(reportId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportFolderControllerApi - axios parameter creator
 * @export
 */
export const ReportFolderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary フォルダを新規作成する
         * @param {CreateReportFolderBody} createReportFolderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportFolder: async (createReportFolderBody: CreateReportFolderBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReportFolderBody' is not null or undefined
            assertParamExists('createReportFolder', 'createReportFolderBody', createReportFolderBody)
            const localVarPath = `/api/report_folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportFolderBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダを削除する
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportFolder: async (folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('deleteReportFolder', 'folderId', folderId)
            const localVarPath = `/api/report_folder/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダ内のフォルダ・レポート一覧を取得する
         * @param {string} [folderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderDetail: async (folderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report_folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダ情報一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderTreeForSelection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report_folder/for-selection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダを指定したフォルダへ移動する
         * @param {string} folderId 
         * @param {MoveReportFolderBody} moveReportFolderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveReportFolder: async (folderId: string, moveReportFolderBody: MoveReportFolderBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('moveReportFolder', 'folderId', folderId)
            // verify required parameter 'moveReportFolderBody' is not null or undefined
            assertParamExists('moveReportFolder', 'moveReportFolderBody', moveReportFolderBody)
            const localVarPath = `/api/report_folder/{folderId}/move`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveReportFolderBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダの名称を更新する
         * @param {string} folderId 
         * @param {RenameReportFolderBody} renameReportFolderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameReportFolder: async (folderId: string, renameReportFolderBody: RenameReportFolderBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('renameReportFolder', 'folderId', folderId)
            // verify required parameter 'renameReportFolderBody' is not null or undefined
            assertParamExists('renameReportFolder', 'renameReportFolderBody', renameReportFolderBody)
            const localVarPath = `/api/report_folder/{folderId}/name`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(renameReportFolderBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダ・レポートを検索する
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFolderDetail: async (searchWord?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report_folder/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchWord !== undefined) {
                localVarQueryParameter['searchWord'] = searchWord;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportFolderControllerApi - functional programming interface
 * @export
 */
export const ReportFolderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportFolderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary フォルダを新規作成する
         * @param {CreateReportFolderBody} createReportFolderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportFolder(createReportFolderBody: CreateReportFolderBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportFolder(createReportFolderBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダを削除する
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportFolder(folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportFolder(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダ内のフォルダ・レポート一覧を取得する
         * @param {string} [folderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolderDetail(folderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportFolderDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderDetail(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダ情報一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolderTreeForSelection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportFolderTreeForSelectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderTreeForSelection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダを指定したフォルダへ移動する
         * @param {string} folderId 
         * @param {MoveReportFolderBody} moveReportFolderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveReportFolder(folderId: string, moveReportFolderBody: MoveReportFolderBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveReportFolder(folderId, moveReportFolderBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダの名称を更新する
         * @param {string} folderId 
         * @param {RenameReportFolderBody} renameReportFolderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameReportFolder(folderId: string, renameReportFolderBody: RenameReportFolderBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameReportFolder(folderId, renameReportFolderBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダ・レポートを検索する
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFolderDetail(searchWord?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportFolderDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFolderDetail(searchWord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportFolderControllerApi - factory interface
 * @export
 */
export const ReportFolderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportFolderControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary フォルダを新規作成する
         * @param {CreateReportFolderBody} createReportFolderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportFolder(createReportFolderBody: CreateReportFolderBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createReportFolder(createReportFolderBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダを削除する
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportFolder(folderId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteReportFolder(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダ内のフォルダ・レポート一覧を取得する
         * @param {string} [folderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderDetail(folderId?: string, options?: any): AxiosPromise<ReportFolderDetailView> {
            return localVarFp.getFolderDetail(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダ情報一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderTreeForSelection(options?: any): AxiosPromise<ReportFolderTreeForSelectionView> {
            return localVarFp.getFolderTreeForSelection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダを指定したフォルダへ移動する
         * @param {string} folderId 
         * @param {MoveReportFolderBody} moveReportFolderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveReportFolder(folderId: string, moveReportFolderBody: MoveReportFolderBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.moveReportFolder(folderId, moveReportFolderBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダの名称を更新する
         * @param {string} folderId 
         * @param {RenameReportFolderBody} renameReportFolderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameReportFolder(folderId: string, renameReportFolderBody: RenameReportFolderBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.renameReportFolder(folderId, renameReportFolderBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダ・レポートを検索する
         * @param {string} [searchWord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFolderDetail(searchWord?: string, options?: any): AxiosPromise<ReportFolderDetailView> {
            return localVarFp.searchFolderDetail(searchWord, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportFolderControllerApi - object-oriented interface
 * @export
 * @class ReportFolderControllerApi
 * @extends {BaseAPI}
 */
export class ReportFolderControllerApi extends BaseAPI {
    /**
     * 
     * @summary フォルダを新規作成する
     * @param {CreateReportFolderBody} createReportFolderBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportFolderControllerApi
     */
    public createReportFolder(createReportFolderBody: CreateReportFolderBody, options?: AxiosRequestConfig) {
        return ReportFolderControllerApiFp(this.configuration).createReportFolder(createReportFolderBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダを削除する
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportFolderControllerApi
     */
    public deleteReportFolder(folderId: string, options?: AxiosRequestConfig) {
        return ReportFolderControllerApiFp(this.configuration).deleteReportFolder(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダ内のフォルダ・レポート一覧を取得する
     * @param {string} [folderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportFolderControllerApi
     */
    public getFolderDetail(folderId?: string, options?: AxiosRequestConfig) {
        return ReportFolderControllerApiFp(this.configuration).getFolderDetail(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダ情報一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportFolderControllerApi
     */
    public getFolderTreeForSelection(options?: AxiosRequestConfig) {
        return ReportFolderControllerApiFp(this.configuration).getFolderTreeForSelection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダを指定したフォルダへ移動する
     * @param {string} folderId 
     * @param {MoveReportFolderBody} moveReportFolderBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportFolderControllerApi
     */
    public moveReportFolder(folderId: string, moveReportFolderBody: MoveReportFolderBody, options?: AxiosRequestConfig) {
        return ReportFolderControllerApiFp(this.configuration).moveReportFolder(folderId, moveReportFolderBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダの名称を更新する
     * @param {string} folderId 
     * @param {RenameReportFolderBody} renameReportFolderBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportFolderControllerApi
     */
    public renameReportFolder(folderId: string, renameReportFolderBody: RenameReportFolderBody, options?: AxiosRequestConfig) {
        return ReportFolderControllerApiFp(this.configuration).renameReportFolder(folderId, renameReportFolderBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダ・レポートを検索する
     * @param {string} [searchWord] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportFolderControllerApi
     */
    public searchFolderDetail(searchWord?: string, options?: AxiosRequestConfig) {
        return ReportFolderControllerApiFp(this.configuration).searchFolderDetail(searchWord, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportTableControllerApi - axios parameter creator
 * @export
 */
export const ReportTableControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary BSレポートの表データをExcel形式で出力
         * @param {string} reportId 
         * @param {BSReportConditionSchema} bSReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBSReportExcel: async (reportId: string, bSReportConditionSchema: BSReportConditionSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getBSReportExcel', 'reportId', reportId)
            // verify required parameter 'bSReportConditionSchema' is not null or undefined
            assertParamExists('getBSReportExcel', 'bSReportConditionSchema', bSReportConditionSchema)
            const localVarPath = `/api/reports/bs/{reportId}/table/excel`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bSReportConditionSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BSレポートの表データを取得する
         * @param {string} reportId 
         * @param {BSReportConditionSchema} bSReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBSReportTable: async (reportId: string, bSReportConditionSchema: BSReportConditionSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getBSReportTable', 'reportId', reportId)
            // verify required parameter 'bSReportConditionSchema' is not null or undefined
            assertParamExists('getBSReportTable', 'bSReportConditionSchema', bSReportConditionSchema)
            const localVarPath = `/api/reports/bs/{reportId}/table`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bSReportConditionSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PLレポートの表データをExcel形式で出力
         * @param {string} reportId 
         * @param {PLReportConditionSchema} pLReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportExcel: async (reportId: string, pLReportConditionSchema: PLReportConditionSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportExcel', 'reportId', reportId)
            // verify required parameter 'pLReportConditionSchema' is not null or undefined
            assertParamExists('getReportExcel', 'pLReportConditionSchema', pLReportConditionSchema)
            const localVarPath = `/api/reports/{reportId}/table/excel`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pLReportConditionSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PLレポートの表データを取得する
         * @param {string} reportId 
         * @param {PLReportConditionSchema} pLReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTable: async (reportId: string, pLReportConditionSchema: PLReportConditionSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportTable', 'reportId', reportId)
            // verify required parameter 'pLReportConditionSchema' is not null or undefined
            assertParamExists('getReportTable', 'pLReportConditionSchema', pLReportConditionSchema)
            const localVarPath = `/api/reports/{reportId}/table`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pLReportConditionSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートの実績明細データを取得する
         * @param {string} reportId 
         * @param {GetReportTableDetailBody} getReportTableDetailBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTableActualDetail: async (reportId: string, getReportTableDetailBody: GetReportTableDetailBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportTableActualDetail', 'reportId', reportId)
            // verify required parameter 'getReportTableDetailBody' is not null or undefined
            assertParamExists('getReportTableActualDetail', 'getReportTableDetailBody', getReportTableDetailBody)
            const localVarPath = `/api/reports/{reportId}/table/detail`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getReportTableDetailBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートの計画明細データを取得する
         * @param {string} reportId 
         * @param {GetReportTableDetailBody} getReportTableDetailBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTablePlanDetail: async (reportId: string, getReportTableDetailBody: GetReportTableDetailBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportTablePlanDetail', 'reportId', reportId)
            // verify required parameter 'getReportTableDetailBody' is not null or undefined
            assertParamExists('getReportTablePlanDetail', 'getReportTableDetailBody', getReportTableDetailBody)
            const localVarPath = `/api/reports/{reportId}/table/detail/plan`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getReportTableDetailBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportTableControllerApi - functional programming interface
 * @export
 */
export const ReportTableControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportTableControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary BSレポートの表データをExcel形式で出力
         * @param {string} reportId 
         * @param {BSReportConditionSchema} bSReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBSReportExcel(reportId: string, bSReportConditionSchema: BSReportConditionSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBSReportExcel(reportId, bSReportConditionSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BSレポートの表データを取得する
         * @param {string} reportId 
         * @param {BSReportConditionSchema} bSReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBSReportTable(reportId: string, bSReportConditionSchema: BSReportConditionSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReportTableView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBSReportTable(reportId, bSReportConditionSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PLレポートの表データをExcel形式で出力
         * @param {string} reportId 
         * @param {PLReportConditionSchema} pLReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportExcel(reportId: string, pLReportConditionSchema: PLReportConditionSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportExcel(reportId, pLReportConditionSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PLレポートの表データを取得する
         * @param {string} reportId 
         * @param {PLReportConditionSchema} pLReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTable(reportId: string, pLReportConditionSchema: PLReportConditionSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReportTableView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTable(reportId, pLReportConditionSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートの実績明細データを取得する
         * @param {string} reportId 
         * @param {GetReportTableDetailBody} getReportTableDetailBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTableActualDetail(reportId: string, getReportTableDetailBody: GetReportTableDetailBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReportTableDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTableActualDetail(reportId, getReportTableDetailBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートの計画明細データを取得する
         * @param {string} reportId 
         * @param {GetReportTableDetailBody} getReportTableDetailBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTablePlanDetail(reportId: string, getReportTableDetailBody: GetReportTableDetailBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReportTableDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTablePlanDetail(reportId, getReportTableDetailBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportTableControllerApi - factory interface
 * @export
 */
export const ReportTableControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportTableControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary BSレポートの表データをExcel形式で出力
         * @param {string} reportId 
         * @param {BSReportConditionSchema} bSReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBSReportExcel(reportId: string, bSReportConditionSchema: BSReportConditionSchema, options?: any): AxiosPromise<File> {
            return localVarFp.getBSReportExcel(reportId, bSReportConditionSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BSレポートの表データを取得する
         * @param {string} reportId 
         * @param {BSReportConditionSchema} bSReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBSReportTable(reportId: string, bSReportConditionSchema: BSReportConditionSchema, options?: any): AxiosPromise<GetReportTableView> {
            return localVarFp.getBSReportTable(reportId, bSReportConditionSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PLレポートの表データをExcel形式で出力
         * @param {string} reportId 
         * @param {PLReportConditionSchema} pLReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportExcel(reportId: string, pLReportConditionSchema: PLReportConditionSchema, options?: any): AxiosPromise<File> {
            return localVarFp.getReportExcel(reportId, pLReportConditionSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PLレポートの表データを取得する
         * @param {string} reportId 
         * @param {PLReportConditionSchema} pLReportConditionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTable(reportId: string, pLReportConditionSchema: PLReportConditionSchema, options?: any): AxiosPromise<GetReportTableView> {
            return localVarFp.getReportTable(reportId, pLReportConditionSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートの実績明細データを取得する
         * @param {string} reportId 
         * @param {GetReportTableDetailBody} getReportTableDetailBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTableActualDetail(reportId: string, getReportTableDetailBody: GetReportTableDetailBody, options?: any): AxiosPromise<GetReportTableDetailView> {
            return localVarFp.getReportTableActualDetail(reportId, getReportTableDetailBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートの計画明細データを取得する
         * @param {string} reportId 
         * @param {GetReportTableDetailBody} getReportTableDetailBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTablePlanDetail(reportId: string, getReportTableDetailBody: GetReportTableDetailBody, options?: any): AxiosPromise<GetReportTableDetailView> {
            return localVarFp.getReportTablePlanDetail(reportId, getReportTableDetailBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportTableControllerApi - object-oriented interface
 * @export
 * @class ReportTableControllerApi
 * @extends {BaseAPI}
 */
export class ReportTableControllerApi extends BaseAPI {
    /**
     * 
     * @summary BSレポートの表データをExcel形式で出力
     * @param {string} reportId 
     * @param {BSReportConditionSchema} bSReportConditionSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTableControllerApi
     */
    public getBSReportExcel(reportId: string, bSReportConditionSchema: BSReportConditionSchema, options?: AxiosRequestConfig) {
        return ReportTableControllerApiFp(this.configuration).getBSReportExcel(reportId, bSReportConditionSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BSレポートの表データを取得する
     * @param {string} reportId 
     * @param {BSReportConditionSchema} bSReportConditionSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTableControllerApi
     */
    public getBSReportTable(reportId: string, bSReportConditionSchema: BSReportConditionSchema, options?: AxiosRequestConfig) {
        return ReportTableControllerApiFp(this.configuration).getBSReportTable(reportId, bSReportConditionSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PLレポートの表データをExcel形式で出力
     * @param {string} reportId 
     * @param {PLReportConditionSchema} pLReportConditionSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTableControllerApi
     */
    public getReportExcel(reportId: string, pLReportConditionSchema: PLReportConditionSchema, options?: AxiosRequestConfig) {
        return ReportTableControllerApiFp(this.configuration).getReportExcel(reportId, pLReportConditionSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PLレポートの表データを取得する
     * @param {string} reportId 
     * @param {PLReportConditionSchema} pLReportConditionSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTableControllerApi
     */
    public getReportTable(reportId: string, pLReportConditionSchema: PLReportConditionSchema, options?: AxiosRequestConfig) {
        return ReportTableControllerApiFp(this.configuration).getReportTable(reportId, pLReportConditionSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートの実績明細データを取得する
     * @param {string} reportId 
     * @param {GetReportTableDetailBody} getReportTableDetailBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTableControllerApi
     */
    public getReportTableActualDetail(reportId: string, getReportTableDetailBody: GetReportTableDetailBody, options?: AxiosRequestConfig) {
        return ReportTableControllerApiFp(this.configuration).getReportTableActualDetail(reportId, getReportTableDetailBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートの計画明細データを取得する
     * @param {string} reportId 
     * @param {GetReportTableDetailBody} getReportTableDetailBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTableControllerApi
     */
    public getReportTablePlanDetail(reportId: string, getReportTableDetailBody: GetReportTableDetailBody, options?: AxiosRequestConfig) {
        return ReportTableControllerApiFp(this.configuration).getReportTablePlanDetail(reportId, getReportTableDetailBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesControllerApi - axios parameter creator
 * @export
 */
export const RolesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ロール一覧
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles: async (name?: string, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パーミッションの割当
         * @param {string} roleId 
         * @param {SetPermissionsParams} setPermissionsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPermissions: async (roleId: string, setPermissionsParams: SetPermissionsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('setPermissions', 'roleId', roleId)
            // verify required parameter 'setPermissionsParams' is not null or undefined
            assertParamExists('setPermissions', 'setPermissionsParams', setPermissionsParams)
            const localVarPath = `/api/roles/{roleId}/permissions`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPermissionsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesControllerApi - functional programming interface
 * @export
 */
export const RolesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ロール一覧
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoles(name?: string, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRoles(name, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary パーミッションの割当
         * @param {string} roleId 
         * @param {SetPermissionsParams} setPermissionsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPermissions(roleId: string, setPermissionsParams: SetPermissionsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPermissions(roleId, setPermissionsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesControllerApi - factory interface
 * @export
 */
export const RolesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary ロール一覧
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(name?: string, description?: string, options?: any): AxiosPromise<Array<RoleView>> {
            return localVarFp.listRoles(name, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パーミッションの割当
         * @param {string} roleId 
         * @param {SetPermissionsParams} setPermissionsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPermissions(roleId: string, setPermissionsParams: SetPermissionsParams, options?: any): AxiosPromise<void> {
            return localVarFp.setPermissions(roleId, setPermissionsParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesControllerApi - object-oriented interface
 * @export
 * @class RolesControllerApi
 * @extends {BaseAPI}
 */
export class RolesControllerApi extends BaseAPI {
    /**
     * 
     * @summary ロール一覧
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public listRoles(name?: string, description?: string, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).listRoles(name, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パーミッションの割当
     * @param {string} roleId 
     * @param {SetPermissionsParams} setPermissionsParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public setPermissions(roleId: string, setPermissionsParams: SetPermissionsParams, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).setPermissions(roleId, setPermissionsParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingControllerApi - axios parameter creator
 * @export
 */
export const SettingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSettingsParam} updateSettingsParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (updateSettingsParam: UpdateSettingsParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSettingsParam' is not null or undefined
            assertParamExists('update', 'updateSettingsParam', updateSettingsParam)
            const localVarPath = `/api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSettingsParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingControllerApi - functional programming interface
 * @export
 */
export const SettingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSettingsParam} updateSettingsParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(updateSettingsParam: UpdateSettingsParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(updateSettingsParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingControllerApi - factory interface
 * @export
 */
export const SettingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): AxiosPromise<SettingsView> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSettingsParam} updateSettingsParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(updateSettingsParam: UpdateSettingsParam, options?: any): AxiosPromise<void> {
            return localVarFp.update(updateSettingsParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingControllerApi - object-oriented interface
 * @export
 * @class SettingControllerApi
 * @extends {BaseAPI}
 */
export class SettingControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public get(options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSettingsParam} updateSettingsParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public update(updateSettingsParam: UpdateSettingsParam, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).update(updateSettingsParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingExcelSheetProtectionControllerApi - axios parameter creator
 * @export
 */
export const SettingExcelSheetProtectionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 更新申請Excelフォーマットのシート保護パスワード取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings/excel-sheet-protection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingExcelSheetProtectionControllerApi - functional programming interface
 * @export
 */
export const SettingExcelSheetProtectionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingExcelSheetProtectionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 更新申請Excelフォーマットのシート保護パスワード取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsExcelSheetProtectionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingExcelSheetProtectionControllerApi - factory interface
 * @export
 */
export const SettingExcelSheetProtectionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingExcelSheetProtectionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 更新申請Excelフォーマットのシート保護パスワード取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1(options?: any): AxiosPromise<SettingsExcelSheetProtectionView> {
            return localVarFp.get1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingExcelSheetProtectionControllerApi - object-oriented interface
 * @export
 * @class SettingExcelSheetProtectionControllerApi
 * @extends {BaseAPI}
 */
export class SettingExcelSheetProtectionControllerApi extends BaseAPI {
    /**
     * 
     * @summary 更新申請Excelフォーマットのシート保護パスワード取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingExcelSheetProtectionControllerApi
     */
    public get1(options?: AxiosRequestConfig) {
        return SettingExcelSheetProtectionControllerApiFp(this.configuration).get1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SimulatingWorkforceSlotControllerApi - axios parameter creator
 * @export
 */
export const SimulatingWorkforceSlotControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} simulationId 
         * @param {BulkUpdateRemainingCountsBody} bulkUpdateRemainingCountsBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateRemainingCounts: async (simulationId: string, bulkUpdateRemainingCountsBody: BulkUpdateRemainingCountsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('bulkUpdateRemainingCounts', 'simulationId', simulationId)
            // verify required parameter 'bulkUpdateRemainingCountsBody' is not null or undefined
            assertParamExists('bulkUpdateRemainingCounts', 'bulkUpdateRemainingCountsBody', bulkUpdateRemainingCountsBody)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/simulating-slots/bulk-update-remaining-counts`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateRemainingCountsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {WorkforceSimulationSlotBody} workforceSimulationSlotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkforceSimulatingSlot: async (simulationId: string, workforceSimulationSlotBody: WorkforceSimulationSlotBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('createWorkforceSimulatingSlot', 'simulationId', simulationId)
            // verify required parameter 'workforceSimulationSlotBody' is not null or undefined
            assertParamExists('createWorkforceSimulatingSlot', 'workforceSimulationSlotBody', workforceSimulationSlotBody)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/simulating-slots/create`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workforceSimulationSlotBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {string} planningSimulationWorkforceSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforceSimulatingSlot: async (simulationId: string, planningSimulationWorkforceSlotId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('deleteWorkforceSimulatingSlot', 'simulationId', simulationId)
            // verify required parameter 'planningSimulationWorkforceSlotId' is not null or undefined
            assertParamExists('deleteWorkforceSimulatingSlot', 'planningSimulationWorkforceSlotId', planningSimulationWorkforceSlotId)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/simulating-slots/{planningSimulationWorkforceSlotId}/delete`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)))
                .replace(`{${"planningSimulationWorkforceSlotId"}}`, encodeURIComponent(String(planningSimulationWorkforceSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {string} simulatingSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforceSimulatingSlot: async (simulationId: string, simulatingSlotId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('getWorkforceSimulatingSlot', 'simulationId', simulationId)
            // verify required parameter 'simulatingSlotId' is not null or undefined
            assertParamExists('getWorkforceSimulatingSlot', 'simulatingSlotId', simulatingSlotId)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/simulating-slots/{simulatingSlotId}`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)))
                .replace(`{${"simulatingSlotId"}}`, encodeURIComponent(String(simulatingSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {string} simulatingSlotId 
         * @param {UpdateWorkforceSimulationSlotBody} updateWorkforceSimulationSlotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforceSimulatingSlot: async (simulationId: string, simulatingSlotId: string, updateWorkforceSimulationSlotBody: UpdateWorkforceSimulationSlotBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('updateWorkforceSimulatingSlot', 'simulationId', simulationId)
            // verify required parameter 'simulatingSlotId' is not null or undefined
            assertParamExists('updateWorkforceSimulatingSlot', 'simulatingSlotId', simulatingSlotId)
            // verify required parameter 'updateWorkforceSimulationSlotBody' is not null or undefined
            assertParamExists('updateWorkforceSimulatingSlot', 'updateWorkforceSimulationSlotBody', updateWorkforceSimulationSlotBody)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/simulating-slots/{simulatingSlotId}/update`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)))
                .replace(`{${"simulatingSlotId"}}`, encodeURIComponent(String(simulatingSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkforceSimulationSlotBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SimulatingWorkforceSlotControllerApi - functional programming interface
 * @export
 */
export const SimulatingWorkforceSlotControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SimulatingWorkforceSlotControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} simulationId 
         * @param {BulkUpdateRemainingCountsBody} bulkUpdateRemainingCountsBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateRemainingCounts(simulationId: string, bulkUpdateRemainingCountsBody: BulkUpdateRemainingCountsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateRemainingCounts(simulationId, bulkUpdateRemainingCountsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {WorkforceSimulationSlotBody} workforceSimulationSlotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkforceSimulatingSlot(simulationId: string, workforceSimulationSlotBody: WorkforceSimulationSlotBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkforceSimulatingSlot(simulationId, workforceSimulationSlotBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {string} planningSimulationWorkforceSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkforceSimulatingSlot(simulationId: string, planningSimulationWorkforceSlotId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkforceSimulatingSlot(simulationId, planningSimulationWorkforceSlotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {string} simulatingSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforceSimulatingSlot(simulationId: string, simulatingSlotId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforceSimulatingSlotView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforceSimulatingSlot(simulationId, simulatingSlotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {string} simulatingSlotId 
         * @param {UpdateWorkforceSimulationSlotBody} updateWorkforceSimulationSlotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkforceSimulatingSlot(simulationId: string, simulatingSlotId: string, updateWorkforceSimulationSlotBody: UpdateWorkforceSimulationSlotBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkforceSimulatingSlot(simulationId, simulatingSlotId, updateWorkforceSimulationSlotBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SimulatingWorkforceSlotControllerApi - factory interface
 * @export
 */
export const SimulatingWorkforceSlotControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SimulatingWorkforceSlotControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} simulationId 
         * @param {BulkUpdateRemainingCountsBody} bulkUpdateRemainingCountsBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateRemainingCounts(simulationId: string, bulkUpdateRemainingCountsBody: BulkUpdateRemainingCountsBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.bulkUpdateRemainingCounts(simulationId, bulkUpdateRemainingCountsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {WorkforceSimulationSlotBody} workforceSimulationSlotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkforceSimulatingSlot(simulationId: string, workforceSimulationSlotBody: WorkforceSimulationSlotBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createWorkforceSimulatingSlot(simulationId, workforceSimulationSlotBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {string} planningSimulationWorkforceSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforceSimulatingSlot(simulationId: string, planningSimulationWorkforceSlotId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteWorkforceSimulatingSlot(simulationId, planningSimulationWorkforceSlotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {string} simulatingSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforceSimulatingSlot(simulationId: string, simulatingSlotId: string, options?: any): AxiosPromise<WorkforceSimulatingSlotView> {
            return localVarFp.getWorkforceSimulatingSlot(simulationId, simulatingSlotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {string} simulatingSlotId 
         * @param {UpdateWorkforceSimulationSlotBody} updateWorkforceSimulationSlotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforceSimulatingSlot(simulationId: string, simulatingSlotId: string, updateWorkforceSimulationSlotBody: UpdateWorkforceSimulationSlotBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateWorkforceSimulatingSlot(simulationId, simulatingSlotId, updateWorkforceSimulationSlotBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SimulatingWorkforceSlotControllerApi - object-oriented interface
 * @export
 * @class SimulatingWorkforceSlotControllerApi
 * @extends {BaseAPI}
 */
export class SimulatingWorkforceSlotControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} simulationId 
     * @param {BulkUpdateRemainingCountsBody} bulkUpdateRemainingCountsBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatingWorkforceSlotControllerApi
     */
    public bulkUpdateRemainingCounts(simulationId: string, bulkUpdateRemainingCountsBody: BulkUpdateRemainingCountsBody, options?: AxiosRequestConfig) {
        return SimulatingWorkforceSlotControllerApiFp(this.configuration).bulkUpdateRemainingCounts(simulationId, bulkUpdateRemainingCountsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {WorkforceSimulationSlotBody} workforceSimulationSlotBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatingWorkforceSlotControllerApi
     */
    public createWorkforceSimulatingSlot(simulationId: string, workforceSimulationSlotBody: WorkforceSimulationSlotBody, options?: AxiosRequestConfig) {
        return SimulatingWorkforceSlotControllerApiFp(this.configuration).createWorkforceSimulatingSlot(simulationId, workforceSimulationSlotBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {string} planningSimulationWorkforceSlotId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatingWorkforceSlotControllerApi
     */
    public deleteWorkforceSimulatingSlot(simulationId: string, planningSimulationWorkforceSlotId: string, options?: AxiosRequestConfig) {
        return SimulatingWorkforceSlotControllerApiFp(this.configuration).deleteWorkforceSimulatingSlot(simulationId, planningSimulationWorkforceSlotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {string} simulatingSlotId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatingWorkforceSlotControllerApi
     */
    public getWorkforceSimulatingSlot(simulationId: string, simulatingSlotId: string, options?: AxiosRequestConfig) {
        return SimulatingWorkforceSlotControllerApiFp(this.configuration).getWorkforceSimulatingSlot(simulationId, simulatingSlotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {string} simulatingSlotId 
     * @param {UpdateWorkforceSimulationSlotBody} updateWorkforceSimulationSlotBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatingWorkforceSlotControllerApi
     */
    public updateWorkforceSimulatingSlot(simulationId: string, simulatingSlotId: string, updateWorkforceSimulationSlotBody: UpdateWorkforceSimulationSlotBody, options?: AxiosRequestConfig) {
        return SimulatingWorkforceSlotControllerApiFp(this.configuration).updateWorkforceSimulatingSlot(simulationId, simulatingSlotId, updateWorkforceSimulationSlotBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SimulationWorkforcePlanUpdateRequestControllerApi - axios parameter creator
 * @export
 */
export const SimulationWorkforcePlanUpdateRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetDepartmentIds: async (simulationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('getTargetDepartmentIds', 'simulationId', simulationId)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/target-departments`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetPlanningProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-plannings/simulations/target-planning-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {SimulationPlanUpdateRequestBody} simulationPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPlanUpdateRequestFromSimulation: async (simulationId: string, simulationPlanUpdateRequestBody: SimulationPlanUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('submitPlanUpdateRequestFromSimulation', 'simulationId', simulationId)
            // verify required parameter 'simulationPlanUpdateRequestBody' is not null or undefined
            assertParamExists('submitPlanUpdateRequestFromSimulation', 'simulationPlanUpdateRequestBody', simulationPlanUpdateRequestBody)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/workforce-plan-update-requests/submit`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simulationPlanUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SimulationWorkforcePlanUpdateRequestControllerApi - functional programming interface
 * @export
 */
export const SimulationWorkforcePlanUpdateRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SimulationWorkforcePlanUpdateRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargetDepartmentIds(simulationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenWorkforcePlanUpdateRequestTargetDepartments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargetDepartmentIds(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargetPlanningProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenWorkforcePlanUpdateRequestTargetPlanningProjects>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargetPlanningProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {SimulationPlanUpdateRequestBody} simulationPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitPlanUpdateRequestFromSimulation(simulationId: string, simulationPlanUpdateRequestBody: SimulationPlanUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitPlanUpdateRequestFromSimulation(simulationId, simulationPlanUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SimulationWorkforcePlanUpdateRequestControllerApi - factory interface
 * @export
 */
export const SimulationWorkforcePlanUpdateRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SimulationWorkforcePlanUpdateRequestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetDepartmentIds(simulationId: string, options?: any): AxiosPromise<OpenWorkforcePlanUpdateRequestTargetDepartments> {
            return localVarFp.getTargetDepartmentIds(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetPlanningProjects(options?: any): AxiosPromise<OpenWorkforcePlanUpdateRequestTargetPlanningProjects> {
            return localVarFp.getTargetPlanningProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {SimulationPlanUpdateRequestBody} simulationPlanUpdateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPlanUpdateRequestFromSimulation(simulationId: string, simulationPlanUpdateRequestBody: SimulationPlanUpdateRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.submitPlanUpdateRequestFromSimulation(simulationId, simulationPlanUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SimulationWorkforcePlanUpdateRequestControllerApi - object-oriented interface
 * @export
 * @class SimulationWorkforcePlanUpdateRequestControllerApi
 * @extends {BaseAPI}
 */
export class SimulationWorkforcePlanUpdateRequestControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulationWorkforcePlanUpdateRequestControllerApi
     */
    public getTargetDepartmentIds(simulationId: string, options?: AxiosRequestConfig) {
        return SimulationWorkforcePlanUpdateRequestControllerApiFp(this.configuration).getTargetDepartmentIds(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulationWorkforcePlanUpdateRequestControllerApi
     */
    public getTargetPlanningProjects(options?: AxiosRequestConfig) {
        return SimulationWorkforcePlanUpdateRequestControllerApiFp(this.configuration).getTargetPlanningProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {SimulationPlanUpdateRequestBody} simulationPlanUpdateRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulationWorkforcePlanUpdateRequestControllerApi
     */
    public submitPlanUpdateRequestFromSimulation(simulationId: string, simulationPlanUpdateRequestBody: SimulationPlanUpdateRequestBody, options?: AxiosRequestConfig) {
        return SimulationWorkforcePlanUpdateRequestControllerApiFp(this.configuration).submitPlanUpdateRequestFromSimulation(simulationId, simulationPlanUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantsControllerApi - axios parameter creator
 * @export
 */
export const TenantsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary テナントの情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsControllerApi - functional programming interface
 * @export
 */
export const TenantsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary テナントの情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantsControllerApi - factory interface
 * @export
 */
export const TenantsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary テナントの情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant(options?: any): AxiosPromise<TenantView> {
            return localVarFp.getTenant(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantsControllerApi - object-oriented interface
 * @export
 * @class TenantsControllerApi
 * @extends {BaseAPI}
 */
export class TenantsControllerApi extends BaseAPI {
    /**
     * 
     * @summary テナントの情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsControllerApi
     */
    public getTenant(options?: AxiosRequestConfig) {
        return TenantsControllerApiFp(this.configuration).getTenant(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransferStepControllerApi - axios parameter creator
 * @export
 */
export const TransferStepControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 振替ステップの作成
         * @param {string} ruleId 
         * @param {CreateTransferStepRequestBody} createTransferStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransferStep: async (ruleId: string, createTransferStepRequestBody: CreateTransferStepRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('createTransferStep', 'ruleId', ruleId)
            // verify required parameter 'createTransferStepRequestBody' is not null or undefined
            assertParamExists('createTransferStep', 'createTransferStepRequestBody', createTransferStepRequestBody)
            const localVarPath = `/api/allocations/rules/{ruleId}/transfer-steps`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransferStepRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替ステップの取得
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTransferStep: async (ruleId: string, stepId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('findTransferStep', 'ruleId', ruleId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('findTransferStep', 'stepId', stepId)
            const localVarPath = `/api/allocations/rules/{ruleId}/transfer-steps/{stepId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 振替ステップの更新
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {UpdateTransferStepRequestBody} updateTransferStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransferStep: async (ruleId: string, stepId: string, updateTransferStepRequestBody: UpdateTransferStepRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('updateTransferStep', 'ruleId', ruleId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('updateTransferStep', 'stepId', stepId)
            // verify required parameter 'updateTransferStepRequestBody' is not null or undefined
            assertParamExists('updateTransferStep', 'updateTransferStepRequestBody', updateTransferStepRequestBody)
            const localVarPath = `/api/allocations/rules/{ruleId}/transfer-steps/{stepId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTransferStepRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransferStepControllerApi - functional programming interface
 * @export
 */
export const TransferStepControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransferStepControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 振替ステップの作成
         * @param {string} ruleId 
         * @param {CreateTransferStepRequestBody} createTransferStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransferStep(ruleId: string, createTransferStepRequestBody: CreateTransferStepRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransferStep(ruleId, createTransferStepRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替ステップの取得
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTransferStep(ruleId: string, stepId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferStepView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTransferStep(ruleId, stepId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 振替ステップの更新
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {UpdateTransferStepRequestBody} updateTransferStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransferStep(ruleId: string, stepId: string, updateTransferStepRequestBody: UpdateTransferStepRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransferStep(ruleId, stepId, updateTransferStepRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransferStepControllerApi - factory interface
 * @export
 */
export const TransferStepControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransferStepControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 振替ステップの作成
         * @param {string} ruleId 
         * @param {CreateTransferStepRequestBody} createTransferStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransferStep(ruleId: string, createTransferStepRequestBody: CreateTransferStepRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createTransferStep(ruleId, createTransferStepRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替ステップの取得
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTransferStep(ruleId: string, stepId: string, options?: any): AxiosPromise<TransferStepView> {
            return localVarFp.findTransferStep(ruleId, stepId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 振替ステップの更新
         * @param {string} ruleId 
         * @param {string} stepId 
         * @param {UpdateTransferStepRequestBody} updateTransferStepRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransferStep(ruleId: string, stepId: string, updateTransferStepRequestBody: UpdateTransferStepRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateTransferStep(ruleId, stepId, updateTransferStepRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransferStepControllerApi - object-oriented interface
 * @export
 * @class TransferStepControllerApi
 * @extends {BaseAPI}
 */
export class TransferStepControllerApi extends BaseAPI {
    /**
     * 
     * @summary 振替ステップの作成
     * @param {string} ruleId 
     * @param {CreateTransferStepRequestBody} createTransferStepRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferStepControllerApi
     */
    public createTransferStep(ruleId: string, createTransferStepRequestBody: CreateTransferStepRequestBody, options?: AxiosRequestConfig) {
        return TransferStepControllerApiFp(this.configuration).createTransferStep(ruleId, createTransferStepRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替ステップの取得
     * @param {string} ruleId 
     * @param {string} stepId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferStepControllerApi
     */
    public findTransferStep(ruleId: string, stepId: string, options?: AxiosRequestConfig) {
        return TransferStepControllerApiFp(this.configuration).findTransferStep(ruleId, stepId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 振替ステップの更新
     * @param {string} ruleId 
     * @param {string} stepId 
     * @param {UpdateTransferStepRequestBody} updateTransferStepRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferStepControllerApi
     */
    public updateTransferStep(ruleId: string, stepId: string, updateTransferStepRequestBody: UpdateTransferStepRequestBody, options?: AxiosRequestConfig) {
        return TransferStepControllerApiFp(this.configuration).updateTransferStep(ruleId, stepId, updateTransferStepRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnconnectedToSlotsPeopleControllerApi - axios parameter creator
 * @export
 */
export const UnconnectedToSlotsPeopleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 人員の実績イベントに枠を紐づける
         * @param {ConnectSlotsToActualChangeEventsBody} connectSlotsToActualChangeEventsBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectWorkforceSlotsToPeople: async (connectSlotsToActualChangeEventsBody: ConnectSlotsToActualChangeEventsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectSlotsToActualChangeEventsBody' is not null or undefined
            assertParamExists('connectWorkforceSlotsToPeople', 'connectSlotsToActualChangeEventsBody', connectSlotsToActualChangeEventsBody)
            const localVarPath = `/api/workforce-planning/unconnected-to-slots-people/connect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectSlotsToActualChangeEventsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 採用枠に紐づいていない人員実績一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnconnectedToSlotsPeople: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/unconnected-to-slots-people/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnconnectedToSlotsPeopleControllerApi - functional programming interface
 * @export
 */
export const UnconnectedToSlotsPeopleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnconnectedToSlotsPeopleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 人員の実績イベントに枠を紐づける
         * @param {ConnectSlotsToActualChangeEventsBody} connectSlotsToActualChangeEventsBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectWorkforceSlotsToPeople(connectSlotsToActualChangeEventsBody: ConnectSlotsToActualChangeEventsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectWorkforceSlotsToPeople(connectSlotsToActualChangeEventsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 採用枠に紐づいていない人員実績一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnconnectedToSlotsPeople(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnconnectedToSlotPersonView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnconnectedToSlotsPeople(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnconnectedToSlotsPeopleControllerApi - factory interface
 * @export
 */
export const UnconnectedToSlotsPeopleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnconnectedToSlotsPeopleControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 人員の実績イベントに枠を紐づける
         * @param {ConnectSlotsToActualChangeEventsBody} connectSlotsToActualChangeEventsBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectWorkforceSlotsToPeople(connectSlotsToActualChangeEventsBody: ConnectSlotsToActualChangeEventsBody, options?: any): AxiosPromise<void> {
            return localVarFp.connectWorkforceSlotsToPeople(connectSlotsToActualChangeEventsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 採用枠に紐づいていない人員実績一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnconnectedToSlotsPeople(options?: any): AxiosPromise<Array<UnconnectedToSlotPersonView>> {
            return localVarFp.listUnconnectedToSlotsPeople(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnconnectedToSlotsPeopleControllerApi - object-oriented interface
 * @export
 * @class UnconnectedToSlotsPeopleControllerApi
 * @extends {BaseAPI}
 */
export class UnconnectedToSlotsPeopleControllerApi extends BaseAPI {
    /**
     * 
     * @summary 人員の実績イベントに枠を紐づける
     * @param {ConnectSlotsToActualChangeEventsBody} connectSlotsToActualChangeEventsBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnconnectedToSlotsPeopleControllerApi
     */
    public connectWorkforceSlotsToPeople(connectSlotsToActualChangeEventsBody: ConnectSlotsToActualChangeEventsBody, options?: AxiosRequestConfig) {
        return UnconnectedToSlotsPeopleControllerApiFp(this.configuration).connectWorkforceSlotsToPeople(connectSlotsToActualChangeEventsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 採用枠に紐づいていない人員実績一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnconnectedToSlotsPeopleControllerApi
     */
    public listUnconnectedToSlotsPeople(options?: AxiosRequestConfig) {
        return UnconnectedToSlotsPeopleControllerApiFp(this.configuration).listUnconnectedToSlotsPeople(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnitAmountControllerApi - axios parameter creator
 * @export
 */
export const UnitAmountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 人件費単価の一括登録・更新をするフォーマットをダウンロードする
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUnitAmountExcel: async (yearMonth: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('downloadUnitAmountExcel', 'yearMonth', yearMonth)
            const localVarPath = `/api/workforce-planning/unit-amount/download/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人件費単価一覧を取得する
         * @param {string} yearMonth 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitAmount: async (yearMonth: string, limit: number, offset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('listUnitAmount', 'yearMonth', yearMonth)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('listUnitAmount', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('listUnitAmount', 'offset', offset)
            const localVarPath = `/api/workforce-planning/unit-amount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定した年月の単価が設定されていない人員のリストを取得する
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforcesWithoutUnitAmount: async (yearMonth: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('listWorkforcesWithoutUnitAmount', 'yearMonth', yearMonth)
            const localVarPath = `/api/workforce-planning/unit-amount/workforces-without-unit-amount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人件費単価の一括登録/更新の Excel ファイルをアップロードする
         * @param {string} yearMonth 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUnitAmountExcel: async (yearMonth: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('uploadUnitAmountExcel', 'yearMonth', yearMonth)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadUnitAmountExcel', 'file', file)
            const localVarPath = `/api/workforce-planning/unit-amount/upload/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitAmountControllerApi - functional programming interface
 * @export
 */
export const UnitAmountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitAmountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 人件費単価の一括登録・更新をするフォーマットをダウンロードする
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadUnitAmountExcel(yearMonth: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadUnitAmountExcel(yearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人件費単価一覧を取得する
         * @param {string} yearMonth 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnitAmount(yearMonth: string, limit: number, offset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoUnitAmountListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnitAmount(yearMonth, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定した年月の単価が設定されていない人員のリストを取得する
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkforcesWithoutUnitAmount(yearMonth: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforceWithoutUnitAmountItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkforcesWithoutUnitAmount(yearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人件費単価の一括登録/更新の Excel ファイルをアップロードする
         * @param {string} yearMonth 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadUnitAmountExcel(yearMonth: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUnitAmountExcel(yearMonth, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitAmountControllerApi - factory interface
 * @export
 */
export const UnitAmountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitAmountControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 人件費単価の一括登録・更新をするフォーマットをダウンロードする
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUnitAmountExcel(yearMonth: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadUnitAmountExcel(yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人件費単価一覧を取得する
         * @param {string} yearMonth 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitAmount(yearMonth: string, limit: number, offset: number, options?: any): AxiosPromise<PageDtoUnitAmountListItem> {
            return localVarFp.listUnitAmount(yearMonth, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定した年月の単価が設定されていない人員のリストを取得する
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforcesWithoutUnitAmount(yearMonth: string, options?: any): AxiosPromise<WorkforceWithoutUnitAmountItems> {
            return localVarFp.listWorkforcesWithoutUnitAmount(yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人件費単価の一括登録/更新の Excel ファイルをアップロードする
         * @param {string} yearMonth 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUnitAmountExcel(yearMonth: string, file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadUnitAmountExcel(yearMonth, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnitAmountControllerApi - object-oriented interface
 * @export
 * @class UnitAmountControllerApi
 * @extends {BaseAPI}
 */
export class UnitAmountControllerApi extends BaseAPI {
    /**
     * 
     * @summary 人件費単価の一括登録・更新をするフォーマットをダウンロードする
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitAmountControllerApi
     */
    public downloadUnitAmountExcel(yearMonth: string, options?: AxiosRequestConfig) {
        return UnitAmountControllerApiFp(this.configuration).downloadUnitAmountExcel(yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人件費単価一覧を取得する
     * @param {string} yearMonth 
     * @param {number} limit 
     * @param {number} offset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitAmountControllerApi
     */
    public listUnitAmount(yearMonth: string, limit: number, offset: number, options?: AxiosRequestConfig) {
        return UnitAmountControllerApiFp(this.configuration).listUnitAmount(yearMonth, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定した年月の単価が設定されていない人員のリストを取得する
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitAmountControllerApi
     */
    public listWorkforcesWithoutUnitAmount(yearMonth: string, options?: AxiosRequestConfig) {
        return UnitAmountControllerApiFp(this.configuration).listWorkforcesWithoutUnitAmount(yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人件費単価の一括登録/更新の Excel ファイルをアップロードする
     * @param {string} yearMonth 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitAmountControllerApi
     */
    public uploadUnitAmountExcel(yearMonth: string, file: File, options?: AxiosRequestConfig) {
        return UnitAmountControllerApiFp(this.configuration).uploadUnitAmountExcel(yearMonth, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ユーザーを再登録する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('activateUser', 'userId', userId)
            const localVarPath = `/api/users/{userId}/activate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 契約ユーザー数とアクティブユーザー数の一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countActiveUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/api/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー一括追加Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBulkCreateUsersExcel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー一覧Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsersListExcel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/list/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログイン中のユーザー情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー情報を返却する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRole: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserRole', 'userId', userId)
            const localVarPath = `/api/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを招待する
         * @param {CreateUserParam} createUserParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: async (createUserParam: CreateUserParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserParam' is not null or undefined
            assertParamExists('inviteUser', 'createUserParam', createUserParam)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーのGoogleアカウントをリンクする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkWithGoogleAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ソーシャルログインの状況を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSocialLogins: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー一覧
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [isActive] 
         * @param {string} [nameOrEmailOrAssignments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (limit?: number, offset?: number, isActive?: boolean, nameOrEmailOrAssignments?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (nameOrEmailOrAssignments !== undefined) {
                localVarQueryParameter['nameOrEmailOrAssignments'] = nameOrEmailOrAssignments;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーにロールを割り当てる
         * @param {string} userId 
         * @param {SetRolesParams} setRolesParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoles: async (userId: string, setRolesParams: SetRolesParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setRoles', 'userId', userId)
            // verify required parameter 'setRolesParams' is not null or undefined
            assertParamExists('setRoles', 'setRolesParams', setRolesParams)
            const localVarPath = `/api/users/{userId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setRolesParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを更新する
         * @param {string} userId 
         * @param {EditUserParam} editUserParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRole: async (userId: string, editUserParam: EditUserParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserRole', 'userId', userId)
            // verify required parameter 'editUserParam' is not null or undefined
            assertParamExists('updateUserRole', 'editUserParam', editUserParam)
            const localVarPath = `/api/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUserParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー一括追加Excelのアップロード
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBulkCreateUsersExcel: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadBulkCreateUsersExcel', 'file', file)
            const localVarPath = `/api/users/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ユーザーを再登録する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 契約ユーザー数とアクティブユーザー数の一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countActiveUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractUserTypeAndActiveUserCountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countActiveUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー一括追加Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadBulkCreateUsersExcel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadBulkCreateUsersExcel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー一覧Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadUsersListExcel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadUsersListExcel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ログイン中のユーザー情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー情報を返却する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRole(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRole(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを招待する
         * @param {CreateUserParam} createUserParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteUser(createUserParam: CreateUserParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUser(createUserParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーのGoogleアカウントをリンクする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkWithGoogleAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkWithGoogleAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ソーシャルログインの状況を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSocialLogins(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialLoginsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSocialLogins(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー一覧
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [isActive] 
         * @param {string} [nameOrEmailOrAssignments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(limit?: number, offset?: number, isActive?: boolean, nameOrEmailOrAssignments?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoUserWithDepartmentNamesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(limit, offset, isActive, nameOrEmailOrAssignments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーにロールを割り当てる
         * @param {string} userId 
         * @param {SetRolesParams} setRolesParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRoles(userId: string, setRolesParams: SetRolesParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRoles(userId, setRolesParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを更新する
         * @param {string} userId 
         * @param {EditUserParam} editUserParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRole(userId: string, editUserParam: EditUserParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRole(userId, editUserParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー一括追加Excelのアップロード
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBulkCreateUsersExcel(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBulkCreateUsersExcel(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary ユーザーを再登録する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser(userId: string, options?: any): AxiosPromise<UserView> {
            return localVarFp.activateUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 契約ユーザー数とアクティブユーザー数の一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countActiveUsers(options?: any): AxiosPromise<ContractUserTypeAndActiveUserCountDto> {
            return localVarFp.countActiveUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを削除する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<UserView> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー一括追加Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBulkCreateUsersExcel(options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.downloadBulkCreateUsersExcel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー一覧Excelのダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsersListExcel(options?: any): AxiosPromise<File> {
            return localVarFp.downloadUsersListExcel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログイン中のユーザー情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<CurrentUserView> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー情報を返却する
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRole(userId: string, options?: any): AxiosPromise<UserView> {
            return localVarFp.getUserRole(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを招待する
         * @param {CreateUserParam} createUserParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser(createUserParam: CreateUserParam, options?: any): AxiosPromise<UserView> {
            return localVarFp.inviteUser(createUserParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーのGoogleアカウントをリンクする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkWithGoogleAccount(options?: any): AxiosPromise<OkJson> {
            return localVarFp.linkWithGoogleAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ソーシャルログインの状況を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSocialLogins(options?: any): AxiosPromise<SocialLoginsDto> {
            return localVarFp.listSocialLogins(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー一覧
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [isActive] 
         * @param {string} [nameOrEmailOrAssignments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(limit?: number, offset?: number, isActive?: boolean, nameOrEmailOrAssignments?: string, options?: any): AxiosPromise<PageDtoUserWithDepartmentNamesDto> {
            return localVarFp.listUsers(limit, offset, isActive, nameOrEmailOrAssignments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーにロールを割り当てる
         * @param {string} userId 
         * @param {SetRolesParams} setRolesParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoles(userId: string, setRolesParams: SetRolesParams, options?: any): AxiosPromise<void> {
            return localVarFp.setRoles(userId, setRolesParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを更新する
         * @param {string} userId 
         * @param {EditUserParam} editUserParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRole(userId: string, editUserParam: EditUserParam, options?: any): AxiosPromise<UserView> {
            return localVarFp.updateUserRole(userId, editUserParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー一括追加Excelのアップロード
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBulkCreateUsersExcel(file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadBulkCreateUsersExcel(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary ユーザーを再登録する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public activateUser(userId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).activateUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 契約ユーザー数とアクティブユーザー数の一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public countActiveUsers(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).countActiveUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを削除する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteUser(userId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー一括追加Excelのダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public downloadBulkCreateUsersExcel(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).downloadBulkCreateUsersExcel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー一覧Excelのダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public downloadUsersListExcel(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).downloadUsersListExcel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログイン中のユーザー情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentUser(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー情報を返却する
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserRole(userId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUserRole(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを招待する
     * @param {CreateUserParam} createUserParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public inviteUser(createUserParam: CreateUserParam, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).inviteUser(createUserParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーのGoogleアカウントをリンクする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public linkWithGoogleAccount(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).linkWithGoogleAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ソーシャルログインの状況を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public listSocialLogins(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).listSocialLogins(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー一覧
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [isActive] 
     * @param {string} [nameOrEmailOrAssignments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public listUsers(limit?: number, offset?: number, isActive?: boolean, nameOrEmailOrAssignments?: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).listUsers(limit, offset, isActive, nameOrEmailOrAssignments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーにロールを割り当てる
     * @param {string} userId 
     * @param {SetRolesParams} setRolesParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public setRoles(userId: string, setRolesParams: SetRolesParams, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).setRoles(userId, setRolesParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを更新する
     * @param {string} userId 
     * @param {EditUserParam} editUserParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateUserRole(userId: string, editUserParam: EditUserParam, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateUserRole(userId, editUserParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー一括追加Excelのアップロード
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public uploadBulkCreateUsersExcel(file: File, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).uploadBulkCreateUsersExcel(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ViewPermissionControllerApi - axios parameter creator
 * @export
 */
export const ViewPermissionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 閲覧権限新規作成
         * @param {CreateViewPermissionParams} createViewPermissionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewPermission: async (createViewPermissionParams: CreateViewPermissionParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createViewPermissionParams' is not null or undefined
            assertParamExists('createViewPermission', 'createViewPermissionParams', createViewPermissionParams)
            const localVarPath = `/api/view-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createViewPermissionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 閲覧権限削除
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteViewPermission: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteViewPermission', 'id', id)
            const localVarPath = `/api/view-permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 閲覧権限詳細
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findViewPermission: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findViewPermission', 'id', id)
            const localVarPath = `/api/view-permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 閲覧権限に指定するユーザーを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersForSelection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/view-permissions/users/for-selection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 閲覧権限一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listViewPermissions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/view-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 閲覧権限編集
         * @param {string} id 
         * @param {UpdateViewPermissionParams} updateViewPermissionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateViewPermission: async (id: string, updateViewPermissionParams: UpdateViewPermissionParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateViewPermission', 'id', id)
            // verify required parameter 'updateViewPermissionParams' is not null or undefined
            assertParamExists('updateViewPermission', 'updateViewPermissionParams', updateViewPermissionParams)
            const localVarPath = `/api/view-permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateViewPermissionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViewPermissionControllerApi - functional programming interface
 * @export
 */
export const ViewPermissionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViewPermissionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 閲覧権限新規作成
         * @param {CreateViewPermissionParams} createViewPermissionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createViewPermission(createViewPermissionParams: CreateViewPermissionParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createViewPermission(createViewPermissionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 閲覧権限削除
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteViewPermission(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteViewPermission(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 閲覧権限詳細
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findViewPermission(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewPermission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findViewPermission(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 閲覧権限に指定するユーザーを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsersForSelection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViewPermissionUsersForSelectionView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsersForSelection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 閲覧権限一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listViewPermissions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViewPermissionView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listViewPermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 閲覧権限編集
         * @param {string} id 
         * @param {UpdateViewPermissionParams} updateViewPermissionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateViewPermission(id: string, updateViewPermissionParams: UpdateViewPermissionParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateViewPermission(id, updateViewPermissionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ViewPermissionControllerApi - factory interface
 * @export
 */
export const ViewPermissionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViewPermissionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 閲覧権限新規作成
         * @param {CreateViewPermissionParams} createViewPermissionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewPermission(createViewPermissionParams: CreateViewPermissionParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createViewPermission(createViewPermissionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 閲覧権限削除
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteViewPermission(id: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteViewPermission(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 閲覧権限詳細
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findViewPermission(id: string, options?: any): AxiosPromise<ViewPermission> {
            return localVarFp.findViewPermission(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 閲覧権限に指定するユーザーを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersForSelection(options?: any): AxiosPromise<Array<ViewPermissionUsersForSelectionView>> {
            return localVarFp.listUsersForSelection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 閲覧権限一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listViewPermissions(options?: any): AxiosPromise<Array<ViewPermissionView>> {
            return localVarFp.listViewPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 閲覧権限編集
         * @param {string} id 
         * @param {UpdateViewPermissionParams} updateViewPermissionParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateViewPermission(id: string, updateViewPermissionParams: UpdateViewPermissionParams, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateViewPermission(id, updateViewPermissionParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViewPermissionControllerApi - object-oriented interface
 * @export
 * @class ViewPermissionControllerApi
 * @extends {BaseAPI}
 */
export class ViewPermissionControllerApi extends BaseAPI {
    /**
     * 
     * @summary 閲覧権限新規作成
     * @param {CreateViewPermissionParams} createViewPermissionParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewPermissionControllerApi
     */
    public createViewPermission(createViewPermissionParams: CreateViewPermissionParams, options?: AxiosRequestConfig) {
        return ViewPermissionControllerApiFp(this.configuration).createViewPermission(createViewPermissionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 閲覧権限削除
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewPermissionControllerApi
     */
    public deleteViewPermission(id: string, options?: AxiosRequestConfig) {
        return ViewPermissionControllerApiFp(this.configuration).deleteViewPermission(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 閲覧権限詳細
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewPermissionControllerApi
     */
    public findViewPermission(id: string, options?: AxiosRequestConfig) {
        return ViewPermissionControllerApiFp(this.configuration).findViewPermission(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 閲覧権限に指定するユーザーを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewPermissionControllerApi
     */
    public listUsersForSelection(options?: AxiosRequestConfig) {
        return ViewPermissionControllerApiFp(this.configuration).listUsersForSelection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 閲覧権限一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewPermissionControllerApi
     */
    public listViewPermissions(options?: AxiosRequestConfig) {
        return ViewPermissionControllerApiFp(this.configuration).listViewPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 閲覧権限編集
     * @param {string} id 
     * @param {UpdateViewPermissionParams} updateViewPermissionParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewPermissionControllerApi
     */
    public updateViewPermission(id: string, updateViewPermissionParams: UpdateViewPermissionParams, options?: AxiosRequestConfig) {
        return ViewPermissionControllerApiFp(this.configuration).updateViewPermission(id, updateViewPermissionParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforceActualAppliedEventControllerApi - axios parameter creator
 * @export
 */
export const WorkforceActualAppliedEventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} actualImportEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyWorkforceActualToFinancialActualImportEvent: async (actualImportEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actualImportEventId' is not null or undefined
            assertParamExists('applyWorkforceActualToFinancialActualImportEvent', 'actualImportEventId', actualImportEventId)
            const localVarPath = `/api/workforce-planning/actual/applied-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (actualImportEventId !== undefined) {
                localVarQueryParameter['actualImportEventId'] = actualImportEventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} actualAppliedEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforceActualAppliedEvent: async (actualAppliedEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actualAppliedEventId' is not null or undefined
            assertParamExists('deleteWorkforceActualAppliedEvent', 'actualAppliedEventId', actualAppliedEventId)
            const localVarPath = `/api/workforce-planning/actual/applied-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (actualAppliedEventId !== undefined) {
                localVarQueryParameter['actualAppliedEventId'] = actualAppliedEventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllWorkforceActualAppliedEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/actual/applied-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforceActualAppliedEventControllerApi - functional programming interface
 * @export
 */
export const WorkforceActualAppliedEventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforceActualAppliedEventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} actualImportEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyWorkforceActualToFinancialActualImportEvent(actualImportEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyWorkforceActualToFinancialActualImportEvent(actualImportEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} actualAppliedEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkforceActualAppliedEvent(actualAppliedEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkforceActualAppliedEvent(actualAppliedEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllWorkforceActualAppliedEvents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkforceActualAppliedEventView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllWorkforceActualAppliedEvents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforceActualAppliedEventControllerApi - factory interface
 * @export
 */
export const WorkforceActualAppliedEventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforceActualAppliedEventControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} actualImportEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyWorkforceActualToFinancialActualImportEvent(actualImportEventId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.applyWorkforceActualToFinancialActualImportEvent(actualImportEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} actualAppliedEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforceActualAppliedEvent(actualAppliedEventId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteWorkforceActualAppliedEvent(actualAppliedEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllWorkforceActualAppliedEvents(options?: any): AxiosPromise<Array<WorkforceActualAppliedEventView>> {
            return localVarFp.listAllWorkforceActualAppliedEvents(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforceActualAppliedEventControllerApi - object-oriented interface
 * @export
 * @class WorkforceActualAppliedEventControllerApi
 * @extends {BaseAPI}
 */
export class WorkforceActualAppliedEventControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} actualImportEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualAppliedEventControllerApi
     */
    public applyWorkforceActualToFinancialActualImportEvent(actualImportEventId: string, options?: AxiosRequestConfig) {
        return WorkforceActualAppliedEventControllerApiFp(this.configuration).applyWorkforceActualToFinancialActualImportEvent(actualImportEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} actualAppliedEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualAppliedEventControllerApi
     */
    public deleteWorkforceActualAppliedEvent(actualAppliedEventId: string, options?: AxiosRequestConfig) {
        return WorkforceActualAppliedEventControllerApiFp(this.configuration).deleteWorkforceActualAppliedEvent(actualAppliedEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualAppliedEventControllerApi
     */
    public listAllWorkforceActualAppliedEvents(options?: AxiosRequestConfig) {
        return WorkforceActualAppliedEventControllerApiFp(this.configuration).listAllWorkforceActualAppliedEvents(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforceActualControllerApi - axios parameter creator
 * @export
 */
export const WorkforceActualControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} periodYearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeWorkforceActual: async (periodYearMonth: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodYearMonth' is not null or undefined
            assertParamExists('closeWorkforceActual', 'periodYearMonth', periodYearMonth)
            const localVarPath = `/api/workforce-planning/actual/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (periodYearMonth !== undefined) {
                localVarQueryParameter['periodYearMonth'] = periodYearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforceActualClosing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/actual/closing-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} periodYearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openWorkforceActual: async (periodYearMonth: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodYearMonth' is not null or undefined
            assertParamExists('openWorkforceActual', 'periodYearMonth', periodYearMonth)
            const localVarPath = `/api/workforce-planning/actual/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (periodYearMonth !== undefined) {
                localVarQueryParameter['periodYearMonth'] = periodYearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforceActualControllerApi - functional programming interface
 * @export
 */
export const WorkforceActualControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforceActualControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} periodYearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeWorkforceActual(periodYearMonth: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeWorkforceActual(periodYearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforceActualClosing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforceActualClosingHistoryView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforceActualClosing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} periodYearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openWorkforceActual(periodYearMonth: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openWorkforceActual(periodYearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforceActualControllerApi - factory interface
 * @export
 */
export const WorkforceActualControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforceActualControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} periodYearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeWorkforceActual(periodYearMonth: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.closeWorkforceActual(periodYearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforceActualClosing(options?: any): AxiosPromise<WorkforceActualClosingHistoryView> {
            return localVarFp.getWorkforceActualClosing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} periodYearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openWorkforceActual(periodYearMonth: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.openWorkforceActual(periodYearMonth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforceActualControllerApi - object-oriented interface
 * @export
 * @class WorkforceActualControllerApi
 * @extends {BaseAPI}
 */
export class WorkforceActualControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} periodYearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualControllerApi
     */
    public closeWorkforceActual(periodYearMonth: string, options?: AxiosRequestConfig) {
        return WorkforceActualControllerApiFp(this.configuration).closeWorkforceActual(periodYearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualControllerApi
     */
    public getWorkforceActualClosing(options?: AxiosRequestConfig) {
        return WorkforceActualControllerApiFp(this.configuration).getWorkforceActualClosing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} periodYearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualControllerApi
     */
    public openWorkforceActual(periodYearMonth: string, options?: AxiosRequestConfig) {
        return WorkforceActualControllerApiFp(this.configuration).openWorkforceActual(periodYearMonth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforceActualImportEventControllerApi - axios parameter creator
 * @export
 */
export const WorkforceActualImportEventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforceActualImportEvent: async (importEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importEventId' is not null or undefined
            assertParamExists('deleteWorkforceActualImportEvent', 'importEventId', importEventId)
            const localVarPath = `/api/workforce-planning/actual/import-event/{importEventId}`
                .replace(`{${"importEventId"}}`, encodeURIComponent(String(importEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員・採用枠工数実績登録フォーマット(Excel)のダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWorkforceActualFormatExcel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/actual/import-event/format/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforceActualUploadEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/actual/import-event/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWorkforceActualUpdateRequest: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadWorkforceActualUpdateRequest', 'file', file)
            const localVarPath = `/api/workforce-planning/actual/import-event/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforceActualImportEventControllerApi - functional programming interface
 * @export
 */
export const WorkforceActualImportEventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforceActualImportEventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkforceActualImportEvent(importEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkforceActualImportEvent(importEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員・採用枠工数実績登録フォーマット(Excel)のダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadWorkforceActualFormatExcel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadWorkforceActualFormatExcel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkforceActualUploadEvents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkforceActualUploadEventView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkforceActualUploadEvents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadWorkforceActualUpdateRequest(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadWorkforceActualUpdateRequest(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforceActualImportEventControllerApi - factory interface
 * @export
 */
export const WorkforceActualImportEventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforceActualImportEventControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} importEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforceActualImportEvent(importEventId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteWorkforceActualImportEvent(importEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員・採用枠工数実績登録フォーマット(Excel)のダウンロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWorkforceActualFormatExcel(options?: any): AxiosPromise<File> {
            return localVarFp.downloadWorkforceActualFormatExcel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforceActualUploadEvents(options?: any): AxiosPromise<Array<WorkforceActualUploadEventView>> {
            return localVarFp.listWorkforceActualUploadEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWorkforceActualUpdateRequest(file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadWorkforceActualUpdateRequest(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforceActualImportEventControllerApi - object-oriented interface
 * @export
 * @class WorkforceActualImportEventControllerApi
 * @extends {BaseAPI}
 */
export class WorkforceActualImportEventControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} importEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportEventControllerApi
     */
    public deleteWorkforceActualImportEvent(importEventId: string, options?: AxiosRequestConfig) {
        return WorkforceActualImportEventControllerApiFp(this.configuration).deleteWorkforceActualImportEvent(importEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員・採用枠工数実績登録フォーマット(Excel)のダウンロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportEventControllerApi
     */
    public downloadWorkforceActualFormatExcel(options?: AxiosRequestConfig) {
        return WorkforceActualImportEventControllerApiFp(this.configuration).downloadWorkforceActualFormatExcel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportEventControllerApi
     */
    public listWorkforceActualUploadEvents(options?: AxiosRequestConfig) {
        return WorkforceActualImportEventControllerApiFp(this.configuration).listWorkforceActualUploadEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportEventControllerApi
     */
    public uploadWorkforceActualUpdateRequest(file: File, options?: AxiosRequestConfig) {
        return WorkforceActualImportEventControllerApiFp(this.configuration).uploadWorkforceActualUpdateRequest(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforceActualImportMappingSettingControllerApi - axios parameter creator
 * @export
 */
export const WorkforceActualImportMappingSettingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 取込設定を作成する
         * @param {UpsertWorkforceActualImportMappingSettingParam} upsertWorkforceActualImportMappingSettingParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkforceActualImportMappingSetting: async (upsertWorkforceActualImportMappingSettingParam: UpsertWorkforceActualImportMappingSettingParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertWorkforceActualImportMappingSettingParam' is not null or undefined
            assertParamExists('createWorkforceActualImportMappingSetting', 'upsertWorkforceActualImportMappingSettingParam', upsertWorkforceActualImportMappingSettingParam)
            const localVarPath = `/api/workforce-planning/mapping-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertWorkforceActualImportMappingSettingParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取込設定を削除する
         * @param {string} workforceActualImportMappingSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforceActualImportMappingSetting: async (workforceActualImportMappingSettingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceActualImportMappingSettingId' is not null or undefined
            assertParamExists('deleteWorkforceActualImportMappingSetting', 'workforceActualImportMappingSettingId', workforceActualImportMappingSettingId)
            const localVarPath = `/api/workforce-planning/mapping-setting/{workforceActualImportMappingSettingId}`
                .replace(`{${"workforceActualImportMappingSettingId"}}`, encodeURIComponent(String(workforceActualImportMappingSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取込設定の初期値を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultWorkforceActualImportMappingSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/mapping-setting/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取込設定を取得する
         * @param {string} workforceActualImportMappingSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforceActualImportMappingSetting: async (workforceActualImportMappingSettingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceActualImportMappingSettingId' is not null or undefined
            assertParamExists('getWorkforceActualImportMappingSetting', 'workforceActualImportMappingSettingId', workforceActualImportMappingSettingId)
            const localVarPath = `/api/workforce-planning/mapping-setting/{workforceActualImportMappingSettingId}`
                .replace(`{${"workforceActualImportMappingSettingId"}}`, encodeURIComponent(String(workforceActualImportMappingSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取込設定一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforceActualImportMappingSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/mapping-setting/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取込設定を更新する
         * @param {string} workforceActualImportMappingSettingId 
         * @param {UpsertWorkforceActualImportMappingSettingParam} upsertWorkforceActualImportMappingSettingParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforceActualImportMappingSetting: async (workforceActualImportMappingSettingId: string, upsertWorkforceActualImportMappingSettingParam: UpsertWorkforceActualImportMappingSettingParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceActualImportMappingSettingId' is not null or undefined
            assertParamExists('updateWorkforceActualImportMappingSetting', 'workforceActualImportMappingSettingId', workforceActualImportMappingSettingId)
            // verify required parameter 'upsertWorkforceActualImportMappingSettingParam' is not null or undefined
            assertParamExists('updateWorkforceActualImportMappingSetting', 'upsertWorkforceActualImportMappingSettingParam', upsertWorkforceActualImportMappingSettingParam)
            const localVarPath = `/api/workforce-planning/mapping-setting/{workforceActualImportMappingSettingId}`
                .replace(`{${"workforceActualImportMappingSettingId"}}`, encodeURIComponent(String(workforceActualImportMappingSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertWorkforceActualImportMappingSettingParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforceActualImportMappingSettingControllerApi - functional programming interface
 * @export
 */
export const WorkforceActualImportMappingSettingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforceActualImportMappingSettingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 取込設定を作成する
         * @param {UpsertWorkforceActualImportMappingSettingParam} upsertWorkforceActualImportMappingSettingParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkforceActualImportMappingSetting(upsertWorkforceActualImportMappingSettingParam: UpsertWorkforceActualImportMappingSettingParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkforceActualImportMappingSetting(upsertWorkforceActualImportMappingSettingParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取込設定を削除する
         * @param {string} workforceActualImportMappingSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取込設定の初期値を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultWorkforceActualImportMappingSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforceActualImportMappingSettingView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultWorkforceActualImportMappingSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取込設定を取得する
         * @param {string} workforceActualImportMappingSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforceActualImportMappingSettingView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取込設定一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkforceActualImportMappingSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkforceActualImportMappingSettingListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkforceActualImportMappingSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取込設定を更新する
         * @param {string} workforceActualImportMappingSettingId 
         * @param {UpsertWorkforceActualImportMappingSettingParam} upsertWorkforceActualImportMappingSettingParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId: string, upsertWorkforceActualImportMappingSettingParam: UpsertWorkforceActualImportMappingSettingParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId, upsertWorkforceActualImportMappingSettingParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforceActualImportMappingSettingControllerApi - factory interface
 * @export
 */
export const WorkforceActualImportMappingSettingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforceActualImportMappingSettingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 取込設定を作成する
         * @param {UpsertWorkforceActualImportMappingSettingParam} upsertWorkforceActualImportMappingSettingParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkforceActualImportMappingSetting(upsertWorkforceActualImportMappingSettingParam: UpsertWorkforceActualImportMappingSettingParam, options?: any): AxiosPromise<void> {
            return localVarFp.createWorkforceActualImportMappingSetting(upsertWorkforceActualImportMappingSettingParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取込設定を削除する
         * @param {string} workforceActualImportMappingSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取込設定の初期値を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultWorkforceActualImportMappingSetting(options?: any): AxiosPromise<WorkforceActualImportMappingSettingView> {
            return localVarFp.getDefaultWorkforceActualImportMappingSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取込設定を取得する
         * @param {string} workforceActualImportMappingSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId: string, options?: any): AxiosPromise<WorkforceActualImportMappingSettingView> {
            return localVarFp.getWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取込設定一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforceActualImportMappingSettings(options?: any): AxiosPromise<Array<WorkforceActualImportMappingSettingListItem>> {
            return localVarFp.listWorkforceActualImportMappingSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取込設定を更新する
         * @param {string} workforceActualImportMappingSettingId 
         * @param {UpsertWorkforceActualImportMappingSettingParam} upsertWorkforceActualImportMappingSettingParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId: string, upsertWorkforceActualImportMappingSettingParam: UpsertWorkforceActualImportMappingSettingParam, options?: any): AxiosPromise<void> {
            return localVarFp.updateWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId, upsertWorkforceActualImportMappingSettingParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforceActualImportMappingSettingControllerApi - object-oriented interface
 * @export
 * @class WorkforceActualImportMappingSettingControllerApi
 * @extends {BaseAPI}
 */
export class WorkforceActualImportMappingSettingControllerApi extends BaseAPI {
    /**
     * 
     * @summary 取込設定を作成する
     * @param {UpsertWorkforceActualImportMappingSettingParam} upsertWorkforceActualImportMappingSettingParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportMappingSettingControllerApi
     */
    public createWorkforceActualImportMappingSetting(upsertWorkforceActualImportMappingSettingParam: UpsertWorkforceActualImportMappingSettingParam, options?: AxiosRequestConfig) {
        return WorkforceActualImportMappingSettingControllerApiFp(this.configuration).createWorkforceActualImportMappingSetting(upsertWorkforceActualImportMappingSettingParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取込設定を削除する
     * @param {string} workforceActualImportMappingSettingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportMappingSettingControllerApi
     */
    public deleteWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId: string, options?: AxiosRequestConfig) {
        return WorkforceActualImportMappingSettingControllerApiFp(this.configuration).deleteWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取込設定の初期値を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportMappingSettingControllerApi
     */
    public getDefaultWorkforceActualImportMappingSetting(options?: AxiosRequestConfig) {
        return WorkforceActualImportMappingSettingControllerApiFp(this.configuration).getDefaultWorkforceActualImportMappingSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取込設定を取得する
     * @param {string} workforceActualImportMappingSettingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportMappingSettingControllerApi
     */
    public getWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId: string, options?: AxiosRequestConfig) {
        return WorkforceActualImportMappingSettingControllerApiFp(this.configuration).getWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取込設定一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportMappingSettingControllerApi
     */
    public listWorkforceActualImportMappingSettings(options?: AxiosRequestConfig) {
        return WorkforceActualImportMappingSettingControllerApiFp(this.configuration).listWorkforceActualImportMappingSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取込設定を更新する
     * @param {string} workforceActualImportMappingSettingId 
     * @param {UpsertWorkforceActualImportMappingSettingParam} upsertWorkforceActualImportMappingSettingParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceActualImportMappingSettingControllerApi
     */
    public updateWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId: string, upsertWorkforceActualImportMappingSettingParam: UpsertWorkforceActualImportMappingSettingParam, options?: AxiosRequestConfig) {
        return WorkforceActualImportMappingSettingControllerApiFp(this.configuration).updateWorkforceActualImportMappingSetting(workforceActualImportMappingSettingId, upsertWorkforceActualImportMappingSettingParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforcePlanUpdateRequestControllerApi - axios parameter creator
 * @export
 */
export const WorkforcePlanUpdateRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 人員計画更新申請を削除する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforcePlanUpdateRequest: async (workforcePlanUpdateRequestId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforcePlanUpdateRequestId' is not null or undefined
            assertParamExists('deleteWorkforcePlanUpdateRequest', 'workforcePlanUpdateRequestId', workforcePlanUpdateRequestId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('deleteWorkforcePlanUpdateRequest', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/{workforcePlanUpdateRequestId}`
                .replace(`{${"workforcePlanUpdateRequestId"}}`, encodeURIComponent(String(workforcePlanUpdateRequestId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 工数比率計画更新申請Excelをダウンロードする
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadConcurrentPostRatioPlanUpdateRequestFormat: async (planningProjectId: string, departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('downloadConcurrentPostRatioPlanUpdateRequestFormat', 'planningProjectId', planningProjectId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('downloadConcurrentPostRatioPlanUpdateRequestFormat', 'departmentId', departmentId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/departments/{departmentId}/excel`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提出元人員計画更新申請Excelをダウンロードする
         * @param {string} planningProjectId 
         * @param {string} workforcePlanUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSubmittedWorkforcePlanUpdateRequestFile: async (planningProjectId: string, workforcePlanUpdateRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('downloadSubmittedWorkforcePlanUpdateRequestFile', 'planningProjectId', planningProjectId)
            // verify required parameter 'workforcePlanUpdateRequestId' is not null or undefined
            assertParamExists('downloadSubmittedWorkforcePlanUpdateRequestFile', 'workforcePlanUpdateRequestId', workforcePlanUpdateRequestId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/{workforcePlanUpdateRequestId}/excel`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"workforcePlanUpdateRequestId"}}`, encodeURIComponent(String(workforcePlanUpdateRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員計画更新申請Excelをダウンロードする
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWorkforcePlanUpdateRequestFormat: async (planningProjectId: string, departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('downloadWorkforcePlanUpdateRequestFormat', 'planningProjectId', planningProjectId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('downloadWorkforcePlanUpdateRequestFormat', 'departmentId', departmentId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/workforce-plan/departments/{departmentId}/workforce-plan-update/excel`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人件費変換の計算状況を取得する
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationStatus: async (planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getCalculationStatus', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/calculation-status`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PL差分を返す
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} showIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPLDiff: async (workforcePlanUpdateRequestId: string, planningProjectId: string, showIn: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforcePlanUpdateRequestId' is not null or undefined
            assertParamExists('getPLDiff', 'workforcePlanUpdateRequestId', workforcePlanUpdateRequestId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getPLDiff', 'planningProjectId', planningProjectId)
            // verify required parameter 'showIn' is not null or undefined
            assertParamExists('getPLDiff', 'showIn', showIn)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/{workforcePlanUpdateRequestId}/pl-diff`
                .replace(`{${"workforcePlanUpdateRequestId"}}`, encodeURIComponent(String(workforcePlanUpdateRequestId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showIn !== undefined) {
                localVarQueryParameter['showIn'] = showIn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 工数差分を返す
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforceDiff: async (workforcePlanUpdateRequestId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforcePlanUpdateRequestId' is not null or undefined
            assertParamExists('getWorkforceDiff', 'workforcePlanUpdateRequestId', workforcePlanUpdateRequestId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getWorkforceDiff', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/{workforcePlanUpdateRequestId}/workforce-diff`
                .replace(`{${"workforcePlanUpdateRequestId"}}`, encodeURIComponent(String(workforcePlanUpdateRequestId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequest: async (workforcePlanUpdateRequestId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforcePlanUpdateRequestId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequest', 'workforcePlanUpdateRequestId', workforcePlanUpdateRequestId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequest', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/{workforcePlanUpdateRequestId}`
                .replace(`{${"workforcePlanUpdateRequestId"}}`, encodeURIComponent(String(workforcePlanUpdateRequestId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員計画更新申請のイベント一覧を取得する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequestEvents: async (workforcePlanUpdateRequestId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforcePlanUpdateRequestId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestEvents', 'workforcePlanUpdateRequestId', workforcePlanUpdateRequestId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestEvents', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/{workforcePlanUpdateRequestId}/events`
                .replace(`{${"workforcePlanUpdateRequestId"}}`, encodeURIComponent(String(workforcePlanUpdateRequestId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequestStatuses: async (planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestStatuses', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/statuses`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員・採用枠と年月を指定して、更新申請における人員・採用枠の詳細情報を取得する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {string} workforceId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth: async (workforcePlanUpdateRequestId: string, planningProjectId: string, workforceId: string, yearMonth: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforcePlanUpdateRequestId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth', 'workforcePlanUpdateRequestId', workforcePlanUpdateRequestId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth', 'planningProjectId', planningProjectId)
            // verify required parameter 'workforceId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth', 'workforceId', workforceId)
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth', 'yearMonth', yearMonth)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/{workforcePlanUpdateRequestId}/details/{workforceId}`
                .replace(`{${"workforcePlanUpdateRequestId"}}`, encodeURIComponent(String(workforcePlanUpdateRequestId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"workforceId"}}`, encodeURIComponent(String(workforceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 枠と年月を指定して、更新申請における枠の詳細情報を取得する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {string} workforceSlotId 
         * @param {string} yearMonth 
         * @param {'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK'} slotType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth: async (workforcePlanUpdateRequestId: string, planningProjectId: string, workforceSlotId: string, yearMonth: string, slotType: 'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforcePlanUpdateRequestId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth', 'workforcePlanUpdateRequestId', workforcePlanUpdateRequestId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth', 'planningProjectId', planningProjectId)
            // verify required parameter 'workforceSlotId' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth', 'workforceSlotId', workforceSlotId)
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth', 'yearMonth', yearMonth)
            // verify required parameter 'slotType' is not null or undefined
            assertParamExists('getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth', 'slotType', slotType)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/{workforcePlanUpdateRequestId}/details/workforce-slots/{workforceSlotId}`
                .replace(`{${"workforcePlanUpdateRequestId"}}`, encodeURIComponent(String(workforcePlanUpdateRequestId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)))
                .replace(`{${"workforceSlotId"}}`, encodeURIComponent(String(workforceSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }

            if (slotType !== undefined) {
                localVarQueryParameter['slotType'] = slotType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員計画更新申請を非同期で承認する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startApprovingWorkforcePlanUpdateRequest: async (workforcePlanUpdateRequestId: string, planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforcePlanUpdateRequestId' is not null or undefined
            assertParamExists('startApprovingWorkforcePlanUpdateRequest', 'workforcePlanUpdateRequestId', workforcePlanUpdateRequestId)
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('startApprovingWorkforcePlanUpdateRequest', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/{workforcePlanUpdateRequestId}/start-approving`
                .replace(`{${"workforcePlanUpdateRequestId"}}`, encodeURIComponent(String(workforcePlanUpdateRequestId)))
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人件費変換を非同期で実行する
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCalculation: async (planningProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('startCalculation', 'planningProjectId', planningProjectId)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/start-calculation`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 工数比率計画更新申請Excelをアップロードする
         * @param {string} planningProjectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConcurrentPostRatioPlanUpdateRequest: async (planningProjectId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('uploadConcurrentPostRatioPlanUpdateRequest', 'planningProjectId', planningProjectId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadConcurrentPostRatioPlanUpdateRequest', 'file', file)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/concurrent-post-ratio/excel`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員計画更新申請Excelをアップロードする
         * @param {string} planningProjectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWorkforcePlanUpdateExcelRequest: async (planningProjectId: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planningProjectId' is not null or undefined
            assertParamExists('uploadWorkforcePlanUpdateExcelRequest', 'planningProjectId', planningProjectId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadWorkforcePlanUpdateExcelRequest', 'file', file)
            const localVarPath = `/api/planningProjects/{planningProjectId}/workforce-plan-update-requests/workforce-plan-update/excel`
                .replace(`{${"planningProjectId"}}`, encodeURIComponent(String(planningProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforcePlanUpdateRequestControllerApi - functional programming interface
 * @export
 */
export const WorkforcePlanUpdateRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforcePlanUpdateRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 人員計画更新申請を削除する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 工数比率計画更新申請Excelをダウンロードする
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadConcurrentPostRatioPlanUpdateRequestFormat(planningProjectId: string, departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadConcurrentPostRatioPlanUpdateRequestFormat(planningProjectId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提出元人員計画更新申請Excelをダウンロードする
         * @param {string} planningProjectId 
         * @param {string} workforcePlanUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSubmittedWorkforcePlanUpdateRequestFile(planningProjectId: string, workforcePlanUpdateRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSubmittedWorkforcePlanUpdateRequestFile(planningProjectId, workforcePlanUpdateRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員計画更新申請Excelをダウンロードする
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadWorkforcePlanUpdateRequestFormat(planningProjectId: string, departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadWorkforcePlanUpdateRequestFormat(planningProjectId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人件費変換の計算状況を取得する
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalculationStatus(planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanUpdateRequestCalculationStatusView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalculationStatus(planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PL差分を返す
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} showIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPLDiff(workforcePlanUpdateRequestId: string, planningProjectId: string, showIn: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanUpdateRequestPLView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPLDiff(workforcePlanUpdateRequestId, planningProjectId, showIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 工数差分を返す
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforceDiff(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanUpdateRequestWorkforceDiffView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforceDiff(workforcePlanUpdateRequestId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanUpdateRequestView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員計画更新申請のイベント一覧を取得する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforcePlanUpdateRequestEvents(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanUpdateRequestEventsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforcePlanUpdateRequestEvents(workforcePlanUpdateRequestId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforcePlanUpdateRequestStatuses(planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanUpdateRequestStatusesView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforcePlanUpdateRequestStatuses(planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員・採用枠と年月を指定して、更新申請における人員・採用枠の詳細情報を取得する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {string} workforceId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth(workforcePlanUpdateRequestId: string, planningProjectId: string, workforceId: string, yearMonth: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth(workforcePlanUpdateRequestId, planningProjectId, workforceId, yearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 枠と年月を指定して、更新申請における枠の詳細情報を取得する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {string} workforceSlotId 
         * @param {string} yearMonth 
         * @param {'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK'} slotType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth(workforcePlanUpdateRequestId: string, planningProjectId: string, workforceSlotId: string, yearMonth: string, slotType: 'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth(workforcePlanUpdateRequestId, planningProjectId, workforceSlotId, yearMonth, slotType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員計画更新申請を非同期で承認する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startApprovingWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startApprovingWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId, planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人件費変換を非同期で実行する
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startCalculation(planningProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startCalculation(planningProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 工数比率計画更新申請Excelをアップロードする
         * @param {string} planningProjectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConcurrentPostRatioPlanUpdateRequest(planningProjectId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadConcurrentPostRatioPlanUpdateRequest(planningProjectId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員計画更新申請Excelをアップロードする
         * @param {string} planningProjectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadWorkforcePlanUpdateExcelRequest(planningProjectId: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadWorkforcePlanUpdateExcelRequest(planningProjectId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforcePlanUpdateRequestControllerApi - factory interface
 * @export
 */
export const WorkforcePlanUpdateRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforcePlanUpdateRequestControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 人員計画更新申請を削除する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 工数比率計画更新申請Excelをダウンロードする
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadConcurrentPostRatioPlanUpdateRequestFormat(planningProjectId: string, departmentId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadConcurrentPostRatioPlanUpdateRequestFormat(planningProjectId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提出元人員計画更新申請Excelをダウンロードする
         * @param {string} planningProjectId 
         * @param {string} workforcePlanUpdateRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSubmittedWorkforcePlanUpdateRequestFile(planningProjectId: string, workforcePlanUpdateRequestId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadSubmittedWorkforcePlanUpdateRequestFile(planningProjectId, workforcePlanUpdateRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員計画更新申請Excelをダウンロードする
         * @param {string} planningProjectId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWorkforcePlanUpdateRequestFormat(planningProjectId: string, departmentId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadWorkforcePlanUpdateRequestFormat(planningProjectId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人件費変換の計算状況を取得する
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationStatus(planningProjectId: string, options?: any): AxiosPromise<WorkforcePlanUpdateRequestCalculationStatusView> {
            return localVarFp.getCalculationStatus(planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PL差分を返す
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} showIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPLDiff(workforcePlanUpdateRequestId: string, planningProjectId: string, showIn: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options?: any): AxiosPromise<WorkforcePlanUpdateRequestPLView> {
            return localVarFp.getPLDiff(workforcePlanUpdateRequestId, planningProjectId, showIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 工数差分を返す
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforceDiff(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: any): AxiosPromise<WorkforcePlanUpdateRequestWorkforceDiffView> {
            return localVarFp.getWorkforceDiff(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: any): AxiosPromise<WorkforcePlanUpdateRequestView> {
            return localVarFp.getWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員計画更新申請のイベント一覧を取得する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequestEvents(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: any): AxiosPromise<WorkforcePlanUpdateRequestEventsView> {
            return localVarFp.getWorkforcePlanUpdateRequestEvents(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequestStatuses(planningProjectId: string, options?: any): AxiosPromise<WorkforcePlanUpdateRequestStatusesView> {
            return localVarFp.getWorkforcePlanUpdateRequestStatuses(planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員・採用枠と年月を指定して、更新申請における人員・採用枠の詳細情報を取得する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {string} workforceId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth(workforcePlanUpdateRequestId: string, planningProjectId: string, workforceId: string, yearMonth: string, options?: any): AxiosPromise<WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView> {
            return localVarFp.getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth(workforcePlanUpdateRequestId, planningProjectId, workforceId, yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 枠と年月を指定して、更新申請における枠の詳細情報を取得する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {string} workforceSlotId 
         * @param {string} yearMonth 
         * @param {'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK'} slotType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth(workforcePlanUpdateRequestId: string, planningProjectId: string, workforceSlotId: string, yearMonth: string, slotType: 'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK', options?: any): AxiosPromise<WorkforcePlanUpdateRequestWorkforceDetailByYearMonthView> {
            return localVarFp.getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth(workforcePlanUpdateRequestId, planningProjectId, workforceSlotId, yearMonth, slotType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員計画更新申請を非同期で承認する
         * @param {string} workforcePlanUpdateRequestId 
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startApprovingWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: any): AxiosPromise<object> {
            return localVarFp.startApprovingWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人件費変換を非同期で実行する
         * @param {string} planningProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCalculation(planningProjectId: string, options?: any): AxiosPromise<object> {
            return localVarFp.startCalculation(planningProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 工数比率計画更新申請Excelをアップロードする
         * @param {string} planningProjectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConcurrentPostRatioPlanUpdateRequest(planningProjectId: string, file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadConcurrentPostRatioPlanUpdateRequest(planningProjectId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員計画更新申請Excelをアップロードする
         * @param {string} planningProjectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWorkforcePlanUpdateExcelRequest(planningProjectId: string, file: File, options?: any): AxiosPromise<OkJson> {
            return localVarFp.uploadWorkforcePlanUpdateExcelRequest(planningProjectId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforcePlanUpdateRequestControllerApi - object-oriented interface
 * @export
 * @class WorkforcePlanUpdateRequestControllerApi
 * @extends {BaseAPI}
 */
export class WorkforcePlanUpdateRequestControllerApi extends BaseAPI {
    /**
     * 
     * @summary 人員計画更新申請を削除する
     * @param {string} workforcePlanUpdateRequestId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public deleteWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).deleteWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 工数比率計画更新申請Excelをダウンロードする
     * @param {string} planningProjectId 
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public downloadConcurrentPostRatioPlanUpdateRequestFormat(planningProjectId: string, departmentId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).downloadConcurrentPostRatioPlanUpdateRequestFormat(planningProjectId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提出元人員計画更新申請Excelをダウンロードする
     * @param {string} planningProjectId 
     * @param {string} workforcePlanUpdateRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public downloadSubmittedWorkforcePlanUpdateRequestFile(planningProjectId: string, workforcePlanUpdateRequestId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).downloadSubmittedWorkforcePlanUpdateRequestFile(planningProjectId, workforcePlanUpdateRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員計画更新申請Excelをダウンロードする
     * @param {string} planningProjectId 
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public downloadWorkforcePlanUpdateRequestFormat(planningProjectId: string, departmentId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).downloadWorkforcePlanUpdateRequestFormat(planningProjectId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人件費変換の計算状況を取得する
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public getCalculationStatus(planningProjectId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).getCalculationStatus(planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PL差分を返す
     * @param {string} workforcePlanUpdateRequestId 
     * @param {string} planningProjectId 
     * @param {'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION'} showIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public getPLDiff(workforcePlanUpdateRequestId: string, planningProjectId: string, showIn: 'ONE' | 'THOUSAND' | 'MILLION' | 'BILLION', options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).getPLDiff(workforcePlanUpdateRequestId, planningProjectId, showIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 工数差分を返す
     * @param {string} workforcePlanUpdateRequestId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public getWorkforceDiff(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).getWorkforceDiff(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workforcePlanUpdateRequestId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public getWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).getWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員計画更新申請のイベント一覧を取得する
     * @param {string} workforcePlanUpdateRequestId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public getWorkforcePlanUpdateRequestEvents(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).getWorkforcePlanUpdateRequestEvents(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public getWorkforcePlanUpdateRequestStatuses(planningProjectId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).getWorkforcePlanUpdateRequestStatuses(planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員・採用枠と年月を指定して、更新申請における人員・採用枠の詳細情報を取得する
     * @param {string} workforcePlanUpdateRequestId 
     * @param {string} planningProjectId 
     * @param {string} workforceId 
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth(workforcePlanUpdateRequestId: string, planningProjectId: string, workforceId: string, yearMonth: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).getWorkforcePlanUpdateRequestWorkforceDetailByYearMonth(workforcePlanUpdateRequestId, planningProjectId, workforceId, yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 枠と年月を指定して、更新申請における枠の詳細情報を取得する
     * @param {string} workforcePlanUpdateRequestId 
     * @param {string} planningProjectId 
     * @param {string} workforceSlotId 
     * @param {string} yearMonth 
     * @param {'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK'} slotType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth(workforcePlanUpdateRequestId: string, planningProjectId: string, workforceSlotId: string, yearMonth: string, slotType: 'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK', options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).getWorkforcePlanUpdateRequestWorkforceSlotDetailByYearMonth(workforcePlanUpdateRequestId, planningProjectId, workforceSlotId, yearMonth, slotType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員計画更新申請を非同期で承認する
     * @param {string} workforcePlanUpdateRequestId 
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public startApprovingWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId: string, planningProjectId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).startApprovingWorkforcePlanUpdateRequest(workforcePlanUpdateRequestId, planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人件費変換を非同期で実行する
     * @param {string} planningProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public startCalculation(planningProjectId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).startCalculation(planningProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 工数比率計画更新申請Excelをアップロードする
     * @param {string} planningProjectId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public uploadConcurrentPostRatioPlanUpdateRequest(planningProjectId: string, file: File, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).uploadConcurrentPostRatioPlanUpdateRequest(planningProjectId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員計画更新申請Excelをアップロードする
     * @param {string} planningProjectId 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanUpdateRequestControllerApi
     */
    public uploadWorkforcePlanUpdateExcelRequest(planningProjectId: string, file: File, options?: AxiosRequestConfig) {
        return WorkforcePlanUpdateRequestControllerApiFp(this.configuration).uploadWorkforcePlanUpdateExcelRequest(planningProjectId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforcePlanningBaseSalaryIncreaseRuleControllerApi - axios parameter creator
 * @export
 */
export const WorkforcePlanningBaseSalaryIncreaseRuleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 人員計画明細科目昇給ルールを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchWorkforcePlanningBaseSalaryIncreaseRule: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/convert/base-salary-increase-rule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員計画明細科目昇給ルールを保存する
         * @param {UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody} updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforcePlanningBaseSalaryIncreaseRule: async (updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody: UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody' is not null or undefined
            assertParamExists('updateWorkforcePlanningBaseSalaryIncreaseRule', 'updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody', updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody)
            const localVarPath = `/api/workforce-planning/convert/base-salary-increase-rule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforcePlanningBaseSalaryIncreaseRuleControllerApi - functional programming interface
 * @export
 */
export const WorkforcePlanningBaseSalaryIncreaseRuleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforcePlanningBaseSalaryIncreaseRuleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 人員計画明細科目昇給ルールを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchWorkforcePlanningBaseSalaryIncreaseRule(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanningBaseSalaryIncreaseRuleView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchWorkforcePlanningBaseSalaryIncreaseRule(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員計画明細科目昇給ルールを保存する
         * @param {UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody} updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkforcePlanningBaseSalaryIncreaseRule(updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody: UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkforcePlanningBaseSalaryIncreaseRule(updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforcePlanningBaseSalaryIncreaseRuleControllerApi - factory interface
 * @export
 */
export const WorkforcePlanningBaseSalaryIncreaseRuleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforcePlanningBaseSalaryIncreaseRuleControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 人員計画明細科目昇給ルールを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchWorkforcePlanningBaseSalaryIncreaseRule(options?: any): AxiosPromise<WorkforcePlanningBaseSalaryIncreaseRuleView> {
            return localVarFp.fetchWorkforcePlanningBaseSalaryIncreaseRule(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員計画明細科目昇給ルールを保存する
         * @param {UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody} updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforcePlanningBaseSalaryIncreaseRule(updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody: UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateWorkforcePlanningBaseSalaryIncreaseRule(updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforcePlanningBaseSalaryIncreaseRuleControllerApi - object-oriented interface
 * @export
 * @class WorkforcePlanningBaseSalaryIncreaseRuleControllerApi
 * @extends {BaseAPI}
 */
export class WorkforcePlanningBaseSalaryIncreaseRuleControllerApi extends BaseAPI {
    /**
     * 
     * @summary 人員計画明細科目昇給ルールを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningBaseSalaryIncreaseRuleControllerApi
     */
    public fetchWorkforcePlanningBaseSalaryIncreaseRule(options?: AxiosRequestConfig) {
        return WorkforcePlanningBaseSalaryIncreaseRuleControllerApiFp(this.configuration).fetchWorkforcePlanningBaseSalaryIncreaseRule(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員計画明細科目昇給ルールを保存する
     * @param {UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody} updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningBaseSalaryIncreaseRuleControllerApi
     */
    public updateWorkforcePlanningBaseSalaryIncreaseRule(updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody: UpdateWorkforcePlanningBaseSalaryIncreaseRuleViewBody, options?: AxiosRequestConfig) {
        return WorkforcePlanningBaseSalaryIncreaseRuleControllerApiFp(this.configuration).updateWorkforcePlanningBaseSalaryIncreaseRule(updateWorkforcePlanningBaseSalaryIncreaseRuleViewBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforcePlanningDimensionsControllerApi - axios parameter creator
 * @export
 */
export const WorkforcePlanningDimensionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllDimensionTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-plannings/dimensions/list-dimension-tags-for-workforce-planning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforcePlanningDimensionsControllerApi - functional programming interface
 * @export
 */
export const WorkforcePlanningDimensionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforcePlanningDimensionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllDimensionTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkforcePlanningDimensionWithDimensionTagsView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllDimensionTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforcePlanningDimensionsControllerApi - factory interface
 * @export
 */
export const WorkforcePlanningDimensionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforcePlanningDimensionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllDimensionTags(options?: any): AxiosPromise<Array<WorkforcePlanningDimensionWithDimensionTagsView>> {
            return localVarFp.listAllDimensionTags(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforcePlanningDimensionsControllerApi - object-oriented interface
 * @export
 * @class WorkforcePlanningDimensionsControllerApi
 * @extends {BaseAPI}
 */
export class WorkforcePlanningDimensionsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningDimensionsControllerApi
     */
    public listAllDimensionTags(options?: AxiosRequestConfig) {
        return WorkforcePlanningDimensionsControllerApiFp(this.configuration).listAllDimensionTags(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforcePlanningItemConversionRuleControllerApi - axios parameter creator
 * @export
 */
export const WorkforcePlanningItemConversionRuleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 人員計画明細科目変換ルールを作成する
         * @param {WorkforceConversionRuleBody} workforceConversionRuleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkforcePlanningItemConvertRule: async (workforceConversionRuleBody: WorkforceConversionRuleBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceConversionRuleBody' is not null or undefined
            assertParamExists('createWorkforcePlanningItemConvertRule', 'workforceConversionRuleBody', workforceConversionRuleBody)
            const localVarPath = `/api/workforce-planning/convert/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workforceConversionRuleBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルールを削除する
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforcePlanningItemConvertRule: async (ruleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('deleteWorkforcePlanningItemConvertRule', 'ruleId', ruleId)
            const localVarPath = `/api/workforce-planning/convert/rules/{ruleId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルールを取得する
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanningItemConvertRule: async (ruleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('getWorkforcePlanningItemConvertRule', 'ruleId', ruleId)
            const localVarPath = `/api/workforce-planning/convert/rules/{ruleId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルール一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforcePlanningItemConvertRules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/convert/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルールを更新する
         * @param {string} ruleId 
         * @param {WorkforceConversionRuleBody} workforceConversionRuleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforcePlanningItemConvertRule: async (ruleId: string, workforceConversionRuleBody: WorkforceConversionRuleBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('updateWorkforcePlanningItemConvertRule', 'ruleId', ruleId)
            // verify required parameter 'workforceConversionRuleBody' is not null or undefined
            assertParamExists('updateWorkforcePlanningItemConvertRule', 'workforceConversionRuleBody', workforceConversionRuleBody)
            const localVarPath = `/api/workforce-planning/convert/rules/{ruleId}`
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workforceConversionRuleBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforcePlanningItemConversionRuleControllerApi - functional programming interface
 * @export
 */
export const WorkforcePlanningItemConversionRuleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforcePlanningItemConversionRuleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 人員計画明細科目変換ルールを作成する
         * @param {WorkforceConversionRuleBody} workforceConversionRuleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkforcePlanningItemConvertRule(workforceConversionRuleBody: WorkforceConversionRuleBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkforcePlanningItemConvertRule(workforceConversionRuleBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルールを削除する
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkforcePlanningItemConvertRule(ruleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkforcePlanningItemConvertRule(ruleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルールを取得する
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkforcePlanningItemConvertRule(ruleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanningItemConvertRuleDetailView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkforcePlanningItemConvertRule(ruleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルール一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkforcePlanningItemConvertRules(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkforcePlanningItemConvertRuleView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkforcePlanningItemConvertRules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルールを更新する
         * @param {string} ruleId 
         * @param {WorkforceConversionRuleBody} workforceConversionRuleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkforcePlanningItemConvertRule(ruleId: string, workforceConversionRuleBody: WorkforceConversionRuleBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkforcePlanningItemConvertRule(ruleId, workforceConversionRuleBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforcePlanningItemConversionRuleControllerApi - factory interface
 * @export
 */
export const WorkforcePlanningItemConversionRuleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforcePlanningItemConversionRuleControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 人員計画明細科目変換ルールを作成する
         * @param {WorkforceConversionRuleBody} workforceConversionRuleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkforcePlanningItemConvertRule(workforceConversionRuleBody: WorkforceConversionRuleBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.createWorkforcePlanningItemConvertRule(workforceConversionRuleBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルールを削除する
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkforcePlanningItemConvertRule(ruleId: string, options?: any): AxiosPromise<OkJson> {
            return localVarFp.deleteWorkforcePlanningItemConvertRule(ruleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルールを取得する
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkforcePlanningItemConvertRule(ruleId: string, options?: any): AxiosPromise<WorkforcePlanningItemConvertRuleDetailView> {
            return localVarFp.getWorkforcePlanningItemConvertRule(ruleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルール一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforcePlanningItemConvertRules(options?: any): AxiosPromise<Array<WorkforcePlanningItemConvertRuleView>> {
            return localVarFp.listWorkforcePlanningItemConvertRules(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員計画明細科目変換ルールを更新する
         * @param {string} ruleId 
         * @param {WorkforceConversionRuleBody} workforceConversionRuleBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforcePlanningItemConvertRule(ruleId: string, workforceConversionRuleBody: WorkforceConversionRuleBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateWorkforcePlanningItemConvertRule(ruleId, workforceConversionRuleBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforcePlanningItemConversionRuleControllerApi - object-oriented interface
 * @export
 * @class WorkforcePlanningItemConversionRuleControllerApi
 * @extends {BaseAPI}
 */
export class WorkforcePlanningItemConversionRuleControllerApi extends BaseAPI {
    /**
     * 
     * @summary 人員計画明細科目変換ルールを作成する
     * @param {WorkforceConversionRuleBody} workforceConversionRuleBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningItemConversionRuleControllerApi
     */
    public createWorkforcePlanningItemConvertRule(workforceConversionRuleBody: WorkforceConversionRuleBody, options?: AxiosRequestConfig) {
        return WorkforcePlanningItemConversionRuleControllerApiFp(this.configuration).createWorkforcePlanningItemConvertRule(workforceConversionRuleBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員計画明細科目変換ルールを削除する
     * @param {string} ruleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningItemConversionRuleControllerApi
     */
    public deleteWorkforcePlanningItemConvertRule(ruleId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanningItemConversionRuleControllerApiFp(this.configuration).deleteWorkforcePlanningItemConvertRule(ruleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員計画明細科目変換ルールを取得する
     * @param {string} ruleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningItemConversionRuleControllerApi
     */
    public getWorkforcePlanningItemConvertRule(ruleId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanningItemConversionRuleControllerApiFp(this.configuration).getWorkforcePlanningItemConvertRule(ruleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員計画明細科目変換ルール一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningItemConversionRuleControllerApi
     */
    public listWorkforcePlanningItemConvertRules(options?: AxiosRequestConfig) {
        return WorkforcePlanningItemConversionRuleControllerApiFp(this.configuration).listWorkforcePlanningItemConvertRules(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員計画明細科目変換ルールを更新する
     * @param {string} ruleId 
     * @param {WorkforceConversionRuleBody} workforceConversionRuleBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningItemConversionRuleControllerApi
     */
    public updateWorkforcePlanningItemConvertRule(ruleId: string, workforceConversionRuleBody: WorkforceConversionRuleBody, options?: AxiosRequestConfig) {
        return WorkforcePlanningItemConversionRuleControllerApiFp(this.configuration).updateWorkforcePlanningItemConvertRule(ruleId, workforceConversionRuleBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforcePlanningSimulationControllerApi - axios parameter creator
 * @export
 */
export const WorkforcePlanningSimulationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSimulationBody} createSimulationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSimulation: async (createSimulationBody: CreateSimulationBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSimulationBody' is not null or undefined
            assertParamExists('createSimulation', 'createSimulationBody', createSimulationBody)
            const localVarPath = `/api/workforce-plannings/simulations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSimulationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSimulation: async (simulationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('deleteSimulation', 'simulationId', simulationId)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimulationDetail: async (simulationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('getSimulationDetail', 'simulationId', simulationId)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/detail`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimulationPL: async (simulationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('getSimulationPL', 'simulationId', simulationId)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/pl`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimulationSlots: async (simulationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('getSimulationSlots', 'simulationId', simulationId)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/slots`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimulationWorkforcePlanning: async (simulationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('getSimulationWorkforcePlanning', 'simulationId', simulationId)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}/workforce-planning`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimulations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-plannings/simulations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {UpdateSimulationBody} updateSimulationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimulation: async (simulationId: string, updateSimulationBody: UpdateSimulationBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('updateSimulation', 'simulationId', simulationId)
            // verify required parameter 'updateSimulationBody' is not null or undefined
            assertParamExists('updateSimulation', 'updateSimulationBody', updateSimulationBody)
            const localVarPath = `/api/workforce-plannings/simulations/{simulationId}`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSimulationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforcePlanningSimulationControllerApi - functional programming interface
 * @export
 */
export const WorkforcePlanningSimulationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforcePlanningSimulationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSimulationBody} createSimulationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSimulation(createSimulationBody: CreateSimulationBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedSimulationView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSimulation(createSimulationBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSimulation(simulationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSimulation(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimulationDetail(simulationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforcePlanningSimulationMetaView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimulationDetail(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimulationPL(simulationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationPLTableView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimulationPL(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimulationSlots(simulationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationSlotTableView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimulationSlots(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimulationWorkforcePlanning(simulationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationWorkforcePlanningTableView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimulationWorkforcePlanning(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSimulations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSimulationView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSimulations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {UpdateSimulationBody} updateSimulationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSimulation(simulationId: string, updateSimulationBody: UpdateSimulationBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSimulation(simulationId, updateSimulationBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforcePlanningSimulationControllerApi - factory interface
 * @export
 */
export const WorkforcePlanningSimulationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforcePlanningSimulationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSimulationBody} createSimulationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSimulation(createSimulationBody: CreateSimulationBody, options?: any): AxiosPromise<CreatedSimulationView> {
            return localVarFp.createSimulation(createSimulationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSimulation(simulationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSimulation(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimulationDetail(simulationId: string, options?: any): AxiosPromise<WorkforcePlanningSimulationMetaView> {
            return localVarFp.getSimulationDetail(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimulationPL(simulationId: string, options?: any): AxiosPromise<SimulationPLTableView> {
            return localVarFp.getSimulationPL(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimulationSlots(simulationId: string, options?: any): AxiosPromise<SimulationSlotTableView> {
            return localVarFp.getSimulationSlots(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimulationWorkforcePlanning(simulationId: string, options?: any): AxiosPromise<SimulationWorkforcePlanningTableView> {
            return localVarFp.getSimulationWorkforcePlanning(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimulations(options?: any): AxiosPromise<ListSimulationView> {
            return localVarFp.listSimulations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} simulationId 
         * @param {UpdateSimulationBody} updateSimulationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimulation(simulationId: string, updateSimulationBody: UpdateSimulationBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateSimulation(simulationId, updateSimulationBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforcePlanningSimulationControllerApi - object-oriented interface
 * @export
 * @class WorkforcePlanningSimulationControllerApi
 * @extends {BaseAPI}
 */
export class WorkforcePlanningSimulationControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateSimulationBody} createSimulationBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningSimulationControllerApi
     */
    public createSimulation(createSimulationBody: CreateSimulationBody, options?: AxiosRequestConfig) {
        return WorkforcePlanningSimulationControllerApiFp(this.configuration).createSimulation(createSimulationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningSimulationControllerApi
     */
    public deleteSimulation(simulationId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanningSimulationControllerApiFp(this.configuration).deleteSimulation(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningSimulationControllerApi
     */
    public getSimulationDetail(simulationId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanningSimulationControllerApiFp(this.configuration).getSimulationDetail(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningSimulationControllerApi
     */
    public getSimulationPL(simulationId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanningSimulationControllerApiFp(this.configuration).getSimulationPL(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningSimulationControllerApi
     */
    public getSimulationSlots(simulationId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanningSimulationControllerApiFp(this.configuration).getSimulationSlots(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningSimulationControllerApi
     */
    public getSimulationWorkforcePlanning(simulationId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanningSimulationControllerApiFp(this.configuration).getSimulationWorkforcePlanning(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningSimulationControllerApi
     */
    public listSimulations(options?: AxiosRequestConfig) {
        return WorkforcePlanningSimulationControllerApiFp(this.configuration).listSimulations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} simulationId 
     * @param {UpdateSimulationBody} updateSimulationBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningSimulationControllerApi
     */
    public updateSimulation(simulationId: string, updateSimulationBody: UpdateSimulationBody, options?: AxiosRequestConfig) {
        return WorkforcePlanningSimulationControllerApiFp(this.configuration).updateSimulation(simulationId, updateSimulationBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforcePlanningUnitAmountItemControllerApi - axios parameter creator
 * @export
 */
export const WorkforcePlanningUnitAmountItemControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UpsertUnitAmountItemParam} upsertUnitAmountItemParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnitAmountItem: async (upsertUnitAmountItemParam: UpsertUnitAmountItemParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertUnitAmountItemParam' is not null or undefined
            assertParamExists('createUnitAmountItem', 'upsertUnitAmountItemParam', upsertUnitAmountItemParam)
            const localVarPath = `/api/workforce-planning/unit-amount-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertUnitAmountItemParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} unitAmountItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnitAmountItem: async (unitAmountItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAmountItemId' is not null or undefined
            assertParamExists('deleteUnitAmountItem', 'unitAmountItemId', unitAmountItemId)
            const localVarPath = `/api/workforce-planning/unit-amount-items/{unitAmountItemId}`
                .replace(`{${"unitAmountItemId"}}`, encodeURIComponent(String(unitAmountItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitAmountItems: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workforce-planning/unit-amount-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} unitAmountItemId 
         * @param {UpsertUnitAmountItemParam} upsertUnitAmountItemParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUnitAmountItem: async (unitAmountItemId: string, upsertUnitAmountItemParam: UpsertUnitAmountItemParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAmountItemId' is not null or undefined
            assertParamExists('updateUnitAmountItem', 'unitAmountItemId', unitAmountItemId)
            // verify required parameter 'upsertUnitAmountItemParam' is not null or undefined
            assertParamExists('updateUnitAmountItem', 'upsertUnitAmountItemParam', upsertUnitAmountItemParam)
            const localVarPath = `/api/workforce-planning/unit-amount-items/{unitAmountItemId}`
                .replace(`{${"unitAmountItemId"}}`, encodeURIComponent(String(unitAmountItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertUnitAmountItemParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforcePlanningUnitAmountItemControllerApi - functional programming interface
 * @export
 */
export const WorkforcePlanningUnitAmountItemControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforcePlanningUnitAmountItemControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UpsertUnitAmountItemParam} upsertUnitAmountItemParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUnitAmountItem(upsertUnitAmountItemParam: UpsertUnitAmountItemParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUnitAmountItem(upsertUnitAmountItemParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} unitAmountItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUnitAmountItem(unitAmountItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUnitAmountItem(unitAmountItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnitAmountItems(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUnitAmountItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnitAmountItems(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} unitAmountItemId 
         * @param {UpsertUnitAmountItemParam} upsertUnitAmountItemParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUnitAmountItem(unitAmountItemId: string, upsertUnitAmountItemParam: UpsertUnitAmountItemParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUnitAmountItem(unitAmountItemId, upsertUnitAmountItemParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforcePlanningUnitAmountItemControllerApi - factory interface
 * @export
 */
export const WorkforcePlanningUnitAmountItemControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforcePlanningUnitAmountItemControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {UpsertUnitAmountItemParam} upsertUnitAmountItemParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnitAmountItem(upsertUnitAmountItemParam: UpsertUnitAmountItemParam, options?: any): AxiosPromise<void> {
            return localVarFp.createUnitAmountItem(upsertUnitAmountItemParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} unitAmountItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnitAmountItem(unitAmountItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUnitAmountItem(unitAmountItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitAmountItems(options?: any): AxiosPromise<ListUnitAmountItemsResponse> {
            return localVarFp.listUnitAmountItems(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} unitAmountItemId 
         * @param {UpsertUnitAmountItemParam} upsertUnitAmountItemParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUnitAmountItem(unitAmountItemId: string, upsertUnitAmountItemParam: UpsertUnitAmountItemParam, options?: any): AxiosPromise<void> {
            return localVarFp.updateUnitAmountItem(unitAmountItemId, upsertUnitAmountItemParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforcePlanningUnitAmountItemControllerApi - object-oriented interface
 * @export
 * @class WorkforcePlanningUnitAmountItemControllerApi
 * @extends {BaseAPI}
 */
export class WorkforcePlanningUnitAmountItemControllerApi extends BaseAPI {
    /**
     * 
     * @param {UpsertUnitAmountItemParam} upsertUnitAmountItemParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningUnitAmountItemControllerApi
     */
    public createUnitAmountItem(upsertUnitAmountItemParam: UpsertUnitAmountItemParam, options?: AxiosRequestConfig) {
        return WorkforcePlanningUnitAmountItemControllerApiFp(this.configuration).createUnitAmountItem(upsertUnitAmountItemParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} unitAmountItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningUnitAmountItemControllerApi
     */
    public deleteUnitAmountItem(unitAmountItemId: string, options?: AxiosRequestConfig) {
        return WorkforcePlanningUnitAmountItemControllerApiFp(this.configuration).deleteUnitAmountItem(unitAmountItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningUnitAmountItemControllerApi
     */
    public listUnitAmountItems(options?: AxiosRequestConfig) {
        return WorkforcePlanningUnitAmountItemControllerApiFp(this.configuration).listUnitAmountItems(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} unitAmountItemId 
     * @param {UpsertUnitAmountItemParam} upsertUnitAmountItemParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforcePlanningUnitAmountItemControllerApi
     */
    public updateUnitAmountItem(unitAmountItemId: string, upsertUnitAmountItemParam: UpsertUnitAmountItemParam, options?: AxiosRequestConfig) {
        return WorkforcePlanningUnitAmountItemControllerApiFp(this.configuration).updateUnitAmountItem(unitAmountItemId, upsertUnitAmountItemParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkforceSlotsControllerApi - axios parameter creator
 * @export
 */
export const WorkforceSlotsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 人員・枠の紐づけを解除する
         * @param {string} workforceSlotId 
         * @param {DisconnectWorkforceSlotFromPersonRequestBody} disconnectWorkforceSlotFromPersonRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectWorkforceSlot: async (workforceSlotId: string, disconnectWorkforceSlotFromPersonRequestBody: DisconnectWorkforceSlotFromPersonRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceSlotId' is not null or undefined
            assertParamExists('disconnectWorkforceSlot', 'workforceSlotId', workforceSlotId)
            // verify required parameter 'disconnectWorkforceSlotFromPersonRequestBody' is not null or undefined
            assertParamExists('disconnectWorkforceSlot', 'disconnectWorkforceSlotFromPersonRequestBody', disconnectWorkforceSlotFromPersonRequestBody)
            const localVarPath = `/api/workforce-planning/workforce-slots/workforceSlot/{workforceSlotId}/disconnect`
                .replace(`{${"workforceSlotId"}}`, encodeURIComponent(String(workforceSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(disconnectWorkforceSlotFromPersonRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 枠情報を取得する
         * @param {string} workforceSlotId 
         * @param {'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK'} workforceSlotType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorkforceSlot: async (workforceSlotId: string, workforceSlotType: 'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceSlotId' is not null or undefined
            assertParamExists('findWorkforceSlot', 'workforceSlotId', workforceSlotId)
            // verify required parameter 'workforceSlotType' is not null or undefined
            assertParamExists('findWorkforceSlot', 'workforceSlotType', workforceSlotType)
            const localVarPath = `/api/workforce-planning/workforce-slots/{workforceSlotId}`
                .replace(`{${"workforceSlotId"}}`, encodeURIComponent(String(workforceSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workforceSlotType !== undefined) {
                localVarQueryParameter['workforceSlotType'] = workforceSlotType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 紐づけ候補の枠一覧を取得する
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBeforeConnectedWorkforceSlots: async (planMasterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('listBeforeConnectedWorkforceSlots', 'planMasterId', planMasterId)
            const localVarPath = `/api/workforce-planning/workforce-slots/before-connected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planMasterId !== undefined) {
                localVarQueryParameter['planMasterId'] = planMasterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 人員・採用枠一覧を取得する
         * @param {string} planMasterId 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforceSlots: async (planMasterId: string, limit: number, offset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planMasterId' is not null or undefined
            assertParamExists('listWorkforceSlots', 'planMasterId', planMasterId)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('listWorkforceSlots', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('listWorkforceSlots', 'offset', offset)
            const localVarPath = `/api/workforce-planning/workforce-slots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planMasterId !== undefined) {
                localVarQueryParameter['planMasterId'] = planMasterId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 枠コードと枠名を変更する
         * @param {string} workforceSlotId 
         * @param {UpdateWorkforceSlotNameAndCodeRequestBody} updateWorkforceSlotNameAndCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforceSlotNameAndCode: async (workforceSlotId: string, updateWorkforceSlotNameAndCodeRequestBody: UpdateWorkforceSlotNameAndCodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workforceSlotId' is not null or undefined
            assertParamExists('updateWorkforceSlotNameAndCode', 'workforceSlotId', workforceSlotId)
            // verify required parameter 'updateWorkforceSlotNameAndCodeRequestBody' is not null or undefined
            assertParamExists('updateWorkforceSlotNameAndCode', 'updateWorkforceSlotNameAndCodeRequestBody', updateWorkforceSlotNameAndCodeRequestBody)
            const localVarPath = `/api/workforce-planning/workforce-slots/{workforceSlotId}`
                .replace(`{${"workforceSlotId"}}`, encodeURIComponent(String(workforceSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkforceSlotNameAndCodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkforceSlotsControllerApi - functional programming interface
 * @export
 */
export const WorkforceSlotsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkforceSlotsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 人員・枠の紐づけを解除する
         * @param {string} workforceSlotId 
         * @param {DisconnectWorkforceSlotFromPersonRequestBody} disconnectWorkforceSlotFromPersonRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectWorkforceSlot(workforceSlotId: string, disconnectWorkforceSlotFromPersonRequestBody: DisconnectWorkforceSlotFromPersonRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectWorkforceSlot(workforceSlotId, disconnectWorkforceSlotFromPersonRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 枠情報を取得する
         * @param {string} workforceSlotId 
         * @param {'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK'} workforceSlotType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findWorkforceSlot(workforceSlotId: string, workforceSlotType: 'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkforceSlotView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findWorkforceSlot(workforceSlotId, workforceSlotType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 紐づけ候補の枠一覧を取得する
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBeforeConnectedWorkforceSlots(planMasterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BeforeConnectedWorkforceSlotView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBeforeConnectedWorkforceSlots(planMasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 人員・採用枠一覧を取得する
         * @param {string} planMasterId 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkforceSlots(planMasterId: string, limit: number, offset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoWorkforceSlotListItemView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkforceSlots(planMasterId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 枠コードと枠名を変更する
         * @param {string} workforceSlotId 
         * @param {UpdateWorkforceSlotNameAndCodeRequestBody} updateWorkforceSlotNameAndCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkforceSlotNameAndCode(workforceSlotId: string, updateWorkforceSlotNameAndCodeRequestBody: UpdateWorkforceSlotNameAndCodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkforceSlotNameAndCode(workforceSlotId, updateWorkforceSlotNameAndCodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkforceSlotsControllerApi - factory interface
 * @export
 */
export const WorkforceSlotsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkforceSlotsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 人員・枠の紐づけを解除する
         * @param {string} workforceSlotId 
         * @param {DisconnectWorkforceSlotFromPersonRequestBody} disconnectWorkforceSlotFromPersonRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectWorkforceSlot(workforceSlotId: string, disconnectWorkforceSlotFromPersonRequestBody: DisconnectWorkforceSlotFromPersonRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.disconnectWorkforceSlot(workforceSlotId, disconnectWorkforceSlotFromPersonRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 枠情報を取得する
         * @param {string} workforceSlotId 
         * @param {'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK'} workforceSlotType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorkforceSlot(workforceSlotId: string, workforceSlotType: 'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK', options?: any): AxiosPromise<WorkforceSlotView> {
            return localVarFp.findWorkforceSlot(workforceSlotId, workforceSlotType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 紐づけ候補の枠一覧を取得する
         * @param {string} planMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBeforeConnectedWorkforceSlots(planMasterId: string, options?: any): AxiosPromise<Array<BeforeConnectedWorkforceSlotView>> {
            return localVarFp.listBeforeConnectedWorkforceSlots(planMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 人員・採用枠一覧を取得する
         * @param {string} planMasterId 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkforceSlots(planMasterId: string, limit: number, offset: number, options?: any): AxiosPromise<PageDtoWorkforceSlotListItemView> {
            return localVarFp.listWorkforceSlots(planMasterId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 枠コードと枠名を変更する
         * @param {string} workforceSlotId 
         * @param {UpdateWorkforceSlotNameAndCodeRequestBody} updateWorkforceSlotNameAndCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkforceSlotNameAndCode(workforceSlotId: string, updateWorkforceSlotNameAndCodeRequestBody: UpdateWorkforceSlotNameAndCodeRequestBody, options?: any): AxiosPromise<OkJson> {
            return localVarFp.updateWorkforceSlotNameAndCode(workforceSlotId, updateWorkforceSlotNameAndCodeRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkforceSlotsControllerApi - object-oriented interface
 * @export
 * @class WorkforceSlotsControllerApi
 * @extends {BaseAPI}
 */
export class WorkforceSlotsControllerApi extends BaseAPI {
    /**
     * 
     * @summary 人員・枠の紐づけを解除する
     * @param {string} workforceSlotId 
     * @param {DisconnectWorkforceSlotFromPersonRequestBody} disconnectWorkforceSlotFromPersonRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceSlotsControllerApi
     */
    public disconnectWorkforceSlot(workforceSlotId: string, disconnectWorkforceSlotFromPersonRequestBody: DisconnectWorkforceSlotFromPersonRequestBody, options?: AxiosRequestConfig) {
        return WorkforceSlotsControllerApiFp(this.configuration).disconnectWorkforceSlot(workforceSlotId, disconnectWorkforceSlotFromPersonRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 枠情報を取得する
     * @param {string} workforceSlotId 
     * @param {'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK'} workforceSlotType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceSlotsControllerApi
     */
    public findWorkforceSlot(workforceSlotId: string, workforceSlotType: 'HIRING' | 'RESIGNATION' | 'LEAVE_OF_ABSENCE' | 'RETURN_TO_WORK', options?: AxiosRequestConfig) {
        return WorkforceSlotsControllerApiFp(this.configuration).findWorkforceSlot(workforceSlotId, workforceSlotType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 紐づけ候補の枠一覧を取得する
     * @param {string} planMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceSlotsControllerApi
     */
    public listBeforeConnectedWorkforceSlots(planMasterId: string, options?: AxiosRequestConfig) {
        return WorkforceSlotsControllerApiFp(this.configuration).listBeforeConnectedWorkforceSlots(planMasterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 人員・採用枠一覧を取得する
     * @param {string} planMasterId 
     * @param {number} limit 
     * @param {number} offset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceSlotsControllerApi
     */
    public listWorkforceSlots(planMasterId: string, limit: number, offset: number, options?: AxiosRequestConfig) {
        return WorkforceSlotsControllerApiFp(this.configuration).listWorkforceSlots(planMasterId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 枠コードと枠名を変更する
     * @param {string} workforceSlotId 
     * @param {UpdateWorkforceSlotNameAndCodeRequestBody} updateWorkforceSlotNameAndCodeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkforceSlotsControllerApi
     */
    public updateWorkforceSlotNameAndCode(workforceSlotId: string, updateWorkforceSlotNameAndCodeRequestBody: UpdateWorkforceSlotNameAndCodeRequestBody, options?: AxiosRequestConfig) {
        return WorkforceSlotsControllerApiFp(this.configuration).updateWorkforceSlotNameAndCode(workforceSlotId, updateWorkforceSlotNameAndCodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ZendeskControllerApi - axios parameter creator
 * @export
 */
export const ZendeskControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Zendeskでサポートサイトと連携するための認証用JWTを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwt: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/zendesk/jwt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZendeskControllerApi - functional programming interface
 * @export
 */
export const ZendeskControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZendeskControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Zendeskでサポートサイトと連携するための認証用JWTを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jwt(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZendeskJwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jwt(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZendeskControllerApi - factory interface
 * @export
 */
export const ZendeskControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZendeskControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Zendeskでサポートサイトと連携するための認証用JWTを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwt(options?: any): AxiosPromise<ZendeskJwtDto> {
            return localVarFp.jwt(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZendeskControllerApi - object-oriented interface
 * @export
 * @class ZendeskControllerApi
 * @extends {BaseAPI}
 */
export class ZendeskControllerApi extends BaseAPI {
    /**
     * 
     * @summary Zendeskでサポートサイトと連携するための認証用JWTを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZendeskControllerApi
     */
    public jwt(options?: AxiosRequestConfig) {
        return ZendeskControllerApiFp(this.configuration).jwt(options).then((request) => request(this.axios, this.basePath));
    }
}


