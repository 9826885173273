import { useEvent } from 'react-use';
import { useEffect } from 'react';
import { getItemFromLocalStorage, setItemToLocalStorage } from '~/common/utils/local-storage';
import { useLoginUser } from '~/common/providers/auth/use-login-user';
import { useTenantContext } from '~/common/providers/auth/TenantProvider';

/**
 * 自タブ以外でログアウト及びログイン発生時、現在ログインしているテナントが表示しているテナントと異なる場合、強制リロード
 * 対象はログインユーザーがシステム管理者の場合のみ
 **/
const useReloadAllUnauthorizedTabs = (logout: () => void) => {
  const loginUser = useLoginUser();
  const { tenantId } = useTenantContext();

  const reloadWhenUnmatchedTenantId = (event: StorageEvent) => {
    if (loginUser?.role !== 'LOGLASS_SYSTEM_ADMIN') return;
    if (event.key != null && event.key !== LOGIN_TENANT_ID) return; // ログアウト（localStorage 全消し）もしくは tenantId 変更以外の場合は無視
    const tenantId = getItemFromLocalStorage<string>(LOGIN_TENANT_ID);
    if (tenantId && tenantId === loginUser?.tenantInfo.tenantId) return;
    document.body.remove(); // リロードが即座に動作しないため、画面を消す
    location.reload();
  };
  useEvent('storage', reloadWhenUnmatchedTenantId);

  useEffect(() => {
    if (loginUser?.role !== 'LOGLASS_SYSTEM_ADMIN') return;
    if (tenantId && tenantId !== loginUser.tenantInfo.tenantId) logout(); // ログインしているテナントが異なる場合、ログアウト
    setItemToLocalStorage(LOGIN_TENANT_ID, loginUser.tenantInfo.tenantId);
  }, [loginUser, logout, tenantId]);
};

const LOGIN_TENANT_ID = 'v1/login-tenant-id';

export { useReloadAllUnauthorizedTabs };
