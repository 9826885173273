import React, { ReactElement } from 'react';
import { css } from '@emotion/react';
import { X } from 'react-feather';
import { Span } from '~/common/components/span';
import { Button } from '~/common/components/button';
import { useDrawer } from '~/common/components/drawer/DrawerContext';
import { Icon } from '~/common/components/icon';

type DrawerHeaderProps = {
  title: string;
  headerIcon?: ReactElement;
};

const DrawerHeader = React.memo(function DrawerHeader({ title, headerIcon }: DrawerHeaderProps) {
  const { onClose } = useDrawer();

  return (
    <div css={styles.header}>
      <Span css={styles.textIcon}>
        <Span color={'dark'} level={1} bold>
          {title}
        </Span>
        {headerIcon && (
          <Icon size={'s'} color={'gray'}>
            {headerIcon}
          </Icon>
        )}
      </Span>
      <Button
        variant={'secondary'}
        appearance={'ghost'}
        iconSize="s"
        ariaLabel={`「${title}」を閉じる`}
        icon={<X />}
        onClick={onClose}
      />
    </div>
  );
});

const styles = {
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-5);
    border-bottom: 1px solid var(--color-new-neutral-2);
    gap: var(--spacing-5);
  `,
  textIcon: css`
    display: flex;
    column-gap: var(--spacing-3);
    align-items: center;
  `,
};

export { DrawerHeader };
