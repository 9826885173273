import React, { useCallback, useRef, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { css } from '@emotion/react';
import { getItemFromLocalStorage, setItemToLocalStorage } from '~/common/utils/local-storage';
import { Icon } from '~/common/components/icon';

type NavigationStatus = 'CLOSE' | 'OPEN' | 'FLOAT_OPEN';

const useNavigationControl = () => {
  const prevState = useRef<NavigationStatus>('OPEN');
  const timerId = useRef<NodeJS.Timeout | undefined>(undefined);
  const [state, setState] = useState<NavigationStatus>(
    getItemFromLocalStorage(NAVIGATION_STORAGE_KEY) ?? 'OPEN',
  );

  const setNavigationState = useCallback(
    (newValue: NavigationStatus) => {
      prevState.current = state;
      setItemToLocalStorage(NAVIGATION_STORAGE_KEY, newValue);
      setState(newValue);
    },
    [state, prevState],
  );

  const openFloatNavigation = useCallback(() => {
    if (state !== 'CLOSE') return;
    // マウスホバー時、すぐに開かずに 500ms 待機する
    timerId.current = setTimeout(() => {
      setNavigationState('FLOAT_OPEN');
    }, FLOAT_OPEN_DELAY_TIME);
  }, [setNavigationState, state]);

  const closeNavigationIfFloatOpen = useCallback(() => {
    clearTimeout(timerId.current);
    if (state === 'FLOAT_OPEN') setNavigationState('CLOSE');
  }, [setNavigationState, state]);

  const NavigationToggleButton = () => (
    <div
      css={[styles.container]}
      // FLOAT_OPEN -> OPEN, CLOSE -> OPEN, OPEN -> CLOSE
      onClick={() => setNavigationState(state === 'OPEN' ? 'CLOSE' : 'OPEN')}
    >
      <Icon color="dark" size="m">
        <ChevronRight />
      </Icon>
    </div>
  );

  return {
    navigationStatus: state,
    navigationPreviousStatus: prevState.current,
    openFloatNavigation,
    closeNavigationIfFloatOpen,
    NavigationToggleButton,
  };
};

const NAVIGATION_STORAGE_KEY = 'v1/components/layout/navigation';
const FLOAT_OPEN_DELAY_TIME = 500;

const styles = {
  container: css`
    background-color: var(--color-base-background);
    border: var(--border-width-s) solid var(--color-new-neutral-2);
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: var(--color-new-neutral-3);
    }
  `,
};

export { useNavigationControl };
export type { NavigationStatus };
