import { useContext } from 'react';
import { LoginUser } from './login-user';
import { Role } from '~/common/providers/auth/role';
import { LoginUserContext } from '~/common/providers/auth/LoginUserProvider';

export const useLoginUser = (): LoginUser | undefined => {
  return useContext(LoginUserContext)?.user;
};

export const useMyRole = (): Role | undefined => {
  const loginUser = useLoginUser();
  return loginUser?.role;
};
