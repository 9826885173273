/**
 * 安全にType Assertionするための関数。
 * 型が合わないものはそもそもコンパイルエラーにし、conditionに合わないものはランタイムエラーにしてくれる
 *
 * 例）
 * const str: string = asSafely('1' as unknown, isString);
 * // OK
 * * const strOrUndefined: string | undefined = asSafely(undefined as unknown, [isString, isUndefined]);
 * // OK
 * const str: string = asSafely(1, isString);
 * // コンパイルエラー
 * const str: string = asSafely(1 as unknown, isString);
 * // ランタイムエラー
 */
const asSafely = <RESULT extends TARGET, TARGET = unknown, OR_ELSE = RESULT, RESULT2 = RESULT>(
  obj: TARGET,
  condition:
    | ((obj: unknown) => obj is RESULT)
    | [(obj: unknown) => obj is RESULT, (obj: unknown) => obj is RESULT2],
  orElse?: (obj: TARGET) => OR_ELSE,
): RESULT | RESULT2 | OR_ELSE => {
  if (!isArray(condition) && condition(obj)) {
    return obj as RESULT;
  }
  if (isArray(condition) && condition.length > 0 && condition.some((c) => c(obj))) {
    return obj as RESULT | RESULT2;
  }
  if (orElse != null) {
    return orElse(obj);
  }
  throw new Error(
    `type assertion is failed. object type: ${typeof obj}. object keys: ${obj && Object.keys(obj)}`,
  );
};

const isString = (obj: unknown): obj is string => typeof obj === 'string';
const isBoolean = (obj: unknown): obj is boolean => typeof obj === 'boolean';
const isNumber = (obj: unknown): obj is number => typeof obj === 'number';
const isSymbol = (obj: unknown): obj is symbol => typeof obj === 'symbol';
const isBigint = (obj: unknown): obj is bigint => typeof obj === 'bigint';
const isUndefined = (obj: unknown): obj is undefined => typeof obj === 'undefined';
// eslint-disable-next-line eqeqeq
const isNull = (obj: unknown): obj is null => obj === null;
const isDate = (obj: unknown): obj is Date => obj instanceof Date;
const isArray = (obj: unknown): obj is unknown[] => Array.isArray(obj);
const isStringArray = (obj: unknown): obj is string[] => isArray(obj) && obj.every(isString);
const isEmptyString = (obj: unknown): obj is '' => obj === '';
const isBlob = (obj: unknown): obj is Blob => obj?.constructor.name === 'Blob';

export {
  asSafely,
  isString,
  isBoolean,
  isNumber,
  isSymbol,
  isBigint,
  isUndefined,
  isNull,
  isDate,
  isArray,
  isStringArray,
  isEmptyString,
  isBlob,
};
