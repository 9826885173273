import React, { MouseEventHandler } from 'react';
import { css, jsx } from '@emotion/react';
import * as FeatherIcons from 'react-feather';
import { assertNever } from '~/common/utils/assertNever';

type IconSize = 'xs' | 's' | 'm' | 'l' | 'xl';
type IconColor =
  | 'dark'
  | 'darkGray'
  | 'gray'
  | 'light'
  | 'highlight'
  | 'danger'
  | 'warn'
  | 'success'
  | 'info'
  | 'white'
  | 'primary';
type IconType = keyof typeof FeatherIcons | keyof typeof CustomIcons;

type IconProps = {
  children?: React.ReactElement;
  type?: IconType;
  size: IconSize;
  color: IconColor;
  onClick?: MouseEventHandler;
  /** @deprecated 代わりに Button で children 無し、icon を指定する */
  button?: boolean;
  className?: string;
  dataTestId?: string;
};

const CustomIcons = {
  Allocation: '/assets/icons/allocation.svg',
  Handle: '/assets/icons/handle.svg',
  Spreadsheet: '/assets/icons/spreadsheet.svg',
  Support: '/assets/icons/support.svg',
};

const Icon = React.forwardRef<HTMLButtonElement, IconProps>(
  ({ children, type, size, color, onClick, button, className, dataTestId }, ref) => {
    if (typeof onClick !== 'undefined' && !button) {
      throw new Error('onClick propsはbuttonモードのときのみ使用できます');
    }

    if (children && type) {
      throw new Error('childrenとtypeは同時に指定できません');
    }

    let icon = null;

    if (type && type in CustomIcons) {
      const svgUrl = `url("${CustomIcons[type as keyof typeof CustomIcons]}")`;
      icon = svgUrl ? (
        <i
          css={[sizeStyle(size), dynamicMaskStyle(svgUrl), backgroundStyle(color)]}
          className={className}
        />
      ) : null;
    } else if (type && type in FeatherIcons) {
      const FeatherIcon = FeatherIcons[type as keyof typeof FeatherIcons];
      icon = FeatherIcon ? (
        <FeatherIcon css={[colorStyle(color), sizeStyle(size)]} className={className} />
      ) : null;
    } else if (children) {
      icon = jsx(children.type, {
        key: children.key,
        className,
        css: [colorStyle(color), sizeStyle(size)],
        ...children.props,
      });
    }

    return button ? (
      <button
        ref={ref}
        type="button"
        css={styles.button}
        onClick={
          onClick
            ? (e) => {
                e.stopPropagation();
                onClick(e);
              }
            : undefined
        }
        data-testid={dataTestId}
      >
        {icon}
      </button>
    ) : (
      <span css={[styles.container, sizeStyle(size), colorStyle(color)]} data-testid={dataTestId}>
        {icon}
      </span>
    );
  },
);

Icon.displayName = 'Icon';

const colorStyle = (color: IconColor) => {
  switch (color) {
    case 'dark':
      return styles.colorDark;
    case 'darkGray':
      return styles.colorDarkGray;
    case 'gray':
      return styles.colorGray;
    case 'light':
      return styles.colorLight;
    case 'highlight':
      return styles.colorHighlight;
    case 'danger':
      return styles.colorDanger;
    case 'warn':
      return styles.colorWarn;
    case 'success':
      return styles.colorSuccess;
    case 'info':
      return styles.colorInfo;
    case 'white':
      return styles.colorWhite;
    case 'primary':
      return styles.colorPrimary;
    default:
      return assertNever(color);
  }
};

const backgroundStyle = (color: IconColor) => {
  switch (color) {
    case 'dark':
      return styles.backgroundDark;
    case 'darkGray':
      return styles.colorDarkGray;
    case 'gray':
      return styles.backgroundGray;
    case 'light':
      return styles.backgroundLight;
    case 'highlight':
      return styles.backgroundHighlight;
    case 'danger':
      return styles.backgroundDanger;
    case 'warn':
      return styles.backgroundWarn;
    case 'success':
      return styles.backgroundSuccess;
    case 'info':
      return styles.backgroundInfo;
    case 'white':
      return styles.backgroundWhite;
    case 'primary':
      return styles.backgroundPrimary;
    default:
      return assertNever(color);
  }
};

const sizeStyle = (size: IconSize) => {
  switch (size) {
    case 'xs':
      return styles.sizeXS;
    case 's':
      return styles.sizeS;
    case 'm':
      return styles.sizeM;
    case 'l':
      return styles.sizeL;
    case 'xl':
      return styles.sizeXl;
    default:
      return assertNever(size);
  }
};

const dynamicMaskStyle = (svgUrl: string) => css`
  display: block;
  mask: ${svgUrl};
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
`;

const styles = {
  button: css`
    border: none;
    background-color: initial;
    padding: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  `,

  container: css`
    user-select: none;
    display: inline-block;
    svg {
      width: 100%;
      height: 100%;
    }
  `,

  colorDark: css`
    color: var(--color-new-neutral-9);
  `,

  colorDarkGray: css`
    color: var(--color-new-neutral-7);
  `,

  colorGray: css`
    color: var(--color-new-neutral-5);
  `,

  colorLight: css`
    color: var(--color-new-neutral-0);
  `,

  colorHighlight: css`
    color: var(--color-warning-5);
  `,

  colorDanger: css`
    color: var(--status-error-normal);
  `,

  colorWarn: css`
    color: var(--status-warning-normal);
  `,

  colorSuccess: css`
    color: var(--status-success-normal);
  `,

  colorInfo: css`
    color: var(--status-info-normal);
  `,

  colorWhite: css`
    color: var(--color-new-neutral-0);
  `,

  colorPrimary: css`
    color: var(--color-primary-6);
  `,

  backgroundDark: css`
    background-color: var(--color-new-neutral-9);
  `,

  backgroundGray: css`
    background-color: var(--color-new-neutral-5);
  `,

  backgroundLight: css`
    background-color: var(--color-new-neutral-0);
  `,

  backgroundHighlight: css`
    background-color: var(--color-warning-5);
  `,

  backgroundDanger: css`
    background-color: var(--color-danger-6);
  `,

  backgroundWarn: css`
    background-color: var(--color-warning-6);
  `,

  backgroundSuccess: css`
    background-color: var(--color-success-6);
  `,

  backgroundInfo: css`
    background-color: var(--color-info-6);
  `,

  backgroundWhite: css`
    background-color: var(--color-new-neutral-0);
  `,

  backgroundPrimary: css`
    background-color: var(--color-primary-6);
  `,

  sizeXS: css`
    height: 12px;
    width: 12px;
  `,

  sizeS: css`
    height: 16px;
    width: 16px;
  `,

  sizeM: css`
    height: 20px;
    width: 20px;
  `,

  sizeL: css`
    height: 24px;
    width: 24px;
  `,

  sizeXl: css`
    height: 32px;
    width: 32px;
  `,
};

export { Icon };
export type { IconSize, IconColor, IconType };
