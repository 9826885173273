import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { clearLoglassAllItemsInLocalStorage } from '~/common/utils/local-storage';

type UseLogout = {
  logout: () => void;
};

const useLogout = (): UseLogout => {
  const { logout } = useAuth0();
  const logoutCallback = useCallback(() => {
    clearLoglassAllItemsInLocalStorage();
    logout();
  }, [logout]);
  return {
    logout: logoutCallback,
  };
};

export { useLogout };
